import {
  ACTIVATION_USER,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL
} from "./actionTypes"

export const verify = (uid, token) => {
  return {
    type: ACTIVATION_USER,
    payload: { uid, token },
  };
};

export const activationSuccess = message => {
  return {
    type: ACTIVATION_SUCCESS,
    payload: message
  }
}

export const activationFail = message => {
  return {
    type: ACTIVATION_FAIL,
    payload: message
  }
}

