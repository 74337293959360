import {
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL
} from "./actionTypes";

const initialState = {
  success: null,
  error: null
};

const Activation = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATION_SUCCESS:
      state = {
        ...state
      };
      break;
    case ACTIVATION_FAIL:
      state = {
        ...state
      };
      break;
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Activation;
