import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import {
  Container,
  Row
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../Components/Common/DeleteModal";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";

//Import actions

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const PriorityStars = ({ priority }) => {
  
  const renderStars = () => {
    if (priority && priority > 0 && priority <= 5) {
      const stars = [];
      for (let i = 1; i <= 5; i++) {
        stars.push(
          <span
            key={i}
            style={{
              color: i <= priority ? '#DC7621' : '#a3a3a3'
            }}
          >
            &#9733; {/* Étoile Unicode */}
          </span>
        );
      }
      return stars;
    } else {
      return null;
    }
  };

  return (
    <div>
      {renderStars()}
    </div>
  );
};

const Publicities = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const dispatch = useDispatch();

  const { products} = useSelector((state) => ({
    products :state.Ecommerce.products,
  }));

  // Definition des etats
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  const [publicites, setPublicites] = useState([]);


  const role = JSON.parse(sessionStorage.getItem("authUser"))?.role.some((role)=>role === "assistant")



  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://backend.leconseillerdubtp.com/api/v1/publicity/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setPublicites(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  
  const handleDeleteProduct = () => {
    if (publicites) {
      // dispatch(deleteProducts(articles.id));
      setDeleteModal(false);
    }
  };


  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = 'none';
    });
  };

  const columns = useMemo(() => [
    {
      Header:props.t("ILLUSTRATION"),
      Cell: (publicity) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
           
              <img
                  src={publicity.row.original.publicity_pic}
                  alt=""
                  className="avatar-sm rounded"
                /> 
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("TITRE"),
      accessor:"title",
      Cell: (publicity) => (
        <>
          <div className="d-flex align-items-center" style={{width:200,height:50,overflow:"clip",verticalAlign:"middle",textTransform:'uppercase'}}>
            
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  className="text-dark"
                >
                  {" "}
                  {publicity.row.original.title}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("CONTENU"),
      accessor:"content",
      Cell: (publicity) => (
        <>
          <div className="d-flex align-items-center" >
            <div className="flex-grow-1">
              {publicity.row.original.publicity_content?
                <h5 className="fs-14 mb-1" style={{ width: 200, height: 50, overflow: "clip", verticalAlign: "middle" }}>
                  <Link
                    to={`/#/${publicity.row.original.id}/`}
                    className="text-dark"
                  >
                  </Link>
                  {publicity.row.original.publicity_content}
                </h5>
                : <h5 className="fs-14 mb-1">Pas de description</h5>}
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("PRIORITE"),
      accessor:"priority",
      Cell: (publicity) => (
        <>
          <div className="d-flex align-items-center" >
            <div className="flex-grow-1">
              {publicity.row.original.priority &&
                <PriorityStars priority={publicity.row.original.priority} />
              }
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("STATUT"),
      Cell: (publicity) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1" >
              <h5 className="fs-14  p-2">
                <span className="mt-4">
                  {publicity.row.original.published === false && !role ?
                    <div>
                      <div className="btn btn-soft-success" >
                        <Link to={`apps-publicities-details/${publicity.row.original.id}/`}>
                          Publier
                        </Link>
                      </div>
                    </div> : 
                    <h5 className="mb-1 badge badge-soft-success " style={{fontSize:'10px'}} >
                        Publiée
                    </h5>
                  }
                </span>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "ACTIONS",
      Cell: (cellProps) => {
        const publicitiesData = cellProps.row.original;
        return(
          <> 
          <li className="list-inline-item" title={props.t("Modifier")}>
              <Link to={`apps-publicities-update/${publicitiesData.id}/`} className="text-warning d-inline-block"
                    >
                    <i className="ri-pencil-fill align-bottom"></i>
                </Link>
            </li> 
            
            <li className="list-inline-item" title={props.t("Voir")}>
              <Link to={`apps-publicities-details/${publicitiesData.id}/`} className="text-warning d-inline-block"
                    >
                  <i className="ri-eye-fill align-bottom"></i>
              </Link>
           </li>
          </> 
        );
      },
    },
    
  ],
    [props]
  );


  document.title = props.t("Liste des publicités");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Liste des publicités")} pageTitle={props.t("Liste des publicités")} />

        <Row>
          <div className="col-xl-12 col-lg-12">
          
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {  isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) : publicites.length > 0 ? (
                    <div>
                    <TableContainer
                      columns={columns}
                      data={(publicites || [])}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle "
                      theadClass="table-light text-muted"
                      isPublicitiesFilter={true}
                    />
                    </div>
                    ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

Publicities.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}

export default withRouter(withTranslation()(Publicities))