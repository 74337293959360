import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";

import {
  Container,
  Row
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../Components/Common/DeleteModal";
import TableContainer from "../../Components/Common/TableContainer";
import BreadCrumb from "../../Components/Common/BreadCrumb";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Innovations = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const dispatch = useDispatch();

  // Definition des etats
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  const [innovations, setInnovations] = useState([]);


  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://backend.leconseillerdubtp.com/api/v1/innovations/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setInnovations(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const tokenInfo = JSON.parse(sessionStorage.getItem("authUser")).email
  
  console.log(tokenInfo)

  const handleDeleteProduct = () => {
    if (innovations) {
      // dispatch(deleteProducts(articles.id));
      setDeleteModal(false);
    }
  };

  const role = JSON.parse(sessionStorage.getItem("authUser"))?.role.some((role)=>role === "assistant")

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = 'none';
    });
  };

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  }
  const columns = useMemo(() => [
    {
      Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
      Cell: (cellProps) => {
        return <input type="checkbox" className="entrepriseCheckBox" value={cellProps.row.original.id} />;
      },
      id: '#',
    },
    {
      Header:props.t("ILLUSTRATION"),
      Cell: (innovation) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <img
                  src={innovation.row.original.innovation_pic}
                  alt=""
                  className="avatar-sm rounded"
                /> 
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("TITRE"),
      accessor:"label",
      Cell: (innovation) => (
        <>
          <div className="d-flex align-items-center" style={{width:200,height:50,overflow:"clip",verticalAlign:"middle",textTransform:'uppercase'}}>
            
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <b style={{fontWeight:'bold'}}>
                  {innovation.row.original.label}
                </b>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("DESCRIPTION"),
      accessor:"description",
      Cell: (innovation) => (
          <div className="d-flex align-items-center" >
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1" style={{width:200,height:50,overflow:"clip",verticalAlign:"middle"}}>
                <Link
                  to={`/#/${innovation.row.original.id}/`}
                  className="text-dark"
                >
                </Link>
                  {innovation.row.original.description}
              </h5>
            </div>
          </div>
      ),
    },
    {
      Header: props.t("STATUT"),
      Cell: (innovation) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1" >
                  {innovation.row.original.published === false ?
                    <div>
                      <h5 className="mb-1 badge badge-soft-warning me-1 ">
                        En attente
                      </h5>
                    </div>
                    : <p className="mb-1 badge badge-soft-success me-1"> Publiée</p>
                  }
            </div>
          </div>
        </>
      ),
    },
    {
      Header:" ",
      Cell: (innovation) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1" >
              <h5 className="fs-14  p-2">
                <span className="mt-4">
                  {innovation.row.original.published === false && !role &&
                    <div>
                      <div className="btn btn-soft-success" >
                        <Link to={`apps-innovations-details/${innovation.row.original.id}/`}>
                          Publier
                        </Link>
                      </div>
                    </div> 
                  }
                </span>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("AUTEUR"),
      accessor: "innovator",
      Cell: (innovation) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1" >
              <h5 className="fs-14 mb-1 ">
                <i className="p-2 align-items-center " >
                  {innovation.row.original.innovator ?
                    innovation.row.original.innovator.username
                    : "-"
                  }</i>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "ACTIONS",
      Cell: (cellProps) => {
        const innovationsData = cellProps.row.original;
        return(
          <>
            <li className="list-inline-item" title={props.t("Modifier")}>
              <Link to={`apps-innovations-update/${innovationsData.id}/`} className="text-warning d-inline-block"
                    >
                    <i className="ri-pencil-fill align-bottom"></i>
                </Link>
            </li>
            <li className="list-inline-item" title={props.t("Voir")}>
              <Link to={`apps-innovations-details/${innovationsData.id}/`} className="text-warning d-inline-block"
                    >
                  <i className="ri-eye-fill align-bottom"></i>
              </Link>
           </li>
          </> 
        );
      },
    },
    
  ],
    [props]
  );


  document.title = props.t("Liste des innovations");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Liste des innovations")} pageTitle={props.t("Listes des innovations")} />
        <Row>
          <div className="col-xl-12 col-lg-12">
          
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {  isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) : innovations ? (
                    <div>
                    <TableContainer
                      columns={columns}
                      data={(innovations|| [])}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle"
                      theadClass="table-light text-muted"
                      isInnovationFilter={true}
                    />
                    </div>
                    ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
Innovations.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(Innovations))