import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";

import AuthSlider from '../authCarousel';

import ParticlesAuth from '../ParticlesAuth';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//PhoneInput
import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect, { isPossiblePhoneNumber } from 'react-phone-number-input';

// action
import { registerAffiliate , apiError, resetRegisterFlag } from "../../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

import { withRouter } from 'react-router-dom/cjs/react-router-dom';

const AffiliateSignUp = () => {

    const [phoneinput,setPhoneinput] = useState();
    const [valid,setValid] = useState();
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmpasswordShow, setConfirmPasswordShow] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const validation = useFormik({
        enableReinitialize: false,
        initialValues: {
            address:"",
            email:"",
            first_name:"",
            last_name:"",
            fonction:"",
            langue:"",
            country:"",
            tel: phoneinput,
            password: "",
            confirm_password:"",
        },
        validationSchema: Yup.object({
            address: Yup.string().required("Veuillez entrer votre adresse"),
            email: Yup.string().required("Veuillez entrer votre adresse email").email("Veuillez entrer une adresse email valide"),
            first_name: Yup.string().required("Veuillez entrer votre prénom"),
            last_name: Yup.string().required("Veuillez entrer votre nom"),
            fonction: Yup.string().required("Veuillez entrer votre fonction"),
            langue: Yup.string().required("Veuillez entrer votre langue"),
            country: Yup.string().required("Veuillez entrer votre pays"),
            password: Yup.string().required("Veuillez entrer votre mot de passe").min(8, "Le mot de passe doit contenir au moins 8 caractères").matches(/(?=.*[0-9])/, "Le mot de passe doit contenir au moins un chiffre").matches(/(?=.*[A-Z])/, "Le mot de passe doit contenir au moins une lettre majuscule").matches(/(?=.*[a-z])/, "Le mot de passe doit contenir au moins une lettre minuscule"),
            confirm_password: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Les mots de passe ne correspondent pas"
                )
            })
        }),
        
        onSubmit: (values) => {
            const affiliate = {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                password: values.password,
                affilie: {
                    tel: phoneinput,
                    address: values.address,
                    pays: values.country,
                    country: values.country,
                    fonction: values.fonction,
                    langue: values.langue
                },
            }
            //console.log('Les valeurs envoyés pour affilié');
            //console.log(affiliate);
            dispatch(registerAffiliate(affiliate));
            //console.log('Les valeurs envoyés');
            //console.log(values);
            //console.log('environnement');
            //console.log(process.env.REACT_APP_DEFAULTAUTH);
        }
    });

    const { error, registrationError, success } = useSelector(state => ({
        registrationError: state.Account.registrationError,
        success: state.Account.success,
        error: state.Account.error
    }));

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            setTimeout(() => history.push("login"), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]);
    
    document.title = "Inscription des affiliés | Le Conseiller du BTP";

    return (
        <React.Fragment>
          <ParticlesAuth>
            <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div style={{
                        backgroundColor: "#FFFFFF", opacity: 0, width: "100%", height: "100%", position: "absolute", top: 0,
                        left: 0
                }}>  
                </div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden m-0">
                                    <Row className="justify-content-center g-0">
                                        <AuthSlider />

                                        <Col lg={6} style={{ backgroundColor: "#ECECEE" }}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <h5 className="text-primary"> Démarrez votre essai gratuit dès maintenant.</h5>
                                                    <p className="text-muted">Remplissez le formulaire ci-dessous pour démarrer votre essai gratuit. Nous resterons disponibles pour vous accompagner à chaque pas que vous faites avec nous.</p>
                                                </div>

                                                <div className="mt-4">
                                                    <Form 
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    className="needs-validation" noValidate action="#">
                                                    {success && success ? (
                                                        <>
                                                            {toast("Your Redirect To Login Page...", { position: "top-right", hideProgressBar: false, className: 'bg-success text-white', progress: undefined, toastId: "" })}
                                                            <ToastContainer autoClose={2000} limit={1} />
                                                            <Alert color="success">
                                                                Register User Successfully and Your Redirect To Login Page...
                                                            </Alert>
                                                        </>
                                                    ) : null}

                                                    {error && error ? (
                                                        <Alert color="danger"><div>
                                                            {/* {registrationError} */}
                                                            Email has been Register Before, Please Use Another Email Address... </div></Alert>
                                                    ) : null}
                                                <Row>
                                                  <Col md={6}> 
                                                    <div className="mb-3">
                                                            {/* <label className="form-label" htmlFor="password-input">Password</label> */}
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Prénom(s)"
                                                                    id="first_name"
                                                                    name="first_name"
                                                                    value={validation.values.first_name}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.first_name && validation.touched.first_name ? true : false}
                                                                />
                                                                {validation.errors.first_name && validation.touched.first_name ? (
                                                                    <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                                                ) : null}
                                                        </div>
                                                  </Col>
                                                  <Col md={6}> 
                                                    <div className="mb-3">
                                                            {/* <label className="form-label" htmlFor="password-input">Password</label> */}
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Nom"
                                                                    id="last_name"
                                                                    name="last_name"
                                                                    value={validation.values.last_name}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.last_name && validation.touched.last_name ? true : false}
                                                                />
                                                                {validation.errors.last_name && validation.touched.last_name ? (
                                                                    <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                                                ) : null}
                                                        </div>
                                                  </Col>
                                                  <Col md={12}> 
                                                    <div className="mb-3">
                                                            {/* <label className="form-label" htmlFor="password-input">Password</label> */}
                                                                <Input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Adresse Email"
                                                                    id="email"
                                                                    name="email"
                                                                    value={validation.values.email}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.email && validation.touched.email ? true : false}
                                                                />
                                                                {validation.errors.email && validation.touched.email ? (
                                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                                ) : null}
                                                        </div>
                                                  </Col>
                                                  <Col md={12}> 
                                                    <div className="mb-3">
                                                            {/* <label className="form-label" htmlFor="password-input">Password</label> */}
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    type={passwordShow ? "text" : "password"}
                                                                    className="form-control pe-5 password-input"
                                                                    placeholder="Mot de passe"
                                                                    id="password-input"
                                                                    name="password"
                                                                    value={validation.values.password}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.password && validation.touched.password ? true : false}
                                                                />
                                                                {validation.errors.password && validation.touched.password ? (
                                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                ) : null}
                                                                <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                    id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                            </div>
                                                        </div>
                                                  </Col>
                                                  <Col md={12}>
                                                        <div className="mb-3">
                                                            {/* <Label htmlFor="confirmPassword" className="form-label">Confirm Password <span className="text-danger">*</span></Label> */}
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    name="confirm_password"
                                                                    type={confirmpasswordShow ? "text" : "password"}
                                                                    placeholder="Confirmer mot de passe"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.confirm_password || ""}
                                                                    invalid={
                                                                        validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                                    <FormFeedback type="invalid"><div>{validation.errors.confirm_password}</div></FormFeedback>
                                                                ) : null}
                                                                <Button color="link" onClick={() => setConfirmPasswordShow(!confirmpasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                    id="password-addon-2"><i className="ri-eye-fill align-middle"></i></Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <PhoneInputWithCountrySelect 
                                                                defaultCountry="US"
                                                                international
                                                                placeholder="Téléphone"
                                                                id="telephone"
                                                                name="telephone"
                                                                numberInputProps={{
                                                                    className: `form-control ${!valid ? 'border-danger' : 'border-success'}`
                                                                }}
                                                                value={validation.values.telephone || ""}
                                                                onChange={(e) => {
                                                                    if(!isNaN(e))
                                                                    {
                                                                        if(isPossiblePhoneNumber(e))
                                                                        {
                                                                            setPhoneinput(e)
                                                                            setValid(true)
                                                                        }
                                                                        else{
                                                                            setPhoneinput(e)
                                                                            setValid(false)
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <label className={`text-danger ${valid ? 'd-none' : 'd-block'}`}>{valid === false ? 'Numéro de téléphone invalide' : ''}</label>
                                                        </div>

                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            {/* <label htmlFor="address" className="form-label">Adresse <span className="text-danger">*</span></label> */}
                                                            <Input type="text" className="form-control" id="address" placeholder="Adresse"
                                                                name="address"
                                                                value={validation.values.address}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.address && validation.touched.address ? true : false} />
                                                            {validation.errors.address && validation.touched.address ? (
                                                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="langue">Langue</label> */}
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="fonction" value={validation.values.fonction} onChange={validation.handleChange}>
                                                                            <option value="">--- Fonction ---</option>
                                                                            <option value="Entre">Entrepreneur</option>
                                                                            <option value="Indep">Indépendant</option>
                                                                            <option value="Salarie">Salarié</option>
                                                                        </Input>
                                                                        {validation.errors.fonction && validation.touched.fonction ? (
                                                                            <FormFeedback type="invalid">{validation.errors.fonction}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                </Row>      

                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="country">Pays</label> */}
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="country" value={validation.values.country} onChange={validation.handleChange}>
                                                                            <option value="">--- Pays ---</option>
                                                                            <option value="Afrique du Sud">Afrique du Sud</option>
                                                                            <option value="Afghanistan">Afghanistan</option>
                                                                            <option value="Albanie">Albanie</option>
                                                                            <option value="Algérie">Algérie</option>
                                                                            <option value="Allemagne">Allemagne</option>
                                                                            <option value="Andorre">Andorre</option>
                                                                            <option value="Angola">Angola</option>
                                                                            <option value="Antigua-et-Barbuda">Antigua-et-Barbuda</option>
                                                                            <option value="Arabie Saoudite">Arabie Saoudite</option>
                                                                            <option value="Argentine">Argentine</option>
                                                                            <option value="Arménie">Arménie</option>
                                                                            <option value="Australie">Australie</option>
                                                                            <option value="Autriche">Autriche</option>
                                                                            <option value="Azerbaïdjan">Azerbaïdjan</option>
                                                                            <option value="Bahamas">Bahamas</option>
                                                                            <option value="Bahreïn">Bahreïn</option>
                                                                            <option value="Bangladesh">Bangladesh</option>
                                                                            <option value="Barbade">Barbade</option>
                                                                            <option value="Belgique">Belgique</option>
                                                                            <option value="Belize">Belize</option>
                                                                            <option value="Bénin">Bénin</option>
                                                                            <option value="Bhoutan">Bhoutan</option>
                                                                            <option value="Biélorussie">Biélorussie</option>
                                                                            <option value="Birmanie">Birmanie</option>
                                                                            <option value="Bolivie">Bolivie</option>
                                                                            <option value="Bosnie-Herzégovine">Bosnie-Herzégovine</option>
                                                                            <option value="Botswana">Botswana</option>
                                                                            <option value="Brésil">Brésil</option>
                                                                            <option value="Brunei">Brunei</option>
                                                                            <option value="Bulgarie">Bulgarie</option>
                                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                                            <option value="Burundi">Burundi</option>
                                                                            <option value="Cambodge">Cambodge</option>
                                                                            <option value="Cameroun">Cameroun</option>
                                                                            <option value="Canada">Canada</option>
                                                                            <option value="Cap-Vert">Cap-Vert</option>
                                                                            <option value="Chili">Chili</option>
                                                                            <option value="Chine">Chine</option>
                                                                            <option value="Chypre">Chypre</option>
                                                                            <option value="Colombie">Colombie</option>
                                                                            <option value="Comores">Comores</option>
                                                                            <option value="Corée du Nord">Corée du Nord</option>
                                                                            <option value="Corée du Sud">Corée du Sud</option>
                                                                            <option value="Costa Rica">Costa Rica</option>
                                                                            <option value="Côte d’Ivoire">Côte d’Ivoire</option>
                                                                            <option value="Croatie">Croatie</option>
                                                                            <option value="Cuba">Cuba</option>
                                                                            <option value="Danemark">Danemark</option>
                                                                            <option value="Djibouti">Djibouti</option>
                                                                            <option value="Dominique">Dominique</option>
                                                                            <option value="Égypte">Égypte</option>
                                                                            <option value="Émirats arabes unis">Émirats arabes unis</option>
                                                                            <option value="Équateur">Équateur</option>
                                                                            <option value="Érythrée">Érythrée</option>
                                                                            <option value="Espagne">Espagne</option>
                                                                            <option value="Eswatini">Eswatini</option>
                                                                            <option value="Estonie">Estonie</option>
                                                                            <option value="États-Unis">États-Unis</option>
                                                                            <option value="Éthiopie">Éthiopie</option>
                                                                            <option value="Fidji">Fidji</option>
                                                                            <option value="Finlande">Finlande</option>
                                                                            <option value="France">France</option>
                                                                            <option value="Gabon">Gabon</option>
                                                                            <option value="Gambie">Gambie</option>
                                                                            <option value="Géorgie">Géorgie</option>
                                                                            <option value="Ghana">Ghana</option>
                                                                            <option value="Grèce">Grèce</option>
                                                                            <option value="Grenade">Grenade</option>
                                                                            <option value="Guatemala">Guatemala</option>
                                                                            <option value="Guinée">Guinée</option>
                                                                            <option value="Guinée équatoriale">Guinée équatoriale</option>
                                                                            <option value="Guinée-Bissau">Guinée-Bissau</option>
                                                                            <option value="Guyana">Guyana</option>
                                                                            <option value="Haïti">Haïti</option>
                                                                            <option value="Honduras">Honduras</option>
                                                                            <option value="Hongrie">Hongrie</option>
                                                                            <option value="Îles Cook">Îles Cook</option>
                                                                            <option value="Îles Marshall">Îles Marshall</option>
                                                                            <option value="Inde">Inde</option>
                                                                            <option value="Indonésie">Indonésie</option>
                                                                            <option value="Irak">Irak</option>
                                                                            <option value="Iran">Iran</option>
                                                                            <option value="Irlande">Irlande</option>
                                                                            <option value="Islande">Islande</option>
                                                                            <option value="Israël">Israël</option>
                                                                            <option value="Italie">Italie</option>
                                                                            <option value="Jamaïque">Jamaïque</option>
                                                                            <option value="Japon">Japon</option>
                                                                            <option value="Jordanie">Jordanie</option>
                                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                                            <option value="Kenya">Kenya</option>
                                                                            <option value="Kirghizistan">Kirghizistan</option>
                                                                            <option value="Kiribati">Kiribati</option>
                                                                            <option value="Koweït">Koweït</option>
                                                                            <option value="Laos">Laos</option>
                                                                            <option value="Lesotho">Lesotho</option>
                                                                            <option value="Lettonie">Lettonie</option>
                                                                            <option value="Liban">Liban</option>
                                                                            <option value="Liberia">Liberia</option>
                                                                            <option value="Libye">Libye</option>
                                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                                            <option value="Lituanie">Lituanie</option>
                                                                            <option value="Luxembourg">Luxembourg</option>
                                                                            <option value="Macédoine">Macédoine</option>
                                                                            <option value="Madagascar">Madagascar</option>
                                                                            <option value="Malaisie">Malaisie</option>
                                                                            <option value="Malawi">Malawi</option>
                                                                            <option value="Maldives">Maldives</option>
                                                                            <option value="Mali">Mali</option>
                                                                            <option value="Malte">Malte</option>
                                                                            <option value="Maroc">Maroc</option>
                                                                            <option value="Maurice">Maurice</option>
                                                                            <option value="Mauritanie">Mauritanie</option>
                                                                            <option value="Mexique">Mexique</option>
                                                                            <option value="Micronésie">Micronésie</option>
                                                                            <option value="Moldavie">Moldavie</option>
                                                                            <option value="Monaco">Monaco</option>
                                                                            <option value="Mongolie">Mongolie</option>
                                                                            <option value="Monténégro">Monténégro</option>
                                                                            <option value="Mozambique">Mozambique</option>
                                                                            <option value="Namibie">Namibie</option>
                                                                            <option value="Nauru">Nauru</option>
                                                                            <option value="Népal">Népal</option>
                                                                            <option value="Nicaragua">Nicaragua</option>
                                                                            <option value="Niger">Niger</option>
                                                                            <option value="Nigeria">Nigeria</option>
                                                                            <option value="Niue">Niue</option>
                                                                            <option value="Norvège">Norvège</option>
                                                                            <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
                                                                            <option value="Oman">Oman</option>
                                                                            <option value="Ouganda">Ouganda</option>
                                                                            <option value="Ouzbékistan">Ouzbékistan</option>
                                                                            <option value="Pakistan">Pakistan</option>
                                                                            <option value="Palaos">Palaos</option>
                                                                            <option value="Palestine">Palestine</option>
                                                                            <option value="Panama">Panama</option>
                                                                            <option value="Papouasie-Nouvelle-Guinée">Papouasie-Nouvelle-Guinée</option>
                                                                            <option value="Paraguay">Paraguay</option>
                                                                            <option value="Pays-Bas">Pays-Bas</option>
                                                                            <option value="Pérou">Pérou</option>
                                                                            <option value="Philippines">Philippines</option>
                                                                            <option value="Pologne">Pologne</option>
                                                                            <option value="Portugal">Portugal</option>
                                                                            <option value="Qatar">Qatar</option>
                                                                            <option value="République centrafricaine">République centrafricaine</option>
                                                                            <option value="République démocratique du Congo">République démocratique du Congo</option>
                                                                            <option value="République Dominicaine">République Dominicaine</option>
                                                                            <option value="République du Congo">République du Congo</option>
                                                                            <option value="République tchèque">République tchèque</option>
                                                                            <option value="Roumanie">Roumanie</option>
                                                                            <option value="Royaume-Uni">Royaume-Uni</option>
                                                                            <option value="Russie">Russie</option>
                                                                            <option value="Rwanda">Rwanda</option>
                                                                            <option value="Saint-Kitts-et-Nevis">Saint-Kitts-et-Nevis</option>
                                                                            <option value="Saint-Vincent-et-les-Grenadines">Saint-Vincent-et-les-Grenadines</option>
                                                                            <option value="Sainte-Lucie">Sainte-Lucie</option>
                                                                            <option value="Saint-Marin">Saint-Marin</option>
                                                                            <option value="Salomon">Salomon</option>
                                                                            <option value="Salvador">Salvador</option>
                                                                            <option value="Samoa">Samoa</option>
                                                                            <option value="São Tomé-et-Principe">São Tomé-et-Principe</option>
                                                                            <option value="Sénégal">Sénégal</option>
                                                                            <option value="Serbie">Serbie</option>
                                                                            <option value="Seychelles">Seychelles</option>
                                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                                            <option value="Singapour">Singapour</option>
                                                                            <option value="Slovaquie">Slovaquie</option>
                                                                            <option value="Slovénie">Slovénie</option>
                                                                            <option value="Somalie">Somalie</option>
                                                                            <option value="Soudan">Soudan</option>
                                                                            <option value="Soudan du Sud">Soudan du Sud</option>
                                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                                            <option value="Suède">Suède</option>
                                                                            <option value="Suisse">Suisse</option>
                                                                            <option value="Suriname">Suriname</option>
                                                                            <option value="Syrie">Syrie</option>
                                                                            <option value="Tadjikistan">Tadjikistan</option>
                                                                            <option value="Tanzanie">Tanzanie</option>
                                                                            <option value="Tchad">Tchad</option>
                                                                            <option value="Thaïlande">Thaïlande</option>
                                                                            <option value="Timor oriental">Timor oriental</option>
                                                                            <option value="Togo">Togo</option>
                                                                            <option value="Tonga">Tonga</option>
                                                                            <option value="Trinité-et-Tobago">Trinité-et-Tobago</option>
                                                                            <option value="Tunisie">Tunisie</option>
                                                                            <option value="Turkménistan">Turkménistan</option>
                                                                            <option value="Turquie">Turquie</option>
                                                                            <option value="Tuvalu">Tuvalu</option>
                                                                            <option value="Ukraine">Ukraine</option>
                                                                            <option value="Uruguay">Uruguay</option>
                                                                            <option value="Vanuatu">Vanuatu</option>
                                                                            <option value="Vatican">Vatican</option>
                                                                            <option value="Venezuela">Venezuela</option>
                                                                            <option value="Viêt Nam">Viêt Nam</option>
                                                                            <option value="Yémen">Yémen</option>
                                                                            <option value="Zambie">Zambie</option>
                                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                                        </Input>
                                                                    </div>
                                                                </Col>


                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="langue">Langue</label> */}
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="langue" value={validation.values.langue} onChange={validation.handleChange}>
                                                                            <option value="">--- Langue ---</option>
                                                                            <option value="Eng">Anglais</option>
                                                                            <option value="Fr">Français</option>
                                                                        </Input>
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                        <div className="mb-4">
                                                            <p className="mb-0 fs-12 text-muted fst-normal">En vous inscrivant, vous acceptez nos <a href="https://inawo.pro" className="text-primary text-decoration-underline fst-normal fw-medium">Conditions Générales de Vente</a>, nos <a href="https://inawo.pro" className="text-primary text-decoration-underline fst-normal fw-medium">Conditions d'utilisation</a> et à notre <a href="https://inawo.pro/a_propos/mentions_legales" className="text-primary text-decoration-underline fst-normal fw-medium">Politique de confidentialité</a></p>
                                                        </div>

                                                        <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                            <h5 className="fs-13">Le mot de passe doit contenir:</h5>
                                                            <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 caractères</b></p>
                                                            <p id="pass-lower" className="invalid fs-12 mb-2">Une <b>lettre (a-z)</b>minuscule</p>
                                                            <p id="pass-upper" className="invalid fs-12 mb-2">Au moins une <b>lettre (A-Z)</b> majuscule</p>
                                                            <p id="pass-number" className="invalid fs-12 mb-0">Au moins un <b>nombre</b> (0-9)</p>
                                                        </div>

                                                        <div className="mt-4">
                                                            <Button backgroud-color="#0166ff" className="btn w-100" type="submit">S'inscrire</Button>
                                                        </div>

                                                    </Form>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0">Vous avez déjà un compte ? <Link to="/affiliate/fr/connexion" className="fw-semibold text-primary text-decoration-underline"> Se connecter </Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>

              
            </div>
          </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(AffiliateSignUp);