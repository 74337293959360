import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// crm Redux States
import {
  GET_ENTREPRISES,
  ADD_NEW_ENTREPRISE,
  UPDATE_ENTREPRISE,
} from "./actionType";

import {
  entrepriseApiResponseSuccess,
  entrepriseApiResponseError,

  addEntrepriseFail,
  addEntrepriseSuccess,
  updateEntrepriseSuccess,
  updateEntrepriseFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getEntreprises as getEntreprisesApi,
  addNewEntreprise,
  updateEntreprise,
} from "../../helpers/fakebackend_helper";

function* getEntreprises() {
  try {
    const response = yield call(getEntreprisesApi);
    yield put(entrepriseApiResponseSuccess(GET_ENTREPRISES, response));
  } catch (error) {
    yield put(entrepriseApiResponseError(GET_ENTREPRISES, error));
  }
}

function* onUpdateEntreprise({ payload: entreprise }) {
  console.log(entreprise);
  try {
    const response = yield call(updateEntreprise, entreprise);
    console.log(response);
    yield put(updateEntrepriseSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(updateEntrepriseFail(error));
  }
}

function* onAddNewEntreprise({ payload: entreprise }) {
  try {
    console.log(entreprise);
    const response = yield call(addNewEntreprise, entreprise);
    yield put(addEntrepriseSuccess(response));
  } catch (error) {
    yield put(addEntrepriseFail(error));
  }
}

export function* watchGetEntreprises() {
  yield takeEvery(GET_ENTREPRISES, getEntreprises);
}


export function* watchUpdateEntreprise() {
  yield takeEvery(UPDATE_ENTREPRISE, onUpdateEntreprise);
}

export function* watchAddNewEntreprise() {
  yield takeEvery(ADD_NEW_ENTREPRISE, onAddNewEntreprise);
}

function* entrepriseSaga() {
  yield all([
    fork(watchGetEntreprises),
    fork(watchUpdateEntreprise),
    fork(watchAddNewEntreprise),
  ]);
}

export default entrepriseSaga;
