import axios from 'axios';

const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

export const axiosIns = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization : "Bearer " + token
  }
});
