import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import AllTasks from './AllTasks';
import Widgets from './Widgets';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const TaskList = (props) => {
    document.title=props.t("Tâches");
    return (
        <React.Fragment>
            <div className="page-content">
            
                <Container fluid>
                    <BreadCrumb title={props.t("Tâches")} pageTitle={props.t("Tâches") }/>
                    <Row>
                        {/* <Widgets /> */}
                    </Row>
                    <AllTasks />
                </Container>
            </div>
        </React.Fragment>
    );
};
TaskList.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
  };
  export default withRouter(withTranslation()(TaskList));
