import {
  REGISTER_AFFILIATE,
  REGISTER_AFFILIATE_SUCCESSFUL,
  REGISTER_AFFILIATE_FAILED,
  RESET_REGISTER_FLAG,
} from "./actionTypes"

export const registerAffiliate = affiliate => {
  return {
    type: REGISTER_AFFILIATE,
    payload: { affiliate },
  }
}

export const registerAffiliateSuccessful = affiliate => {
  return {
    type: REGISTER_AFFILIATE_SUCCESSFUL,
    payload: affiliate,
  }
}

export const registerAffiliateFailed = affiliate => {
  return {
    type: REGISTER_AFFILIATE_FAILED,
    payload: affiliate,
  }
}

export const resetAffiliateRegisterFlag = () => {
  return {
    type: RESET_REGISTER_FLAG,
  }
}

