import React, { useEffect, useState} from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import dayjs from "dayjs";
import {
  Card,
  Col,
  Container,
  Row,
  Label,
} from "reactstrap";

import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const QuestionsDetails = (props) => {
 
  document.title = "Questions/Réponses détail";

  const articlesId=useParams('id');
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://backend.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;


    const [assistance, setAssistance] = useState({})
    const [response, setReponse] = useState([])
    
    useEffect(() => {
      const checkTokenValidity = async () => {
          try {
          const response = await axios.get(`${url}/questions/${articlesId.id}/`, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          setAssistance(response)
          setReponse(assistance._response)
          } catch (error) {
          console.log(error)
          } 
      };
      checkTokenValidity();
    }, [assistance]);


  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Questions/Réponses reponse")} pageTitle={props.t("Questions/Réponsess")} />
        <Row  style={{ display: 'flex', flexDirection: 'column' }}>
          <Col lg={12}>
          <div>
            <Card className="p-4 h-screen">
              <div className="rounded p-4 " style={{border:'orange', borderStyle:'solid'}}> <Label>{props.t("QUESTION")}</Label> 
                <div> {assistance && assistance.question_text}</div> 
                <img src={assistance && assistance.question_pic} style={{alignItems:"center", marginTop:'20px', maxWidth:'50%'}}alt={`Thumbnail`} /> 
              </div>

              <div className="p-2">{assistance && dayjs(assistance.request_date).format('DD-MM-YYYY')}</div>
              {response && response.map((item, key) => (
                <div key={key}>
                  <div className="rounded p-4 mt-4" style={{ border: 'green', borderStyle: 'solid' }} >
                    <Label>{props.t("REPONSE")}</Label>
                    <div>{item.response_text}</div>
                  </div>
                  <div className="p-2" >{ dayjs(item.response_date).format('DD-MM-YYYY') }</div>
                </div>
                ))}

            </Card>
            <Row>
              <Col lg={6}>
                <Card className="h-screen rounded p-2">
                  <Label style={{fontWeight:'bold'}}>{props.t("INFORMATIONS DU PARTICULIER")}</Label>
                </Card>
                <Card className="p-2 h-screen">
                  {assistance && assistance.requestor &&
                      <div>
                        <div >{assistance.requestor.profile && assistance.requestor.profile.first_name +" "+ assistance.requestor.profile.last_name }</div>
                        <div>{assistance.requestor.profile && assistance.requestor.profile.requestor_type}</div>
                        <div>{assistance.requestor.profile && assistance.requestor.profile.city +" , "+ assistance.requestor.profile.country }</div>
                        <div>{assistance.requestor.email}</div>
                      </div>
                  }
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="h-screen rounded p-2">
                  <Label style={{fontWeight:'bold'}}>{props.t("INTERVENANTS")}</Label>
                </Card>
                
                  {response && response.map((item, key) => (
                    <div key={key}>
                    <Card className=" p-2 h-screen">
                      <div >
                        <div>{item.responder && item.responder.profile && item.responder.profile.first_name + " " + item.responder.profile.last_name}</div>
                        <div>{item.responder && item.responder.email }</div>
                        <div>{item.responder && item.responder.profile && item.responder.profile.user_type}</div>
                      </div>
                      </Card>
                    </div>
                    ))}
              </Col>
            </Row>
          </div>   
          </Col>
        </Row>
      </Container>
    </div>
  );
};
QuestionsDetails.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(QuestionsDetails));

