import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { 
  Card,
  CardBody,
  Col,
  Container,
  CardHearder,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  CardHeader,
 } from 'reactstrap';
 import * as moment from 'moment';
 import { Link } from "react-router-dom";
 import Select from "react-select";
 import classnames from "classnames";
 import Flatpickr from "react-flatpickr";
 import BreadCrumb
  from '../../Components/Common/BreadCrumb';
import TableContainer from '../../Components/Common/TableContainer';
import DeleteModal  from '../../Components/Common/DeleteModal';
import { isEmpty } from "lodash";
import axios from 'axios';
import { axiosIns } from '../../utils';

// import { ventesData } from  "../../common/data"

// Formik
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";

// redux
import { useSelector, useDispatch } from "react-redux";

import { useReducer } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { getContacts as onGetContacts } from "../../store/crm/action"; 

import Loader from '../../Components/Common/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ExportCSVModal from '../../Components/Common/ExportCSVModal';

const Abonnes = (props) => {

    const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;


    const [abonnes, setAbonnes] = useState([]);

  useEffect(() => {
    const fetchAbonnes = async () => {
      try {
        const { data } = await axiosIns.get('/administration/subscribers', {
            headers : {
                Authorization : "Bearer " + token
        }})
        console.log("abonnés",data);
        setAbonnes(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchAbonnes();
  }, []);

  const [abonnement, setAbonnement] = useState([]);

  useEffect(() => {
    const fetchAbonnement = async () => {
      try {
        const { data } = await axiosIns.get('/administration/subscriptions', {
            headers : {
                Authorization : "Bearer " + token
        }})
        console.log("abonnements", data);
        setAbonnement(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchAbonnement();
  }, []);

  const AbonnementAbo = (j)=> {
    for(let i=0 ; i<=abonnement.length ; i++){
      if( j === abonnement[i]?.abonne){
       return (abonnement[i]?.pricing) ;
      }
    }
  }

  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [venteList, setVenteList] = useState([]);
  const [vente, setVente] = useState(null);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedMulti, setSelectedMulti] = useState(null);
    const [factures, setFactures] = useState([]);
    useEffect(() => {
        const fetchFactures = async () => {
          try {
            const { data } = await axiosIns.get(`/factures/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            console.log("test2", data);

            const dateSysteme = new Date();
            const moisActuel = dateSysteme.getMonth();
            const facturesFiltrees = data.filter(facture => {
              const moisFacture = parseInt(facture.dateFacture.split("T")[0].slice(5, 7)) - 1;
              return moisFacture === moisActuel;
            });
            console.log("test3", facturesFiltrees);
            setFactures(facturesFiltrees);
          } catch (err) {
            console.log(err);
          }
        };
        fetchFactures();
        }, [token]);
      

  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [contactList, setContactList] = useState([]);

  const handleVenteClicks = () => {
    setVente("");
    setIsEdit(false);
    toggle();
  };

  const onClickDelete = (vente) => {
    setVente(vente);
    setDeleteModal(true);
  }

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".productCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }

  function handleMulti(ventes, selectedMulti) {
    setSelectedMulti(selectedMulti);
  }

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setVente(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const [dele, setDele] = useState(0);

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let h = (d.getHours() % 12) || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
  };


  const [date, setDate] = useState(defaultdate());

  const dateformate = (e) => {
    const dateString = e.toString().split(" ");

    let time = dateString[4];
    let H = +time.substr(0, 2);
    let h = (H % 12) || 12;
    h = (h <= 9) ? h = ("0" + h) : h;
    let ampm = H < 12 ? "AM" : "PM";
    time = h + time.substr(2, 3) + " " + ampm;

    const date = dateString[2] + " " + dateString[1] + ", " + dateString[3];
    const venteDate = (date + ", " + time).toString();
    setDate(venteDate);

  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
    return updateTime;
  };

  const ConId = (j)=> {
    for(let i=0;i<=contactList.length; i++){
      if( j===contactList[i]?.nom){
       return (contactList[i]?.id) ;
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("No."),
        Cell: (abo)=> (
          <>
            <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.index + 1}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Abonnés"),
        accessor: "nom",
        Cell: (abo) => ( 
          <>
          <Link to={`/apps-admin-abonne-detail/${abo.row.original.id}/`} className="text-warning d-inline-block">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  { 
                    abo.row.original.first_name ?
                        abo.row.original.last_name ?
                            abo.row.original.last_name + " " + abo.row.original.first_name
                            :
                            abo.row.original.first_name
                        :
                    ""
                   } 
              </h5>
            </div>
          </div>
          </Link>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Adresse"),
        Cell: (abo) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.original.adresse ? abo.row.original.adresse : ""}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Téléphone"),
        Cell: (abo) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.original.telephone ? abo.row.original.telephone : ""}
              </h5>
            </div>
          </div>
          </>
        )
      },
        {
          Header: props.t("Pays"),
          Cell: (abo) => ( 
            <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                    {abo.row.original.pays ? abo.row.original.pays : ""}
                </h5>
              </div>
            </div>
            </>
          )
        },
      {
        Header: props.t("Société"),
        Cell: (abo) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.original.societe ? abo.row.original.societe : ""}
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: props.t("Fonction"),
        Cell: (abo) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.original.fonction ? abo.row.original.fonction : ""}
              </h5>
            </div>
          </div>
          </>
        )
      },
    ],
    []
  );

  document.title = props.t("Liste des abonnés | Le Conseiller du BTP");

  return (
    <div className='page-content'>
      <ToastContainer closeButton={false} />
        <Container fluid>
          <BreadCrumb title={props.t("Abonnés")} pageTitle={props.t("Liste des abonnés")} />
          <Row>
            <Col lg={12}>
                <Card id='orderList'>
                <CardBody className="pt-0">
                <div>
                    {
                        abonnes && abonnes.length > 0 ? 
                        (
                            <TableContainer
                                columns={columns}
                                data={(abonnes || [])}
                                isGlobalFilter={true}
                                isAddUserList={false}
                                customPageSize={25}
                                className="custom-header-css"
                                divClass="table-responsive table-card mb-3"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light"
                              />
                        )
                        :
                            (
                                <div className="py-4 text-center">
                                <div>
                                    <lord-icon
                                    src="https://cdn.lordicon.com/msoeawqm.json"
                                    trigger="loop"
                                    colors="primary:#405189,secondary:#0ab39c"
                                    style={{ width: "72px", height: "72px" }}
                                    ></lord-icon>
                                </div>

                                <div className="mt-4">
                                    <h5>{props.t("Sorry! No Result Found")}</h5>
                                </div>
                                </div>
                            )
                    }
                  
                </div>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </Container>
    </div>
  )

}

Abonnes.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default  withRouter(withTranslation()(Abonnes));
