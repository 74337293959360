import React, { useEffect, useState}from "react";
import { useSelector} from "react-redux";
import axios from 'axios';
import { isEmpty } from "lodash";
import { toast } from 'react-toastify';
import '@react-pdf-viewer/core/lib/styles/index.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  FormFeedback,
  Form,
  Label,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";
import {getProductsCategories as onGetCategories } from "../../store/ecommerce/action";


//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const AppelsdOffresUpdate=(props)=>{
  document.title = "Modifier un appel d'offre";
  const {categories } = useSelector((state) => ({
    categories :state.Ecommerce.categories
  
  }));


  const history = useHistory();
  const dispatch = useDispatch();
 
  const [categoryList,setCategoryList]= useState([{"id":null,"designation":""}]);
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const formData= new FormData();


  const recruitmentsId = useParams('id');
  const url = "https://backend.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
 

  const [selectRecrutements, setSelectRecrutements] = useState({})
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(`${url}/appel-offres/${recruitmentsId.id}/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setSelectRecrutements(response)
        } catch(error) {
          console.log(error)
        } 
    };
    checkTokenValidity();
  }, [url]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileIsEdit, setFileIsEdit] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileIsEdit(true)
  };

  const handleImageChange = (event) => {
    const image= event.target.files[0];
    setSelectedImage(image);
    setFileIsEdit(true)
  };

    const validation = useFormik({
      enableReinitialize: true,
      initialValues: {
        title: selectRecrutements && selectRecrutements.title,
        description: selectRecrutements && selectRecrutements.description,
        publication_link: selectRecrutements && selectRecrutements.publication_link,
        publication_date: selectRecrutements && selectRecrutements.publication_date,
        closure_date: selectRecrutements && selectRecrutements.closure_date,
        reference: selectRecrutements && selectRecrutements.reference,
        offer_file: selectRecrutements && selectRecrutements.offer_file,
        offer_pic: selectRecrutements && selectRecrutements.offer_pic,
        closed: false
      },
      validationSchema: Yup.object({
        title:Yup.string().required("Le champ est requis !"),
        description:Yup.string().required("Le champ est requis!"),
        publication_date:Yup.string().required("Le champ est requis"),
        closure_date:Yup.string().required("Le champ est requis")
       }),
      onSubmit: (values) => {
            formData.append("id",selectRecrutements.id);
            formData.append("title",values.title);
            formData.append("description",values.description);
            formData.append("publication_link",values.publication_link);
            formData.append("publication_date",values.publication_date);
            formData.append("closure_date",values.closure_date);
            formData.append("reference",values.reference);
            formData.append("closure_date",values.closure_date);
            selectedFile && formData.append("offer_file", selectedFile);
            selectedImage && formData.append("offer_pic", selectedImage);
              axios.patch(`${url}/appel-offres/${recruitmentsId.id}/`,formData)
              .then(res =>{
                if(res){
                  toast.success(props.t("Appel d'offre bien modifié"),{autoClose:3000})
                }
              }, (error) =>{
                if(error){
                  toast.error(props.t("Appel d'offre non modifié"),{autoClose:3000})
                }}
              )
              validation.resetForm();
              history.push("/apps-appels-offres");
              setTimeout(()=>window.location.reload(true),2000); 
        } 
    });
    return (
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={props.t("Modification d'une offre d'emploi")} pageTitle={props.t("Offre d'emploi")} />
          <Form encType="multipart/form-data"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit(); 
                }}
                >
              <Row>
        <Col lg={12}>
            <Card className="p-4">
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                            <Label className="form-label" htmlFor="photo">
                              {props.t("IMAGE")} <span style={{color: "red"}}>* Tous les champs sont obligatoires</span>
                            </Label>
                                <div>
                                      <div>
                                        <input name="offer_pic"
                                          className="form-control"
                                          type="file"
                                          accept="image/jpeg"
                                          capture="file"
                                          onChange={handleImageChange}/>
                                      </div>
                                      <div className="pt-4">
                                      {selectedImage && fileIsEdit ?(
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <img src={URL.createObjectURL(selectedImage)}  style={{ maxWidth: '50%'}} />
                                        </div>
                                      ):
                                     ( <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <img src={selectRecrutements && selectRecrutements.offer_pic} alt="Aperçu" style={{ maxWidth: '50%' }} />
                                      </div>)
                                    }
                                      </div>
                                </div>
                            {validation.errors.offer_pic && validation.touched.offer_pic? (
                              <FormFeedback type="invalid">{props.t(validation.errors.pic)}</FormFeedback>
                            ) : null}
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mb-3">
                      <Label className="form-label" htmlFor="fichier">
                        {props.t("FICHIER")} <span style={{color: "red"}}>*</span>
                      </Label>
                        <div>
                              <div>
                                <input name="offer_file"
                                  className="form-control"
                                  type="file"
                                  accept=".pdf"
                                  capture="file"
                                  onChange={handleFileChange}/>
                              </div>
                              <div>

                              {selectedFile && fileIsEdit ?(
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <a className="text-xs rounded" href={selectedFile} title="Télécharger"><i style={{fontSize: '100px',width:"50%",height:"50%", color:"red" }} className="ri-file-download-fill align-bottom"></i></a>
                                </div>
                              ):  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <a className="text-xs rounded" href={selectRecrutements && selectRecrutements.offer_file} title="Télécharger"><i style={{fontSize: '100px',width:"50%",height:"50%", color:"red" }} className="ri-file-download-fill align-bottom"></i></a>
                                  </div>
                              
                              }
                              </div>
                        </div>
                    {validation.errors.offer_file && validation.touched.offer_file? (
                      <FormFeedback type="invalid">{props.t(validation.errors.offer_file)}</FormFeedback>
                    ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                  <Col xl={6}>
                      <div className="mb-3">
                      <Label className="form-label" htmlFor="reference">
                        {props.t("REFERENCE")} <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form"
                        placeholder={props.t("Entrer la reference de l'offre")}
                        name="reference"
                        value={validation.values.reference}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                      />
                      </div>
                    </Col>
                    <Col xl={6}>
                        <div className="mb-3">
                        <Label className="form-label" htmlFor="title">
                          {props.t("TITRE DE L'OFFRE")}  <span style={{color: "red"}}>*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form"
                          name="title"
                          value={validation.values.title}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={validation.errors.title && validation.touched.title ? true : false}
                        />
                        {validation.errors.title && validation.touched.title? (
                          <FormFeedback type="invalid">{props.t(validation.errors.title)}</FormFeedback>
                        ) : null}
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="category">
                          {props.t("DATE DE PUBLICATION")}  <span style={{color: "red"}}>*</span>
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          name="publication_date"
                          value={validation.values.publication_date}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={validation.errors.publication_date && validation.touched.publication_date ? true : false}
                        />
                        {validation.errors.publication_date && validation.touched.publication_date? (
                          <FormFeedback type="invalid">{props.t(validation.errors.publication_date)}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mb-3">
                      <Label className="form-label" htmlFor="">
                        {props.t("DATE D'ECHEANCE")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        name="closure_date"
                        min={validation.values.publication_date}
                        value={validation.values.closure_date}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.closure_date && validation.touched.closure_date ? true : false}
                      />
                      {validation.errors.closure_date && validation.touched.closure_date? (
                        <FormFeedback type="invalid">{props.t(validation.errors.closure_date)}</FormFeedback>
                      ) : null}
                      </div>
                    </Col>
                  </Row>
                 
                  <div className="mb-3">
                      <Label className="form-label" htmlFor="description"> {props.t("DESCRIPTION")}  <span style={{color: "red"}}>*</span></Label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={validation.values.description}
                        onChange={validation.handleChange}
                        rows={4} // Nombre de lignes
                        cols={50} // Nombre de colonnes
                        placeholder="Saisissez votre texte ici..."
                        onBlur={validation.handleBlur}
                      ></textarea>
                      
                      {validation.errors.description && validation.touched.description? (
                        <FormFeedback type="invalid">{props.t(validation.errors.description)}</FormFeedback>
                      ) : null}
                  </div>
                    
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="publication_link">
                      {props.t("LIEN")}  <span style={{color: "red", borderTop:'10px'}}>* (Format du lien : https://www.example.com)</span>
                    </Label>
                    <Input
                      type="url"
                      className="form"
                      placeholder={props.t("Entrer l'url de l'article")}
                      name="publication_link"
                      value={validation.values.publication_link}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={validation.errors.publication_link && validation.touched.publication_link ? true : false}
                    />
                    {validation.errors.title && validation.touched.publication_link? (
                      <FormFeedback type="invalid">{props.t(validation.errors.publication_link)}</FormFeedback>
                    ) : null}
                  </div>
            </Card>
           <div className="text-end mb-3">
           <button className="btn btn-primary mr-3">
                  <Link
                    to={`/apps-appels-offres`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
              <button type="submit" className="btn btn-warning m-3">
                 {props.t("Modifier")}
              </button>
           </div> 
        </Col>
      </Row>
     </Form>
        </Container>
      </div>
    );
};

AppelsdOffresUpdate.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(AppelsdOffresUpdate));
