import React, { useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

//Simple bar

import BreadCrumb from "../../Components/Common/BreadCrumb";

import classnames from "classnames";
import { useSelector} from "react-redux";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import axios from 'axios';

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { Link,useHistory, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";

  
SwiperCore.use([FreeMode, Navigation, Thumbs]);


function OpportunitesDetails(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {categories } = useSelector((state) => ({
    categories :state.Ecommerce.categories
  
  }));
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;


  const opportunitesId = useParams('id');
  const url = "https://back.crm.inawo.pro/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const [selectContact, setSelectContact] = useState([])
  const [selectCollaborateur, setSelectCollaborateur] = useState([])
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/contacts`);
          setSelectContact(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/collaborateurs`);
          setSelectCollaborateur(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);
  const [selectOpportunites, setSelectOpportunites] = useState([
    {
      "type": null,
      "title": "",
      "value": null,
      "date": null,
      "description": "",
      "owner": null,
      "contact": null,
    }
  ])
 
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/opportunities/${opportunitesId.id}/`);
          setSelectOpportunites(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [opportunitesId, url]);
   console.log("opportunites",selectOpportunites.contact)

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const contact=(j)=>{
  for(let i=1; i <= selectContact.length;i++){
     if(j=== selectContact[i].id){
       return selectContact[i].nom
     }
    }
  }

  const collaborateur=(j)=>{
    for(let i=1; i<=selectCollaborateur.length;i++){
       if(j=== selectCollaborateur[i].id){
          return selectCollaborateur[i].nom
       }
       else return ""
      }
    }


document.title =props.t("Détail de l'opportunité");
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title={props.t("Détail de l'opportunité")} pageTitle={props.t("Opportunités")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={12}>
                    <div className="mt-xl-0 mt-5">
                     
                      <div className="product-content mt-2">
                        <h5 className="fs-14 mb-3">{props.t("Informations de l'opportunité")}</h5>
                        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                             { props.t("Opportunité")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2");
                              }}
                            >
                              {props.t("Détails de l'opportunité")}
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                        activeTab={customActiveTab}
                          className="border border-top-0 p-4"
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="1"
                          >
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row" style={{ width: "200px" }}>
                                      {props.t("Libellé")}
                                    </th>
                                    <td> {selectOpportunites.title}</td>
                                  </tr>
                                  <br></br>
                                  <tr>
                                  
                                    <th scope="row"> {props.t("Date ")} </th>
                                    <td>{selectOpportunites.date}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                          <TabPane
                            id="nav-detail"
                            tabId="2"
                          >
                             <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                <tr>
                                    <th scope="row"> {props.t("Responsable")} </th>
                                    <td>{collaborateur(selectOpportunites.owner)}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Contact")} </th>
                                    <td>{contact(selectOpportunites.contact)}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("description")} </th>
                                    <td>{selectOpportunites.description}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                           
                          </TabPane>
                        </TabContent>
                      </div>

                      
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

OpportunitesDetails.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(OpportunitesDetails));