import {
    
    GET_DOCUMENTS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

    ADD_DOCUMENT,
    ADD_DOCUMENT_SUCCESS,
    ADD_DOCUMENT_FAIL,

    UPDATE_DOCUMENT,
    UPDATE_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_FAIL,

    DELETE_DOCUMENT,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAIL

} from './actionType'

// common success
export const documentApiResponseSuccess = (actionType, response) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, response }
})

//common error
export const documentApiResponseError =(actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error }
})

export const getDocuments =() => ({
    type: GET_DOCUMENTS,
})

export const addNewDocument = document => ({
    type: ADD_DOCUMENT,
    payload: document,
})

export const addNewDocumentSuccess = document => ({
    type: ADD_DOCUMENT_SUCCESS,
    payload: document,
})

export const addNewDocumentFail = error => ({
    type: ADD_DOCUMENT_FAIL,
    payload: error,
})

export const updateDocument = document => ({
    type: UPDATE_DOCUMENT,
    payload: document,
})

export const updateDocumentSuccess = document => ({
    type: UPDATE_DOCUMENT_SUCCESS,
    payload: document,
})

export const updateDocumentFail = error => ({
    type: UPDATE_DOCUMENT_FAIL,
    payload: error,
})

export const deleteDocument = document => ({
    type: DELETE_DOCUMENT,
    payload: document,
})

export const deleteDocumentSuccess = document => ({
    type: DELETE_DOCUMENT_SUCCESS,
    payload: document,
})

export const deleteDocumentFail = error => ({
    type: DELETE_DOCUMENT_FAIL,
    payload: error,
})