import {

    GET_DOCUMENTS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

    ADD_DOCUMENT,
    ADD_DOCUMENT_SUCCESS,
    ADD_DOCUMENT_FAIL,

    UPDATE_DOCUMENT,
    UPDATE_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_FAIL,

    DELETE_DOCUMENT,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAIL

} from './actionType'

const INIT_STATE = {
    documents: [],
}

const Document = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_DOCUMENTS:
                    return {
                        ...state,
                        documents: action.payload.response,
                    };
                case ADD_DOCUMENT:
                    return {
                        ...state,
                        documents: [...state.documents, action.payload.response],
                    };
                case DELETE_DOCUMENT:
                    return {
                        ...state,
                        documents: state.documents.filter(
                            document => (document.id + '') !== (action.payload.response.id + '')
                            ),
                    };
                    case UPDATE_DOCUMENT:
                        return {
                            ...state,
                            documents: state.documents.map(
                                document => (document.id + '') === (action.payload.response.id + '')
                                    ? action.payload.response
                                    : document
                            ),
                        };
                default: 
                return { ...state };    
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_DOCUMENTS:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                case DELETE_DOCUMENT:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                default: 
                return { ...state };    
            }
        case GET_DOCUMENTS:
            return {
                ...state,
            }
        case ADD_DOCUMENT:
            return {
                ...state,
                document: [...state.documents, action.payload],
            }
        case ADD_DOCUMENT_SUCCESS:
                return {
                    ...state,
                    document: [...state.documents, action.payload.response],
            }
        case ADD_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload.error,
            }
        case UPDATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                document: state.documents.map(document => 
                    document.id.toString() === action.payload.id.toString()
                        ? {...document, ...action.payload.data}
                        : document
                ),
            }
        case UPDATE_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload.error,
            }
        case DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                document: state.documents.filter(
                    document => document.id.toString() !== action.payload.document.toString()),
            }
        case DELETE_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload.error,
            }
        default:
            return { ...state };
    }
}

export default Document;