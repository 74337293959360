import React from 'react';
import { Col, Container, Row } from 'reactstrap';

//import Components
import Widget from '../DashboardAnalytics/Widget';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import LiveUsers from '../DashboardAnalytics/LiveUsers';
import MyTasks from '../DashboardCrm/MyTasks';
const Dashboard = () => {
document.title="Le Conseiller du BTP | Tableau de bord";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Tableau de bord" pageTitle="Tableau de bord"/>
                    <Row>
                        <Widget />   
                    </Row>
                    
                    <Row xl={12}>
                    
                    </Row>
                    <Row>

                    <MyTasks />
               
                 
                       
                    </Row>

                    <Col>
                        <LiveUsers />
                    </Col>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
