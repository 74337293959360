import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  addNewPays as addNewPaysApi,
  deletePays as deletePaysApi,
  getPays as getPaysApi,
  updatePays as updatePaysApi,
} from "../../../helpers/fakebackend_helper";
import {
  addNewPaysFail,
  addNewPaysSuccess,
  deletePaysFail,
  deletePaysSuccess,
  paysApiResponseError,
  paysApiResponseSuccess,
  updatePaysFail,
  updatePaysSuccess,
} from "./action";
import { ADD_NEW_PAYS, DELETE_PAYS, GET_PAYS, UPDATE_PAYS } from "./actionType";

function* getPays() {
  try {
    const response = yield call(getPaysApi);
    yield put(paysApiResponseSuccess(GET_PAYS, response));
  } catch (error) {
    yield put(paysApiResponseError(GET_PAYS, error));
  }
}

function* addNewPays(action) {
  try {
    const response = yield call(addNewPaysApi, action.payload);
    yield put(addNewPaysSuccess(response));
    toast.success("Pays ajouté avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(addNewPaysFail(error));
    toast.error("Erreur lors de l'ajout du pays", { autoclose: 3000 });
  }
}

function* updatePays(action) {
  try {
    const response = yield call(updatePaysApi, action.payload);
    yield put(updatePaysSuccess(response));
    toast.success("Pays modifié avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(updatePaysFail(error));
    toast.error("Erreur lors de la modification du pays", { autoclose: 3000 });
  }
}

function* deletePays(action) {
  try {
    const response = yield call(deletePaysApi, action.payload);
    yield put(deletePaysSuccess(response));
    toast.success("Pays supprimé avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(deletePaysFail(error));
    toast.error("Erreur lors de la suppression du pays", { autoclose: 3000 });
  }
}

export function* watchGetPays() {
  yield takeEvery(GET_PAYS, getPays);
}

export function* watchAddNewPays() {
  yield takeEvery(ADD_NEW_PAYS, addNewPays);
}

export function* watchUpdatePays() {
  yield takeEvery(UPDATE_PAYS, updatePays);
}

export function* watchDeletePays() {
  yield takeEvery(DELETE_PAYS, deletePays);
}

function* paysSaga() {
  yield all([
    fork(watchGetPays),
    fork(watchAddNewPays),
    fork(watchUpdatePays),
    fork(watchDeletePays),
  ]);
}

export default paysSaga;
