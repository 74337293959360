import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { object, string } from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { getPays, updateProvince } from "../../../store/actions";
import { axiosIns } from "../../../utils";

const UpdateProvince = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [province, setProvince] = useState({});
  const [pays, setPays] = useState([]);
  const { pays: paysList } = useSelector((state) => ({
    pays: state.Pays.pays,
  }));

  const handleGetProvince = async () => {
    try {
      const response = await axiosIns.get(`provinces/${id}/`);
      if (response.status === 200) {
        setProvince(response.data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getPays());
    handleGetProvince();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(paysList)) {
      setPays(paysList);
    }
  }, [paysList]);

  document.title = props.t(
    "Modification d'une province - Le Conseiller du BTP"
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: province?.name || "",
      country: province?.country || "",
    },

    validationSchema: object().shape({
      name: string().required(props.t("Veuillez entrer le nom de la province")),
      country: string().required(props.t("Veuillez choisir un pays")),
    }),

    onSubmit: (values) => {
      const updatedProvince = {
        ...province,
        name: values.name,
        country: values.country,
      };
      dispatch(updateProvince(updatedProvince));
      history.push("/apps-geolocalisation-province");
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Ajout d'une province")}
          pageTitle={props.t("Province")}
        />

        <Row>
          <Col lg={12}>
            <Form
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="country">
                      {props.t("Pays")}
                    </Label>
                    <Input
                      type="select"
                      className="form"
                      placeholder={props.t("Entrer le nom du pays")}
                      name="country"
                      value={validation.values.country}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    >
                      <option value="">{props.t("Choisir un pays")}</option>
                      {pays.map((pay) => (
                        <option key={pay.id} value={pay.id}>
                          {pay.name}
                        </option>
                      ))}
                    </Input>
                    {validation.errors.country && validation.touched.country ? (
                      <FormFeedback type="invalid">
                        {validation.errors.country}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="name">
                      {props.t("Nom de la province")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      placeholder={props.t("Entrer le nom de la province")}
                      name="name"
                      value={validation.values.name}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-3">
                  <Link
                    to={`/apps-geolocalisation-province`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm m-2">
                  {props.t("Valider")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

UpdateProvince.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UpdateProvince));
