import React, { useEffect, useId, useReducer, useState, useRef } from 'react';
import { Link, useHistory, withRouter, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CardBody, Row, Col, Card, Table, CardHeader, Container, Input, Label } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { getContacts as onGetContacts } from "../../../store/crm/action";
import { getFactures as onGetFactures } from "../../../store/factures/action";
import { getventes as onGetVentes } from "../../../store/ventes/action";
import { axiosIns } from '../../../utils';
import axios from 'axios';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import { isEmpty } from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import logoDark from "../../../assets/images/logo-dark.png";
import logoLight from "../../../assets/images/logo-light.png";

const FactureView = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { id }  = useParams();
  console.log("id", id);

  const { ventes, factures, crmcontacts } = useSelector((state) => ({
    ventes: state.Vente.ventes,
    factures: state.Facture.factures,
    crmcontacts: state.Crm.crmcontacts,
  }));

  const [contactList, setContactList] = useState([]);
  const [factureList, setFactureList] = useState([]);
  const [venteList, setVenteList] = useState([]);

  //Afficher les factures
  useEffect(() => {
    if (factures && !factures.length) {
      dispatch(onGetFactures());
    }
  }, [dispatch, factures]);

  useEffect(() => {
    setFactureList(factures);
  }, [factures]);
  console.log("factures_lib", factureList);

  useEffect(()=> {
    if (!isEmpty(factures))
    setFactureList(factures.filter((facture) => facture.id === parseInt(id)))
  }, [factures])
  console.log("test", factureList)

  //Afficher un contact 
  useEffect(() => {
    if (crmcontacts && !crmcontacts.length) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContactList(crmcontacts);
  }, [crmcontacts]);
  console.log("contacts", crmcontacts);

  useEffect(()=> {
    if (!isEmpty(crmcontacts))
    setContactList(crmcontacts)
  }, [crmcontacts])

  //Afficher les ventes
  useEffect(() => {
    if (ventes && !ventes.length) {
      dispatch(onGetVentes());
    }
  }, [dispatch, ventes]);

  useEffect(()=> {
    setVenteList(ventes);
  }, [ventes]);
  console.log("ventes", venteList);

  useEffect(()=> {
    if (!isEmpty(ventes))
      setVenteList(ventes);
  }, [ventes]);

  const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;

    const [entreprise,  setEntreprise] = useState({});
    useEffect(() => {
       const fetchEntreprise = async () => {
        try {
          const {data} = await axiosIns.get(`/entreprise/`, {
            headers: {
              Authorization : 'Bearer ' + token,
            },
          });
          console.log("first", data);
          setEntreprise(data);
        } catch (err) {
          console.log(err);
        }
       }
        fetchEntreprise();
    }, [token]);

    const datas = {
      'Entreprise' : entreprise.nom,
      'Numero Legal 1' : entreprise.num_enreg_legal1,
      'Numero Facture' : factureList[0]?.numFacture
    };
    
    const qrValue = JSON.stringify(datas);

    const contact = (j)=> {
      for(let i=0;i<=contactList.length; i++){
        if( j===contactList[i]?.id){
         return (
          contactList[i]?.category === "Entreprise" || contactList[i]?.category === "Institution" || contactList[i]?.category === "Association" || contactList[i]?.category === "Administration" || contactList[i]?.category === "ONG" || contactList[i]?.category === "Société" ?

          <div>
            <Label style={{textAlign: "justify"}}>
              {contactList[i]?.nomEntreprise} <br/>
              {contactList[i]?.num_enreg_legal1 ? 
              <div>
                {contactList[i]?.num_enreg_legal1} <br/>
              </div> : false }
              {contactList[i]?.num_enreg_legal2 ?
              <div>
                {contactList[i]?.num_enreg_legal2} <br/>
              </div> : false}
              {contactList[i]?.adresse ?
              <div>
                {contactList[i]?.adresse} <br/>
              </div> : false}
              {contactList[i]?.siteweb ? 
              <div>
                {contactList[i]?.siteweb} <br/>
              </div> : false}
              {contactList[i]?.email ? 
              <div>
                {contactList[i]?.email} <br/>
              </div> : false}
              {contactList[i]?.telephone ?
              <div>
                {contactList[i]?.telephone} <br/>
              </div> : false}
            </Label>
          </div>
          :
          <div>
            <Label style={{textAlign: "justify"}}>
              {contactList[i]?.nom ?
              <div>
                {contactList[i]?.nom} <br/>
              </div> : false}
              {contactList[i]?.prenom ? 
              <div>
                {contactList[i]?.prenom} <br/>
              </div> : false}
              {contactList[i]?.adresse ?
              <div>
                {contactList[i]?.adresse} <br/>
              </div> : false} 
              {contactList[i]?.telephone ?
              <div>
                {contactList[i]?.telephone} <br/>
              </div> : false}
              </Label>
          </div>
          ) ;
        }
      }
    }
    console.log("contact", contact(1));

    const venteNum = (j)=> {
      for(let i=0;i<=venteList.length; i++){
        if( j===venteList[i]?.id){
         return (venteList[i]?.numVente) ;
        }
      }
    }

    const [productslist, setProductslist] = useState([]);
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const { data } = await axiosIns.get(`/products/`, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          setProductslist(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchProducts();
    }, [token]);
    console.log("products", productslist);

    const productNam = (j)=> {
      for(let i=0;i<=productslist.length; i++){
        if( j===productslist[i]?.id){
         return (productslist[i]?.designationProd + " " + productslist[i]?.marqueProd) ;
        }
      }
    }

    const productPrice = (j)=> {
      for(let i=0;i<=productslist.length; i++){
        if( j===productslist[i]?.id){
         return (productslist[i]?.prixProd) ;
        }
      }
    }


    const [details, setDetails] = useState([]);
    useEffect(() => {
      if (!isEmpty(factureList))
      setDetails(factureList[0]?.factureDetails);
  }, [factureList]);
  console.log("details", details);
    
  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

 //Download the Invoice
 const ref = useRef(null);

  const handleDownload = () => {
    html2canvas(ref.current)
      .then(canvas => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgData = canvas.toDataURL('image/*');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 5;
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (imgWidth / canvas.width) * canvas.height;
        pdf.addImage(imgData, 'JPEG', margin, 10, imgWidth, imgHeight);
        pdf.save('Facture.pdf');
      });
  };

  document.title = props.t("Details Facture | Le Conseiller du BTP");

  const logo = `https://backend.crm.inawo.pro` + entreprise.logo

  return (
    <div className="page-content" id='html-to-pdf'>
      <Container fluid>
        <BreadCrumb title={props.t("Details Facture")} pageTitle={props.t("Factures")} />

        <Row className="justify-content-center">
          <Col xxl={9}>
            <div ref={ref} >
              <Card id="demo">
              <Row>
              <Col lg={12}>
                    <CardHeader className="border-bottom-dashed p-4">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          {/* <img
                              src={`https://backend.crm.inawo.pro` + entreprise.logo}
                              className="card-logo card-logo-dark"
                              alt="logo dark"
                              width={150}
                            /> */}
                          <div className="mt-sm-5 mt-4">
                            <h2 className="text-uppercase fw-semibold" style={{color:'#014C94'}}>
                            {entreprise && entreprise.nom}
                              {/* {props.t("Adresse")} */}
                            </h2>
                            <p className="text-muted mb-1" id="address-details" style={{fontSize : '16px'}}>
                              {entreprise && entreprise.num_enreg_legal1 === "" ? false : 
                              <div>
                                { props.t("Numéro légal 1") + " : " + entreprise.num_enreg_legal1}<br/>
                              </div>}
                            {entreprise && entreprise.num_enreg_legal2 === "" ? false : 
                            <div>
                              { props.t("Numéro légal 1") + " : " + entreprise.num_enreg_legal2}<br/>
                            </div>}<br /><br />
                            
                            {entreprise && entreprise.capitalSocial === null ? false : props.t("Capital Social") + " : " + entreprise.capitalSocial } <br />
                            
                            {entreprise && entreprise.adresse === "" ? false :
                            <div>
                              { "Adresse : " + entreprise.adresse}<br/>
                            </div>}
                            {entreprise && entreprise.siteweb  === "" ? false : 
                            <div>
                              { "Site web : " + entreprise.siteweb}<br/>
                            </div>}
                            {entreprise && entreprise.email === "" ? false :
                            <div>
                              { "Email : " + entreprise.email}<br/>
                            </div>}
                            {entreprise && entreprise.telephone === "" ? false : 
                            <div>
                              {"Tel : " + entreprise.telephone}<br/>
                            </div>
                            }
                            </p> 
                          </div>
                        </div>
                        <div className="flex-shrink-0 mt-sm-0 mt-3" >
                        {/* style={{ marginTop : '137%'  }} */}
                         
                          <div style={{ marginTop : '60%'  }}>
                          <h2 className="text-uppercase fw-semibold" style={{color:'black'}}>
                            {/* {entreprise && entreprise.nom} */}
                              {props.t("client")}
                            </h2>
                         <p style={{fontSize : '16px' }}>{contact && contact(factureList[0]?.contact)}</p> 
                          </div>
                            
                        </div>
                      </div>
                    </CardHeader>
                  </Col>
                <div className="table-responsive">
                <div className='container-fluid d-flex fw-semibold border-bottom border-bottom-dashed p-2' style={{ backgroundColor: '#014C94', display : 'flex', alignItems: 'center', justifyContent:'center' }}>
                      <h4 className='fw-semibold' style={{fontSize :"23px", color:'white'}}>
                        {props.t("FACTURE")}
                        </h4>
                    </div>
                </div>
                <Col lg={12}>
                  <CardBody className="p-4">
                    <Row className="g-3">
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          {props.t("Facture No.")}
                        </p>
                        <h5 className="fs-14 mb-0"><span id="invoice-no">{factureList && factureList[0]?.numFacture}</span></h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          {props.t("Vente No.")}
                        </p>
                        <h5 className="fs-14 mb-0"><span id="invoice-no">{venteNum(factureList[0]?.vente)}</span></h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          {props.t("Date")}
                        </p>
                        <p> {factureList && moment(factureList[0]?.dateFacture).format('YYYY-MM-DD')} </p>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          {props.t("Statut de Paiement")}
                        </p>
                        {factureList && factureList[0]?.statusPaiement === "Payé" ?
                        <span className="badge badge-soft-success fs-11" id="payment-status">{props.t("Payé")}</span> : factureList[0]?.statusPaiement === "Non Payé" ?
                        <span className="badge badge-soft-danger fs-11" id="payment-status">{props.t("Non Payé")}</span> : <span className="badge badge-soft-secondary fs-11" id="payment-status">{props.t("En Cours")}</span>
                        }
                      </Col>

                    </Row>
                  </CardBody>
                </Col>
                <Col lg={12}>
                  <CardBody className="p-4">

                    <div className="table-responsive">
                      <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                        <thead>
                        <tr className=""  style={{ backgroundColor: '#014C94', color:'white', height:'48px', fontSize:'14px' }}>
                            <th scope="col" style={{ width: "50px" }}>
                              #
                            </th>
                            <th scope="col">{props.t("Désignation Produit")}</th>
                            <th scope="col">{props.t("Prix")}</th>
                            <th scope="col">{props.t("Quantité")}</th>
                            <th scope="col" className="text-end">
                              {props.t("Montant")}
                            </th>
                          </tr>
                        </thead>
                        {details.map((detail, index) => (
                           <tbody key={index} style={{ backgroundColor: 'white', borderBottomWidth: '100px', borderBottom: '2px solid black' }}>
                              <th scope="row">{index + 1}</th>
                            <td className="text-start">
                              <span className="fw-medium"><br />
                                {productNam(detail.produit)}
                              </span>
                              <p className="text-muted mb-0">
                                {detail.description}
                              </p>
                            </td>
                            <td>{productPrice(detail.produit)}</td>
                            <td>{detail.qte}</td>
                            <td>{productPrice(detail.produit)*detail.qte}</td>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                    <div className="" style={{ backgroundColor: 'white' }}>
                      <Table className="table table-borderless table-nowrap align-middle mb-0 ms-auto" style={{ width: "250px", backgroundColor:"white" }}>
                            <tbody style={{ backgroundColor: '#dddddd' }}>
                              <tr style={{ color:'#014C94', fontWeight: 'bold' }}>
                                <td>{props.t("Total brut")} :</td>
                                <td className="text-end">{details.map((item) => productPrice(item.produit) * item.qte).reduce((a, b) => a + b, 0)}</td>
                              </tr>
                              <tr style={{ fontWeight: 'bold' }}>
                                <td>{props.t("Taxe Estimée")} :</td>
                                <td className="text-end">{(factureList[0]?.taxe/100)*details.map((item) => productPrice(item.produit) * item.qte).reduce((a, b) => a + b, 0)}</td>
                              </tr>
                              <tr style={{ fontWeight: 'bold' }}>
                                <td>{props.t("Remise")}</td>
                                <td className="text-end">{(factureList[0]?.remise/100)*details.map((item) => productPrice(item.produit) * item.qte).reduce((a, b) => a + b, 0)}</td>
                              </tr>
                              {/* border-top border-top-dashed */}
                              <tr className="fs-15" style={{ backgroundColor: '#014C94', color:'white', padding : '5px' }}>
                                <th scope="row">{props.t("Net à payer")} :</th>
                                <th className="text-end">{factureList[0]?.montant + " F CFA" }</th>
                              </tr> 
                            </tbody>
                          </Table>
                      </div>
                      <div className='mt-4'>
                        <div
                              name="comment"
                              className="form-control alert"
                              id="exampleFormControlTextarea1"
                              style={{ padding: '10px', color:"black", backgroundColor: '#dddddd', textAlign: 'right', display: 'flex', alignItems: 'center' }}
                            >
                              <div>
                                <QRCodeSVG 
                                 value={qrValue}
                                 bgColor='#dddddd'
                                 size={90}
                                />
                              </div>
                              

                              <p style={{marginLeft : '40%', fontSize : '16px' }}>
                                  {props.t(factureList[0]?.comment)}
                                </p>
                            </div>
                      </div>
                  </CardBody>
                </Col>
              </Row>
            </Card>
            </div>
            <Card id="orderList">
                <CardHeader className='card-header border-0'>
                    <div className="d-flex.align-items-center hstack gap-2 float-end d-print-none">
                          <Link
                            to="#"
                            onClick={printInvoice}
                            className="btn btn-success"
                          >
                            <i className="ri-printer-line align-bottom me-1"></i> {props.t("Imprimer")}
                          </Link>
                          <Link to="#" onClick={handleDownload} className="btn btn-primary">
                            <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                            {props.t("télécharger")}
                          </Link>
                    </div>
                </CardHeader>
            </Card>
              
          </Col>
        </Row>
      </Container>
    </div >
  );
};

FactureView.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(FactureView));
