import React, { useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Form
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import classnames from "classnames";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import axios from 'axios';

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import {useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
  
SwiperCore.use([FreeMode, Navigation, Thumbs]);



function ArticlesDetail(props) {
  const articlesId = useParams('id');
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const history = useHistory()
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectAticle, setSelectArticle] = useState([])
  const url = "https://backend.leconseillerdubtp.com/api/v1";
  const [articleListApp, setArticleListApp] = useState([{title:"",comments:[{id:null, replies:[]}],likes_count:""}]);

  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(`${url}/categories/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setSelectCategory(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://backend.leconseillerdubtp.com/api/v1/article-list/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setArticleListApp(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(`${url}/articles/${articlesId.id}/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setSelectArticle(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  const categgory =(j)=>{
    for(let i=0;i<=selectCategory.length;i++){
      if(j===selectCategory[i]?.id){
        return selectCategory[i]?.designation;
      }
    }
  }

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues : {} ,
    onSubmit: () => {
            axios.patch(`${url}/articles/${articlesId.id}/validate/`)
            .then(res => {
            if(res){
              toast.success("Article validé avec succes",{autoClose:3000})
            }
          }, 
          error => {
            if(error){
              toast.error("Article non validé ",{autoClose:3000})
            }
          }
          )
          validation.resetForm();
          history.push("/apps-articles");
          setTimeout(()=>window.location.reload(true),3000); 
      } 
         
  });
document.title =props.t("Détail d'un article'");
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title={props.t("Détail d'un article")} pageTitle={props.t("Article")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  
                  <Col xl={12}>
                  <div className="mt-xl-0 mt-5">
                    <Row>
                      <Col lg={6} >
                          <img
                                  src={selectAticle.article_pic}
                                  className="rounded" style={{width:'100%'}}
                                  alt="Article Picture"
                              />
                          
                      </Col>
                      <Col lg={6}  >
                        <div className="product-content ">
                            <Nav tabs className="nav-tabs-custom nav-warning">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleCustom("1");
                                  }}
                                >
                                { props.t("Détails")}
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <TabContent
                            activeTab={customActiveTab}
                              className="border border-top-0 p-4"
                              id="nav-tabContent"
                            >
                              <TabPane
                                id="nav-speci"
                                tabId="1"
                              >
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <tbody>
                                      <tr>
                                        <th scope="row"> {props.t("Statut")}</th>
                                        <td className="text-blue-500" style={{color:"blue"}}>{!selectAticle.validated ?
                                          <div className="fs-12 uppercase badge badge-soft-danger" 
                                            style={{textAlign:'center',fontSize:'13px'}}> En attente
                                          </div> : <div 
                                            className="fs-12 uppercase badge badge-soft-success" 
                                            style={{textAlign:'center',fontSize:'13px'}}> Validé
                                            </div>
                                        }</td>
                                      </tr>
                                      <tr>
                                        <th scope="row"> {props.t("Validé par")} </th>
                                        <td className="text-red-500" style={{fontStyle:'italic'}}>{selectAticle.validated_by ? selectAticle.validated_by.username : 'Non rensigné'}</td>
                                      </tr>

                                      <tr>
                                        <th scope="row"> {props.t("Auteur")} </th>
                                        <td className="text-red-500" style={{fontStyle:'italic'}}>{selectAticle.author && selectAticle.author.username}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                      </Col>
                    </Row>
                      <div className="d-flex">
                        <div className="flex-wrap gap-3">
                          <br></br>
                            <h2> {selectAticle.title}</h2>
                            
                            <span className="text-xl fw-medium badge-soft-warning me-1">
                              {categgory(selectAticle.category)}
                          </span>
                          </div>
                      </div>
                      <div className="mt-4 text-muted" style={{textAlign:'justify'}}>
                        <p>
                          {selectAticle?.paragraphs  && selectAticle?.paragraphs.map((item,key) => {
                            <React.Fragment key={key}>
                               <p> {item.paragraph_pic} </p>
                               <p> {item.subtitle} </p>
                               <p> {item.content} </p>
                            </React.Fragment>
                          })}
                        </p>
                      </div>
                  </div>
                  </Col>
                </Row>
                {
                   !selectAticle.validated ?
                   <Form encType="multipart/form-data"
                   onSubmit={(e) => {
                       e.preventDefault();
                       validation.handleSubmit(); 
                   }}
                   >
                   <div className="text-end mb-3">
                       <button className="btn btn-primary mr-6">
                       <Link
                           to={`/apps-articles`}
                           className="text-white"
                       >
                           {props.t("Retour")}
                       </Link>
                       </button>
                       <button type="submit" className="btn btn-warning m-2">
                       {props.t("Valider l'article")}
                       </button>
                   </div>
               </Form>
                : <div className="text-end mb-3">
                <button className="btn btn-warning mr-6">
                <Link
                    to={`/apps-articles`}
                    className="text-white"
                >
                    {props.t("Retour")}
                </Link>
                </button>
               
            </div>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

ArticlesDetail.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(ArticlesDetail));