import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_ADMIN, LOGOUT_ADMIN, SOCIAL_LOGIN } from "./actionTypes";
import { apiAdminError, loginAdminSuccess, logoutAdminSuccess } from "./actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postJwtLoginAdmin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginAdmin({ payload: { admin, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginAdmin,
        admin.email,
        admin.password
      );
      yield put(loginAdminSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const currentUrl = window.location.href;
      try {
        const response = yield call(postJwtLoginAdmin, {
          email: admin.email,
          password: admin.password,
        });
        sessionStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginAdminSuccess(response));
        history.push("/dashboard");
      } catch (error) {
        if (currentUrl === "https://app.inawo.pro/fr/connexion") {
          toast.error("Email ou mot de passe incorrect")
        } else {
          toast.error("Incorrect email or password")
        }
        
      }

    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postJwtLoginAdmin, {
        email: admin.email,
        password: admin.password,
      });
      sessionStorage.setItem("authUser", JSON.stringify(response));
      if (response.status === "success") {
        yield put(loginAdminSuccess(response));
        history.push("/dashboard");
      } else {
        yield put(apiAdminError(response));
      }
    }
    
  } catch (error) {
    yield put(apiAdminError(error));
  }
}

function* logoutAdmin() {
  try {
    sessionStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutAdminSuccess(LOGOUT_ADMIN, response));
    } else {
      yield put(logoutAdminSuccess(LOGOUT_ADMIN, true));
    }
  } catch (error) {
    yield put(apiAdminError(LOGOUT_ADMIN, error));
  }
}

function* authSagaAdmin() {
  yield takeEvery(LOGIN_ADMIN, loginAdmin);
  yield takeEvery(LOGOUT_ADMIN, logoutAdmin);
}

export default authSagaAdmin;
