import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Row, Form, Label, Input, FormFeedback } from 'reactstrap';
import ParticlesAuth from '../ParticlesAuth';
// import logoInawoCRMBlanc2 from "../../assets/images/logoInawoCRMBlanc2.png";
//redux
import { useSelector, useDispatch } from "react-redux";
// action
import { userForgetPassword, apiError } from "../../../store/actions";
//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from "../../../assets/images/lcbtp.png";




const BasicPasswReset = () => {
    document.title="Réinitialisation de Mot de passe | Le Conseiller du BTP";
    const history = useHistory();
    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Veuillez entrer votre adresse électronique")
            // .matches("Veuillez inclure un @ dans l'adresse électronique"),
        }),
        onSubmit: (values) => {
            const user = {
                email: values.email
            }
            console.log(user);
            dispatch(userForgetPassword(user));
            localStorage.setItem("langreset", "fr");
            setTimeout(() => history.push("/auth-twostep-basic"), 3000);
        }
    });

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    return (
        <ParticlesAuth>
            <div className="auth-page-content">                
                <Container>
                <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logo} width="32%" alt=""  />
                                        </Link>
                                    </div>
                                    {/* <p className="mt-1 fs-15 fw-medium" style={{color:'#FF6600'}}>Le Conseiller du BTP</p> */}
                                </div>
                            </Col>
                        </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 style={{color:"#FF6600"}}>Mot de passe oublié ?</h5> 
                                    </div>
                                    <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                                        Saisissez votre adresse électronique et les instructions vous seront envoyées !
                                    </Alert>
                                    <div className="p-2">
                                        <Form onSubmit={validation.handleSubmit} action="#">
                                            <div className="mb-4">
                                                <Label className="form-label">Email</Label>
                                                <Input 
                                                type="email" 
                                                className="form-control" 
                                                id="email"
                                                placeholder="Entrez l'adresse e-mail" 
                                                name="email"
                                                value={validation.values.email}
                                                onBlur={validation.handleBlur}
                                                onChange={validation.handleChange}
                                                invalid={validation.errors.email && validation.touched.email ? true : false}
                                                />
                                                {validation.errors.email && validation.touched.email ? (
                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                ): null}
                                            </div>

                                            <div className="text-center mt-4" >
                                                <button className="btn btn-warning w-100" style={{ height: "40px", backgroundColor:'#FF6600', border:'#FF6600'}}type="submit">Envoyer le lien de réinitialisation</button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-4 text-center">
                                <p className="mb-0" style={{color:'white'}}>Je me souviens de mon mot de passe... <Link to="/fr/connexion" className="fw-bold text-primary text-decoration-underline"> Cliquer ici </Link> </p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default BasicPasswReset;