import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from "../../helpers/jwt-token-access/auth-token-header";

// Ecoomerce Redux States
import {
  GET_PRODUCTS,
  GET_SERVICES,
  GET_PRODUCTS_CATEGORIES,
  DELETE_PRODUCT,
  DELETE_PRODUCTS_CATEGORIES,
  GET_ORDERS,
  GET_SELLERS,
  GET_CUSTOMERS,
  ADD_PRODUCT_SUCCESS,
  GET_TYPES,
  ADD_TYPES,
  UPDATE_TYPES,
  ADD_TYPES_SUCCESS,
  ADD_TYPES_FAIL,
  UPDATE_TYPES_FAIL,
  UPDATE_TYPES_SUCCESS,
  GET_METIERS,

  DELETE_ORDER,
  UPDATE_ORDER,
  ADD_NEW_ORDER,
  ADD_SERVICES,
  ADD_SERVICES_SUCCESS,
  ADD_SERVICES_FAIL,

  ADD_NEW_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_SERVICES,
  UPDATE_SERVICES_SUCCESS,
  UPDATE_SERVICES_FAIL,

  ADD_NEW_PRODUCT,
  UPDATE_PRODUCT,
  ADD_PRODUCT_FAIL,
  ADD_PRODUCTS_CATEGORIES,
  UPDATE_PRODUCTS_CATEGORIES,
  UPDATE_PRODUCTS_CATEGORIES_SUCCESS,
  UPDATE_PRODUCTS_CATEGORIES_FAIL
} from "./actionType";

import {
  ecommerceApiResponseSuccess,
  ecommerceApiResponseError,
  deleteOrderSuccess,
  deleteOrderFail,
  updateOrderSuccess,
  updateOrderFail,
  addOrderSuccess,
  addOrderFail,
  addCustomerFail,
  addCustomerSuccess,
  updateCustomerSuccess,
  updateCustomerFail,
  deleteCustomerSuccess,
  deleteCustomerFail,
  deleteProductSuccess,
  deleteProductFail,
  addProductSuccess,
  addProductFail,
  updateProductSuccess,
  updateProductFail,
  addProductsCategoriesFail,
  addProductsCategoriesSuccess,
  // deleteProductsCategoriesSuccess,
  // deleteProductsCategoriesFail,
  updateProductsCategories,
  updateProductsCategoriesSuccess,
  updateProductsCategoriesFail,
  addServiceSuccess,
  addServiceFail,
  updateServiceSuccess,
  updateServiceFail,
  updateServices,
  addTypeSuccess,
  addTypeFail,
  updateTypeFail,
  getMetiers
  
} from "./action";

//Include Both Helper File with needed methods
import {
  getProducts as getProductsApi,
  getServices as getServicesApi,
  deleteProducts as deleteProductsApi,
  getOrders as getOrdersApi,
  getSellers as getSellersApi,
  getCustomers as getCustomersApi,
  getProductsCategories as getProductsCategoriesApi,
  getTypes as getTypesApi,
  updateOrder,
  updateService,
  updateType,
  addNewType,
  deleteOrder,
  addNewOrder,
  addNewService,
  addNewCustomer,
  updateCustomer,
  deleteCustomer,
  addNewProduct,
  updateProduct,
  addNewProductCategory,
  updateProductCategory 
} from "../../helpers/fakebackend_helper";
import { updateTaskSuccess } from "../actions";

function* getProducts() {
  try {
    const response = yield call(getProductsApi);
    yield put(ecommerceApiResponseSuccess(GET_PRODUCTS, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_PRODUCTS, error));
  }
}

function* getServices() {
  try {
    const response = yield call(getServicesApi);
    yield put(ecommerceApiResponseSuccess(GET_SERVICES, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_SERVICES, error));
  }
}

function* getTypes() {
  try {
    const response = yield call(getTypesApi);
    yield put(ecommerceApiResponseSuccess(GET_TYPES, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_TYPES, error));
  }
}

function* getProductsCategories (){
  try {
    const response = yield call(getProductsCategoriesApi);
    yield put(ecommerceApiResponseSuccess(GET_PRODUCTS_CATEGORIES, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_PRODUCTS_CATEGORIES, error));
  }
}

function* getOrders() {
  try {
    const response = yield call(getOrdersApi);
    yield put(ecommerceApiResponseSuccess(GET_ORDERS, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_ORDERS, error));
  }
}

function* getSellers() {
  try {
    const response = yield call(getSellersApi);
    yield put(ecommerceApiResponseSuccess(GET_SELLERS, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_SELLERS, error));
  }
}

function* getCustomers() {
  try {
    const response = yield call(getCustomersApi);
    yield put(ecommerceApiResponseSuccess(GET_CUSTOMERS, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_CUSTOMERS, error));
  }
}

function* deleteProducts({ payload: product }) {
  try {
    const response = yield call(deleteProductsApi, product);
    yield put(deleteProductSuccess({ product, ...response }));
    toast.success("Product Delete Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(deleteProductFail(error));
    toast.error("Product Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewProduct({payload:product}) {
  try {
    const response = yield call(addNewProduct,product);
    yield put(addProductSuccess(response));
    // toast.success("Product Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addProductFail(error));
    // toast.error("Product Added Failed", { autoClose: 3000 });
  }
}

function* onAddNewType({payload:type}) {
  try {
    const response = yield call(addNewType,type);
    yield put(addTypeSuccess(response));
    toast.success("Type Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addTypeFail(error));
    toast.error("Type Added Failed", { autoClose: 3000 });
  }
}

function* onAddNewService({payload:service}) {
  try {
    const response = yield call(addNewService,service);
    yield put(addServiceSuccess(response));
    toast.success("Service Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addServiceFail(error));
    toast.error("Service Added Failed", { autoClose: 3000 });
  }
}


function* onAddNewProductCategory({payload:category}) {
  try {
    const response = yield call(addNewProductCategory,category);
    yield put(addProductsCategoriesSuccess(response));
    // toast.success("Categories Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addProductsCategoriesFail(error));
    // toast.error("Product Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateProduct({ payload: product }) {
  try {
    const response = yield call(updateProduct, product);
    yield put(updateProductSuccess(response));
    // toast.success("Product Updateded Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateProductFail(error));
    // toast.error("Product Updateded Failed", { autoClose: 3000 });
  }
}

function* onUpdateType({payload:type}){
  try{
    const response=yield call(updateType,type);
    yield put(updateTaskSuccess(response));
    toast.success("type bien modifié",{autoClose:3000});
  } catch(error){
    yield put(updateTypeFail(error));
    toast.error("type non ajouté",{autoclose:3000});
  }
}

function* onUpdateService({ payload: service }) {
  try {
    const response = yield call(updateService, service);
    yield put(updateServiceSuccess(response));
    toast.success("Service Updateded Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateServiceFail(error));
    toast.error("Service Updateded Failed", { autoClose: 3000 });
  }
}

function* onUpdateProductCategory({ payload: category }) {
  try {
    const response = yield call(updateProductCategory, category);
    yield put(updateProductsCategoriesSuccess(response));
    // toast.success("cat Updateded Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateProductsCategoriesFail(error));
    // toast.error("cat Updateded Failed", { autoClose: 3000 });
  }
}

function* onUpdateOrder({ payload: order }) {
  try {
    const response = yield call(updateOrder, order);
    yield put(updateOrderSuccess(response));
    toast.success("Order Updateded Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateOrderFail(error));
    toast.error("Order Updateded Failed", { autoClose: 3000 });
  }
}

function* onDeleteOrder({ payload: order }) {
  try {
    const response = yield call(deleteOrder, order);
    yield put(deleteOrderSuccess({ order, ...response }));
    toast.success("Order Deleted Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(deleteOrderFail(error));
    toast.error("Order Deleted Failed", { autoClose: 3000 });
  }
}

function* onAddNewOrder({ payload: order }) {
  try {
    const response = yield call(addNewOrder, order);
    yield put(addOrderSuccess(response));
    toast.success("Order Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addOrderFail(error));
    toast.error("Order Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateCustomer({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer);
    yield put(updateCustomerSuccess(response));
    toast.success("Customer Updateded Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateCustomerFail(error));
    toast.error("Customer Updateded Failed", { autoClose: 3000 });
  }
}

function* onDeleteCustomer({ payload: customer }) {
  try {
    const response = yield call(deleteCustomer, customer);
    yield put(deleteCustomerSuccess({ customer, ...response }));
    toast.success("Customer Deleted Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(deleteCustomerFail(error));
    toast.error("Customer Deleted Failed", { autoClose: 3000 });
  }
}

function* onAddNewCustomer({ payload: customer }) {
  try {
    const response = yield call(addNewCustomer, customer);
    yield put(addCustomerSuccess(response));
    toast.success("Customer Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addCustomerFail(error));
    toast.error("Customer Added Failed", { autoClose: 3000 });
  }
}

export function* watchGetProducts() {
  yield takeEvery(GET_PRODUCTS, getProducts);
}


export function* watchGetServices() {
  yield takeEvery(GET_SERVICES, getServices);
}

export function*watchAddType(){
  yield takeEvery (ADD_TYPES,onAddNewType);
}

export function*watchUpdateType(){
  yield takeEvery (UPDATE_TYPES,onUpdateType);
}

export function* watchGetTypes() {
  yield takeEvery(GET_TYPES, getTypes);
}

// PRODUCTS CATEGORIES
export function* watchGetProductsCategories() {
  yield takeEvery(GET_PRODUCTS_CATEGORIES, getProductsCategories);
}

export function* watchDeleteProducts() {
  yield takeEvery(DELETE_PRODUCT, deleteProducts);
}

export function* watchGetOrders() {
  yield takeEvery(GET_ORDERS, getOrders);
}

export function* watchGetSellers() {
  yield takeEvery(GET_SELLERS, getSellers);
}

export function* watchGetCustomers() {
  yield takeEvery(GET_CUSTOMERS, getCustomers);
}

export function* watchUpdateOrder() {
  yield takeEvery(UPDATE_ORDER, onUpdateOrder);
}

export function* watchUpdateService() {
  yield takeEvery(UPDATE_SERVICES, onUpdateService);
}

export function* watchUpdateProductsCategory(){
  yield takeEvery(UPDATE_PRODUCTS_CATEGORIES,onUpdateProductCategory);
}

export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, onDeleteOrder);
}

export function* watchAddNewOrder() {
  yield takeEvery(ADD_NEW_ORDER, onAddNewOrder);
}

export function* watchAddNewService() {
  yield takeEvery(ADD_SERVICES, onAddNewService);
}

export function* watchUpdateCustomer() {
  yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
}

export function* watchDeleteCustomer() {
  yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer);
}

export function* watchAddNewCustomer() {
  yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer);
}

export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct);
}

export function* watchAddNewProduct() {
  yield takeEvery(ADD_NEW_PRODUCT, onAddNewProduct);
}

export function* watchAddNewProductCategory() {
  yield takeEvery(ADD_PRODUCTS_CATEGORIES, onAddNewProductCategory);
}

function* ecommerceSaga() {
  yield all([
    fork(watchGetProducts),
    fork(watchGetTypes),
    fork(watchGetServices),
    fork(watchGetProductsCategories),
    fork(watchDeleteProducts),
    fork(watchGetOrders),
    fork(watchGetSellers),
    fork(watchGetCustomers),
    fork(watchDeleteOrder),
    fork(watchUpdateOrder),
    fork(watchAddNewOrder),
    fork(watchAddType),
    fork(watchDeleteCustomer),
    fork(watchUpdateCustomer),
    fork(watchAddNewCustomer),
    fork(watchUpdateProduct),
    fork(watchUpdateType),
    fork(watchUpdateService),
    fork(watchAddNewProduct),
    fork(watchAddNewService),
    fork(watchAddNewProductCategory),
    fork(watchUpdateProductsCategory)
  ]);
}


export default ecommerceSaga;
