import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, Offcanvas, OffcanvasBody, Row, UncontrolledDropdown, FormFeedback, ModalHeader } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import { axiosIns } from '../../utils';

// Rating
import Rating from "react-rating";

//User Images
import userdummy from '../../assets/images/users/user-dummy-img.jpg';

//Small Images
import smallImage9 from '../../assets/images/small/img-9.jpg';
//redux
import { useSelector, useDispatch } from 'react-redux';

//import action
// import {
//     getTeamData as onGetTeamData,
//     deleteTeamData as onDeleteTeamData,
//     addTeamData as onAddTeamData
// } from "../../store/actions";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

const AdminsDemos = (props) => {
    document.title = props.t("Administrateurs et Comptes Démos | Le Conseiller du BTP");

    const dispatch = useDispatch();
    const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

    const [abonne, setAbonne] = useState("")

    const [team, setTeam] = useState(null);

    useEffect(() => {
        const fetchTeam = async () => {
          try {
            const { data } = await axiosIns.get('/administration/users/list', {
                headers : {
                    Authorization : "Bearer " + token
            }})
            console.log("admin",data);
            setTeam(data.filter((item) => item.is_superuser === true ));
          } catch (err){
            console.log(err);
          }
        }
        fetchTeam();
      }, []);


    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        const list = document.querySelectorAll(".team-list");
        const buttonGroups = document.querySelectorAll('.filter-button');
        for (let i = 0; i < buttonGroups.length; i++) {
            buttonGroups[i].addEventListener('click', onButtonGroupClick);
        }

        function onButtonGroupClick(event) {
            if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
                document.getElementById("list-view-button").classList.add("active");
                document.getElementById("grid-view-button").classList.remove("active");
                list.forEach(function (el) {
                    el.classList.add("list-view-filter");
                    el.classList.remove("grid-view-filter");
                });

            } else {
                document.getElementById("grid-view-button").classList.add("active");
                document.getElementById("list-view-button").classList.remove("active");
                list.forEach(function (el) {
                    el.classList.remove("list-view-filter");
                    el.classList.add("grid-view-filter");
                });
            }
        }
    }, []);


    //Modal  
    const [modal, setModal] = useState(false);
    const openModal = () => setModal(!modal);

    //OffCanvas  
    const [isOpen, setIsOpen] = useState(false);
    const [sideBar, setSideBar] = useState([]);

    //Dropdown
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggledropDown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            first_name: "",
            last_name: "",
            password: "",
            cpassword: "",
            },
        validationSchema: Yup.object({
            email: Yup.string().required("Veuillez renseigner l'email"),
            first_name: Yup.string().required("Veuillez renseigner le ou les prénom(s)"),
            last_name: Yup.string().required("Veuillez renseigner le nom"),
            password: Yup.string()
                        .min(8, 'Le mot de passe doit comporter au moins 8 caractères')
                        .matches(RegExp('(.*[a-z].*)'), 'Au moins une lettre minuscule')
                        .matches(RegExp('(.*[A-Z].*)'), 'Au moins une lettre majuscule')
                        .matches(RegExp('(.*[0-9].*)'), 'Au moins un chiffre')
                        .required("Ce champ est obligatoire"),
            cpassword: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Le mot de passe de confirmation ne correspond pas"
                )
            }).required("Ce champ est obligatoire"),
        }),
        onSubmit: (values) => {
            const newTeamData = {
                    email: values.email,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    password: values.password,
            };
            console.log("newTeamData", newTeamData);
            // save new TeamData
            try {
                const { data } = axiosIns.post(`/administration/users/`, newTeamData
                );
                console.log("new", data);
                validation.resetForm();
                toast.success(props.t("Compte Administrateur ajouté avec succès"), { autoClose: 3000 });
                window.location.reload(true);
              } catch (err) {
                console.log(err);
                toast.error(props.t("Echec de l'ajout d'un compte administrateur"), { autoClose: 3000 });
              }
        },
    });
    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={props.t("Administrateurs")} pageTitle={props.t("Comptes")} />
                    <Card>
                        <CardBody>
                            <Row className="g-2">
                                <Col sm={4}>
                                </Col>
                                <Col className="col-sm-auto ms-auto">
                                    <div className="list-grid-nav hstack gap-1">

                                        <Button color="info" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button"><i className="ri-grid-fill"></i></Button>
                                        <Button color="info" id="list-view-button" className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button"><i className="ri-list-unordered"></i></Button>
                                         <Button color="info" onClick={openModal}>
                                                <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter des membres")}
                                            </Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row>
                        <Col lg={12}>
                            <div>
                                <Row className="team-list grid-view-filter">
                                    {(team || []).map((item, key) => (
                                        <Col key={key}>
                                            <Card className="team-box">
                                                <div className="team-cover">
                                                    <img src={item.backgroundImg || smallImage9} alt="" className="img-fluid" />
                                                </div>
                                                <CardBody className="p-4">
                                                    <Row className="align-items-center team-row">
                                                        <Col className="team-settings">
                                                            <Row>
                                                                <Col>
                                                                    <div className="bookmark-icon flex-shrink-0 me-2">
                                                                        <Input type="checkbox" id="favourite1" className="bookmark-input bookmark-hide" />
                                                                        <Label htmlFor="favourite1" className="btn-star">
                                                                            <svg width="20" height="20">
                                                                                {/* <use xlink:href="#icon-star"/> */}
                                                                            </svg>
                                                                        </Label>

                                                                    </div>
                                                                </Col>
                                                                <UncontrolledDropdown direction='start' className="col text-end">
                                                                    <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                                        <i className="ri-more-fill fs-17"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => { setIsOpen(!isOpen); setSideBar(item); }}><i className="ri-eye-line me-2 align-middle text-success" />{props.t("Voir")}</DropdownItem>
                                                                        {/* <DropdownItem><i className="ri-pencil-line me-2 align-middle text-warning" />Désactiver</DropdownItem> */}
                                                                        {/* <DropdownItem onClick={() => onClickData(item)}><i className="ri-delete-bin-5-line me-2 align-middle text-danger" />Supprimer</DropdownItem> */}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={4} className="col">
                                                            <div className="flex-shrink-0 avatar-md mx-auto">
                                                                    <div className="avatar-title bg-soft-bg-secondary text-info rounded-circle fs-20">
                                                                        <div className={"avatar-title rounded-circle bg-soft-light text-info"}>
                                                                        {`${item.first_name}`.charAt(0)}
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                                    <br />
                                                                    <br /> 
                                                                <div className="team-content text-center">
                                                                    <h5 className="fs-16 mb-1">{item.first_name} <span style={{textTransform: "uppercase"}}>{item.last_name}</span></h5>
                                                                </div>
                                                                <div className="team-content text-center">
                                                                    <h5 className="mb-1">{props.t("Email")}</h5>
                                                                    <p className="text-muted mb-0">{item.email}</p>
                                                                </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}

                                    {/* <Col lg={12}>
                                        <div className="text-center mb-3">
                                            <Link to="#" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load More </Link>
                                        </div>
                                    </Col> */}
                                </Row>


                                <div className="modal fade" id="addmembers" tabIndex="-1" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <Modal isOpen={modal} toggle={openModal} centered>
                                            <ModalHeader id="myModalLabel" toggle={openModal}>
                                                {props.t("Ajout de nouveaux membres")}
                                            </ModalHeader>
                                            <Form onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                openModal(!modal);
                                                return false;
                                            }}>
                                                <ModalBody>

                                                    <Row>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="teammembersLastName" className="form-label">{props.t("Nom")}</Label>
                                                                <Input type="text" className="form-control" id="teammembersLastName" placeholder={props.t("Saisissez nom")}
                                                                    name='last_name'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.last_name || ""}
                                                                    invalid={
                                                                        validation.touched.last_name && validation.errors.last_name ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.last_name && validation.errors.last_name ? (
                                                                    <FormFeedback type="invalid">{props.t(validation.errors.last_name)}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="teammembersLastName" className="form-label">{props.t("Prénom(s)")}</Label>
                                                                <Input type="text" className="form-control" id="teammembersFirstName" placeholder={props.t("Saisissez prénom(s)")}
                                                                    name='first_name'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.first_name || ""}
                                                                    invalid={
                                                                        validation.touched.first_name && validation.errors.first_name ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.first_name && validation.errors.first_name ? (
                                                                    <FormFeedback type="invalid">{props.t(validation.errors.first_name)}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="teammembersLastName" className="form-label">{props.t("Email")}</Label>
                                                                <Input type="text" className="form-control" id="teammembersEmail" placeholder={props.t("Saisissez adresse email")}
                                                                    name='email'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.email || ""}
                                                                    invalid={
                                                                        validation.touched.email && validation.errors.email ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.email && validation.errors.email ? (
                                                                    <FormFeedback type="invalid">{props.t(validation.errors.email)}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="teammembersPassword" className="form-label">{props.t("Mot de Passe")}</Label>
                                                                <Input type="password" className="form-control" id="teammembersPassword" placeholder={props.t("Saisissez mot de passe")} 
                                                                    name='password'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.password || ""}
                                                                    invalid={validation.touched.password && validation.errors.password ? true : false}
                                                                />
                                                               {validation.errors.password && validation.touched.password ? (
                                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                        ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor="teammembersCPassword" className="form-label">{props.t("Confirmer mot de passe")}</Label>
                                                                <Input type="password" className="form-control" id="teammembersCPassword" placeholder={props.t("Confirmer mot de passe")}
                                                                    name='cpassword'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.cpassword || ""}
                                                                    invalid={validation.touched.cpassword && validation.errors.cpassword ? true : false}
                                                                />
                                                                {validation.errors.cpassword && validation.touched.cpassword ? (
                                                                    <FormFeedback type="invalid">{validation.errors.cpassword}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    
                                                        <Col lg={12}>
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <button type="button" className="btn btn-light" onClick={() => { setModal(false); }}>{props.t("Fermer")}</button>
                                                                <button type="submit" className="btn btn-success">{props.t("Ajouter utilisateur")}</button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </ModalBody>
                                            </Form>
                                        </Modal>
                                    </div>
                                </div>

                                <Offcanvas
                                    isOpen={isOpen}
                                    direction="end"
                                    toggle={() => setIsOpen(!isOpen)}
                                    className="offcanvas-end border-0"
                                    tabIndex="-1"
                                >
                                    <OffcanvasBody className="profile-offcanvas p-0">
                                        <div className="team-cover">
                                            <img src={sideBar.backgroundImg || smallImage9} alt="" className="img-fluid" />
                                        </div>
                                        <div className="p-3">
                                            <div className="team-settings">
                                                <Row>
                                                    <Col>
                                                        <div className="bookmark-icon flex-shrink-0 me-2">
                                                            <Input type="checkbox" id="favourite13" className="bookmark-input bookmark-hide" />
                                                            <Label htmlFor="favourite13" className="btn-star">
                                                                <svg width="20" height="20">
                                                                    {/* <use xlink:href="#icon-star"/> */}
                                                                </svg>
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                    {/* <UncontrolledDropdown direction='start' className="col text-end">
                                                        <DropdownToggle tag="a" id="dropdownMenuLink14" role="button">
                                                            <i className="ri-more-fill fs-17"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem><i className="ri-eye-line me-2 align-middle" />{props.t("Voir")}</DropdownItem>
                                                            <DropdownItem><i className="ri-star-line me-2 align-middle" />Favorites</DropdownItem>
                                                            <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle" />Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown> */}
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="p-3 text-center">
                                            {sideBar.photo != null ?
                                                <img src={`https://backend.crm.inawo.pro` + sideBar.photo} alt="" className="avatar-lg img-thumbnail rounded-circle mx-auto" />
                                                :
                                                <img src={userdummy} alt="" className="avatar-lg img-thumbnail rounded-circle mx-auto" />
                                            }
                                            <div className="mt-3">
                                                <h5 className="fs-15"><Link to="#" className="link-primary">{sideBar.first_name || props.t("Non renseignée")} {sideBar.last_name || props.t("Non renseignée")}</Link></h5>
                                                <p className="text-muted">{sideBar.fonction || props.t("Non renseignée")}</p>
                                            </div>
                                            {/* <div className="hstack gap-2 justify-content-center mt-4">
                                                <div className="avatar-xs">
                                                    <Link to="#" className="avatar-title bg-soft-secondary text-secondary rounded fs-16">
                                                        <i className="ri-facebook-fill"></i>
                                                    </Link>
                                                </div>
                                                <div className="avatar-xs">
                                                    <Link to="#" className="avatar-title bg-soft-success text-success rounded fs-16">
                                                        <i className="ri-slack-fill"></i>
                                                    </Link>
                                                </div>
                                                <div className="avatar-xs">
                                                    <Link to="#" className="avatar-title bg-soft-info text-info rounded fs-16">
                                                        <i className="ri-linkedin-fill"></i>
                                                    </Link>
                                                </div>
                                                <div className="avatar-xs">
                                                    <Link to="#" className="avatar-title bg-soft-danger text-danger rounded fs-16">
                                                        <i className="ri-dribbble-fill"></i>
                                                    </Link>
                                                </div>
                                            </div> */}
                                        </div>

                                        <div className="p-3">
                                            {/* <h5 className="fs-15 mb-3">Détails Personel </h5> */}
                                            <Row className="g-0 text-center">
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Nom")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.last_name}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Prénom(s)")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.first_name}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="g-0 text-center">
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Pays")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.pays}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Ville")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.ville}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="g-0 text-center">
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Email")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.email}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Téléphone")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.telephone}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="g-0 text-center">
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Adresse")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.adresse}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Fonction")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.fonction}</p>
                                                    </div>
                                                </Col>
                                            </Row>



                                            {/* <div className="mb-3">
                                                <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Adresse</p>
                                                <h6>{sideBar.adresse}</h6>
                                            </div> */}

                                        </div>
                                        {/* <div className="p-3 border-top">
                                            <h5 className="fs-15 mb-4">File Manager</h5>
                                            <div className="d-flex mb-3">
                                                <div className="flex-shrink-0 avatar-xs">
                                                    <div className="avatar-title bg-soft-danger text-danger rounded fs-16">
                                                        <i className="ri-image-2-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6 className="mb-1"><Link to="#">Images</Link></h6>
                                                    <p className="text-muted mb-0">4469 Files</p>
                                                </div>
                                                <div className="text-muted">
                                                    12 GB
                                                </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <div className="flex-shrink-0 avatar-xs">
                                                    <div className="avatar-title bg-soft-secondary text-secondary rounded fs-16">
                                                        <i className="ri-file-zip-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6 className="mb-1"><Link to="#">Documents</Link></h6>
                                                    <p className="text-muted mb-0">46 Files</p>
                                                </div>
                                                <div className="text-muted">
                                                    3.46 GB
                                                </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <div className="flex-shrink-0 avatar-xs">
                                                    <div className="avatar-title bg-soft-success text-success rounded fs-16">
                                                        <i className="ri-live-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6 className="mb-1"><Link to="#">Media</Link></h6>
                                                    <p className="text-muted mb-0">124 Files</p>
                                                </div>
                                                <div className="text-muted">
                                                    4.3 GB
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 avatar-xs">
                                                    <div className="avatar-title bg-soft-primary text-primary rounded fs-16">
                                                        <i className="ri-error-warning-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6 className="mb-1"><Link to="#">Others</Link></h6>
                                                    <p className="text-muted mb-0">18 Files</p>
                                                </div>
                                                <div className="text-muted">
                                                    846 MB
                                                </div>
                                            </div>
                                        </div> */}
                                    </OffcanvasBody>
                                    {/* <div className="offcanvas-foorter border p-3 hstack gap-3 text-center position-relative">
                                        <button className="btn btn-light w-100"><i className="ri-question-answer-fill align-bottom ms-1"></i> Send Message</button>
                                        <Link to="/pages-profile" className="btn btn-primary w-100"><i className="ri-user-3-fill align-bottom ms-1"></i> View Profile</Link>
                                    </div> */}
                                </Offcanvas>
                            </div>
                        </Col>
                    </Row>

                    <svg className="bookmark-hide">
                        <symbol viewBox="0 0 24 24" stroke="currentColor" id="icon-star"><path strokeWidth=".4" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></symbol>
                    </svg>

                </Container>
            </div>
        </React.Fragment>
    );
};

AdminsDemos.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(AdminsDemos));