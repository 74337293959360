import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { ACTIVATION_USER } from "./actionTypes";
import { activationSuccess, activationFail } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  activation,
} from "../../../helpers/fakebackend_helper";


const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* Activate({ payload: { uid, token } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      // const response = yield call(fireBaseBackend.activateUser, user.email);
      // if (response) {
      //   yield put(
      //     activationSuccess(
      //       "Reset link are sended to your mailbox, check there first"
      //     )
      //   );
      // }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      // const body = JSON.stringify({ uid, token});
      // console.log(body);
       
      const response = yield call(activation, {
        // uid: user.uid,
        // token: user.token,
        uid: uid,
        token: token,
      });
      if (response) {
        yield put(
          activationSuccess(
            "Votre mail a été activé avec succes!"
          )
        );
      }
    } else if (process.env.REACT_APP_API_URL) {
      // const response = yield call(activation, user);
      // if (response) {
      //   yield put(
      //     activationSuccess(
      //       "Reset link are sended to your mailbox, check there first"
      //     )
      //   );
      // }
    }
  } catch (error) {
    yield put(activationFail(error));
  }
}

export function* watchActivateUser() {
  yield takeEvery(ACTIVATION_USER, Activate);
}

function* activateSaga() {
  yield all([fork(watchActivateUser)]);
}

export default activateSaga;
