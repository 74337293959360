import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { 
    Card,
    CardBody,
    Col,
    Container,
    CardHearder,
    Nav,
    NavItem,
    NavLink,
    Row,
    Modal,
    ModalHeader,
    Form,
    ModalBody,
    Label,
    Input,
    FormFeedback,
    CardHeader,
   } from 'reactstrap';
   import axios from "axios";
   import moment from 'moment';
   import { Link, withRouter } from "react-router-dom";
   import Select from "react-select";
   import classnames from "classnames";
   import Flatpickr from "react-flatpickr";
   import BreadCrumb
    from '../../../Components/Common/BreadCrumb';
  import TableContainer from '../../../Components/Common/TableContainer';
  import DeleteModal  from '../../../Components/Common/DeleteModal';
  import { isEmpty } from "lodash"; 
  import { axiosIns } from '../../../utils';
  // Formik
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";

// redux
import { useSelector, useDispatch } from "react-redux";

import { useReducer } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

//import Actions
import {
    getFactures as onGetFactures,
    addNewFacture as onAddNewFacture,
    updateFacture as onUpdateFacture,
    deleteFacture as onDeleteFacture,
} from "../../../store/factures/action"

import {
    getventes as onGetVentes,
  } from "../../../store/ventes/action";

import Loader from '../../../Components/Common/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { getContacts as onGetContacts } from "../../../store/crm/action"; 
import 'react-toastify/dist/ReactToastify.css';

import ExportCSVModal from '../../../Components/Common/ExportCSVModal';
import jwtDecode from 'jwt-decode';

const Factures = (props) => {
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const [docList, setDocList] = useState([]);
    const [doc, setDoc] = useState(null);
    const [selectedMulti, setSelectedMulti] = useState(null);
    const toastMe = () => {
        toast.error("Votre formule ne vous permet d'ajouter que 25 factures par mois.")
      };
    const toastMe2 = () => {
        toast.error("Votre formule ne vous permet d'ajouter que 75 factures par mois.")
      };
    const toastMe3 = () => {
        toast.error("Votre formule ne vous permet d'ajouter que 100 factures par mois.")
      };

    const { ventes, factures, crmcontacts } = useSelector((state) =>({
        factures: state.Facture.factures,
        crmcontacts: state.Crm.crmcontacts,
        ventes: state.Vente.ventes,
    }))

    const [isEdit, setIsEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);

    const [contactList, setContactList] = useState([]);

    const [venteList, setVenteList] = useState([]);

    const handleDeleteFacture = () => {
        if (doc) {
            dispatch(onDeleteFacture(doc.id));
            setDeleteModal(false);
        }
    }
    
      // Ventes
    useEffect(() => {
        if (ventes && !ventes.length) {
          dispatch(onGetVentes());
        }
      }, [dispatch, ventes]);

      useEffect(() => {
        setVenteList(ventes);
      }, [ventes]);
      console.log("lite", ventes);

      useEffect(() => {
        if (!isEmpty(ventes)) setVenteList(ventes);
      }, [ventes]);

       // Récuperer le type d'abonnement
  const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        const getLastAbonnement = response.length - 1
        const currentTypeAbonnement = response[getLastAbonnement].pricing
        setTypeAbonnementCourant(currentTypeAbonnement)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);


     // Factures 
    useEffect(() => {
        if (factures && !factures.length){
            dispatch(onGetFactures());
        }
    }, [dispatch, factures])

    useEffect(() => {
        setDocList(factures);
    }, [factures])
    console.log("libdoc", docList);

    useEffect(() => {
        if (!isEmpty(factures))  
            setDocList(factures); 
    }, [factures])
    console.log("test", docList);

    const [restfac, setRestfac] = useState([]);
    useEffect(() => {
        if (!isEmpty(factures)) {
          const dateSysteme = new Date(); // Crée un objet Date pour la date système
          const moisActuel = dateSysteme.getMonth(); // Obtient le mois actuel
      
          const facturesFiltrees = factures.filter(facture => {
            const moisFacture = parseInt(facture.dateFacture.slice(5, 7)) - 1; // Extrait le mois de la date de la facture
      
            return moisFacture === moisActuel; // Retourne true si le mois de la facture correspond au mois actuel
          });
      
          setRestfac(facturesFiltrees);
        }
      }, [factures]);
      console.log("test22", restfac);
      


    const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;

    const infotoken = JSON.parse(sessionStorage.getItem('authUser')) ? jwtDecode(JSON.parse(sessionStorage.getItem('authUser')).access) : null;
    console.log("infotoken", infotoken)

    useEffect(() => {
        const fetchProducts = async () => {
          try {
            const { data } = await axiosIns.get(`/contacts/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            console.log("bella",data);
            setContactList(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchProducts();
        }, [token]);

        const CrmCon = useCallback((j) => {
          for (let i = 0; i < contactList.length; i++) { // Changed <= to <
            if (j === contactList[i]?.id) {
              return contactList[i]?.nom === "" ? contactList[i]?.nomEntreprise : contactList[i]?.nom;
            }
          }
        }, [contactList]); // Add contactList as a dependency for useCallback

      console.log("te5t", CrmCon(3));

      const [isLoading, setIsLoading] = useState(true);

      useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
          setIsLoading(false); // Set loading to false once the data is fetched or loaded
        }, 2000); // Adjust the time according to your needs
      }, []); 

      const [realFactures, setRealFactures] = useState([]);
      useEffect(() => {
        if (!isEmpty(docList)) {
          const updatedFactures = docList.map(doc => ({
            ...doc,
            contact: CrmCon(parseInt(doc.contact))
          }));
          setRealFactures(updatedFactures);
        }
      }, [docList, contactList, CrmCon]);
      
      console.log("details", realFactures);

    const ConId = (j)=> {
      for(let i=0;i<=contactList.length; i++){
        if( j===contactList[i]?.nom){
         return (contactList[i]?.id) ;
        }
      }
    }
    
    
      const venteCon = useCallback((j)=> {
        for(let i = 0;i<=venteList.length; i++){
          if( j === venteList[i]?.id){
           return (venteList[i]?.numVente) ;
          }
        }
      }, [venteList])
      console.log("tet", venteCon(1));


    const columns = useMemo(
        () => [
            {
                Header: props.t("No."),
                Cell: (doc) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <h5 className="fs-14 mb-1">
                                    {doc.row.original.numFacture}
                                </h5>
                            </div>
                        </div>
                    </>
                ),
                filterable: false,
            },
            {
                Header: props.t("Clients"),
                accessor: "contact",
                Cell: (doc) => ( 
                    <>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                            {" "}
                            <Link to={`/apps-crm-contact/${ConId(doc.row.original.contact)}`}>
                                { doc.row.original.contact }
                            </Link>
                        </h5>
                      </div>
                    </div>
                    </>
                  ),
                  filterable: false,
            },
            {
                Header: props.t("Date"),
                Cell: (doc) => ( 
                    <>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                            {" "}
                            {moment(doc.row.original.dateFacture).format('YYYY-MM-DD')}
                        </h5>
                      </div>
                    </div>
                    </>
                  ),
                  filterable: false,
            },
            {
                Header: props.t("Montant"),
                Cell: (doc) => ( 
                    <>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                            {" "}
                            { doc.row.original.montant }
                        </h5>
                      </div>
                    </div>
                    </>
                  ),
                  filterable: false,
            },
            {
                Header: props.t("Vente"),
                Cell: (doc) => (
                    <>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1">
                                { venteCon(doc.row.original.vente) }
                            </h5>
                        </div>
                    </div>
                    </>
                )
            },
            {
                Header: props.t("Statut de Paiement"),
                Cell: (doc) => (
                    <>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1">
                                {props.t(doc.row.original.statusPaiement)}
                            </h5>
                        </div>
                    </div>
                    </>
                )
            },
            {
                Header: props.t("Actions"),
                Cell: (cellProps) => {
                  const docData = cellProps.row.original.id;
                  return (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li className="liste-inline-item" title={props.t("Voir")}>
                            <Link to={`/apps-documents-facture-view/${docData}`} className="text-warning d-inline-block">
                                <i className="ri-eye-fill align-bottom"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item" title={props.t("Editer une facture d'avoir")}>
                            <Link to={`/apps-documents-avoir-add/${docData}/`} className="text-primary d-inline-block">
                                <i className="ri-bill-fill align-bottom"></i>
                            </Link>
                        </li>  
                   </ul>
                  );
                },
              },
        ], [venteCon]
    )

    document.title = props.t("Factures | Le Conseiller du BTP");

    return (
        <div className="page-content">
            <ToastContainer closeButton={false} />
            <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteFacture}
            onCloseClick={() => setDeleteModal(false)}
            />
            <DeleteModal
            show={deleteModalMulti}
            onDeleteClick={
                () => {
                    setDeleteModalMulti(false);
                }}
            onCloseClick={() => setDeleteModalMulti(false)}
            />
            <Container fluid>
                <BreadCrumb parent={props.t("Factures")} title={props.t("Factures")} />
                <Row>
                    <Col lg={12}>
                        <Card id="orderList">
                            <CardHeader className='card-hearder border-0'>
                            <div className="d-flex.align-items-center">
                                <div></div>
                                {
                                    typeAbonnementCourant === 1 && restfac.length === 25 ?
                                    <button
                                        className="btn btn-info add-btn"
                                        onClick={() => {
                                        toastMe()
                                        }}
                                    >
                                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter une facture")}
                                    </button>
                                    :
                                    typeAbonnementCourant === 4 && restfac.length < 75 ?
                                    <button
                                        className="btn btn-info add-btn"
                                        onClick={() => {
                                        toastMe2()
                                        }}
                                    >
                                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter une facture")}
                                    </button>
                                    :
                                    typeAbonnementCourant === 7 && restfac.length < 100 ?
                                    <button
                                        className="btn btn-info add-btn"
                                        onClick={() => {
                                        toastMe3()
                                        }}
                                    >
                                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter une facture")}
                                    </button>
                                    :
                                    <button
                                    className="btn btn-info add-btn"
                                    onClick={() => {
                                    setModal(true);
                                    }}
                                    ><Link to={`/apps-documents-facture-add`} className="text-white d-inline-block">
                                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter une facture")}</Link>
                                    </button>
                                }
                                
                            </div>
                            </CardHeader>
                            </Card>
                            <Card id='orderList'>
                            <CardBody className='pt-0'>
                                <div>
                                    { isLoading ? ( // If loading, show loading indicator
                                      <div className="py-4 text-center">
                                        <div>
                                          <lord-icon
                                              src="https://cdn.lordicon.com/xjovhxra.json"
                                              trigger="loop"
                                              colors="primary:#0960b6,secondary:#02376b"
                                              style={{ width: "72px", height:"72px" }}>
                                          </lord-icon>
                                        </div>
                                        <div className="mt-4">
                                          <h5>{props.t("Chargement")}...</h5>
                                        </div>
                                      </div>
                                    ) 
                                    :
                                    realFactures && realFactures.length > 0 ? (
                                        <TableContainer
                                            filter
                                            columns={columns}
                                            data={realFactures || []}
                                            isGlobalFilter={true}
                                            isAddUserList = {false}
                                            customPageSize={10}
                                            divClass="table-responsive mb-1"
                                            tableClass="mb-0 align-middle table-borderless"
                                            theadClass="table-light text-muted"
                                        />) : (
                                            <div className="py-4 text-center">
                                                <div className="mt-4">
                                                    <h5>{props.t("Désolé! aucun résultat trouvé")}</h5>
                                                </div>
                                            </div>
                                        )}

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

Factures.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(Factures));
