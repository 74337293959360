import React, { useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

//Simple bar

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import classnames from "classnames";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { Link,useHistory, useParams } from "react-router-dom";
import { axiosIns } from "../../utils";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

function CollaborateursDetail(props) {

  const history = useHistory();

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const { id } = useParams('id')

  const [collab, setCollab] = useState({})
  const [profile, setProfile] = useState({})

  const collaborateurInfo = async () => {
    const response = await axiosIns.get(`collaborators/${id}/`)
    setCollab(response.data)
    setProfile(response.data.profile)
  }

  useEffect(() => {
    collaborateurInfo()
  }, [!collab])

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

document.title =props.t("Détail Collaborateur");
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title={props.t("Détail Collaborateur")} pageTitle={props.t("Collaborateurs")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={12}>
                    <div className="mt-xl-0 mt-5">
                      <div>
                        <div className="d-flex">
                          <img src={profile.profile_pic} width="100" style={{
                            borderRadius: '50%',
                            height: '100px',
                            width: '100px',
                            objectFit: 'cover',
                            objectPosition: 'center center',
                            paddingBottom: "10px"
                          }} alt="" />
                          <div 
                          style={{
                            marginLeft: "20px",
                            marginTop: "8px"
                          }}
                          >
                            <h2 style={{fontWeight:"bold",textTransform:"uppercase"}}>{collab.username}</h2>
                            {collab.email}<br/>
                            <br></br>
                              {profile && profile.user_type === "particular"?
                              (<h5 className="badge badge-soft-danger">Particulier</h5>):
                              profile && profile.user_type ==="assistant"?
                              (<h5 className="badge badge-soft-primary">Assistant</h5>):
                              profile && profile.user_type ==="professional"?
                              (<h5 className="badge badge-soft-info">Professionnel</h5>):
                              profile && profile.user_type ==="expert"?
                              (<h5 className="badge badge-soft-secondary">Expert</h5>):
                              profile && profile.user_type ==="commite"?
                              (<h5 className="badge badge-soft-secondary">Commité</h5>):
                              profile && profile.user_type ==="admin"?
                              (<h5 className="badge badge-soft-secondary">Admin</h5>):""
                              }
                          </div>
                        </div>
                      </div>

                      <div className="product-content mt-3">
                        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                             { props.t("Détails")}
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                        activeTab={customActiveTab}
                          className="border border-top-0 p-4"
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="1"
                          >
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row"> {props.t("Nom : ")}</th>
                                    <td>{profile.first_name || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Prénom(s) :")} </th>
                                    <td>{profile.last_name || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Adresse :")} </th>
                                    <td>{profile.address || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Ville :")} </th>
                                    <td>{profile.city || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Pays :")} </th>
                                    <td>{profile.country || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                          <TabPane
                            id="nav-detail"
                            tabId="2"
                          >
                            <div>
                              <h5 className="font-size-16 mb-3">
                                {/* {selectProduct.desigantionProd} */}
                              </h5>
                              <p>
                                {/* {selectProduct.description} */}
                              </p>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>

                      
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

CollaborateursDetail.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(CollaborateursDetail));