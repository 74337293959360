import React, { useEffect, useState, useMemo } from "react";
import fecthData from "../Categories/EcommerceUpdateCategories";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from "axios";
import {
  Container,
  Form,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,Nav,NavItem,TabPane,TabContent,NavLink,
  FormFeedback
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../../Components/Common/DeleteModal";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback } from "react";
import { toast } from "react-toastify";
import classnames from "classnames";

//redux
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";



const Metiers= (props) => {
  const dispatch = useDispatch();

  const url = "https://backend.leconseillerdubtp.com/api/v1";
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [metierList,setMetierList]=useState([]);
  const [metier,setMetier]=useState([]);
  const [metiers,setMetiers]=useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isMet, setMet] = useState({});
    useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/metier-types/`);
          setMetierList(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/metiers/`);
          setMetier(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/pros-metiers/`);
          setMetiers(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [type, setType] = useState({});
  const toggle = useCallback(() => {
      if (modal) {
        setModal(false);
        setType({});
      } else {
        setModal(true);
      }
      
    }, [modal]);

    const toggle2 = useCallback(() => {
      if (modal2) {
        setModal2(false);
      } else {
        setModal2(true);
      }
      
    }, [modal2]);


  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);


  const handleDeleteCategory = () => {
    if (metierList) {
      dispatch(metierList._id);
      setDeleteModal(false);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []); 

  const [dele, setDele] = useState(0);
  // Displat Delete Button
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = 'none';
    } else {
      del.style.display = 'block';
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      dispatch(element.value);
      del.style.display = 'none';
    });
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "label":(type && type.label )|| " "
    },
    
    validationSchema: Yup.object().shape({
      label: Yup.string().required(props.t("Entrer un metier")),
    }),

    onSubmit: (values) => {
      if(!isEdit){
        const newCategory={
          label:values.label
        }
  
        var count=0;
          if (newCategory){
            for (let i=0; i<=metierList.length;i++){
              if(newCategory.label=== metierList[i]?.label){
                count= count+1;
                toast.error(props.t("Le métier existe deja"), { autoClose: 3000 });
                break;
              }
            }
            if (count===0){
              axios.post(`${url}/metier-types/`,newCategory)
              .then(res =>{
                if(res){
                  toast.success(props.t("Métier bien ajouté"),{autoClose:3000})
                }
              }, (error) =>{
                if(error){
                  toast.error(props.t("Métier non ajouté"),{autoClose:3000})
                }}
              )
              validation.resetForm();
              setTimeout(()=>window.location.reload(true),2000); 
              setModal(false);
            }else{
              count=0;
            }
          }
      } else{
        const updateCategory={
          id: type && type.id ,
          label:values.label
        }
  
        var count=0;
          if (updateCategory){
            for (let i=0; i<=metierList.length;i++){
              if(updateCategory.label=== metierList[i]?.label){
                count= count+1;
                toast.error(props.t("Le métier existe deja"), { autoClose: 3000 });
                break;
              }
            }
            if (count===0){
              axios.patch(`${url}/metier-types/${updateCategory.id}/`,updateCategory)
              .then(res =>{
                if(res){
                  toast.success(props.t("Type de métier bien modifié"),{autoClose:3000})
                }
              }, (error) =>{
                if(error){
                  toast.error(props.t("Type de métier non modifié"),{autoClose:3000})
                }}
              )
              validation.resetForm();
              setTimeout(()=>window.location.reload(true),2000); 
              setModal(false);
            }else{
              count=0;
            }
          }

      }

  } 
  });

  const validation2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      "metier_type":isMet && isMet.metier_type,
      "label":isMet && isMet.label
    },
    
    validationSchema: Yup.object().shape({
      label: Yup.string().required(props.t("Entrer un metier")),
    }),

    onSubmit: (values) => {
      if(!isEdit){
        const newCategory={
          metier_type:values.metier_type,
          label:values.label
        }
        axios.post(`${url}/metiers/`,newCategory)
          .then(res =>{
            if(res){
              toast.success(props.t("Métier bien ajouté"),{autoClose:3000})
            }
          }, (error) =>{
            if(error){
              toast.error(props.t("Métier non ajouté"),{autoClose:3000})
            }}
          )
          validation2.resetForm();
          setModal2(false);
          setTimeout(()=>window.location.reload(true),2000)  
      }
       else{
        const newCategory={
          id: isMet && isMet.id,
          metier_type: values.metier_type,
          label:values.label
        }
        axios.patch(`${url}/metiers/${newCategory.id}/`,newCategory)
          .then(res =>{
            if(res){
              toast.success(props.t("Métier bien modifié"),{autoClose:3000})
            }
          }, (error) =>{
            if(error){
              toast.error(props.t("Métier non modifié"),{autoClose:3000})
            }}
          )
          validation2.resetForm();
          setModal2(false);
          setTimeout(()=>window.location.reload(true),2000) 
          }
      }
      
  });

  useEffect(() => {
    if(metierList)
      return
  }, [metierList]);

  useEffect(() => {
    if(metier)
      return
  }, [metier]);

  useEffect(() => {
    if(metiers)
      return
  }, [metiers]);

  const handleContactClick = useCallback((arg) => {
    setType({
      id:arg.id,
      label:arg.label
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const handleContactClicks = useCallback((arg) => {
    setMet({
      id:arg.id,
      metier_type:arg.metier_type && arg.metier_type.label,
      label:arg.label
    });

    setIsEdit(true);
    toggle2();
  }, [toggle2]);

  
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  }
  const columns = useMemo(() => [
    {
      Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
      Cell: (cellProps) => {
        return <input type="checkbox" className="entrepriseCheckBox" value={cellProps.row.original.id} />;
      },
      id: '#',
    },
    {
      Header: props.t("TITRE"),
      accessor:"label",
      Cell: (metiers) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <p className="fs-14 mb-1" >
                <Link
                  to="/apps-metiers"
                  className="text-dark"
                >
                  {" "}
                  {metiers.row.original.label}
                </Link>
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "ACTION",
      Cell: (cellProps) => {
        const metiersData = cellProps.row.original;
        return(
          <li className="list-inline-item" title={props.t("Modifier")}>
             <a className="text-warning d-inline-block" style={{cursor:'pointer'}} onClick={() => { handleContactClick(metiersData); }}
                   >
                  <i className="ri-pencil-fill align-bottom"></i>
              </a>
            </li> 
        );
      },
    },
  ],
    [fecthData, props,handleContactClick]
  );

  const columns1 = useMemo(() => [
    {
      Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
      Cell: (cellProps) => {
        return <input type="checkbox" className="entrepriseCheckBox" value={cellProps.row.original.id} />;
      },
      id: '#',
    },
        {
          Header: props.t("TYPE METIER"),
          Cell: (metiers) => (
            <>
              <div className="d-flex align-items-center" >
                <div className="flex-grow-1">
                  <p className="fs-14 mb-1" >
                    <Link
                      to="/apps-metiers"
                      className="text-dark"
                    >
                      {" "}
                      {metiers.row.original.metier_type && metiers.row.original.metier_type?.label }
                    </Link>
                  </p>
                </div>
              </div>
            </>
          ),
        },
        {
          Header: props.t("TITRE"),
          Cell: (metiers) => (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className="fs-14 mb-1">
                    <Link
                      to="/apps-metiers"
                      className="text-dark"
                    >
                      {" "}
                      {metiers.row.original.label}
                    </Link>
                  </p>
                </div>
              </div>
            </>
          ),
        },
        {
          Header: "ACTIONS",
          Cell: (cellProps) => {
            const metiersData = cellProps.row.original;
            return(
              <li className="list-inline-item" title={props.t("Modifier")}>
                 <a onClick={() => { handleContactClicks(metiersData); }} style={{cursor:'pointer'}}className="text-warning d-inline-block"
                       >
                      <i className="ri-pencil-fill align-bottom"></i>
                  </a >
                </li> 
            );
          },
        },
      ],
        [fecthData, props,handleContactClicks]
  );


  document.title = props.t("Liste des métiers");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Métiers")} pageTitle={props.t("Métiers")}/>

        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer", fontWeight:'bold'}}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                             { props.t("TYPE DE METIER")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer",fontWeight:'bold' }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2");
                              }}
                            >
                             { props.t("METIER")}
                            </NavLink>
                          </NavItem>
        </Nav>

          <TabContent
                        activeTab={customActiveTab}
                          className=" border-top-0 "
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="1"
                          >
                            <div >
                            <Row>
                              <div className="col-xl-12 col-lg-12">
                                <div>
                                  <div className="card">
                                    <div className="card-header border-0">
                                      <Row className="g-4 align-items-center">
                                        <Col sm={6}>
                                          <div className="search-box">
                                            <Input
                                              type="text"
                                              className="form-control search"
                                              placeholder="Rechercher..."
                                            />
                                            <i className="ri-search-line search-icon"></i>
                                          </div>
                                        </Col>
                                          <div className="col-sm-auto ms-auto">
                                              <div>
                                                  <button className="btn btn-warning" onClick={()=>{setModal(true); setIsEdit(false)}}>  
                                                      <i className="ri-add-fill align-bottom me-1"></i> Ajouter un type de métier 
                                                  </button>
                                              </div>
                                          </div>
                                      </Row>
                                      <div className="row align-items-center">
                                      
                                        <div className="col-auto">
                                          <div id="selection-element">
                                            <div className="my-n1 d-flex align-items-center text-muted">
                                              Select{" "}
                                              <div
                                                id="select-content"
                                                className="text-body fw-semibold px-1"
                                              >{dele}</div>{" "}
                                              Result{" "}
                                              <button
                                                type="button"
                                                className="btn btn-link link-danger p-0 ms-3"
                                                onClick={() => setDeleteModalMulti(true)}
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body pt-0">
                                      <Modal id="showModal1" isOpen={modal} toggle={toggle} centered>
                                        <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                                          {!!isEdit ? props.t("Modifier Type") : props.t("Ajouter Type")}
                                        </ModalHeader>

                                        <Form onSubmit={(e) => {
                                          e.preventDefault();
                                          validation.handleSubmit();
                                          return false;
                                        }}>
                                          <ModalBody>
                                            <Input type="hidden" id="id-field" />
                                            <Row className="g-3">  
                                              <Col lg={12}>
                                                <div>
                                                  <Label
                                                    htmlFor=""
                                                    className="form-label"
                                                  >
                                                    {props.t("Type")}
                                                  </Label>
                                                  <Input
                                                    name="label"
                                                    id="label"
                                                    className="form-control"
                                                    placeholder={props.t("Entrez le nom")}
                                                    type="text"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.label || ""}
                                                    invalid={
                                                      validation.touched.label && validation.errors.label ? true : false
                                                    }
                                                  />
                                                  {validation.touched.label && validation.errors.label ? (
                                                    <FormFeedback type="invalid">{validation.errors.label}</FormFeedback>
                                                  ) : null}

                                                </div>
                                              </Col>
                                            </Row>
                                          </ModalBody>
                                          <ModalFooter>
                                            <div className="hstack gap-2 justify-content-end">
                                              <button type="button" className="btn btn-light" onClick={() => { setModal(false); }} > {props.t("Fermer")} </button>
                                              <button type="submit" className="btn btn-warning" id="add-btn"> {!!isEdit ? props.t("Modifier") : props.t("Ajouter")} </button>
                                            </div>
                                          </ModalFooter>
                                        </Form>
                                      </Modal>
                                      {   isLoading ? ( // If loading, show loading indicator
                                          <div className="py-4 text-center">
                                            <div>
                                              <lord-icon
                                                  src="https://cdn.lordicon.com/xjovhxra.json"
                                                  trigger="loop"
                                                  colors="primary:#0960b6,secondary:#02376b"
                                                  style={{ width: "72px", height:"72px" }}>
                                              </lord-icon>
                                            </div>
                                            <div className="mt-4">
                                              <h5>{props.t("Chargement")}...</h5>
                                            </div>
                                          </div>
                                        ) 
                                        :
                                      metierList && metierList.length >= 0 ? (
                                        <TableContainer
                                          columns={columns}
                                          data={(metierList || [])}
                                          isAddUserList={false}
                                          customPageSize={10}
                                          divClass="table-responsive mb-1"
                                          tableClass="mb-0 align-middle table-borderless"
                                          theadClass="table-light text-muted"
                                          isMetierFilter={false}
                                        />
                                      ) : (
                                        <div className="py-4 text-center">
                                          <div className="mt-4">
                                            <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                            </div>
                          </TabPane>
          </TabContent>
          <TabContent
                        activeTab={customActiveTab}
                          className="border-top-0 "
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="2"
                          >
                            <div>
                            <Row>
                              <div className="col-xl-12 col-lg-12">
                                <div>
                                  <div className="card">
                                    <div className="card-header border-0">
                                    <Row className="g-4 align-items-center">
                                        <Col sm={5}>
                                          <div className="search-box">
                                            <Input
                                              type="text"
                                              className="form-control search"
                                              placeholder="Rechercher..."
                                            />
                                            <i className="ri-search-line search-icon"></i>
                                          </div>
                                        </Col>
                                        <div className="col-sm-auto ms-auto">
                                              <div>
                                                  <button className="btn btn-warning" onClick={()=>{setModal2(true);setIsEdit(false)}}>  
                                                      <i className="ri-add-fill align-bottom me-1"></i> Ajouter un métier 
                                                  </button>
                                              </div>
                                          </div>
                                      </Row>
                                      <div className="row align-items-center">
                                        <div className="col-auto">
                                          <div id="selection-element">
                                         
                                            <div className="my-n1 d-flex align-items-center text-muted">
                                              Select{" "}
                                              <div
                                                id="select-content"
                                                className="text-body fw-semibold px-1"
                                              >{dele}</div>{" "}
                                              Result{" "}
                                              <button
                                                type="button"
                                                className="btn btn-link link-danger p-0 ms-3"
                                                onClick={() => setDeleteModalMulti(true)}
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body pt-0">
                                    <Modal id="showModal1" isOpen={modal2} toggle={toggle2} centered>
                                        <ModalHeader className="bg-soft-info p-3" toggle={toggle2}>
                                          {!!isEdit ? props.t("Modifier Type") : props.t("Ajouter Type")}
                                        </ModalHeader>

                                        <Form onSubmit={(e) => {
                                          e.preventDefault();
                                          validation2.handleSubmit();
                                          return false;
                                        }}>
                                          <ModalBody>
                                            <Input type="hidden" id="id-field" />
                                            <Row className="g-3">  
                                              <Col lg={12}>
                                                <div>
                                                  <Label
                                                    htmlFor=""
                                                    className="form-label"
                                                  >
                                                    {props.t("Type")}
                                                  </Label>
                                                  <Input
                                                    name="metier_type"
                                                    className="form-control"
                                                    type="select"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    onChange={validation2.handleChange}
                                                    onBlur={validation2.handleBlur}
                                                    value={isMet && isMet.metier_type }
                                                    invalid={
                                                      validation2.touched.metier_type && validation2.errors.metier_type ? true : false
                                                    }
                                                  >
                                                    <option className="form-select" value=" ">Sélectionner un domaine</option>
                                                    {metierList && metierList.map((item,key)=> (
                                                        <option value={item.id} key={key}>{item.label}</option>
                                                    ))
                                                    }
                                                  </Input>
                                                  {validation2.touched.metier_type && validation2.errors.metier_type ? (
                                                    <FormFeedback type="invalid">{validation2.errors.metier_type}</FormFeedback>
                                                  ) : null}

                                                </div>
                                              </Col>
                                              <Col lg={12}>
                                                <div>
                                                  <Label
                                                    htmlFor=""
                                                    className="form-label"
                                                  >
                                                    {props.t("Désignation")}
                                                  </Label>
                                                  <Input
                                                    name="label"
                                                    id="label"
                                                    className="form-control"
                                                    type="text"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    onChange={validation2.handleChange}
                                                    onBlur={validation2.handleBlur}
                                                    value={validation2.values.label || ""}
                                                    invalid={
                                                      validation2.touched.label && validation2.errors.label ? true : false
                                                    }
                                                  />
                                                  {validation2.touched.label && validation2.errors.label ? (
                                                    <FormFeedback type="invalid">{validation2.errors.label}</FormFeedback>
                                                  ) : null}

                                                </div>
                                              </Col>
                                            </Row>
                                          </ModalBody>
                                          <ModalFooter>
                                            <div className="hstack gap-2 justify-content-end">
                                              <button type="button" className="btn btn-light" onClick={() => { setModal2(false); }} > {props.t("Fermer")} </button>
                                              <button type="submit" className="btn btn-warning" id="add-btn"> {!!isEdit ? props.t("Modifier") : props.t("Ajouter")} </button>
                                            </div>
                                          </ModalFooter>
                                        </Form>
                                      </Modal>
                                      <ToastContainer closeButton={false} />
                                      {   isLoading ? ( // If loading, show loading indicator
                                          <div className="py-4 text-center">
                                            <div>
                                              <lord-icon
                                                  src="https://cdn.lordicon.com/xjovhxra.json"
                                                  trigger="loop"
                                                  colors="primary:#0960b6,secondary:#02376b"
                                                  style={{ width: "72px", height:"72px" }}>
                                              </lord-icon>
                                            </div>
                                            <div className="mt-4">
                                              <h5>{props.t("Chargement")}...</h5>
                                            </div>
                                          </div>
                                        ) 
                                        :
                                      metier && metier.length >= 0 ? (
                                        <TableContainer
                                          columns={columns1}
                                          data={(metier || [])}
                                          isAddUserList={false}
                                          customPageSize={10}
                                          divClass="table-responsive mb-1"
                                          tableClass="mb-0 align-middle"
                                          theadClass="table-light text-muted"
                                          isMetierFilter={false}
                                        />
                                      ) : (
                                        <div className="py-4 text-center">
                                          <div className="mt-4">
                                            <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Row>
                            </div>
                          </TabPane>
          </TabContent>

      </Container>
    </div>
  );
};
Metiers.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(Metiers));