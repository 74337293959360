import {
  ADD_ARRONDISSEMENT_FAIL,
  ADD_ARRONDISSEMENT_SUCCESS,
  ADD_NEW_ARRONDISSEMENT,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  DELETE_ARRONDISSEMENT,
  DELETE_ARRONDISSEMENT_FAIL,
  DELETE_ARRONDISSEMENT_SUCCESS,
  GET_ARRONDISSEMENTS,
  GET_ARRONDISSEMENTS_BY_ID,
  GET_ARRONDISSEMENTS_BY_ID_FAIL,
  GET_ARRONDISSEMENTS_BY_ID_SUCCESS,
  UPDATE_ARRONDISSEMENT,
  UPDATE_ARRONDISSEMENT_FAIL,
  UPDATE_ARRONDISSEMENT_SUCCESS,
} from "./actionType";

// common success
export const arrondissementApiResponseSuccess = (actionType, response) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, response },
});

//common error
export const arrondissementApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getArrondissements = () => ({
  type: GET_ARRONDISSEMENTS,
});

export const getArrondissementsById = (id) => ({
  type: GET_ARRONDISSEMENTS_BY_ID,
  payload: id,
});

export const addNewArrondissements = (arrondissement) => ({
  type: ADD_NEW_ARRONDISSEMENT,
  payload: arrondissement,
});

export const addNewArrondissementsSuccess = (arrondissement) => ({
  type: ADD_ARRONDISSEMENT_SUCCESS,
  payload: arrondissement,
});

export const addNewArrondissementsFail = (error) => ({
  type: ADD_ARRONDISSEMENT_FAIL,
  payload: error,
});

export const updateArrondissements = (arrondissement) => ({
  type: UPDATE_ARRONDISSEMENT,
  payload: arrondissement,
});

export const updateArrondissementsSuccess = (arrondissement) => ({
  type: UPDATE_ARRONDISSEMENT_SUCCESS,
  payload: arrondissement,
});

export const updateArrondissementsFail = (error) => ({
  type: UPDATE_ARRONDISSEMENT_FAIL,
  payload: error,
});

export const deleteArrondissements = (arrondissement) => ({
  type: DELETE_ARRONDISSEMENT,
  payload: arrondissement,
});

export const deleteArrondissementsSuccess = (arrondissement) => ({
  type: DELETE_ARRONDISSEMENT_SUCCESS,
  payload: arrondissement,
});

export const deleteArrondissementsFail = (error) => ({
  type: DELETE_ARRONDISSEMENT_FAIL,
  payload: error,
});

export const getArrondissementsByIdSuccess = (arrondissement) => ({
  type: GET_ARRONDISSEMENTS_BY_ID_SUCCESS,
  payload: arrondissement,
});

export const getArrondissementsByIdFail = (error) => ({
  type: GET_ARRONDISSEMENTS_BY_ID_FAIL,
  payload: error,
});
