import {
  ADD_NEW_PAYS,
  ADD_PAYS_FAIL,
  ADD_PAYS_SUCCESS,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  DELETE_PAYS,
  DELETE_PAYS_FAIL,
  DELETE_PAYS_SUCCESS,
  GET_PAYS,
  UPDATE_PAYS,
  UPDATE_PAYS_FAIL,
  UPDATE_PAYS_SUCCESS,
} from "./actionType";

// common success
export const paysApiResponseSuccess = (actionType, response) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, response },
});

//common error
export const paysApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPays = () => ({
  type: GET_PAYS,
});

export const addNewPays = (pays) => ({
  type: ADD_NEW_PAYS,
  payload: pays,
});

export const addNewPaysSuccess = (pays) => ({
  type: ADD_PAYS_SUCCESS,
  payload: pays,
});

export const addNewPaysFail = (error) => ({
  type: ADD_PAYS_FAIL,
  payload: error,
});

export const updatePays = (pays) => ({
  type: UPDATE_PAYS,
  payload: pays,
});

export const updatePaysSuccess = (pays) => ({
  type: UPDATE_PAYS_SUCCESS,
  payload: pays,
});

export const updatePaysFail = (error) => ({
  type: UPDATE_PAYS_FAIL,
  payload: error,
});

export const deletePays = (pays) => ({
  type: DELETE_PAYS,
  payload: pays,
});

export const deletePaysSuccess = (pays) => ({
  type: DELETE_PAYS_SUCCESS,
  payload: pays,
});

export const deletePaysFail = (error) => ({
  type: DELETE_PAYS_FAIL,
  payload: error,
});
