import React,{useEffect,useState} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

const Assistance = () => {
    const [assistances, setAssistances] = useState([]);
    const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
    useEffect(() => {
      const checkTokenValidity = async () => {
          try {
          const response = await axios.get("https://backend.leconseillerdubtp.com/api/v1/assistances/not-responded/", {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          setAssistances(response)
          } catch (error) {
          console.log(error)
          } 
      };
      checkTokenValidity();
    }, []);
    return (
        <React.Fragment>
            
              <Link to={`/apps-assistances-toResponded`} className="text-warning d-inline-block">
                <div style={{position:'relative'}}>
                  <i className='ri-customer-service-2-fill btn btn-icon btn-topbar btn-ghost-secondary rounded-circle' style={{fontSize:'23px'}} >
                  {assistances.length > 0 &&  <p className='rounded-circle py-0 px-1' style={{background:'red',color:'white',position:'absolute',fontSize:"10px"}}>{assistances.length}
                      </p> 
                    }
                  </i>
                </div>
              </Link>
            
        </React.Fragment>
    );
};

export default Assistance;