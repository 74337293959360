import React, { useState, useEffect } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { Col, Container, Row, CardBody, Card } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import axios from "axios";
import { axiosIns } from '../../utils';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const AbonnementInfo = (props) => {
document.title=props.t("AbonnementInfo | Le Conseiller du BTP");
// Récuperer le type d'abonnement
const [dateDebutAbonnement, setDateDebutAbonnement] = useState(null)
const [dateFinAbonnement, setDateFinAbonnement] = useState(null)
const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
useEffect(() => {
  const checkTokenValidity = async () => {
      try {
      const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      const getLastAbonnement = response.length - 1
      const currentTypeAbonnement = response[getLastAbonnement].pricing
      const currentDateDebutAbonnement = new Date(response[getLastAbonnement].start).toISOString().substring(0,10)
      const currentDateFinAbonnement = response[getLastAbonnement].end
      setTypeAbonnementCourant(currentTypeAbonnement)
      setDateDebutAbonnement(currentDateDebutAbonnement)
      setDateFinAbonnement(currentDateFinAbonnement)
      } catch (error) {
      console.log(error)
      } 
  };
  checkTokenValidity();
}, []);
console.log(typeAbonnementCourant)
console.log(dateDebutAbonnement)
console.log(dateFinAbonnement)

const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
const [abonne, setAbonne] = useState("")
    useEffect(() => {
        const getProfileInfo = async () => {
            try {
                const response = await axiosIns.get(`/profile/`, {
                  headers: {
                    Authorization: 'Bearer ' + token,
                  },
                });
                setAbonne(response.data.abonne)
              } catch (err) {
                console.log(err);
            }
        }
        getProfileInfo()
}, [])
console.log(abonne)

  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
        <BreadCrumb title={props.t("Info-Abonnement")} pageTitle="CRM" />
            <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                    <Card className="mt-4">
                        <CardBody className="p-4 text-center">
                            <div className="avatar-lg mx-auto mt-2">
                                <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                    <i className="ri-checkbox-circle-fill"></i>
                                </div>
                            </div>
                            <div className="mt-4 pt-2">
                                <h4>
                                  {
                                    typeAbonnementCourant === 1 ?
                                        <div>
                                          {props.t("PROSPECTION ET VENTE")}<br/>
                                          {props.t("Formule : ESSENTIEL")}
                                        </div>
                                    : typeAbonnementCourant === 2 ?
                                        <div>
                                          {props.t("PROSPECTION ET VENTE")}<br/>
                                          {props.t("Formule : PROFESSIONNEL")}
                                        </div>
                                    : typeAbonnementCourant === 3 ?
                                        <div>
                                          {props.t("PROSPECTION ET VENTE")}<br/>
                                          {props.t("Formule : PREMIUM")}
                                        </div>
                                    : typeAbonnementCourant === 4 ?
                                        <div>
                                          {props.t("FACTURATION ET GESTION")}<br/>
                                          {props.t("Formule : ESSENTIEL")}
                                        </div>
                                    : typeAbonnementCourant === 5 ?
                                        <div>
                                          {props.t("FACTURATION ET GESTION")}<br/>
                                          {props.t("Formule : PROFESSIONNEL")}
                                        </div>
                                    : typeAbonnementCourant === 6 ?
                                        <div>
                                          {props.t("FACTURATION ET GESTION")}<br/>
                                          {props.t("Formule : PREMIUM")}
                                        </div>
                                    : typeAbonnementCourant === 7 ?
                                        <div>
                                          {props.t("INAWO GLOBAL")}<br/>
                                          {props.t("Formule : ESSENTIEL")}
                                        </div>
                                    : typeAbonnementCourant === 8 ?
                                        <div>
                                          {props.t("INAWO GLOBAL")}<br/>
                                          {props.t("Formule : PROFESSIONNEL")}
                                        </div>
                                    : typeAbonnementCourant === 9 ?
                                        <div>
                                          {props.t("INAWO GLOBAL")}<br/>
                                          {props.t("Formule : PREMIUM")}
                                        </div>
                                    : typeAbonnementCourant === 10 ?
                                        <div>
                                          {props.t("Formule : Essai gratuit de 14 jours")}
                                        </div>
                                    : null
                                  }

                                </h4>
                                <p className="text-muted mx-4">{props.t("Du")} {dateDebutAbonnement} {props.t("au")} {dateFinAbonnement}</p>
                                <div className='justify-content-center'>
                                    { abonne ?
                                        <div className="col-auto mt-4">
                                        </div>:
                                        <div className="col-auto mt-4">
                                            <span><Link to="/apps-crm-abonnements" className="btn btn-success">{props.t("Changer de formule")}</Link></span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AbonnementInfo.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
  };
export default withRouter(withTranslation()(AbonnementInfo));