import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { object, string } from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { updatePays } from "../../../store/actions";
import { axiosIns } from "../../../utils";

const UpdatePays = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [country, setCountry] = useState({});

  const handleGetCountry = async () => {
    try {
      const response = await axiosIns.get(`countries/${id}/`);
      if (response.status === 200) {
        setCountry(response.data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetCountry();
  }, []);

  console.log("Country", country);

  document.title = props.t("Modification d'un pays - Le Conseiller du BTP");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: country?.name || "",
    },

    validationSchema: object().shape({
      name: string().required(props.t("Veuillez entrer le nom du pays")),
    }),

    onSubmit: (values) => {
      const updatedCountry = {
        ...country,
        name: values.name,
      };
      dispatch(updatePays(updatedCountry));
      history.push("/apps-geolocalisation-pays");
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Ajout d'un pays")}
          pageTitle={props.t("Pays")}
        />

        <Row>
          <Col lg={12}>
            <Form
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="name">
                      {props.t("Nom du pays")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      placeholder={props.t("Entrer le nom du pays")}
                      name="name"
                      value={validation.values.name}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-3">
                  <Link
                    to={`/apps-geolocalisation-pays`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm m-2">
                  {props.t("Valider")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

UpdatePays.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UpdatePays));
