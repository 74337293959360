import {
  ADD_NEW_CITY,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  DELETE_CITY,
  GET_CITY,
  UPDATE_CITY,
} from "./actionType";

const initialState = {
  cities: [],
  oneCity: {},
  error: null,
};

export default function cityReducer(state = initialState, action) {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CITY:
          return {
            ...state,
            isLoading: false,
            cities: action.payload.response,
          };
        case ADD_NEW_CITY:
          return {
            ...state,
            isLoading: false,
            cities: [...state.cities, action.payload.response],
          };
        case UPDATE_CITY:
          return {
            ...state,
            isLoading: false,
            cities: state.cities.map((city) =>
              city._id === action.payload.response._id
                ? action.payload.response
                : city
            ),
          };
        case DELETE_CITY:
          return {
            ...state,
            isLoading: false,
            cities: state.cities.filter(
              (city) => city._id !== action.payload.response._id
            ),
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
