import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isComptes, setIsComptes] = useState(false);
  const [isContacts, setIsContacts] = useState(false);
  const [isParticuliers, setIsParticuliers] = useState(false);
  const [isEntreprises, setIsEntreprises] = useState(false);
  const [isOpportunites, setIsOpportunites] = useState(false);
  const [isAbonnements, setIsAbonnements] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isCatalogue, setIsCatalogue] = useState(false);
  const [isVente, setIsVente] = useState(false);
  const [isMetiers, setIsMetiers] = useState(false);
  const [isAchat, setIsAchat] = useState(false);
  const [isOrganisme, setIsOrganisme] = useState(false);
  const [isDocuments, setIsDocuments] = useState(false);
  const [isAgenda, setIsAgenda] = useState(false);
  const [isTaches, setIsTaches] = useState(false);
  const [isFichiers, setIsFichiers] = useState(false);
  const [isEntreprise, setisEntreprise] = useState(false);
  const [isDeals, setIsDeals] = useState(false);
  const [isLeads, setIsLeads] = useState(false);
  const [isAppelOffres, setIsAppelOffres] = useState(false);
  const [isExperts, setIsExperts] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [isStock, setIsStock] = useState(false);
  const [isPartenaire, setIsPartenaire] = useState(false);
  const [isAbonnement, setIsAbonnement] = useState(false);
  const [isMessagerie, setIsMessagerie] = useState(false);
  const [isFormations, setIsFormations] = useState(false);
  const [isInnovations, setIsInnovations] = useState(false);
  const [isAssistances, setIsAssistances] = useState(false);
  const [isPublicties, setIsPublicties] = useState(false);
  const [isFonctions, setIsFonctions] = useState(false);
  const [isLevel, setIsLevel] = useState(false);
  const [isFormationsPro, setIsFormationsPro] = useState(false);
  const [particular, setIsParticular] = useState(false);
  const [isQuestions, setIsQuestions] = useState(false);
  const [isOrganismes, setIsOrganismes] = useState(false);
  const [isProfessional, setIsProfessional] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);
  const [isBank, setIsBank] = useState(false);

  //Geolocalisation
  const [isCountry, setIsCountry] = useState(false);
  const [isProvince, setIsProvince] = useState(false);
  const [isCity, setIsCity] = useState(false);
  const [isArrondissement, setIsArrondissement] = useState(false);
  const [isQuartier, setIsQuartier] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Banques") {
      setIsBank(false);
    }
    if (iscurrentState !== "Niveau de formation") {
      setIsLevel(false);
    }
    if (iscurrentState !== "Fonction") {
      setIsFonctions(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }

    if (iscurrentState !== "Particuliers") {
      setIsParticular(false);
    }

    if (iscurrentState === "Widgets") {
      history.push("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
    if (iscurrentState !== "Contacts") {
      setIsContacts(false);
    }
    if (iscurrentState !== "Abonnements") {
      setIsAbonnements(false);
    }
    if (iscurrentState !== "Vente") {
      setIsVente(false);
    }
    if (iscurrentState !== "Metiers") {
      setIsMetiers(false);
    }
    if (iscurrentState !== "Opportunites") {
      setIsOpportunites(false);
    }

    if (iscurrentState !== "Approvisionnement") {
      setIsAchat(false);
    }

    if (iscurrentState !== "Formations Professionnelles") {
      setIsFormationsPro(false);
    }

    if (iscurrentState !== "Documents") {
      setIsDocuments(false);
    }
    if (iscurrentState !== "Agenda") {
      setIsAgenda(false);
    }
    if (iscurrentState !== "Taches") {
      setIsTaches(false);
    }
    if (iscurrentState !== "Fichiers") {
      setIsFichiers(false);
    }
    if (iscurrentState !== "Entreprise") {
      setisEntreprise(false);
    }
    if (iscurrentState !== "Entreprises") {
      setIsEntreprises(false);
    }
    if (iscurrentState !== "Particuliers") {
      setIsParticuliers(false);
    }
    if (iscurrentState !== "Deals") {
      setIsDeals(false);
    }
    if (iscurrentState !== "Leads") {
      setIsLeads(false);
    }
    if (iscurrentState !== "Utilisateurs") {
      setIsUsers(false);
    }
    if (iscurrentState !== "Comptes") {
      setIsComptes(false);
    }
    if (iscurrentState !== "Gestion des Stocks") {
      setIsStock(false);
    }
    if (iscurrentState !== "Partenaires") {
      setIsPartenaire(false);
    }
    if (iscurrentState !== "Abonnements") {
      setIsAbonnement(false);
    }
    if (iscurrentState !== "Messageries") {
      setIsMessagerie(false);
    }
    if (iscurrentState !== "Appel d'Offres") {
      setIsAppelOffres(false);
    }
    if (iscurrentState !== "Experts") {
      setIsExperts(false);
    }
    if (iscurrentState !== "Formations") {
      setIsFormations(false);
    }
    if (iscurrentState !== "Innovations") {
      setIsInnovations(false);
    }
    if (iscurrentState !== "Assistances") {
      setIsAssistances(false);
    }
    if (iscurrentState !== "Publicites") {
      setIsPublicties(false);
    }
    if (iscurrentState !== "Questions/Réponses") {
      setIsQuestions(false);
    }
    if (iscurrentState !== "Organisme") {
      setIsOrganismes(false);
    }
    if (iscurrentState !== "Professional") {
      setIsProfessional(false);
    }
    if (iscurrentState !== "Organisme") {
      setIsOrganisme(false);
    }
    if (iscurrentState !== "Pays") {
      setIsCountry(false);
    }
    if (iscurrentState !== "Province") {
      setIsProvince(false);
    }
    if (iscurrentState !== "City") {
      setIsCity(false);
    }
    if (iscurrentState !== "Arrondissement") {
      setIsArrondissement(false);
    }
    if (iscurrentState !== "Quartier") {
      setIsQuartier(false);
    }
  }, [
    history,
    isOrganisme,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isContacts,
    isParticuliers,
    isEntreprises,
    isCatalogue,
    isVente,
    isAgenda,
    isDocuments,
    isTaches,
    isFichiers,
    isEntreprise,
    isLeads,
    isDeals,
    isUsers,
    isComptes,
    isStock,
    isPartenaire,
    isAbonnement,
    isMessagerie,
    isOpportunites,
    isAppelOffres,
    isExperts,
    isFormations,
    isInnovations,
    isAssistances,
    isPublicties,
    isFonctions,
    isLevel,
    isFormationsPro,
    isQuestions,
    isOrganismes,
    isProfessional,
    isBank,
    isCountry,
    isProvince,
    isCity,
    isArrondissement,
    isQuartier,
  ]);
  const menuItems = [
    {
      label: "MENU",
      isHeader: true,
    },
    {
      id: "Dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "comptes",
      label: "Comptes",
      icon: "ri-account-circle-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsComptes(!isComptes);
        setIscurrentState("Comptes");
        updateIconSidebar(e);
      },
      stateVariables: isComptes,
      subItems: [
        {
          id: "utilisateurs",
          label: "Utilisateurs",
          link: "/pages-team",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsUsers(!isUsers);
          },
        },
        {
          id: "entreprise",
          label: "Collaborateurs",
          icon: "ri-apps-2-line",
          link: "/apps-collaborateurs",
          click: function (e) {
            e.preventDefault();
            setisEntreprise(!isEntreprise);
            setIscurrentState("Entreprise");
            updateIconSidebar(e);
          },
          stateVariables: isEntreprise,
        },
        {
          id: "profil",
          label: "Profil",
          link: "/pages-profile",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsProfile(!isProfile);
          },
        },
      ],
    },
    // {
    //   label: "UTILISATEURS",
    //   isHeader: true,
    // },

    // {
    //   id: "organismes",
    //   label: "Organismes",
    //   icon: "ri-building-2-line",
    //   link: "/#",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsOrganisme(!isOrganisme);
    //     setIscurrentState("Organisme");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isOrganisme,
    //   subItems: [
    //     {
    //       id: "organisme",
    //       label: "Organismes de régulation",
    //       link: "/apps-organismes",
    //       isChildItem: false,
    //       click: function (e) {
    //         e.preventDefault();
    //         setIsOrganismes(!isOrganismes);
    //       },
    //     },
    //     {
    //       id: "bank",
    //       label: "Banques et assurances",
    //       link: "/apps-banks",
    //       isChildItem: false,
    //       click: function (e) {
    //         e.preventDefault();
    //         setIsProfile(!isProfile);
    //       },
    //     },
    //   ],
    // },
    // {
    //   id: "entreprise",
    //   label: "Entreprises",
    //   icon: "ri-hotel-line",
    //   link: "/apps-enterprises",
    //   stateVariables: isEntreprises,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsEntreprises(!isEntreprises);
    //     setIscurrentState("Entreprise");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "professional",
    //   label: "Professionnels",
    //   icon: "ri-user-star-line",
    //   link: "/apps-professionals",
    //   stateVariables: isProfessional,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsProfessional(!isProfessional);
    //     setIscurrentState("Professionnels");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "particuliers",
    //   label: "Particuliers",
    //   icon: "ri-account-circle-fill",
    //   link: "/apps-particuliers",
    //   stateVariables: isParticuliers,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsParticuliers(!isParticuliers);
    //     setIscurrentState("Particuliers");
    //     updateIconSidebar(e);
    //   },
    // },
    { label: "GEOLOCALISATION", isHeader: true },
    
    {
      id: "arrondissement",
      label: "Arrondissement",
      icon: "ri-map-pin-line",
      link: "/apps-geolocalisation-arrondissement",
      stateVariables: isArrondissement,
      click: function (e) {
        e.preventDefault();
        setIsArrondissement(!isArrondissement);
        setIscurrentState("Arrondissement");
        updateIconSidebar(e);
      },
    },
    {
      id: "quartier",
      label: "Quartier",
      icon: "ri-community-line",
      link: "/apps-geolocalisation-quartier",
      stateVariables: isQuartier,
      click: function (e) {
        e.preventDefault();
        setIsQuartier(!isQuartier);
        setIscurrentState("Quartier");
        updateIconSidebar(e);
      },
    },
    {
      label: "ARTICLES",
      isHeader: true,
    },
    {
      id: "",
      label: "Articles",
      icon: "ri-book-open-line",
      link: "/apps-articles",
      stateVariables: isEcommerce,
      click: function (e) {
        e.preventDefault();
        setIsEcommerce(!isEcommerce);
        setIscurrentState("Articles");
        updateIconSidebar(e);
      },
    },
    {
      id: "catalogue",
      label: "Type d'articles",
      link: "/apps-categories",
      icon: "ri-book-open-line",
      click: function (e) {
        e.preventDefault();
        setIsCatalogue(!isCatalogue);
        setIscurrentState("Catalogue");
        updateIconSidebar(e);
      },
    },

    {
      label: "FORMATIONS",
      isHeader: true,
    },
    {
      id: "formations",
      label: "Formations",
      icon: "ri-book-2-line",
      link: "/apps-formations",
      stateVariables: isFormations,
      click: function (e) {
        e.preventDefault();
        setIsFormations(!isFormations);
        setIscurrentState("Formations");
        updateIconSidebar(e);
      },
    },
    {
      label: "PROFESSIONNELS",
      isHeader: true,
    },
    {
      id: "level",
      label: "Niveau de formation",
      icon: "ri-bar-chart-grouped-fill",
      link: "/apps-level",
      stateVariables: isLevel,
      click: function (e) {
        e.preventDefault();
        setIsLevel(!isLevel);
        setIscurrentState("Niveau de formation");
        updateIconSidebar(e);
      },
    },

    {
      id: "formation-pro",
      label: "Formations Pro",
      icon: "ri-shopping-bag-fill",
      link: "/apps-formationspro",
      stateVariables: isFormationsPro,
      click: function (e) {
        e.preventDefault();
        setIsFormationsPro(!isFormationsPro);
        setIscurrentState("Fonctions Professionnelles");
        updateIconSidebar(e);
      },
    },

    {
      id: "fonction",
      label: "Fonctions",
      icon: "ri-briefcase-fill",
      link: "/apps-fonctions",
      stateVariables: isFonctions,
      click: function (e) {
        e.preventDefault();
        setIsFonctions(!isFonctions);
        setIscurrentState("Fonctions");
        updateIconSidebar(e);
      },
    },
    {
      id: "Metiers",
      label: "Metiers",
      icon: "ri-funds-box-line",
      link: "/apps-metiers",
      click: function (e) {
        e.preventDefault();
        setIsMetiers(!isMetiers);
        setIscurrentState("Vente");
        updateIconSidebar(e);
      },
      stateVariables: isMetiers,
    },
    {
      label: "EXPERT",
      isHeader: true,
    },
    {
      id: "experts",
      label: "Experts",
      icon: "ri-user-voice-fill",
      link: "/apps-experts",
      click: function (e) {
        e.preventDefault();
        setIsExperts(!isExperts);
        setIscurrentState("Experts");
        updateIconSidebar(e);
      },
    },
    {
      id: "questions",
      label: "Questions/Réponses",
      icon: "ri-questionnaire-line",
      link: "/apps-questions",
      click: function (e) {
        e.preventDefault();
        setIsQuestions(!isQuestions);
        setIscurrentState("Questions");
        updateIconSidebar(e);
      },
    },
    {
      label: "CERTIFICATION",
      isHeader: true,
    },
    {
      id: "certification",
      label: "Certification",
      icon: "ri-shield-star-fill",
      link: "/certification",
      click: function (e) {
        e.preventDefault();
        setIsExperts(!isExperts);
        setIscurrentState("Certification");
        updateIconSidebar(e);
      },
    },
    {
      label: "AUTRES ACTIVITES",
      isHeader: true,
    },
    {
      id: "publicites",
      label: "Publicités",
      icon: "ri-advertisement-line",
      link: "/apps-publicities",
      stateVariables: isPublicties,
      click: function (e) {
        e.preventDefault();
        setIsPublicties(!isPublicties);
        setIscurrentState("Publicites");
        updateIconSidebar(e);
      },
    },

    {
      id: "opportunites",
      label: "Recrutements",
      icon: "ri-trophy-fill",
      link: "/apps-recrutements",
      // link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsOpportunites(!isOpportunites);
        setIscurrentState("Opportunités");
        updateIconSidebar(e);
      },
      stateVariables: isOpportunites,
    },
    {
      id: "appel_offres",
      label: "Appel d'Offres",
      icon: "ri-profile-fill",
      link: "/apps-appels-offres",
      click: function (e) {
        e.preventDefault();
        setIsAppelOffres(!isAppelOffres);
        setIscurrentState("Appel d'Offres");
        updateIconSidebar(e);
      },
      stateVariables: isAppelOffres,
    },

    {
      id: "innovations",
      label: "Innovations",
      icon: "ri-alarm-warning-line",
      link: "/apps-innovations",
      stateVariables: isInnovations,
      click: function (e) {
        e.preventDefault();
        setIsInnovations(!isInnovations);
        setIscurrentState("Innovations");
        updateIconSidebar(e);
      },
    },
    {
      label: "Type d'abonnement",
      isHeader: true,
    },

    {
      id: "type_abonnement",
      label: "Type d'abonnement",
      icon: "ri-bill-fill",
      link: "/apps-type",
      stateVariables: isAbonnement,
      click: function (e) {
        e.preventDefault();
        setIsAbonnement(!isAbonnement);
        setIscurrentState("Abonnement");
        updateIconSidebar(e);
      },
    },

    {
      label: "Clientèle",
      isHeader: true,
    },
    {
      id: "assistance",
      label: "Assistance technique",
      icon: "ri-customer-service-2-fill",
      link: "/apps-assistances",
      stateVariables: isAssistances,
      click: function (e) {
        e.preventDefault();
        setIsAssistances(!isAssistances);
        setIscurrentState("Assistances");
        updateIconSidebar(e);
      },
    },
  ];
  const menuItemsCommite = [
    {
      label: "MENU",
      isHeader: true,
    },
    {
      id: "Dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "comptes",
      label: "Comptes",
      icon: "ri-account-circle-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsComptes(!isComptes);
        setIscurrentState("Comptes");
        updateIconSidebar(e);
      },
      stateVariables: isComptes,
      subItems: [
        {
          id: "utilisateurs",
          label: "Utilisateurs",
          link: "/pages-team",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsUsers(!isUsers);
          },
        },

        {
          id: "profil",
          label: "Profil",
          link: "/pages-profile",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsProfile(!isProfile);
          },
        },
      ],
    },
    {
      label: "ARTICLES",
      isHeader: true,
    },
    {
      id: "catalogue",
      label: "Articles",
      icon: "ri-book-open-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsEcommerce(!isEcommerce);
        setIscurrentState("Articles");
        updateIconSidebar(e);
      },
      stateVariables: isCatalogue,
      subItems: [
        {
          id: "produits",
          label: "Articles",
          link: "/apps-articles",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsEcommerce(!isEcommerce);
          },
        },
      ],
    },
    {
      label: "FORMATIONS",
      isHeader: true,
    },
    {
      id: "formations",
      label: "Formations",
      icon: "ri-book-2-line",
      link: "/apps-formations",
      stateVariables: isFormations,
      click: function (e) {
        e.preventDefault();
        setIsFormations(!isFormations);
        setIscurrentState("Formations");
        updateIconSidebar(e);
      },
    },
    {
      label: "EXPERT",
      isHeader: true,
    },
    {
      id: "experts",
      label: "Experts",
      icon: "ri-user-voice-fill",
      link: "/apps-experts",
      click: function (e) {
        e.preventDefault();
        setIsExperts(!isExperts);
        setIscurrentState("Experts");
        updateIconSidebar(e);
      },
    },
    {
      id: "questions",
      label: "Questions/Réponses",
      icon: "ri-questionnaire-line",
      link: "/apps-questions",
      click: function (e) {
        e.preventDefault();
        setIsQuestions(!isQuestions);
        setIscurrentState("Questions");
        updateIconSidebar(e);
      },
    },
    {
      label: "AUTRES ACTIVITES",
      isHeader: true,
    },
    {
      id: "publicites",
      label: "Publicités",
      icon: "ri-advertisement-line",
      link: "/apps-publicities",
      stateVariables: isPublicties,
      click: function (e) {
        e.preventDefault();
        setIsPublicties(!isPublicties);
        setIscurrentState("Publicites");
        updateIconSidebar(e);
      },
    },
    {
      id: "innovations",
      label: "Innovations",
      icon: "ri-alarm-warning-line",
      link: "/apps-innovations",
      stateVariables: isInnovations,
      click: function (e) {
        e.preventDefault();
        setIsInnovations(!isInnovations);
        setIscurrentState("Innovations");
        updateIconSidebar(e);
      },
    },

    {
      label: "Type d'abonnement",
      isHeader: true,
    },

    {
      id: "type_abonnement",
      label: "Type d'abonnement",
      icon: "ri-bill-fill",
      link: "/apps-type",
      stateVariables: isAbonnement,
      click: function (e) {
        e.preventDefault();
        setIsAbonnement(!isAbonnement);
        setIscurrentState("Abonnement");
        updateIconSidebar(e);
      },
    },

    {
      label: "Clientèle",
      isHeader: true,
    },
    {
      id: "assistance",
      label: "Assistance technique",
      icon: "ri-customer-service-2-fill",
      link: "/apps-assistances",
      stateVariables: isAssistances,
      click: function (e) {
        e.preventDefault();
        setIsAssistances(!isAssistances);
        setIscurrentState("Assistances");
        updateIconSidebar(e);
      },
    },
  ];
  const menuItemsAssistant = [
    {
      label: "MENU",
      isHeader: true,
    },
    {
      id: "Dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "comptes",
      label: "Comptes",
      icon: "ri-account-circle-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsComptes(!isComptes);
        setIscurrentState("Comptes");
        updateIconSidebar(e);
      },
      stateVariables: isComptes,
      subItems: [
        {
          id: "utilisateurs",
          label: "Utilisateurs",
          link: "/pages-team",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsUsers(!isUsers);
          },
        },
        {
          id: "profil",
          label: "Profil",
          link: "/pages-profile",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsProfile(!isProfile);
          },
        },
      ],
    },
    {
      label: "ARTICLES",
      isHeader: true,
    },
    {
      id: "catalogue",
      label: "Articles",
      icon: "ri-book-open-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsEcommerce(!isCatalogue);
        setIscurrentState("Catalogue");
        updateIconSidebar(e);
      },
      stateVariables: isCatalogue,
      subItems: [
        {
          id: "addcategory",
          label: "Type d'articles",
          link: "/apps-categories",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsAddCategory(!isAddCategory);
          },
        },
        {
          id: "produits",
          label: "Articles",
          link: "/apps-articles",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsEcommerce(!isEcommerce);
          },
        },
      ],
    },
    {
      label: "FORMATIONS",
      isHeader: true,
    },
    {
      id: "formations",
      label: "Formations",
      icon: "ri-book-2-line",
      link: "/apps-formations",
      stateVariables: isFormations,
      click: function (e) {
        e.preventDefault();
        setIsFormations(!isFormations);
        setIscurrentState("Formations");
        updateIconSidebar(e);
      },
    },

    {
      label: "AUTRES ACTIVITES",
      isHeader: true,
    },
    {
      id: "publicites",
      label: "Publicités",
      icon: "ri-advertisement-line",
      link: "/apps-publicities",
      stateVariables: isPublicties,
      click: function (e) {
        e.preventDefault();
        setIsPublicties(!isPublicties);
        setIscurrentState("Publicites");
        updateIconSidebar(e);
      },
    },

    {
      id: "opportunites",
      label: "Recrutements",
      icon: "ri-trophy-fill",
      link: "/apps-recrutements",
      // link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsOpportunites(!isOpportunites);
        setIscurrentState("Opportunités");
        updateIconSidebar(e);
      },
      stateVariables: isOpportunites,
    },
    {
      id: "appel_offres",
      label: "Appel d'Offres",
      icon: "ri-profile-fill",
      link: "/apps-appels-offres",
      click: function (e) {
        e.preventDefault();
        setIsAppelOffres(!isAppelOffres);
        setIscurrentState("Appel d'Offres");
        updateIconSidebar(e);
      },
      stateVariables: isAppelOffres,
    },

    {
      id: "innovations",
      label: "Innovations",
      icon: "ri-alarm-warning-line",
      link: "/apps-innovations",
      stateVariables: isInnovations,
      click: function (e) {
        e.preventDefault();
        setIsInnovations(!isInnovations);
        setIscurrentState("Innovations");
        updateIconSidebar(e);
      },
    },

    {
      label: "Type d'abonnement",
      isHeader: true,
    },

    {
      id: "type_abonnement",
      label: "Type d'abonnement",
      icon: "ri-bill-fill",
      link: "/apps-type",
      stateVariables: isAbonnement,
      click: function (e) {
        e.preventDefault();
        setIsAbonnement(!isAbonnement);
        setIscurrentState("Abonnement");
        updateIconSidebar(e);
      },
    },

    {
      label: "Clientèle",
      isHeader: true,
    },
    {
      id: "assistance",
      label: "Assistance technique",
      icon: "ri-customer-service-2-fill",
      link: "/apps-assistances",
      stateVariables: isAssistances,
      click: function (e) {
        e.preventDefault();
        setIsAssistances(!isAssistances);
        setIscurrentState("Assistances");
        updateIconSidebar(e);
      },
    },
  ];
  const menuItemsExpert = [
    {
      label: "MENU",
      isHeader: true,
    },
    {
      id: "Dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "comptes",
      label: "Comptes",
      icon: "ri-account-circle-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsComptes(!isComptes);
        setIscurrentState("Comptes");
        updateIconSidebar(e);
      },
      stateVariables: isComptes,
      subItems: [
        {
          id: "utilisateurs",
          label: "Utilisateurs",
          link: "/pages-team",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsUsers(!isUsers);
          },
        },
        {
          id: "profil",
          label: "Profil",
          link: "/pages-profile",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsProfile(!isProfile);
          },
        },
      ],
    },

    {
      label: "EXPERT",
      isHeader: true,
    },
    {
      id: "questions",
      label: "Questions/Réponses",
      icon: "ri-questionnaire-line",
      link: "/apps-questions",
      click: function (e) {
        e.preventDefault();
        setIsQuestions(!isQuestions);
        setIscurrentState("Questions");
        updateIconSidebar(e);
      },
    },
  ];
  const menuItemsParticulier = [
    {
      id: "Dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "comptes",
      label: "Comptes",
      icon: "ri-account-circle-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsComptes(!isComptes);
        setIscurrentState("Comptes");
        updateIconSidebar(e);
      },
      stateVariables: isComptes,
      subItems: [
        {
          id: "profil",
          label: "Profil",
          link: "/pages-profile",
          isChildItem: false,
          click: function (e) {
            e.preventDefault();
            setIsProfile(!isProfile);
          },
        },
      ],
    },
  ];
  const tokenInfo = JSON.parse(sessionStorage.getItem("authUser"));
  const role = tokenInfo.role;

  return (
    <React.Fragment>
      {role.some((role) => role === "admin")
        ? menuItems
        : role.some((role) => role === "commite")
        ? menuItemsCommite
        : role.some((role) => role === "expert")
        ? menuItemsExpert
        : role.some((role) => role === "assistant")
        ? menuItemsAssistant
        : menuItemsParticulier}
    </React.Fragment>
  );
};
export default Navdata;
