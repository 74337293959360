import React, { useState, useEffect, useMemo, useCallback } from 'react';
import TableContainer from '../../../Components/Common/TableContainer';
import DeleteModal from "../../../Components/Common/DeleteModal";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Import Scroll Bar - SimpleBar
import SimpleBar from 'simplebar-react';

//Import Flatepicker
import Flatpickr from "react-flatpickr";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Col, Modal, ModalBody, Row, Label, Input, Button, ModalHeader, FormFeedback, Form } from 'reactstrap';

import {
  getTaskList,
  addNewTask,
  updateTask,
  deleteTask,
} from "../../../store/actions";

import {
  CreateBy,
  DueDate,
  Status,
  Priority,
  AssignedTo
} from "./TaskListCol";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { Link } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";

const Assigned = [
  { id: 1, imgId: "anna-adame", img: "avatar-1.jpg", name: "Anna Adame" },
  { id: 2, imgId: "frank-hook", img: "avatar-3.jpg", name: "Frank Hook" },
  { id: 3, imgId: "alexis-clarke", img: "avatar-6.jpg", name: "Alexis Clarke" },
  { id: 4, imgId: "herbert-stokes", img: "avatar-2.jpg", name: "Herbert Stokes" },
  { id: 5, imgId: "michael-morris", img: "avatar-7.jpg", name: "Michael Morris" },
  { id: 6, imgId: "nancy-martino", img: "avatar-5.jpg", name: "Nancy Martino" },
  { id: 7, imgId: "thomas-taylor", img: "avatar-8.jpg", name: "Thomas Taylor" },
  { id: 8, imgId: "tonya-noble", img: "avatar-10.jpg", name: "Tonya Noble" },
];


const AllTasks = (props) => {
  const dispatch = useDispatch();
  const history=useHistory();

   // Récuperer le type d'abonnement
 const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
 useEffect(() => {
   const checkTokenValidity = async () => {
       try {
       const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
       const getLastAbonnement = response.length - 1
       const currentTypeAbonnement = response[getLastAbonnement].pricing
       setTypeAbonnementCourant(currentTypeAbonnement)
       } catch (error) {
       console.log(error)
       } 
   };
   checkTokenValidity();
 }, []);

  const { taskList, isTaskCreated, isTaskSuccess, error } = useSelector((state) => ({
    taskList: state.Tasks.taskList,
    isTaskCreated: state.Tasks.isTaskCreated,
    isTaskSuccess: state.Tasks.isTaskSuccess,
    error: state.Tasks.error,
  }));

  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);


  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://back.crm.inawo.pro/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const [isEdit, setIsEdit] = useState(false);
  const [task, setTask] = useState([]);
  const [TaskList, setTaskList] = useState([]);

  // Delete Task
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTask(null);
      setTaskList(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  // Delete Data
  const onClickDelete = (task) => {
    setTask(task);
    setDeleteModal(true);
  };

  useEffect(() => {
    setTaskList(taskList);
  }, [taskList]);

  // Delete Data
  const handleDeleteTask = () => {
    if (task) {
      dispatch(deleteTask(task._id));
      setDeleteModal(false);
    }
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
    taskname: (TaskList && TaskList.taskname) || "",
    taskdate:(TaskList && TaskList.taskdate) || "",
    description: (TaskList && TaskList.description)||"",
    status: (TaskList && TaskList.status) || "",
    priority: (TaskList && TaskList.priority)|| null,
    taskresponsable: (TaskList && TaskList.taskresponsable)|| null,
    expiry: (TaskList && TaskList.expiry) || "",
    },
    validationSchema: Yup.object({
      taskname: Yup.string().required("Ajouter un nom"),
      // taskresponsable: Yup.string().nullable().required("Assigner la tache à un responsable"),
      taskdate:Yup.string().required("Ajouter une date"),
      status:Yup.string().required("Ajouter un status"),
      expiry: Yup.string().required("Ajouter une date d'expiration"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedTask = {
          id: TaskList.id,
          taskname: values.taskname,
          description: values.description,
          taskresponsable: values.taskresponsable,
          expiry: values.expiry,
          taskdate: values.taskdate,
          status:values.status,
          priority: values.priority
        };
        // update customer
        dispatch(updateTask(updatedTask));
        toast.success(props.t("Tâche modifiée avec succès"),{autoClose:3000})
        validation.resetForm();
        dispatch(getTaskList());
        setTimeout(()=>window.location.reload(true),2000);

      } else {
        const newTask = {
          taskname: values["taskname"],
          description: values["description"],
          expiry: values["expiry"],
          taskresponsable: values["taskresponsable"],
          taskdate: values["taskdate"],
          status: values["status"],
          priority: values.priority,
        };
        // save new customer
        dispatch(addNewTask(newTask));
        toast.success(props.t("Tâche ajoutée avec succès"),{autoClose:3000})
        validation.resetForm();
        dispatch(getTaskList());
        setTimeout(()=>window.location.reload(true),2000);
      }
      toggle();
    },
  });

  // Update Data
  const handleCustomerClick = useCallback((arg) => {
    const task = arg;

    setTaskList({
      id: task.id,
      taskname: task.taskname,
      description: task.description,
      taskresponsable: task.taskresponsable,
      expiry: task.expiry,
      taskdate: task.taskdate,
      status: task.status,
      priority: task.priority,
    });
    
  }, 
  [toggle]
  );

  // Add Data
  const handleTaskClicks = () => {
    setTaskList("");
    setIsEdit(false);
    toggle();
  };

  useEffect(() => {
    if (!isEmpty(taskList)) setTaskList(taskList);
  }, [taskList]);

  useEffect(() => {
    if (taskList && !taskList.length) {
      dispatch(getTaskList());
    }
  }, [dispatch, taskList]);


  useEffect(() => {
    setTaskList(taskList);
  }, [taskList]);

  useEffect(() => {
    if (!isEmpty(taskList)) {
      setTaskList(taskList);
      setIsEdit(false);
    }
  }, [taskList]);

  // Node API 
  // useEffect(() => {
  //   if (isTaskCreated) {
  //     setTask(null);
  //     dispatch(getTaskList());
  //   }
  // }, [
  //   dispatch,
  //   isTaskCreated,
  // ]);

  const [abonne,setAbonne]=useState([{"id":null,"first_name":"","last_name":""}]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/profile/`);
          setAbonne(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);
    const abonnee =(j)=>{
        if(j===abonne.id){
          return (abonne.first_name+" "+ abonne.last_name);
        }
    } 


    const [collaborateur,setCollaborateur]=useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data  = await axios.get(`${url}/collaborateurs/`);
            setCollaborateur(data)
            console.log(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
  
      }, [url]);
      const categgory =(j)=>{
        for(let i=0;i<=collaborateur.length;i++){
          if(j===collaborateur[i]?.id){
            return (collaborateur[i]?.first_name+" "+ collaborateur[i]?.last_name);
          }
        }
      } 
    
// Checked All
const checkedAll = () => {
  const checkall = document.getElementById("checkBoxAll");
  const ele = document.querySelectorAll(".taskCheckBox");

  if (checkall.checked) {
    ele.forEach((ele) => {
      ele.checked = true;
    });
  } else {
    ele.forEach((ele) => {
      ele.checked = false;
    });
  }
};

// Delete Multiple
const deleteMultiple = () => {
  const ele = document.querySelectorAll(".taskCheckBox:checked");
  const checkall = document.getElementById("checkBoxAll");
  ele.forEach((element) => {
    dispatch(deleteTask(element.value));
  });
  checkall.checked = false;
};

  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="taskCheckBox" value={cellProps.row.original.id} />;
        },
        id: '#',
      },
      
      {
        Header: props.t("Tâches"),
        accessor: "task",
        filterable: false,
        Cell: (cellProps) => {
          const taskData = cellProps.row.original; 
          return <React.Fragment>
            
            <div className="d-flex">
              <div className="flex-grow-1 tasks_name">{cellProps.row.original.taskname}</div>
              <div className="flex-shrink-0 ms-4">
                <ul className="list-inline tasks-list-menu mb-0">
                  <li className="list-inline-item">
                    <Link to={`/apps-tasks-details/${taskData.id}`}>
                      <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" onClick={() => {handleCustomerClick(taskData);setIsEdit(true);toggle();}}>
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                    </Link>
                  </li>
                  {/* <li className="list-inline-item">
                    <Link to="#" className="remove-item-btn" onClick={() => { const taskData = cellProps.row.original; onClickDelete(taskData); }}>
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </React.Fragment>;
        },
      },
      {
        Header:props.t("Description"),
        Cell: (cellProps) => (
          <>
            <div className="d-flex align-items-center" >
              
              <div className="flex-grow-1" >
                <h5 className="fs-14 mb-1 text-muted" style={{width:50,overflow:"clip"}}>
                 
                    {cellProps.row.original.description}
                </h5>
                
              </div>
            </div>
          </>
        ),
      },
      {
        Header:props.t("Crée par"),
        accessor: "creater",
        filterable: false,
        Cell: (cellProps) => {
          return <CreateBy {...cellProps} />
        },
      },
      {
        Header:props.t("Responsable de la tache"),
        accessor: "subItem",
        filterable: false,
        Cell: (cellProps) => {
          return <AssignedTo {...cellProps} />
        },
      },
      {
        Header:props.t("Echéance"),
        accessor: "dueDate",
        filterable: false,
        Cell: (cellProps) => {
          return <DueDate {...cellProps} />;
        },
      },
      {
        Header:props.t("Statut"),
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header:props.t("Priorité"),
        accessor: "priority",
        filterable: false,
        Cell: (cellProps) => {
          return <Priority {...cellProps} />;
        },
      },
    ],
    [handleCustomerClick,props],

  );

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
  };

  const [date, setDate] = useState(defaultdate());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => setDeleteModal(false)}
      />
       <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <div className="row">
        <Col lg={12}>
          <div className="card" id="tasksList">
            <div className="card-header border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">{props.t("Toutes les tâches")}</h5>
                <div className="flex-shrink-0">
                  {
                    (<button className="btn btn-info add-btn me-1" 
                    onClick={() => { setIsEdit(false); toggle()}}
                    ><i className="ri-add-line align-bottom me-1"></i> {props.t("Ajouter une tâche")}
                   </button>)
                  }
                  
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
              isTaskSuccess && TaskList && TaskList.length ? (
                <TableContainer
                  columns={columns}
                  data={(TaskList || [])}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={20}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light table-nowrap"
                  thClass="table-light text-muted"
                  handleTaskClick={handleTaskClicks}
                />
              ) : (
              <div className="py-4 text-center">
                <div className="mt-4">
                  <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                </div>
              </div>)
              }
            </div>
          </div>
        </Col>
      </div>


      <Modal
        isOpen={modal}
        // toggle={toggle}
        centered
        size="lg"
        className="border-0"
        modalClassName='modal fade zoomIn'
      >
        <ModalHeader className="p-3 bg-soft-info">
          {!!isEdit ? props.t("Modifier une tache") : props.t("Créer une tache")}
        </ModalHeader>
        <Form onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}>
          <ModalBody className="modal-body">
            <Row className="g-3">
              <Col lg={12}>
                <Label for="taskname-field" className="form-label">{props.t("Nom de la tâche")} <span style={{ color: "red" }}>*</span></Label>
                <Input
                  name="taskname"
                  id="taskname-field"
                  className="form-control"
                  // placeholder={props.t("Entrer une tâche")}
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.taskname|| ""}
                  invalid={
                    validation.touched.taskname && validation.errors.taskname ? true : false
                  }
                />
                {validation.touched.taskname && validation.errors.taskname ? (
                  <FormFeedback type="invalid">{props.t(validation.errors.taskname)}</FormFeedback>
                ) : null}
              </Col>

              <Col lg={12}>
                <Label className="form-label">{props.t("Responsable de la tâche")}</Label>
                        <Input 
                          name="taskresponsable" 
                          type="select"
                          value={validation.values.taskresponsable}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                    >
                    <option > {props.t("Sélectionner un collaborateur")} </option>
                    {collaborateur.map((item,key) => 
                    <option key={key} value={item.id}>{item.first_name +" "+ item.last_name}</option>
                    )}
                    </Input>
              </Col>
             
              <Col lg={12}>
                <Label for="description-field" className="form-label">{props.t("Description")}</Label>
                <Input
                     className="form"
                     placeholder={props.t("")}
                     name="description"
                     type="textarea"
                     value={validation.values.description}
                     onBlur={validation.handleBlur}
                     onChange={validation.handleChange}
                    />
              </Col>
              <Col lg={6}>
                <Label for="taskdate-field" className="form-label">{props.t("Date de la tâche")} <span style={{ color: "red" }}>*</span></Label>
                <Input
                  name="taskdate"
                  id="taskdate-field"
                  className="form-control"
                  placeholder={props.t("Selectionner une date")}
                  type="date"
                  min={new Date().toISOString().substring(0,10)}
                  value={validation.values.taskdate}
                  onChange={validation.handleChange}
                  invalid={validation.touched.taskdate && validation.errors.taskdate ? true : false}
                />
                {validation.touched.taskdate && validation.errors.taskdate ? (
                  <FormFeedback type="invalid">{props.t(validation.errors.taskdate)}</FormFeedback>
                ) : null}
              </Col>
              <Col lg={6}>
                <Label for="status" className="form-label">{props.t("Statut")} <span style={{ color: "red" }}>*</span></Label>
                <Input
                  name="status"
                  type="select"
                  className="form-select"
                  id="status-field"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.status 
                  }
                  invalid={validation.touched.status && validation.errors.status ? true : false}
                >
                  <option >{props.t("Statut")}</option>
                  <option value="nouveau">{props.t("Nouveau")}</option>
                  <option value="en cours">{props.t("En cours")}</option>
                  <option value="en attente">{props.t("En attente")}</option>
                  <option value="complet">{props.t("Complet")}</option>
                </Input>
                {validation.touched.status &&
                  validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {props.t(validation.errors.status)}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col lg={12}>
                <Label for="expiry" className="form-label">{props.t("Date d'Expiration")} <span style={{ color: "red" }}>*</span></Label>
                <Input
                  name="expiry"
                  id="expiry"
                  className="form-control"
                  placeholder={props.t("Selectionner une date")}
                  type="date"
                  min={new Date().toISOString().substring(0,10)}
                  value={validation.values.expiry}
                  onChange={validation.handleChange}
                  invalid={validation.touched.expiry && validation.errors.expiry ? true : false}
                />
                {validation.touched.expiry && validation.errors.expiry ? (
                  <FormFeedback type="invalid">{props.t(validation.errors.expiry)}</FormFeedback>
                ) : null}
              </Col>
              <Col lg={12}>
                <Label for="priority-field" className="form-label">{props.t("Priorité")}</Label>
                <Input
                  name="priority"
                  type="select"
                  className="form-select"
                  id="priority-field"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.priority 
                  }
                >
                  <option >{props.t("Priorité")}</option>
                  <option value="haute">{props.t("Haute")}</option>
                  <option value="moyenne">{props.t("Moyenne")}</option>
                  <option value="faible">{props.t("Faible")}</option>
                </Input>
                {validation.touched.priority &&
                  validation.errors.priority ? (
                  <FormFeedback type="invalid">
                    {validation.errors.priority}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <div className="modal-footer">
            <div className="hstack gap-2 justify-content-end">
              <Button
                type="button"
                onClick={() => {
                  setModal(false); 
                  dispatch(getTaskList());
                }}
                className="btn-light"
              >{props.t("Fermer")}</Button>
              <button type="submit" className="btn btn-success" id="add-btn">{!!isEdit ? props.t("Modifier") :  props.t("Ajouter")}</button>
            </div>
          </div>
        </Form>
      </Modal>
      <ToastContainer closeButton={false} />
    </React.Fragment>
  );
}

AllTasks.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(AllTasks));
