import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";

import AuthSliderEn from '../authCarouselEn';

import ParticlesAuth from '../ParticlesAuth';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//PhoneInput
import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect, { isPossiblePhoneNumber } from 'react-phone-number-input';

// action
import { registerAffiliate , apiError, resetRegisterFlag } from "../../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';

const AffiliateSignUpEn = () => {

    const [phoneinput,setPhoneinput] = useState();
    const [valid,setValid] = useState();
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmpasswordShow, setConfirmPasswordShow] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: false,
        initialValues: {
            address:"",
            email:"",
            first_name:"",
            last_name:"",
            fonction:"",
            langue:"",
            country:"",
            tel: !phoneinput ? false : phoneinput,
            password: "",
            confirm_password:"",
        },
        validationSchema: Yup.object({
            address: Yup.string().required("Please Enter Your Adresse"),
            email: Yup.string().required("Please Enter Your Email"),
            first_name: Yup.string().required("Please Enter Your Firstname"),
            last_name: Yup.string().required("Please Enter Your Lastname"),
            fonction: Yup.string().required("Please Enter Your Fonction"),
            langue: Yup.string().required("Please Enter Your Language"),
            country: Yup.string().required("Please Enter Your Country"),
            password: Yup.string().required("Please Enter Your Password"),
            confirm_password: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Confirm Password Isn't Match"
                )
            })
        }),
        
        onSubmit: (values) => {
            const affiliate = {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                password: values.password,
                affilie: {
                    tel: phoneinput,
                    address: values.address,
                    pays: values.country,
                    country: values.country,
                    fonction: values.fonction,
                    langue: values.langue
                },
            }
            //console.log('Les valeurs envoyés pour affilié');
            //console.log(affiliate);
            dispatch(registerAffiliate(affiliate));
            //console.log('Les valeurs envoyés');
            //console.log(values);
            //console.log('environnement');
            //console.log(process.env.REACT_APP_DEFAULTAUTH);
        }
    });

    const { error, registrationError, success } = useSelector(state => ({
        registrationError: state.Account.registrationError,
        success: state.Account.success,
        error: state.Account.error
    }));

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            setTimeout(() => history.push("/affiliate/en/login"), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]);
    
    document.title = "Affiliate registration | Le Conseiller du BTP";

    return (
        <React.Fragment>
          <ParticlesAuth>
            <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                {/* <div className="bg-overlay"></div> */}
                <div style={{
                        backgroundColor: "#FFFFFF", opacity: 0, width: "100%", height: "100%", position: "absolute", top: 0,
                        left: 0
                }}>  
                </div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden m-0">
                                    <Row className="justify-content-center g-0">
                                        <AuthSliderEn />
                                        <Col lg={6} style={{ backgroundColor: "#ECECEE" }}>
                                            <div className="p-lg-5 p-4">
                                                    <div>
                                                        <h5 className="text-primary">Start your free trial now.</h5>
                                                        <p className="text-muted">Fill out the form below to start your free trial. We will be available to accompany you every step of the way.</p>
                                                    </div>

                                                <div className="mt-4">
                                                    <Form 
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    className="needs-validation" noValidate action="#">
                                                    {success && success ? (
                                                        <>
                                                            {toast("Your Redirect To Login Page...", { position: "top-right", hideProgressBar: false, className: 'bg-success text-white', progress: undefined, toastId: "" })}
                                                            <ToastContainer autoClose={2000} limit={1} />
                                                            <Alert color="success">
                                                                Register Affiliate Successfully and Your Redirect To Login Page...
                                                            </Alert>
                                                        </>
                                                    ) : null}

                                                    {error && error ? (
                                                        <Alert color="danger"><div>
                                                            {/* {registrationError} */}
                                                            Email has been Register Before, Please Use Another Email Address... </div></Alert>
                                                    ) : null}
                                                <Row>
                                                  <Col md={6}> 
                                                    <div className="mb-3">
                                                            {/* <label className="form-label" htmlFor="password-input">Password</label> */}
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="First Name"
                                                                    id="first_name"
                                                                    name="first_name"
                                                                    value={validation.values.first_name}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.first_name && validation.touched.first_name ? true : false}
                                                                />
                                                                {validation.errors.first_name && validation.touched.first_name ? (
                                                                    <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                                                ) : null}
                                                        </div>
                                                  </Col>
                                                  <Col md={6}> 
                                                    <div className="mb-3">
                                                            {/* <label className="form-label" htmlFor="password-input">Password</label> */}
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Last Name"
                                                                    id="last_name"
                                                                    name="last_name"
                                                                    value={validation.values.last_name}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.last_name && validation.touched.last_name ? true : false}
                                                                />
                                                                {validation.errors.last_name && validation.touched.last_name ? (
                                                                    <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                                                ) : null}
                                                        </div>
                                                  </Col>
                                                  <Col md={12}> 
                                                    <div className="mb-3">
                                                            {/* <label className="form-label" htmlFor="password-input">Password</label> */}
                                                                <Input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Email"
                                                                    id="email"
                                                                    name="email"
                                                                    value={validation.values.email}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.email && validation.touched.email ? true : false}
                                                                />
                                                                {validation.errors.email && validation.touched.email ? (
                                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                                ) : null}
                                                        </div>
                                                  </Col>
                                                  <Col md={12}> 
                                                    <div className="mb-3">
                                                            {/* <label className="form-label" htmlFor="password-input">Password</label> */}
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    type={passwordShow ? "text" : "password"}
                                                                    className="form-control pe-5 password-input"
                                                                    placeholder="Password"
                                                                    id="password-input"
                                                                    name="password"
                                                                    value={validation.values.password}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.password && validation.touched.password ? true : false}
                                                                />
                                                                {validation.errors.password && validation.touched.password ? (
                                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                ) : null}
                                                                <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                    id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                            </div>
                                                        </div>
                                                  </Col>
                                                  <Col md={12}>
                                                        <div className="mb-3">
                                                            {/* <Label htmlFor="confirmPassword" className="form-label">Confirm Password <span className="text-danger">*</span></Label> */}
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    name="confirm_password"
                                                                    type={confirmpasswordShow ? "text" : "password"}
                                                                    placeholder="Confirm Password"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.confirm_password || ""}
                                                                    invalid={
                                                                        validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                                    <FormFeedback type="invalid"><div>{validation.errors.confirm_password}</div></FormFeedback>
                                                                ) : null}
                                                                <Button color="link" onClick={() => setConfirmPasswordShow(!confirmpasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                    id="password-addon-2"><i className="ri-eye-fill align-middle"></i></Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                    <div className="mb-3">
                                                                        <PhoneInputWithCountrySelect
                                                                            defaultCountry="US"
                                                                            international
                                                                            placeholder="Téléphone"
                                                                            id="telephone"
                                                                            name="telephone"
                                                                            numberInputProps={{
                                                                                className: `form-control ${!valid ? 'border-danger' : 'border-success'}`
                                                                            }}
                                                                            value={validation.values.telephone || ""}
                                                                            onChange={(e) => {
                                                                                if(!isNaN(e))
                                                                                {
                                                                                    if(isPossiblePhoneNumber(e))
                                                                                    {
                                                                                        setPhoneinput(e)
                                                                                        setValid(true)
                                                                                    }
                                                                                    else{
                                                                                        setPhoneinput(e)
                                                                                        setValid(false)
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className={`text-danger ${valid ? 'd-none' : 'd-block'}`}>{valid === false ? 'Invalid phone Number' : ''}</label>
                                                                    </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            {/* <label htmlFor="address" className="form-label">Adresse <span className="text-danger">*</span></label> */}
                                                            <Input type="text" className="form-control" id="address" placeholder="Address"
                                                                name="address"
                                                                value={validation.values.address}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.address && validation.touched.address ? true : false} />
                                                            {validation.errors.address && validation.touched.address ? (
                                                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="langue">Langue</label> */}
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="fonction" value={validation.values.fonction} onChange={validation.handleChange}>
                                                                            <option value="">--- Function---</option>
                                                                            <option value="Entre">Entrepreneur</option>
                                                                            <option value="Indep">Independent</option>
                                                                            <option value="Salarie">Employee</option>
                                                                        </Input>
                                                                        {validation.errors.fonction && validation.touched.fonction ? (
                                                                            <FormFeedback type="invalid">{validation.errors.fonction}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                </Row>      

                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="country">Pays</label> */}
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="country" value={validation.values.country} onChange={validation.handleChange}>
                                                                            <option value="">--- Country ---</option>
                                                                            <option value="Afrique du Sud">South Africa</option>
                                                                            <option value="Afghanistan">Afghanistan</option>
                                                                            <option value="Albanie">Albania</option>
                                                                            <option value="Algérie">Algeria</option>
                                                                            <option value="Allemagne">Germany</option>
                                                                            <option value="Andorre">Andorra</option>
                                                                            <option value="Angola">Angola</option>
                                                                            <option value="Antigua-et-Barbuda">Antigua and Barbuda</option>
                                                                            <option value="Arabie Saoudite">Saudi Arabia</option>
                                                                            <option value="Argentine">Argentina</option>
                                                                            <option value="Arménie">Armenia</option>
                                                                            <option value="Australie">Australia</option>
                                                                            <option value="Autriche">Austria</option>
                                                                            <option value="Azerbaïdjan">Azerbaijan</option>
                                                                            <option value="Bahamas">Bahamas</option>
                                                                            <option value="Bahreïn">Bahrain</option>
                                                                            <option value="Bangladesh">Bangladesh</option>
                                                                            <option value="Barbade">Barbados</option>
                                                                            <option value="Belgique">Belgium</option>
                                                                            <option value="Belize">Belize</option>
                                                                            <option value="Bénin">Benin</option>
                                                                            <option value="Bhoutan">Bhutan</option>
                                                                            <option value="Biélorussie">Belarus</option>
                                                                            <option value="Birmanie">Myanmar (Burma)</option>
                                                                            <option value="Bolivie">Bolivia</option>
                                                                            <option value="Bosnie-Herzégovine">Bosnia and Herzegovina</option>
                                                                            <option value="Botswana">Botswana</option>
                                                                            <option value="Brésil">Brazil</option>
                                                                            <option value="Brunei">Brunei</option>
                                                                            <option value="Bulgarie">Bulgaria</option>
                                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                                            <option value="Burundi">Burundi</option>
                                                                            <option value="Cambodge">Cambodia</option>
                                                                            <option value="Cameroun">Cameroon</option>
                                                                            <option value="Canada">Canada</option>
                                                                            <option value="Cap-Vert">Cape Verde</option>
                                                                            <option value="Chili">Chile</option>
                                                                            <option value="Chine">China</option>
                                                                            <option value="Chypre">Cyprus</option>
                                                                            <option value="Colombie">Colombia</option>
                                                                            <option value="Comores">Comoros</option>
                                                                            <option value="Corée du Nord">North Korea</option>
                                                                            <option value="Corée du Sud">South Korea</option>
                                                                            <option value="Costa Rica">Costa Rica</option>
                                                                            <option value="Côte d’Ivoire">Ivory Coast</option>
                                                                            <option value="Croatie">Croatia</option>
                                                                            <option value="Cuba">Cuba</option>
                                                                            <option value="Danemark">Denmark</option>
                                                                            <option value="Djibouti">Djibouti</option>
                                                                            <option value="Dominique">Dominica</option>
                                                                            <option value="Égypte">Egypt</option>
                                                                            <option value="Émirats arabes unis">United Arab Emirates (UAE)</option>
                                                                            <option value="Équateur">Ecuador</option>
                                                                            <option value="Érythrée">Eritrea</option>
                                                                            <option value="Espagne">Spain</option>
                                                                            <option value="Eswatini">Eswatini</option>
                                                                            <option value="Estonie">Estonia</option>
                                                                            <option value="États-Unis">United States of America (USA)</option>
                                                                            <option value="Éthiopie">Ethiopia</option>
                                                                            <option value="Fidji">Fiji</option>
                                                                            <option value="Finlande">Finland</option>
                                                                            <option value="France">France</option>
                                                                            <option value="Gabon">Gabon</option>
                                                                            <option value="Gambie">Gambia</option>
                                                                            <option value="Géorgie">Georgia</option>
                                                                            <option value="Ghana">Ghana</option>
                                                                            <option value="Grèce">Greece</option>
                                                                            <option value="Grenade">Grenada</option>
                                                                            <option value="Guatemala">Guatemala</option>
                                                                            <option value="Guinée">Guinea</option>
                                                                            <option value="Guinée équatoriale">Equatorial Guinea</option>
                                                                            <option value="Guinée-Bissau">Guinea-Bissau</option>
                                                                            <option value="Guyana">Guyana</option>
                                                                            <option value="Haïti">Haiti</option>
                                                                            <option value="Honduras">Honduras</option>
                                                                            <option value="Hongrie">Hungary</option>
                                                                            <option value="Îles Cook">Cook Islands</option>
                                                                            <option value="Îles Marshall">Marshall Islands</option>
                                                                            <option value="Inde">India</option>
                                                                            <option value="Indonésie">Indonesia</option>
                                                                            <option value="Irak">Iraq</option>
                                                                            <option value="Iran">Iran</option>
                                                                            <option value="Irlande">Ireland</option>
                                                                            <option value="Islande">Iceland</option>
                                                                            <option value="Israël">Israel</option>
                                                                            <option value="Italie">Italy</option>
                                                                            <option value="Jamaïque">Jamaica</option>
                                                                            <option value="Japon">Japan</option>
                                                                            <option value="Jordanie">Jordan</option>
                                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                                            <option value="Kenya">Kenya</option>
                                                                            <option value="Kirghizistan">Kyrgyzstan</option>
                                                                            <option value="Kiribati">Kiribati</option>
                                                                            <option value="Koweït">Kuwait</option>
                                                                            <option value="Laos">Laos</option>
                                                                            <option value="Lesotho">Lesotho</option>
                                                                            <option value="Lettonie">Latvia</option>
                                                                            <option value="Liban">Lebanon</option>
                                                                            <option value="Liberia">Liberia</option>
                                                                            <option value="Libye">Libya</option>
                                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                                            <option value="Lituanie">Lithuania</option>
                                                                            <option value="Luxembourg">Luxembourg</option>
                                                                            <option value="Macédoine">North Macedonia</option>
                                                                            <option value="Madagascar">Madagascar</option>
                                                                            <option value="Malaisie">Malaysia</option>
                                                                            <option value="Malawi">Malawi</option>
                                                                            <option value="Maldives">Maldives</option>
                                                                            <option value="Mali">Mali</option>
                                                                            <option value="Malte">Malta</option>
                                                                            <option value="Maroc">Morocco</option>
                                                                            <option value="Maurice">Mauritius</option>
                                                                            <option value="Mauritanie">Mauritania</option>
                                                                            <option value="Mexique">Mexico</option>
                                                                            <option value="Micronésie">Micronesia</option>
                                                                            <option value="Moldavie">Moldova</option>
                                                                            <option value="Monaco">Monaco</option>
                                                                            <option value="Mongolie">Mongolia</option>
                                                                            <option value="Monténégro">Montenegro</option>
                                                                            <option value="Mozambique">Mozambique</option>
                                                                            <option value="Namibie">Namibia</option>
                                                                            <option value="Nauru">Nauru</option>
                                                                            <option value="Népal">Nepal</option>
                                                                            <option value="Nicaragua">Nicaragua</option>
                                                                            <option value="Niger">Niger</option>
                                                                            <option value="Nigeria">Nigeria</option>
                                                                            <option value="Niue">Niue</option>
                                                                            <option value="Norvège">Norway</option>
                                                                            <option value="Nouvelle-Zélande">New Zealand</option>
                                                                            <option value="Oman">Oman</option>
                                                                            <option value="Ouganda">Uganda</option>
                                                                            <option value="Ouzbékistan">Uzbekistan</option>
                                                                            <option value="Pakistan">Pakistan</option>
                                                                            <option value="Palaos">Palau</option>
                                                                            <option value="Palestine">Palestine</option>
                                                                            <option value="Panama">Panama</option>
                                                                            <option value="Papouasie-Nouvelle-Guinée">Papua New Guinea</option>
                                                                            <option value="Paraguay">Paraguay</option>
                                                                            <option value="Pays-Bas">Netherlands</option>
                                                                            <option value="Pérou">Peru</option>
                                                                            <option value="Philippines">Philippines</option>
                                                                            <option value="Pologne">Poland</option>
                                                                            <option value="Portugal">Portugal</option>
                                                                            <option value="Qatar">Qatar</option>
                                                                            <option value="République centrafricaine">Central African Republic</option>
                                                                            <option value="République démocratique du Congo">Democratic Republic of the Congo</option>
                                                                            <option value="République Dominicaine">Dominican Republic</option>
                                                                            <option value="République du Congo">Republic of the Congo</option>
                                                                            <option value="République tchèque">Czech Republic</option>
                                                                            <option value="Roumanie">Romania</option>
                                                                            <option value="Royaume-Uni">United Kingdom (UK)</option>
                                                                            <option value="Russie">Russia</option>
                                                                            <option value="Rwanda">Rwanda</option>
                                                                            <option value="Saint-Kitts-et-Nevis">Saint Kitts and Nevis</option>
                                                                            <option value="Saint-Vincent-et-les-Grenadines">Saint Vincent and the Grenadines</option>
                                                                            <option value="Sainte-Lucie">Saint Lucia</option>
                                                                            <option value="Saint-Marin">San Marino</option>
                                                                            <option value="Salomon">Solomon Islands</option>
                                                                            <option value="Salvador">El Salvador</option>
                                                                            <option value="Samoa">Samoa</option>
                                                                            <option value="São Tomé-et-Principe">São Tomé and Principe</option>
                                                                            <option value="Sénégal">Senegal</option>
                                                                            <option value="Serbie">Serbia</option>
                                                                            <option value="Seychelles">Seychelles</option>
                                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                                            <option value="Singapour">Singapore</option>
                                                                            <option value="Slovaquie">Slovakia</option>
                                                                            <option value="Slovénie">Slovenia</option>
                                                                            <option value="Somalie">Somalia</option>
                                                                            <option value="Soudan">Sudan</option>
                                                                            <option value="Soudan du Sud">South Sudan</option>
                                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                                            <option value="Suède">Sweden</option>
                                                                            <option value="Suisse">Switzerland</option>
                                                                            <option value="Suriname">Suriname</option>
                                                                            <option value="Syrie">Syria</option>
                                                                            <option value="Tadjikistan">Tajikistan</option>
                                                                            <option value="Tanzanie">Tanzania</option>
                                                                            <option value="Tchad">Chad</option>
                                                                            <option value="Thaïlande">Thailand</option>
                                                                            <option value="Timor oriental">East Timor (Timor-Leste)</option>
                                                                            <option value="Togo">Togo</option>
                                                                            <option value="Tonga">Tonga</option>
                                                                            <option value="Trinité-et-Tobago">Trinidad and Tobago</option>
                                                                            <option value="Tunisie">Tunisia</option>
                                                                            <option value="Turkménistan">Turkmenistan</option>
                                                                            <option value="Turquie">Turkey</option>
                                                                            <option value="Tuvalu">Tuvalu</option>
                                                                            <option value="Ukraine">Ukraine</option>
                                                                            <option value="Uruguay">Uruguay</option>
                                                                            <option value="Vanuatu">Vanuatu</option>
                                                                            <option value="Vatican">Vatican City (Holy See)</option>
                                                                            <option value="Venezuela">Venezuela</option>
                                                                            <option value="Viêt Nam">Vietnam</option>
                                                                            <option value="Yémen">Yemen</option>
                                                                            <option value="Zambie">Zambia</option>
                                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                                        </Input>
                                                                    </div>
                                                                </Col>


                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="langue">Langue</label> */}
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="langue" value={validation.values.langue} onChange={validation.handleChange}>
                                                                            <option value="">--- Language ---</option>
                                                                            <option value="Eng">English</option>
                                                                            <option value="Fr">French</option>
                                                                        </Input>
                                                                    </div>
                                                                </Col>

                                                            </Row>

                                                            <div className="mb-4">
                                                                <p className="mb-0 fs-12 text-muted fst-normal">By registering, you agree to our <a href="https://en.inawo.pro" className="text-primary text-decoration-underline fst-normal fw-medium">General terms and conditions of sale</a>, our <a href="https://en.inawo.pro" className="text-primary text-decoration-underline fst-normal fw-medium">Terms and conditions of use</a> and our <a href="https://en.inawo.pro/about/legal_notices" className="text-primary text-decoration-underline fst-normal fw-medium">Privacy Policy</a></p>
                                                            </div>

                                                            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                                <h5 className="fs-13">Password must contain:</h5>
                                                                <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 characters</b></p>
                                                                <p id="pass-lower" className="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                                                <p id="pass-upper" className="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                                                <p id="pass-number" className="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                                            </div>

                                                        <div className="mt-4">
                                                            <Button backgroud-color="#0166ff" className="btn w-100" type="submit">Register</Button>
                                                        </div>

                                                    </Form>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0">Already have an account ? <Link to="/affiliate/en/login" className="fw-semibold text-primary text-decoration-underline"> Se connecter </Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>

              
            </div>
          </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(AffiliateSignUpEn);