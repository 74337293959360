import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";

// crm Redux States
import {
  GET_PARTICULIERS,
  ADD_NEW_PARTICULIER,
  UPDATE_PARTICULIER,
} from "./actionType";

import {
  particulierApiResponseSuccess,
  particulierApiResponseError,

  addParticulierFail,
  addParticulierSuccess,
  updateParticulierSuccess,
  updateParticulierFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getParticuliers as getParticuliersApi,
  addNewParticulier,
  updateParticulier,
} from "../../helpers/fakebackend_helper";

function* getParticuliers() {
  try {
    const response = yield call(getParticuliersApi);
    yield put(particulierApiResponseSuccess(GET_PARTICULIERS, response));
  } catch (error) {
    yield put(particulierApiResponseError(GET_PARTICULIERS, error));
  }
}

function* onUpdateParticulier({ payload: particulier}) {
  console.log(particulier);
  try {
    const response = yield call(updateParticulier, particulier);
    console.log(response);
    yield put(updateParticulierSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(updateParticulierFail(error));
  }
}


function* onAddNewParticulier({ payload: particulier }) {
  try {
    console.log(particulier);
    const response = yield call(addNewParticulier, particulier);
    yield put(addParticulierSuccess(response));
  } catch (error) {
    yield put(addParticulierFail(error));
  }
}

export function* watchGetParticuliers() {
  yield takeEvery(GET_PARTICULIERS, getParticuliers);
}


export function* watchUpdateParticulier() {
  yield takeEvery(UPDATE_PARTICULIER, onUpdateParticulier);
}

export function* watchAddNewParticulier() {
  yield takeEvery(ADD_NEW_PARTICULIER, onAddNewParticulier);
}

function* particulierSaga() {
  yield all([
    fork(watchGetParticuliers),
    fork(watchUpdateParticulier),
    fork(watchAddNewParticulier),
  ]);
}

export default particulierSaga;
