import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  addNewCity as addNewCityApi,
  deleteCity as deleteCityApi,
  getCity as getCityApi,
  updateCity as updateCityApi,
} from "../../../helpers/fakebackend_helper";
import {
  addNewCityFail,
  addNewCitySuccess,
  deleteCityFail,
  deleteCitySuccess,
  cityApiResponseError,
  cityApiResponseSuccess,
  updateCityFail,
  updateCitySuccess,
} from "./action";
import { ADD_NEW_CITY, DELETE_CITY, GET_CITY, UPDATE_CITY } from "./actionType";

function* getCity() {
  try {
    const response = yield call(getCityApi);
    yield put(cityApiResponseSuccess(GET_CITY, response));
  } catch (error) {
    yield put(cityApiResponseError(GET_CITY, error));
  }
}

function* addNewCity(action) {
  try {
    const response = yield call(addNewCityApi, action.payload);
    yield put(addNewCitySuccess(response));
    toast.success("Ville ajoutée avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(addNewCityFail(error));
    toast.error("Erreur lors de l'ajout de la ville", { autoclose: 3000 });
  }
}

function* updateCity(action) {
  try {
    const response = yield call(updateCityApi, action.payload);
    yield put(updateCitySuccess(response));
    toast.success("Ville modifiée avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(updateCityFail(error));
    toast.error("Erreur lors de la modification de la ville", {
      autoclose: 3000,
    });
  }
}

function* deleteCity(action) {
  try {
    const response = yield call(deleteCityApi, action.payload);
    yield put(deleteCitySuccess(response));
    toast.success("Ville supprimée avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(deleteCityFail(error));
    toast.error("Erreur lors de la suppression de la ville", {
      autoclose: 3000,
    });
  }
}

export function* watchGetCity() {
  yield takeEvery(GET_CITY, getCity);
}

export function* watchAddNewCity() {
  yield takeEvery(ADD_NEW_CITY, addNewCity);
}

export function* watchUpdateCity() {
  yield takeEvery(UPDATE_CITY, updateCity);
}

export function* watchDeleteCity() {
  yield takeEvery(DELETE_CITY, deleteCity);
}

function* citySaga() {
  yield all([
    fork(watchGetCity),
    fork(watchAddNewCity),
    fork(watchUpdateCity),
    fork(watchDeleteCity),
  ]);
}

export default citySaga;
