import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
    const history = useHistory();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isComptes, setIsComptes] = useState(false);
    const [isContacts, setIsContacts] = useState(false);
    const [isParticuliers, setIsParticuliers] = useState(false);
    const [isEntreprises, setIsEntreprises] = useState(false);
    const [isAbonnements, setIsAbonnements] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isCatalogue, setIsCatalogue] = useState(false);
    const [isVente, setIsVente] = useState(false);
    const [isDepense, setIsDepense] = useState(false);
    const [isDocuments, setIsDocuments] = useState(false);
    const [isAgenda, setIsAgenda] = useState(false);
    const [isTaches, setIsTaches] = useState(false);
    const [isFichiers, setIsFichiers] = useState(false);
    const [isEntreprise, setisEntreprise] = useState(false);
    const [isDeals, setIsDeals] = useState(false);
    const [isLeads, setIsLeads] = useState(false);

    // Apps
    const [isEmail, setEmail] = useState(false);
    const [isSubEmail, setSubEmail] = useState(false);
    const [isDevis, setIsDevis] = useState(false);
    const [isFactures, setIsFactures] = useState(false);
    const [isAvoirs, setIsAvoirs] = useState(false);
    const [isCommandes, setIsCommandes] = useState(false);
    const [isLivraisons, setIsLivraisons] = useState(false);
    const [isNormalisees, setIsNormalisees] = useState(false);
    const [isProformas, setIsProformas] = useState(false);
    const [isSubParticulier, setSubParticulier] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [isProjects, setIsProjects] = useState(false);
    const [isTasks, setIsTasks] = useState(false);
    const [isCRM, setIsCRM] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isSupportTickets, setIsSupportTickets] = useState(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    const [isClients, setIsClients] = useState(false);
    const [isFournisseurs, setIsFournisseurs] = useState(false);
    const [isProspects, setIsProspects] = useState(false);
    const [isPartenaires, setIsPartenaires] = useState(false);
    const [isProduits, setIsProduits] = useState(false);
    const [isAddProduit, setIsAddProduit] = useState(false);
    const [isUsers, setIsUsers] = useState(false);
    const [isAddCategories, setIsAddCategories] = useState(false);
    const [isCategories, setIsCategories] = useState(false);
    const [isAddCategory, setIsAddCategory] = useState(false);
    const [isAddType, setIsAddType] = useState(false);
    const [isAffiliate, setIsAffiliate] = useState(false);
    const [isStatAffiliate, setIsStatAffiliate] = useState(false);
    const [isAbonner, setIsAbonner] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [islistMessage, setIslistMessage] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [isStock, setIsStock] = useState(false);
    const [isPartenaire, setIsPartenaire] = useState(false);
    const [isAbonnement, setIsAbonnement] = useState(false);
    const [isMessagerie, setIsMessagerie] = useState(false);

    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);


    // Charts
    const [isApex, setIsApex] = useState(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState(false);
    const [isLevel2, setIsLevel2] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }

        if (iscurrentState === 'Widgets') {
            history.push("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
        if (iscurrentState !== 'Contacts') {
            setIsContacts(false);
        }
        if (iscurrentState !== 'Abonnements') {
            setIsAbonnements(false);
        }
        if (iscurrentState !== 'Vente') {
            setIsVente(false);
        }
        if (iscurrentState !== 'Depense') {
            setIsDepense(false);
        }
        if (iscurrentState !== 'Documents') {
            setIsDocuments(false);
        }
        if (iscurrentState !== 'Agenda') {
            setIsAgenda(false);
        }
        if (iscurrentState !== 'Taches') {
            setIsTaches(false);
        }
        if (iscurrentState !== 'Fichiers') {
            setIsFichiers(false);
        }
        if (iscurrentState !== 'Entreprise') {
            setisEntreprise(false);
        }
        if (iscurrentState !== 'Entreprises') {
            setIsEntreprises(false);
        }
        if (iscurrentState !== 'Particuliers') {
            setIsParticuliers(false);
        }
        if (iscurrentState !== 'Deals') {
            setIsDeals(false);
        }
        if (iscurrentState !== 'Leads') {
            setIsLeads(false);
        }
        if (iscurrentState !== 'Utilisateurs') {
            setIsUsers(false);
        }
        if (iscurrentState !== 'Comptes') {
            setIsComptes(false);
        }
        if (iscurrentState !== 'Gestion des Stocks') {
            setIsStock(false);
        }
        if (iscurrentState !== "Partenaires") {
            setIsPartenaire(false);
        }
        if (iscurrentState !== "Abonnements") {
            setIsAbonnement(false);
        }
        if (iscurrentState !== "Messageries") {
            setIsMessagerie(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isContacts,
        isParticuliers,
        isEntreprises,
        isCatalogue,
        isVente,
        isAgenda,
        isDocuments,
        isTaches,
        isFichiers,
        isEntreprise,
        isLeads,
        isDeals,
        isUsers,
        isComptes,
        isStock,
        isPartenaire,
        isAbonnement,
        isMessagerie,
    ]);

    const menuItems = [
        // {
        //     label: "Menu",
        //     isHeader: true,
        // },
        {
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-dashboard-2-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },
        {
            id: "comptes",
            label: "Comptes",
            icon: "ri-account-circle-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsComptes(!isComptes);
                setIscurrentState('Comptes');
                updateIconSidebar(e);
            },
            stateVariables: isComptes,
            subItems: [
                {
                    id: "utilisateurs",
                    label: "Utilisateurs",
                    link: "/pages-team",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsUsers(!isUsers);
                    },

                },
                {
                    id: "entreprise",
                    label: "Entreprise",
                    icon: "ri-apps-2-line",
                    link: "/apps-crm-entreprise",
                    click: function (e) {
                        e.preventDefault();
                        setisEntreprise(!isEntreprise);
                        setIscurrentState('Entreprise');
                        updateIconSidebar(e);
                    },
                    stateVariables: isEntreprise,
                },
                {
                    id: "profil",
                    label: "Profil",
                    link: "/pages-profile",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsProfile(!isProfile);
                    },

                },
                

            ]
        },
        {
            id: "contacts",
            label: "Contacts",
            icon: "ri-contacts-book-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsContacts(!isContacts);
                setIscurrentState('Contacts');
                updateIconSidebar(e);
            },
            stateVariables: isContacts,
            subItems: [
                {
                    id: "particuliers",
                    label: "Particuliers",
                    link: "/apps-crm-particuliers",
                    
                    stateVariables: isParticuliers,
                    click: function (e) {
                        e.preventDefault();
                        setIsParticuliers(!isParticuliers);
                        setIscurrentState('Particuliers');
                    }
                },
                
                {
                    id: "entreprises",
                    label: "Entreprises",
                    link: "/apps-crm-entreprises",
                    isChildItem: false,
                    stateVariables: isEntreprises,
                    click: function (e) {
                        e.preventDefault();
                        setIsEntreprises(!isEntreprises);
                        setIscurrentState('Entreprises');
                        updateIconSidebar(e);
                    }
                }
                
            ]
        },

        // {
            //     id: "authentication",
            //     label: "Authentication",
            //     icon: "ri-account-circle-line",
            //     link: "/#",
            //     click: function (e) {
            //         e.preventDefault();
            //         setIsAuth(!isAuth);
            //         setIscurrentState('Auth');
            //         updateIconSidebar(e);
            //     },
            //     stateVariables: isAuth,
            //     subItems: [
            //         {
            //             id: "signIn",
            //             label: "Sign In",
            //             link: "/#",
            //             isChildItem: true,
            //             click: function (e) {
            //                 e.preventDefault();
            //                 setIsSignIn(!isSignIn);
            //             },
            //             parentId: "authentication",
            //             stateVariables: isSignIn,
            //             childItems: [
            //                 { id: 1, label: "Basic", link: "/auth-signin-basic" },
            //                 { id: 2, label: "Cover", link: "/auth-signin-cover" },
            //             ]
            //         },
        // {
        //     id: "abonnements",
        //     label: "Abonnements",
        //     icon: "ri-secure-payment-line",
        //     link: "/apps-crm-abonnements",
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsAbonnements(!isAbonnements);
        //         setIscurrentState('Abonnements');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: isAbonnements,
        // },
        
        
        // {
        //     id: "deals",
        //     label: "Vente",
        //     icon: "ri-apps-2-line",
        //     link: "/apps-crm-deals",
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsDeals(!isDeals);
        //         setIscurrentState('Deals');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: isDeals,
        // },
        
        // {
        //     label: "Produits et Services",
        //     isHeader: true,
        // },
        {
            id: "catalogue",
            label: "Catalogues",
            icon: "ri-book-open-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsCatalogue(!isCatalogue);
                setIscurrentState('Catalogue');
                updateIconSidebar(e);
            },
            stateVariables: isCatalogue,
            subItems: [
                {
                    id: "produits",
                    label: "Produits",
                    link: "/apps-ecommerce-products",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsEcommerce(!isEcommerce);
                    },

                }, 
                // {
                //     id: "addproduit",
                //     label: "Services",
                //     link: "/apps-ecommerce-services",
                //     isChildItem: false,
                //     click: function (e) {
                //         e.preventDefault();
                //         setIsAddProduit(!isAddProduit);
                //     },

                // },
                {
                    id: "addcategory",
                    label: "Catégories",
                    link: "/apps-ecommerce-categories",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsAddCategory(!isAddCategory);
                    },

                },
                // {
                //     id: "addtype",
                //     label: "Type de prestations",
                //     link: "/apps-ecommerce-types",
                //     isChildItem: false,
                //     click: function (e) {
                //         e.preventDefault();
                //         setIsAddType(!isAddType);
                //     },

                // },
            ]
        },
        {
            id: "stock",
            label: "Gestion des Stocks",
            icon: "ri-archive-fill",
            link: "/apps-stock",
            click: function (e) {
                e.preventDefault();
                setIsStock(!isStock);
                setIscurrentState('Stock');
                updateIconSidebar(e);
            }
        },
        {
            id: "depense",
            label: "Depenses",
            icon: "ri-funds-box-line",
            link: "/apps-depenses",
            click: function (e) {
                e.preventDefault();
                setIsDepense(!isDepense);
                setIscurrentState('Vente');
                updateIconSidebar(e);
            },
            stateVariables: isDepense,
        },
        {
            id: "vente",
            label: "Ventes",
            icon: "ri-funds-box-line",
            link: "/apps-ventes",
            click: function (e) {
                e.preventDefault();
                setIsDeals(!isDeals);
                setIscurrentState('Vente');
                updateIconSidebar(e);
            },
            stateVariables: isDeals,
        },
        // {
        //     label: "Gestion des documents",
        //     isHeader: true,
        // },
        {
            id: "documents",
            label: "Documents",
            icon: "ri-folder-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsDocuments(!isDocuments);
                setIscurrentState('Documents');
                updateIconSidebar(e);
            },
            stateVariables: isDocuments,
            subItems: [
              {
                    id: "factures",
                    label: "Factures",
                    link: "/apps-documents-factures",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsFactures(!isFactures);
                    },

                },  {
                    id: "devis",
                    label: "Devis",
                    link: "/apps-documents-devis",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsDevis(!isDevis);
                    },

                },
                 {
                    id: "facturesproforma",
                    label: "Factures proforma",
                    link: "/apps-documents-fproforma",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsProformas(!isProformas);
                    },

                },
                {
                    id: "avoirs",
                    label: "Factures d'Avoirs",
                    link: "/apps-documents-avoirs",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsAvoirs(!isAvoirs);
                    },

                },
                {
                    id: "bons_commande",
                    label: "Bons de commande",
                    link: "/apps-documents-bcommande",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsCommandes(!isCommandes);
                    },

                },
                
                {
                    id: "bons_livraison",
                    label: "Bons de Livraison",
                    link: "/apps-documents-blivraison",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsLivraisons(!isLivraisons);
                    },

                },
                {
                    id: "facturesnormalisée",
                    label: "Factures normalisées",
                    link: "/#",
                    badgeName: "Coming Soon",
                    badgeColor: "danger",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsNormalisees(!isNormalisees);
                    }
                },
                
            ]
        },
        {
            id: "agenda",
            label: "Agenda",
            icon: "ri-folder-history-line",
            link: "/apps-calendar",
            click: function (e) {
                e.preventDefault();
                setIsAgenda(!isAgenda);
                setIscurrentState('Agenda');
                updateIconSidebar(e);
            },
            stateVariables: isAgenda,
        },
        {
            id: "taches",
            label: "Tâches",
            icon: "ri-task-line",
            link: "/apps-tasks-list-view",
            click: function (e) {
                e.preventDefault();
                setIsTaches(!isTaches);
                setIscurrentState('Taches');
                updateIconSidebar(e);
            },
            stateVariables: isTaches,
        },
        {
            id: "fichiers",
            label: "Fichiers",
            badgeName: "Coming Soon",
            badgeColor: "danger",
            icon: "ri-stack-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsFichiers(!isFichiers);
                setIscurrentState('Fichiers');
                updateIconSidebar(e);
            },
            stateVariables: isFichiers,
        },
        // {
        //     label: "pages",
        //     isHeader: true,
        // },
        // {
        //     id: "authentication",
        //     label: "Authentication",
        //     icon: "ri-account-circle-line",
        //     link: "/#",
        //     click: function (e) {
        //         e.preventDefault();
        //         setIsAuth(!isAuth);
        //         setIscurrentState('Auth');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: isAuth,
        //     subItems: [
        //         {
        //             id: "signIn",
        //             label: "Sign In",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setIsSignIn(!isSignIn);
        //             },
        //             parentId: "authentication",
        //             stateVariables: isSignIn,
        //             childItems: [
        //                 { id: 1, label: "Basic", link: "/auth-signin-basic" },
        //                 { id: 2, label: "Cover", link: "/auth-signin-cover" },
        //             ]
        //         },
        //         {
        //             id: "signUp",
        //             label: "Sign Up",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setIsSignUp(!isSignUp);
        //             },
        //             parentId: "authentication",
        //             stateVariables: isSignUp,
        //             childItems: [
        //                 { id: 1, label: "Basic", link: "/auth-signup-basic" },
        //                 { id: 2, label: "Cover", link: "/auth-signup-cover" },
        //             ]
        //         },
        //         {
        //             id: "passwordReset",
        //             label: "Password Reset",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setIsPasswordReset(!isPasswordReset);
        //             },
        //             parentId: "authentication",
        //             stateVariables: isPasswordReset,
        //             childItems: [
        //                 { id: 1, label: "Basic", link: "/auth-pass-reset-basic" },
        //                 { id: 2, label: "Cover", link: "/auth-pass-reset-cover" },
        //             ]
        //         },
        //         {
        //             id: "passwordCreate",
        //             label: "Password Create",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setIsPasswordCreate(!isPasswordCreate);
        //             },
        //             parentId: "authentication",
        //             stateVariables: isPasswordCreate,
        //             childItems: [
        //                 { id: 1, label: "Basic", link: "/auth-pass-change-basic" },
        //                 { id: 2, label: "Cover", link: "/auth-pass-change-cover" },
        //             ]
        //         },
        //         {
        //             id: "lockScreen",
        //             label: "Lock Screen",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setIsLockScreen(!isLockScreen);
        //             },
        //             parentId: "authentication",
        //             stateVariables: isLockScreen,
        //             childItems: [
        //                 { id: 1, label: "Basic", link: "/auth-lockscreen-basic" },
        //                 { id: 2, label: "Cover", link: "/auth-lockscreen-cover" },
        //             ]
        //         },
        //         {
        //             id: "logout",
        //             label: "Logout",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setIsLogout(!isLogout);
        //             },
        //             parentId: "authentication",
        //             stateVariables: isLogout,
        //             childItems: [
        //                 { id: 1, label: "Basic", link: "/auth-logout-basic" },
        //                 { id: 2, label: "Cover", link: "/auth-logout-cover" },
        //             ]
        //         },
        //         {
        //             id: "successMessage",
        //             label: "Success Message",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setIsSuccessMessage(!isSuccessMessage);
        //             },
        //             parentId: "authentication",
        //             stateVariables: isSuccessMessage,
        //             childItems: [
        //                 { id: 1, label: "Basic", link: "/auth-success-msg-basic" },
        //                 { id: 2, label: "Cover", link: "/auth-success-msg-cover" },
        //             ]
        //         },
        //         {
        //             id: "twoStepVerification",
        //             label: "Two Step Verification",
        //             link: "/#",
        //             isChildItem: true,
        //             click: function (e) {
        //                 e.preventDefault();
        //                 setIsVerification(!isVerification);
        //             },
        //             parentId: "authentication",
        //             stateVariables: isVerification,
        //             childItems: [
        //                 { id: 1, label: "Basic", link: "/auth-twostep-basic" },
        //                 { id: 2, label: "Cover", link: "/auth-twostep-cover" },
        //             ]
        //         },

        //     ],
        // },

    ];

    const menuItemsAdmin = [
        {
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-dashboard-2-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },
        {
            id: "comptes",
            label: "Comptes",
            icon: "ri-account-circle-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsComptes(!isComptes);
                setIscurrentState('Comptes');
                updateIconSidebar(e);
            },
            stateVariables: isComptes,
            subItems: [
                {
                    id: "utilisateurs",
                    label: "Utilisateurs",
                    link: "/pages-admin-team",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsUsers(!isUsers);
                    },

                },
                {
                    id: "profil",
                    label: "Profil",
                    link: "/pages-admin-profile",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsProfile(!isProfile);
                    },

                },
            ]
        },
        {
            id: "partenaires",
            label: "Partenaires",
            icon: "ri-service-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsPartenaire(!isPartenaire);
                setIscurrentState('Partenaires');
                updateIconSidebar(e);
            },
            stateVariables: isPartenaire,
            subItems: [
                {
                    id: "affiliés",
                    label: "Affiliés",
                    link: "/apps-admin-affiliates",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsAffiliate(!isAffiliate);
                    },
                },
                {
                    id: "statistiques_affiliés",
                    label: "Statistiques Affiliés",
                    link: "/apps-admin-statistics-affiliates",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsStatAffiliate(!isStatAffiliate);
                    },

                },
            ]
        },
        {
            id: "abonnements",
            label: "Abonnements",
            icon: "ri-money-euro-circle-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsAbonnement(!isAbonnement);
                setIscurrentState('Abonnements');
                updateIconSidebar(e);
            },
            stateVariables: isAbonnement,
            subItems: [
                {
                    id: "abonnes",
                    label: "Abonnés",
                    link: "/apps-admin-abonnes",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsAbonner(!isAbonner);
                    },
                },
                {
                    id: "utilisateurs",
                    label: "Utilisateurs",
                    link: "/apps-admin-users",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsUser(!isUser);
                    },

                },
            ]
        },
        {
            id: "messageries",
            label: "Messageries",
            icon: "ri-chat-voice-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsMessagerie(!isMessagerie);
                setIscurrentState('Messageries');
                updateIconSidebar(e);
            },
            stateVariables: isMessagerie,
            subItems: [
                {
                    id: "liste_messages",
                    label: "Liste des messages",
                    link: "/apps-admin-list-messages",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIslistMessage(!islistMessage);
                    },
                },
                {
                    id: "notifications",
                    label: "Notifications",
                    link: "/apps-admin-notifications",
                    isChildItem: false,
                    click: function (e) {
                        e.preventDefault();
                        setIsNotification(!isNotification);
                    },

                },
            ]
        },
    ]

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
// import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";

// const Navdata = () => {
//     const history = useHistory();
//     //state data
//     const [isDashboard, setIsDashboard] = useState(false);
//     const [isContacts, setIsContacts] = useState(false);
//     const [isApps, setIsApps] = useState(false);
//     const [isAuth, setIsAuth] = useState(false);
//     const [isPages, setIsPages] = useState(false);
//     const [isBaseUi, setIsBaseUi] = useState(false);
//     const [isAdvanceUi, setIsAdvanceUi] = useState(false);
//     const [isForms, setIsForms] = useState(false);
//     const [isTables, setIsTables] = useState(false);
//     const [isCharts, setIsCharts] = useState(false);
//     const [isIcons, setIsIcons] = useState(false);
//     const [isMaps, setIsMaps] = useState(false);
//     const [isCatalogue, setIsCatalogue] = useState(false);
//     const [isVente, setIsVente] = useState(false);
//     const [isDocuments, setIsDocuments] = useState(false);
//     const [isAgenda, setIsAgenda] = useState(false);
//     const [isTaches, setIsTaches] = useState(false);
//     const [isFichiers, setIsFichiers] = useState(false);
//     const [isCompanies, setIsCompanies] = useState(false);
//     const [isDeals, setIsDeals] = useState(false);
//     const [isLeads, setIsLeads] = useState(false);

//     // Apps
//     const [isEmail, setEmail] = useState(false);
//     const [isSubEmail, setSubEmail] = useState(false);
//     const [isEcommerce, setIsEcommerce] = useState(false);
//     const [isProjects, setIsProjects] = useState(false);
//     const [isTasks, setIsTasks] = useState(false);
//     const [isCRM, setIsCRM] = useState(false);
//     const [isCrypto, setIsCrypto] = useState(false);
//     const [isInvoices, setIsInvoices] = useState(false);
//     const [isSupportTickets, setIsSupportTickets] = useState(false);
//     const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
//     const [isClients, setIsClients] = useState(false);
//     const [isFournisseurs, setIsFournisseurs] = useState(false);
//     const [isProspects, setIsProspects] = useState(false);
//     const [isPartenaires, setIsPartenaires] = useState(false);
//     const [isProduits, setIsProduits] = useState(false);
//     const [isAddProduit, setIsAddProduit] = useState(false);
//     const [isAddCategories, setIsAddCategories] = useState(false);
//     const [isCategories, setIsCategories] = useState(false);



//     // Authentication
//     const [isSignIn, setIsSignIn] = useState(false);
//     const [isSignUp, setIsSignUp] = useState(false);
//     const [isPasswordReset, setIsPasswordReset] = useState(false);
//     const [isPasswordCreate, setIsPasswordCreate] = useState(false);
//     const [isLockScreen, setIsLockScreen] = useState(false);
//     const [isLogout, setIsLogout] = useState(false);
//     const [isSuccessMessage, setIsSuccessMessage] = useState(false);
//     const [isVerification, setIsVerification] = useState(false);
//     const [isError, setIsError] = useState(false);

//     // Pages
//     const [isProfile, setIsProfile] = useState(false);
//     const [isLanding, setIsLanding] = useState(false);


//     // Charts
//     const [isApex, setIsApex] = useState(false);

//     // Multi Level
//     const [isLevel1, setIsLevel1] = useState(false);
//     const [isLevel2, setIsLevel2] = useState(false);

//     const [iscurrentState, setIscurrentState] = useState('Dashboard');

//     function updateIconSidebar(e) {
//         if (e && e.target && e.target.getAttribute("subitems")) {
//             const ul = document.getElementById("two-column-menu");
//             const iconItems = ul.querySelectorAll(".nav-icon.active");
//             let activeIconItems = [...iconItems];
//             activeIconItems.forEach((item) => {
//                 item.classList.remove("active");
//                 var id = item.getAttribute("subitems");
//                 if (document.getElementById(id))
//                     document.getElementById(id).classList.remove("show");
//             });
//         }
//     }

//     useEffect(() => {
//         document.body.classList.remove('twocolumn-panel');
//         if (iscurrentState !== 'Dashboard') {
//             setIsDashboard(false);
//         }
//         if (iscurrentState !== 'Apps') {
//             setIsApps(false);
//         }
//         if (iscurrentState !== 'Auth') {
//             setIsAuth(false);
//         }
//         if (iscurrentState !== 'Pages') {
//             setIsPages(false);
//         }
//         if (iscurrentState !== 'BaseUi') {
//             setIsBaseUi(false);
//         }
//         if (iscurrentState !== 'AdvanceUi') {
//             setIsAdvanceUi(false);
//         }
//         if (iscurrentState !== 'Forms') {
//             setIsForms(false);
//         }
//         if (iscurrentState !== 'Tables') {
//             setIsTables(false);
//         }
//         if (iscurrentState !== 'Charts') {
//             setIsCharts(false);
//         }
//         if (iscurrentState !== 'Icons') {
//             setIsIcons(false);
//         }
//         if (iscurrentState !== 'Maps') {
//             setIsMaps(false);
//         }
       
//         if (iscurrentState === 'Widgets') {
//             history.push("/widgets");
//             document.body.classList.add('twocolumn-panel');
//         }
//         if (iscurrentState !== 'Landing') {
//             setIsLanding(false);
//         }
//         if (iscurrentState !== 'Contacts') {
//             setIsContacts(false);
//         }
//         if (iscurrentState !== 'Vente') {
//             setIsVente(false);
//         }
//         if (iscurrentState !== 'Documents') {
//             setIsDocuments(false);
//         }
//         if (iscurrentState !== 'Agenda') {
//             setIsAgenda(false);
//         }
//         if (iscurrentState !== 'Taches') {
//             setIsTaches(false);
//         }
//         if (iscurrentState !== 'Fichiers') {
//             setIsFichiers(false);
//         }
//         if (iscurrentState !== 'Companies') {
//             setIsCompanies(false);
//         }
//         if (iscurrentState !== 'Deals') {
//             setIsDeals(false);
//         }
//         if (iscurrentState !== 'Leads') {
//             setIsLeads(false);
//         }
//     }, [
//         history,
//         iscurrentState,
//         isDashboard,
//         isApps,
//         isAuth,
//         isPages,
//         isBaseUi,
//         isAdvanceUi,
//         isForms,
//         isTables,
//         isCharts,
//         isIcons,
//         isMaps,
//         isContacts,
//         isCatalogue,
//         isVente,
//         isAgenda,
//         isDocuments,
//         isTaches,
//         isFichiers,
//         isCompanies,
//         isLeads,
//         isDeals
//     ]);

//     const menuItems = [
//         {
//             label: "Menu",
//             isHeader: true,
//         },
//         {
//             id: "dashboard",
//             label: "Dashbord",
//             icon: "ri-dashboard-2-line",
//             link: "/#",
//             stateVariables: isDashboard,
//             click: function (e) {
//                 e.preventDefault();
//                 setIsDashboard(!isDashboard);
//                 setIscurrentState('Dashboard');
//                 updateIconSidebar(e);
//             },
//         },
//         {
//             id: "contacts",
//             label: "Contacts",
//             icon: "ri-apps-2-line",
//             link: "/#",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsContacts(!isContacts);
//                 setIscurrentState('Contacts');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isContacts,
//             subItems: [
//                 {
//                     id: "listecontacts",
//                     label: "Liste des contacts",
//                     link: "/apps-crm-contacts",
//                     isChildItem: false,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsContacts(!isContacts);
//                     },
                   
//                 },
//                 {
//                     id: "clients",
//                     label: "Clients",
//                     link: "/#",
//                     isChildItem: false,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsClients(!isClients);
//                     },
                   
//                 },
//                 {
//                     id: "fournisseurs",
//                     label: "Fournisseurs",
//                     link: "/#",
//                     isChildItem: false,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsFournisseurs(!isFournisseurs);
//                     },
                   
//                 },
//                 {
//                     id: "prospects",
//                     label: "Prospects",
//                     link: "/#",
//                     isChildItem: false,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsProspects(!isProspects);
//                     },
                   
//                 },
//                 {
//                     id: "partenaires",
//                     label: "Partenaires",
//                     link: "/#",
//                     isChildItem: false,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsPartenaires(!isPartenaires);
//                     },
                   
//                 }
//             ]
//          },
//          {
//             id: "companies",
//             label: "Companies",
//             icon: "ri-apps-2-line",
//             link: "/apps-crm-companies",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsCompanies(!isCompanies);
//                 setIscurrentState('Companies');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isCompanies,
//         },
//         {
//             id: "deals",
//             label: "Deals",
//             icon: "ri-apps-2-line",
//             link: "/apps-crm-deals",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsDeals(!isDeals);
//                 setIscurrentState('Deals');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isDeals,
//         },
//         {
//             id: "leads",
//             label: "Leads",
//             icon: "ri-apps-2-line",
//             link: "/apps-crm-leads",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsLeads(!isLeads);
//                 setIscurrentState('Leads');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isLeads,
//         },
//         {
//             label: "Produits et Services",
//             isHeader: true,
//         },
//         {
//             id: "catalogue",
//             label: "Catalogues",
//             icon: "ri-apps-2-line",
//             link: "/#",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsCatalogue(!isCatalogue);
//                 setIscurrentState('Catalogue');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isCatalogue,
//             subItems: [
//                 {
//                     id: "produits",
//                     label: "Liste des produits",
//                     link: "/#",
//                     isChildItem: false,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsProduits(!isProduits);
//                     },
                   
//                 },
//                 {
//                     id: "addproduit",
//                     label: "Ajouter un produit",
//                     link: "/#",
//                     isChildItem: false,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsAddProduit(!isAddProduit);
//                     },
                   
//                 },
//             ]
//         },
//         {
//             id: "vente",
//             label: "Vente",
//             icon: "ri-apps-2-line",
//             link: "/#",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsVente(!isVente);
//                 setIscurrentState('Vente');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isVente,
//         },
//         {
//             label: "Gestion des documents",
//             isHeader: true,
//         },
//         {
//             id: "documents",
//             label: "Documents",
//             icon: "ri-apps-2-line",
//             link: "/#",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsDocuments(!isDocuments);
//                 setIscurrentState('Documents');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isDocuments,
//         },
//         {
//             id: "agenda",
//             label: "Agenda",
//             icon: "ri-apps-2-line",
//             link: "/#",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsAgenda(!isAgenda);
//                 setIscurrentState('Agenda');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isAgenda,
//         },
//         {
//             id: "taches",
//             label: "Taches",
//             icon: "ri-apps-2-line",
//             link: "/#",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsTaches(!isTaches);
//                 setIscurrentState('Taches');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isTaches,
//         },
//         {
//             id: "fichiers",
//             label: "Fichiers",
//             icon: "ri-apps-2-line",
//             link: "/#",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsFichiers(!isFichiers);
//                 setIscurrentState('Fichiers');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isFichiers,
//         },
//         {
//             label: "pages",
//             isHeader: true,
//         },
//         {
//             id: "authentication",
//             label: "Authentication",
//             icon: "ri-account-circle-line",
//             link: "/#",
//             click: function (e) {
//                 e.preventDefault();
//                 setIsAuth(!isAuth);
//                 setIscurrentState('Auth');
//                 updateIconSidebar(e);
//             },
//             stateVariables: isAuth,
//             subItems: [
//                 {
//                     id: "signIn",
//                     label: "Sign In",
//                     link: "/#",
//                     isChildItem: true,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsSignIn(!isSignIn);
//                     },
//                     parentId: "authentication",
//                     stateVariables: isSignIn,
//                     childItems: [
//                         { id: 1, label: "Basic", link: "/auth-signin-basic" },
//                         { id: 2, label: "Cover", link: "/auth-signin-cover" },
//                     ]
//                 },
//                 {
//                     id: "signUp",
//                     label: "Sign Up",
//                     link: "/#",
//                     isChildItem: true,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsSignUp(!isSignUp);
//                     },
//                     parentId: "authentication",
//                     stateVariables: isSignUp,
//                     childItems: [
//                         { id: 1, label: "Basic", link: "/auth-signup-basic" },
//                         { id: 2, label: "Cover", link: "/auth-signup-cover" },
//                     ]
//                 },
//                 {
//                     id: "passwordReset",
//                     label: "Password Reset",
//                     link: "/#",
//                     isChildItem: true,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsPasswordReset(!isPasswordReset);
//                     },
//                     parentId: "authentication",
//                     stateVariables: isPasswordReset,
//                     childItems: [
//                         { id: 1, label: "Basic", link: "/auth-pass-reset-basic" },
//                         { id: 2, label: "Cover", link: "/auth-pass-reset-cover" },
//                     ]
//                 },
//                 {
//                     id: "passwordCreate",
//                     label: "Password Create",
//                     link: "/#",
//                     isChildItem: true,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsPasswordCreate(!isPasswordCreate);
//                     },
//                     parentId: "authentication",
//                     stateVariables: isPasswordCreate,
//                     childItems: [
//                         { id: 1, label: "Basic", link: "/auth-pass-change-basic" },
//                         { id: 2, label: "Cover", link: "/auth-pass-change-cover" },
//                     ]
//                 },
//                 {
//                     id: "lockScreen",
//                     label: "Lock Screen",
//                     link: "/#",
//                     isChildItem: true,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsLockScreen(!isLockScreen);
//                     },
//                     parentId: "authentication",
//                     stateVariables: isLockScreen,
//                     childItems: [
//                         { id: 1, label: "Basic", link: "/auth-lockscreen-basic" },
//                         { id: 2, label: "Cover", link: "/auth-lockscreen-cover" },
//                     ]
//                 },
//                 {
//                     id: "logout",
//                     label: "Logout",
//                     link: "/#",
//                     isChildItem: true,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsLogout(!isLogout);
//                     },
//                     parentId: "authentication",
//                     stateVariables: isLogout,
//                     childItems: [
//                         { id: 1, label: "Basic", link: "/auth-logout-basic" },
//                         { id: 2, label: "Cover", link: "/auth-logout-cover" },
//                     ]
//                 },
//                 {
//                     id: "successMessage",
//                     label: "Success Message",
//                     link: "/#",
//                     isChildItem: true,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsSuccessMessage(!isSuccessMessage);
//                     },
//                     parentId: "authentication",
//                     stateVariables: isSuccessMessage,
//                     childItems: [
//                         { id: 1, label: "Basic", link: "/auth-success-msg-basic" },
//                         { id: 2, label: "Cover", link: "/auth-success-msg-cover" },
//                     ]
//                 },
//                 {
//                     id: "twoStepVerification",
//                     label: "Two Step Verification",
//                     link: "/#",
//                     isChildItem: true,
//                     click: function (e) {
//                         e.preventDefault();
//                         setIsVerification(!isVerification);
//                     },
//                     parentId: "authentication",
//                     stateVariables: isVerification,
//                     childItems: [
//                         { id: 1, label: "Basic", link: "/auth-twostep-basic" },
//                         { id: 2, label: "Cover", link: "/auth-twostep-cover" },
//                     ]
//                 },
               
//             ],
//         },
       
//     ];
//     return <React.Fragment>{menuItems}</React.Fragment>;
// };
// export default Navdata;