import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { useDispatch } from "react-redux";
import { CountriesCharts } from './DashboardAnalyticsCharts';
import { axiosIns } from '../../utils';


const LiveUsers = () => {
    const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;


   const [dashboard, setDashboard] = useState({})

   useEffect(() => {
      const fetch = async () => {
       try {
         const {data} = await axiosIns.get(`/dashboard/`, {
           headers: {
             Authorization : 'Bearer ' + token,
           },
         });
         setDashboard(data)
       } catch (err) {
         console.log(err);
       }
      }
       fetch();
   }, [token]);
   
    const dispatch = useDispatch();
  
    const seriesData = [
        {
            name:"Nombre d'utilisateurs",
            data: [
                typeof dashboard?.nb_admin === 'undefined' ? 0 : dashboard?.nb_admin ,
                typeof dashboard?.nb_commite  === 'undefined' ? 0 : dashboard?.nb_commite,
                typeof dashboard?.nb_expert_users === 'undefined' ? 0 : dashboard?.nb_expert_users,
                typeof dashboard?.nb_professional === 'undefined' ? 0 : dashboard?.nb_professional  ,
                typeof dashboard?.nb_organism  === 'undefined' ? 0 : dashboard?.nb_organism,
                typeof dashboard?.nb_enterprise === 'undefined' ? 0 : dashboard?.nb_enterprise ,
                typeof dashboard?.nb_particular === 'undefined' ? 0 : dashboard?.nb_particular
            ],
        },
    ];


    return (
        <React.Fragment>
            <Col xxl={12}>
                <Row className="h-100">
                   
                <Card className="card-height-100">
                            <div className="card-header align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1"> Nombre d'utilisateurs </h4>
                            </div>
                            <div className="card-body p-0">
                                <div>
                                    <CountriesCharts series={seriesData} dataColors='["#b38a12", "#b38a12", "#b38a12", "#b38a12", "#b38a12", "#b38a12", "#b38a12"]' />
                                </div>
                            </div>
                        </Card>


                </Row>
            </Col>
        </React.Fragment>
    );
};

export default LiveUsers;