import flagus from "../assets/images/flags/us.svg";
import flagfrench from "../assets/images/flags/france.svg";

const languages = {
  
  en: {
    label: "English",
    flag: flagus,
  },
  fr: {
    label: "Français",
    flag: flagfrench,
  },
}

export default languages
