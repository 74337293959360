import {
    GET_PARTICULIERS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
  
    UPDATE_PARTICULIER,
    UPDATE_PARTICULIER_SUCCESS,
    UPDATE_PARTICULIER_FAIL,
    
    ADD_NEW_PARTICULIER,
    ADD_PARTICULIER_SUCCESS,
    ADD_PARTICULIER_FAIL,

  } from "./actionType";

  // common success
  export const particulierApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  // common error
  export const particulierApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  
  export const getParticuliers = () => ({
    type: GET_PARTICULIERS,
  });

  export const updateParticulier = particulier => ({
    type: UPDATE_PARTICULIER,
    payload: particulier,
  });
  
  export const updateParticulierSuccess = particulier => ({
    type: UPDATE_PARTICULIER_SUCCESS,
    payload: particulier,
  });
  
  export const updateParticulierFail = error => ({
    type: UPDATE_PARTICULIER_FAIL,
    payload: error,
  });
  
  export const addNewParticulier = particulier => ({
    type: ADD_NEW_PARTICULIER,
    payload: particulier,
  });
  
  export const addParticulierSuccess = particulier => ({
    type: ADD_PARTICULIER_SUCCESS,
    payload: particulier,
  });
  
  export const addParticulierFail = error => ({
    type: ADD_PARTICULIER_FAIL,
    payload: error,
  });