import React, { useEffect, useState,useSelector } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import axios from 'axios';
import categoriesData from "../Categories/index"
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Select,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";


import classnames from "classnames";
import Dropzone from "react-dropzone";
import { Link, useHistory, useParams } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { toast } from "react-toastify";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const EcommerceUpdateCategories=(props)=>{
  document.title = props.t("Modifier Categories");
  const history = useHistory();
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(null);
  const [selectedVisibility, setselectedVisibility] = useState(null);
  
  const categoriesId = useParams('id');
  console.log(categoriesId);
  const url = "https://backend.leconseillerdubtp.com/api/v1";
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [selectCategory, setSelectCategory] = useState({})

  const DataUserType = [
    {
      label: "Particulier",
      value: "particular",
    },
    {
      label: "Professionnel du BTP",
      value: "professional",
    },
    {
      label: "Entreprise",
      value: "enterprise",
    },
    {
      label: "Organisme",
      value: "organism",
    },
  ];

  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/categories/${categoriesId.id}/`);
          setSelectCategory(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [categoriesId, url]);
    console.log("data",selectCategory);

  const [category,setCategoryList]=useState([]);
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/categories/`);
          setCategoryList(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

 
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "designation":selectCategory.designation,
      "user_type":selectCategory.user_type
    },
    
    validationSchema: Yup.object().shape({
      designation: Yup.string().required(props.t("Entrer un nom")),
      user_type: Yup.string().required(props.t("Entrer une catégorie")),
    }),

    onSubmit: (values) => {
        const newCategory = {
          id:selectCategory.id,
          designation: values.designation,
          user_type: values.user_type     
        }

        var count=0;
        if (newCategory){
          for (let i=0; i<=category.length;i++){
            if(newCategory.designation=== category[i]?.designation){
              count= count+1;
              history.push("/apps-categories");
              toast.error(props.t("La categorie existe deja"), { autoClose: 3000 });
              break;
            }
          }
          if (count===0){
            axios.patch(`${url}/categories/${categoriesId.id}/`, newCategory, { 
              headers: 
              {
                Authorization: 'Bearer ' + token,
              }
          })
          .then(res => {
            if(res){
              toast.success(props.t("Catégorie bien modifiée"), { autoClose: 3000 })
            }
          }, (error)=>{
            if(error){
              toast.error(props.t("Catégorie non modifiée"), { autoClose: 3000 })
            }
          }
          )
            history.push("/apps-categories")
            validation.resetForm();
            setTimeout(()=>window.location.reload(true),2000);
          }else{
            count=0;
          }

        }
        
       
  }
  });
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Modification de catégorie")} pageTitle={props.t("Catégorie")} />

        <Row>
          <Col lg={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                
              }}
              >
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="designationCat">
                      {props.t("Titre de la catégorie")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      name="designation"
                      value={validation.values.designation}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    />
                    {validation.errors.designation && validation.touched.designation ? (
                      <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
                    ) : null}
                  </div>

                  <Row>
                    <Col xl={12}>
                      <div className="mb-3">
                        <Label className="form-label"> {props.t("Type d'utilisateur")}  <span style={{color: "red"}}>*</span></Label>
                        <Input
                          className="form-control"
                          name="user_type"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.user_type}
                          required
                        >
                          <option value="">{validation.values.user_type}</option>
                            {DataUserType.map((item,key)=> (
                                <option value={item.value} key={key}>{item.label}</option>
                            ))
                            }
                        </Input>
                        {validation.errors.user_type && validation.touched.user_type ? (
                          <FormFeedback type="invalid"> {validation.errors.user_type} </FormFeedback>
                          ) : null 
                        }
                        
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              
              <div className="text-end mb-3">
              <button className="btn btn-primary mr-3">
                  <Link
                    to={`apps-categories`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm m-2">
                  {props.t("Modifier")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

EcommerceUpdateCategories.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(EcommerceUpdateCategories));
