import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';

import {
    GET_VENTES,
    ADD_NEW_VENTE,
    UPDATE_VENTE,
    DELETE_VENTE,
    
} from './actionType';

import {
    venteApiResponseSuccess,
    venteApiResponseError,
    addNewVenteSuccess,
    addNewVenteFail,
    updateVenteSuccess,
    updateVenteFail,
    deleteVenteSuccess,
    deleteVenteFail,
} from './action';

import {
    getVentes as getVenteApi,
    addNewVente,
    updateVente,
    deleteVente,
} from "../../helpers/fakebackend_helper";

function* getVentes() {
    try {
        const response = yield call(getVenteApi);
        yield put(venteApiResponseSuccess(GET_VENTES,response));
    } catch(error){
        yield put(venteApiResponseError(GET_VENTES,error));
    }
}

function* onaddNewVente({payload:vente}) {
    try {
        const response = yield call(addNewVente, vente);
        yield put(addNewVenteSuccess(response));
        toast.success('Vente ajoutée avec succès', { autoclose: 3000 });
    } catch(error){
        yield put(addNewVenteFail(error));
        toast.error('Erreur lors de l\'ajout de la vente', { autoclose: 3000 });
    }
}

function* onUpdateVente({payload:vente}) {
    try {
        const response = yield call(updateVente, vente);
        yield put(updateVenteSuccess(response));
        toast.success('Vente modifiée avec succès', { autoclose: 3000 });
    } catch(error){
        yield put(updateVenteFail(error));
        toast.error('Erreur lors de la modification de la vente', { autoclose: 3000 });
    }
}

function* onDeleteVente({payload:vente}) {
    try {
        const response = yield call(deleteVente, vente);
        yield put(deleteVenteSuccess({vente, ...response}));
        toast.success('Vente supprimée avec succès', { autoclose: 3000 });
    } catch(error){
        yield put(deleteVenteFail(error));
        toast.error('Erreur lors de la suppression de la vente', { autoclose: 3000 });
    }
}

export function* watchGetVente() {
    yield takeEvery(GET_VENTES, getVentes);
}

export function* watchAddNewVente() {
    yield takeEvery(ADD_NEW_VENTE, onaddNewVente);
}

export function* watchUpdateVente() {
    yield takeEvery(UPDATE_VENTE, onUpdateVente);
}

export function* watchDeleteVente() {
    yield takeEvery(DELETE_VENTE, onDeleteVente);
}

function* venteSaga() {
    yield all([
        fork(watchGetVente),
        fork(watchAddNewVente),
        fork(watchUpdateVente),
        fork(watchDeleteVente),
    ]);
}

export default venteSaga;
