import {
    GET_ENTREPRISES,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
  
    UPDATE_ENTREPRISE,
    UPDATE_ENTREPRISE_SUCCESS,
    UPDATE_ENTREPRISE_FAIL,
    
    ADD_NEW_ENTREPRISE,
    ADD_ENTREPRISE_SUCCESS,
    ADD_ENTREPRISE_FAIL,

  } from "./actionType";

  // common success
  export const entrepriseApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  // common error
  export const entrepriseApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  
  export const getEntreprises = () => ({
    type: GET_ENTREPRISES,
  });

  export const updateEntreprise = entreprise => ({
    type: UPDATE_ENTREPRISE,
    payload: entreprise,
  });
  
  export const updateEntrepriseSuccess = entreprise => ({
    type: UPDATE_ENTREPRISE_SUCCESS,
    payload: entreprise,
  });
  
  export const updateEntrepriseFail = error => ({
    type: UPDATE_ENTREPRISE_FAIL,
    payload: error,
  });
  
  export const addNewEntreprise = entreprise => ({
    type: ADD_NEW_ENTREPRISE,
    payload: entreprise,
  });
  
  export const addEntrepriseSuccess = entreprise => ({
    type: ADD_ENTREPRISE_SUCCESS,
    payload: entreprise,
  });
  
  export const addEntrepriseFail = error => ({
    type: ADD_ENTREPRISE_FAIL,
    payload: error,
  });