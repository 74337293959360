// Actions 
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR"; 
export const GET_VENTES = "GET_VENTES";

// Add VENTE
export const ADD_NEW_VENTE = "ADD_NEW_VENTE";
export const ADD_VENTE_SUCCESS = "ADD_VENTE_SUCCESS";
export const ADD_VENTE_FAIL = "ADD_VENTE_FAIL";

// Update VENTE
export const UPDATE_VENTE = "UPDATE_VENTE";
export const UPDATE_VENTE_SUCCESS = "UPDATE_VENTE_SUCCESS";
export const UPDATE_VENTE_FAIL = "UPDATE_VENTE_FAIL";

// Delete VENTE
export const DELETE_VENTE = "DELETE_VENTE";
export const DELETE_VENTE_SUCCESS = "DELETE_VENTE_SUCCESS";
export const DELETE_VENTE_FAIL = "DELETE_VENTE_FAIL";