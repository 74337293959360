import {
  LOGIN_ADMIN,
  LOGIN_SUCCESS_ADMIN,
  LOGOUT_ADMIN,
  LOGOUT_ADMIN_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  RESET_LOGIN_FLAG
} from "./actionTypes";

export const loginAdmin = (admin, history) => {
  return {
    type: LOGIN_ADMIN,
    payload: { admin, history },
  };
};

export const loginAdminSuccess = admin => {
  return {
    type: LOGIN_SUCCESS_ADMIN,
    payload: admin,
  };
};

export const logoutAdmin = history => {
  return {
    type: LOGOUT_ADMIN,
    payload: { history },
  };
};

export const logoutAdminSuccess = () => {
  return {
    type: LOGOUT_ADMIN_SUCCESS,
    payload: {},
  };
};

export const apiAdminError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const resetLoginAdminFlag = () => {
  return {
    type: RESET_LOGIN_FLAG,
  }
}
