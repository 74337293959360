import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Alert,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Select,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { addProductsCategories ,addProductsCategoriesFail} from "../../../store/ecommerce/action";
import {  useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const MetiersAdd = (props) => {

  const {error} = useSelector(state => ({
    error: state.Ecommerce.error
}));
  
  document.title = "Creation de catégories d'articles";

  const history = useHistory();
  const dispatch = useDispatch();

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://backend.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [metierList,setMetierList]=useState([]);
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/pros-metiers/`);
          setMetierList(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "niveau": null,
      "status": null,
      "professional": null,
      "formation": null,
      "formations_level": null,
      "fonction": null,
      "metier": null
    },
    
    validationSchema: Yup.object().shape({
    }),

    onSubmit: (values) => {
      const newCategory={
        designation_metier:values.designation_metier
      }

      var count=0;
        if (newCategory){
          for (let i=0; i<=metierList.length;i++){
            if(newCategory.designation_metier=== metierList[i]?.designation_metier){
              count= count+1;
              history.push("apps-metiers");
              toast.error(props.t("Le métier existe deja"), { autoClose: 3000 });
              break;
            }
          }
          if (count===0){
            axios.post(`${url}/metier/`,newCategory)
              .then(res => {
              if(res){
                toast.success(props.t("Metier bien ajoutée"), { autoClose: 3000 })
                history.push("apps-metiers")
              }else{
                toast.error(props.t("Metier non ajoutée"), { autoClose: 3000 })
              }
            })
            history.push("apps-metiers")
            validation.resetForm();
            // setTimeout(()=>window.location.reload(true),2000);
          }else{
            count=0;
          }
        }
  } 
  });
    useEffect(() => {
      dispatch(addProductsCategoriesFail(""));
  }, [dispatch]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Création de métier")} pageTitle={props.t("Metier")} />
        <Row>
          <Col lg={12}>
            <Form encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit(); 
              }}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="designation_metier">
                      {props.t("Titre du métier")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      placeholder={props.t("Entrer un nom")}
                      name="designation_metier"
                      value={validation.values.designation_metier}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    />
                    {validation.errors.designation_metier && validation.touched.designation_metier? (
                      <FormFeedback type="invalid">{validation.errors.designation_metier}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              
              <div className="text-end mb-3">
              <button className="btn btn-primary mr-3">
                  <Link
                    to={`apps-metiers`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm">
                 {props.t("Ajouter")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

MetiersAdd.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(MetiersAdd));