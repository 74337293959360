import React, { useEffect, useState, useMemo } from "react";
import fecthData from "../Categories/EcommerceUpdateCategories";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledCollapse,
  Row,
  Card,
  CardHeader,
  Col,
} from "reactstrap";
import classnames from "classnames";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../../Components/Common/DeleteModal";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
//Import data
import { productsData } from "../../../common/data";

//Import actions
import { getProductsCategories as onGetCategories, deleteProductsCategories, updateProductsCategories} from "../../../store/ecommerce/action";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";

const SingleOptions = [
  { value: 'Watches', label: 'Watches' },
  { value: 'Headset', label: 'Headset' },
  { value: 'Sweatshirt', label: 'Sweatshirt' },
  { value: '20% off', label: '20% off' },
  { value: '4 star', label: '4 star' },
];

const Categories = (props) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => ({
    categories :state.Ecommerce.categories,
    
    error:state.Ecommerce.error
  }));

  // Definition des etats
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []); 

  const [activeTab, setActiveTab] = useState("1");
  const [selectedMulti, setselectedMulti] = useState(null);
  const [category, setCategory] = useState(null);
  
  function handleMulti(products ,selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  
  // envoyer products s'il n'esxiste pas
  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(onGetCategories());
    }
  }, [dispatch, categories]);
 
  //  remplacer products par son existant dans l'etat
  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(categories)) setCategoryList(categories);
  }, [categories]);

  console.log("cate",categories);
  
  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredCategories = categories;
      if (type !== "all") {
        filteredCategories = categories.filter((category) => category.status === type);
      }
      setCategoryList(filteredCategories);
    }
  };

  const onUpdate = (value) => {
    setCategoryList(
      productsData.filter(
        (category) => category.price >= value[0] && category.price <= value[1],
      )
    );
  };

  const [ratingvalues, setRatingvalues] = useState([]);
  /*
  on change rating checkbox method
  */
  function verifyNameUser_type(user_type) {
    switch (user_type) {  
      case "particular":
        return "Particulier";
      case "professional":
        return "Professionnel du BTP";
      case "enterprise":
        return "Organisme";
      default:
        return user_type;
    }
  }


  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const onClickDelete = (category) => {
    setCategory(category);
    setDeleteModal(true);
  };

  const handleDeleteCategory = () => {
    if (category) {
      dispatch(deleteProductsCategories(category._id));
      setDeleteModal(false);
    }
  };


  const [dele, setDele] = useState(0);
  // Displat Delete Button
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = 'none';
    } else {
      del.style.display = 'block';
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      dispatch(deleteProductsCategories(element.value));
      del.style.display = 'none';
    });
  };
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  }
  
  const columns = useMemo(() => [
    {
      Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
      Cell: (cellProps) => {
        return <input type="checkbox" className="entrepriseCheckBox" value={cellProps.row.original.id} />;
      },
      id: '#',
    },
    {
      Header: props.t("CATEGORIE"),
      accessor:"designation",
      Cell: (category) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <p className="fs-14 mb-1">
                <Link
                  to="/apps-ecommerce-categories"
                  className="text-dark"
                >
                  {" "}
                  {category.row.original.designation}
                </Link>
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("Type d'utilisateur"),
      accessor:"user_type",
      Cell: (category) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <p className="fs-14 mb-1">
                <Link
                  to="/apps-ecommerce-categories"
                  className="text-dark"
                >
                  {" "}
                  {verifyNameUser_type(category.row.original.user_type)}
                </Link>
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "ACTIONS",
      Cell: (cellProps) => {
        const categoriesData = cellProps.row.original;
        return(
          <li className="list-inline-item" title={props.t("Modifier")}>
             <Link to={`apps-categories-update/${categoriesData.id}/`} className="text-warning d-inline-block"
                   >
                  <i className="ri-pencil-fill align-bottom"></i>
              </Link>
            </li> 
        );
      },
    },
  ],
    [fecthData, props]
  );

  document.title = props.t("Catégorie d'articles");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Catégories")} pageTitle={props.t("Categories d'articles")}/>

        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {   isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
                  categoryList && categoryList.length >=0 ? (
                    <TableContainer
                      columns={columns}
                      data={(categoryList || [])}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle "
                      theadClass="table-light text-muted"
                      isCategoryFilter={true}
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

Categories.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}

export default withRouter(withTranslation()(Categories));