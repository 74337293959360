import classnames from "classnames";
import React, { useEffect, useReducer, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import SwiperCore, { Autoplay } from "swiper";

import { document } from "../../../../common/data";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { axiosIns } from "../../../../utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, user: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false };

    default:
      return state;
  }
};

const SimplePage = (props) => {
  document.title = props.t("Profil | Le Conseiller du BTP");
  const [{ user }, dispatch] = useReducer(reducer, {
    loading: true,
    user: {},
    error: "",
  });
  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;
  const [abonne, setAbonne] = useState({});
  const [organisation, setOrganisation] = useState({});
  const [professionnel, setProfessionnel] = useState({});
  const [profile, setProfile] = useState({});

  const id = JSON.parse(sessionStorage.getItem("authUser")).id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_DATA" });
        const { data } = await axiosIns.get(`/profile/${id}/`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: data });
        setProfile(data);
        setAbonne(data.profile);
        setProfessionnel(data.professional);
        setOrganisation(data.organisation);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  SwiperCore.use([Autoplay]);

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg"></div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
            <Row className="g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  {
                    <img
                      src={abonne.profile_pic}
                      alt="user-img"
                      className="img-thumbnail rounded-circle"
                      style={{ width: 100, height: 100 }}
                    />
                  }
                </div>
              </div>

              <Col>
                <div className="p-2">
                  <h3 className="text-white mb-1">
                    {abonne.first_name} {abonne.last_name}
                  </h3>
                  <p className="text-white-75">
                    {abonne.user_type && abonne.user_type === "particular" ? (
                      <h5 style={{ color: "white" }}>Particulier</h5>
                    ) : abonne.user_type && abonne.user_type === "commite" ? (
                      <h5 style={{ color: "white" }}>Commité</h5>
                    ) : abonne.user_type && abonne.user_type === "expert" ? (
                      <h5 style={{ color: "white" }}>Expert</h5>
                    ) : abonne.user_type && abonne.user_type === "assistant" ? (
                      <h5 style={{ color: "white" }}>Assistant</h5>
                    ) : abonne.user_type &&
                      abonne.user_type === "professional" ? (
                      <h5 style={{ color: "white" }}>Professionnel</h5>
                    ) : abonne.user_type && abonne.user_type === "admin" ? (
                      <h5 style={{ color: "white" }}>Admin</h5>
                    ) : abonne.user_type && abonne.user_type === "organism" ? (
                      <h5 style={{ color: "white" }}>
                        Organisme de regulation
                      </h5>
                    ) : abonne.user_type && abonne.user_type === "bank" ? (
                      <h5 style={{ color: "white" }}>Banques et Assurance </h5>
                    ) : (
                      " "
                    )}
                  </p>
                  <p className="text-white gap-1">
                    <p>
                      <i className="ri-map-pin-user-line  text-white-75 fs-16 align-middle"></i>
                      {abonne.city} {abonne.country} {abonne.address}
                    </p>
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex">
                  <Nav
                    pills
                    className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          {props.t("Profil")}
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div className="flex-shrink-0">
                    <Link
                      to={`/pages-profile/${profile.id}/`}
                      className="btn btn-warning"
                    >
                      <i className="ri-edit-box-line align-bottom"></i>{" "}
                      {props.t("Modifier")}
                    </Link>
                  </div>
                </div>

                <TabContent activeTab={activeTab} className="pt-4">
                  <TabPane tabId="1">
                    <Row>
                      <Col xxl={3}>
                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-5">
                              {props.t("Complétez votre profil")}
                            </h5>
                            {user.first_name === null ||
                            user.last_name === null ||
                            user.email === null ||
                            user.telephone === null ||
                            user.adresse === null ||
                            user.pays === null ||
                            user.societe === null ||
                            user.fonction === null ||
                            user.langue === null ||
                            user.ville === null ||
                            user.photo === null ? (
                              <Progress
                                value={80}
                                color="danger"
                                className="animated-progess custom-progress progress-label"
                              >
                                <div className="label">50%</div>{" "}
                              </Progress>
                            ) : (
                              <Progress
                                value={100}
                                color="success"
                                className="animated-progess custom-progress progress-label"
                              >
                                <div className="label">90%</div>{" "}
                              </Progress>
                            )}
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-3">
                              {props.t("Informations")}
                            </h5>
                            <div className="table-responsive">
                              <Table className="table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      {props.t("Nom")} :
                                    </th>
                                    <td className="text-muted">
                                      {(abonne && abonne.last_name) ||
                                        props.t("Non renseignée")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      {props.t("Prénom(s)")} :
                                    </th>
                                    <td className="text-muted">
                                      {(abonne && abonne.first_name) ||
                                        props.t("Non renseignée")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      {props.t("Identifiant")} :
                                    </th>
                                    <td className="text-muted">
                                      {(profile && profile.username) ||
                                        props.t("Non renseignée")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      {props.t("Téléphone")} :
                                    </th>
                                    <td className="text-muted">
                                      {(profile && profile.phone_number) ||
                                        props.t("Non renseignée")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      {props.t("Adresse")} :
                                    </th>
                                    <td className="text-muted">
                                      {(abonne && abonne.address) ||
                                        props.t("Non renseignée")}
                                    </td>
                                  </tr>
                                  {abonne && abonne.user_type === "organism" ? (
                                    <>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Société")} :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation && organisation.nom) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Slogan")} :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.slogan) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Domaine d'exercice")} :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.domain) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Site Web")} :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.site_internet) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Adresse de l'entreprise")} :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.address) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Email de l'entreprise")} :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.email) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("RCCM")} :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.rccm) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Téléphone de l'entreprise")}{" "}
                                          :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.telephone) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Directeur de l'entreprise")}{" "}
                                          :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.contact_firstname +
                                              " " +
                                              organisation.contact_lastname) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Email du DG")} :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.contact_email) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          {props.t("Téléphone du DG")} :
                                        </th>
                                        <td className="text-muted">
                                          {(organisation &&
                                            organisation.contact_telephone) ||
                                            props.t("Non renseignée")}
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <tr>
                                      <th className="ps-0" scope="row">
                                        {props.t("Type d'utilisateur")} :
                                      </th>
                                      <td className="text-muted">
                                        {abonne.user_type === "admin" &&
                                          "Administrateur"}
                                        {abonne.user_type === "particular" &&
                                          "Particulier"}
                                        {abonne.user_type === "enterprise" &&
                                          "Entreprise"}
                                        {abonne.user_type === "professional" &&
                                          "Professionnel"}
                                        {abonne.user_type === "organism" &&
                                          "Organisme"}
                                        {abonne.user_type === "" &&
                                          props.t("Non renseignée")}
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      {props.t("Pays")} :
                                    </th>
                                    <td className="text-muted">
                                      {abonne.country ||
                                        props.t("Non renseignée")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      {props.t("Ville")} :
                                    </th>
                                    <td className="text-muted">
                                      {abonne.city || props.t("Non renseignée")}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th className="ps-0" scope="row">
                                      {props.t("Arrondissement")} :
                                    </th>
                                    <td className="text-muted">
                                      {(abonne && abonne.arrondissement) ||
                                        props.t("Non renseignée")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      {props.t("Quartier")} :
                                    </th>
                                    <td className="text-muted">
                                      {(abonne && abonne.quartier) ||
                                        props.t("Non renseignée")}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SimplePage.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SimplePage));
