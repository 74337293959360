import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import {
    CardBody,
    Row,
    Col,
    Card,
    Container,
    Form,
    Input,
    Label,
    Table,
} from "reactstrap"

import { isEmpty } from "lodash";

import { toast } from 'react-toastify';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
 
import { Link, useHistory, useParams } from "react-router-dom"

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { axiosIns } from '../../utils'



const AbonnementsUpdate = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;


  document.title = props.t("Modifier abonnement type | Le Conseiller du BTP");



  const history = useHistory();
  const dispatch = useDispatch();

  const venteId = useParams('id');

  const url = "https://backend.leconseillerdubtp.com/api/v1/"

        

      const prodId = useParams('id');
      console.log("code1" ,prodId);
     

    

      const [FormData, setFormData] = useState({});
        useEffect(() => {
            const fetchData = async ()=> {
                try {
                    const data = await axios.get(`${url}/abonnement-types/${venteId.id}/`);
                    setFormData(data);  
                } catch (err) {
                    console.log(err)
                }
            };
            fetchData(); 
        }, [venteId, url])

    const [details, setDetails] = useState([]);
    useEffect(() => {
      if (!isEmpty(FormData))
      setDetails(FormData.contents);
  }, [FormData]);


      const handleInputForm = (e) => {
        setFormData({ ...FormData, [e.target.name]: e.target.value });
      };

      const [errors, setErrors] = useState({});

      const categgory = [
        { label: "Prestataire", value: "prestataire" },
        { label: "Client", value: "client" }
      ];
  


const validate = () => {
  const newErrors = {};
      if (!FormData.designation) {
        newErrors.designation = "Ce champ est requis";
      }
      if (!FormData.description) {
        newErrors.description = "Ce champ est requis";
      }
      if (!FormData.montant) {
        newErrors.montant = "Ce champ est requis";
      }
      if (!FormData.nbr_mois) {
        newErrors.nbr_mois = "Ce champ est requis";
      }

      if (!FormData._type) {
        newErrors._type = "Ce champ est requis";
      }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

      const submitHandler = async (e) => {
        e.preventDefault();
        if (validate()) {

        const newVente = {
          "designation": FormData.designation,
          "description": FormData.description,
          "montant": FormData.montant,
          "nbr_mois": FormData.nbr_mois,
          "_type": FormData._type,
          "contents":details,
        }
    
        try {
          const { data } = await axiosIns.patch(`/abonnement-types/${venteId.id}/`, newVente, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          history.push("/apps-type");
          toast.success(props.t("Type abonnement modifié avec succès"));
        } catch (err) {
          console.log(err);
        }
      }
    }
  
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={props.t("Modifier un type d'abonnement ")} pageTitle={props.t("Modifier un type d'abonnement")} />
                <Row className="justify-content-center">
                    <Col xxl={9}>
                        <Card>
                            <Form
                            onSubmit={submitHandler}
                            className="needs-validation"
                            id="invoice_form"
                            >
                                 <CardBody className="p-4">
                                  <div className="table-responsive">
                                    <Table className="invoice-table table table-borderless mb-0">
                                    <thead className="align-middle">
                                              <tr className="table-active">
                                                  <th scope="col" style={{ width: "30px" }}>
                                                  </th>
                                                  <th scope="col" style={{ width: "50%" }}>{props.t("DESIGNATION")} <span className='text-danger'>*</span></th>
                                                 
                                                  <th scope="col" style={{ width: "50%"}}>
                                                    {props.t("CAPACITE")} <span className='text-danger'>*</span>
                                                  </th>
                                              </tr>
                                        </thead>

                                      {details && details.map((item, index) => (
                                        <React.Fragment key={index}>
                                          <tbody name={`productInfo-${index}`} key={index}>
                                                <tr id={index} className="product">
                                                  <th scope="row" className="product-id">
                                                    <Label>{index + 1}</Label>
                                                  </th>
                                                  <td className="text-start">
                                                    <div className="mb-2">
                                                      <Input 
                                                        readOnly
                                                        type="text"
                                                        className="form-control"
                                                        value={item.designation}
                                                        name={`contents[${index}].designation`}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                  <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="0.00"
                                                    readOnly
                                                    value={item.capacity}
                                                    name={`contents[${index}].capacity`}
                                                  />
                                                  </td>
                                                </tr>
                                            </tbody>

                                        </React.Fragment>
                                      ))}
                                         
                                                                              
                                    </Table>
                                  </div>
                                  
                                  
                                </CardBody>
                                <CardBody className="p-4">
                                <Row>
                                  <Col xl={6}>
                                    <div className="mb-3">
                                    <Label className="form-label" >
                                      {props.t("NOM DU TYPE D'ABONNEMENT")} <span style={{color: "red"}}>*</span>
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form"
                                      name="designation"
                                      value={FormData.designation}
                                      onChange={handleInputForm}
                                    />
                                    {errors.designation && <p className="text-danger">{props.t(errors.designation)}</p>}
                                    </div>
                                  </Col>
                                  <Col xl={6}>
                                      <div className="mb-3">
                                      <Label className="form-label">
                                        {props.t("DESCRIPTION")}  <span style={{color: "red"}}>*</span>
                                      </Label>
                                      <textarea
                                        className="form-control"
                                        name="description"
                                        value={FormData.description}
                                        onChange={handleInputForm}
                                        rows="2"
                                        cols="20"
                                        />
                                      {errors.description && <p className="text-danger">{props.t(errors.description)}</p>}
                                      </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl={6}>
                                    <div className="mb-3">
                                      <Label className="form-label" >
                                        {props.t("MONTANT")}  <span style={{color: "red"}}>*</span>
                                      </Label>
                                      <Input
                                        type="number"
                                        className="form-control"
                                        name="montant"
                                        value={FormData.montant}
                                        onChange={handleInputForm}
                                      />
                                    </div>
                                  </Col>
                                  <Col xl={6}>
                                    <div className="mb-3">
                                    <Label className="form-label" htmlFor="">
                                      {props.t("NOMBRE DE MOIS")}  <span style={{color: "red"}}>*</span>
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      name="nbr_mois"
                                      max={12}
                                      min={1}
                                      value={FormData.nbr_mois}
                                      onChange={handleInputForm}
                                    />
                                    {errors.nbr_mois && <p className="text-danger">{props.t(errors.nbr_mois)}</p>}
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label"> {props.t("TYPE DE CLIENT")}  <span style={{color: "red"}}>*</span></Label>
                                      <Input
                                        className="form-control"
                                        name="_type"
                                        type="select"
                                        value={FormData._type}
                                        onChange={handleInputForm}
                                      >
                                        <option value="">Sélectionner un type</option>
                                          {categgory.map((item,key)=> (
                                              <option value={item.value} key={key}>{item.label}</option>
                                          ))
                                          }
                                      </Input>
                                      {errors._type && <p className="text-danger">{props.t(errors._type)}</p>}
                                      
                                    </div>
                                  </Col>
                                </Row>
                                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    <button type="submit" className="btn btn-warning">
                                      <i className=" align-bottom me-1"></i> {props.t("Modifier")}
                                    </button>
                                  </div>
                                </CardBody>
                                
                             

                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )


}

AbonnementsUpdate.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AbonnementsUpdate));
