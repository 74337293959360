import React , {useState,useEffect}from 'react';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';

//Import Icons
import FeatherIcon from "feather-icons-react";

//import images
import illustarator from "../../assets/images/user-illustarator-2.png";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from'axios';

const UpgradeAccountNotise = (props) => {
    const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
    const [AbonnementCourant, setAbonnementCourant] = useState({})
    useEffect(() => {
      const checkTokenValidity = async () => {
          try {
          const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
          const getLastAbonnement = response.length - 1
          const currentAbonnement = response[getLastAbonnement]
          setAbonnementCourant(currentAbonnement)
          } catch (error) {
          console.log(error)
          } 
      };
      checkTokenValidity();
    }, []);


    const RestDays =(today)=>{
        var freeTrialDays= new Date(AbonnementCourant.start).toISOString().substring(0,10) - new Date(AbonnementCourant.end ).toISOString().substring(0,10);
        const restDay = freeTrialDays - new Date(today).toISOString().substring(0,10);
        return restDay;
    }

    const [daysRemaining, setDaysRemaining] = useState(0);
    
    useEffect(() => {
      const intervalId = setInterval(() => {
        const today = new Date();
        const timeDiff = new Date(AbonnementCourant.end ).getTime() - today.getTime();
        const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  
        if (days < 0) {
          setDaysRemaining(0);
        } else {
          setDaysRemaining(days);
        }
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, [AbonnementCourant.end]);
  
    // return (
    //   <div>
    //     {daysRemaining} {daysRemaining === 1 ? props.t('jour') : props.t('jours')} 
    //   </div>
    // );

  

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardBody className="p-0">
                            <Alert className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                                <FeatherIcon
                                    icon="alert-triangle"
                                    className="text-warning me-2 icon-sm"
                                />
                                <div className="flex-grow-1 text-truncate">
                                    {props.t("Votre période d'essai expire dans ")}<b>{daysRemaining}</b> {props.t("jours")}
                                </div>
                                <div className="flex-shrink-0">
                                    <Link to="/apps-crm-abonnements" className="text-reset text-decoration-underline"><b>{props.t("Mettre à niveau")}</b></Link>
                                </div>
                            </Alert>

                            <Row className="align-items-end">
                                <Col sm={8}>
                                    <div className="p-3">
                                        <p className="fs-16 lh-base">{props.t("Mettez à niveau votre plan d'")} <span className="fw-semibold">{props.t("Essai gratuit")} </span>{props.t("vers un Plan Premium")} <i className="mdi mdi-arrow-right"></i></p>
                                        <div className="mt-3">
                                            <Link to="/apps-crm-abonnements" className="btn btn-info">{props.t("Mettre à niveau")}</Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="px-3">
                                        <img src={illustarator} className="img-fluid" alt=" " />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};
UpgradeAccountNotise.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
  };
  
export default  withRouter(withTranslation()(UpgradeAccountNotise));
