import React, { useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Form
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import classnames from "classnames";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import axios from 'axios';

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import {useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
  
SwiperCore.use([FreeMode, Navigation, Thumbs]);



function AbonnementsDetail(props) {
  const articlesId = useParams('id');
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const history = useHistory()
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectAticle, setSelectArticle] = useState([])
  const url = "https://backend.leconseillerdubtp.com/api/v1";


  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(`${url}/abonnement-types/${articlesId.id}/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setSelectArticle(response)
        setSelectCategory(response.contents)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, [url]);



  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  document.title =props.t("Détail d'un type d'abonnement'");
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title={props.t("Détail d'un type d'abonnement")} pageTitle={props.t("Type d'abonnement")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  
                  <Col xl={12}>
                  <div className="mt-xl-0 mt-5">
                    <Row>
                      
                      <Col lg={12}  >
                        <div className="product-content ">
                            <Nav tabs className="nav-tabs-custom nav-warning">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleCustom("1");
                                  }}
                                >
                                { props.t("Détails")}
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <TabContent
                            activeTab={customActiveTab}
                              className="border border-top-0 p-4"
                              id="nav-tabContent"
                            >
                              <TabPane
                                id="nav-speci"
                                tabId="1"
                              >
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <tbody>
                                      <tr>
                                        <th scope="row">{props.t("Titre")}</th>
                                        <td className="text-blue-500" style={{color:"blue"}}>{selectAticle.designation}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row"> {props.t("Description")} </th>
                                        <td className="text-red-500" >{selectAticle.description}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row"> {props.t("Montant")} </th>
                                        <td className="text-red-500" style={{fontStyle:'italic'}}>{selectAticle.montant}</td>
                                      </tr>

                                      <tr>
                                        <th scope="row"> {props.t("Nombre de mois")} </th>
                                        <td className="text-red-500" style={{fontStyle:'italic'}}>{selectAticle.nbr_mois}</td>
                                      </tr>

                                      <tr>
                                        <th scope="row"> {props.t("Type d'abonnement")} </th>
                                        <td className="text-red-500" style={{fontStyle:'italic'}}>{selectAticle._type}</td>
                                      </tr>

                                      <tr>
                                        <th scope="row"> {props.t("Contenu de l'abonnement")} </th>
                                        <td className="text-red-500" style={{fontWeight:'bold'}}> 
                                          {selectCategory.map((item,key)=> (
                                            <React.Fragment key={key}>
                                              <p key={item.id}>{item.designation} {''} : {''} {item.capacity} </p>
                                            </React.Fragment>
                                          ))
                                          }</td>
                                      </tr>

                                    </tbody>
                                  </table>
                                  <div className="text-end mb-3">
                                    <button className="btn btn-warning m-3">
                                    <Link
                                        to={`/apps-type`}
                                        className="text-white"
                                    >
                                        {props.t("Retour")}
                                    </Link>
                                    </button>
                                    
                                </div>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                      </Col>
                    </Row>
                     

                  </div>
                  </Col>
                </Row>
               
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

AbonnementsDetail.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(AbonnementsDetail));