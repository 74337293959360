import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { verify } from '../../store/auth/validation/actions';

//import images
import logoLight from "../../assets/images/logo-light.png";

const Activate = ({verify, match}) => {
  document.title ="Verify Email | Le Conseiller du BTP";

  const [verified, setVerified] = useState(false);

  useEffect(() => {
    const uid = match.params.uid;
    const token = match.params.token;
    verify(uid, token);
    setVerified(true);
  }, [match.params.uid, match.params.token, verify])

  const savedLangue = sessionStorage.getItem("langsuccess")
  if (savedLangue === "fr") {
    if (verified) {
      return <Redirect to='/fr/connexion' />
    }
  } else if (savedLangue === "en") {
    if (verified) {
      return <Redirect to='/en/login' />
    }
  }
  
  return (

    <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                <img src={logoLight} alt="" height="20" />
                                            </Link>
                                        </div>
                                        <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4 text-center">
                                            <div className="avatar-lg mx-auto mt-2">
                                                <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                    <i className="ri-checkbox-circle-fill"></i>
                                                </div>
                                            </div>
                                            <div className="mt-4 pt-2">
                                                <h4>Verify your account !</h4>
                                                <p className="text-muted mx-4">Please click on the button to check your email.</p>
                                                <div className="mt-4">
                                                {/* <button onClick={verify_account} style={{ marginTop: '50px' }} type="button" className='btn btn-primary w-100'>
                                                  Verify
                                                </button> */}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment >
  
  )
}

export default connect(null, {verify})(Activate)