import React, { useEffect, useId, useReducer, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  Form,
  Input,
  Label,
  Table,
  FormFeedback,
} from 'reactstrap';

import { isEmpty } from "lodash";

import { Link, useHistory, withRouter, useParams } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from 'react-select';

import logoDark from '../../../assets/images/logo-dark.png';
import logoLight from '../../../assets/images/logo-light.png';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

//redux
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewInvoice as onAddNewInvoice,
  updateInvoice as onUpdateInvoice,
} from '../../../store/invoice/action';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import newId from '../../../../src/newid';
import { v4 as uuid } from 'uuid';
import { getContacts as onGetContacts } from "../../../store/crm/action"; 
import { getventes as onGetVentes } from "../../../store/ventes/action";
import logoInawoCRMBlanc1 from '../../../assets/images/logo-InawoCRM-Blanc-1.png';
import axios from 'axios';
import { axiosIns } from '../../../utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      return { ...state, itemlist: [...state.itemlist, action.payload] };
    }
    case 'REMOVE_ITEM': {
      const newItemListR = state.itemlist.filter(
        (item) => item.id !== action.payload.id
      );
      return { ...state, itemlist: newItemListR };
    }
    case 'UPDATE_DESCRIPTION': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
          ? state.itemlist.map((item) =>
              item.id === existItem.id ? {...item, description: newItem.description } : item
            )
          : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
  }  
    case 'UPDATE_QTE': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    case 'UPDATE_PRODUCT': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);

      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    default:
      return state;
  }
};

const BonLivraisonAdd = (props) => {
  //const uniqueId = newId();
  const [state, invDispatch] = useReducer(reducer, {
    itemlist: [
      {
        id: newId(),
        productId: newId(),
        description: '',
        qte: 1,
        qteStock: 1,
        prixProd: 0,
      },
    ],
  });
  const { itemlist } = state;
  const addItem = () => {
    invDispatch({
      type: 'ADD_ITEM',
      payload: {
        id: newId(),
        productId: newId(),
        description: '',
        qte: 1,
        qteStock: 1,
        prixProd: 0,
      },
    });
  };

  const removeItem = (item) => {
    //const newItem = 2;
    invDispatch({
      type: 'REMOVE_ITEM',
      payload: item,
    });
  };

  const updateQuantityHandler = (item, qte) => {
    //const newItem = 2;
    invDispatch({
      type: 'UPDATE_QTE',
      payload: { ...item, qte },
    });
  };

  const updateDescriptionHandler = (item, description) => {
    invDispatch({
      type: 'UPDATE_DESCRIPTION',
      payload: { ...item, description },
    });
  }

  const updateProductHandler = async (item, productId) => {
    try {
      const { data } = await axiosIns.get(`/products/${productId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      console.log(data);
      invDispatch({
        type: 'UPDATE_PRODUCT',
        payload: {
          ...item,
          productId: productId,
          qteStock: data.qteStock,
          prixProd: data.prixProd,
        },
      });
    } catch (err) {
      console.log(err);
      //toast.error(t('make_sure_internet'));
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const {ventes, crmcontacts } = useSelector((state) => ({
    ventes: state.Vente.ventes,
    crmcontacts: state.Crm.crmcontacts,
  }));

  const [venteList, setVenteList] = useState([]);
  const [contactList, setContactList] = useState([]);

  //Afficher un contact 
  useEffect(() => {
    if (crmcontacts && !crmcontacts.length) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContactList(crmcontacts);
  }, [crmcontacts]);
  console.log("contacts", crmcontacts);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) 
    setContactList(crmcontacts);
  }, [crmcontacts]);

  //Afficher une vente
  useEffect(() => {
    if (ventes && !ventes.length) {
      dispatch(onGetVentes());
    }
  }, [dispatch, ventes]);

  useEffect(() => {
    setVenteList(ventes);
  }, [ventes]);
  console.log("IDVentes", ventes);

  useEffect(() => {
    if (!isEmpty(ventes))
    setVenteList(ventes);
  }, [ventes]);

  // const [date1, setDate1] = useState(new Date());

  const [ispaymentDetails, setispaymentDetails] = useState(null);
  const [isCurrency, setisCurrency] = useState('$');

  function handleispaymentDetails(ispaymentDetails) {
    setispaymentDetails(ispaymentDetails);
  }

  function handleisCurrency(isCurrency) {
    setisCurrency(isCurrency);
  }

  const paymentdetails = [
    {
      options: [
        { label: props.t("Sélectionner la méthode de paiement"), value: "" },
        {label: props.t("Espèce"), value: "Espece"},
        {label: props.t("Chèque"), value: "Cheque"},
        { label: props.t("Master Card"), value: "Master Card" },
        { label: props.t("Visa"), value: "Visa" },
        { label: props.t("Paypal"), value: "Paypal" },
        {label: props.t("Virement") , value: "Virement"},
        {label: props.t("Mobile Money"), value: "Mobile Money"},
      ],
    },
  ];

  const allstatus = [
    {
      options: [
        {
          label: props.t('Select_Payment_Status'),
          value: props.t('Select_Payment_Status'),
        },
        { label: props.t('Paid'), value: props.t('Paid') },
        { label: props.t('Unpaid'), value: props.t('Unpaid') },
      ],
    },
  ];

  const allcurrency = [
    {
      options: [
        { label: 'FCFA', value: '(FCFA)' },
        { label: '$', value: '($)' },
        { label: '€', value: '(€)' },
      ],
    },
  ];

  const [count, setCount] = useState(0);
  const [rate, setRate] = useState(0);
  const [tax, setTax] = useState(0);
  const [dis, setDis] = useState(0);
  const [charge, setCharge] = useState(0);

  useEffect(() => {
    let tax = 0;
    let dis = 0;

    setTax(tax);
    setDis(dis);
  }, [rate, count]);

  const allstatuspayement = [
    {
      options: [
        { label: props.t("Sélectionner le status du payement"), value: "" },
        { label: props.t("En cours"), value: "En Cours" },
        { label: props.t("Terminé"), value: "Terminé" },
        { label: props.t("Non Payé"), value: "Non Payé"}
      ],
    },
  ];

  const dateFormat = () => {
    let d = new Date(),
      months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
    return (
      d.getDate() +
      ' ' +
      months[d.getMonth()] +
      ', ' +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const [taxpercent, setTaxpercent] = useState(0);
  const [discountpercent, setDiscountpercent] = useState(0);

  const dateformate = (e) => {
    const date = e.toString().split(' ');
    const joinDate = (date[2] + ' ' + date[1] + ', ' + date[3]).toString();
    setDate(joinDate);
  };

  document.title = props.t(
    "Ajout d\'un bon de livraison  | Le Conseiller du BTP"
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      postalcode: '',
      registration: '',
      email: '',
      website: '',
      contact: '',
      invoiceId: '',
      date: '',
      name: '',
      status: '',
      country: '',
      amount: '',
      billing_address: '',
      billing_phone: '',
      billing_taxno: '',
      shipping_name: '',
      shipping_address: '',
      shipping_phone: '',
      shipping_taxno: '',
      product_name: '',
    },
    validationSchema: Yup.object({
      /*  postalcode: Yup.string().required('This field is required'), */
      registration: Yup.string().required('Please Enter a registration no'),
      email: Yup.string().required('Please Enter a Email'),
      /* website: Yup.string().required('Please Enter a website'), */
      contact: Yup.string().required('Please Enter a contact number'),
      /* invoiceId: Yup.string().required('This field is required'), */
      name: Yup.string().required('Please Enter a Full name'),
      // country: Yup.string().required("Please Enter a Country"),
      billing_address: Yup.string().required('Please Enter a Address'),
      billing_phone: Yup.string().required('Please Enter a Phone Number'),
      billing_taxno: Yup.string().required('Please Enter a tax Number'),
      shipping_name: Yup.string().required('Please Enter a Full name'),
      shipping_address: Yup.string().required('Please Enter a Address'),
      shipping_phone: Yup.string().required('Please Enter a Phone Number'),
      shipping_taxno: Yup.string().required('Please enter a tax Number'),
      product_name: Yup.string().required('Please Enter a product Name'),
    }),
  });

  const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;

    const [entreprise,  setEntreprise] = useState({});
    useEffect(() => {
       const fetchEntreprise = async () => {
        try {
          const {data} = await axiosIns.get(`/entreprise/`, {
            headers: {
              Authorization : 'Bearer ' + token,
            },
          });
          console.log("first",data);
          setEntreprise(data);
        } catch (err) {
          console.log(err);
        }
       }
        fetchEntreprise();
    }, [token]);

  const [productslist, setProductslist] = useState([]);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await axiosIns.get(`/products/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        console.log(data);
        setProductslist(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProducts();
  }, [token]);


  const [FormData, setFormData] = useState({});

  const [selectedClient, setSelectedClient] = useState({});

  const handleChange = (e) => {
    console.log("e.value: ", e.target.value);
    if (e.target.value === "0") {
      setSelectedClient({});
    } else{
      const selectedClient = contactList.find((item) => item.id === parseInt(e.target.value));
      setSelectedClient(selectedClient);
    }
    
  }

  const [vente, setVente] = useState([]);
  const [venteCont, setVenteCont] = useState("");
  const [venteContInfo, setVenteContInfo] = useState({});
  const [details, setDetails] = useState([]);

  const handleventeselected =(e) => {
    setVente(venteList.find((item) => item.id === parseInt(e.target.value)))
    setDetails(venteList.find((item) => item.id === parseInt(e.target.value)).detailsVente)
    setVenteCont(venteList.find((item) => item.id === parseInt(e.target.value)).contact)
    setVenteContInfo(contactList.find((item) => item.id === parseInt(venteList.find((item) => item.id === parseInt(e.target.value)).contact)))
  }

  console.log("vente selected", vente);
  console.log("details Vente", details);
  console.log("venteCont", venteCont);
  console.log("venteContInfo", venteContInfo);

  const DetailsProduit = (j) => {
    for(let i = 0;i<=productslist.length; i++){
      if( j === productslist[i]?.id){
       return (productslist[i]?.designationProd) ;
      }
    }
  }

  const handleinputform = (e) => {
    setFormData({ ...FormData, [e.target.name]: e.target.value });
  };

  const [des, setDes] = useState([]);
  const [counter, setCounter] = useState(0);

  const handledescription = (e, item) => {
    const updatedDes = [...des];
    updatedDes[item.id] = e.target.value;
    setDes(updatedDes);
    setCounter(item);
  }

  const [errors, setErrors] = useState({});
  const [prod, setProd] = useState("");
  const validate = () => {
    const newErrors = {};
    if (isEmpty(vente)) {
      newErrors.vente = "Le numéro de la vente est requise";
      newErrors.statusPaiement = "Le statut du payement est requis";
      newErrors.product = "Le produit est requis";
      newErrors.typePaiement = "La méthode de paiement est requise";
      newErrors.contact = "Le contact est requis";
    }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };


  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      const livraisonDetails = [];
      details.map((item) =>
        livraisonDetails.push({
          qte: item.qteVendu,
          description: des[item.id] || "",
          produit: item.produit,
        })
      );
      const newLivraison = {
        contact: isEmpty(vente) ? selectedClient.id : vente.contact,
        modePaiement: isEmpty(vente) ? FormData.typePaiement : vente.typePaiement,
        statusPaiement: isEmpty(vente) ? FormData.statusPaiement : vente.statusPaiement === "Terminé" ? "Payé" : vente.statusPaiement,
        vente: isEmpty(vente) ? parseInt(FormData.vente) : vente.id,
        livraisonDetails: livraisonDetails,
        dateLivraison: new Date(),
        comment: FormData.comment ? FormData.comment :  "Merci pour votre achat",
  
      };
      console.log("newLivraison",newLivraison);
  
      try {
        const { data } = await axiosIns.post(`/livraisons/`, newLivraison, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        console.log(data);
        history.push("/apps-documents-blivraison");
        toast.success(props.t("Bon de Livraison enregistré avec succès"));
        // window.location.reload(true)
      } catch (err) {
        console.log(err);
        //toast.error(t('make_sure_internet'));
      }
      //history.push('apps-invoices-list');
    }
    
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t('Ajouter un bon de livraison')}
          pageTitle={props.t('Bon de Livraison')}
        />
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card>
              <Form onSubmit={submitHandler}>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={4}>
                      <div className="profile-user mx-auto  mb-3">
                        <Label style={{textAlign: "justify"}} >
                             <h3><strong>{entreprise && entreprise.nom}</strong></h3><br />
                            {entreprise && entreprise.capitalSocial === null ? false : props.t("Capital Social") + " : " + entreprise.capitalSocial } <br />
                            {entreprise && entreprise.num_enreg_legal1 === "" ? false : 
                              <div>
                                {entreprise.num_enreg_legal1}<br/>
                              </div>}
                            {entreprise && entreprise.num_enreg_legal2 === "" ? false : 
                            <div>
                              {entreprise.num_enreg_legal2}<br/>
                            </div>}
                            {entreprise && entreprise.adresse === "" ? false :
                            <div>
                              {entreprise.adresse}<br/>
                            </div>}
                            {entreprise && entreprise.siteweb  === null ? false : 
                            <div>
                              {entreprise.siteweb}<br/>
                            </div>}
                            {entreprise && entreprise.email === null ? false :
                            <div>
                              {entreprise.email}<br/>
                            </div>}
                            {entreprise && entreprise.telephone === "" ? false : 
                            <div>
                              {"Tel : " + entreprise.telephone}<br/>
                            </div>
                            }
                          </Label>
                      </div>
                    </Col>
                    <Col lg={4} className="ms-auto">
                      <div className='mb-2'>
                        <Label for='factureclient'>
                          {props.t("Sélectionner un client")} <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input 
                          type="select"
                          className="form-control bg-light border-0"
                          id="contact"
                          name="contact"
                          onChange={(e) => handleChange(e)}
                          onBlur={validation.handleBlur}
                          value={isEmpty(vente) ? FormData.contact || "" : vente.contact}
                        >
                            <option value="0">{props.t("Sélectionner un client")}</option>
                            {contactList.map((item, key)=> ( 
                                              item.category === "Entreprise" || item.category === "Institution"  || item.category === "Association" || item.category === "Administration" || item.category === "ONG" || item.category === "Particulier" || item.category === "Société" ?
                                                <option value={item.id} key={key}>{item.category === "Entreprise" || item.category === "Institution"  || item.category === "Association" || item.category === "Administration" || item.category === "ONG" || item.category === "Société" ? item.nomEntreprise + " - " + item.type_contact : item.nom + " - " + item.type_contact}</option> : null
                                            ))
                                            }
                        </Input>
                        {errors.contact && <p className='text-danger'>{props.t(errors.contact)}</p>}

                        <div className='row-4'>
                                    {selectedClient && selectedClient.category === "Entreprise" || selectedClient.category === "Institution" || selectedClient.category === "Association" || selectedClient.category === "Administration" || selectedClient.category === "ONG" || selectedClient.category === "Société" ? 
                                      (
                                        <div>
                                          <Label style={{textAlign: "justify"}} >
                                            {selectedClient&& selectedClient.nomEntreprise}<br />
                                                {selectedClient && selectedClient.num_enreg_legal1 === null ? false : 
                                                  <div>
                                                    {selectedClient.num_enreg_legal1}<br/>
                                                  </div>}
                                                {selectedClient && selectedClient.num_enreg_legal2 === null ? false : 
                                                <div>
                                                  {selectedClient.num_enreg_legal2}<br/>
                                                </div>}
                                                {selectedClient && selectedClient.adresse ===  null ? false :
                                                <div>
                                                  {selectedClient.adresse}<br/>
                                                </div>}
                                                {selectedClient && selectedClient.siteweb  === "" ? false : 
                                                <div>
                                                  {selectedClient.siteweb}<br/>
                                                </div>}
                                                {selectedClient && selectedClient.email === null ? false :
                                                <div>
                                                  {selectedClient.email}<br/>
                                                </div>}
                                                {selectedClient && selectedClient.telephone === null ? false : 
                                                <div>
                                                  {"Tel : " + selectedClient.telephone}<br/>
                                                </div>
                                                }
                                          </Label>
                                        </div> 
                                      )
                                      
                                      : selectedClient.category === "Particulier" ?
                                      (
                                        <div>
                                          <Label style={{textAlign: "justify"}}>
                                            {selectedClient && selectedClient.nom === "" ? false : 
                                            <div>
                                              {selectedClient.nom}<br/>
                                            </div>
                                            }
                                            {selectedClient && selectedClient.adresse === null ? false :
                                                <div>
                                                  {selectedClient.adresse}<br/>
                                                </div>}
                                            {selectedClient && selectedClient.telephone === "" ? false : 
                                                <div>
                                                  {"Tel : " + selectedClient.telephone}<br/>
                                                </div>
                                                }
                                          </Label>
                                        </div>
                                      ): null
                                      }
                        </div>

                        <div className='row-4'>
                                    {venteContInfo && venteContInfo.category === "Entreprise" || venteContInfo.category === "Institution" || venteContInfo.category === "Association" || venteContInfo.category === "Administration" || venteContInfo.category === "ONG" || venteContInfo.category === "Société" ? 
                                      (
                                        <div>
                                          <Label style={{textAlign: "justify"}} >
                                            {venteContInfo&& venteContInfo.nomEntreprise}<br />
                                                {venteContInfo && venteContInfo.num_enreg_legal1 === null ? false : 
                                                  <div>
                                                    {venteContInfo.num_enreg_legal1}<br/>
                                                  </div>}
                                                {venteContInfo && venteContInfo.num_enreg_legal2 === null ? false : 
                                                <div>
                                                  {venteContInfo.num_enreg_legal2}<br/>
                                                </div>}
                                                {venteContInfo && venteContInfo.adresse ===  null ? false :
                                                <div>
                                                  {venteContInfo.adresse}<br/>
                                                </div>}
                                                {venteContInfo && venteContInfo.siteweb  === "" ? false : 
                                                <div>
                                                  {venteContInfo.siteweb}<br/>
                                                </div>}
                                                {venteContInfo && venteContInfo.email === null ? false :
                                                <div>
                                                  {venteContInfo.email}<br/>
                                                </div>}
                                                {venteContInfo && venteContInfo.telephone === null ? false : 
                                                <div>
                                                  {"Tel : " + venteContInfo.telephone}<br/>
                                                </div>
                                                }
                                          </Label>
                                        </div> 
                                      )
                                      
                                      : venteContInfo.category === "Particulier" ?
                                      (
                                        <div>
                                          <Label style={{textAlign: "justify"}}>
                                            {venteContInfo && venteContInfo.nom === "" ? false : 
                                            <div>
                                              {venteContInfo.nom}<br/>
                                            </div>
                                            }
                                            {venteContInfo && venteContInfo.adresse === null ? false :
                                                <div>
                                                  {venteContInfo.adresse}<br/>
                                                </div>}
                                            {venteContInfo && venteContInfo.telephone === "" ? false : 
                                                <div>
                                                  {"Tel : " + venteContInfo.telephone}<br/>
                                                </div>
                                                }
                                          </Label>
                                        </div>
                                      ): null
                                      }
                        </div>            
 
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <div className="table-responsive">
                      <div className='container-fluid d-flex justify-content-center fw-semibold border-bottom border-bottom-dashed p-2' style={{ backgroundColor: 'whitesmoke' }}>
                        <h4 className='fw-semibold'>{props.t("BON DE LIVRAISON")}</h4>
                      </div>
                    </div>
                <CardBody className="border-bottom border-bottom-dashed p-4">
                  <Row className="g-3">
                    <Col lg={3} sm={6}>
                      <Label for="invoicenoInput" className="form-label text-muted text-uppercase fw-semibold">{props.t("BON DE LIVRAISON")} <span style={{ color: "red" }}>*</span></Label>
                  
                  { id ? (
                      <div>
                        <Input
                        type="text"
                        className="bg-light border-0"
                        name='invoiceNumber'
                        id='invoiceNumber'
                        value={id}
                        onChange={handleinputform}
                        readOnly
                      >
                      </Input>
                      </div>
                       ):
                       (
                        <div>
                          <Input 
                            type="select"
                            className="bg-light border-0"
                            name="vente"
                            id="invoiceNumber"
                            onChange={(e) => {
                              handleventeselected(e)
                              handleinputform(e)
                            }}
                            value={ FormData.vente || "" }
                          >
                            <option value="0">{props.t("Sélectionner le numéro de la vente")}</option>
                            { venteList.map((item, key)=> (
                                <option value={item.id} key={key}>{item.numVente}</option>
                            ))
                            }
                          </Input>
                          {errors.vente && <p className='text-danger'>{props.t(errors.vente)}</p>}
                        </div>
                       )
                      

                      }
                      
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <Label for="date-field" className="form-label text-muted text-uppercase fw-semibold">{props.t("DATE")}</Label>
                        {
                          isEmpty(vente) ?
                          <Flatpickr
                            name="date"
                            id="date-field"
                            className="form-control"
                            placeholder="Select a date"
                            options={{ 
                              defaultDate: new Date(),
                              minDate: new Date(),  // block dates before today
                              maxDate: new Date(),  // block dates after today
                            }}
                            onChange={date => {
                              setDate(date[0]);
                            }}
                          />
                          :
                          <Input
                            name="date"
                            type="text"
                            className="form-control"
                            id="date-field"
                            value={vente && vente.dateVente}
                            readOnly
                            />
                        }
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <Label for="choices-payment-status" className="form-label text-muted text-uppercase fw-semibold">{props.t("STATUS DU PAIEMENT")} <span style={{ color: "red" }}>*</span></Label>
                      <Input
                        name="statusPaiement"
                        type="select"
                        className="bg-light border-0"
                        id="choices-payment-status"
                        value={ isEmpty(vente) ? FormData.statusPaiement || '' : vente.statusPaiement }
                        onChange={handleinputform}
                      >
                        {allstatuspayement.map((item, key) => (
                            <React.Fragment key={key}>
                              {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                            </React.Fragment>
                          ))}
                      </Input>
                        {errors.statusPaiement && <p className='text-danger'>{props.t(errors.statusPaiement)}</p>}
                    </Col>
                    <Col lg={3} sm={6}>
                    <div className="mb-2">
                        <Label
                          for="choices-payment-type"
                          className="form-label text-muted text-uppercase fw-semibold"
                        >
                          {props.t("Méthode de paiement")} <span style={{ color: "red" }}>*</span>
                        </Label>
                        <div className="input-light">
                                        <Input
                                          name="typePaiement"
                                          type="select"
                                          className="bg-light border-0"
                                          id="choices-payment-status"
                                          onBlur={validation.handleBlur}
                                          onChange={handleinputform}
                                          value={ isEmpty(vente) ? FormData.typePaiement || "" : vente.typePaiement }
                                        >
                                          {paymentdetails.map((item, key) => (
                                              <React.Fragment key={key}>
                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                              </React.Fragment>
                                            ))}
                                          </Input>
                                          {errors.typePaiement && <p className="text-danger">{props.t(errors.typePaiement)}</p>}
                                      </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className="p-4">
                  <div className="table-responsive">
                    <Table className="invoice-table table-borderless table-nowrap mb-0">
                      <thead className="align-middle">
                        <tr className="table-active">
                          <th scope="col" style={{ width: '50px' }}>
                            #
                          </th>
                          <th scope="col" className='text-center'>{props.t("Désignation du produit")} <span style={{ color: "red" }}>*</span></th>
                          <th scope="col" className='text-center' style={{ width: '120px' }}>
                            {props.t("Quantité")} <span style={{ color: "red" }}>*</span>
                          </th>
                          <th
                            scope="col"
                            style={{ width: '105px' }}
                          ></th>
                        </tr>
                      </thead>
                      {
                        isEmpty(vente) ?
                          false
                        :
                          details.map((item, key) => (
                            <tbody key={key}>
                                <tr id={key} className="product">
                                  <th scope="row" className="product-id">
                                    {details.indexOf(item) + 1}
                                  </th>
                                  <th className="text-center">
                                  <div className="mb-2">
                                    <Label for="productnameInput" className="form-label">
                                      {DetailsProduit(item.produit)}
                                    </Label>
                                    <Input
                                    name='description'
                                    type="textarea" 
                                    className='form-control bg-light border-0'
                                    id={`productDescription-${item.id}`}
                                    placeholder={props.t("Description")}
                                    style={{ marginTop: '0.5rem' }}
                                    value={des[item.id] || ''}
                                    onChange={(e) => {
                                      handledescription(e, item)
                                    }}
                                  ></Input>
                                    </div>
                                  </th>
                                  <th className="text-center">
                                    <Label for="productqteInput" className="form-label">
                                      {item.qteVendu}
                                    </Label>
                                  </th>
                                </tr>
                            </tbody>
                          ))
                      }
                      <tbody>
                        <tr className="border-top border-top-dashed mt-2">
                          <td colSpan="3"></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="mt-4">
                    <Input
                      type="textarea"
                      name="comment"
                      className="form-control alert alert-info"
                      id="exampleFormControlTextarea1"
                      placeholder={props.t("Merci pour votre achat")}
                      onChange={handleinputform}
                      value={props.t(FormData.comment) || ""}
                      style={{ height: '110px' }}
                    />
                  </div>
                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    <button type="submit" className="btn btn-success">
                    <i className="ri-save-3-fill align-bottom me-1"></i>{' '}
                      {props.t("Enregistrer")}
                    </button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

BonLivraisonAdd.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};


export default withRouter(withTranslation()(BonLivraisonAdd));
