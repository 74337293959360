export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_ARRONDISSEMENTS = "GET_ARRONDISSEMENTS";

// Add ARRONDISSEMENT
export const ADD_NEW_ARRONDISSEMENT = "ADD_NEW_ARRONDISSEMENT";
export const ADD_ARRONDISSEMENT_SUCCESS = "ADD_ARRONDISSEMENT_SUCCESS";
export const ADD_ARRONDISSEMENT_FAIL = "ADD_ARRONDISSEMENT_FAIL";

// Update ARRONDISSEMENT
export const UPDATE_ARRONDISSEMENT = "UPDATE_ARRONDISSEMENT";
export const UPDATE_ARRONDISSEMENT_SUCCESS = "UPDATE_ARRONDISSEMENT_SUCCESS";
export const UPDATE_ARRONDISSEMENT_FAIL = "UPDATE_ARRONDISSEMENT_FAIL";

// Delete ARRONDISSEMENT
export const DELETE_ARRONDISSEMENT = "DELETE_ARRONDISSEMENT";
export const DELETE_ARRONDISSEMENT_SUCCESS = "DELETE_ARRONDISSEMENT_SUCCESS";
export const DELETE_ARRONDISSEMENT_FAIL = "DELETE_ARRONDISSEMENT_FAIL";

export const GET_ARRONDISSEMENTS_BY_ID = "GET_ARRONDISSEMENTS_BY_ID";
export const GET_ARRONDISSEMENTS_BY_ID_SUCCESS =
  "GET_ARRONDISSEMENTS_BY_ID_SUCCESS";
export const GET_ARRONDISSEMENTS_BY_ID_FAIL = "GET_ARRONDISSEMENTS_BY_ID_FAIL";
