import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";
import axios from "axios";
import { axiosIns } from '../../utils'
// Import Images
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
  Button
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

//Import actions
import {
  getEntreprises as onGetEntreprises,
  addNewEntreprise as onAddNewEntreprise,
  updateEntreprise as onUpdateEntreprise,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../../Components/Common/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from "react";
import { withTranslation } from "react-i18next";

const CrmEntreprises = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const { crmentreprises, isEntrepriseCreated, isEntrepriseSuccess, error } = useSelector((state) => ({
    crmentreprises: state.Entreprise.crmentreprises,
    isEntrepriseCreated: state.Entreprise.isEntrepriseCreated,
    isEntrepriseSuccess: state.Entreprise.isEntrepriseSuccess,
    error: state.Entreprise.error,
  }));

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }

  // Récuperer le type d'abonnement
  const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        const getLastAbonnement = response.length - 1
        const currentTypeAbonnement = response[getLastAbonnement].pricing
        setTypeAbonnementCourant(currentTypeAbonnement)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  // Récupérer la liste des contacts
  const [contact, setContact] = useState(null)
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://back.crm.inawo.pro/api/v1/contacts/");
        const getLastAbonnement = response.length
        console.log(getLastAbonnement)
        setContact(getLastAbonnement)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  // useEffect(() => {
  //   if (typeAbonnementCourant === 3 && contact === 19) {
  //     toast.error("Votre formule ne vous permet d'ajouter que 150 clients.")
  //   }
  // }, [typeAbonnementCourant, contact])
  
  const toastMe = () => {
    toast.error(props.t("Votre formule ne vous permet d'ajouter que 250 contacts."))
  };

  const toastMe2 = () => {
    toast.error(props.t("Votre formule ne vous permet d'ajouter que 350 contacts."))
  };
  useEffect(() => {
    // setTimeout(() => history.push("apps-crm-entreprises"), 1000);
    console.log(crmentreprises);
    if (crmentreprises && !isEntrepriseSuccess) {
      dispatch(onGetEntreprises());
    }
  }, [dispatch, crmentreprises,isEntrepriseSuccess, history, reducerValue]);

  useEffect(() => {
    setEntreprise(crmentreprises);
  }, [crmentreprises]);

  useEffect(() => {
    if (!isEmpty(crmentreprises)) {
      setEntreprise(crmentreprises);
      setIsEdit(false);
    }
  }, [crmentreprises]);


  const [isEdit, setIsEdit] = useState(false);
  const [entreprise, setEntreprise] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);


  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setEntreprise(null);
    } else {
      setModal(true);
      
    }
  }, [modal]);

  const onClickDelete = (entreprise) => {
    setEntreprise(entreprise);
    setDeleteModal(true);
  };

  // Add Data
  const handleEntrepriseClicks = () => {
    setEntreprise("");
    setIsEdit(false);
    toggle();
  };


  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  const timeFormat = () => {
    let d = new Date();
    let minutes = d.getMinutes().toString().length === 1 ? '0' + d.getMinutes() : d.getMinutes();
    let hours = (d.getHours().toString() % 12) || 12;
    hours = (hours <= 9) ? hours = ("0" + hours) : hours;
    let ampm = d.getHours() >= 12 ? 'PM' : 'AM';
    return (hours + ':' + minutes + ampm);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category: (entreprise && entreprise.category) || '',
      type_contact: (entreprise && entreprise.type_contact) || '',
      responsable: (entreprise && entreprise.responsable) || '',
      email: (entreprise && entreprise.email) || '',
      date_creation:(entreprise && entreprise.date_creation) || '',
      revenu_entreprise:(entreprise && entreprise.revenu_entreprise) || '',
      commentaire:(entreprise && entreprise.commentaire) || '',
      telephone: (entreprise && entreprise.telephone) || '',
      adresse: (entreprise && entreprise.adresse) || '',
      num_enreg_legal1: (entreprise && entreprise.num_enreg_legal1) || '',
      num_enreg_legal2: (entreprise && entreprise.num_enreg_legal2) || '',
      nomEntreprise: (entreprise && entreprise.nomEntreprise) || '',
      siteweb: (entreprise && entreprise.siteweb) || '',
      codePostal: (entreprise && entreprise.codePostal) || '',
      formeJuridique: (entreprise && entreprise.formeJuridique) || '',
      capitalSocial: (entreprise && entreprise.capitalSocial) || '',
      faxe: (entreprise && entreprise.faxe) || '',
      facebook: (entreprise && entreprise.facebook) || '',
      twitter: (entreprise && entreprise.twitter) || '',
      instagram: (entreprise && entreprise.instagram) || '',
      linkedin: (entreprise && entreprise.linkedin) || '',
      snapchat: (entreprise && entreprise.snapchat) || '',
      tiktok: (entreprise && entreprise.tiktok) || '',
      youtube: (entreprise && entreprise.youtube) || '',
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Veuillez renseigner la catégorie"),
      type_contact: Yup.string().required("Veuillez renseigner le type de contact"),
      telephone: Yup.string().required("Veuillez renseigner le numéro de téléphone"),
      nomEntreprise: Yup.string().required("Veuillez renseigner le nom de l'entreprise"),
      formeJuridique: Yup.string().required("Veuillez renseigner la forme juridique"),
    }),
    onSubmit: (values) => {
      console.log(isEdit);
      if (isEdit) {
        const updateEntreprise = {
          id: entreprise ? entreprise.id : 0,
          category: values.category,
          type_contact: values.type_contact,
          responsable: values.responsable,
          email: values.email,
          telephone: values.telephone,
          adresse: values.adresse,
          commentaire:values.commentaire,
          date_creation:values.date_creation,
          revenu_entreprise:values.revenu_entreprise,
          num_enreg_legal1: values.num_enreg_legal1,
          num_enreg_legal2: values.num_enreg_legal2,
          nomEntreprise: values.nomEntreprise,
          siteweb: values.siteweb,
          codePostal: values.codePostal,
          formeJuridique: values.formeJuridique,
          capitalSocial: values.capitalSocial,
          faxe: values.faxe,
          facebook: values.facebook,
          twitter: values.twitter,
          instagram: values.instagram,
          linkedin: values.linkedin,
          snapchat: values.snapchat,
          tiktok: values.tiktok,
          youtube: values.youtube,
        };
        // update Entreprise
        try {
          const data = axiosIns.patch(`/entreprises/${entreprise.id}/`, updateEntreprise, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }).then(response => {
            validation.resetForm();
            toast.success(props.t("Entreprise modifiée avec succès"), { autoClose: 3000 });
            dispatch(onGetEntreprises());
            handleClick()
          }).catch(error => {
            toast.error(props.t("Echec de la modification de l'Entreprise"), { autoClose: 3000 });
          })
          
        } catch (err) {
          console.log(err);
          toast.error(props.t("Echec de la modification de l'Entreprise"), { autoClose: 3000 });
        }
        dispatch(onGetEntreprises());
      } else {
        const newEntreprise = {
          category: values.category,
          type_contact: values.type_contact,
          responsable: values.responsable,
          email: values.email,
          revenu_entreprise:values.revenu_entreprise,
          commentaire:values.commentaire,
          date_creation:values.date_creation,
          telephone: values.telephone,
          adresse: values.adresse,
          num_enreg_legal1: values.num_enreg_legal1,
          num_enreg_legal2: values.num_enreg_legal2,
          nomEntreprise: values.nomEntreprise,
          siteweb: values.siteweb,
          codePostal: values.codePostal,
          formeJuridique: values.formeJuridique,
          capitalSocial: values.capitalSocial,
          faxe: values.faxe,
          facebook: values.facebook,
          twitter: values.twitter,
          instagram: values.instagram,
          linkedin: values.linkedin,
          snapchat: values.snapchat,
          tiktok: values.tiktok,
          youtube: values.youtube,
          photo: null,
        };

        console.log("newEntreprise",newEntreprise);
        // save new Entreprise

        const fetchData = async () => {
          try {
            const data = axiosIns.post(`/entreprises/`, newEntreprise, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            }).then(response => {
              toast.success(props.t("Entreprise ajoutée avec succès"), { autoClose: 3000 });
              dispatch(onGetEntreprises());
              handleClick()
            }).catch(error => {
              toast.error(props.t("Echec de l'ajout de l'Entreprise"), { autoClose: 3000 });
            });
            
          } catch (err) {
            console.log(err);
            toast.error(props.t("Echec de l'ajout de l'Entreprise"), { autoClose: 3000 });
          }
        };
        fetchData();
        validation.resetForm();
        dispatch(onGetEntreprises());       
      }
      toggle();
    },
  });

  // Update Data
  const handleEntrepriseClick = useCallback((arg) => {
    const entreprise = arg;

    setEntreprise({
      id: entreprise.id,
      category: entreprise.category,
      type_contact: entreprise.type_contact,
      responsable: entreprise.responsable,
      email: entreprise.email,
      telephone: entreprise.telephone,
      adresse: entreprise.adresse,
      commentaire:entreprise.commentaire,
      date_creation:entreprise.date_creation,
      revenu_entreprise:entreprise.revenu_entreprise,
      num_enreg_legal1: entreprise.num_enreg_legal1,
      num_enreg_legal2: entreprise.num_enreg_legal2,
      nomEntreprise: entreprise.nomEntreprise,
      siteweb: entreprise.siteweb,
      codePostal: entreprise.codePostal,
      formeJuridique: entreprise.formeJuridique,
      capitalSocial: entreprise.capitalSocial,
      faxe: entreprise.faxe,
      facebook: entreprise.facebook,
      twitter: entreprise.twitter,
      instagram: entreprise.instagram,
      linkedin: entreprise.linkedin,
      snapchat: entreprise.snapchat,
      tiktok: entreprise.tiktok,
      youtube: entreprise.youtube,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Node API 
  useEffect(() => {
    if (isEntrepriseCreated) {
      setEntreprise(null);
      dispatch(onGetEntreprises());
    }
  }, [
    dispatch,
    isEntrepriseCreated,
  ]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
    return updateTime;
  };


  // Checked All
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };


  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="entrepriseCheckBox" value={cellProps.row.original.id} />;
        },
        id: '#',
      },
      {
        Header: props.t("Image"),
        accessor: "name",
        filterable: false,
        Cell: (entreprise) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {entreprise.row.original.image_src ? <img
                  src={process.env.REACT_APP_API_URL + "/images/users/" + entreprise.row.original.image_src}
                  alt=""
                  className="avatar-xxs rounded-circle"
                /> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {entreprise.row.original.responsable===null && entreprise.row.original.nomEntreprise.charAt(0)}
                      {entreprise.row.original.responsable!==null && entreprise.row.original.responsable.charAt(0)}
                    </div>
                  </div>
                  // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                }
              </div>
              {/* <div className="flex-grow-1 ms-2 name">
                {entreprise.row.original.name}
              </div> */}
            </div>
          </>
        ),
      },
      // {
      //   Header: props.t("Responsable"),
      //   accessor: "responsable",
      //   filterable: false,
      // },
      // {
        //   Header: "Tel",
        //   accessor: "phone",
        //   filterable: false,
        // },
        {
          Header: props.t("Entreprise"),
          accessor: "nomEntreprise",
          filterable: false,
        },
        {
          Header: props.t("Email"),
          accessor: "email",
          filterable: false,
        },
      {
        Header: props.t("Type contact"),
        Cell: (entreprise) => (
          <>
            {/* {entreprise.row.original.tags.map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))} */}
            <span className="badge badge-soft-secondary me-1" >{props.t(entreprise.row.original.type_contact)}</span>
          </>
        ),
      },
      {
        Header: props.t("Catégorie"),
        Cell: (entreprise) => (
          <>
            {/* {entreprise.row.original.tags.map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))} */}
            <span className="badge badge-soft-danger me-1" >{props.t(entreprise.row.original.category)}</span>
          </>
        ),
      },
      {
        Header: props.t("Forme Juridique"),
        Cell: (entreprise) => (
          <>
            {/* {entreprise.row.original.tags.map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))} */}
            <span className="badge badge-soft-warning me-1" >{entreprise.row.original.formeJuridique}</span>
          </>
        ),
      },
      // {
      //   Header: "Last Entrepriseed",
      //   Cell: (entreprise) => (
      //     <>
      //       {handleValidDate(entreprise.row.original.last_entrepriseed)},{" "}
      //       <small className="text-muted">{handleValidTime(entreprise.row.original.last_entrepriseed)}</small>
      //     </>
      //   ),
      // },
      {
        Header: props.t("Actions"),
        Cell: (cellProps) => {
          const entrepriseData = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title={props.t("Appeler")}>
                <a href={"tel:+" + cellProps.row.original.telephone} className="text-primary d-inline-block">
                  <i className="ri-phone-line fs-16"></i>
                </a>
              </li>
              <li className="list-inline-item edit" title={props.t("Mail")}>
                <a href={"mailto:" + cellProps.row.original.email} className="text-secondary d-inline-block">
                  <i className="ri-question-answer-line fs-16"></i>
                </a>
              </li>
              {/* <li className="list-inline-item" title="Voir">
                <Link to="#" className="text-success d-inline-block"
                  onClick={() => { const entrepriseData = cellProps.row.original; setInfo(entrepriseData); }}>
                  <i className="ri-eye-fill align-bottom"></i>
                </Link>
              </li>     */}
              <li className="list-inline-item" title={props.t("Voir")}>
                <Link to={`/apps-crm-entreprises/${entrepriseData.id}`} className="text-success d-inline-block"
                  onClick={() => { const entrepriseData = cellProps.row.original; setInfo(entrepriseData) }}>
                  <i className="ri-eye-fill align-bottom"></i>
                </Link>
              </li>    
              <li className="list-inline-item" title={props.t("Modifier")}>
                <Link to="#" className="text-warning d-inline-block"
                  onClick={() => { const entrepriseData = cellProps.row.original; handleEntrepriseClick(entrepriseData); }}>
                  <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>    
              <li className="list-inline-item" title="Modifier">
              {/* <Link to="#" className="btn btn-secondary" onClick={toggleDefultCanvas}>
                Link with href
              </Link> */}
              </li>    
              
              
            </ul>
          );
        },
      },
    ],
    [handleEntrepriseClick, props]
  );


  // const [category, setCategory] = useState("");
  // const [type_contact, setTypeEntreprise] = useState("");
  // const [form_juridique, setFormJuridique] = useState("");

  // const [assignFormJuri, setAssignFormJuri] = useState('');
  // const [assignCat, setAssignCat] = useState('');
  // const [assignTypeCont, setAssignTypeCont] = useState('');
  // // const [assignTag, setAssignTag] = useState([]);
  
  // const handlesformjuridique = (optformjuris) => {
  //   setFormJuridique(optformjuris.label);
  //   const assignedformjur = optformjuris.value;
  //   setAssignFormJuri(assignedformjur);
  //   // console.log(optformjuris.value);
  // };

  // const optformjuris = [
  //   { label: "EI", value: "PA" },
  //   { label: "EURL", value: "EURL" },
  //   { label: "SA", value: "SA" },
  //   { label: "SARL", value: "SARL" },
  //   { label: "SNC", value: "SNC" },
  //   { label: "SCS", value: "SCS" },
  //   { label: "SP", value: "SP" },
  //   { label: "SF", value: "SF" },
  //   { label: "GIE", value: "GIE" }, 
  // ];

  // const handlescategory = (optcats) => {
  //   setCategory(optcats.label);
  //   const assignedcat = optcats.value;
  //   setAssignCat(assignedcat);
  // };

  // const optcats = [
  //   { label: "Entreprise", value: "Entreprise" },
  //   { label: "Institution", value: "Institution" },
  //   { label: "Association", value: "Association" },
  //   { label: "Administration", value: "Administration" },
  //   { label: "ONG", value: "ONG" }
  // ];

  // const handlestypeentreprise = (opttypeentreprises) => {
  //   setTypeEntreprise(opttypeentreprises.label);
  //   const assignedtypecont = opttypeentreprises.value;
  //   setAssignTypeCont(assignedtypecont);
  // };

  // const opttypeentreprises = [
  //   { label: "Client", value: "Client" },
  //   { label: "Prospect", value: "Prospect" },
  //   { label: "Fournisseur", value: "Fournisseur" },
  //   { label: "Partenaire", value: "Partenaire" }
  // ];

  // SideBar Entreprise Deatail
  const [info, setInfo] = useState([]);
  

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Entreprises | Le Conseiller du BTP";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={crmentreprises}
        />
        
        <Container fluid>
          <BreadCrumb title={props.t("Entreprises")} pageTitle="Le Conseiller du BTP" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                    { (typeAbonnementCourant === 1 || typeAbonnementCourant === 4) && contact === 250 ?
                      (<button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          toastMe();
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter Entreprise")}
                      </button>)
                      : 
                      (typeAbonnementCourant === 7 && contact === 350) ?
                      (<button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          toastMe2();
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter Entreprise")}
                      </button>)
                      : 
                      (<button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter Entreprise")}
                      </button>)
                    }
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        {/* <button className="btn btn-soft-danger"
                        onClick={() => setDeleteModalMulti(true)}
                        ><i className="ri-delete-bin-2-line"></i></button> */}
                        {/* <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                          Filters
                        </button> */}
                        <button className="btn btn-soft-success" onClick={() => setIsExportCSV(true)}>{props.t("Export")}</button>

                        {/* <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            className="btn btn-soft-info"
                            tag="button"
                          >
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">All</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Last Week</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Last Month</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Last Year</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}

                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={12}>
              <Card id="entrepriseList">
                <CardBody className="pt-0">
                  <div>
                    { isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
                    (entreprise && entreprise.length>0) ? (
                      <TableContainer
                        columns={columns}
                        data={(entreprise || [])}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={8}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleEntrepriseClick={handleEntrepriseClicks}
                        isEntreprisesFilter={true}
                      />
                    ) : (<TableContainer
                      columns={columns}
                      data={([])}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={8}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                      handleEntrepriseClick={handleEntrepriseClicks}
                      isEntreprisesFilter={true}
                    />)
                    }
                  </div>

                  <Modal id="showModal" isOpen={modal} centered>
                    <ModalHeader className="bg-soft-info p-3" >
                      {!!isEdit ? props.t("Modifier Entreprise") : props.t("Ajouter Entreprise")}
                    </ModalHeader>

                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3"> 
                        <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="nom-entreprise"
                                className="form-label"
                              >
                                {props.t("Entreprise")} <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                name="nomEntreprise"
                                id="entreprise-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: Le Conseiller du BTP")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nomEntreprise || ""}
                                invalid={
                                  validation.touched.nomEntreprise && validation.errors.nomEntreprise ? true : false
                                }
                              />
                              {validation.touched.nomEntreprise && validation.errors.nomEntreprise ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.nomEntreprise)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col> 
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="formjuriduqueinput-choices"
                                className="form-label font-size-13 text-muted"
                              >
                                {props.t("Forme Juridique")} <span style={{ color: "red" }}>*</span>
                              </Label>

                              <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="formeJuridique" value={validation.values.formeJuridique} onChange={validation.handleChange}
                                validate={{
                                  required: { value: true },
                                }}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.formeJuridique && validation.errors.formeJuridique ? true : false
                                }
                              >
                                <option value="">{props.t("Sélectionner")}</option>
                                <option value="Entreprise individuelle">{props.t("Entreprise individuelle")}</option>
                                <option value="Entreprise publique">{props.t("Entreprise publique")}</option>
                                <option value="Société anonyme">{props.t("Société anonyme")}</option>
                                <option value="Société à responsabilité limitée">{props.t("Société à responsabilité limitée")}</option>
                                <option value="Société privée à responsabilité limitée">{props.t("Société privée à responsabilité limitée")}</option>
                                <option value="Société à responsabilité limitée par garantie">{props.t("Société à responsabilité limitée par garantie")}</option>
                                <option value="Entreprise unipersonnelle à responsabilité limitée">{props.t("Entreprise unipersonnelle à responsabilité limitée")}</option>
                                <option value="Société en nom collectif">{props.t("Société en nom collectif")}</option>
                                <option value="Société en commandite simple">{props.t("Société en commandite simple")}</option>
                                <option value="Société en participation">{props.t("Société en participation")}</option>
                                <option value="Société en formation">{props.t("Société en formation")}</option>
                                <option value="Partenariat">{props.t("Partenariat")}</option>
                                <option value="Groupement d'intérêt économique ">{props.t("Groupement d'intérêt économique")}</option>
                              </Input>
                              {validation.touched.formeJuridique && validation.errors.formeJuridique ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.formeJuridique)}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="categoryinput-choices"
                                className="form-label font-size-13 text-muted"
                              >
                                {props.t("Catégorie")} <span style={{ color: "red" }}>*</span>
                              </Label>

                              <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="category" value={validation.values.category} onChange={validation.handleChange}
                                validate={{
                                  required: { value: true },
                                }}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.category && validation.errors.category ? true : false
                                }
                              >
                                <option value="">{props.t("Sélectionner")}</option>
                                <option value="Société">{props.t("Société")}</option>
                                <option value="Entreprise">{props.t("Entreprise")}</option>
                                <option value="Institution">{props.t("Institution")}</option>
                                <option value="Association">{props.t("Association")}</option>
                                <option value="Administration">{props.t("Administration")}</option>
                                <option value="ONG">{props.t("ONG")}</option>
                              </Input>
                              {validation.touched.category && validation.errors.category ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.category)}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="typeentrepriseinput-choices"
                                className="form-label font-size-13 text-muted"
                              >
                                {props.t("Type contact")} <span style={{ color: "red" }}>*</span>
                              </Label>

                              <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="type_contact" value={validation.values.type_contact} onChange={validation.handleChange}
                                validate={{
                                  required: { value: true },
                                }}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.type_contact && validation.errors.type_contact ? true : false
                                }
                              >
                                <option value="">{props.t("Sélectionner")}</option>
                                <option value="Client">{props.t("Client")}</option>
                                <option value="Prospect">{props.t("Prospect")}</option>
                                <option value="Fournisseur">{props.t("Fournisseur")}</option>
                                <option value="Partenaire">{props.t("Partenaire")}</option>
                              </Input>
                              {validation.touched.type_contact && validation.errors.type_contact ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.type_contact)}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="telephone-entreprise"
                                className="form-label"
                              >
                                {props.t("Téléphone")} <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                name="telephone"
                                id="telephone-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: 22966998204")}
                                type="number"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.telephone || ""}
                                invalid={
                                  validation.touched.telephone && validation.errors.telephone ? true : false
                                }
                              />
                              {validation.touched.telephone && validation.errors.telephone ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.telephone)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="responsable-entreprise"
                                className="form-label"
                              >
                                {props.t("Responsable")}
                              </Label>
                              <Input
                                name="responsable"
                                id="responsable-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: John")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.responsable || ""}

                              />

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor=""
                                className="form-label"
                              >
                                {props.t("Revenu de l'entreprise")}
                              </Label>
                              <Input
                                name="revenu_entreprise"
                                id="revenu_entreprise"
                                className="form-control"
                                type="select"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.revenu_entreprise|| ""}
                              >
                                <option value=" ">{props.t("Sélectionner")}</option>
                                <option value='0-5000000'>{props.t('0-5000000')}</option>
                                <option value='5000000-25000000'>{props.t('5000000-25000000')}</option>
                                <option value='25000000-50000000'>{props.t('25000000-50000000')}</option>
                                <option value='50000000-250000000'>{props.t('50000000-250000000')}</option>
                                <option value='25000000-50000000'>{props.t('25000000-50000000')}</option>
                                <option value='Superieur a 250000000'>{props.t('Supérieur à 250000000')}</option>
                              </Input>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor=""
                                className="form-label"
                              >
                                {props.t("Date de création")} {props.t("")}
                              </Label>
                              <Input
                                name="date_creation"
                                id="date_creation"
                                className="form-control"
                                type="date"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.date_creation || ""}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="adresse-entreprise"
                                className="form-label"
                              >
                                {props.t("Adresse")} {props.t("(Ville/Pays)")}
                              </Label>
                              <Input
                                name="adresse"
                                id="adresse-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: 39, rue Croix-des-Petits-Champs 75001 Paris")}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.adresse || ""}
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="siteweb-entreprise"
                                className="form-label"
                              >
                                {props.t("Site web")}
                              </Label>

                              <Input
                                name="siteweb"
                                id="siteweb-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: https://site.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.siteweb || ""}
                                invalid={
                                  validation.touched.siteweb && validation.errors.siteweb ? true : false
                                }
                              />
                              {validation.touched.siteweb && validation.errors.siteweb ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.siteweb)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>
                          
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="email-entreprise"
                                className="form-label"
                              >
                                {props.t("Email")}
                              </Label>
                              <Input
                                name="email"
                                id="email-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: inawo@gmail.com")}
                                type="email"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                              />
                              
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="codePostal-entreprise"
                                className="form-label"
                              >
                                {props.t("Code postal")}
                              </Label>

                              <Input
                                name="codePostal"
                                id="codePostal-entreprise"
                                className="form-control"
                                placeholder={props.t("Entrez le code postal")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.codePostal || ""}
                                invalid={
                                  validation.touched.codePostal && validation.errors.codePostal ? true : false
                                }
                              />
                              {validation.touched.codePostal && validation.errors.codePostal ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.codePostal)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="capitalSocial-entreprise"
                                className="form-label"
                              >
                                {props.t("Capital Social")}
                              </Label>

                              <Input
                                name="capitalSocial"
                                id="capitalSocial-entreprise"
                                className="form-control"
                                placeholder={props.t("Entrez le capital social")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.capitalSocial || ""}
                                invalid={
                                  validation.touched.capitalSocial && validation.errors.capitalSocial ? true : false
                                }
                              />
                              {validation.touched.capitalSocial && validation.errors.capitalSocial ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.capitalSocial)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="faxe-entreprise"
                                className="form-label"
                              >
                                {props.t("Faxe")}
                              </Label>

                              <Input
                                name="faxe"
                                id="faxe-entreprise"
                                className="form-control"
                                placeholder={props.t("Entrez le numéro du faxe")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.faxe || ""}
                                invalid={
                                  validation.touched.faxe && validation.errors.faxe ? true : false
                                }
                              />
                              {validation.touched.faxe && validation.errors.faxe ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.faxe)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>
                            
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="num-legal1-entreprise"
                                className="form-label"
                              >
                                {props.t("N° légal 1")}
                              </Label>
                              <Input
                                name="num_enreg_legal1"
                                id="num-legal1-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: ifu:214589673")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.num_enreg_legal1 || ""}
                                // invalid={
                                //   validation.touched.num_enreg_legal1 && validation.errors.num_enreg_legal1 ? true : false
                                // }
                              />
                              {/* {validation.touched.num_enreg_legal1 && validation.errors.num_enreg_legal1 ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.num_enreg_legal1)}</FormFeedback>
                              ) : null} */}

                            </div>
                          </Col>
                            
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="num-legal2-entreprise"
                                className="form-label"
                              >
                                {props.t("N° légal 2")}
                              </Label>
                              <Input
                                name="num_enreg_legal2"
                                id="num-legal2-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: rccm:214589673")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.num_enreg_legal2 || ""}
                                // invalid={
                                //   validation.touched.num_enreg_legal2 && validation.errors.num_enreg_legal2 ? true : false
                                // }
                              />
                              {/* {validation.touched.num_enreg_legal2 && validation.errors.num_enreg_legal2 ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.num_enreg_legal2)}</FormFeedback>
                              ) : null} */}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="facebook-entreprise"
                                className="form-label"
                              >
                                Facebook
                              </Label>

                              <Input
                                name="facebook"
                                id="facebook-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: https://facebook.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.facebook || ""}
                                invalid={
                                  validation.touched.facebook && validation.errors.facebook ? true : false
                                }
                              />
                              {validation.touched.facebook && validation.errors.facebook ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.facebook)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="twitter-entreprise"
                                className="form-label"
                              >
                                Twitter
                              </Label>

                              <Input
                                name="twitter"
                                id="twitter-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: https://twitter.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.twitter || ""}
                                invalid={
                                  validation.touched.twitter && validation.errors.twitter ? true : false
                                }
                              />
                              {validation.touched.twitter && validation.errors.twitter ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.twitter)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="instagram-entreprise"
                                className="form-label"
                              >
                                Instagram
                              </Label>

                              <Input
                                name="instagram"
                                id="instagram-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: https://instagram.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.instagram || ""}
                                invalid={
                                  validation.touched.instagram && validation.errors.instagram ? true : false
                                }
                              />
                              {validation.touched.instagram && validation.errors.instagram ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.instagram)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="linkedin-entreprise"
                                className="form-label"
                              >
                                Linkedin
                              </Label>

                              <Input
                                name="linkedin"
                                id="linkedin-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: https://linkedin.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.linkedin || ""}
                                invalid={
                                  validation.touched.linkedin && validation.errors.linkedin ? true : false
                                }
                              />
                              {validation.touched.linkedin && validation.errors.linkedin ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.linkedin)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="snapchat-entreprise"
                                className="form-label"
                              >
                                snapchat
                              </Label>

                              <Input
                                name="snapchat"
                                id="snapchat-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: https://snapchat.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.snapchat || ""}
                                invalid={
                                  validation.touched.snapchat && validation.errors.snapchat ? true : false
                                }
                              />
                              {validation.touched.snapchat && validation.errors.snapchat ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.snapchat)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="tiktok-entreprise"
                                className="form-label"
                              >
                                Tiktok
                              </Label>

                              <Input
                                name="tiktok"
                                id="tiktok-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: https://tiktok.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tiktok || ""}
                                invalid={
                                  validation.touched.tiktok && validation.errors.tiktok ? true : false
                                }
                              />
                              {validation.touched.tiktok && validation.errors.tiktok ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.tiktok)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="youtube-entreprise"
                                className="form-label"
                              >
                                Youtube
                              </Label>

                              <Input
                                name="youtube"
                                id="youtube-entreprise"
                                className="form-control"
                                placeholder={props.t("Ex: https://youtube.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.youtube || ""}
                                invalid={
                                  validation.touched.youtube && validation.errors.youtube ? true : false
                                }
                              />
                              {validation.touched.youtube && validation.errors.youtube ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.youtube)}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="youtube-entreprise"
                                className="form-label"
                              >
                                {props.t("Commentaire")}
                              </Label>
                              <textarea
                                name="commentaire"
                                id="commentaire"
                                className="form-control"
                                placeholder={props.t("Entrez votre commentaire")}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.commentaire|| ""}
                              />
                              {validation.touched.commentaire && validation.errors.commentaire ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.commentaire)}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button type="button" className="btn btn-light" onClick={() => { setModal(false) }} > {props.t("Fermer")} </button>
                          <button type="submit" className="btn btn-success" id="add-btn"> {!!isEdit ? props.t("Modifier") : props.t("Ajouter Entreprise")} </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CrmEntreprises.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(CrmEntreprises));
