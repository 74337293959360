import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import {
    CardBody,
    Row,
    Col,
    Card,
    Container,
    Form,
    Input,
    Label,
    Table,
    FormFeedback,
    Button,
} from "reactstrap"

import { isEmpty } from "lodash";

import { ToastContainer, toast } from 'react-toastify';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
 
import { Link, useHistory, useParams } from "react-router-dom"
import Flatpickr from "react-flatpickr"

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";

import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"

import newId from '../../../src/newid'
import { axiosIns } from '../../utils'

import { useFormik } from "formik";
import * as Yup from "yup";

import {getProducts as onGetProducts} from "../../store/ecommerce/action"
import { getContacts as onGetContacts } from "../../store/crm/action"; 
import { updateVente as onUpdateVente } from "../../store/ventes/action";
import { isDateSpansEqual } from "@fullcalendar/react";

const AchatsUpdate = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

  const [productslist, setProductsList] = useState([{
      id: null,
      prixProd: null,
      qteStock: null,
  }]);
      useEffect(() => {
        const fetchProducts = async () => {
          try {
            const { data } = await axiosIns.get(`/products/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            console.log("ali",data);
            setProductsList(data);
          } catch (err) {
            console.log(err);
            //toast.error(t('make_sure_internet'));
          }
        };
        fetchProducts();
      }, [token]);

  const PrixProd =(j)=>{
    for (let i = 0; i < productslist.length; i++) {
      if(productslist[i]?.id === j){
        return productslist[i]
      }
      return productslist[i]
    }
  }

  console.log("code" ,PrixProd(1))

  document.title = props.t("Modifier Vente | Le Conseiller du BTP");

  const { products, crmcontacts } = useSelector((state) => ({
    products :state.Ecommerce.products,
    crmcontacts: state.Crm.crmcontacts,
  
  }));

  const history = useHistory();
  const dispatch = useDispatch();
  const [ispayementDetails, setIspayementDetails] = useState(null);
  const [isCurrency, setIsCurrency] = useState("$")

    function handleisCurrency(isCurrency){
        setIsCurrency(isCurrency);
    }

  function handleispaymentDetails(ispayementDetails) {
    setIspayementDetails(ispayementDetails);
  }

  const [productList, setProductList] = useState([]);
  const [contactList, setContactList] = useState([]);
  
  useEffect(() => {
    if (products && !products.length) {
      dispatch(onGetProducts());
    }
  }, [dispatch, products]);
  
  //  remplacer products par son existant dans l'etat
  useEffect(() => {
    setProductList(products);
  }, [products]);
  console.log("products", products);


  useEffect(() => {
    if (!isEmpty(products)) setProductList(products);
  }, [products]);


  useEffect(() => {
    if (crmcontacts && !crmcontacts.length) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContactList(crmcontacts);
  }, [crmcontacts]);
  console.log("contacts", crmcontacts);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) setContactList(crmcontacts);
  }, [crmcontacts]);


  const venteId = useParams('id');
  console.log("venteId", venteId);
  const url = "https://back.crm.inawo.pro/api/v1"

 
    
  const dateFormat = () => {
    let d = new Date(),
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
  };
    
  const [date, setDate] = useState(dateFormat());
    
  const dateformate = (e) => {
  const date = e.toString().split(" ");
  const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
        setDate(joinDate);
  };


  // const validation = useFormik ({
  //       enableReinitialize: true,

  //       initialValues: {
  //         statusVente: "",
  //         statusLivraison: "",
  //         statusPaiement: "",
  //         typePaiement: "",
  //         encaissementRecu: "",
  //         contact: "",
  //         commentaire: "",
  //         detailsVente: [
  //           {
  //             produit: null,
  //             qteVendu: null,
  //         }
  //         ]
  //       },
        
  //       validationSchema: Yup.object().shape({
  //         statusVente: Yup.string().required("Ce champ est obligatoire"),
  //         statusLivraison: Yup.string().required("Ce champ est obligatoire"),
  //         statusPaiement: Yup.string().required("Ce champ est obligatoire"),
  //         typePaiement: Yup.string().required("Ce champ est obligatoire"),
  //         encaissementRecu: Yup.number().required("Ce champ est obligatoire"),
  //         contact: Yup.number().required("Ce champ est obligatoire"),
  //         commentaire: Yup.string().required("Ce champ est obligatoire"),
  //         detailsVente: Yup.array().of(
  //           Yup.object().shape({
  //             produit: Yup.number().required("Ce champ est obligatoire"),
  //             qteVendu: Yup.number().required("Ce champ est obligatoire"),
  //           }
  //         )
  //         )
  //       }),

  //     })

      const achatId = useParams('id');
      console.log("code1" ,achatId );
      const [FormData, setFormData] = useState({
        "detailsEntre": [],
        "libelle_entre": "",
        "montant": null,
        "date_entre": null,

      });
      const [details, setDetails] = useState([]);
        useEffect(() => {
          if (!isEmpty(FormData))
          setDetails(FormData.detailsEntre);
      }, [FormData]);
      console.log("details", details);

      const handleAddItem = () => {
        setDetails([...details, { produit: null, qte: null, prix_achat:null }]);
      };

      
        useEffect(() => {
            const fetchData = async ()=> {
                try {
                    const data = await axios.get(`${url}/entries/${achatId.id}`);
                    setFormData(data);                  
                    console.log("resultat", data)
                } catch (err) {
                    console.log(err)
                }
            };
            fetchData(); 
            console.log("data", fetchData())       
        }, [achatId, url])
      console.log("all",FormData)



  const handleUpdateQteAcheté = (index, value) => {
    const updatedDetails = [...details];
    updatedDetails[index].qte = value;
    setDetails(updatedDetails);
  };

  const handleUpdatePrixAcheté = (index, value) => {
    const updatedDetails = [...details];
    updatedDetails[index].prix_achat = value;
    setDetails(updatedDetails);
  };

  const handleUpdateProduit = (index, value) => {
    const updatedDetails = [...details];
    updatedDetails[index].produit = value;
    setDetails(updatedDetails);
  };

  const removeItem = (index) => {
        let updatedDetails = [...details];
        updatedDetails.pop(index, 1);
        setDetails(updatedDetails);
      };

      const [selectedProductPrice, setSelectedProductPrice] = useState({ tbodyIndex: null, price: null });
      const [productPrices, setProductPrices] = useState({});
      
      const handleSelectedProductPrice = (tbodyIndex, price) => {
        setSelectedProductPrice({ tbodyIndex, price });
        setProductPrices({ ...productPrices, [tbodyIndex]: price });
      };

  const productPrice = (j)=> {
    for(let i=0;i<=productslist.length; i++){
      if( j===productslist[i]?.id){
       return (productslist[i]?.prixProd) ;
      }
    }
  }


      const handleInputForm = (e) => {
        setFormData({ ...FormData, [e.target.name]: e.target.value });
      };

      const [errors, setErrors] = useState({});

const validate = () => {
  const newErrors = {};
  
  if(!( details.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0))){
    newErrors.montant = "Veuillez renseigner les produits ou services !!!"
  }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

      const submitHandler = async (e) => {
        e.preventDefault();
        if (validate()) {

        const newAchat = {
          libelle_entre:FormData.libelle_entre,
          // date_entre: new Date().toISOString().slice(0, 10),                                             
          date_reception: FormData.date_reception,
          montant_paye: FormData.montant_paye,
          status: parseInt(FormData.montant_paye) === 0 ? "Non payé" : parseInt(FormData.montant_paye) === details.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0) ? "Payé" : parseInt(FormData.montant_paye) < details.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0) ? "En Cours" : "Non Payé",                                              
          montant: details.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0),
          detailsEntre:  details,
        } 
        console.log("newVente", newAchat);
    
        try {
          const { data } = await axiosIns.patch(`/entries/${achatId.id}/`, newAchat, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          history.push("/apps-achats");
          setTimeout(() =>{
            window.location.reload(true);
          }, 2000);
          toast.success(props.t("Achat modifié avec succès"));
        } catch (err) {
          console.log(err);
          //toast.error(t('make_sure_internet'));
        }
      }
    }
  
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={props.t("Modifier un achat")} pageTitle={props.t("Achats")} />
                <Row className="justify-content-center">
                    <Col xxl={9}>
                        <Card>
                            <Form
                            onSubmit={submitHandler}
                            className="needs-validation"
                            id="invoice_form"
                            >
                                <CardBody className="p-4">
                  <Row>

                    <Col lg={6} sm={6}>
                      <div className='mb-2'>
                        <Label for='libelle_entre'>
                          {props.t("Libellé de l'achat")} <span className='text-danger'>*</span>
                        </Label>
                        <Input 
                          type="text"
                          className="form-control bg-light border-0"
                          id="libelle_entre"
                          name="libelle_entre"
                          onChange={handleInputForm}
                          value={FormData.libelle_entre}
                          invalid={errors.libelle_entre ? true : false}
                          />
                             
                          
                      </div>
                    </Col>
                   
                    <Col lg={6} sm={6}>
                      <div className='mb-2'>
                        <Label for='date_reception'>
                          {props.t("Date réception")} <span className='text-danger'>*</span>
                        </Label>
                        <Input 
                          type="date"
                          className="form-control bg-light border-0"
                          id="date_reception"
                          name="date_reception"
                          onChange={handleInputForm}
                          value={FormData.date_reception }
                        >
                        </Input> 
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <div className="table-responsive">
                  <div className='container-fluid d-flex justify-content-center fw-semibold border-bottom border-bottom-dashed p-2' style={{ backgroundColor: 'whitesmoke' }}>
                    <h4 className='fw-semibold'>{props.t("Achat")}</h4>
                  </div>
                </div>
                                
                                <CardBody className="p-4">
                                  <div className="table-responsive">
                                    <Table className="invoice-table table table-borderless mb-0">
                                        <thead className="align-middle">
                                              <tr className="table-active">
                                                  <th scope="col" style={{ width: "50px" }}>
                                                    
                                                  </th>
                                                  <th scope="col">{props.t("Désignation")} <span className='text-danger'>*</span></th>
                                                  <th scope="col" style={{ width: "250px" }}>
                                                    {props.t("Prix")} <span className='text-danger'>*</span>
                                                  </th>
                                                  <th scope="col" style={{ width: "120px" }}>
                                                    {props.t("Quantité")} <span className='text-danger'>*</span>
                                                  </th>
                                                  <th scope="col" style={{ width: "150px" }}>
                                                    {props.t("Montant")} <span className='text-danger'>*</span>
                                                  </th>
                                                  <th scope="col" style={{ width: "20px" }}>
                                                  </th>
                                              </tr>
                                        </thead>

                                      {details.map((item, index) => (
                                        <React.Fragment key={index}>
                                          <tbody name={`productInfo-${index}`} key={index}>
                                                <tr id={index} className="product">
                                                  <th scope="row" className="product-id">
                                                    <Label>{index + 1}</Label>
                                                  </th>
                                                  <td className="text-start">

                                                    <div className="mb-2">

                                                    <Input 
                                                        type="select"
                                                        className="form-control bg-light border-0"
                                                        id={`productName-${index}`}
                                                        name={`product_name-${index}`}
                                                        onChange={(e) => {
                                                          handleUpdateProduit(index, e.target.value);
                                                        }}
                                                      >
                                                        <option value="">{props.t("Sélectionner")}</option>
                                                        {productslist.map((product) => (
                                                          <option
                                                            value={product.id}
                                                            key={product.id}
                                                            selected={details[index].produit === product.id}
                                                          >
                                                            {product.designationProd + ' ' + product.marqueProd}
                                                          </option>
                                                        ))} 
                                                      </Input>
                                                     
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <Input
                                                      type="number"
                                                      className="form-control product-price bg-light border-0"
                                                      id={`productRate-${index}`}
                                                      value={item.prix_achat}
                                                      step="0.01" 
                                                      onChange={(e) => {
                                                        handleUpdatePrixAcheté(index, e.target.value);
                                                      }}  

                                                    />
                                                      <div className="invalid-feedback">
                                                        {props.t("Veuillez entrer un prix")}
                                                      </div>
                                                  </td>
                                                  <td>
                                                    <div className="input-step">
                                                    <Input
                                                      type="number"
                                                      className="product-quantity"
                                                      id={`product-qty-${index}`}
                                                      value={item.qte}
                                                      onChange={(e) => handleUpdateQteAcheté(index, e.target.value)}
                                                    />
                                                    </div>
                                                  </td>
                                                  <td className="text-end">
                                                    <div>
                                                    <Input
                                                      type="text"
                                                      className="form-control bg-light border-0 product-line-price"
                                                      id={`productPrice-${index}`}
                                                      placeholder="0.00"
                                                      value={item.prix_achat*item.qte}
                                                      readOnly
                                                    />
                                                    </div>
                                                  </td>
                                                  <td id={`product-removal-${index}`} className="product-removal">
                                                      <button
                                                        id={`del-${index}`}
                                                        className="btn btn-success"
                                                        onClick={() => {
                                                          removeItem(index);
                                                        }}
                                                      >
                                                        {props.t("Supprimer")}
                                                      </button>
                                                  </td>
                                                </tr>
                                            </tbody>

                                        </React.Fragment>
                                      ))}
                                         <tfoot>
                                        <tr>
                                           <td colSpan="5">
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-secondary fw-medium"
                                                  id="add-item"
                                                  onClick={handleAddItem}
                                                >
                                                  <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                                                  {props.t("Ajouter un achat")}
                                                </button>
                                            </td>
                                          </tr>
                                        <tr className="border-top border-top-dashed mt-2">
                                          <td colSpan="3"></td>
                                          <td colSpan="2" className="p-0">
                                          <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                                          <tbody>
                                           <tr>
                                              <th scope="row">{props.t("Montant Payé")} <span className='text-danger'>*</span></th>
                                              <td style={{ width: '150px' }}>
                                                <Input
                                                  type="number"
                                                  name="montant_paye"
                                                  className="form-control bg-light border-0"
                                                  id="cart-subtotal"
                                                  onChange={handleInputForm}
                                                  value={FormData.montant_paye}
                                                />
                                              </td>
                                            </tr>
                                            <tr className="border-top border-top-dashed">
                                              <th scope="row">{props.t("Montant Total")} <span className='text-danger'>*</span></th>
                                              <td>
                                                <Input
                                                  type="number"
                                                  className="form-control bg-light border-0"
                                                  name="montant"
                                                  id="cart-total"
                                                  onChange={handleInputForm}
                                                  value={details.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0)}
                                                />
                                                {errors.montant && <p className="text-danger">{errors.montant}</p>}
                                              </td>
                                           </tr>
                                          </tbody>
                                          </Table>
                                          </td> 
                                        </tr>  
                                        </tfoot>
                                                                              
                                    </Table>
                                  </div>
                                  
                                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    <button type="submit" className="btn btn-success">
                                      <i className="ri-printer-line align-bottom me-1"></i> {props.t("Valider")}
                                    </button>
                                  </div>
                                </CardBody>

                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )


}

AchatsUpdate.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AchatsUpdate));
