export const LOGIN_ADMIN = "LOGIN_ADMIN"
export const LOGIN_SUCCESS_ADMIN = "LOGIN_SUCCESS_ADMIN"

export const LOGOUT_ADMIN = "LOGOUT_ADMIN"
export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_ADMIN_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const RESET_LOGIN_FLAG = "RESET_LOGIN_FLAG"
