import React from "react";
import { Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

// Import Images
// import logoLight from "../../assets/images/logo-light.png";
import logoInawoCRMBlanc1 from "../../assets/images/logo01.png";
// import logoInawoCRMBlanc2 from "../../assets/images/logo-InawoCRM-Blanc-2.png";
import inscriptionlaptop from "../../assets/images/inscription-laptop.png";


const AuthSlider = () => {
    return (
        <React.Fragment>
 {/* auth-one-bg */}
            <Col lg={6}>
                <div className="p-lg-5 p-4 h-100">
                    {/* <div className="bg-overlay"></div> */}
                    <div style={{ backgroundColor: "#ffff", opacity: 0.4, width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}>
                        
                    </div>
                    <div className="position-relative h-100 d-flex flex-column">
                        <div className="mb-2">
                            <a href="https://inawo.pro/" target="_blank" rel="noreferrer" className="d-block">
                                {/* <img src={logoLight} alt="" height="18" /> */}
                                <img src={logoInawoCRMBlanc1} width="30%" alt=""  style={{ marginLeft: "-10px" }}/>
                            </a>
                            <h4 className="mt-1 text-black">
                            
                                Try Le Conseiller du BTP free of charge and without obligation for 14 days to discover how the platform can help you grow your business.

                            </h4>
                            <p className="mt-4 text-black"  style={{  fontWeight: 1, fontSize: 15 }}>
                                14 days and counting ! Take advantage of the many benefits and facilities that regular use of Le Conseiller du BTP can bring you, in line with your vision: always moving forward in your business.
                            </p>

                        </div>
                        <div className="">
                            <div className="text-black"  style={{  fontWeight: 1, fontSize: 15 }}>Discover all you can achieve with our Le Conseiller du BTP software :</div>
                            <ul className="" style={{ listStyleType: 'none'}}>
                            <li style={{ fontSize: "15px", color: "black" }}><i className="las la-check text-success" style={{ fontSize: 23 }}></i> Manage your business contacts</li>
                            <li style={{ fontSize: "15px", color: "black" }}><i className="las la-check text-success" style={{ fontSize: 23 }}></i> Create and manage all your invoicing</li>
                            <li style={{ fontSize: "15px", color: "black" }}><i className="las la-check text-success" style={{ fontSize: 23 }}></i> Get an overview of your business activity at all times</li>
                            <li style={{ fontSize: "15px", color: "black" }}><i className="las la-check text-success" style={{ fontSize: 23 }}></i> Find out who your best customers are</li>
                            <li style={{ fontSize: "15px", color: "black" }}><i className="las la-check text-success" style={{ fontSize: 23 }}></i> Manage all your business activity</li>
                            <li style={{ fontSize: "15px", color: "black" }}><i className="las la-check text-success" style={{ fontSize: 23 }}></i> Manage your expenses</li>
                            <li style={{ fontSize: "15px", color: "black" }}><i className="las la-check text-success" style={{ fontSize: 23 }}></i> Easily manage your inventory</li>
                            <li style={{ fontSize: "15px", color: "black" }}><i className="las la-check text-success" style={{ fontSize: 23 }}></i> Manage your business opportunities</li>
                            <li style={{ fontSize: "15px", color: "black" }}><i className="las la-check text-success" style={{ fontSize: 23 }}></i> Start trading now, with no commitment and no credit card required.</li>
                            </ul>

                        </div>
                        <div style={{ marginLeft: "auto" }}>
                            <img src={inscriptionlaptop} alt="" width="80%"/>
                        </div>
                        <div className="mt-auto">
                            <p className="mt-3 text-black" >Do you have any questions ? Write to us on <a href="mailto: hello@inawo.pro" style={{ color: "#62B6E5" }}>hello@inawo.pro</a></p>
                        </div>
                    </div>
                </div>
            </Col>
        </React.Fragment >
    );
};

export default AuthSlider;