import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";
import axios from "axios";
import { axiosIns } from '../../utils'
// Import Images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
  Button,
  
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

//Import actions
import {
  getParticuliers as onGetParticuliers,
  addNewParticulier as onAddNewParticulier,
  updateParticulier as onUpdateParticulier,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Common/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from "react";
import { withTranslation } from "react-i18next";

const CrmParticuliers = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const { crmparticuliers, isParticulierCreated, isParticulierSuccess, error } = useSelector((state) => ({
    crmparticuliers: state.Particulier.crmparticuliers,
    isParticulierCreated: state.Particulier.isParticulierCreated,
    isParticulierSuccess: state.Particulier.isParticulierSuccess,
    error: state.Particulier.error,
  }));

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }

  // Récuperer le type d'abonnement
  const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        const getLastAbonnement = response.length - 1
        const currentTypeAbonnement = response[getLastAbonnement].pricing
        setTypeAbonnementCourant(currentTypeAbonnement)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  // Récupérer la liste des contacts
  const [contact, setContact] = useState(null)
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://back.crm.inawo.pro/api/v1/contacts/");
        const getLastAbonnement = response.length
        console.log(getLastAbonnement)
        setContact(getLastAbonnement)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  const toastMe = () => {
    toast.error(props.t("Votre formule ne vous permet d'ajouter que 250 contacts."))
  };
  const toastMe2 = () => {
    toast.error(props.t("Votre formule ne vous permet d'ajouter que 350 contacts."))
  };

  useEffect(() => {
    console.log(crmparticuliers);
    if (crmparticuliers && !isParticulierSuccess) {
      dispatch(onGetParticuliers());
    }
  }, [dispatch, crmparticuliers,isParticulierSuccess, history, reducerValue]);

  useEffect(() => {
    setParticulier(crmparticuliers);
  }, [crmparticuliers]);

  useEffect(() => {
    if (!isEmpty(crmparticuliers)) {
      setParticulier(crmparticuliers);
      setIsEdit(false);
    }
  }, [crmparticuliers]);


  const [isEdit, setIsEdit] = useState(false);
  const [particulier, setParticulier] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setParticulier(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const onClickDelete = (particulier) => {
    setParticulier(particulier);
    setDeleteModal(true);
  };

  // Add Data
  const handleParticulierClicks = () => {
    setParticulier("");
    setIsEdit(false);
    toggle();
  };


  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  const timeFormat = () => {
    let d = new Date();
    let minutes = d.getMinutes().toString().length === 1 ? '0' + d.getMinutes() : d.getMinutes();
    let hours = (d.getHours().toString() % 12) || 12;
    hours = (hours <= 9) ? hours = ("0" + hours) : hours;
    let ampm = d.getHours() >= 12 ? 'PM' : 'AM';
    return (hours + ':' + minutes + ampm);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category: (particulier && particulier.category) || 'Particulier',
      type_contact: (particulier && particulier.type_contact) || '',
      nom: (particulier && particulier.nom) || '',
      date_anniversaire:(particulier && particulier.date_anniversaire) || null,
      adresse: (particulier && particulier.adresse) || '',
      revenu_particulier:(particulier && particulier.revenu_particulier) || null,
      email: (particulier && particulier.email) || '',
      telephone: (particulier && particulier.telephone) || '',
      codePostal: (particulier && particulier.codePostal) || '',
      facebook: (particulier && particulier.facebook) || '',
      twitter: (particulier && particulier.twitter) || '',
      instagram: (particulier && particulier.instagram) || '',
      linkedin: (particulier && particulier.linkedin) || '',
      snapchat: (particulier && particulier.snapchat) || '',
      tiktok: (particulier && particulier.tiktok) || '',
      youtube: (particulier && particulier.youtube) || '',
      commentaire:(particulier && particulier.commentaire)||null
    },
    validationSchema: Yup.object({
      type_contact: Yup.string().required(props.t("Veuillez renseigner le type de contact")),
      nom: Yup.string().required(props.t("Veuillez renseigner le nom")),
      // adresse: Yup.string().required(props.t("Veuillez renseigner l'adresse")),
      telephone: Yup.string().required(props.t("Veuillez renseigner le numéro de téléphone")),
    }),
    onSubmit: (values) => {
      console.log(isEdit);
      if (isEdit) {
        const updateParticulier = {
          id: particulier ? particulier.id : 0,
          category: values.category,
          type_contact: values.type_contact,
          nom: values.nom,
          adresse: values.adresse,
          date_anniversaire:values.date_anniversaire,
          revenu_particulier: values.revenu_particulier,
          commentaire:values.commentaire,
          email: values.email,
          telephone: values.telephone,
          codePostal: values.codePostal,
          facebook: values.facebook,
          twitter: values.twitter,
          instagram: values.instagram,
          linkedin: values.linkedin,
          snapchat: values.snapchat,
          tiktok: values.tiktok,
          youtube: values.youtube,
        };
        console.log("contact",updateParticulier)
        try {
         axiosIns.patch(`/particuliers/${particulier.id}/`, updateParticulier, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }).then((response) => {
            console.log("response",response)
            validation.resetForm();
            toast.success(props.t("Particulier modifié avec succès"), { autoClose: 2000 });
            // dispatch(onGetParticuliers());
            handleClick()
          }).catch((error) => {
            console.log("error",error)
            toast.error(props.t("Echec de la modification du Particulier"), { autoClose: 3000 });
          });
          window.location.reload(true);
        } catch (err) {
          console.log(err);
          toast.error(props.t("Echec de la modification du Particulier"), { autoClose: 2000 });
        }
        dispatch(onGetParticuliers());
        
      } else {
        const newParticulier = {       
          category: values.category,
          type_contact: values.type_contact,
          nom: values.nom,
          adresse: values?.adresse,
          email: values.email,
          date_anniversaire:values.date_anniversaire,
          revenu_particulier:values.revenu_particulier,
          commentaire:values.commentaire,
          telephone: values.telephone,
          codePostal: values.codePostal,
          facebook: values.facebook,
          twitter: values.twitter,
          instagram: values.instagram,
          linkedin: values.linkedin,
          snapchat: values.snapchat,
          tiktok: values.tiktok,
          youtube: values.youtube,
        };
        // save new Particulier
        try {
          const { data } = axiosIns.post(`/particuliers/`, newParticulier, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }).then(response => {
            validation.resetForm();
            toast.success(props.t("Particulier ajouté avec succès"), { autoClose: 3000 });
            dispatch(onGetParticuliers());
            handleClick()
          }).catch(error => {
            toast.error(props.t("Echec de l'ajout du Particulier"), { autoClose: 3000 });
          });
        } catch (err) {
          console.log(err);
          toast.error(props.t("Echec de l'ajout du Particulier"), { autoClose: 3000 });
        }
        dispatch(onGetParticuliers());
      }
      toggle();
    },
  });

  // Update Data
  const handleParticulierClick = useCallback((arg) => {
    const particulier = arg;

    setParticulier({
      id: particulier.id,      
      category: particulier.category,
      type_contact: particulier.type_contact,
      nom: particulier.nom,
      adresse: particulier?.adresse,
      email: particulier.email,
      date_anniversaire:particulier.date_anniversaire,
      revenu_particulier:particulier.revenu_particulier,
      commentaire:particulier.commentaire,
      telephone: particulier.telephone,
      codePostal: particulier.codePostal,
      facebook: particulier.facebook,
      twitter: particulier.twitter,
      instagram: particulier.instagram,
      linkedin: particulier.linkedin,
      snapchat: particulier.snapchat,
      tiktok: particulier.tiktok,
      youtube: particulier.youtube,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Node API 
  useEffect(() => {
    if (isParticulierCreated) {
      setParticulier(null);
      dispatch(onGetParticuliers());
    }
  }, [
    dispatch,
    isParticulierCreated,
  ]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
    return updateTime;
  };


  // Checked All
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".particulierCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };


  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="particulierCheckBox" value={cellProps.row.original.id} />;
        },
        id: '#',
      },
      {
        Header: props.t("Image"),
        accessor: "name",
        filterable: false,
        Cell: (particulier) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {particulier.row.original.image_src ? <img
                  src={process.env.REACT_APP_API_URL + "/images/users/" + particulier.row.original.image_src}
                  alt=""
                  className="avatar-xxs rounded-circle"
                /> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {particulier.row.original.nom.charAt(0)}
                    </div>
                  </div>
                }
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("Nom"),
        accessor: "nom",
        filterable: false,
      },
      {
        Header: props.t("Email"),
        accessor: "email",
        filterable: false,
      },
      {
        Header: props.t("Type contact"),
        Cell: (particulier) => (
          <>
            {/* {particulier.row.original.tags.map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))} */}
            <span className="badge badge-soft-secondary me-1" >{props.t(particulier.row.original.type_contact)}</span>
          </>
        ),
      },
      {
        Header: props.t("Catégorie"),
        Cell: (particulier) => (
          <>
            {/* {particulier.row.original.tags.map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))} */}
            <span className="badge badge-soft-danger me-1" >{props.t(particulier.row.original.category)}</span>
          </>
        ),
      },
      {
        Header: props.t("Actions"),
        Cell: (cellProps) => {
          const particulierData = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title={props.t("Appeler")}>
                <a href={"tel:+" + cellProps.row.original.telephone} className="text-primary d-inline-block">
                  <i className="ri-phone-line fs-16"></i>
                </a>
              </li>
              <li className="list-inline-item edit" title={props.t("Mail")}>
                <a href={"mailto:" + cellProps.row.original.email} className="text-secondary d-inline-block">
                  <i className="ri-question-answer-line fs-16"></i>
                </a>
              </li>
              <li className="list-inline-item" title={props.t("Voir")}>
                <Link to={`/apps-crm-particuliers/${particulierData.id}`} className="text-success d-inline-block"
                  onClick={() => { const particulierData = cellProps.row.original; setInfo(particulierData) }}>
                  <i className="ri-eye-fill align-bottom"></i>
                </Link>
              </li>    
              <li className="list-inline-item" title={props.t("Modifier")}>
                <Link to="#" className="text-warning d-inline-block"
                  onClick={() => { const particulierData = cellProps.row.original; handleParticulierClick(particulierData); }}>
                  <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>    
              <li className="list-inline-item" title="Modifier">
              </li>    
              
              
            </ul>
          );
        },
      },
    ],
    [handleParticulierClick, props]
  );

  const [info, setInfo] = useState([]);
  

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = props.t("Particuliers | Le Conseiller du BTP");
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={crmparticuliers}
        />
        
        <Container fluid>
          <BreadCrumb title={props.t("Particuliers")} pageTitle="Le Conseiller du BTP" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      { (typeAbonnementCourant === 1 || typeAbonnementCourant === 4) && contact === 250 ?
                      (<button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          toastMe();
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter Particuliers")}
                      </button>) :
                      typeAbonnementCourant === 7 && contact === 350 ?
                      (<button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          toastMe2();
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter Particuliers")}
                      </button>) :
                      (<button
                      className="btn btn-info add-btn"
                      onClick={() => {
                        setModal(true);
                      }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter Particuliers")}
                      </button>)
                      // (toast.error("Vous avez dépassez le nombre maximum de contact à ajouter. "))
                      }
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        {/* <button className="btn btn-soft-danger"
                        onClick={() => setDeleteModalMulti(true)}
                        ><i className="ri-delete-bin-2-line"></i></button> */}
                        {/* <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                          Filters
                        </button> */}
                        <button className="btn btn-soft-success" onClick={() => setIsExportCSV(true)}>{props.t("Export")}</button>

                        {/* <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            className="btn btn-soft-info"
                            tag="button"
                          >
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">All</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Last Week</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Last Month</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Last Year</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}

                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={12}>
              <Card id="particulierList">
                <CardBody className="pt-0">
                  <div>
                    {isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
                    (particulier && particulier.length > 0) ? (
                      <TableContainer
                        columns={columns}
                        data={(particulier || [])}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={8}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleParticulierClick={handleParticulierClicks}
                      />
                    ) : (<TableContainer
                      columns={columns}
                      data={([])}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={8}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                      handleParticulierClick={handleParticulierClicks}
                      isParticuliersFilter={true}
                    />)
                    }
                  </div>

                  <Modal id="showModal" isOpen={modal}  centered>
                    <ModalHeader className="bg-soft-info p-3" >
                      {isEdit=== true ? props.t("Modifier Particulier") : props.t("Ajouter Particulier")}
                    </ModalHeader>

                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3"> 
                          <Col lg={6}>
                              <div>
                                <Label
                                  htmlFor="categoryinput-choices"
                                  className="form-label font-size-13 text-muted"
                                >
                                  {props.t("Catégorie")}
                                </Label>

                                <Input name="category" className="form-control" type='text' id="category" readOnly value={validation.values.category} onChange={validation.handleChange}
                                  validate={{
                                        required: { value: false },
                                      }}
                                  onBlur={validation.handleBlur}
                                />
                              </div>
                            </Col> 
                            <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="typeparticulierinput-choices"
                                className="form-label font-size-13 text-muted"
                              >
                                {props.t("Type contact")} <span style={{ color: "red" }}>*</span>
                              </Label>

                              <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="type_contact" value={validation.values.type_contact} onChange={validation.handleChange}
                              validate={{
                                required: { value: true },
                              }}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.type_contact && validation.errors.type_contact ? true : false
                              }
                              >
                                <option value="">{props.t("Sélectionner")}</option>
                                <option value="Client">{props.t("Client")}</option>
                                <option value="Prospect">{props.t("Prospect")}</option>
                                <option value="Fournisseur">{props.t("Fournisseur")}</option>
                                <option value="Partenaire">{props.t("Partenaire")}</option>
                                
                              </Input>
                              {validation.touched.type_contact && validation.errors.type_contact ? (
                                <FormFeedback type="invalid">{props.t(validation.errors.type_contact)}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>


                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="nom-particulier"
                                className="form-label"
                              >
                                {props.t("Nom")} <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                name="nom"
                                id="nom-particulier"
                                className="form-control"
                                placeholder={props.t("Entrez le nom")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nom || ""}
                                invalid={
                                  validation.touched.nom && validation.errors.nom ? true : false
                                }
                              />
                              {validation.touched.nom && validation.errors.nom ? (
                                <FormFeedback type="invalid">{validation.errors.nom}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>
                          <Col lg={6}>
                            <Label
                                htmlFor="date anniversaire-particulier"
                                className="form-label"
                              >
                                {props.t("Date d'anniversaire")} 
                              </Label>
                            <Input
                                  name="date_anniversaire"
                                  id="date_anniversaire"
                                  className="form-control"
                                  placeholder={props.t("Entrez votre date d'anniversaire")}
                                  type="date"
                                  value={validation.values.date_anniversaire || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                 
                                />
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="telephone-particulier"
                                className="form-label"
                              >
                                {props.t("Téléphone")} <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                name="telephone"
                                id="telephone-particulier"
                                className="form-control"
                                placeholder="Ex: 22966998204"
                                type="number"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.telephone || ""}
                                invalid={
                                  validation.touched.telephone && validation.errors.telephone ? true : false
                                }
                              />
                              {validation.touched.telephone && validation.errors.telephone ? (
                                <FormFeedback type="invalid">{validation.errors.telephone}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="email-particulier"
                                className="form-label"
                              >
                                {props.t("Email")}
                              </Label>
                              <Input
                                name="email"
                                id="email-particulier"
                                className="form-control"
                                placeholder={props.t("Entrez l'email")}
                                type="email"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <Label
                                htmlFor="adresse-particulier"
                                className="form-label"
                              >
                                {props.t("Adresse")} {props.t("(Ville/Pays)")} 
                              </Label>
                            <Input
                                  name="adresse"
                                  id="adresse-particulier"
                                  className="form-control"
                                  placeholder={props.t("Entrez votre adresse")}
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.adresse || ""}
                                />
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="typeparticulierinput-choices"
                                className="form-label font-size-13 text-muted"
                              >
                                {props.t("Revenu")}
                              </Label>

                              <Input className="form-select" 
                              type='select' data-choices data-choices-sorting="true" 
                              id="revenu_particulier" value={validation.values.revenu_particulier} 
                              onChange={validation.handleChange}
                              validate={{
                                required: { value: true },
                              }}
                              onBlur={validation.handleBlur}
                             
                              >
                                <option value=" ">{props.t("Sélectionner")}</option>
                                <option value="0-50000">{props.t("0-50000")}</option>
                                <option value="50000-150000">{props.t("50000-150000")}</option>
                                <option value="150000-350000">{props.t("150000-350000")}</option>
                                <option value="350000-500000">{props.t("350000-500000")}</option>
                                <option value="Superieur a 500000">{props.t("Supérieur à 500000")}</option>
                                
                              </Input>
                              {validation.touched.revenu_particulier && validation.errors.revenu_particulier? (
                                <FormFeedback type="invalid">{props.t(validation.errors.revenu_particulier)}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="codePostal-particulier"
                                className="form-label"
                              >
                                {props.t("Code postal")}
                              </Label>

                              <Input
                                name="codePostal"
                                id="codePostal-particulier"
                                className="form-control"
                                placeholder={props.t("Entrez le code postal")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.codePostal || ""}
                                invalid={
                                  validation.touched.codePostal && validation.errors.codePostal ? true : false
                                }
                              />
                              {validation.touched.codePostal && validation.errors.codePostal ? (
                                <FormFeedback type="invalid">{validation.errors.codePostal}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>
                         
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="facebook-particulier"
                                className="form-label"
                              >
                                Facebook
                              </Label>

                              <Input
                                name="facebook"
                                id="facebook-particulier"
                                className="form-control"
                                placeholder={props.t("Ex: https://facebook.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.facebook || ""}
                                invalid={
                                  validation.touched.facebook && validation.errors.facebook ? true : false
                                }
                              />
                              {validation.touched.facebook && validation.errors.facebook ? (
                                <FormFeedback type="invalid">{validation.errors.facebook}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="twitter-particulier"
                                className="form-label"
                              >
                                Twitter
                              </Label>

                              <Input
                                name="twitter"
                                id="twitter-particulier"
                                className="form-control"
                                placeholder={props.t("Ex: https://twitter.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.twitter || ""}
                                invalid={
                                  validation.touched.twitter && validation.errors.twitter ? true : false
                                }
                              />
                              {validation.touched.twitter && validation.errors.twitter ? (
                                <FormFeedback type="invalid">{validation.errors.twitter}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="instagram-particulier"
                                className="form-label"
                              >
                                Instagram
                              </Label>

                              <Input
                                name="instagram"
                                id="instagram-particulier"
                                className="form-control"
                                placeholder={props.t("Ex: https://instagram.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.instagram || ""}
                                invalid={
                                  validation.touched.instagram && validation.errors.instagram ? true : false
                                }
                              />
                              {validation.touched.instagram && validation.errors.instagram ? (
                                <FormFeedback type="invalid">{validation.errors.instagram}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="linkedin-particulier"
                                className="form-label"
                              >
                                Linkedin
                              </Label>

                              <Input
                                name="linkedin"
                                id="linkedin-particulier"
                                className="form-control"
                                placeholder={props.t("Ex: https://linkedin.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.linkedin || ""}
                                invalid={
                                  validation.touched.linkedin && validation.errors.linkedin ? true : false
                                }
                              />
                              {validation.touched.linkedin && validation.errors.linkedin ? (
                                <FormFeedback type="invalid">{validation.errors.linkedin}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="snapchat-particulier"
                                className="form-label"
                              >
                                snapchat
                              </Label>

                              <Input
                                name="snapchat"
                                id="snapchat-particulier"
                                className="form-control"
                                placeholder={props.t("Ex: https://snapchat.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.snapchat || ""}
                                invalid={
                                  validation.touched.snapchat && validation.errors.snapchat ? true : false
                                }
                              />
                              {validation.touched.snapchat && validation.errors.snapchat ? (
                                <FormFeedback type="invalid">{validation.errors.snapchat}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="tiktok-particulier"
                                className="form-label"
                              >
                                Tiktok
                              </Label>

                              <Input
                                name="tiktok"
                                id="tiktok-particulier"
                                className="form-control"
                                placeholder={props.t("Ex: https://tiktok.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tiktok || ""}
                                invalid={
                                  validation.touched.tiktok && validation.errors.tiktok ? true : false
                                }
                              />
                              {validation.touched.tiktok && validation.errors.tiktok ? (
                                <FormFeedback type="invalid">{validation.errors.tiktok}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="youtube-particulier"
                                className="form-label"
                              >
                                Youtube
                              </Label>

                              <Input
                                name="youtube"
                                id="youtube-particulier"
                                className="form-control"
                                placeholder={props.t("Ex: https://youtube.com")}
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.youtube || ""}
                                invalid={
                                  validation.touched.youtube && validation.errors.youtube ? true : false
                                }
                              />
                              {validation.touched.youtube && validation.errors.youtube ? (
                                <FormFeedback type="invalid">{validation.errors.youtube}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>
                          <Col lg={6}>
                            <Label
                                htmlFor="commentaire"
                                className="form-label"
                              >
                                {props.t("Commentaire")} 
                              </Label>
                              <textarea
                                name="commentaire"
                                id="commentaire"
                                className="form-control"
                                placeholder={props.t("Entrez votre commentaire")}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.commentaire|| ""}
                              />
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button type="button" className="btn btn-light" onClick={() => { setModal(false) }} > {props.t("Fermer")} </button>
                          <button type="submit" className="btn btn-success" id="add-btn"> {!!isEdit ? props.t("Modifier") : props.t("Ajouter Particulier")} </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} />
                </CardBody>
              </Card>
            </Col>

            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CrmParticuliers.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(CrmParticuliers));
