// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_SERVICES= "GET_SERVICES";
export const GET_ORDERS = "GET_ORDERS";
export const GET_SELLERS = "GET_SELLERS";
export const GET_ARTICLES = "GET_ARTICLES";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_PRODUCTS_CATEGORIES = "GET_PRODUCTS_CATEGORIES";
export const GET_TYPES = "GET_TYPES";
export const GET_METIERS = "GET_METIERS";


/**
 * Delete ORDER
 */
 export const DELETE_ORDER = "DELETE_ORDER"
 export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
 export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL"

 /**
 * Update ORDER
 */
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

/**
 * Add ORDER
 */
 export const ADD_NEW_ORDER = "ADD_NEW_ORDER"
 export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS"
 export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL"
 export const ADD_METIER= "ADD_METIERS";
 
 /**
 * Add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL"

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"

/**
 * Delete PRODUCT
 */
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"

 /**
 * Add PRODUCT
 */
  export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT"
  export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"
  export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL"
  
  /**
   * Edit PRODUCT
   */
  export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
  export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
  export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"

/**
 * Delete PRODUCTS_CATEGORIES
 */
 export const DELETE_PRODUCTS_CATEGORIES = "DELETE_PRODUCTS_CATEGORIES"
 export const DELETE_PRODUCTS_CATEGORIES_SUCCESS = "DELETE_PRODUCTS_CATEGORIES_SUCCESS"
 export const DELETE_PRODUCTS_CATEGORIES_FAIL = "DELETE_PRODUCTS_CATEGORIES_FAIL"
 
  /**
  * Add PRODUCTS_CATEGORIES
  */
   export const ADD_PRODUCTS_CATEGORIES = "ADD_PRODUCTS_CATEGORIES"
   export const ADD_PRODUCTS_CATEGORIES_SUCCESS = "ADD_PRODUCTS_CATEGORIES_SUCCESS"
   export const ADD_PRODUCTS_CATEGORIES_FAIL = "ADD_PRODUCTS_CATEGORIES_FAIL"

   /**
  * Add PRODUCTS_CATEGORIES
  */
    export const ADD_TYPES = "ADD_TYPES"
    export const ADD_TYPES_SUCCESS = "ADD_TYPES_SUCCESS"
    export const ADD_TYPES_FAIL = "ADD_TYPES_FAIL"
   
   /**
    * Edit PRODUCT
    */
   export const UPDATE_PRODUCTS_CATEGORIES = "UPDATE_PRODUCTS_CATEGORIES"
   export const UPDATE_PRODUCTS_CATEGORIES_SUCCESS = "UPDATE_PRODUCTS_CATEGORIES_SUCCESS"
   export const UPDATE_PRODUCTS_CATEGORIES_FAIL = "UPDATE_PRODUCTS_CATEGORIES_FAIL"

   export const UPDATE_TYPES = "UPDATE_TYPES"
   export const UPDATE_TYPES_SUCCESS = "UPDATE_TYPES_SUCCESS"
   export const UPDATE_TYPES_FAIL = "UPDATE_TYPES_FAIL"

 
/**
 * Delete PRODUCTS_CATEGORIES
 */
 export const DELETE_SERVICES = "DELETE_SERVICES"
 export const DELETE_SERVICES_SUCCESS = "DELETE_SERVICES_SUCCESS"
 export const DELETE_SERVICES_FAIL = "DELETE_SERVICES_FAIL"
 
  /**
  * Add PRODUCTS_CATEGORIES
  */
   export const ADD_SERVICES = "ADD_SERVICES"
   export const ADD_SERVICES_SUCCESS = "ADD_SERVICES_SUCCESS"
   export const ADD_SERVICES_FAIL = "ADD_SERVICES_FAIL"
   
   /**
    * Edit PRODUCT
    */
   export const UPDATE_SERVICES = "UPDATE_SERVICES"
   export const UPDATE_SERVICES_SUCCESS = "UPDATE_SERVICES_SUCCESS"
   export const UPDATE_SERVICES_FAIL = "UPDATE_SERVICES_FAIL "


  export const UPDATE_METIERS = "UPDATE_METIERS"

  
