import {
  ADD_CITY_FAIL,
  ADD_CITY_SUCCESS,
  ADD_NEW_CITY,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  DELETE_CITY,
  DELETE_CITY_FAIL,
  DELETE_CITY_SUCCESS,
  GET_CITY,
  UPDATE_CITY,
  UPDATE_CITY_FAIL,
  UPDATE_CITY_SUCCESS,
} from "./actionType";

// common success
export const cityApiResponseSuccess = (actionType, response) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, response },
});

//common error
export const cityApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCities = () => ({
  type: GET_CITY,
});

export const addNewCity = (city) => ({
  type: ADD_NEW_CITY,
  payload: city,
});

export const addNewCitySuccess = (city) => ({
  type: ADD_CITY_SUCCESS,
  payload: city,
});

export const addNewCityFail = (error) => ({
  type: ADD_CITY_FAIL,
  payload: error,
});

export const updateCity = (city) => ({
  type: UPDATE_CITY,
  payload: city,
});

export const updateCitySuccess = (city) => ({
  type: UPDATE_CITY_SUCCESS,
  payload: city,
});

export const updateCityFail = (error) => ({
  type: UPDATE_CITY_FAIL,
  payload: error,
});

export const deleteCity = (city) => ({
  type: DELETE_CITY,
  payload: city,
});

export const deleteCitySuccess = (city) => ({
  type: DELETE_CITY_SUCCESS,
  payload: city,
});

export const deleteCityFail = (error) => ({
  type: DELETE_CITY_FAIL,
  payload: error,
});
