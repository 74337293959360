import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import CreatePassword from "./auth/createpwd/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import LoginAdmin from "./auth/login copy/reducer";
import Login from "./auth/login/reducer";
import Profile from "./auth/profile/reducer";
import Account from "./auth/register/reducer";
import AffiliateAccount from "./auth/registerAffiliate/reducer";
import Activation from "./auth/validation/reducer";

//Calendar
import Calendar from "./calendar/reducer";
//Chat
import chat from "./chat/reducer";
//Ecommerce
import Ecommerce from "./ecommerce/reducer";

//Project
import Projects from "./projects/reducer";

// Tasks
import Tasks from "./tasks/reducer";
//Form advanced
import changeNumber from "./formAdvanced/reducer";

//Crypto
import Crypto from "./crypto/reducer";

//TicketsList
import Tickets from "./tickets/reducer";
//Type Abonnement
import TypeAbonnement from "./typeabonnement/reducer";

//Crm
import Crm from "./crm/reducer";
//Particulier
import Particulier from "./particulier/reducer";

//ENtreprise
import Entreprise from "./entreprise/reducer";

//Invoice
import Invoice from "./invoice/reducer";

//Ventes
import Vente from "./ventes/reducer";

//Documents
import Document from "./documents/reducer";

//Factures
import Facture from "./factures/reducer";

//Mailbox
import Mailbox from "./mailbox/reducer";

// Dashboard Analytics
import DashboardAnalytics from "./dashboardAnalytics/reducer";

// Dashboard CRM
import DashboardCRM from "./dashboardCRM/reducer";

// Dashboard Ecommerce
import DashboardEcommerce from "./dashboardEcommerce/reducer";

// Dashboard Cryto
import DashboardCrypto from "./dashboardCrypto/reducer";

// Dashboard Cryto
import DashboardProject from "./dashboardProject/reducer";

// Dashboard NFT
import DashboardNFT from "./dashboardNFT/reducer";

// File Manager
import FileManager from "./fileManager/reducer";

// Pages > Team
import Team from "./team/reducer";

// Pays
import Pays from "./geolocalisation/pays/reducer";

// Province
import Province from "./geolocalisation/province/reducer";

// City
import City from "./geolocalisation/city/reducer";

// Arrondissement
import Arrondissement from "./geolocalisation/arrondissements/reducer";

// Quartier
import Quartier from "./geolocalisation/quartiers/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  LoginAdmin,
  Account,
  AffiliateAccount,
  ForgetPassword,
  CreatePassword,
  Activation,
  Profile,
  Calendar,
  chat,
  Projects,
  Ecommerce,
  Tasks,
  changeNumber,
  Crypto,
  Tickets,
  TypeAbonnement,
  Crm,
  Particulier,
  Entreprise,
  Invoice,
  Mailbox,
  DashboardAnalytics,
  DashboardCRM,
  DashboardEcommerce,
  DashboardCrypto,
  DashboardProject,
  DashboardNFT,
  Team,
  Vente,
  Document,
  Facture,
  FileManager,
  Pays,
  Province,
  City,
  Arrondissement,
  Quartier,
});

export default rootReducer;
