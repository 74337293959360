import React, { useEffect, useId, useReducer, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  CardBody,
  Row,
  Col,
  Card,
  
  Container,
  Form,
  Input,
  Label,
  Table,
  FormFeedback,
} from 'reactstrap';
import { axiosIns } from '../utils';


import { Link, useHistory, withRouter, useParams } from 'react-router-dom';

import BreadCrumb from '../../Components/Common/BreadCrumb';

//redux
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import newid from '../../newid';
import axios from 'axios';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      return { ...state, itemlist: [...state.itemlist, action.payload] };
    }
    case 'REMOVE_ITEM': {
      const newItemListR = state.itemlist.filter(
        (item) => item.id !== action.payload.id
      );
      return { ...state, itemlist: newItemListR };
    }

    case 'UPDATE_PRICE': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
          ? state.itemlist.map((item) =>
              item.id === existItem.id ? {...item, prix_achat: newItem.prix_achat } : item
            )
          : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
  }
     
    case 'UPDATE_QTE': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    case 'UPDATE_PRODUCT': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);

      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    default:
      return state;
  }
};

const AchatsAddNew= (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://back.crm.inawo.pro/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const [state, invDispatch] = useReducer(reducer, {
    itemlist: [
      {
        id: newid(),
        productId: newid(),
        produit:1,
        qte: 1,
        prix_achat: 0,
      },
    ],
  });
  const { itemlist } = state;
  const addItem = () => {
    invDispatch({
      type: 'ADD_ITEM',
      payload: {
        id: newid(),
        productId: newid(),
        produit:1,
        qte: 1,
        prix_achat: 0,
      },
    });
  };

  const removeItem = (item) => {
    //const newItem = 2;
    invDispatch({
      type: 'REMOVE_ITEM',
      payload: item,
    });
  };

  const updateQuantityHandler = (item, qte) => {
    //const newItem = 2;
    invDispatch({
      type: 'UPDATE_QTE',
      payload: { ...item, qte },
    });
  };

  const updatePriceHandler = (item, prix_achat) => {
    invDispatch({
      type: 'UPDATE_PRICE',
      payload: { ...item, prix_achat},
    });
  }

  const updateProductHandler = async (item, productId) => {
    try {
      const { data } = await axiosIns.get(`/products/${productId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      console.log(data);
      invDispatch({
        type: 'UPDATE_PRODUCT',
        payload: {
          ...item,
          productId: productId,
        },
      });
    } catch (err) {
      console.log(err);
      //toast.error(t('make_sure_internet'));
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const {ventes, crmcontacts } = useSelector((state) => ({
    ventes: state.Vente.ventes,
    crmcontacts: state.Crm.crmcontacts,
  }));

  const [venteList, setVenteList] = useState([]);
  const [contactList, setContactList] = useState([]);



  // const [date1, setDate1] = useState(new Date());

  const [ispaymentDetails, setispaymentDetails] = useState(null);
  const [isCurrency, setisCurrency] = useState('$');

  function handleispaymentDetails(ispaymentDetails) {
    setispaymentDetails(ispaymentDetails);
  }

  function handleisCurrency(isCurrency) {
    setisCurrency(isCurrency);
  }

  const dateFormat = () => {
    let d = new Date(),
      months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
    return (
      d.getDate() +
      ' ' +
      months[d.getMonth()] +
      ', ' +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const [taxpercent, setTaxpercent] = useState(0);
  const [discountpercent, setDiscountpercent] = useState(0);

  const dateformate = (e) => {
    const date = e.toString().split(' ');
    const joinDate = (date[2] + ' ' + date[1] + ', ' + date[3]).toString();
    setDate(joinDate);
  };

  document.title = props.t(
    'Ajout d\'un achat | Le Conseiller du BTP'
  );

  const [productslist, setProductslist] = useState([]);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await axiosIns.get(`/products/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        console.log(data);
        setProductslist(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProducts();
  }, [token]);


  const [FormData, setFormData] = useState({
  });

  const [selectedClient, setSelectedClient] = useState({});

  const handleinputform = (e) => {
    setFormData({ ...FormData, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({});
  const [produit, setProduit] = useState("");

  const validate = () => {
    const newErrors = {};

    if (!FormData.libelle_entre){
      newErrors.libelle_entre = "Veuillez renseigner le libellé de l'achat !!!";
    } 
    if (!FormData.date_reception){
      newErrors.date_reception = "Veuillez renseigner la date de réception  !!!";
    }
    if (!FormData.montant_paye){
      newErrors.montant_paye = "Veuillez renseigner le montant payé !!!";
    }
    if (!produit){
      newErrors.produit = "Veuillez bien renseigner le produit !!!"
    }
    if(!(itemlist.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0))){
      newErrors.montant ="Veuillez bien renseigner tous les champs !!!"
    }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };

  console.log("testDR", FormData.date_reception);


  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()){
      const  details= [];
    itemlist.map((item) =>
      details.push({
        produit:item.productId,
        qte:item.qte,
        prix_achat:item.prix_achat
      })
    );
    const newEntre = {
      libelle_entre:FormData.libelle_entre,
      date_entre: new Date().toISOString().slice(0, 10),                                                
      date_reception: FormData.date_reception, 
      montant_paye: FormData.montant_paye,
      status: parseInt(FormData.montant_paye) === 0 ? "Non payé" : parseInt(FormData.montant_paye) === itemlist.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0) ? "Payé" : parseInt(FormData.montant_paye) < itemlist.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0) ? "En Cours" : "Non Payé",                                              
      montant: itemlist.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0),
      detailsEntre:  details,
    };
    console.log("gg",newEntre);

    axios.post(`${url}/entries/`,newEntre)
    .then(res=>{
      if(res.status==="200" || res.status==="201" ){
        console.log("submit",newEntre);
        toast.success(props.t("Achat enregistrée avec succès"));
        history.push("/apps-achats");
        setTimeout(()=>window.location.reload(true),2000); 

      }else{
        toast.error(props.t("Un problème est survenu lors de l\'enregistrement"),{autoClose:3000})
        console.log(res.status); 
        history.push("/apps-achats");
        setTimeout(()=>window.location.reload(true),2000); 
          
      } 
    })
      
    }
    
  };

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />
      <Container fluid>
        <BreadCrumb
          title={props.t('Ajouter un achat')}
          pageTitle={props.t('Ajout d\'un achat')}
        />
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card>
              <Form onSubmit={submitHandler}>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={6} sm={6}>
                      <div className='mb-2'>
                        <Label for='libelle_entre'>
                          {props.t("Libellé de l'achat")} <span className='text-danger'>*</span>
                        </Label>
                        <Input 
                          type="text"
                          className="form-control bg-light border-0"
                          id="libelle_entre"
                          name="libelle_entre"
                          onChange={handleinputform}
                          value={FormData.libelle_entre}
                          />
                              {errors.libelle_entre && <p className="text-danger">{props.t(errors.libelle_entre)}</p>}
                          
                      </div>
                    </Col>
                   
                    <Col lg={6} sm={6}>
                      <div className='mb-2'>
                        <Label for='date_reception'>
                          {props.t("Date de réception")} <span className='text-danger'>*</span>
                        </Label>
                        <Input 
                          type="date"
                          className="form-control bg-light border-0"
                          id="date_reception"
                          name="date_reception"
                          onChange={handleinputform}
                          value={FormData.date_reception}
                        >
                        </Input>
                        {errors.date_reception && <p className="text-danger">{props.t(errors.date_reception)}</p>}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <div className="table-responsive">
                  <div className='container-fluid d-flex justify-content-center fw-semibold border-bottom border-bottom-dashed p-2' style={{ backgroundColor: 'whitesmoke' }}>
                    <h4 className='fw-semibold'>{props.t("Achat")}</h4>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="table-responsive">
                    <Table className="invoice-table table-borderless table-nowrap mb-0">
                    <thead className="align-middle">
                        <tr className="table-active">
                          <th scope="col" style={{ width: '50px' }}>
                            #
                          </th>
                          <th scope="col">{props.t("Désignation du produit")} <span className='text-danger'>*</span></th>
                          <th scope="col" style={{ width: '120px' }}>
                              <center>{props.t("Prix")} <span className='text-danger'>*</span></center> 
                          </th>
                          <th scope="col" style={{ width: '120px' }}>
                            <center>{props.t("Quantité")} <span className='text-danger'>*</span></center>
                          </th>
                          <th
                            scope="col"
                            className="text-end"
                            style={{ width: '150px' }}
                          ><center>{props.t("Montant")} <span className='text-danger'>*</span></center>
                          </th>
                          <th
                            scope="col"
                            className="text-end"
                            style={{ width: '105px' }}
                          ></th>
                        </tr>
                      </thead>
                      { itemlist.map((item) => {
                                          
                                          return (
                                          <React.Fragment key={item.id}>
                                            <tbody name={'productInfo'} key={item.id}>
                                                <tr id={item.id} className="product">
                                                  <th scope="row" className="product-id">
                                                    <Label>{itemlist.indexOf(item) + 1}</Label>
                                                  </th>
                                                  <td className="text-start">
                                                    <div className="mb-2">
                                                      <Input 
                                                        type="select"
                                                        className="form-control bg-light border-0"
                                                        id={`productName-${item.id}`}
                                                        name={`product_name-${item.id}`}
                                                        onChange={(e) => {
                                                          console.log(e.target.value);
                                                          updateProductHandler(
                                                            item,
                                                            e.target.value
                                                          );
                                                          setProduit(e.target.value);
                                                        }}
                                                        
                                                      >
                                                        <option value="">{props.t("Sélectionner")}</option>
                                                            {productslist.map((product) => (
                                                              <>
                                                                <option value={product.id}>
                                                                  {product.designationProd +
                                                                    ' ' +
                                                                    product.marqueProd}
                                                                </option>
                                                            </>
                                                          ))}
                                                      </Input>
                                                      {errors.produit && <p className="text-danger">{props.t(errors.produit)}</p>}
                                                    </div>
                                                  </td>
                                                  <td>
                                                  <Input
                                                    type="number"
                                                    className="form-control product-price bg-light border-0"
                                                    placeholder="0.00"
                                                    id={`productRate-${item.id}`}
                                                    value={item.prix_achat}
                                                    onChange={(e) => {
                                                      updatePriceHandler(
                                                        item, 
                                                        e.target.value
                                                        );
                                                    }}
                                                  />
                                                  </td>
                                                  <td>
                                                    <div className="input-step">
                                                    <Input
                                                      type="number"
                                                      className="product-quantity"
                                                      id={`product-qty-${item.id}`}
                                                      value={item.qte}
                                                      onChange={(e) => {
                                                        updateQuantityHandler(
                                                          item,
                                                          e.target.value
                                                        );
                                                      }}
                                                      required
                                                    />
                                                    
                                                    </div>
                                                  </td>
                                                  <td className="text-end">
                                                    <div>
                                                    <Input
                                                      type="number"
                                                      className="form-control bg-light border-0 product-line-price"
                                                      id={`productPrice-${item.id}`}
                                                      placeholder="0.00"
                                                      value={item.prix_achat*item.qte}
                                                      readOnly
                                                      required
                                                    />
                                                    </div>
                                                  </td>
                                                  <td id={`product-removal-${item.id}`} className="product-removal">
                                                  <Link
                                                    id={`del-${item.id}`}
                                                    to="#"
                                                    className="btn btn-success"
                                                    onClick={() => {
                                                      removeItem(item);
                                                    }}
                                                  >
                                                    {props.t("Supprimer")}
                                                  </Link>
                                                  </td>
                                                </tr>
                                          
                                        </tbody>
                                          </React.Fragment> 
                                         ) 
                                        })
                                        }
                      <tbody>
                        <tr>
                          <td colSpan="5">
                            <Link
                              to="#"
                              className="btn btn-soft-secondary fw-medium"
                              id="add-item"
                              onClick={addItem}
                            >
                              <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                              {props.t("Ajouter un achat")}
                            </Link>
                          </td>
                        </tr>
                        <tr className="border-top border-top-dashed mt-2">
                          <td colSpan="3"></td>
                          <td colSpan="2" className="p-0">
                            <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">{props.t("Montant Payé")} <span className='text-danger'>*</span></th>
                                  <td style={{ width: '150px' }}>
                                    <Input
                                      type="number"
                                      name="montant_paye"
                                      className="form-control bg-light border-0"
                                      id="cart-subtotal"
                                      onChange={handleinputform}
                                      value={FormData.montant_paye}
                                    />
                                    {errors.montant_paye && <p className="text-danger">{props.t(errors.montant_paye)}</p>}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Montant Total")} <span className='text-danger'>*</span></th>
                                  <td style={{ width: '150px' }}>
                                    <Input
                                      type="number"
                                      name="montant"
                                      className="form-control bg-light border-0"
                                      id="cart-subtotal"
                                      placeholder="0"
                                      value={itemlist.map((item) => item.prix_achat * item.qte).reduce((a, b) => a + b, 0)}
                                      readOnly
                                    />
                                    {errors.montant && <p className="text-danger">{props.t(errors.montant)}</p>}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    <button type="submit" className="btn btn-success">
                    <i className="ri-save-3-fill align-bottom me-1"></i>{' '}
                      {props.t("Enregistrer")}
                    </button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

AchatsAddNew.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AchatsAddNew));