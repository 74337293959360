// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_ENTREPRISES = "GET_ENTREPRISES";

// Add ENTREPRISE
export const ADD_NEW_ENTREPRISE = "ADD_NEW_ENTREPRISE";
export const ADD_ENTREPRISE_SUCCESS = "ADD_ENTREPRISE_SUCCESS";
export const ADD_ENTREPRISE_FAIL = "ADD_ENTREPRISE_FAIL";

// Edit ENTREPRISE
export const UPDATE_ENTREPRISE = "UPDATE_ENTREPRISE";
export const UPDATE_ENTREPRISE_SUCCESS = "UPDATE_ENTREPRISE_SUCCESS";
export const UPDATE_ENTREPRISE_FAIL = "UPDATE_ENTREPRISE_FAIL";
