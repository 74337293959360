// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_DOCUMENTS = "GET_DOCUMENTS";

// Add DOCUMENT
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAIL = "ADD_DOCUMENT_FAIL";

// Update DOCUMENT
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAIL = "UPDATE_DOCUMENT_FAIL";

// Delete DOCUMENT
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";
