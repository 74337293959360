import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { 
  Card,
  CardBody,
  Col,
  Container,
  CardHearder,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  CardHeader,
 } from 'reactstrap';
 import * as moment from 'moment';
 import { Link } from "react-router-dom";
 import Select from "react-select";
 import classnames from "classnames";
 import Flatpickr from "react-flatpickr";
 import BreadCrumb
  from '../../Components/Common/BreadCrumb';
import TableContainer from '../../Components/Common/TableContainer';
import DeleteModal  from '../../Components/Common/DeleteModal';
import { isEmpty } from "lodash";
import axios from 'axios';
import { axiosIns } from '../../utils';

// import { ventesData } from  "../../common/data"

// Formik
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";

// redux
import { useSelector, useDispatch } from "react-redux";

import { useReducer } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

// Import Actions 
import { getdepenses as onGetDepenses } from '../../store/depenses/action';
import { getContacts as onGetContacts } from "../../store/crm/action"; 

import Loader from '../../Components/Common/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ExportCSVModal from '../../Components/Common/ExportCSVModal';




const Achats = (props) => {

  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [achatList, setAchatList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);
  
  const [achat, setAchat] = useState(null);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedMulti, setSelectedMulti] = useState(null);

  const { crmcontacts } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
  }));

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://back.crm.inawo.pro/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

   // Récuperer le type d'abonnement
   const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
   useEffect(() => {
     const checkTokenValidity = async () => {
         try {
         const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
         const getLastAbonnement = response.length - 1
         const currentTypeAbonnement = response[getLastAbonnement].typeAbonnement
         setTypeAbonnementCourant(currentTypeAbonnement)
         } catch (error) {
         console.log(error)
         } 
     };
     checkTokenValidity();
   }, []);

     // Récuperer le type d'abonnement
     const [pays, setPays] = useState("")
     useEffect(() => {
       const checkPaysValidity = async () => {
           try {
           const response = await axios.get("https://back.crm.inawo.pro/api/v1/profile/");
           const currentPays = response.pays
           setPays(currentPays)
           } catch (error) {
           console.log(error)
           } 
       };
       checkPaysValidity();
     }, []);

    useEffect(() => {
    const fetchData = async () => {
      try {
        const data  = await axios.get(`${url}/entries`);
        setAchatList(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();

  }, [url]);
  

   const [currency, setCurrency] = useState('');
   useEffect(() => {
     const fetchCurrency = async () => {
       try {
         const response = await fetch(`https://restcountries.com/v3.1/name/${pays}`);
         const data = await response.json();
         const currencies = data[0]?.currencies;
 
         if (currencies) {
           const currencyCodes = Object.keys(currencies);
           setCurrency(currencyCodes.join(', '));
         } else {
           setCurrency(' ');
         }
       } catch (error) {
         console.error(error);
         setCurrency('Error fetching currency');
       }
     };
 
     fetchCurrency();
   }, [!isEmpty(achatList)]);

   console.log("balana",currency)
 

    const [factures, setFactures] = useState([]);
    useEffect(() => {
        const fetchFactures = async () => {
          try {
            const { data } = await axiosIns.get(`/factures/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            console.log("test2", data);

            const dateSysteme = new Date();
            const moisActuel = dateSysteme.getMonth();
            const facturesFiltrees = data.filter(facture => {
              const moisFacture = parseInt(facture.dateFacture.split("T")[0].slice(5, 7)) - 1;
              return moisFacture === moisActuel;
            });
            console.log("test3", facturesFiltrees);
            setFactures(facturesFiltrees);
          } catch (err) {
            console.log(err);
          }
        };
        fetchFactures();
        }, [token]);
      

  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    if (crmcontacts && !crmcontacts.length) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContactList(crmcontacts);
  }, [crmcontacts]);
  console.log("Contacts", crmcontacts);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) setContactList(crmcontacts);
  }, [crmcontacts]);



  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }


  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setAchat(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const [dele, setDele] = useState(0);

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let h = (d.getHours() % 12) || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
  };


  const [date, setDate] = useState(defaultdate());

  const dateformate = (e) => {
    const dateString = e.toString().split(" ");

    let time = dateString[4];
    let H = +time.substr(0, 2);
    let h = (H % 12) || 12;
    h = (h <= 9) ? h = ("0" + h) : h;
    let ampm = H < 12 ? "AM" : "PM";
    time = h + time.substr(2, 3) + " " + ampm;

    const date = dateString[2] + " " + dateString[1] + ", " + dateString[3];
    const venteDate = (date + ", " + time).toString();
    setDate(venteDate);

  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
    return updateTime;
  };


  const handleAchatClick = useCallback((arg) => {
    const achat = arg;
    setAchat({
      id: achat.id,
      date_entre: achat.date_entre,
      libelle_entre:achat.libelle_entre,
      detailsEntre: [
        {
          produit: achat.produit,
          qte: achat.qte,
          prix:achat.prix
        }
      ]
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);


  const columns = useMemo(
    () => [
      {
        Header: props.t("Libellé de l'achat"),
        accessor: "libelle_entre",
        Cell: (depense) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {depense.row.original.libelle_entre}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Paiement"),
        Cell: (depense) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {
                    depense.row.original.status === "En Cours" ? <span className='badge badge-soft-secondary me-1'>{props.t("En cours")}</span> : depense.row.original.status === "Non payé" ? <span className='badge badge-soft-danger me-1'>{props.t("Non payé")}</span> : <span className='badge badge-soft-success me-1'>{props.t("Payé")}</span>
                }  
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: props.t("Montant total"),
        accessor: "montant",
        Cell: (depense) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {depense.row.original.montant}  {" "}{currency}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Montant payé"),
        accessor: "montant_paye",
        Cell: (depense) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {depense.row.original.montant_paye}  {" "}{currency}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Date achat"),
        accessor: "date_entre",
        Cell: (depense) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {depense.row.original.date_entre}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Date réception"),
        accessor: "date_reception",
        Cell: (depense) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {depense.row.original.date_reception}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Actions"),
        Cell: (cellProps) => {
          const achatData = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
             <li className="list-inline-item" title={props.t("Modifier")}>
              <Link to={`/apps-achats-update/${achatData.id}/`} className="text-warning d-inline-block">
                   <i className="ri-pencil-fill align-bottom"></i>
               </Link>
             </li>
             <li className="list-inline-item" title={props.t("Voir")}>
              <Link to={`/apps-achats-details/${achatData.id}/`} className="text-warning d-inline-block">
                   <i className="ri-eye-fill align-bottom"></i>
               </Link>
             </li>
           </ul>
          );
        },
      },
    ],
   
  );

  document.title = props.t("Achat| Le Conseiller du BTP");

  return (
    <div className='page-content'>
      <ToastContainer closeButton={false} />
        
        <Container fluid>
          <BreadCrumb title={props.t("Achats")} pageTitle={props.t("Liste des achats")} />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className='card-header border-0'>
                  <div className="d-flex.align-items-center">
                    <div></div>
                    <button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          setModal(true);
                        }}
                      ><Link to={`/apps-achats-add`} className="text-white d-inline-block">
                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter un achat")} </Link>
                      </button>
                  </div>  
                </CardHeader>
                </Card>
                <Card id='orderList'>
                <CardBody className="pt-0">
                <div>
                  {  isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
                    (achatList && achatList.length > 0) ? (
                    <TableContainer
                        columns={columns}
                        data={(achatList || [])}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={8}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé! Aucunes données trouvées")}</h5>
                      </div>
                    </div>
                  )}
                  
                </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
    </div>
  )

}

Achats.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
 

export default  withRouter(withTranslation()(Achats));
