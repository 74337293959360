import React, { useEffect, useId, useReducer, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  Form,
  Input,
  Label,
  Table,
  FormFeedback,
} from 'reactstrap';

import { isEmpty } from "lodash";

import { Link, useHistory, withRouter, useParams } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from 'react-select';

import logoDark from '../../../assets/images/logo-dark.png';
import logoLight from '../../../assets/images/logo-light.png';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

//redux
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewInvoice as onAddNewInvoice,
  updateInvoice as onUpdateInvoice,
} from '../../../store/invoice/action';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import newId from '../../../../src/newid';
import { v4 as uuid } from 'uuid';
import { getContacts as onGetContacts } from "../../../store/crm/action"; 
import { getventes as onGetVentes } from "../../../store/ventes/action";
import logoInawoCRMBlanc1 from '../../../assets/images/logo-InawoCRM-Blanc-1.png';
import axios from 'axios';
import { axiosIns } from '../../../utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      return { ...state, itemlist: [...state.itemlist, action.payload] };
    }
    case 'REMOVE_ITEM': {
      const newItemListR = state.itemlist.filter(
        (item) => item.id !== action.payload.id
      );
      return { ...state, itemlist: newItemListR };
    }
    case 'UPDATE_DESCRIPTION': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
          ? state.itemlist.map((item) =>
              item.id === existItem.id ? {...item, description: newItem.description } : item
            )
          : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
  }  
    case 'UPDATE_QTE': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    case 'UPDATE_PRODUCT': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);

      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    default:
      return state;
  }
};

const DevisAdd = (props) => {
  //const uniqueId = newId();
  const [state, invDispatch] = useReducer(reducer, {
    itemlist: [
      {
        id: newId(),
        productId: newId(),
        description: '',
        qte: 1,
        qteStock: 1,
        prixProd: 0,
      },
    ],
  });
  const { itemlist } = state;
  const addItem = () => {
    invDispatch({
      type: 'ADD_ITEM',
      payload: {
        id: newId(),
        productId: newId(),
        description: '',
        qte: 1,
        qteStock: 1,
        prixProd: 0,
      },
    });
  };

  const removeItem = (item) => {
    //const newItem = 2;
    invDispatch({
      type: 'REMOVE_ITEM',
      payload: item,
    });
  };

  const updateQuantityHandler = (item, qte) => {
    //const newItem = 2;
    invDispatch({
      type: 'UPDATE_QTE',
      payload: { ...item, qte },
    });
  };

  const updateDescriptionHandler = (item, description) => {
    invDispatch({
      type: 'UPDATE_DESCRIPTION',
      payload: { ...item, description },
    });
  }

  const updateProductHandler = async (item, productId) => {
    try {
      const { data } = await axiosIns.get(`/products/${productId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      console.log(data);
      invDispatch({
        type: 'UPDATE_PRODUCT',
        payload: {
          ...item,
          productId: productId,
          qteStock: data.qteStock,
          prixProd: data.prixProd,
        },
      });
    } catch (err) {
      console.log(err);
      //toast.error(t('make_sure_internet'));
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const {ventes, crmcontacts } = useSelector((state) => ({
    ventes: state.Vente.ventes,
    crmcontacts: state.Crm.crmcontacts,
  }));

  const [venteList, setVenteList] = useState([]);
  const [contactList, setContactList] = useState([]);

  //Afficher un contact 
  useEffect(() => {
    if (crmcontacts && !crmcontacts.length) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContactList(crmcontacts);
  }, [crmcontacts]);
  console.log("contacts", crmcontacts);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) 
    setContactList(crmcontacts);
  }, [crmcontacts]);

  //Afficher une vente
  useEffect(() => {
    if (ventes && !ventes.length) {
      dispatch(onGetVentes());
    }
  }, [dispatch, ventes]);

  useEffect(() => {
    setVenteList(ventes);
  }, [ventes]);
  console.log("IDVentes", ventes);

  useEffect(() => {
    if (!isEmpty(ventes))
    setVenteList(ventes);
  }, [ventes]);

  // const [date1, setDate1] = useState(new Date());

  const [ispaymentDetails, setispaymentDetails] = useState(null);
  const [isCurrency, setisCurrency] = useState('$');

  function handleispaymentDetails(ispaymentDetails) {
    setispaymentDetails(ispaymentDetails);
  }

  function handleisCurrency(isCurrency) {
    setisCurrency(isCurrency);
  }

  const paymentdetails = [
    {
      options: [
        { label: "Sélectionner la méthode de paiement", value: "" },
        {label: "Espece", value: "Espece"},
        {label: "Cheque", value: "Cheque"},
        { label: "Master Card", value: "Master Card" },
        { label: "Visa", value: "Visa" },
        { label: "Paypal", value: "Paypal" },
        {label: "Virement" , value: "Virement"},
        {label: "Mobile Money" , value: "Mobile Money"},
      ],
    },
  ];

  const allstatus = [
    {
      options: [
        {
          label: props.t('Select_Payment_Status'),
          value: props.t('Select_Payment_Status'),
        },
        { label: props.t('Paid'), value: props.t('Paid') },
        { label: props.t('Unpaid'), value: props.t('Unpaid') },
      ],
    },
  ];

  const allcurrency = [
    {
      options: [
        { label: 'FCFA', value: '(FCFA)' },
        { label: '$', value: '($)' },
        { label: '€', value: '(€)' },
      ],
    },
  ];

  const [count, setCount] = useState(0);
  const [rate, setRate] = useState(0);
  const [tax, setTax] = useState(0);
  const [dis, setDis] = useState(0);
  const [charge, setCharge] = useState(0);

  useEffect(() => {
    let tax = 0;
    let dis = 0;

    setTax(tax);
    setDis(dis);
  }, [rate, count]);

  const allstatuspayement = [
    {
      options: [
        { label: "Sélectionner le status du payement", value: "" },
        { label: "En cours", value: "En Cours" },
        { label: "Payé", value: "Terminé" },
        { label: "Non Payé", value: "Non Payé"}
      ],
    },
  ];

  const dateFormat = () => {
    let d = new Date(),
      months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
    return (
      d.getDate() +
      ' ' +
      months[d.getMonth()] +
      ', ' +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const [taxpercent, setTaxpercent] = useState(0);
  const [discountpercent, setDiscountpercent] = useState(0);

  const dateformate = (e) => {
    const date = e.toString().split(' ');
    const joinDate = (date[2] + ' ' + date[1] + ', ' + date[3]).toString();
    setDate(joinDate);
  };

  document.title = props.t(
    'Ajout d\'un devis  | Le Conseiller du BTP'
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      postalcode: '',
      registration: '',
      email: '',
      website: '',
      contact: '',
      invoiceId: '',
      date: '',
      name: '',
      status: '',
      country: '',
      amount: '',
      billing_address: '',
      billing_phone: '',
      billing_taxno: '',
      shipping_name: '',
      shipping_address: '',
      shipping_phone: '',
      shipping_taxno: '',
      product_name: '',
    },
    validationSchema: Yup.object({
      /*  postalcode: Yup.string().required('This field is required'), */
      registration: Yup.string().required('Please Enter a registration no'),
      email: Yup.string().required('Please Enter a Email'),
      /* website: Yup.string().required('Please Enter a website'), */
      contact: Yup.string().required('Please Enter a contact number'),
      /* invoiceId: Yup.string().required('This field is required'), */
      name: Yup.string().required('Please Enter a Full name'),
      // country: Yup.string().required("Please Enter a Country"),
      billing_address: Yup.string().required('Please Enter a Address'),
      billing_phone: Yup.string().required('Please Enter a Phone Number'),
      billing_taxno: Yup.string().required('Please Enter a tax Number'),
      shipping_name: Yup.string().required('Please Enter a Full name'),
      shipping_address: Yup.string().required('Please Enter a Address'),
      shipping_phone: Yup.string().required('Please Enter a Phone Number'),
      shipping_taxno: Yup.string().required('Please enter a tax Number'),
      product_name: Yup.string().required('Please Enter a product Name'),
    }),
  });

  const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;

    const [entreprise,  setEntreprise] = useState({});
    useEffect(() => {
       const fetchEntreprise = async () => {
        try {
          const {data} = await axiosIns.get(`/entreprise/`, {
            headers: {
              Authorization : 'Bearer ' + token,
            },
          });
          console.log("first",data);
          setEntreprise(data);
        } catch (err) {
          console.log(err);
        }
       }
        fetchEntreprise();
    }, [token]);

  const [productslist, setProductslist] = useState([]);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await axiosIns.get(`/products/`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        console.log(data);
        setProductslist(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProducts();
  }, [token]);


  const [FormData, setFormData] = useState({});

  const [selectedClient, setSelectedClient] = useState({});

  const handleChange = (e) => {
    console.log("e.value: ", e.target.value);
    if (e.target.value === "0") {
      setSelectedClient({});
    } else{
      const selectedClient = contactList.find((item) => item.id === parseInt(e.target.value));
      setSelectedClient(selectedClient);
    }
    
  }

  const handleinputform = (e) => {
    setFormData({ ...FormData, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({});
  const [prod, setProd] = useState("");

  const validate = () => {
    const newErrors = {};
    if (!selectedClient.id) {
      newErrors.contact = "Le contact est requis";
    }
    if (!prod){
      newErrors.product = "Le produit est requis";
    }
    if(!(itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0) + ((taxpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)) - ((discountpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)))){
      newErrors.montant ="Veuillez bien renseigner les champs !!!"
    }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };


  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()){

      const devisDetails = [];
    itemlist.map((item) =>
      devisDetails.push({
        qte: item.qte,
        description: item.description,
        produit: item.productId,
      })
    );
    const newDevis = {
      devisDetails: devisDetails,
      contact: selectedClient.id,
      montant: itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0) + ((taxpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)) - ((discountpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)),
      taxe: taxpercent,
      remise: discountpercent,
      comment: FormData.comment ? FormData.comment :  "Merci de votre confiance",
    };
    console.log("newDevis: ", newDevis)

    try {
      const { data } = await axiosIns.post(`/devis/`, newDevis, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      console.log(data);
      history.push("/apps-documents-devis");
      window.location.reload(true)
      toast.success(props.t("Devis enregistré avec succès"));
    } catch (err) {
      console.log(err);
      //toast.error(t('make_sure_internet'));
    }
    //history.push('apps-invoices-list');
      
    }
    
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t('Ajouter un devis')}
          pageTitle={props.t('Devis')}
        />
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card>
              {/* <Form
                onSubmit={submitHandler}
                className="needs-validation"
                id="invoice_form"
              > */}
              <Form onSubmit={submitHandler}>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={4}>
                      <div className="profile-user mx-auto  mb-3">
                        <Label style={{textAlign: "justify"}} >
                         <h3><strong>{entreprise && entreprise.nom}</strong></h3><br />
                            {entreprise && entreprise.capitalSocial === null ? false : props.t("Capital Social") + " : " + entreprise.capitalSocial } <br />
                            {entreprise && entreprise.num_enreg_legal1 === "" ? false : 
                              <div>
                                {entreprise.num_enreg_legal1}<br/>
                              </div>}
                            {entreprise && entreprise.num_enreg_legal2 === "" ? false : 
                            <div>
                              {entreprise.num_enreg_legal2}<br/>
                            </div>}
                            {entreprise && entreprise.adresse === "" ? false :
                            <div>
                              {entreprise.adresse}<br/>
                            </div>}
                            {entreprise && entreprise.siteweb  === null ? false : 
                            <div>
                              {entreprise.siteweb}<br/>
                            </div>}
                            {entreprise && entreprise.email === null ? false :
                            <div>
                              {entreprise.email}<br/>
                            </div>}
                            {entreprise && entreprise.telephone === "" ? false : 
                            <div>
                              {"Tel : " + entreprise.telephone}<br/>
                            </div>
                            }
                          </Label>
                      </div>
                    </Col>
                    <Col lg={4} className="ms-auto">
                      <div className='mb-2'>
                        <Label for='factureclient'>
                          {props.t("Sélectionner un client")} <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input 
                          type="select"
                          className="form-control bg-light border-0"
                          id="contact"
                          name="contact"
                          onChange={(e) => handleChange(e)}
                          onBlur={validation.handleBlur}
                        >
                            <option value="0">{props.t("Sélectionner un client")}</option>
                            {contactList.map((item, key)=> ( 
                                              item.category === "Entreprise" || item.category === "Institution"  || item.category === "Association" || item.category === "Administration" || item.category === "ONG" || item.category === "Particulier" || item.category === "Société" ?
                                                <option value={item.id} key={key}>{item.category === "Entreprise" || item.category === "Institution"  || item.category === "Association" || item.category === "Administration" || item.category === "ONG" || item.category === "Société" ? item.nomEntreprise + " - " + item.type_contact : item.nom + " - " + item.type_contact}</option> : null
                                            ))
                                            }
                        </Input>

                        {errors.contact && <p className='text-danger'>{props.t(errors.contact)}</p>}

                        <div className='row-4'>
                                    {selectedClient && selectedClient.category === "Entreprise" || selectedClient.category === "Institution" || selectedClient.category === "Association" || selectedClient.category === "Administration" || selectedClient.category === "ONG" || selectedClient.category === "Société" ? 
                                      (
                                        <div>
                                          <Label style={{textAlign: "justify"}} >
                                            {selectedClient&& selectedClient.nomEntreprise}<br />
                                                {selectedClient && selectedClient.num_enreg_legal1 === null ? false : 
                                                  <div>
                                                    {selectedClient.num_enreg_legal1}<br/>
                                                  </div>}
                                                {selectedClient && selectedClient.num_enreg_legal2 === null ? false : 
                                                <div>
                                                  {selectedClient.num_enreg_legal2}<br/>
                                                </div>}
                                                {selectedClient && selectedClient.adresse ===  null ? false :
                                                <div>
                                                  {selectedClient.adresse}<br/>
                                                </div>}
                                                {selectedClient && selectedClient.siteweb  === "" ? false : 
                                                <div>
                                                  {selectedClient.siteweb}<br/>
                                                </div>}
                                                {selectedClient && selectedClient.email === null ? false :
                                                <div>
                                                  {selectedClient.email}<br/>
                                                </div>}
                                                {selectedClient && selectedClient.telephone === null ? false : 
                                                <div>
                                                  {"Tel : " + selectedClient.telephone}<br/>
                                                </div>
                                                }
                                          </Label>
                                        </div> 
                                      )
                                      
                                      : selectedClient.category === "Particulier" ?
                                      (
                                        <div>
                                          <Label style={{textAlign: "justify"}}>
                                            {selectedClient && selectedClient.nom === "" ? false : 
                                            <div>
                                              {selectedClient.nom}<br/>
                                            </div>
                                            }
                                            {selectedClient && selectedClient.adresse === null ? false :
                                                <div>
                                                  {selectedClient.adresse}<br/>
                                                </div>}
                                            {selectedClient && selectedClient.telephone === "" ? false : 
                                                <div>
                                                  {"Tel : " + selectedClient.telephone}<br/>
                                                </div>
                                                }
                                          </Label>
                                        </div>
                                      ): null
                                      }
                        </div>
  
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <div className="table-responsive">
                      <div className='container-fluid d-flex justify-content-center fw-semibold border-bottom border-bottom-dashed p-2' style={{ backgroundColor: 'whitesmoke' }}>
                        <h4 className='fw-semibold'>{props.t("DEVIS")}</h4>
                      </div>
                    </div>
                <CardBody className="border-bottom border-bottom-dashed p-4">
                  <Row className="g-5">
                    <Col lg={3} sm={6}>
                      <Label for="invoicenoInput" className="form-label text-muted text-uppercase fw-semibold">{props.t("DEVIS")}</Label>
                      <Input
                        type="text"
                        className="bg-light border-0"
                        name='invoiceNumber'
                        id='invoiceNumber'
                        placeholder={props.t('Automatique')}
                        onChange={handleinputform}
                        readOnly
                      >
                      </Input>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <Label for="date-field" className="form-label text-muted text-uppercase fw-semibold">{props.t("DATE")}</Label>
                        <Flatpickr
                          name="date"
                          id="date-field"
                          className="form-control"
                          placeholder="Select a date"
                          options={{ 
                            defaultDate: new Date(),
                            minDate: new Date(),  // block dates before today
                            maxDate: new Date(),  // block dates after today
                          }}
                          onChange={date => {
                            setDate(date[0]);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className="p-4">
                  <div className="table-responsive">
                    <Table className="invoice-table table-borderless table-nowrap mb-0">
                      <thead className="align-middle">
                        <tr className="table-active">
                          <th scope="col" style={{ width: '50px' }}>
                            #
                          </th>
                          <th scope="col">{props.t("Désignation du produit")} <span style={{ color: "red" }}>*</span></th>
                          <th scope="col" style={{ width: '120px' }}>
                              <center>{props.t("Prix")}</center> 
                          </th>
                          <th scope="col" style={{ width: '120px' }}>
                            <center>{props.t("Quantité")}  <span style={{ color: "red" }}>*</span></center>
                          </th>
                          <th
                            scope="col"
                            className="text-end"
                            style={{ width: '150px' }}
                          ><center>{props.t("Montant")}</center>
                          </th>
                          <th
                            scope="col"
                            className="text-end"
                            style={{ width: '105px' }}
                          ></th>
                        </tr>
                      </thead>
                      {itemlist.map((item) => (
                        <>
                          <tbody key={item.id}>
                            <tr id={item.id} className="product">
                              <th scope="row" className="product-id">
                                {itemlist.indexOf(item) + 1}
                              </th>
                              <td className="text-start">
                                <div className="mb-2">
                                  <Input
                                    className="form-select"
                                    type="select"
                                    data-choices
                                    data-choices-sorting="true"
                                    id={`productName-${item.id}`}
                                    name={`product_name-${item.id}`}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      updateProductHandler(
                                        item,
                                        e.target.value
                                      );
                                      setProd(e.target.value);
                                    }}
                                  >
                                    <option value="">{props.t("Sélectionner")}</option>
                                    {productslist.map((product) => (
                                      <>
                                        <option value={product.id}>
                                          {product.designationProd + ' - ' +
                                            product.marqueProd}
                                        </option>
                                      </>
                                    ))}
                                  </Input>
                                  {errors.product && <p className='text-danger'>{props.t(errors.product)}</p>}
                                  <Input
                                  name='description'
                                  type="textarea" 
                                  className='form-control bg-light border-0'
                                  id={`productDescription-${item.id}`}
                                  placeholder={props.t("Description")}
                                  style={{ marginTop: '0.5rem' }}
                                  value={item.description || ''}
                                  onChange={(e) => {
                                    updateDescriptionHandler(
                                      item,
                                      e.target.value
                                    );
                                  }}
                                  ></Input>
                                </div>
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  className="form-control product-price bg-light border-0"
                                  placeholder="0.00"
                                  id={`productRate-${item.id}`}
                                  step="0.01"
                                  value={item.prixProd}
                                  onChange={handleinputform}
                                  readOnly
                                />
                                <div className="invalid-feedback">
                                {props.t("Veuillez entrer un prix")}
                                </div>
                              </td>
                              <td>
                                <div className="input-step">
                                  <button
                                    type="button"
                                    className="minus"
                                    onClick={() =>
                                      updateQuantityHandler(item, item.qte - 1)
                                    }
                                    disabled={item.qte === 1}
                                  >
                                    –
                                  </button>
                                  <Input
                                    type="number"
                                    className="product-quantity"
                                    id={`product-qty-${item.id}`}
                                    value={item.qte}
                                    onChange={(e)=>{
                                      handleinputform(e)
                                      updateQuantityHandler(item, e.target.value)
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="plus"
                                    onClick={() =>
                                      updateQuantityHandler(item, item.qte + 1)
                                    }
                                    disabled={item.qte === item.qteStock}
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                              <td className="text-end">
                                <div>
                                  <Input
                                    type="text"
                                    className="form-control bg-light border-0 product-line-price"
                                    id={`productPrice-${item.id}`}
                                    placeholder="0.00"
                                    value={item.prixProd * item.qte}
                                    onChange={handleinputform}
                                    readOnly
                                  />
                                </div>
                              </td>
                              <td
                                id={`product-removal-${item.id}`}
                                className="product-removal"
                              >
                                <Link
                                  id={`del-${item.id}`}
                                  to="#"
                                  className="btn btn-success"
                                  onClick={() => {
                                    removeItem(item);
                                  }}
                                >
                                  {props.t("Supprimer")}
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      ))}
                      <tbody>
                        <tr>
                          <td colSpan="5">
                            <Link
                              to="#"
                              className="btn btn-soft-secondary fw-medium"
                              id="add-item"
                              onClick={addItem}
                            >
                              <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                              {props.t("Ajouter un produit")}
                            </Link>
                          </td>
                        </tr>
                        <tr className="border-top border-top-dashed mt-2">
                          <td colSpan="3"></td>
                          <td colSpan="2" className="p-0">
                            <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">{props.t("Sub Total")} <span style={{ color: "red" }}>*</span></th>
                                  <td style={{ width: '150px' }}>
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-subtotal"
                                      placeholder="0"
                                      readOnly
                                      onChange={handleinputform}
                                      value={itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Taxe")} <span className='text-muted'>(%)</span></th>
                                  <td style={{display: "flex"}}>
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-tax"
                                      placeholder="0"
                                      name="taxpercent"
                                      value={taxpercent}
                                      onChange={e => setTaxpercent(e.target.value)}
                                      style={{width: "60px"}}
                                    />
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-tax"
                                      readOnly
                                      onChange={handleinputform}
                                      value={(taxpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)}
                                      style={{width: "150px", marginLeft: "10px"}}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    {props.t("Remise")} <span className='text-muted'>(%)</span>
                                  </th>
                                  <td style={{display: "flex"}}>
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-discount"
                                      style={{width: "60px"}}
                                      name="discountpercent"
                                      value={discountpercent}
                                      onChange={e => setDiscountpercent(e.target.value)}
                                    />
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-discount"
                                      placeholder="0"
                                      name="remisepercent"
                                      style={{width: "150px", marginLeft: "10px"}}
                                      onChange={handleinputform}
                                      value={(discountpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)}
                                    />
                                  </td>
                                </tr>
                                <tr className="border-top border-top-dashed">
                                  <th scope="row">{props.t("Total Amount")} <span style={{ color: "red" }}>*</span></th>
                                  <td>
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-total"
                                      placeholder="0"
                                      readOnly
                                      onChange={handleinputform}
                                      value={itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0) + ((taxpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)) - ((discountpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0))}
                                    />
                                    {errors.montant && <p className="text-danger">{props.t(errors.montant)}</p>}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="mt-4">
                    <Input
                      type="textarea"
                      name="comment"
                      className="form-control alert alert-info"
                      id="exampleFormControlTextarea1"
                      placeholder={props.t("Merci de votre confiance")}
                      onChange={handleinputform}
                      value={props.t(FormData.comment) || ""}
                      style={{ height: '110px' }}
                    />
                  </div>
                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    <button type="submit" className="btn btn-success">
                    <i className="ri-save-3-fill align-bottom me-1"></i>{' '}
                      {props.t("Enregistrer")}
                    </button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

DevisAdd.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};


export default withRouter(withTranslation()(DevisAdd));
