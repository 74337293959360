import {
  COUNTRY_API_KEY,
  COUNTRY_BASE_URL,
  COUNTRYENDPOINT,
} from "../constants/country";
import axios from "axios";
import { useState, useEffect } from "react";

const useFetchAllCountries = () => {
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGetCountries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${COUNTRY_BASE_URL}${COUNTRYENDPOINT.COUNTRIES}`,
        {
          headers: {
            "X-CSCAPI-KEY": COUNTRY_API_KEY,
          },
        }
      );
      setCountries(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    handleGetCountries();
  }, []);

  return { countries, isLoading, error };
};

export default useFetchAllCountries;
