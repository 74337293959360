// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PARTICULIERS = "GET_PARTICULIERS";

// Add PARTICULIER
export const ADD_NEW_PARTICULIER = "ADD_NEW_PARTICULIER";
export const ADD_PARTICULIER_SUCCESS = "ADD_PARTICULIER_SUCCESS";
export const ADD_PARTICULIER_FAIL = "ADD_PARTICULIER_FAIL";

// Edit PARTICULIER
export const UPDATE_PARTICULIER = "UPDATE_PARTICULIER";
export const UPDATE_PARTICULIER_SUCCESS = "UPDATE_PARTICULIER_SUCCESS";
export const UPDATE_PARTICULIER_FAIL = "UPDATE_PARTICULIER_FAIL";
