import React, { useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

//Simple bar

import BreadCrumb from "../../Components/Common/BreadCrumb";

import classnames from "classnames";
import { useSelector} from "react-redux";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import axios from 'axios';

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { Link,useHistory, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";

  
SwiperCore.use([FreeMode, Navigation, Thumbs]);


function AchatsDetails(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {categories } = useSelector((state) => ({
    categories :state.Ecommerce.categories
  
  }));
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;


  const achatId = useParams('id');
  const url = "https://back.crm.inawo.pro/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [selectAchat, setSelectAchat] = useState([
    {
        libelle_entre:"",
        date_entre:null,
        detailsEntre:[]
    }
  ])
  const [details, setDetails] = useState([
    {
        id:null,
        produit:null,
        prix_achat:null,
        qte:null
    }
  ])
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/entries/${achatId.id}/`);
          setSelectAchat(data)
          setDetails(data.detailsEntre)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [achatId, selectAchat, url]);
    console.log("ddd",selectAchat)
   

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const count=(j)=>{
  for(let i=1; i<=details.length;i++){
     if(j=== details.id){
        return i
     }
     else return ""
    }
  }
  console.log("ftft",details.id)

document.title =props.t("Détail de l'achat");
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title={props.t("Détail de l'achat")} pageTitle={props.t("Achats")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={12}>
                    <div className="mt-xl-0 mt-5">
                      {/* <div className="d-flex">
                        
                        <div className="flex-shrink-0">
                          <div>
                            <Tooltip
                              placement="top"
                              isOpen={ttop}
                              target="TooltipTop"
                              toggle={() => {
                                setttop(!ttop);
                              }}
                            >
                              {props.t("Modifier")}
                            </Tooltip>
                            <a
                              href={`/apps-update-depenses/${selectDepense.id}/`}
                              id="TooltipTop"
                              className="btn btn-light"
                            >
                              <i className="ri-pencil-fill align-bottom"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                       */}
                      <div className="product-content mt-2">
                        <h5 className="fs-14 mb-3">{props.t("Informations de l'achat")}</h5>
                        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                             { props.t("Achat")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2");
                              }}
                            >
                              {props.t("Détails de l'achat")}
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                        activeTab={customActiveTab}
                          className="border border-top-0 p-4"
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="1"
                          >
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row" style={{ width: "200px" }}>
                                      {props.t("Libellé de l'achat")}
                                    </th>
                                    <td> {selectAchat.libelle_entre}</td>
                                  </tr>
                                  <br></br>
                                  <tr>
                                  
                                    <th scope="row"> {props.t("Date de la dépense")} </th>
                                    <td>{selectAchat.date_entre}</td>
                                  </tr>
                                  <br></br>
                                  <tr>
                                    <th scope="row"> {props.t("Montant total de l'achat")} </th>
                                    <td>{selectAchat.montant}</td>
                                  </tr>
                                 
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                          <TabPane
                            id="nav-detail"
                            tabId="2"
                          >
                             <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                        {details.map((item,key)=>
                                        <>
                                        <th scope="row" style={{ width: "200px" }} key={key}>
                                            {props.t("Produit")} {details.indexOf(item) + 1}
                                        </th>
                                        <td>
                                            <div key={key}>{item.produit}</div>
                                        </td>
                                        </>
                                        )}
                                  </tr>

                                  <br></br>

                                  <tr>
                                        {details.map((item,key)=>
                                        <>
                                        <th scope="row" style={{ width: "200px" }} key={key}>
                                            {props.t("Quantité")} {details.indexOf(item) + 1}
                                        </th>
                                        <td>
                                            <div key={key}>{item.qte}</div>
                                        </td>
                                        </>
                                        )}
                                  </tr>
                                  <br></br>
                                  <tr>
                                        {details.map((item,key)=>
                                        <>
                                        <th scope="row" style={{ width: "200px" }} key={key}>
                                            {props.t("Prix")} {details.indexOf(item) + 1}
                                        </th>
                                        <td>
                                            <div key={key}>{item.prix_achat}</div>
                                        </td>
                                        </>
                                        )}
                                  </tr>
                                  <br></br>
                                 
                                  
                                  
                                </tbody>
                              </table>
                            </div>
                           
                          </TabPane>
                        </TabContent>
                      </div>

                      
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

AchatsDetails.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(AchatsDetails));