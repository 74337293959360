import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CREATE_PASSWORD } from "./actionTypes";
import { userCreatePasswordSuccess, userCreatePasswordError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  // postFakeCreatePwd,
  postJwtCreatePwd,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* createUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.createPassword, user.email);
      if (response) {
        yield put(
          userCreatePasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        );
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtCreatePwd, user);
      if (response) {
        yield put(
          userCreatePasswordSuccess(
            "Votre mot de passe a été réinitialisé avec succès"
          )
        );
      }
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postJwtCreatePwd, user);
      if (response) {
        yield put(
          userCreatePasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        );
      } else {
        yield put(
          userCreatePasswordSuccess(response)
        );
      }
    }
  } catch (error) {
    yield put(userCreatePasswordError(error));
  }
}

export function* watchUserPasswordCreate() {
  yield takeEvery(CREATE_PASSWORD, createUser);
}

function* createPasswordSaga() {
  yield all([fork(watchUserPasswordCreate)]);
}

export default createPasswordSaga;
