import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";
import Cookies from 'js-cookie';
// import PropTypes from "prop-types";

//import images
// import logoLight from "../../../assets/images/logo-light.png";
import logoInawoCRMBlanc2 from "../../../assets/images/logo-InawoCRM-Blanc-2.png";

const SuccessMsg = (props) => {

            document.title ="Check your mail | Le Conseiller du BTP";
        return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/en/login" className="d-inline-block auth-logo">
                                                <img src={logoInawoCRMBlanc2} width="30%" alt=""  />
                                            </Link>
                                        </div>
                                        <p className="mt-3 fs-15 fw-medium">Grow with your customers</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4 text-center">
                                            <div className="avatar-lg mx-auto mt-2">
                                                <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                    <i className="ri-mail-line"></i>
                                                </div>
                                            </div>
                                            <div className="mt-4 pt-2">
                                                <h4>Check your mails !</h4>
                                                <p className="text-muted mx-4">A verification email has been sent to your email address.</p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment >
    );
        }

// SuccessMsg.propTypes = {
//     location: PropTypes.object,
//     t: PropTypes.any,
// }

export default SuccessMsg;