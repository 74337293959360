import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    CardBody,
    Row,
    Col,
    Card,
    Container,
    Form,
    Input,
    Label,
    Table,
    FormFeedback,
    Button,
} from "reactstrap"

import { ToastContainer, toast } from 'react-toastify';

import { isEmpty } from "lodash";
 
import { Link, useHistory } from "react-router-dom"
import Flatpickr from "react-flatpickr"

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";

import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"

import { useFormik } from "formik";
import * as Yup from "yup";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import newId from '../../../src/newid'
import { axiosIns } from '../../utils'

import {getProducts as onGetProducts} from "../../store/ecommerce/action"
import { getContacts as onGetContacts } from "../../store/crm/action"; 
import { addNewVente as onAddVente } from "../../store/ventes/action";

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      return { ...state, itemlist: [...state.itemlist, action.payload] };
    }
    case 'REMOVE_ITEM': {
      const newItemListR = state.itemlist.filter(
        (item) => item.id !== action.payload.id
      );
      return { ...state, itemlist: newItemListR };
    }
    case 'UPDATE_QTE': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    case 'UPDATE_PRIX': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    case 'UPDATE_PRODUCT': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);

      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    default:
      return state;
  }
};

const VenteAddNewVente = (props) => {

  const [state, invDispatch] = useReducer(reducer, {
    itemlist: [
      {
        id: newId(),
        productId: newId(),
        document: '',
        qte: 1,
        qteStock: 1,
        prixProd: 0,
      },
    ],
  });
  const { itemlist } = state;
  const addItem = () => {
    invDispatch({
      type: 'ADD_ITEM',
      payload: {
        id: newId(),
        productId: newId(),
        document: '',
        qte: 1,
        qteStock: 1,
        prixProd: 0,
      },
    });
  };

  const removeItem = (item) => {
    //const newItem = 2;
    invDispatch({
      type: 'REMOVE_ITEM',
      payload: item,
    });
  };

  const updateQuantityHandler = (item, qte) => {
    //const newItem = 2;
    invDispatch({
      type: 'UPDATE_QTE',
      payload: { ...item, qte },
    });
  };


  const updatePriceHandler = (item, prixProd) => {
    //const newItem = 2;
    invDispatch({
      type: 'UPDATE_PRIX',
      payload: { ...item, prixProd},
    });
  };



  const updateProductHandler = async (item, productId) => {
    try {
      const { data } = await axiosIns.get(`/products/${productId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      console.log(data);
      invDispatch({
        type: 'UPDATE_PRODUCT',
        payload: {
          ...item,
          productId: productId,
          qteStock: data.qteStock,
          prixProd: data.prixProd,
        },
      });
    } catch (err) {
      console.log(err);
      //toast.error(t('make_sure_internet'));
    }
  };

    const dispatch = useDispatch();
    const history = useHistory();
    const [discountpercent, setDiscountpercent] = useState(0);

    const [ispayementDetails, setIspayementDetails] = useState(null);
    const [isCurrency, setIsCurrency] = useState("$")

    function handleisCurrency(isCurrency){
        setIsCurrency(isCurrency);
    }
    
      const { products, crmcontacts } = useSelector((state) => ({
        products :state.Ecommerce.products,
        crmcontacts: state.Crm.crmcontacts,
      
      }));
   

    function handleispaymentDetails(ispayementDetails) {
        setIspayementDetails(ispayementDetails);
      }

      const [productList, setProductList] = useState([]);
      const [contactList, setContactList] = useState([]);
      const [venteList, setVenteList] = useState([]);

      useEffect(() => {
        if (products && !products.length) {
          dispatch(onGetProducts());
        }
      }, [dispatch, products]);
      
      //  remplacer products par son existant dans l'etat
      useEffect(() => {
        setProductList(products);
      }, [products]);
      console.log("products", products);


      useEffect(() => {
        if (!isEmpty(products)) setProductList(products);
      }, [products]);


      useEffect(() => {
        if (crmcontacts && !crmcontacts.length) {
          dispatch(onGetContacts());
        }
      }, [dispatch, crmcontacts]);

      useEffect(() => {
        setContactList(crmcontacts);
      }, [crmcontacts]);
      console.log("contacts", crmcontacts);

      useEffect(() => {
        if (!isEmpty(crmcontacts)) setContactList(crmcontacts);
      }, [crmcontacts]);
    
      const paymentdetails = [
        {
          options: [
            { label: props.t("Sélectionner la méthode de paiement"), value: "" },
            {label: props.t("Espèce"), value: "Espece"},
            {label: props.t("Chèque"), value: "Cheque"},
            { label: props.t("Master Card"), value: "Master Card" },
            { label: props.t("Visa"), value: "Visa" },
            { label: props.t("Paypal"), value: "Paypal" },
            {label: props.t("Virement") , value: "Virement"},
            {label: props.t("Mobile Money"), value: "Mobile Money"},
          ],
        },
      ];

      const allstatusvente = [
        {
          options: [
            { label: "Sélectionner le status de la vente", value: "" },
            { label: "En cours", value: "En Cours" },
            { label: "Terminé", value: "Terminé" },
          ],
        },
      ];

      const allstatuspayement = [
        {
          options: [
            { label: "Sélectionner le status du payement", value: "" },
            { label: "En cours", value: "En Cours" },
            { label: "Terminé", value: "Terminé" },
            { label: "Non Payé", value: "Non Payé"}
          ],
        },
      ];
    
      const allstatuslivraison = [
        {
          options: [
            { label: props.t("Sélectionner le statut de la livraison"), value: "" },
            { label: props.t("En cours"), value: "En Cours" },
            { label: props.t("Terminé"), value: "Terminé" },
          ],
        },
      ];
    

      const [count, setCount] = useState(0);
      const [rate, setRate] = useState(0);
      const [tax, setTax] = useState(0);
      const [dis, setDis] = useState(0);
      const [charge, setCharge] = useState(0);
    
      useEffect(() => {
        let tax = 0.125 * rate * count;
        let dis = 0.15 * rate * count;
    
        if (rate && count && isNaN !== 0) {
          setCharge(65);
        } else {
          setCharge(0);
        }
        setTax(tax);
        setDis(dis);
      }, [rate, count]);
    
      const dateFormat = () => {
        let d = new Date(),
          months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ];
        return (
          d.getDate() +
          ' ' +
          months[d.getMonth()] +
          ', ' +
          d.getFullYear()
        ).toString();
      };
    
      const [date, setDate] = useState(dateFormat());
    
      const dateformate = (e) => {
        const date = e.toString().split(' ');
        const joinDate = (date[2] + ' ' + date[1] + ', ' + date[3]).toString();
        setDate(joinDate);
      };

      document.title = props.t("Ajout d'une Vente | Le Conseiller du BTP");

      const validation = useFormik ({
        enableReinitialize: true,

        initialValues: {
            "numVente": 0,
            "statusVente": null,
            "statusLivraison": null,
            "statusPaiement": null,
            "typePaiement": null,
            "encaissementRecu": null,
            "contact": null,
            "commentaire": "",
            "detailsVente": []
        },
        
        validationSchema: Yup.object().shape({
          statusVente: Yup.string().required("Ce champ est obligatoire"),
          statusLivraison: Yup.string().required("Ce champ est obligatoire"),
          statusPaiement: Yup.string().required("Ce champ est obligatoire"),
          typePaiement: Yup.string().required("Ce champ est obligatoire"),
          encaissementRecu: Yup.number().required("Ce champ est obligatoire"),
          contact: Yup.number().required("Ce champ est obligatoire"),
          commentaire: Yup.string().required("Ce champ est obligatoire"),
          detailsVente: Yup.array().of(
            Yup.object().shape({
              produit: Yup.number().required("Ce champ est obligatoire"),
              qteVendu: Yup.number().required("Ce champ est obligatoire"),
            }
          )
          )
        }),

      })
      const token = JSON.parse(sessionStorage.getItem('authUser'))
      ? JSON.parse(sessionStorage.getItem('authUser')).access
      : null;
    const [productslist, setProductslist] = useState([]);
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const { data } = await axiosIns.get(`/products/`, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          setProductslist(data);
        } catch (err) {
          console.log(err);
          //toast.error(t('make_sure_internet'));
        }
      };
      fetchProducts();
    }, [token]);
  
    const [FormData, setFormData] = useState({
            "numVente": 0,
            "statusVente": null,
            "statusLivraison": null,
            "statusPaiement": null,
            "typePaiement": null,
            "encaissementRecu": null,
            "contact": null,
            "echeance": "",
            "commentaire": "",
    });
  
    const handleInputForm = (e) => {
      setFormData({ ...FormData, [e.target.name]: e.target.value });
    };

    const [errors, setErrors] = useState({}); 

    const montantCalul2 = discountpercent === 0 ? (itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)) : (itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)) - ((discountpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0))


const validate = () => {
  const newErrors = {};
  if (!FormData.statusLivraison) {
    newErrors.statusLivraison = "Le statut de livraison est requis";
  }
  if (!FormData.typePaiement) {
    newErrors.typePaiement = "La méthode de paiement est requise";
  }
  if (!FormData.encaissementRecu) {
    newErrors.encaissementRecu = "L'encaissement reçu est requis";
  }
  if (!FormData.contact) {
    newErrors.contact = "Le contact est requis";
  }
  if(!montantCalul2){
    newErrors.montant = "Veuillez renseigner les produits ou services !!!"
  }
  if (parseInt(FormData.encaissementRecu) < parseInt(montantCalul2) && !FormData.echeance) {
    newErrors.echeance = "L'encaissement étant inférieur au montant, l'échéance est requise";
  }
  if (parseInt(FormData.encaissementRecu) > parseInt(montantCalul2)) {
    newErrors.encaissementRecu = "L'encaissement ne peut pas être supérieur au montant";
  }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};
  
    const submitHandler = async (e) => {
      e.preventDefault();
      if (validate()) {
        const detailsVente = [];
        itemlist.map((item) =>
          detailsVente.push({
            qteVendu: item.qte,
            produit: item.productId,
          })
        );

        const montantCalul = discountpercent === 0 ? (itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)) : (itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)) - ((discountpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0))
        
        const newVente = {
          "statusVente": parseInt(FormData.encaissementRecu) === parseInt(montantCalul) && FormData.statusLivraison === "Terminé" ? "Terminé" : "En Cours",
          "statusLivraison": FormData.statusLivraison,
          "statusPaiement":parseInt(FormData.encaissementRecu) === parseInt(montantCalul) ? "Terminé" : parseInt(FormData.encaissementRecu) < parseInt(montantCalul) ? "En Cours" : "Non Payé",
          "typePaiement": FormData.typePaiement,
          "encaissementRecu": FormData.encaissementRecu,
          "contact": FormData.contact,
          "montant": montantCalul,
          "remise": discountpercent,
          "echeance":  parseInt(FormData.encaissementRecu) === parseInt(montantCalul) ? null : FormData.echeance,
          "commentaire": "",
          "detailsVente": detailsVente,
        }
        console.log("newVente", newVente);
        
          try {
          const { data } = await axiosIns.post(`/ventes/`, newVente, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          history.push("/apps-ventes");
          window.location.reload(true)
          toast.success(props.t("Vente enregistrée avec succès"));
        } catch (err) {
          console.log(err);
          // history.push("/apps-ventes");
          // toast.error('Echec d\'ajout de la vente. Veuillez bien renseigner les informations !!!');
        }
        
        //history.push('apps-invoices-list');
      }
      
    };
  

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={props.t("Ajouter une vente")} pageTitle={props.t("Ventes")} />
                <Row className="justify-content-center">
                    <Col xxl={9}>
                        <Card>
                            <Form
                            onSubmit={submitHandler}
                            className="needs-validation"
                            id="invoice_form"
                            >
                                <CardBody className="p-4">
                                  <Row className="g-3">
                                    <Col lg={6} sm={6}>
                                      <Label for="choices-payment-status">{props.t("Statut de Livraison")} <span className='text-danger'>*</span></Label>
                                      <div className="input-light">
                                        <Input
                                          name="statusLivraison"
                                          type="select"
                                          className="bg-light border-0"
                                          id="choices-payment-status"
                                          onChange={handleInputForm}
                                          onBlur={validation.handleBlur}
                                          value={FormData.statusLivraison || ""}
                                        >
                                          {allstatuslivraison.map((item, key) => (
                                              <React.Fragment key={key}>
                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                              </React.Fragment>
                                            ))}
                                          </Input>
                                          {validation.touched.statusLivraison &&
                                            validation.errors.statusLivraison ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.statusLivraison}
                                            </FormFeedback>
                                          ) : null}
                                          {errors.statusLivraison && <p className="text-danger">{props.t(errors.statusLivraison)}</p>}
                                      </div>
                                    </Col>
                                    <Col lg={6} sm={6}>
                                      <Label for="choices-payment-status">{props.t("Méthode de Paiement")} <span className='text-danger'>*</span></Label>
                                      <div className="input-light">
                                        <Input
                                          name="typePaiement"
                                          type="select"
                                          className="bg-light border-0"
                                          id="choices-payment-status"
                                          onChange={handleInputForm}
                                          onBlur={validation.handleBlur}
                                          value={FormData.typePaiement || ""}
                                        >
                                          {paymentdetails.map((item, key) => (
                                              <React.Fragment key={key}>
                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                              </React.Fragment>
                                            ))}
                                          </Input>
                                          {validation.touched.typePaiement &&
                                            validation.errors.typePaiement ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.typePaiement}
                                            </FormFeedback>
                                          ) : null}
                                          {errors.typePaiement && <p className="text-danger">{props.t(errors.typePaiement)}</p>}
                                      </div>
                                    </Col>

                                    <Col lg={6} sm={6}> 
                                      <div className='mb-2'>
                                        <Label for='echeance'>
                                          {props.t("Echéance")}
                                        </Label>
                                        <Input 
                                          type="date"
                                          className="form-control bg-light border-0"
                                          id="echeance"
                                          name="echeance"
                                          onChange={(e) => {
                                            handleInputForm(e)
                                          }}
                                          value={FormData.echeance || ""}
                                        >
                                        </Input>
                                        {errors.echeance && <p className="text-danger">{props.t(errors.echeance)}</p>}
                                      </div>
                                    </Col>

                                  </Row>
                                </CardBody>
                                
                                <CardBody className="p-4">
                                  <div className="table-responsive">
                                    <Table className="invoice-table table table-borderless mb-0">
                                        <thead className="align-middle">
                                              <tr className="table-active">
                                                  <th scope="col" style={{ width: "30px" }}>
                                                  </th>
                                                  <th scope="col" style={{ width: "230px" }}>{props.t("Désignation")} <span className='text-danger'>*</span></th>
                                                  <th scope="col" style={{ width: "180px" }}>
                                                    {props.t("Prix")} <span className='text-danger'>*</span>
                                                  </th>
                                                  <th scope="col" style={{ width: "100px" }}>
                                                    {props.t("Quantité")} <span className='text-danger'>*</span>
                                                  </th>
                                                  <th scope="col" style={{ width: "180px" }}>
                                                    {props.t("Montant")}
                                                  </th>
                                                  <th scope="col" style={{ width: "20px" }}>
                                                  </th>
                                              </tr>
                                        </thead>
                                        { itemlist.map((item) => {
                                          
                                          return (
                                          <React.Fragment key={item.id}>
                                            <tbody name={'productInfo'} key={item.id}>
                                                <tr id={item.id} className="product">
                                                  <th scope="row" className="product-id">
                                                    <Label>{itemlist.indexOf(item) + 1}</Label>
                                                  </th>
                                                  <td className="text-start">

                                                    <div className="mb-2">

                                                      <Input 
                                                        type="select"
                                                        className="form-control bg-light border-0"
                                                        id={`productName-${item.id}`}
                                                        name={`product_name-${item.id}`}
                                                        onChange={(e) => {
                                                          console.log(e.target.value);
                                                          updateProductHandler(
                                                            item,
                                                            e.target.value
                                                          );
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        invalid={validation.touched.produit && validation.errors.produit ? true : false}
                                                      >
                                                        <option value="">{props.t("Sélectionner")}</option>
                                                            {productslist.map((product) => (
                                                              <>
                                                                <option value={product.id}>
                                                                  {product.designationProd +
                                                                    ' ' +
                                                                    product.marqueProd}
                                                                </option>
                                                            </>
                                                          ))}
                                                      </Input>
                                                      {validation.touched.produit &&
                                                        validation.errors.produit ? (
                                                        <FormFeedback type="invalid">
                                                          {validation.errors.produit}
                                                        </FormFeedback>
                                                      ) : null}
                                                    </div>
                                                  </td>
                                                  <td>
                                                  <Input
                                                    type="number"
                                                    className="form-control product-price bg-light border-0"
                                                    placeholder="0.00"
                                                    id={`productRate-${item.id}`}
                                                    step="0.01"
                                                    value={item.prixProd}
                                                    onChange={(e) => updatePriceHandler(item, e.target.value)}
                                                  />
                                                  </td>
                                                  <td>
                                                    <div className="input-step">
                                                    <button
                                                      type="button"
                                                      className="minus"
                                                      onClick={() =>
                                                        updateQuantityHandler(
                                                          item,
                                                          item.qte - 1
                                                        )
                                                      }
                                                      disabled={item.qte === 1}
                                                    >
                                                      –
                                                    </button>
                                                    <Input
                                                      type="number"
                                                      className="product-quantity"
                                                      id={`product-qty-${item.id}`}
                                                      value={item.qte}
                                                      onChange={(e) => updateQuantityHandler(item, e.target.value)}
                                                    />
                                                    <button
                                                      type="button"
                                                      className="plus"
                                                      onClick={() =>
                                                        updateQuantityHandler(
                                                          item,
                                                          item.qte + 1
                                                        )
                                                      }
                                                      disabled={item.qte === item.qteStock}
                                                    >
                                                      +
                                                    </button>
                                                    </div>
                                                  </td>
                                                  <td className="text-end">
                                                    <div>
                                                    <Input
                                                      type="text"
                                                      className="form-control bg-light border-0 product-line-price"
                                                      id={`productPrice-${item.id}`}
                                                      placeholder="0.00"
                                                      value={item.prixProd * item.qte}
                                                      readOnly
                                                    />
                                                    </div>
                                                  </td>
                                                  <td id={`product-removal-${item.id}`} className="product-removal">
                                                  <Link
                                                    id={`del-${item.id}`}
                                                    to="#"
                                                    className="btn btn-success"
                                                    onClick={() => {
                                                      removeItem(item);
                                                    }}
                                                  >
                                                    {props.t("Supprimer")}
                                                  </Link>
                                                  </td>
                                                </tr>
                                          
                                        </tbody>
                                          </React.Fragment> 
                                         ) 
                                        })
                                        }
                                        <tfoot>
                                        <tr>
                                           <td colSpan="5">
                                                <Link
                                                  to="#"
                                                  className="btn btn-soft-secondary fw-medium"
                                                  id="add-item"
                                                  onClick={addItem}
                                                >
                                                  <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                                                  {props.t("Ajouter un produit")}
                                                </Link>
                                            </td>
                                          </tr>
                                        <tr className="border-top border-top-dashed mt-2">
                                          <td colSpan="3"></td>
                                          <td colSpan="2" className="p-0">
                                          <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                                          <tbody>
                                            <tr>
                                              <th scope="row">
                                                {props.t("Remise")} <span className='text-muted'>(%)</span>
                                              </th>
                                              <td style={{display: "flex"}}>
                                                <Input
                                                  type="text"
                                                  className="form-control bg-light border-0"
                                                  id="cart-discount"
                                                  style={{width: "60px"}}
                                                  name="discountpercent"
                                                  value={discountpercent}
                                                  onChange={e => setDiscountpercent(e.target.value)}
                                                />
                                                <Input
                                                  type="text"
                                                  className="form-control bg-light border-0"
                                                  id="cart-discount"
                                                  placeholder="0"
                                                  name="remisepercent"
                                                  style={{width: "150px", marginLeft: "10px"}}
                                                  value={(discountpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)}
                                                />
                                              </td>
                                            </tr>
                                            <tr className="border-top border-top-dashed">
                                              <th scope="row">{props.t("Encaissement Reçu")} <span className='text-danger'>*</span></th>
                                              <td>
                                                <Input
                                                  type="number"
                                                  className="form-control bg-light border-0"
                                                  name="encaissementRecu"
                                                  placeholder="0"
                                                  id="cart-total"
                                                  onChange={handleInputForm}
                                                  value={FormData.encaissementRecu || ''}
                                                />
                                                {errors.encaissementRecu && <p className="text-danger">{props.t(errors.encaissementRecu)}</p>}
                                              </td>
                                           </tr>
                                            <tr className="border-top border-top-dashed">
                                              <th scope="row">{props.t("Montant Total")}</th>
                                              <td>
                                                <Input
                                                  type="number"
                                                  className="form-control bg-light border-0"
                                                  name="montant"
                                                  id="cart-total"
                                                  onChange={handleInputForm}
                                                  value={discountpercent === 0 ? (itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)) : (itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0)) - ((discountpercent/100) * itemlist.map((item) => item.prixProd * item.qte).reduce((a, b) => a + b, 0))}
                                                />
                                                {errors.montant && <p className="text-danger">{props.t(errors.montant)}</p>}
                                              </td>
                                           </tr>
                                          </tbody>
                                          </Table>
                                          </td> 
                                        </tr>  
                                        </tfoot>
                                                                              
                                    </Table>
                                  </div>
                                  <Row className="mt-3">
                                    <Col lg={4}>
                                      <div className="mb-2">
                                      <Input 
                                          type="select"
                                          className="form-control bg-light border-0"
                                          id="contact"
                                          name="contact"
                                          onChange={handleInputForm}
                                          value={FormData.contact || ""}
                                          onBlur={validation.handleBlur}
                                        >
                                            <option value="0">{props.t("Sélectionner un client")}</option>
                                            {contactList.map((item, key)=> ( 
                                              item.category === "Entreprise" || item.category === "Institution"  || item.category === "Association" || item.category === "Administration" || item.category === "ONG" || item.category === "Particulier" || item.category === "Société" ?
                                                <option value={item.id} key={key}>{item.category === "Entreprise" || item.category === "Institution"  || item.category === "Association" || item.category === "Administration" || item.category === "ONG" || item.category === "Société" ? item.nomEntreprise + " - " + item.type_contact : item.nom + " - " + item.type_contact}</option> : null
                                            ))
                                            }
                                        </Input>
                                        {errors.contact && <p className="text-danger">{props.t(errors.contact)}</p>}
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    <button type="submit" className="btn btn-success">
                                      <i className="ri-send-plane-fill align-bottom me-1"></i> {props.t("Ajouter")}
                                    </button>
                                  </div>
                                </CardBody>

                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

VenteAddNewVente.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default  withRouter(withTranslation()(VenteAddNewVente));