import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { getCities, getProvince } from "../../../store/actions";
import useDesignation from "../../../utils/renderDesignation";

const City = (props) => {
  const dispatch = useDispatch();
  const [city, setCity] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const { city: cityList, province: provinceList } = useSelector((state) => ({
    city: state.City.cities,
    province: state.Province.provinces,
  }));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleGetData = async () => {
    dispatch(getCities());
    dispatch(getProvince());
  };

  useEffect(() => {
    handleGetData();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(cityList)) {
      setIsLoading(false);
      setCity(cityList);
    }
  }, [cityList]);

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (city) => {
          return (
            <input
              type="checkbox"
              className="entrepriseCheckBox"
              value={city.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: props.t("VILLLE"),
        accessor: "name",
        Cell: (city) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <p className="fs-14 mb-1">{city.row.original.name}</p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("PAYS"),
        Cell: (city) => {
          const designation = useDesignation(
            provinceList,
            city.row.original.province
          );
          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className="fs-14 mb-1">{designation}</p>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: "ACTIONS",
        Cell: (city) => {
          const { id } = city.row.original;
          return (
            <li className="list-inline-item" title={props.t("Modifier")}>
              <Link
                to={`/apps-geolocalisation-city-update/${id}/`}
                className="text-warning d-inline-block"
              >
                <i className="ri-pencil-fill align-bottom" />
              </Link>
            </li>
          );
        },
      },
    ],
    [cityList, provinceList]
  );

  const handleDeleteCity = () => {
    if (city) {
      // supprimer le pays
      setDeleteModal(false);
    }
  };

  document.title = props.t("Ville - Le Conseiller du BTP");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCity}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <BreadCrumb
          title={props.t("Ville")}
          pageTitle={props.t("Liste des villes")}
        />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <div className="d-flex.align-items-center">
                  <div></div>
                  <button className="btn btn-info add-btn">
                    <Link
                      to={`/apps-geolocalisation-city-add`}
                      className="text-white d-inline-block"
                    >
                      <i className="ri-add-fill me-1 align-bottom" />{" "}
                      {props.t("Ajouter une ville")}{" "}
                    </Link>
                  </button>
                </div>
              </CardHeader>
            </Card>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {isLoading ? ( // If loading, show loading indicator
                    <div className="py-4 text-center">
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/xjovhxra.json"
                          trigger="loop"
                          colors="primary:#0960b6,secondary:#02376b"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                      </div>
                      <div className="mt-4">
                        <h5>{props.t("Chargement")}...</h5>
                      </div>
                    </div>
                  ) : city && city.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={city || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={8}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

City.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(City));
