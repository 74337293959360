import React, { useMemo } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Progress, Row } from 'reactstrap';
//SimpleBar
import SimpleBar from "simplebar-react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { AudiencesCharts, AudiencesSessionsCharts, UsersByDeviceCharts } from '../DashboardAnalytics/DashboardAnalyticsCharts';
import { sortBy } from 'lodash';
import { orderBy } from 'lodash';
import { isArray } from 'lodash';


const MyTasks = (props) => {
  const url = "https://backend.leconseillerdubtp.com/api/v1/";
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [newTask, setNewTask]=useState([]);
  const [TaskList, setTaskList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [TaskListInterest, setTaskListInterest] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {

            const data = await axios.get(`${url}/sondages/`);

            console.log(data)
            
            const aggregatedData = data?.reduce((acc, item) => {
                const existingItem = acc.find(i => i.interet === item.interet);
                if (existingItem) {
                    existingItem.interest_level += item.interest_level; // Ajoute la valeur de interest_level
                } else {
                    acc.push({ ...item });
                }
                return acc;
            }, [data]);

            console.log(aggregatedData)

            setNewTask(aggregatedData); // Mettez à jour newTask avec les données agrégées
            console.log(aggregatedData);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
      }, [url]);

      useEffect(() => {
        const fetchData = async () => {
          const aggregatedData = newTask.reduce((acc, item) => {
            if (acc[item.user]) {
              acc[item.user].count += 1;
            } else {
              acc[item.user] = { ...item, count: 1 };
            }
            return acc;
          }, {});
      
          const resultArray = Object.values(aggregatedData);
      
          console.log(resultArray);
      
          setUserList(resultArray);
        };
      
        fetchData();
      }, [newTask]);

      console.log(userList)


    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await axios.get(`${url}/users/list/`);
            setTaskList(data);
            console.log(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
      }, [url]);

      useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await axios.get(`${url}/categories/`);
            setTaskListInterest(data);
            console.log(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
      }, [url]);
      

      const sondages = useMemo(()=>{
        return newTask.filter((element)=> element.id)
      }, [newTask])

      console.log(sondages)

      const userFirst = useMemo(()=>{
        return userList.filter((element)=> element.id)
      }, [userList])

      console.log(userFirst)



    const interest =(j)=>{
        for(let i=0;i <= TaskListInterest?.length;i++){
          if(j === TaskListInterest[i]?.id){
              const interet = TaskListInterest[i]?.designation
              return interet
            }
        }
      }

    
      const seriesData = useMemo(() => {
        var array = [];
      
        sondages.forEach((element) => {
          // Si element n'est pas défini ou element.interest_level n'est pas défini, le définir à 0
          if (element && typeof element.interest_level === 'undefined') {
            element.interest_level = 0;
          }
      
          // Ajouter element.interest_level dans le tableau array
          if (element) {
            array.push(element.interest_level);
          }
        });
      
        console.log(array);
        return [{name:"Sondages", data:array}]
      }, [sondages]);

      const name1 =(j)=>{
        for(let i=0;i <= TaskList.length;i++){
          if(j === TaskList[i]?.id){
            if(TaskList[i].profile){
              const profil = TaskList[i].profile
              return profil.user_type;
            }else{
              return " "
            }
          }
        }
      }

    return (
        <React.Fragment>
          <Col lg={6} className='ml-4'>
      
                  <Row className="h-100">
                  <Card className="card-height-100">
                              <div className="card-header align-items-center d-flex">
                                  <h4 className="card-title mb-0 flex-grow-1"> Sondages par centre d'interet </h4>
                              </div>
                              <div className="card-body p-0">
                              <div>
                              <AudiencesCharts series={seriesData} dataColors='["--vz-warning" ]' />
                              </div>
                              </div>
                          </Card>
                  </Row>
          </Col>
          <Col lg={6}>
            <Card className="card-height-50">
                      <CardHeader className="align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">{props.t("Les centres d'interets les plus choisis")}</h4>
                      </CardHeader>

                      <CardBody>
                          <SimpleBar>
                              <ul className="list-group list-group-flush border-dashed p-3">
                                  {orderBy(sondages,['interest_level'],['desc','asc'])?.slice(0,3)?.map((item, index) => (
                                      <li className="list-group-item ps-0" key={index}>
                                          <div className="d-flex align-items-start">
                                              

                                              
                                              <div className="flex-grow-1">
                                                  <label> {interest(item.interet)} </label>
                                              </div>


                                          

                                              <Col>
                                                      {item.interest_level < 49 ?
                                                        <Progress value={item.interest_level} color="danger" className="animated-progess custom-progress progress-label" ><div className="label">{item.interest_level} %</div> </Progress>    
                                                        : <Progress value={item.interest_level} color="warning" className="animated-progess custom-progress progress-label" ><div className="label">{item.interest_level} %</div> </Progress>
                                                        }
                                                      
                                              </Col> 
                                              

                                          </div>
                                      </li>
                                  )) }
                              </ul>

                        
                          </SimpleBar>
                          
                      </CardBody> 
            </Card>
            <Card className="card-height-50">
                      <CardHeader className="align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">{props.t("Les types d'utilisateurs les plus fréquents")}</h4>
                      </CardHeader>

                      <CardBody>
                          <SimpleBar>

                              <ul className="list-group list-group-flush border-dashed p-3">
                                  {orderBy(userFirst, ['count'], ['desc','asc'])?.slice(0,3)?.map((item, index) => (
                                      <li className="list-group-item ps-0" key={index}>
                                          <div className="d-flex align-items-start">
                                             

                                              
                                              <div className="flex-grow-1">
                                                  <label > { name1(item.user) === 'admin' ? "Administrateur" : 
                                                  name1(item.user) === 'particular' ? "Particulier" : " ok"

                                                  } </label>
                                              </div>

                                              <Col>
                                                      {item.count < 100 ?
                                                        <Progress value={item.count} color="danger" className="animated-progess custom-progress progress-label" ><div className="label">{item.count} %</div> </Progress>    
                                                        : <Progress value={item.count} color="warning" className="animated-progess custom-progress progress-label" ><div className="label">{item.count} %</div> </Progress>
                                                        }
                                                      
                                              </Col> 

                                          </div>
                                      </li>
                                  )) }
                              </ul>
                        
                          </SimpleBar>
                          
                      </CardBody> 
            </Card>
          </Col>

        </React.Fragment>
    );
};

MyTasks.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
  };

export default  withRouter(withTranslation()(MyTasks));
