import React, { useEffect, useState,useSelector } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import axios from 'axios';
import categoriesData from "../Categories/index"
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Select,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";


import classnames from "classnames";
import Dropzone from "react-dropzone";
import { Link, useHistory, useParams } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { toast } from "react-toastify";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const MetiersUpdate=(props)=>{
  document.title = props.t("Modifier métiers");
  const history = useHistory();
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(null);
  const [selectedVisibility, setselectedVisibility] = useState(null);
  
  const metiersId = useParams('id');
  console.log(metiersId);
  const url = "https://backend.leconseillerdubtp.com/api/v1";
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [selectMetier, setSelectMetier] = useState({})
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/metier/${metiersId.id}/`);
          setSelectMetier(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [metiersId, url]);

  const [metierList,setMetierList]=useState([]);
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/metier/`);
          setMetierList(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

 
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "designation_metier":selectMetier.designation_metier
    },
    
    validationSchema: Yup.object().shape({
      designation_metier: Yup.string().required(props.t("Entrer un nom")),
    }),

    onSubmit: (values) => {
        const newCategory = {
          id:selectMetier.id,
          designation_metier: values.designation_metier,
        }

        var count=0;
        if (newCategory){
          for (let i=0; i<=selectMetier.length;i++){
            if(newCategory.designation_metier=== selectMetier[i]?.designation_metier){
              count= count+1;
              history.push("/apps-metiers");
              toast.error(props.t("Le métier existe deja"), { autoClose: 3000 });
              break;
            }
          }
          if (count===0){

            axios.patch(`${url}/metier/${metiersId.id}/`, newCategory, { 
              headers: 
              {
                Authorization: 'Bearer ' + token,
              }
          })
          .then(res => {
            if(res){
              toast.success(props.t("Metier bien modifié"), { autoClose: 3000 })
              history.push("/apps-metiers")
            }else{
              toast.error(props.t("Métier non modifié"), { autoClose: 3000 })
            }
          })
            validation.resetForm();
            history.push("/apps-metiers"); 
            setTimeout(()=>window.location.reload(true),2000);
            
           
          }else{
            count=0;
          }

        }
        
       
  }
  });
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Modification de métier")} pageTitle={props.t("Métiers")} />

        <Row>
          <Col lg={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                
              }}
              >
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="designation_metier">
                      {props.t("Titre du metier")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      name="designation_metier"
                      value={validation.values.designation_metier}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    />
                    {validation.errors.designation_metier && validation.touched.designation_metier ? (
                      <FormFeedback type="invalid">{validation.errors.designation_metier}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              
              <div className="text-end mb-3">
              <button className="btn btn-primary mr-3">
                  <Link
                    to={`apps-articles`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm">
                  {props.t("Modifier")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

MetiersUpdate.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(MetiersUpdate));
