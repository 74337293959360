import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { object, string } from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import useFetchAllCountries from "../../../Components/Hooks/useFetchAllCountries";
import useFetchAllCities from "../../../Components/Hooks/useFetchCityOfCountry";
import useFetchAllStates from "../../../Components/Hooks/useFetchStateBYCountry";
import { updateArrondissements } from "../../../store/actions";
import { axiosIns } from "../../../utils";

const UpdateArrondissement = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [arrondissement, setArrondissement] = useState({});
  const [city, setCity] = useState([]);

  const [diso2, setdiso2] = useState("");
  const [iso2, setIso2] = useState("");
  const [departement, setDepartement] = useState([]);
  const [commune, setCommune] = useState([]);

  const handleGetArrondissement = async () => {
    try {
      const response = await axiosIns.get(`arrondissements/${id}/`);
      if (response.status === 200) {
        setArrondissement(response.data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetArrondissement();
  }, [dispatch]);

  document.title = props.t(
    "Modification d'un arrondissement - Le Conseiller du BTP"
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: arrondissement?.name || "",
      city: arrondissement?.city || "",
      pays: arrondissement?.pays || "",
      province: arrondissement?.province || "",
    },

    validationSchema: object().shape({
      name: string().required(
        props.t("Veuillez entrer le nom de l'arrondissement")
      ),
      city: string().required(props.t("Veuillez choisir une city")),
      pays: string().required(props.t("Veuillez sélectionner un pays")),
      province: string().required(
        props.t("Veuillez sélectionner une province")
      ),
    }),

    onSubmit: (values) => {
      const updatedArrondissement = {
        ...arrondissement,
        name: values.name,
        city: values.city,
        pays: values.pays,
        province: values.province,
      };
      dispatch(updateArrondissements(updatedArrondissement));
      history.push("/apps-geolocalisation-arrondissement");
    },
  });

  ///////NEW CODE START////////////////////////////////////////////////
  const { countries, error, loading } = useFetchAllCountries();

  const { states } = useFetchAllStates(iso2);
  useEffect(() => {
    setDepartement(states);
  }, [states]);

  const { cities } = useFetchAllCities(iso2, diso2);
  useEffect(() => {
    setCommune(cities);
  }, [cities]);
  ///////NEW CODE END////////////////////////////////////////////////

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Ajout d'un arrondissement")}
          pageTitle={props.t("Arrondissement")}
        />

        <Row>
          <Col lg={12}>
            <Form
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Card>
                <CardBody>
                  {/* Sélection du pays */}
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="pays">
                      {props.t("Pays")}
                    </Label>
                    <Input
                      type="select"
                      className="form"
                      name="pays"
                      value={validation.values.pays}
                      onChange={(e) => {
                        const current = countries.filter(
                          (el) => el.name === e.target.value
                        )[0];
                        validation.setFieldValue("pays", current.id);
                        validation.setFieldValue("province", "");
                        validation.setFieldValue("city", "");
                        setIso2(current.iso2);
                      }}
                      onBlur={validation.handleBlur}
                    >
                      <option value={validation.values.pays}>
                        {validation.values.pays}
                      </option>
                      {countries.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.errors.pays && validation.touched.pays && (
                      <FormFeedback type="invalid">
                        {validation.errors.pays}
                      </FormFeedback>
                    )}
                  </div>

                  {/* Sélection de la province */}
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="province">
                      {props.t("Province")}
                    </Label>
                    <Input
                      type="select"
                      className="form"
                      name="province"
                      value={validation.values.province}
                      onChange={(e) => {
                        console.log({ provinceId: e.target.value });
                        const current = states.filter(
                          (el) => el.name === e.target.value
                        )[0];
                        validation.setFieldValue("province", current.name);
                        validation.setFieldValue("city", "");
                        setdiso2(current.iso2);
                      }}
                      onBlur={validation.handleBlur}
                    >
                      <option value={validation.values.province}>
                        {validation.values.province}
                      </option>
                      {departement.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.errors.province &&
                      validation.touched.province && (
                        <FormFeedback type="invalid">
                          {validation.errors.province}
                        </FormFeedback>
                      )}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label" htmlFor="city">
                      {props.t("Ville")}
                    </Label>
                    <Input
                      type="select"
                      className="form"
                      name="city"
                      value={validation.values.city}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    >
                      <option value={validation.values.city}>
                        {validation.values.city}
                      </option>
                      {commune.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.errors.city && validation.touched.city ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="name">
                      {props.t("Nom de l'arrondissement")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      placeholder={props.t("Entrer le nom de l'arrondissement")}
                      name="name"
                      value={validation.values.name}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                      disabled={
                        !validation.values.pays ||
                        !validation.values.province ||
                        !validation.values.city
                      }
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-3">
                  <Link
                    to={`/apps-geolocalisation-arrondissement`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm m-2">
                  {props.t("Valider")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

UpdateArrondissement.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UpdateArrondissement));
