import React, { useEffect, useState} from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { className } from "gridjs";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const PublicitiesAdd= (props) => {
 
  document.title = "Création d'articles ";
  const history = useHistory();

  const dateFormat = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let h = (d.getHours() % 12) || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
  };


  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://backend.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };

    const [count, setCount] = useState(1);

    const increment = () => {
      if (count < 5) {
        setCount(count + 1);
      }
    };
  
    const decrement = () => {
      if (count > 1) {
        setCount(count - 1);
      }
    };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "title": "",
      "publicity_content": "",
      "publicity_pic": null,
      "priority":null
    },
    
    validationSchema: Yup.object().shape({
      title:Yup.string().required('Ce champ est requis'),
      publicity_content:Yup.string().required('Ce champ est requis')
   
     }),

    onSubmit: (values) => {
          const formData= new FormData();
          formData.append("title",values.title);
          formData.append("publicity_content",values.publicity_content);
          formData.append("priority",count);
          selectedFile && formData.append("publicity_pic",selectedFile);
          axios.post(`${url}/publicity/`,formData)
          .then(res =>{
            if(res){
              toast.success(props.t("Publicité bien ajoutée"),{autoClose:3000})
            }
          }, (error) =>{
            if(error){
              toast.error(props.t("Publicité non ajoutée"),{autoClose:3000})
            }}
          )
          validation.resetForm();
          history.push("/apps-publicities");
          setTimeout(()=>window.location.reload(true),3000);
    }
  })
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Création de publicité")} pageTitle={props.t("Publicité")} />
        <Form encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit(); 
              }}
        >
        <Row>
          <Col lg={12}>
            <Card className="p-4">
            <div className="mb-3" >
                      <Label className="form-label" htmlFor="photo">
                        {props.t("IMAGE")}   <span style={{color: "red"}}>* Tous les champs sont obligatoires</span>
                      </Label>
                          <div>
                              <input type="file"
                               className="form-control" 
                               name="publicity_pic" 
                               accept="image/jpeg" 
                               capture="file"
                               onChange={handleFileChange}/>
                               <br />
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                  {selectedFile ? <img src={URL.createObjectURL(selectedFile)} style={{maxWidth:'50%'}} alt={`Thumbnail`} /> :null}
                                </div>
                          </div>
                      {validation.errors.publicity_pic && validation.touched.publicity_pic? (
                        <FormFeedback type="invalid">{props.t(validation.errors.publicity_pic)}</FormFeedback>
                      ) : null}
                      <br />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="title">
                        {props.t("TITRE")}  <span style={{color: "red"}}>*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form"
                        style={{fontWeight:"bold", textTransform:"uppercase"}}
                        name="title"
                        value={validation.values.title}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={validation.errors.title && validation.touched.title ? true : false}
                      />
                      {validation.errors.title && validation.touched.title? (
                        <FormFeedback type="invalid">{props.t(validation.errors.title)}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="publicty_content"> {props.t("CONTENU")}  <span style={{color: "red"}}>*</span></Label>
                      <textarea
                       className="form-control"
                       name="publicity_content"
                       value={validation.values.publicity_content}
                       onBlur={validation.handleBlur}
                       onChange={validation.handleChange}
                       rows="20"
                       cols="50"
                      />
                       {validation.errors.publicity_content && validation.touched.publicity_content? (
                        <FormFeedback type="invalid">{props.t(validation.errors.publicity_content)}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="priority">
                        {props.t("PRIORITE DE LA PUBLICITE")}  
                      </Label>
                      {/* <Input
                        type="number"
                        className="form"
                        name="priority"
                        value={validation.values.count}
                        onChange={validation.handleChange}
                        min={1}
                        max={5}
                      /> */}
                      <div style={{ display: 'flex', alignItems: 'center',justifyItems:'center' }}>
                      <button type="button" onClick={decrement } style={{ marginRight:'20px' }}>-</button>
                      <Input
                        type="number"
                        className="form"
                        name="priority"
                        style={{ textAlign: 'center' }}
                        value={count}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          if (!isNaN(newValue) && newValue >= 1 && newValue <= 5) {
                            setCount(newValue);
                          }
                        }}
                       
                      />
                       <button type="button" onClick={increment} style={{ marginLeft:'20px' }}>+</button>
                       </div>
                    </div>

            </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-6">
                  <Link
                    to={`/apps-publicities`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning m-3">
                   {props.t("Ajouter")}
                </button>
              </div> 
          </Col>
        </Row>
   </Form>
      </Container>
    </div>
  );
};
PublicitiesAdd.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(PublicitiesAdd));

