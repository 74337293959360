import {
  COUNTRY_API_KEY,
  COUNTRY_BASE_URL,
  COUNTRYENDPOINT,
} from "../constants/country";
import axios from "axios";
import { useEffect, useState } from "react";

const useFetchAllCities = (ciso, siso) => {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGetCityByCountry = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${COUNTRY_BASE_URL}${COUNTRYENDPOINT.COUNTRIES}/${ciso}${COUNTRYENDPOINT.STATES_BY_COUNTRY}/${siso}${COUNTRYENDPOINT.CITIES_BY_STATES} `,
        {
          headers: {
            "X-CSCAPI-KEY": COUNTRY_API_KEY,
          },
        }
      );
      setIsLoading(false);
      setCities(response);
      // if (response.status === 200) {
      // } else {
      //   setIsLoading(false);
      //   setError("Failed to fetch cities");
      // }
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    handleGetCityByCountry();
  }, [ciso, siso]);

  return { cities, isLoading, error };
};

export default useFetchAllCities;
