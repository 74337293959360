import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row, CardBody, Card } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { axiosIns } from '../utils';

const SuccessMsgAbo = ({ match }) => {

    const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;

    const savedLangue = Cookies.get('lang');

    const price = Cookies.get('selectedPrice');
    
    const month = Cookies.get('nbr_month');

    const year = Cookies.get('nbr_year');

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const typeAbonnement = match.params.id;
    
    const transaction_id = queryParams.get('transaction_id');

    console.log(typeAbonnement)
    console.log(transaction_id)

    const [prices, setPrices] = useState([]);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const { data } = await axiosIns.get('/pricing', {
            headers: {
              Authorization : 'Bearer ' + token,
            },
          })
        console.log("pricing", data);
        setPrices(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchPrice();
  }, []);


  const sub = {
    "transaction_id": `${transaction_id}`,
    "nb_month": month,
    "nb_year": year,
    "code_affiliation": "",
    "pricing": typeAbonnement,
    "affilie": null
  }


console.log("sub",sub);
useEffect(() => {
    if (performance.navigation.type === 1) {
        window.location.href = "/dashboard";
    } else {
        const checkTokenValidity = async () => {
            try {
                const response = await axios.post("https://back.crm.inawo.pro/api/v1/subscribes/", sub, {
                    headers: {
                      Authorization : 'Bearer ' + token,
                    },
                  });

        toast.success('Abonnement enregistré avec succès !')
        } catch (error) {
            toast.error("Une erreur est survenue lors de l'enregistrement de votre abonnement !")
        } 
    };
    checkTokenValidity();
}

}, [typeAbonnement, transaction_id]);

if (savedLangue === "Fr"){
    document.title ="Abonnement | Le Conseiller du BTP";
    return (
        <React.Fragment>      
          <div className="page-content">
            <Container fluid>
            <BreadCrumb title="Abonnement" pageTitle="Le Conseiller du BTP" />
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="mt-4">
                            <CardBody className="p-4 text-center">
                                <div className="avatar-lg mx-auto mt-2">
                                    <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                        <i className="ri-checkbox-circle-fill"></i>
                                    </div>
                                </div>
                                <div className="mt-4 pt-2">
                                    <h4>Félicitation !</h4>
                                    <p className="text-muted mx-4">Votre abonnement a été enregistré avec succès !</p>
                                    <div className="mt-4">
                                        <Link to="/dashboard" className="btn btn-success w-100">Accéder à votre dashboard</Link>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
          </div>
        </React.Fragment>
      );
} else {
    document.title ="Subscription | Le Conseiller du BTP";
    return (
        <React.Fragment>      
          <div className="page-content">
            <Container fluid>
            <BreadCrumb title="Subscription" pageTitle="Le Conseiller du BTP" />
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="mt-4">
                            <CardBody className="p-4 text-center">
                                <div className="avatar-lg mx-auto mt-2">
                                    <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                        <i className="ri-checkbox-circle-fill"></i>
                                    </div>
                                </div>
                                <div className="mt-4 pt-2">
                                    <h4>Congratulation !</h4>
                                    <p className="text-muted mx-4">Your subscription has been successfully registered !</p>
                                    <div className="mt-4">
                                        <Link to="/dashboard" className="btn btn-success w-100">Access your dashboard</Link>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
          </div>
        </React.Fragment>
      );
}
};

export default SuccessMsgAbo;