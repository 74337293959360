import {
  ADD_NEW_PROVINCE,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  DELETE_PROVINCE,
  GET_PROVINCE,
  UPDATE_PROVINCE,
} from "./actionType";

const initialState = {
  provinces: [],
  error: null,
};

export default function provinceReducer(state = initialState, action) {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PROVINCE:
          return {
            ...state,
            isLoading: false,
            provinces: action.payload.response,
          };
        case ADD_NEW_PROVINCE:
          return {
            ...state,
            isLoading: false,
            provinces: [...state.provinces, action.payload.response],
          };
        case UPDATE_PROVINCE:
          return {
            ...state,
            isLoading: false,
            provinces: state.provinces.map((province) =>
              province._id === action.payload.response._id
                ? action.payload.response
                : province
            ),
          };
        case DELETE_PROVINCE:
          return {
            ...state,
            isLoading: false,
            provinces: state.provinces.filter(
              (province) => province._id !== action.payload.response._id
            ),
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
