import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";
import axios from 'axios'

// Import Images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
  Button
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

//Import actions
import {
  getTypeAbonnements as onGetTypeAbonnements,
  addNewTypeAbonnement as onAddNewTypeAbonnement,
  updateTypeAbonnement as onUpdateTypeAbonnement,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Common/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from "react";
import { withTranslation } from "react-i18next";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";

const TypeAbonnementList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { typeabonnements, isTypeAbonnementCreated, isTypeAbonnementSuccess, error } = useSelector((state) => ({
    typeabonnements: state.TypeAbonnement.typeabonnements,
    isTypeAbonnementCreated: state.TypeAbonnement.isTypeAbonnementCreated,
    isTypeAbonnementSuccess: state.TypeAbonnement.isTypeAbonnementSuccess,
    error: state.TypeAbonnement.error,
  }));

  const open = (j) => {
    const price = typeabonnements
    for (let i = 0; i <= price.length; i++) {
        if (j === price[0]?.id) {
            return openKkiapayWidget({
                amount: 35000,
                api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                sandbox: false,
                email: "",
                callback: "https://crm.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                //  phone: "61754061",
            })
        } else if (j === price[1]?.id) {
            return openKkiapayWidget({
                amount: 46500,
                api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                sandbox: false,
                email: "",
                callback: "https://crm.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                //  phone: "61754061",
            })
        } else if (j === price[2]?.id) {
            return openKkiapayWidget({
                amount: 92500,
                api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                sandbox: false,
                email: "",
                callback: "https://crm.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                //  phone: "61754061",
            })
        } else if (j === price[3]?.id) {
            return (
                openKkiapayWidget({
                    amount: 0,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://crm.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            )
        } else {
          return (
              openKkiapayWidget({
                  amount: 0,
                  api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                  sandbox: false,
                  email: "",
                  callback: "https://crm.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                  //  phone: "61754061",
              })
          )
      }
    }
}

function successHandler(response) {
    console.log(response);
}

const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

// Récuperer le type d'abonnement
const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
useEffect(() => {
  const checkTokenValidity = async () => {
      try {
      const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      const getLastAbonnement = response.length - 1
      const currentTypeAbonnement = response[getLastAbonnement].typeAbonnement
      setTypeAbonnementCourant(currentTypeAbonnement)
      } catch (error) {
      console.log(error)
      } 
  };
  checkTokenValidity();
}, []);
console.log(typeAbonnementCourant)


useEffect(() => {
    addKkiapayListener('success', successHandler)
    return () => {
        removeKkiapayListener('success', successHandler)
    };
}, []);


  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }

  useEffect(() => {
    // setTimeout(() => history.push("apps-crm-typeabonnements"), 1000);
    console.log(typeabonnements);
    if (typeabonnements && !isTypeAbonnementSuccess) {
      dispatch(onGetTypeAbonnements());
    }
  }, [dispatch, typeabonnements,isTypeAbonnementSuccess, history, reducerValue]);

  useEffect(() => {
    setTypeAbonnement(typeabonnements);
  }, [typeabonnements]);

  useEffect(() => {
    if (!isEmpty(typeabonnements)) {
      setTypeAbonnement(typeabonnements);
      setIsEdit(false);
    }
  }, [typeabonnements]);


  const [isEdit, setIsEdit] = useState(false);
  const [typeabonnement, setTypeAbonnement] = useState([]);


  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTypeAbonnement(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const onClickDelete = (typeabonnement) => {
    setTypeAbonnement(typeabonnement);
    setDeleteModal(true);
  };

  // Add Data
  const handleTypeAbonnementClicks = () => {
    setTypeAbonnement("");
    setIsEdit(false);
    toggle();
  };


  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  const timeFormat = () => {
    let d = new Date();
    let minutes = d.getMinutes().toString().length === 1 ? '0' + d.getMinutes() : d.getMinutes();
    let hours = (d.getHours().toString() % 12) || 12;
    hours = (hours <= 9) ? hours = ("0" + hours) : hours;
    let ampm = d.getHours() >= 12 ? 'PM' : 'AM';
    return (hours + ':' + minutes + ampm);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      designation: (typeabonnement && typeabonnement.designation) || '',
      description: (typeabonnement && typeabonnement.description) || '',
      montant: (typeabonnement && typeabonnement.montant) || '',
    },
    // validationSchema: Yup.object({
    //   // designation: Yup.string().required("Veuillez renseigner la categorie"),
    //   description: Yup.string().required("Veuillez renseigner le type de contact"),
    //   montant: Yup.string().required("Veuillez renseigner le montant"),
    //   // email: Yup.string().required("Veuillez renseigner l'email"),
    //   telephone: Yup.string().required("Veuillez renseigner le numéro de téléphone"),
    //   // codePostal: Yup.string().required("Veuillez renseigner le code postal"),
    //   // facebook: Yup.string().required("Veuillez renseigner l'adresse facebook"),
    //   // twitter: Yup.string().required("Veuillez renseigner l'adresse twitter"),
    //   // instagram: Yup.string().required("Veuillez renseigner l'adresse instagram"),
    //   // linkedin: Yup.string().required("Veuillez renseigner l'adresse linkedin"),
    //   // snapchat: Yup.string().required("Veuillez renseigner l'adresse snapchat"),
    //   // tiktok: Yup.string().required("Veuillez renseigner l'adresse tiktok"),
    //   // youtube: Yup.string().required("Veuillez renseigner l'adresse youtube"),
    // }),
    onSubmit: (values) => {
      console.log(isEdit);
      if (isEdit) {
        const updateTypeAbonnement = {
          id: typeabonnement ? typeabonnement.id : 0,
          designation: values.designation,
          description: values.description,
          montant: values.montant
        };
        // update TypeAbonnement
        dispatch(onUpdateTypeAbonnement(updateTypeAbonnement));
        validation.resetForm();
        dispatch(onGetTypeAbonnements());
        handleClick()
      } else {
        const newTypeAbonnement = {       
          designation: values["designation"],
          description: values["description"],
          montant: values["montant"]
        };
        // save new TypeAbonnement
        dispatch(onAddNewTypeAbonnement(newTypeAbonnement));
        validation.resetForm();
        // setTimeout(() => window.location.href = "http://localhost:3000/apps-crm-typeabonnements", 1000);
        // window.location.href = "http://localhost:3000/apps-crm-typeabonnements";
        window.location.reload(true);
        // dispatch(onGetTypeAbonnements());
      }
      toggle();
    },
  });

  // Update Data
  const handleTypeAbonnementClick = useCallback((arg) => {
    const typeabonnement = arg;

    setTypeAbonnement({
      id: typeabonnement.id,      
      designation: typeabonnement.designation,
      description: typeabonnement.description,
      montant: typeabonnement.montant
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Node API 
  useEffect(() => {
    if (isTypeAbonnementCreated) {
      setTypeAbonnement(null);
      dispatch(onGetTypeAbonnements());
    }
  }, [
    dispatch,
    isTypeAbonnementCreated,
  ]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
    return updateTime;
  };


  // Checked All
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".typeabonnementCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };


  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="typeabonnementCheckBox" value={cellProps.row.original.id} />;
        },
        id: '#',
      },
      
      {
        Header: props.t("Formules"),
        Cell: (typeabonnement) => (
          <>
            {/* {typeabonnement.row.original.tags.map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))} */}
            <span >{typeabonnement.row.original.designation}</span>
          </>
        ),
      },
      
      {
        Header: props.t("Description"),
        Cell: (typeabonnement) => (
          <>
            {/* {typeabonnement.row.original.tags.map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))} */}
            <span >{typeabonnement.row.original.description}</span>
          </>
        ),
      },
      
      {
        Header: props.t("Montant"),
        accessor: "montant",
        filterable: false,
        Cell: (typeAbonnement) => (
          <>
            { typeAbonnement.row.original.montant !== 125000 ?
              <span>{typeAbonnement.row.original.montant}</span>:
              <span></span>
            }
          </>
        ),
      },
      {
        Header: props.t("Action"),
        Cell: (cellProps) => {
          const typeabonnementData = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {typeabonnementData.id !==5 ?
                <li title={props.t("S'abonner")}>
                  <Link to="#" className="btn btn-sm btn-soft-info"
                    onClick={() => open(typeabonnementData.id)}>
                    {props.t("Abonnez-vous")}
                  </Link>
                </li>
              : <li title={props.t("Contactez-nous")}>
                  <a className="btn btn-sm btn-soft-info" href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@inawo.pro" target='_blank'>
                    {props.t("Contactez-nous")}
                  </a>
                </li>
              }
            </ul>
          );
        },
      },
    ],
    [handleTypeAbonnementClick, props, open]
  );

  // const [designation, setdesignation] = useState("");
  // const [description, setTypeTypeAbonnement] = useState("");
  // const [form_juridique, setFormJuridique] = useState("");

  // const [assignFormJuri, setAssignFormJuri] = useState('');
  // const [assignCat, setAssignCat] = useState('');
  // const [assignTypeCont, setAssignTypeCont] = useState('');
  // // const [assignTag, setAssignTag] = useState([]);
  
  // const handlesformjuridique = (optformjuris) => {
  //   setFormJuridique(optformjuris.label);
  //   const assignedformjur = optformjuris.value;
  //   setAssignFormJuri(assignedformjur);
  //   // console.log(optformjuris.value);
  // };

  // const optformjuris = [
  //   { label: "EI", value: "PA" },
  //   { label: "EURL", value: "EURL" },
  //   { label: "SA", value: "SA" },
  //   { label: "SARL", value: "SARL" },
  //   { label: "SNC", value: "SNC" },
  //   { label: "SCS", value: "SCS" },
  //   { label: "SP", value: "SP" },
  //   { label: "SF", value: "SF" },
  //   { label: "GIE", value: "GIE" }, 
  // ];

  // const handlesdesignation = (optcats) => {
  //   setdesignation(optcats.label);
  //   const assignedcat = optcats.value;
  //   setAssignCat(assignedcat);
  // };

  // const optcats = [
  //   { label: "TypeAbonnement", value: "TypeAbonnement" },
  //   { label: "Personne morale", value: "Personne morale" },
  //   { label: "Institution", value: "Institution" },
  //   { label: "Association", value: "Association" },
  //   { label: "Administration", value: "Administration" },
  //   { label: "ONG", value: "ONG" }
  // ];

  // const handlestypetypeabonnement = (opttypetypeabonnements) => {
  //   setTypeTypeAbonnement(opttypetypeabonnements.label);
  //   const assignedtypecont = opttypetypeabonnements.value;
  //   setAssignTypeCont(assignedtypecont);
  // };

  // const opttypetypeabonnements = [
  //   { label: "Client", value: "Client" },
  //   { label: "Prospect", value: "Prospect" },
  //   { label: "Fournisseur", value: "Fournisseur" },
  //   { label: "Partenaire", value: "Partenaire" }
  // ];

  // SideBar TypeAbonnement Deatail
  const [info, setInfo] = useState([]);
  

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Type Abonnements | Le Conseiller du BTP";
  return (
    <React.Fragment>
      <div className="page-content">
        
        
        <Container fluid>
          <BreadCrumb title={props.t("Type Abonnements")} pageTitle="CRM" />
          <Row>
           
            <Col xxl={12}>
              <Card id="typeabonnementList">
                <CardBody className="pt-0">
                  <div>
                    {(isTypeAbonnementSuccess && typeabonnements.length > 0 && typeAbonnementCourant === 1) ? (
                      <TableContainer
                        columns={columns}
                        data={(typeabonnements.filter(typabo => typabo.montant > 0 && typabo.id !== typeAbonnementCourant) || [])}
                        // isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={4}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-primary"
                        handleTypeAbonnementClick={handleTypeAbonnementClicks}
                        isTypeAbonnementsFilter={true}
                        desc={false}
                      />
                    ):(isTypeAbonnementSuccess && typeabonnements.length>0 && typeAbonnementCourant === 2) ? (
                      <TableContainer
                        columns={columns}
                        data={(typeabonnements.filter(typabo => typabo.montant > 0 && typabo.id !== typeAbonnementCourant) || [])}
                        // isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={4}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-primary"
                        handleTypeAbonnementClick={handleTypeAbonnementClicks}
                        isTypeAbonnementsFilter={true}
                        desc={false}
                      />
                    ):(isTypeAbonnementSuccess && typeabonnements.length>0 && typeAbonnementCourant === 3) ? (
                      <TableContainer
                        columns={columns}
                        data={(typeabonnements.filter(typabo => typabo.montant > 0 && typabo.id !== typeAbonnementCourant) || [])}
                        // isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={4}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-primary"
                        handleTypeAbonnementClick={handleTypeAbonnementClicks}
                        isTypeAbonnementsFilter={true}
                        desc={false}
                      />
                    ):(isTypeAbonnementSuccess && typeabonnements.length > 0 && typeAbonnementCourant === 4) ? (
                      <TableContainer
                        columns={columns}
                        data={(typeabonnements.filter(typabo => typabo.montant > 0 && typabo.id !== typeAbonnementCourant) || [])}
                        // isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={4}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-primary"
                        handleTypeAbonnementClick={handleTypeAbonnementClicks}
                        isTypeAbonnementsFilter={true}
                        desc={false}
                      />
                    ):(isTypeAbonnementSuccess && typeabonnements.length>0 && typeAbonnementCourant === 5) ? (
                      <TableContainer
                        columns={columns}
                        data={(typeabonnements.filter(typabo => typabo.montant > 0 && typabo.id !== typeAbonnementCourant) || [])}
                        // isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={4}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-primary"
                        handleTypeAbonnementClick={handleTypeAbonnementClicks}
                        isTypeAbonnementsFilter={true}
                        desc={false}
                      />)
                    : (<TableContainer
                      columns={columns}
                      data={([])}
                      // isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={4}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                      handleTypeAbonnementClick={handleTypeAbonnementClicks}
                      isTypeAbonnementsFilter={true}
                      desc={false}
                    />)
                    }
                  </div>

                  
                  
                </CardBody>
              </Card>
            </Col>

            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

TypeAbonnementList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(TypeAbonnementList));
