import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_AFFILIATE,LOGOUT_AFFILIATE } from "./actionTypes";
import { apiAffiliateError,loginAffiliateSuccess, logoutAffiliateSuccess } from "./actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLoginAffiliate,
  postJwtLoginAffiliate,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginAffiliate({ payload: { affiliate, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginAffiliate,
        affiliate.email,
        affiliate.password
      );
      yield put(loginAffiliateSuccess(response));
      history.push("/affiliate/dashboard");
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const currentUrl = window.location.href;
      try {
        const response = yield call(postJwtLoginAffiliate, {
          email: affiliate.email,
          password: affiliate.password,
        });
        sessionStorage.setItem("authAffiliate", JSON.stringify(response));
        yield put(loginAffiliateSuccess(response));
        history.push("/affiliate/dashboard");
      } catch (error) {
        if (currentUrl === "https://app.inawo.pro/fr/connexion") {
          toast.error("Email ou mot de passe incorrect")
        } else {
          toast.error("Incorrect email or password")
        }
        
      }

    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeLoginAffiliate, {
        email: affiliate.email,
        password: affiliate.password,
      });
      sessionStorage.setItem("authAffiliate", JSON.stringify(response));
      if (response.status === "success") {
        yield put(loginAffiliateSuccess(response));
        history.push("/affiliate/dashboard");
      } else {
        yield put(apiAffiliateError(response));
      }
    }
    
  } catch (error) {
    yield put(apiAffiliateError(error));
  }
}

function* logoutAffiliate() {
  try {
    sessionStorage.removeItem("authAffiliate");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutAffiliateSuccess(LOGOUT_AFFILIATE, response));
    } else {
      yield put(logoutAffiliateSuccess(LOGOUT_AFFILIATE, true));
    }
  } catch (error) {
    yield put(apiAffiliateError(LOGOUT_AFFILIATE, error));
  }
}

function* authAffiliateSaga() {
  yield takeEvery(LOGIN_AFFILIATE, loginAffiliate);
  yield takeEvery(LOGOUT_AFFILIATE, logoutAffiliate);
}

export default authAffiliateSaga;
