import React, { useState } from "react";
import newId from "../../newid";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { axiosIns } from "../../utils";
import {CardBody,Table}from "reactstrap";
import { useDispatch } from "react-redux";
import { useReducer } from "react";
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      return { ...state, itemlist: [...state.itemlist, action.payload] };
    }
    case 'REMOVE_ITEM': {
      const newItemListR = state.itemlist.filter(
        (item) => item.id !== action.payload.id
      );
      return { ...state, itemlist: newItemListR };
    }
    case 'UPDATE_PRODUCT': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }

    case 'UPDATE_CAPACITY': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    default:
      return state;
  }
};

const AbonnementsAdd = (props) => {

  const [state, invDispatch] = useReducer(reducer, {
    itemlist: [
      {
        id: newId(),
        designation: '',
        capacity: '',
      },
    ],
  });
  const { itemlist } = state;
  const addItem = () => {
    invDispatch({
      type: 'ADD_ITEM',
      payload: {
        id: newId(),
        designation: '',
        capacity: '',
      },
    });
  };

  const removeItem = (item) => {
    invDispatch({
      type: 'REMOVE_ITEM',
      payload: item,
    });
  };

  const updateDesignationHandler = async (item, designation) => {
    invDispatch({
      type: 'UPDATE_PRODUCT',
      payload: { ...item, designation},
    });
  }

  const updateCapacityHandler = async (item, capacity) => {
    invDispatch({
      type: 'UPDATE_CAPACITY',
      payload: { ...item, capacity},
    });
  }

    const dispatch = useDispatch();
    const history = useHistory();

    const categgory = [
      { label: "Prestataire", value: "prestataire" },
      { label: "Client", value: "client" }
    ];

      document.title = props.t("Ajout d'un type abonnement | Le Conseiller du BTP");

      const validation = useFormik ({
        enableReinitialize: true,

        initialValues: {
            "numVente": 0,
            "statusVente": null,
            "statusLivraison": null,
            "statusPaiement": null,
            "typePaiement": null,
            "encaissementRecu": null,
            "contact": null,
            "commentaire": "",
            "detailsVente": []
        },
        
        validationSchema: Yup.object().shape({
          statusVente: Yup.string().required("Ce champ est obligatoire"),
          statusLivraison: Yup.string().required("Ce champ est obligatoire"),
          statusPaiement: Yup.string().required("Ce champ est obligatoire"),
          typePaiement: Yup.string().required("Ce champ est obligatoire"),
          encaissementRecu: Yup.number().required("Ce champ est obligatoire"),
          contact: Yup.number().required("Ce champ est obligatoire"),
          commentaire: Yup.string().required("Ce champ est obligatoire"),
          detailsVente: Yup.array().of(
            Yup.object().shape({
              produit: Yup.number().required("Ce champ est obligatoire"),
              qteVendu: Yup.number().required("Ce champ est obligatoire"),
            }
          )
          )
        }),

      })

      const token = JSON.parse(sessionStorage.getItem('authUser'))
      ? JSON.parse(sessionStorage.getItem('authUser')).access
      : null;
    const [productslist, setProductslist] = useState([]);
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const { data } = await axiosIns.get(`/abonnement-types/`, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          setProductslist(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchProducts();
    }, [token]);
  
    const [FormData, setFormData] = useState({
        "contents": [],
        "designation": "",
        "description": "",
        "montant": null,
        "nbr_mois": null,
        "_type": null
    
    });
  
    const handleInputForm = (e) => {
      setFormData({ ...FormData, [e.target.name]: e.target.value });
    };

    const [errors, setErrors] = useState({}); 



    const validate = () => {
      const newErrors = {};
      if (!FormData.designation) {
        newErrors.designation = "Ce champ est requis";
      }
      if (!FormData.description) {
        newErrors.description = "Ce champ est requis";
      }
      if (!FormData.montant) {
        newErrors.montant = "Ce champ est requis";
      }
      if (!FormData.nbr_mois) {
        newErrors.nbr_mois = "Ce champ est requis";
      }

      if (!FormData._type) {
        newErrors._type = "Ce champ est requis";
      }

    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
  
    const submitHandler = async (e) => {
      e.preventDefault();
      if (validate()) {
        const contentsList = [];
        itemlist.map((item) =>
        contentsList.push({
            designation: item.designation,
            capacity: item.capacity,
          })
        );

        const newVente = {
          "designation": FormData.designation,
          "description": FormData.description,
          "montant": FormData.montant,
          "nbr_mois": FormData.nbr_mois,
          "_type": FormData._type,
          "contents":contentsList,
        }
        
          try {
          const { data } = await axiosIns.post(`/abonnement-types/`, newVente, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          history.push("/apps-type");
          toast.success(props.t("Type d'abonnement enregistrée avec succès"));
        } catch (err) {
          console.log(err);
        }
      }
      
    };
  

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={props.t("Type d'abonnement")} pageTitle={props.t("Type d'abonnement")} />
                <Row className="justify-content-center">
                    <Col xxl={9}>
                        <Card>
                            <Form
                            onSubmit={submitHandler}
                            className="needs-validation"
                            >
                              <CardBody className="p-4">
                                  <div className="table-responsive">
                                    <Table className="invoice-table table table-borderless mb-0">
                                        <thead className="align-middle">
                                              <tr className="table-active">
                                                  <th scope="col" style={{ width: "30px" }}>
                                                  </th>
                                                  <th scope="col" style={{ width: "50%" }}>{props.t("DESIGNATION")} <span className='text-danger'>*</span></th>
                                                 
                                                  <th scope="col" style={{ width: "50%"}}>
                                                    {props.t("CAPACITE")} <span className='text-danger'>*</span>
                                                  </th>
                                              </tr>
                                        </thead>
                                        { itemlist.map((item) => {
                                          return (
                                          <React.Fragment key={item.id}>
                                            <tbody name={'contents'} key={item.id}>
                                                <tr id={item.id} className="product">
                                                  <th scope="row" className="product-id">
                                                    <Label>{itemlist.indexOf(item) + 1}</Label>
                                                  </th>
                                                  <td className="text-start">
                                                    <div className="mb-2">
                                                      <Input 
                                                        type="text"
                                                        className="form-control"
                                                        name={`contents[${item}].designation`}
                                                        onChange={(e) => {
                                                          updateDesignationHandler(
                                                            item,
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                  <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="0.00"
                                                    name={`contents[${item}].capacity`}
                                                    onChange={(e) => {
                                                      updateCapacityHandler (
                                                        item,
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                  </td>
                                                  
                                                  
                                                  <td id={`product-removal-${item.id}`} className="product-removal">
                                                  <Link
                                                    id={`del-${item.id}`}
                                                    to="#"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                      removeItem(item);
                                                    }}
                                                  >
                                                    {props.t("Supprimer")}
                                                  </Link>
                                                  </td>
                                                </tr>
                                          
                                        </tbody>
                                          </React.Fragment> 
                                         ) 
                                        })
                                        }
                                        <tfoot>
                                        <tr>
                                           <td colSpan="5">
                                                <Link
                                                  to="#"
                                                  className="btn btn-soft-success fw-medium"
                                                  id="add-item"
                                                  onClick={addItem}
                                                >
                                                  <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                                                  {props.t("Ajouter un sujet")}
                                                </Link>
                                            </td>
                                          </tr>
                                         
                                        </tfoot>
                                                                              
                                    </Table>
                                  </div>
                                  
                                </CardBody>

                                <CardBody className="p-4">
                                <Row>
                                  <Col xl={6}>
                                    <div className="mb-3">
                                    <Label className="form-label" >
                                      {props.t("NOM DU TYPE D'ABONNEMENT")} <span style={{color: "red"}}>*</span>
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form"
                                      name="designation"
                                      value={FormData.designation}
                                      onBlur={validation.handleBlur}
                                      onChange={handleInputForm}
                                    />
                                    {errors.designation && <p className="text-danger">{props.t(errors.designation)}</p>}
                                    </div>
                                  </Col>
                                  <Col xl={6}>
                                      <div className="mb-3">
                                      <Label className="form-label">
                                        {props.t("DESCRIPTION")}  <span style={{color: "red"}}>*</span>
                                      </Label>
                                      <textarea
                                        className="form-control"
                                        name="description"
                                        value={FormData.description}
                                        onBlur={validation.handleBlur}
                                        onChange={handleInputForm}
                                        rows="2"
                                        cols="20"
                                        />
                                      {errors.description && <p className="text-danger">{props.t(errors.description)}</p>}
                                      </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl={6}>
                                    <div className="mb-3">
                                      <Label className="form-label" >
                                        {props.t("MONTANT")}  <span style={{color: "red"}}>*</span>
                                      </Label>
                                      <Input
                                        type="number"
                                        className="form-control"
                                        name="montant"
                                        value={FormData.montant}
                                        onChange={handleInputForm}
                                      />
                                    </div>
                                  </Col>
                                  <Col xl={6}>
                                    <div className="mb-3">
                                    <Label className="form-label" htmlFor="">
                                      {props.t("NOMBRE DE MOIS")}  <span style={{color: "red"}}>*</span>
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      name="nbr_mois"
                                      max={12}
                                      min={1}
                                      value={FormData.nbr_mois}
                                      onChange={handleInputForm}
                                    />
                                    {errors.nbr_mois && <p className="text-danger">{props.t(errors.nbr_mois)}</p>}
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label"> {props.t("TYPE DE CLIENT")}  <span style={{color: "red"}}>*</span></Label>
                                      <Input
                                        className="form-control"
                                        name="_type"
                                        type="select"
                                        onChange={handleInputForm}
                                      >
                                        <option value="">Sélectionner un type</option>
                                          {categgory.map((item,key)=> (
                                              <option value={item.value} key={key}>{item.label}</option>
                                          ))
                                          }
                                      </Input>
                                      {errors._type && <p className="text-danger">{props.t(errors._type)}</p>}
                                      
                                    </div>
                                  </Col>
                                </Row>
                                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    <button type="submit" className="btn btn-warning">
                                      <i className=" align-bottom me-1"></i> {props.t("Ajouter")}
                                    </button>
                                  </div>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}


AbonnementsAdd.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(AbonnementsAdd));

