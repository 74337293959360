import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { object, string } from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import useFetchAllCountries from "../../../Components/Hooks/useFetchAllCountries";
import useFetchAllCities from "../../../Components/Hooks/useFetchCityOfCountry";
import useFetchAllStates from "../../../Components/Hooks/useFetchStateBYCountry";
import { addNewArrondissements } from "../../../store/actions";

const AddArrondissement = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [diso2, setdiso2] = useState("");
  const [iso2, setIso2] = useState("");
  const [departement, setDepartement] = useState([]);
  const [commune, setCommune] = useState([]);

  document.title = props.t("Ajout d'un arrondissement - Le Conseiller du BTP");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      pays: "",
      province: "",
      city: "",
    },

    validationSchema: object().shape({
      name: string().required(
        props.t("Veuillez entrer le nom de l'arrondissement")
      ),
      pays: string().required(props.t("Veuillez sélectionner un pays")),
      province: string().required(
        props.t("Veuillez sélectionner une province")
      ),
      city: string().required(props.t("Veuillez sélectionner un département")),
    }),

    onSubmit: (values) => {
      const newArrondissement = {
        name: values.name,
        pays: values.pays,
        province: values.province,
        city: values.city,
      };

      console.log("Arrondissement", newArrondissement);

      dispatch(addNewArrondissements(newArrondissement));
      history.push("/apps-geolocalisation-arrondissement");
    },
  });

  const { countries, error, loading } = useFetchAllCountries();

  const { states } = useFetchAllStates(iso2);
  useEffect(() => {
    setDepartement(states);
  }, [states]);

  const { cities } = useFetchAllCities(iso2, diso2);
  useEffect(() => {
    setCommune(cities);
  }, [cities]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Ajout d'un arrondissement")}
          pageTitle={props.t("Arrondissement")}
        />

        <Row>
          <Col lg={12}>
            <Form
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Card>
                <CardBody>
                  {/* Sélection du pays */}
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="pays">
                      {props.t("Pays")}
                    </Label>
                    <Input
                      type="select"
                      className="form"
                      name="pays"
                      value={validation.values.pays}
                      onChange={(e) => {
                        const current = countries.filter(
                          (el) => el.name === e.target.value
                        )[0];
                        validation.setFieldValue("pays", current.name);
                        validation.setFieldValue("province", "");
                        validation.setFieldValue("city", "");
                        setIso2(current.iso2);
                      }}
                      onBlur={validation.handleBlur}
                    >
                      <option value="">
                        {props.t("Sélectionner un pays")}
                      </option>
                      {countries.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.errors.pays && validation.touched.pays && (
                      <FormFeedback type="invalid">
                        {validation.errors.pays}
                      </FormFeedback>
                    )}
                  </div>

                  {/* Sélection de la province */}
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="province">
                      {props.t("Province")}
                    </Label>
                    <Input
                      type="select"
                      className="form"
                      name="province"
                      value={validation.values.province}
                      onChange={(e) => {
                        const current = states.filter(
                          (el) => el.name === e.target.value
                        )[0];
                        validation.setFieldValue("province", current.name);
                        validation.setFieldValue("city", "");
                        setdiso2(current.iso2);
                      }}
                      onBlur={validation.handleBlur}
                    >
                      <option value="">
                        {props.t("Sélectionner une province")}
                      </option>
                      {departement.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.errors.province &&
                      validation.touched.province && (
                        <FormFeedback type="invalid">
                          {validation.errors.province}
                        </FormFeedback>
                      )}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label" htmlFor="city">
                      {props.t("Ville")}
                    </Label>
                    <Input
                      type="select"
                      className="form"
                      name="city"
                      value={validation.values.city}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    >
                      <option value="">Sélectionner la ville concernée</option>
                      {commune.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    {validation.errors.city && validation.touched.city ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="name">
                      {props.t("Nom de l'arrondissement")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      placeholder={props.t("Entrer le nom de l'arrondissement")}
                      name="name"
                      value={validation.values.name}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                      disabled={
                        !validation.values.pays ||
                        !validation.values.province ||
                        !validation.values.city
                      }
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-3">
                  <Link
                    to={`apps-geolocalisation-arrondissement`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm m-2">
                  {props.t("Ajouter")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

AddArrondissement.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AddArrondissement));
