import { useMemo } from "react";

const renderDesignation = (data, id) => {
  const i = data?.find((item) => item.id === id);
  return i?.name;
};

const useDesignation = (data, id) => {
  return useMemo(() => renderDesignation(data, id), [data, id]);
};

export default useDesignation;
