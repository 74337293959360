import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CountUp from "react-countup";
import {useEffect,useState} from 'react'
import { axiosIns } from '../../utils';

//Import Icons
import FeatherIcon from "feather-icons-react";

const Widget = () => {
    const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;

   const [dashboard, setDashboard] = useState({})

   useEffect(() => {
      const fetch = async () => {
       try {
         const {data} = await axiosIns.get(`/dashboard/`, {
           headers: {
             Authorization : 'Bearer ' + token,
           },
         });
         setDashboard(data)
       } catch (err) {
         console.log(err);
       }
      }
       fetch();
   }, [token]);

    return (
        <React.Fragment>
            <Row>
                <Col md={4}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Tous les utilisateurs</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={dashboard.nb_users}
                                                duration={4}
                                            />
                                            </span></h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-soft-warning rounded-circle fs-2">
                                            <FeatherIcon
                                                icon="users"
                                                className="text-warning"
                                            />
                                        </span>
                                        <span className='bottom fs-4 text-primary m-3  rounded'>
                                            <i                                                
                                                className="ri-external-link-line"
                                            >
                                                <a href='/pages-team'></a>
                                            </i>
                                            
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Articles validés</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={dashboard.nb_articles_validated}
                                                duration={4}
                                            />
                                            </span></h2>
                                   
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-soft-warning rounded-circle fs-2">
                                            <FeatherIcon
                                                icon="activity"
                                                className="text-warning"
                                            />
                                        </span>

                                        <span className='bottom fs-4 text-primary m-3  rounded'>
                                            <i                                                
                                                className="ri-external-link-line"
                                            ></i>
                                            
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Articles non validés</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={dashboard.nb_articles_unvalidated}
                                                duration={4}
                                            />
                                            </span></h2>
                                   
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-soft-warning rounded-circle fs-2">
                                            <FeatherIcon
                                                icon="activity"
                                                className="text-warning"
                                            />
                                            
                                        </span>

                                        <span className='bottom fs-4 text-primary m-3  rounded'>
                                            <i                                                
                                                className="ri-external-link-line"
                                            ></i>
                                            
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                
            </Row>
        </React.Fragment>
    );
};

export default Widget;