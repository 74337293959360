import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, Offcanvas, OffcanvasBody, Row, UncontrolledDropdown, FormFeedback, ModalHeader } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import { axiosIns } from '../../utils';

// Rating
import Rating from "react-rating";

//User Images
import userdummy from '../../assets/images/users/user-dummy-img.jpg';

//Small Images
import smallImage9 from '../../assets/images/small/img-9.jpg';
//redux
import { useSelector, useDispatch } from 'react-redux';

//import action
// import {
//     getTeamData as onGetTeamData,
//     deleteTeamData as onDeleteTeamData,
//     addTeamData as onAddTeamData
// } from "../../store/actions";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

const AdminsDemos = (props) => {
    document.title = props.t("Comptes Démos | Administration Le Conseiller du BTP");

    const dispatch = useDispatch();
    const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

    const [abonne, setAbonne] = useState("")

    const [team, setTeam] = useState(null);
    const [demail, setDemail] = useState();

    useEffect(() => {
        const fetchTeam = async () => {
          try {
            const { data } = await axiosIns.get('/administration/users/demo/list/', {
                headers : {
                    Authorization : "Bearer " + token
            }})
            setTeam(data);
            setDemail(data.map((item) => item.email));
          } catch (err){
            console.log(err);
          }
        }
        fetchTeam();
      }, []);

      console.log("team", team);
      console.log("demail", demail);


    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        const list = document.querySelectorAll(".team-list");
        const buttonGroups = document.querySelectorAll('.filter-button');
        for (let i = 0; i < buttonGroups.length; i++) {
            buttonGroups[i].addEventListener('click', onButtonGroupClick);
        }

        function onButtonGroupClick(event) {
            if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
                document.getElementById("list-view-button").classList.add("active");
                document.getElementById("grid-view-button").classList.remove("active");
                list.forEach(function (el) {
                    el.classList.add("list-view-filter");
                    el.classList.remove("grid-view-filter");
                });

            } else {
                document.getElementById("grid-view-button").classList.add("active");
                document.getElementById("list-view-button").classList.remove("active");
                list.forEach(function (el) {
                    el.classList.remove("list-view-filter");
                    el.classList.add("grid-view-filter");
                });
            }
        }
    }, []);


    //Modal  
    const [modal, setModal] = useState(false);
    const openModal = () => setModal(!modal);

    //OffCanvas  
    const [isOpen, setIsOpen] = useState(false);
    const [sideBar, setSideBar] = useState([]);

    //Dropdown
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggledropDown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            first_name: "",
            last_name: "",
            password: "",
            cpassword: "",
            telephone: "",
            pays: "",
            adresse: "",
            societe: "",
            fonction: "",
            langue: "",
            nbrEmploye: "",
            },
        validationSchema: Yup.object({
            email: Yup.string().required("Veuillez renseigner l'email"),
            first_name: Yup.string().required("Veuillez renseigner le ou les prénom(s)"),
            last_name: Yup.string().required("Veuillez renseigner le nom"),
            telephone: Yup.string().required("Veuillez renseigner le numéro de téléphone"),
            pays: Yup.string().required("Veuillez renseigner le pays"),
            adresse: Yup.string().required("Veuillez renseigner l'adresse"),
            societe: Yup.string().required("Veuillez renseigner la société"),
            fonction: Yup.string().required("Veuillez renseigner la fonction"),
            langue: Yup.string().required("Veuillez renseigner la langue"),
            nbrEmploye: Yup.string().required("Veuillez renseigner le nombre d'employés"),
            password: Yup.string()
                        .min(8, 'Le mot de passe doit comporter au moins 8 caractères')
                        .matches(RegExp('(.*[a-z].*)'), 'Au moins une lettre minuscule')
                        .matches(RegExp('(.*[A-Z].*)'), 'Au moins une lettre majuscule')
                        .matches(RegExp('(.*[0-9].*)'), 'Au moins un chiffre')
                        .required("Ce champ est obligatoire"),
            cpassword: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Le mot de passe de confirmation ne correspond pas"
                )
            }).required("Ce champ est obligatoire"),
        }),
        onSubmit: (values) => {
            const abonne = {
                first_name: values.first_name,
                last_name: values.last_name,
                telephone: values.telephone,
                pays: values.pays,
                adresse: values.adresse,
                societe: values.societe,
                fonction: values.fonction,
                langue: values.langue,
            }
            const entreprise = {
                "nbrEmploye": values.nbrEmploye,
            }
            const newTeamData = {
                    email: values.email,
                    password: values.password,
                    abonne : abonne,
                    entreprise : entreprise,
            };
            console.log("newTeamData", newTeamData);
            // save new TeamData
            try {
                const { data } = axiosIns.post(`/administration/users/demo/`, newTeamData, {
                    headers : {
                        Authorization : "Bearer " + token
                }}
                );
                console.log("new", data);
                validation.resetForm();
                toast.success(props.t("Compte Démo ajouté avec succès"), { autoClose: 3000 });
                window.location.reload(true);
              } catch (err) {
                console.log(err);
                toast.error(props.t("Echec de l'ajout d'un compte démo"), { autoClose: 3000 });
              }
        },
    });
    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={props.t("Comptes Démos")} pageTitle={props.t("Comptes")} />
                    <Card>
                        <CardBody>
                            <Row className="g-2">
                                <Col sm={4}>
                                    {/* <div className="search-box">
                                        <Input type="text" className="form-control" placeholder="Search for name, tasks, projects or something..." />
                                        <i className="ri-search-line search-icon"></i>
                                    </div> */}
                                </Col>
                                <Col className="col-sm-auto ms-auto">
                                    <div className="list-grid-nav hstack gap-1">

                                        <Button color="info" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button"><i className="ri-grid-fill"></i></Button>
                                        <Button color="info" id="list-view-button" className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button"><i className="ri-list-unordered"></i></Button>
                                        {/* <Dropdown
                                            isOpen={dropdownOpen}
                                            toggle={toggledropDown}>
                                            <DropdownToggle type="button" className="btn btn-soft-info btn-icon fs-14">
                                                <i className="ri-more-2-fill"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <li><Link className="dropdown-item" to="#">All</Link></li>
                                                <li><Link className="dropdown-item" to="#">Last Week</Link></li>
                                                <li><Link className="dropdown-item" to="#">Last Month</Link></li>
                                                <li><Link className="dropdown-item" to="#">Last Year</Link></li>
                                            </DropdownMenu>
                                        </Dropdown> */}
                                         <Button color="info" onClick={openModal}>
                                                <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter")}
                                            </Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row>
                        <Col lg={12}>
                            <div>
                                <Row className="team-list grid-view-filter">
                                    {(team || []).map((item, key) => (
                                        <Col key={key}>
                                            <Card className="team-box">
                                                <div className="team-cover">
                                                    <img src={item.backgroundImg || smallImage9} alt="" className="img-fluid" />
                                                </div>
                                                <CardBody className="p-4">
                                                    <Row className="align-items-center team-row">
                                                        <Col className="team-settings">
                                                            <Row>
                                                                <Col>
                                                                    <div className="bookmark-icon flex-shrink-0 me-2">
                                                                        <Input type="checkbox" id="favourite1" className="bookmark-input bookmark-hide" />
                                                                        <Label htmlFor="favourite1" className="btn-star">
                                                                            <svg width="20" height="20">
                                                                                {/* <use xlink:href="#icon-star"/> */}
                                                                            </svg>
                                                                        </Label>

                                                                    </div>
                                                                </Col>
                                                                <UncontrolledDropdown direction='start' className="col text-end">
                                                                    <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                                        <i className="ri-more-fill fs-17"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => { setIsOpen(!isOpen); setSideBar(item); }}><i className="ri-eye-line me-2 align-middle text-success" />{props.t("Voir")}</DropdownItem>
                                                                        {/* <DropdownItem><i className="ri-pencil-line me-2 align-middle text-warning" />Désactiver</DropdownItem> */}
                                                                        {/* <DropdownItem onClick={() => onClickData(item)}><i className="ri-delete-bin-5-line me-2 align-middle text-danger" />Supprimer</DropdownItem> */}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={4} className="col">
                                                            <div className="flex-shrink-0 avatar-md mx-auto">
                                                                    <div className="avatar-title bg-soft-bg-secondary text-info rounded-circle fs-20">
                                                                        <div className={"avatar-title rounded-circle bg-soft-light text-info"}>
                                                                        {`${item.first_name}`.charAt(0)}
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                                    <br />
                                                                    <br /> 
                                                                <div className="team-content text-center">
                                                                    <h5 className="fs-16 mb-1">{item.first_name} <span style={{textTransform: "uppercase"}}>{item.last_name}</span></h5>
                                                                </div>
                                                                <div className="team-content text-center">
                                                                    <h5 className="mb-1">{props.t("Email")}</h5>
                                                                    <p className="text-muted mb-0">{demail}</p>
                                                                </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}

                                    {/* <Col lg={12}>
                                        <div className="text-center mb-3">
                                            <Link to="#" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load More </Link>
                                        </div>
                                    </Col> */}
                                </Row>


                                <div className="modal fade" id="addmembers" tabIndex="-1" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <Modal isOpen={modal} toggle={openModal} centered>
                                            <ModalHeader id="myModalLabel" toggle={openModal}>
                                                {props.t("Ajout de nouveaux membres")}
                                            </ModalHeader>
                                            <Form onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                openModal(!modal);
                                                return false;
                                            }}>
                                                <ModalBody>

                                                    <Row>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="teammembersLastName" className="form-label">{props.t("Nom")}</Label>
                                                                <Input type="text" className="form-control" id="teammembersLastName" placeholder={props.t("Saisissez nom")}
                                                                    name='last_name'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.last_name || ""}
                                                                    invalid={
                                                                        validation.touched.last_name && validation.errors.last_name ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.last_name && validation.errors.last_name ? (
                                                                    <FormFeedback type="invalid">{props.t(validation.errors.last_name)}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="teammembersLastName" className="form-label">{props.t("Prénom(s)")}</Label>
                                                                <Input type="text" className="form-control" id="teammembersFirstName" placeholder={props.t("Saisissez prénom(s)")}
                                                                    name='first_name'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.first_name || ""}
                                                                    invalid={
                                                                        validation.touched.first_name && validation.errors.first_name ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.first_name && validation.errors.first_name ? (
                                                                    <FormFeedback type="invalid">{props.t(validation.errors.first_name)}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="teammembersLastName" className="form-label">{props.t("Email")}</Label>
                                                                <Input type="text" className="form-control" id="teammembersEmail" placeholder={props.t("Saisissez adresse email")}
                                                                    name='email'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.email || ""}
                                                                    invalid={
                                                                        validation.touched.email && validation.errors.email ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.email && validation.errors.email ? (
                                                                    <FormFeedback type="invalid">{props.t(validation.errors.email)}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="teammembersPassword" className="form-label">{props.t("Mot de Passe")}</Label>
                                                                <Input type="password" className="form-control" id="teammembersPassword" placeholder={props.t("Saisissez mot de passe")} 
                                                                    name='password'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.password || ""}
                                                                    invalid={validation.touched.password && validation.errors.password ? true : false}
                                                                />
                                                               {validation.errors.password && validation.touched.password ? (
                                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                        ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor="teammembersCPassword" className="form-label">{props.t("Confirmer mot de passe")}</Label>
                                                                <Input type="password" className="form-control" id="teammembersCPassword" placeholder={props.t("Confirmer mot de passe")}
                                                                    name='cpassword'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.cpassword || ""}
                                                                    invalid={validation.touched.cpassword && validation.errors.cpassword ? true : false}
                                                                />
                                                                {validation.errors.cpassword && validation.touched.cpassword ? (
                                                                    <FormFeedback type="invalid">{validation.errors.cpassword}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor='telephone' className='form-label'>{props.t("Téléphone")}</Label>
                                                                <Input type='number' className='form-control' id='telephone' placeholder={props.t("Saisissez numéro de téléphone")} 
                                                                    name='telephone'
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.telephone || ""}
                                                                    invalid={validation.touched.telephone && validation.errors.telephone ? true : false}
                                                                />
                                                                {validation.errors.telephone && validation.touched.telephone ? (
                                                                    <FormFeedback type="invalid">{validation.errors.telephone}</FormFeedback>
                                                                ) : null}

                                                            </div>
                                                        </Col>


                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor='adresse' className='form-label'>{props.t("Adresse")}</Label>
                                                                    <Input type="text" className="form-control" id="adresse" placeholder="Adresse"
                                                                            name="adresse"
                                                                            value={validation.values.adresse}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.adresse && validation.touched.adresse ? true : false} />
                                                                        {validation.errors.adresse && validation.touched.adresse ? (
                                                                            <FormFeedback type="invalid">{validation.errors.adresse}</FormFeedback>
                                                                        ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor='fonction' className='form-label'>{props.t("Fonction")}</Label>
                                                                <Input type="text" className="form-control" id="fonction" placeholder="Fonction "
                                                                            name="fonction"
                                                                            value={validation.values.fonction}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.fonction && validation.touched.fonction ? true : false} />
                                                                        {validation.errors.fonction && validation.touched.fonction ? (
                                                                            <FormFeedback type="invalid">{validation.errors.fonction}</FormFeedback>
                                                                        ) : null}
                                                            </div>
                                                        </Col>


                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor='societe' className='form-label'>{props.t("Société")}</Label>
                                                                <Input type="text" className="form-control" id="societe" placeholder="Société"
                                                                            name="societe"
                                                                            value={validation.values.societe}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.societe && validation.touched.societe ? true : false} />
                                                                        {validation.errors.societe && validation.touched.societe ? (
                                                                            <FormFeedback type="invalid">{validation.errors.societe}</FormFeedback>
                                                                        ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor='nbrEmploye' className='form-label'>{props.t("Nombre d'employés")}</Label>
                                                                <Input type="number" className="form-control" id="nbrEmploye" placeholder="Nombre d'employés"
                                                                    name="nbrEmploye"
                                                                    value={validation.values.nbrEmploye}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.nbrEmploye && validation.touched.nbrEmploye ? true : false} />
                                                                {validation.errors.nbrEmploye && validation.touched.nbrEmploye ? (
                                                                    <FormFeedback type="invalid">{validation.errors.nbrEmploye}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="teammembersCountry" className="form-label">{props.t("Pays")}</Label>
                                                                <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="pays" 
                                                                    name='pays'
                                                                    value={validation.values.pays} 
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    invalid={validation.touched.pays && validation.errors.pays ? true : false}
                                                                    >
                                                                            <option value="">--- Pays ---</option>
                                                                            <option value="Afrique du Sud">Afrique du Sud</option>
                                                                            <option value="Afghanistan">Afghanistan</option>
                                                                            <option value="Albanie">Albanie</option>
                                                                            <option value="Algérie">Algérie</option>
                                                                            <option value="Allemagne">Allemagne</option>
                                                                            <option value="Andorre">Andorre</option>
                                                                            <option value="Angola">Angola</option>
                                                                            <option value="Antigua-et-Barbuda">Antigua-et-Barbuda</option>
                                                                            <option value="Arabie Saoudite">Arabie Saoudite</option>
                                                                            <option value="Argentine">Argentine</option>
                                                                            <option value="Arménie">Arménie</option>
                                                                            <option value="Australie">Australie</option>
                                                                            <option value="Autriche">Autriche</option>
                                                                            <option value="Azerbaïdjan">Azerbaïdjan</option>
                                                                            <option value="Bahamas">Bahamas</option>
                                                                            <option value="Bahreïn">Bahreïn</option>
                                                                            <option value="Bangladesh">Bangladesh</option>
                                                                            <option value="Barbade">Barbade</option>
                                                                            <option value="Belgique">Belgique</option>
                                                                            <option value="Belize">Belize</option>
                                                                            <option value="Bénin">Bénin</option>
                                                                            <option value="Bhoutan">Bhoutan</option>
                                                                            <option value="Biélorussie">Biélorussie</option>
                                                                            <option value="Birmanie">Birmanie</option>
                                                                            <option value="Bolivie">Bolivie</option>
                                                                            <option value="Bosnie-Herzégovine">Bosnie-Herzégovine</option>
                                                                            <option value="Botswana">Botswana</option>
                                                                            <option value="Brésil">Brésil</option>
                                                                            <option value="Brunei">Brunei</option>
                                                                            <option value="Bulgarie">Bulgarie</option>
                                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                                            <option value="Burundi">Burundi</option>
                                                                            <option value="Cambodge">Cambodge</option>
                                                                            <option value="Cameroun">Cameroun</option>
                                                                            <option value="Canada">Canada</option>
                                                                            <option value="Cap-Vert">Cap-Vert</option>
                                                                            <option value="Chili">Chili</option>
                                                                            <option value="Chine">Chine</option>
                                                                            <option value="Chypre">Chypre</option>
                                                                            <option value="Colombie">Colombie</option>
                                                                            <option value="Comores">Comores</option>
                                                                            <option value="Corée du Nord">Corée du Nord</option>
                                                                            <option value="Corée du Sud">Corée du Sud</option>
                                                                            <option value="Costa Rica">Costa Rica</option>
                                                                            <option value="Côte d’Ivoire">Côte d’Ivoire</option>
                                                                            <option value="Croatie">Croatie</option>
                                                                            <option value="Cuba">Cuba</option>
                                                                            <option value="Danemark">Danemark</option>
                                                                            <option value="Djibouti">Djibouti</option>
                                                                            <option value="Dominique">Dominique</option>
                                                                            <option value="Égypte">Égypte</option>
                                                                            <option value="Émirats arabes unis">Émirats arabes unis</option>
                                                                            <option value="Équateur">Équateur</option>
                                                                            <option value="Érythrée">Érythrée</option>
                                                                            <option value="Espagne">Espagne</option>
                                                                            <option value="Eswatini">Eswatini</option>
                                                                            <option value="Estonie">Estonie</option>
                                                                            <option value="États-Unis">États-Unis</option>
                                                                            <option value="Éthiopie">Éthiopie</option>
                                                                            <option value="Fidji">Fidji</option>
                                                                            <option value="Finlande">Finlande</option>
                                                                            <option value="France">France</option>
                                                                            <option value="Gabon">Gabon</option>
                                                                            <option value="Gambie">Gambie</option>
                                                                            <option value="Géorgie">Géorgie</option>
                                                                            <option value="Ghana">Ghana</option>
                                                                            <option value="Grèce">Grèce</option>
                                                                            <option value="Grenade">Grenade</option>
                                                                            <option value="Guatemala">Guatemala</option>
                                                                            <option value="Guinée">Guinée</option>
                                                                            <option value="Guinée équatoriale">Guinée équatoriale</option>
                                                                            <option value="Guinée-Bissau">Guinée-Bissau</option>
                                                                            <option value="Guyana">Guyana</option>
                                                                            <option value="Haïti">Haïti</option>
                                                                            <option value="Honduras">Honduras</option>
                                                                            <option value="Hongrie">Hongrie</option>
                                                                            <option value="Îles Cook">Îles Cook</option>
                                                                            <option value="Îles Marshall">Îles Marshall</option>
                                                                            <option value="Inde">Inde</option>
                                                                            <option value="Indonésie">Indonésie</option>
                                                                            <option value="Irak">Irak</option>
                                                                            <option value="Iran">Iran</option>
                                                                            <option value="Irlande">Irlande</option>
                                                                            <option value="Islande">Islande</option>
                                                                            <option value="Israël">Israël</option>
                                                                            <option value="Italie">Italie</option>
                                                                            <option value="Jamaïque">Jamaïque</option>
                                                                            <option value="Japon">Japon</option>
                                                                            <option value="Jordanie">Jordanie</option>
                                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                                            <option value="Kenya">Kenya</option>
                                                                            <option value="Kirghizistan">Kirghizistan</option>
                                                                            <option value="Kiribati">Kiribati</option>
                                                                            <option value="Koweït">Koweït</option>
                                                                            <option value="Laos">Laos</option>
                                                                            <option value="Lesotho">Lesotho</option>
                                                                            <option value="Lettonie">Lettonie</option>
                                                                            <option value="Liban">Liban</option>
                                                                            <option value="Liberia">Liberia</option>
                                                                            <option value="Libye">Libye</option>
                                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                                            <option value="Lituanie">Lituanie</option>
                                                                            <option value="Luxembourg">Luxembourg</option>
                                                                            <option value="Macédoine">Macédoine</option>
                                                                            <option value="Madagascar">Madagascar</option>
                                                                            <option value="Malaisie">Malaisie</option>
                                                                            <option value="Malawi">Malawi</option>
                                                                            <option value="Maldives">Maldives</option>
                                                                            <option value="Mali">Mali</option>
                                                                            <option value="Malte">Malte</option>
                                                                            <option value="Maroc">Maroc</option>
                                                                            <option value="Maurice">Maurice</option>
                                                                            <option value="Mauritanie">Mauritanie</option>
                                                                            <option value="Mexique">Mexique</option>
                                                                            <option value="Micronésie">Micronésie</option>
                                                                            <option value="Moldavie">Moldavie</option>
                                                                            <option value="Monaco">Monaco</option>
                                                                            <option value="Mongolie">Mongolie</option>
                                                                            <option value="Monténégro">Monténégro</option>
                                                                            <option value="Mozambique">Mozambique</option>
                                                                            <option value="Namibie">Namibie</option>
                                                                            <option value="Nauru">Nauru</option>
                                                                            <option value="Népal">Népal</option>
                                                                            <option value="Nicaragua">Nicaragua</option>
                                                                            <option value="Niger">Niger</option>
                                                                            <option value="Nigeria">Nigeria</option>
                                                                            <option value="Niue">Niue</option>
                                                                            <option value="Norvège">Norvège</option>
                                                                            <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
                                                                            <option value="Oman">Oman</option>
                                                                            <option value="Ouganda">Ouganda</option>
                                                                            <option value="Ouzbékistan">Ouzbékistan</option>
                                                                            <option value="Pakistan">Pakistan</option>
                                                                            <option value="Palaos">Palaos</option>
                                                                            <option value="Palestine">Palestine</option>
                                                                            <option value="Panama">Panama</option>
                                                                            <option value="Papouasie-Nouvelle-Guinée">Papouasie-Nouvelle-Guinée</option>
                                                                            <option value="Paraguay">Paraguay</option>
                                                                            <option value="Pays-Bas">Pays-Bas</option>
                                                                            <option value="Pérou">Pérou</option>
                                                                            <option value="Philippines">Philippines</option>
                                                                            <option value="Pologne">Pologne</option>
                                                                            <option value="Portugal">Portugal</option>
                                                                            <option value="Qatar">Qatar</option>
                                                                            <option value="République centrafricaine">République centrafricaine</option>
                                                                            <option value="République démocratique du Congo">République démocratique du Congo</option>
                                                                            <option value="République Dominicaine">République Dominicaine</option>
                                                                            <option value="République du Congo">République du Congo</option>
                                                                            <option value="République tchèque">République tchèque</option>
                                                                            <option value="Roumanie">Roumanie</option>
                                                                            <option value="Royaume-Uni">Royaume-Uni</option>
                                                                            <option value="Russie">Russie</option>
                                                                            <option value="Rwanda">Rwanda</option>
                                                                            <option value="Saint-Kitts-et-Nevis">Saint-Kitts-et-Nevis</option>
                                                                            <option value="Saint-Vincent-et-les-Grenadines">Saint-Vincent-et-les-Grenadines</option>
                                                                            <option value="Sainte-Lucie">Sainte-Lucie</option>
                                                                            <option value="Saint-Marin">Saint-Marin</option>
                                                                            <option value="Salomon">Salomon</option>
                                                                            <option value="Salvador">Salvador</option>
                                                                            <option value="Samoa">Samoa</option>
                                                                            <option value="São Tomé-et-Principe">São Tomé-et-Principe</option>
                                                                            <option value="Sénégal">Sénégal</option>
                                                                            <option value="Serbie">Serbie</option>
                                                                            <option value="Seychelles">Seychelles</option>
                                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                                            <option value="Singapour">Singapour</option>
                                                                            <option value="Slovaquie">Slovaquie</option>
                                                                            <option value="Slovénie">Slovénie</option>
                                                                            <option value="Somalie">Somalie</option>
                                                                            <option value="Soudan">Soudan</option>
                                                                            <option value="Soudan du Sud">Soudan du Sud</option>
                                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                                            <option value="Suède">Suède</option>
                                                                            <option value="Suisse">Suisse</option>
                                                                            <option value="Suriname">Suriname</option>
                                                                            <option value="Syrie">Syrie</option>
                                                                            <option value="Tadjikistan">Tadjikistan</option>
                                                                            <option value="Tanzanie">Tanzanie</option>
                                                                            <option value="Tchad">Tchad</option>
                                                                            <option value="Thaïlande">Thaïlande</option>
                                                                            <option value="Timor oriental">Timor oriental</option>
                                                                            <option value="Togo">Togo</option>
                                                                            <option value="Tonga">Tonga</option>
                                                                            <option value="Trinité-et-Tobago">Trinité-et-Tobago</option>
                                                                            <option value="Tunisie">Tunisie</option>
                                                                            <option value="Turkménistan">Turkménistan</option>
                                                                            <option value="Turquie">Turquie</option>
                                                                            <option value="Tuvalu">Tuvalu</option>
                                                                            <option value="Ukraine">Ukraine</option>
                                                                            <option value="Uruguay">Uruguay</option>
                                                                            <option value="Vanuatu">Vanuatu</option>
                                                                            <option value="Vatican">Vatican</option>
                                                                            <option value="Venezuela">Venezuela</option>
                                                                            <option value="Viêt Nam">Viêt Nam</option>
                                                                            <option value="Yémen">Yémen</option>
                                                                            <option value="Zambie">Zambie</option>
                                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                                        </Input>
                                                                {validation.errors.pays && validation.touched.pays ? (
                                                                    <FormFeedback type="invalid">{validation.errors.pays}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                    <Label htmlFor='langue' className='form-label'>{props.t("Langue")}</Label>
                                                                    <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="langue" value={validation.values.langue} onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={validation.touched.langue && validation.errors.langue ? true : false}
                                                                    >
                                                                                    <option value="">--- Langue ---</option>
                                                                                    <option value="Eng">Anglais</option>
                                                                                    <option value="Fr">Français</option>
                                                                    </Input>
                                                                    {validation.errors.langue && validation.touched.langue ? (
                                                                        <FormFeedback type="invalid">{validation.errors.langue}</FormFeedback>
                                                                    ) : null}
                                                            </div>
                                                        </Col>
                                                    
                                                        <Col lg={12}>
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <button type="button" className="btn btn-light" onClick={() => { setModal(false); }}>{props.t("Fermer")}</button>
                                                                <button type="submit" className="btn btn-success">{props.t("Ajouter utilisateur")}</button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </ModalBody>
                                            </Form>
                                        </Modal>
                                    </div>
                                </div>

                                <Offcanvas
                                    isOpen={isOpen}
                                    direction="end"
                                    toggle={() => setIsOpen(!isOpen)}
                                    className="offcanvas-end border-0"
                                    tabIndex="-1"
                                >
                                    <OffcanvasBody className="profile-offcanvas p-0">
                                        <div className="team-cover">
                                            <img src={sideBar.backgroundImg || smallImage9} alt="" className="img-fluid" />
                                        </div>
                                        <div className="p-3">
                                            <div className="team-settings">
                                                <Row>
                                                    <Col>
                                                        <div className="bookmark-icon flex-shrink-0 me-2">
                                                            <Input type="checkbox" id="favourite13" className="bookmark-input bookmark-hide" />
                                                            <Label htmlFor="favourite13" className="btn-star">
                                                                <svg width="20" height="20">
                                                                    {/* <use xlink:href="#icon-star"/> */}
                                                                </svg>
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                    {/* <UncontrolledDropdown direction='start' className="col text-end">
                                                        <DropdownToggle tag="a" id="dropdownMenuLink14" role="button">
                                                            <i className="ri-more-fill fs-17"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem><i className="ri-eye-line me-2 align-middle" />{props.t("Voir")}</DropdownItem>
                                                            <DropdownItem><i className="ri-star-line me-2 align-middle" />Favorites</DropdownItem>
                                                            <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle" />Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown> */}
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="p-3 text-center">
                                            {sideBar.photo != null ?
                                                <img src={`https://backend.crm.inawo.pro` + sideBar.photo} alt="" className="avatar-lg img-thumbnail rounded-circle mx-auto" />
                                                :
                                                <img src={userdummy} alt="" className="avatar-lg img-thumbnail rounded-circle mx-auto" />
                                            }
                                            <div className="mt-3">
                                                <h5 className="fs-15"><Link to="#" className="link-primary">{sideBar.first_name || props.t("Non renseignée")} {sideBar.last_name || props.t("Non renseignée")}</Link></h5>
                                                <p className="text-muted">{sideBar.fonction || props.t("Non renseignée")}</p>
                                            </div>
                                            {/* <div className="hstack gap-2 justify-content-center mt-4">
                                                <div className="avatar-xs">
                                                    <Link to="#" className="avatar-title bg-soft-secondary text-secondary rounded fs-16">
                                                        <i className="ri-facebook-fill"></i>
                                                    </Link>
                                                </div>
                                                <div className="avatar-xs">
                                                    <Link to="#" className="avatar-title bg-soft-success text-success rounded fs-16">
                                                        <i className="ri-slack-fill"></i>
                                                    </Link>
                                                </div>
                                                <div className="avatar-xs">
                                                    <Link to="#" className="avatar-title bg-soft-info text-info rounded fs-16">
                                                        <i className="ri-linkedin-fill"></i>
                                                    </Link>
                                                </div>
                                                <div className="avatar-xs">
                                                    <Link to="#" className="avatar-title bg-soft-danger text-danger rounded fs-16">
                                                        <i className="ri-dribbble-fill"></i>
                                                    </Link>
                                                </div>
                                            </div> */}
                                        </div>

                                        <div className="p-3">
                                            {/* <h5 className="fs-15 mb-3">Détails Personel </h5> */}
                                            <Row className="g-0 text-center">
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Nom")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.last_name}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Prénom(s)")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.first_name}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="g-0 text-center">
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Pays")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.pays}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Ville")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.ville}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="g-0 text-center">
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Email")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.email}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Téléphone")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.telephone}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="g-0 text-center">
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Adresse")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.adresse}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="p-3 border border-dashed border-start-0">
                                                        <h5 className="mb-1">{props.t("Fonction")}</h5>
                                                        <p className="text-muted mb-0">{sideBar.fonction}</p>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>
                                    </OffcanvasBody>
                                </Offcanvas>
                            </div>
                        </Col>
                    </Row>

                    <svg className="bookmark-hide">
                        <symbol viewBox="0 0 24 24" stroke="currentColor" id="icon-star"><path strokeWidth=".4" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></symbol>
                    </svg>

                </Container>
            </div>
        </React.Fragment>
    );
};

AdminsDemos.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(AdminsDemos));