import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';

import {
    GET_FACTURES,
    UPDATE_FACTURE,
    DELETE_FACTURE,
    ADD_FACTURE,
} from './actionType';

import {
    factureApiResponseSuccess,
    factureApiResponseError,
    addNewFactureSuccess,
    addNewFactureFail,
    updateFactureSuccess,
    updateFactureFail,
    deleteFactureSuccess,
    deleteFactureFail,
} from './action';

import {
    getFactures as getFactureApi,
    addNewFacture,
    updateFacture,
    deleteFacture,
} from "../../helpers/fakebackend_helper";

function* getFactures() {
    try {
        const response = yield call(getFactureApi);
        yield put(factureApiResponseSuccess(GET_FACTURES,response));
    } catch(error){
        yield put(factureApiResponseError(GET_FACTURES,error));
    }
}

function* onaddNewFacture({payload:facture}) {
    try {
        const response = yield call(addNewFacture, facture);
        yield put(addNewFactureSuccess(response));
        toast.success('Facture ajoutée avec succès', { autoclose: 3000 });
    } catch(error){
        yield put(addNewFactureFail(error));
        toast.error('Erreur lors de l\'ajout du facture', { autoclose: 3000 });
    }
}

function* onUpdateFacture({payload:facture}) {
    try {
        const response = yield call(updateFacture, facture);
        yield put(updateFactureSuccess(response));
        toast.success('Facture modifiée avec succès', { autoclose: 3000 });
    } catch(error){
        yield put(updateFactureFail(error));
        toast.error('Erreur lors de la modification du facture', { autoclose: 3000 });
    }
}

function* onDeleteFacture({payload:facture}) {
    try {
        const response = yield call(deleteFacture, facture);
        yield put(deleteFactureSuccess({facture, ...response}));
        toast.success('Facture supprimée avec succès', { autoclose: 3000 });
    } catch(error){
        yield put(deleteFactureFail(error));
        toast.error('Erreur lors de la suppression du facture', { autoclose: 3000 });
    }
}

export function* watchGetFactures() {
    yield takeEvery(GET_FACTURES, getFactures);
}

export function* watchAddNewFacture() {
    yield takeEvery(ADD_FACTURE, onaddNewFacture);
}

export function* watchUpdateFacture() {
    yield takeEvery(UPDATE_FACTURE, onUpdateFacture);
}

export function* watchDeleteFacture() {
    yield takeEvery(DELETE_FACTURE, onDeleteFacture);
}

function* factureSaga() {
    yield all([
        fork(watchGetFactures),
        fork(watchAddNewFacture),
        fork(watchUpdateFacture),
        fork(watchDeleteFacture),
    ]);
}

export default factureSaga;