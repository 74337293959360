import {
  CREATE_PASSWORD,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_ERROR,
} from "./actionTypes";

const initialState = {
  createSuccessMsg: null,
  createError: null,
};

function createPassword(state = initialState, action) {
  switch (action.type) {
    case CREATE_PASSWORD:
      state = {
        ...state,
        createSuccessMsg: null,
        createError: null,
      };
      break;
    case CREATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        createSuccessMsg: action.payload,
      };
      break;
    case CREATE_PASSWORD_ERROR:
      state = { ...state, createError: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default createPassword;
