import {
    
    GET_FACTURES,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

    ADD_FACTURE,
    ADD_FACTURE_SUCCESS,
    ADD_FACTURE_FAIL,

    UPDATE_FACTURE,
    UPDATE_FACTURE_SUCCESS,
    UPDATE_FACTURE_FAIL,

    DELETE_FACTURE,
    DELETE_FACTURE_SUCCESS,
    DELETE_FACTURE_FAIL

} from './actionType'

const INIT_STATE = {
    factures: [],
}

const Facture = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_FACTURES:
                    return {
                        ...state,
                        factures: action.payload.response,
                    };
                case ADD_FACTURE:
                    return {
                        ...state,
                        factures: [...state.factures, action.payload.response],
                    };
                case DELETE_FACTURE:
                    return {
                        ...state,
                        factures: state.factures.filter(
                            facture => (facture.id + '') !== (action.payload.response.id + '')
                            ),
                    };
                    case UPDATE_FACTURE:
                        return {
                            ...state,
                            factures: state.factures.map(
                                facture => (facture.id + '') === (action.payload.response.id + '')
                                    ? action.payload.response
                                    : facture
                            ),
                        };
                default: 
                return { ...state };    
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_FACTURES:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                case DELETE_FACTURE:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                default: 
                return { ...state };    
            }
        case GET_FACTURES:
            return {
                ...state,
            }
        case ADD_FACTURE:
            return {
                ...state,
                facture: [...state.factures, action.payload],
            }
        case ADD_FACTURE_SUCCESS:
                return {
                    ...state,
                    facture: [...state.factures, action.payload.response],
            }
        case ADD_FACTURE_FAIL:
            return {
                ...state,
                error: action.payload.error,
            }
        case UPDATE_FACTURE_SUCCESS:
            return {
                ...state,
                facture: state.factures.map(facture => 
                    facture.id.toString() === action.payload.id.toString()
                        ? {...facture, ...action.payload.data}
                        : facture
                ),
            }
        case UPDATE_FACTURE_FAIL:
            return {
                ...state,
                error: action.payload.error,
            }
        case DELETE_FACTURE_SUCCESS:
            return {
                ...state,
                facture: state.factures.filter(
                    facture => facture.id.toString() !== action.payload.facture.toString()),
            }
        case DELETE_FACTURE_FAIL:
            return {
                ...state,
                error: action.payload.error,
            }
        default:
            return { ...state };
    }
}

export default Facture;