import {
  COUNTRY_API_KEY,
  COUNTRY_BASE_URL,
  COUNTRYENDPOINT,
} from "../constants/country";
import axios from "axios";
import { useEffect, useState } from "react";

const useFetchAllStates = (ciso) => {
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGetStateByCountry = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${COUNTRY_BASE_URL}${COUNTRYENDPOINT.COUNTRIES}/${ciso}${COUNTRYENDPOINT.STATES_BY_COUNTRY}`,
        {
          headers: {
            "X-CSCAPI-KEY": COUNTRY_API_KEY,
          },
        }
      );
      setIsLoading(false);
      setStates(response);
      // if (response.status === 200) {
      // } else {
      //   setIsLoading(false);
      //   setError("Failed to fetch states");
      // }
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    handleGetStateByCountry();
  }, [ciso]);

  return { states, isLoading, error };
};

export default useFetchAllStates;
