export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_QUARTIERS = "GET_QUARTIERS";

// Add QUARTIER
export const ADD_NEW_QUARTIER = "ADD_NEW_QUARTIER";
export const ADD_QUARTIER_SUCCESS = "ADD_QUARTIER_SUCCESS";
export const ADD_QUARTIER_FAIL = "ADD_QUARTIER_FAIL";

// Update QUARTIER
export const UPDATE_QUARTIER = "UPDATE_QUARTIER";
export const UPDATE_QUARTIER_SUCCESS = "UPDATE_QUARTIER_SUCCESS";
export const UPDATE_QUARTIER_FAIL = "UPDATE_QUARTIER_FAIL";

// Delete QUARTIER
export const DELETE_QUARTIER = "DELETE_QUARTIER";
export const DELETE_QUARTIER_SUCCESS = "DELETE_QUARTIER_SUCCESS";
export const DELETE_QUARTIER_FAIL = "DELETE_QUARTIER_FAIL";

export const GET_QUARTIER_BY_ID = "GET_QUARTIER_BY_ID";
export const GET_QUARTIER_BY_ID_SUCCESS = "GET_QUARTIER_BY_ID_SUCCESS";
export const GET_QUARTIER_BY_ID_FAIL = "GET_QUARTIER_BY_ID_FAIL";
