import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback } from 'reactstrap';
import ParticlesAuth from '../ParticlesAuth';
// import logoInawoCRMBlanc2 from "../../assets/images/logoInawoCRMBlanc2.png";
import logoInawoCRMBlanc2 from "../../../assets/images/logo-InawoCRM-Blanc-2.png";
//redux
import { useDispatch } from "react-redux";

import { userCreatePassword, apiError } from "../../../store/actions";
import Cookies from 'js-cookie';
//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const BasicPasswCreate = ({match}) => {
    const lang = localStorage.getItem('langreset');
    lang === "en" ? document.title="Reset Password | Le Conseiller du BTP" : document.title="Réinitialiser mot de passe | Le Conseiller du BTP";
    const history = useHistory();
    const dispatch = useDispatch();
    // const { token, uid } = match.params;
    const uid = match.params.uid;
    const token = match.params.token;
    // const [new_password, setNewPassword] = useState("");
    // const [isTokenValid, setIsTokenValid] = useState(false);
    // const tok = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
     
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);


    // useEffect(() => {
    //     const checkTokenValidity = async () => {
    //       try {
    //         const response = await axios.post("https://back.crm.inawo.pro/api/v1/users/reset_password/", {
    //           token,
    //           uid
    //         });
    // console.log(response)
    //         if (response.data.valid) {
    //           setIsTokenValid(true);
    //         } else {
    //         toast.error('Invalid token or UID !')
    //         }
    //       } catch (error) {
    //         toast.error("An error occurred while verifying the token.");
    //       } 
    //     };
    
    //     checkTokenValidity();
    //   }, [token, uid]);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            uid: uid,
            token: token,
            new_password: "",
            confirm_password: "",
        },
        validationSchema: Yup.object({
            new_password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
                .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required("Ce champ est requis"),
            confirm_password: Yup.string()
                .when("new_password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("new_password")],
                        "Les deux mots de passe doivent être les mêmes"
                    ),
                })
                .required("Confirmation du mot de passe requise"),
        }),
        onSubmit: async (values) => {
            const user = {
                uid: values.uid,
                token: values.token,
                new_password: values.new_password,
            }
            try {
                await axios.post("https://back.crm.inawo.pro/api/v1/users/reset_password_confirm/", {
                    uid,
                    token,
                    new_password: values.new_password,
                });
                // dispatch(userCreatePassword(user));
                if (lang === "en") {
                    toast.success('Password reset successfully !')
                    setTimeout(() => history.push("/en/login"), 3000);
                } else {
                toast.success('Mot de passe réinitialisé avec succès !')
                setTimeout(() => history.push("/fr/connexion"), 3000);
                }
            } catch (error) {
                if (lang === "en") {
                    toast.error("An error occurred while resetting the password.");
                    setTimeout(() => history.push("/en/login"), 3000)
                } else {
                    toast.error("Une erreur s'est produite lors de la réinitialisation du mot de passe.");
                    setTimeout(() => history.push("/fr/connexion"), 3000)
                }
            }            
        }
    });

    // useEffect(() => {
    //     dispatch(apiError(""));
    // }, [dispatch]);

    return (
        <ParticlesAuth>
            <ToastContainer closeButton={false} />
            <div className="auth-page-content">
                {
                    lang === "en" ?
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/#" className="d-inline-block auth-logo">
                                                <img src={logoInawoCRMBlanc2} width="30%" alt=""  />
                                            </Link>
                                        </div>
                                        <p className="mt-3 fs-15 fw-medium">Growing with your customers</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">

                                        <CardBody className="p-4">
                                            <div className="text-center mt-2">
                                                <h5 className="text-primary">Create a new password</h5>
                                                <p className="text-muted">Your new password must be different from your previous password.</p>
                                            </div>

                                            <div className="p-2">
                                                <Form onSubmit={validation.handleSubmit} action="#">
                                                    <div className="mb-3">
                                                        <div className="position-relative auth-pass-inputgroup">
                                                            <Input
                                                                type="hidden"
                                                                className="form-control pe-5 uid-input"
                                                                id="uid-input"
                                                                name="uid"
                                                                value={validation.values.uid}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.uid && validation.touched.uid ? true : false}
                                                            />
                                                            {validation.errors.uid && validation.touched.uid ? (
                                                                <FormFeedback type="invalid">{validation.errors.uid}</FormFeedback>
                                                            ) : null}
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="position-relative auth-pass-inputgroup">
                                                            <Input
                                                                type="hidden"
                                                                className="form-control pe-5 token-input"
                                                                id="token-input"
                                                                name="token"
                                                                value={validation.values.token}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.token && validation.touched.token ? true : false}
                                                            />
                                                            {validation.errors.token && validation.touched.token ? (
                                                                <FormFeedback type="invalid">{validation.errors.token}</FormFeedback>
                                                            ) : null}
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="new_password-input">New password</Label>
                                                        <div className="position-relative auth-pass-inputgroup">
                                                            <Input
                                                                type={passwordShow ? "text" : "new_password"}
                                                                className="form-control pe-5 new_password-input"
                                                                placeholder="Entrer mot de passe"
                                                                id="new_password-input"
                                                                name="new_password"
                                                                value={validation.values.new_password}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.new_password && validation.touched.new_password ? true : false}
                                                            />
                                                            {validation.errors.new_password && validation.touched.new_password ? (
                                                                <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                                                            ) : null}
                                                            <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                        </div>
                                                        <div id="passwordInput" className="form-text">Must be at least 8 characters long.</div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="confirm-password-input">Confirm password</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Input
                                                                type={confirmPasswordShow ? "text" : "password"}
                                                                className="form-control pe-5 password-input"
                                                                placeholder="Confirmer mot de pass"
                                                                id="confirm-password-input"
                                                                name="confirm_password"
                                                                value={validation.values.confirm_password}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.confirm_password && validation.touched.confirm_password ? true : false}
                                                            />
                                                            {validation.errors.confirm_password && validation.touched.confirm_password ? (
                                                                <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                                                            ) : null}
                                                            <Button color="link" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button">
                                                                <i className="ri-eye-fill align-middle"></i></Button>
                                                        </div>
                                                    </div>

                                                    <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                        <h5 className="fs-13">The password must contain:</h5>
                                                        <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 characters</b></p>
                                                        <p id="pass-lower" className="invalid fs-12 mb-2">A <b>lowercase</b> letter (a-z)</p>
                                                        <p id="pass-upper" className="invalid fs-12 mb-2">At least <b>one lower-case</b> letter (A-Z)</p>
                                                        <p id="pass-number" className="invalid fs-12 mb-0">At least one<b>number</b> (0-9)</p>
                                                    </div>

                                                    <div className="mt-4">
                                                        <Button color="success" className="w-100" type="submit">Reset password</Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-4 text-center">
                                        <p className="mb-0">Wait, I remember my password... <Link to="/en/login" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    :
                    <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/#" className="d-inline-block auth-logo">
                                        <img src={logoInawoCRMBlanc2} width="30%" alt=""  />
                                    </Link>
                                </div>
                                <p className="mt-3 fs-15 fw-medium">Grandir avec vos clients</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">

                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Créer un nouveau mot de passe</h5>
                                        <p className="text-muted">Votre nouveau mot de passe doit être différent du mot de passe utilisé précédemment.</p>
                                    </div>

                                    <div className="p-2">
                                        <Form onSubmit={validation.handleSubmit} action="#">
                                            <div className="mb-3">
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type="hidden"
                                                        className="form-control pe-5 uid-input"
                                                        id="uid-input"
                                                        name="uid"
                                                        value={validation.values.uid}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.uid && validation.touched.uid ? true : false}
                                                    />
                                                    {validation.errors.uid && validation.touched.uid ? (
                                                        <FormFeedback type="invalid">{validation.errors.uid}</FormFeedback>
                                                    ) : null}
                                                    
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type="hidden"
                                                        className="form-control pe-5 token-input"
                                                        id="token-input"
                                                        name="token"
                                                        value={validation.values.token}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.token && validation.touched.token ? true : false}
                                                    />
                                                    {validation.errors.token && validation.touched.token ? (
                                                        <FormFeedback type="invalid">{validation.errors.token}</FormFeedback>
                                                    ) : null}
                                                    
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="new_password-input">Nouveau mot de passe</Label>
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type={passwordShow ? "text" : "new_password"}
                                                        className="form-control pe-5 new_password-input"
                                                        placeholder="Entrer mot de passe"
                                                        id="new_password-input"
                                                        name="new_password"
                                                        value={validation.values.new_password}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.new_password && validation.touched.new_password ? true : false}
                                                    />
                                                    {validation.errors.new_password && validation.touched.new_password ? (
                                                        <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                        id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                                <div id="passwordInput" className="form-text">Doit comporter au moins 8 caractères.</div>
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm-password-input">Confirmer mot de passe</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        type={confirmPasswordShow ? "text" : "password"}
                                                        className="form-control pe-5 password-input"
                                                        placeholder="Confirmer mot de pass"
                                                        id="confirm-password-input"
                                                        name="confirm_password"
                                                        value={validation.values.confirm_password}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.confirm_password && validation.touched.confirm_password ? true : false}
                                                    />
                                                    {validation.errors.confirm_password && validation.touched.confirm_password ? (
                                                        <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button">
                                                        <i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                            </div>

                                            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                <h5 className="fs-13">Le mot de passe doit contenir:</h5>
                                                <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 caractères</b></p>
                                                <p id="pass-lower" className="invalid fs-12 mb-2">Une lettre <b>minuscule</b> (a-z)</p>
                                                <p id="pass-upper" className="invalid fs-12 mb-2">Au moins une lettre <b>majuscule</b> (A-Z)</p>
                                                <p id="pass-number" className="invalid fs-12 mb-0">Au moins un <b>nombre</b> (0-9)</p>
                                            </div>

                                            <div className="mt-4">
                                                <Button color="success" className="w-100" type="submit">Réinitialiser mot de passe</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                <p className="mb-0">Attendez, je me souviens de mon mot de passe... <Link to="/fr/connexion" className="fw-semibold text-primary text-decoration-underline"> Cliquer ici </Link> </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                }
            </div>
        </ParticlesAuth>
    );
};

export default BasicPasswCreate;