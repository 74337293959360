import {
  CREATE_PASSWORD,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_ERROR,
} from "./actionTypes";

export const userCreatePassword = (user, history) => {
  return {
    type: CREATE_PASSWORD,
    payload: { user, history },
  };
};

export const userCreatePasswordSuccess = message => {
  return {
    type: CREATE_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userCreatePasswordError = message => {
  return {
    type: CREATE_PASSWORD_ERROR,
    payload: message,
  };
};
