import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import classnames from "classnames";
import {
    openKkiapayWidget,
    addKkiapayListener,
    removeKkiapayListener,
} from "kkiapay";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { axiosIns } from '../utils';
import { pricing1, pricing2, pricing3 } from '../../common/data';
import { prop } from 'dom7';
import Cookies from 'js-cookie';

const Pricing = (props) => {
    const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;

const [abonnement, setAbonnement] = useState([]);

  useEffect(() => {
    const fetchAbonnement = async () => {
      try {
        const { data } = await axiosIns.get('/subscriptions',
        {
            headers: {
              Authorization : 'Bearer ' + token,
            },
          }
        )
        setAbonnement(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchAbonnement();
  }, []);
  console.log("subscriptions", abonnement);

  const [typeabonnement, setTypeAbonnement] = useState([]);

  useEffect(() => {
    const fetchTypeAbonnement = async () => {
      try {
        const { data } = await axiosIns.get('/subscription-types', {
            headers: {
              Authorization : 'Bearer ' + token,
            },
          })
        setTypeAbonnement(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchTypeAbonnement();
  }, []);
  console.log("subscription-types", typeabonnement);

  const [aboprice, setAboPrice] = useState([]);
  useEffect(() => {
    const fetchAboPrice = async () => {
      try {
        const { data } = await axiosIns.get('/pricing', {
            headers: {
              Authorization : 'Bearer ' + token,
            },
          })
        setAboPrice(data.filter((item) => item.subscription !== 4));
      } catch (err){
        console.log(err);
      }
    }
    fetchAboPrice();
  }, []);
  console.log("pricing", aboprice);

  const [dollar, setDollar] = useState("");

  useEffect(() => {
    async function getExchangeRate() {
        try {
          const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
          const data = await response.json();
          const exchangeRate = data.rates.XOF; // Récupère le taux de change pour le franc CFA
          setDollar(exchangeRate);
          console.log(`La valeur de 1 dollar américain (USD) est de ${exchangeRate} francs CFA (XOF).`);
        } catch (error) {
          console.log('Une erreur s\'est produite lors de la récupération du taux de change.', error);
        }
      }
      
      getExchangeRate();
  })


  const AbonnementName = (j)=> {
    for(let i = 0;i<=abonnement.length; i++){
      if( j === abonnement[i]?.id){
       return (abonnement[i]?.designation) ;
      }
    }
  }

  const AbonnementType = (j)=> {
    for(let i = 0;i<=typeabonnement.length; i++){
      if( j === typeabonnement[i]?.id){
       return (typeabonnement[i]?.designation) ;
      }
    }
  }

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [selectabo, setSelectAbo] = useState([]);
  const [codepromo, setCodePromo] = useState([]);

  const toggle = useCallback((e) => {
    if (modal) {
      setModal(false);
    } else {
        setModal(true);
        setSelectAbo(e)
    }
  }, [modal]);

    const toggle2 = useCallback((e) => {
        if (modal2) {
            setModal2(false);
        } else {
            setModal2(true);
        }
    }, [modal2]);

  const [selectedPrice, setSelectedPrice] = useState();

  const open = (j) => {
    const price = aboprice;
    for (let i = 0; i <= price.length; i++) {
        if (j === price[0]?.id) {
            if (selectedPrice === price[0]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[0]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[0]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[0]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[0]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[0]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else  if (selectedPrice === price[0]?.tarif_annuel){
                Cookies.set('selectedPrice', price[0]?.tarif_annuel);
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[0]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[0]?.tarif_biannuel && selectedPrice === price[0]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[0]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[0]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else if (j === price[1]?.id) {
            if (selectedPrice === price[1]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[1]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[1]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[1]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[1]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[1]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[1]?.tarif_annuel){
                Cookies.set('selectedPrice', price[1]?.tarif_annuel)
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[1]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[1]?.tarif_biannuel && selectedPrice === price[1]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[1]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[1]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else if (j === price[2]?.id) {
            if (selectedPrice === price[2]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[2]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[2]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[2]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[2]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[2]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[2]?.tarif_annuel){
                Cookies.set('selectedPrice', price[2]?.tarif_annuel)
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[2]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[2]?.tarif_biannuel && selectedPrice === price[2]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[2]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[2]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else if (j === price[3]?.id) {
            if (selectedPrice === price[3]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[3]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[3]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[3]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[3]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[3]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[3]?.tarif_annuel){
                Cookies.set('selectedPrice', price[3]?.tarif_annuel)
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[3]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[3]?.tarif_biannuel && selectedPrice === price[3]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[3]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[3]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else if (j === price[4]?.id) {
            if (selectedPrice === price[4]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[4]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[4]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[4]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[4]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[4]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[4]?.tarif_annuel){
                Cookies.set('selectedPrice', price[4]?.tarif_annuel)
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[4]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[4]?.tarif_biannuel && selectedPrice === price[4]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[4]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[4]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else if (j === price[5]?.id) {
            if (selectedPrice === price[5]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[5]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[5]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[5]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[5]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[5]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[5]?.tarif_annuel){
                Cookies.set('selectedPrice', price[5]?.tarif_annuel)
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[5]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[5]?.tarif_biannuel && selectedPrice === price[5]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[5]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[5]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else if (j === price[6]?.id) {
            if (selectedPrice === price[6]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[6]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[6]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[6]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[6]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[6]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[6]?.tarif_annuel){
                Cookies.set('selectedPrice', price[6]?.tarif_annuel)
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[6]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[6]?.tarif_biannuel && selectedPrice === price[6]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[6]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[6]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else if (j === price[7]?.id) {
            if (selectedPrice === price[7]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[7]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[7]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[7]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[7]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[7]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[7]?.tarif_annuel){
                Cookies.set('selectedPrice', price[7]?.tarif_annuel)
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[7]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[7]?.tarif_biannuel && selectedPrice === price[7]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[7]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[7]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else if (j === price[8]?.id) {
            if (selectedPrice === price[8]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[8]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[8]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[8]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[8]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[8]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[8]?.tarif_annuel){
                Cookies.set('selectedPrice', price[8]?.tarif_annuel)
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[8]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[8]?.tarif_biannuel && selectedPrice === price[8]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[8]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[8]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else if (j === price[9]?.id) {
            if (selectedPrice === price[9]?.tarif_mensuel * 3){
                Cookies.set('selectedPrice', price[9]?.tarif_mensuel * 3)
                Cookies.set('nbr_month', 3);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[9]?.tarif_mensuel * 3,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[9]?.tarif_mensuel * 6){
                Cookies.set('selectedPrice', price[9]?.tarif_mensuel * 6)
                Cookies.set('nbr_month', 6);
                Cookies.set('nbr_year', 0);
                return openKkiapayWidget({
                    amount: price[9]?.tarif_mensuel * 6,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (selectedPrice === price[9]?.tarif_annuel){
                Cookies.set('selectedPrice', price[9]?.tarif_annuel)
                Cookies.set('nbr_year', 1);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[9]?.tarif_annuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            } else if (price[9]?.tarif_biannuel && selectedPrice === price[9]?.tarif_biannuel) {
                Cookies.set('selectedPrice', price[9]?.tarif_biannuel)
                Cookies.set('nbr_year', 2);
                Cookies.set('nbr_month', 0);
                return openKkiapayWidget({
                    amount: price[9]?.tarif_biannuel,
                    api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                    sandbox: false,
                    email: "",
                    callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                    //  phone: "61754061",
                })
            }
        } else {
          return (
              openKkiapayWidget({
                  amount: 0,
                  api_key: "dc29faa2263698a777f4df04b9e27276c08b6bdd",
                  sandbox: false,
                  email: "",
                  callback: "https://app.inawo.pro/apps-crm-abonnement" + "/" + j + "/"
                  //  phone: "61754061",
              })
          )
      }
    }
}

    function successHandler(response) {
        console.log(response);
    }

    useEffect(() => {
        addKkiapayListener('success', successHandler)
        return () => {
            removeKkiapayListener('success', successHandler)
        };
    }, []);


    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    document.title = props.t("Abonnement | Le Conseiller du BTP");

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={props.t("Abonnement")} pageTitle="Pages" />
                    <Row className="justify-content-center mt-5">
                        <Col lg={5}>
                            <div className="text-center mb-4 pb-2">
                                <h4 className="fw-semibold fs-22">{props.t("Choisissez le plan qui vous convient le mieux")}</h4>
                                <p className="text-muted mb-4 fs-15">{props.t("Tarification simple. Pas de frais cachés. Des fonctionnalités avancées pour votre entreprise.")}</p>
                            </div>
                        </Col>
                    </Row>


                    <Row className="justify-content-center">

                        <Col xl={10}>
                            <Row>
                                {
                                    (aboprice || []).map((price, key) => (
                                        <Col lg={4} key={key}>
                                            <Card className="pricing-box ribbon-box">
                                                {price.subscription_type === 2 ? <div className="ribbon-two ribbon-two-danger"><span>{props.t("Populaire")}</span></div> : ""}
                                                <CardBody className="p-4 m-2">
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-grow-1">
                                                            <h5 className="mb-1 fw-semibold">{props.t(AbonnementName(price.subscription))}</h5>
                                                            <p className="text-muted mb-0">{props.t(AbonnementType(price.subscription_type))}</p>
                                                        </div>
                                                        <div className="avatar-sm">
                                                            <div className="avatar-title bg-light rounded-circle text-primary">
                                                                {
                                                                    price.subscription === 1 ?
                                                                        <i className="ri-book-mark-line fs-18"></i>
                                                                    : price.subscription === 2 ?
                                                                            <i className="ri-medal-line fs-18"></i>
                                                                            :
                                                                            <i className="ri-stack-line fs-18"></i>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt-4">
                                                        <h5>
                                                        <sup><small>$</small></sup>{dollar && parseInt(price.tarif_mensuel / dollar)} | <sup><small>FCFA </small></sup>{price.tarif_mensuel}<span className="fs-13 text-muted">{props.t("/Mois")}</span>
                                                        </h5>
                                                        <h5>
                                                        <sup><small>$</small></sup>{dollar && parseInt(price.tarif_annuel / dollar)} | <sup><small>FCFA </small></sup>{price.tarif_annuel}<span className="fs-13 text-muted">{props.t("/An")}</span>
                                                        </h5>
                                                            {
                                                            price.tarif_biannuel > 0 ?
                                                                <h5>
                                                                    <sup><small>$</small></sup>{dollar && parseInt(price.tarif_biannuel / dollar)} | <sup><small>FCFA </small></sup>{price.tarif_biannuel}<span className="fs-13 text-muted">{props.t("/2 Ans")}</span>
                                                                </h5>
                                                            : false
                                                            } 
                                                    </div>
                                                    <hr className="my-4 text-muted" />
                                                    <div>
                                                        <ul className="list-unstyled text-muted vstack gap-3">
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                    <div className="flex-grow-1">
                                                                        {props.t("Jusqu'à")} <b>{price.nb_user}</b> {props.t("Utilisateurs")}
                                                                    </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                    {
                                                                        price.nb_contact === "illimité" ?
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Nombre illimité de contacts")}
                                                                            </div>
                                                                            :   
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Jusqu'à")} <b>{price.nb_contact}</b> {props.t("Contacts")}
                                                                            </div>
                                                                    }
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                    {
                                                                        price.catalogue === "illimité" ?
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Nombre illimité de produits/services")}
                                                                            </div>
                                                                            :   
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Jusqu'à")} <b>{price.catalogue}</b> {props.t("Produits/Services")}
                                                                            </div>
                                                                    }
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                    {
                                                                        price.tasks === "illimité" ?
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Nombre illimité de tâches")}
                                                                            </div>
                                                                            :   
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Jusqu'à")} <b>{price.tasks}</b> {props.t("Tâches")}
                                                                            </div>
                                                                    }
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                    {
                                                                        price.events === "illimité" ?
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Nombre illimité d'évènements")}
                                                                            </div>
                                                                            :   
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Jusqu'à")} <b>{price.events}</b> {props.t("Évènements")}
                                                                            </div>
                                                                    }
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                    {
                                                                        price.facture === "illimité" ?
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Nombre illimité de factures")}
                                                                            </div>
                                                                            :   
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Jusqu'à")} <b>{price.facture}</b> {props.t("Factures")}
                                                                            </div>
                                                                    }
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0 text-success me-1">
                                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                </div>
                                                                    {
                                                                        price.docs === "illimité" ?
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Nombre illimité de facture proforma/Devis/Bon de livraison")}
                                                                            </div>
                                                                            :   
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Jusqu'à")} <b>{price.docs}</b> {props.t("facture proforma/Devis/Bon de livraison")}
                                                                            </div>
                                                                    }
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                {
                                                                    price.help_support === true ?
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div> :
                                                                        <div className="flex-shrink-0 text-danger me-1">
                                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                }
                                                                <div className="flex-grow-1">
                                                                    {props.t("Assistance et Support")}
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                {
                                                                    price.dashboard === true ?
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div> :
                                                                        <div className="flex-shrink-0 text-danger me-1">
                                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                }
                                                                <div className="flex-grow-1">
                                                                    {props.t("Tableau de bord")}
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                {
                                                                    price.relance_notification === true ?
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div> :
                                                                        <div className="flex-shrink-0 text-danger me-1">
                                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                }
                                                                <div className="flex-grow-1">
                                                                    {props.t("Relance et Notification")}
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                {
                                                                    price.ventes === true ?
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div> :
                                                                        <div className="flex-shrink-0 text-danger me-1">
                                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                }
                                                                <div className="flex-grow-1">
                                                                    {props.t("Gestion des ventes")}
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                    {
                                                                        price.pipeline_mngt !== "0" ?
                                                                            price.pipeline_mngt === "illimité" ? 
                                                                                <div className="d-flex">
                                                                                    <div className="flex-shrink-0 text-success me-1">
                                                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                                    </div> 
                                                                                    <div className="flex-grow-1">
                                                                                        {props.t("Nombre illimité de gestions d'opportunités")}
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="d-flex">
                                                                                    <div className="flex-shrink-0 text-success me-1">
                                                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                                    </div>
                                                                                    <div className="flex-grow-1">
                                                                                        {props.t("Jusqu'à")} <b>{price.pipeline_mngt}</b> {props.t("gestions d'opportunités")}
                                                                                    </div>
                                                                                </div>
                                                                        :
                                                                        <div className="d-flex">
                                                                            <div className="flex-shrink-0 text-danger me-1">
                                                                                <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                {props.t("Aucune gestion d'opportunités")}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                {
                                                                    price.facture_normalise === true ?
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div> :
                                                                        <div className="flex-shrink-0 text-danger me-1">
                                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                }
                                                                <div className="flex-grow-1">
                                                                    {props.t("Facture normalisée")}
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                {
                                                                    price.gestock === true ?
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div> :
                                                                        <div className="flex-shrink-0 text-danger me-1">
                                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                }
                                                                <div className="flex-grow-1">
                                                                    {props.t("Gestion de stocks")}
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex">
                                                                {
                                                                    price.depenses === true ?
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div> :
                                                                        <div className="flex-shrink-0 text-danger me-1">
                                                                            <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                }
                                                                <div className="flex-grow-1">
                                                                {props.t("Gestion des dépenses")}
                                                                </div>

                                                            </div>
                                                            <br />
                                                        </li>
                                                        <li title={props.t("S'abonner")} className="text-center mb-4 pb-2">
                                                            <Link to="#" className="btn btn-success" w-100 waves-effect
                                                                onClick={() => 
                                                                // open(price.id)
                                                                    {
                                                                        setModal(true)
                                                                        setSelectAbo(price.id)
                                                                }
                                                                }>
                                                                {props.t("Abonnez-vous")}
                                                            </Link>
                                                        </li>
                                                        </ul>
                                                    </div>
                                                </CardBody>
                                             </Card>    

                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                        <ModalHeader toggle={toggle}>{props.t("Formules")}</ModalHeader>
                        <ModalBody>
                                {
                                    aboprice.map((price, key) => (
                                        price.id === selectabo ?
                                            <div key={key} className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <Link className="btn btn-outline-success w-100 mb-2" onClick={() => {
                                                        setSelectedPrice(price.tarif_mensuel*3)
                                                        setModal2(true)
                                                    }}>
                                                        <p className="mb-1 fw-semibold fs-16">{props.t("Trimestriel")}</p>
                                                        <p>
                                                            <sup><small>FCFA </small></sup>{price.tarif_mensuel * 3 }<span className="fs-13 text-muted">{props.t("/Mois")}</span>
                                                        </p>
                                                    </Link>
                                                    <Link className="btn btn-outline-secondary w-100 mb-2" onClick={() => {
                                                        setSelectedPrice(price.tarif_mensuel * 6)
                                                        setModal2(true)
                                                    }}>
                                                        <p className="mb-1 fw-semibold fs-16">{props.t("Semestriel")}</p>
                                                        <p>
                                                            <sup><small>FCFA </small></sup>{price.tarif_mensuel * 6}<span className="fs-13 text-muted">{props.t("/Mois")}</span>
                                                        </p>
                                                    </Link>
                                                    <Link className="btn btn-outline-primary w-100 mb-2" onClick={() => {
                                                        setSelectedPrice(price.tarif_annuel)
                                                        setModal2(true)
                                                    }}>
                                                        <p className="mb-1 fw-semibold fs-16">{props.t("Annuel")}</p>
                                                            <p>
                                                            <sup><small>$</small></sup>{dollar && parseInt(price.tarif_annuel / dollar)} | <sup><small>FCFA </small></sup>{price.tarif_annuel}<span className="fs-13 text-muted">{props.t("/An")}</span>
                                                            </p>
                                                    </Link>
                                                            {
                                                            price.tarif_biannuel > 0 ?
                                                                <Link className="btn btn-outline-danger w-100 mb-2" onClick={() => {
                                                                    setSelectedPrice(price.tarif_biannuel)
                                                                    setModal2(true)
                                                                }}>
                                                                <p className="mb-1 fw-semibold fs-16">{props.t("BiAnnuel")}</p>
                                                                <p>
                                                                <sup><small>$</small></sup>{dollar && parseInt(price.tarif_biannuel / dollar)} | <sup><small>FCFA </small></sup>{price.tarif_biannuel}<span className="fs-13 text-muted">{props.t("/2 Ans")}</span>
                                                                </p>
                                                                </Link>
                                                            : false
                                                            } 
                                                </div>
                                            </div>
                                            :
                                            false
                                    ))
                                }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>{props.t("Annuler")}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal id="showModal" isOpen={modal2} centered>
                    <ModalHeader toggle={toggle2}>{props.t("Code Promo")}</ModalHeader>
                    <ModalBody>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <div className="mb-3">
                                    <Label className="form-label">{props.t("Code Promo")}</Label>
                                    <Input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder={props.t("Code Promo(Facultatif)")}
                                        Onchange={(e) => setCodePromo(e)}
                                        />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle2}>{props.t("Annuler")}</Button>
                        <Button color="primary" onClick={(e) => {
                            setCodePromo(e)
                            toggle()
                            toggle2()
                            aboprice.map((price, key) => (
                                price.id === selectabo ?
                                open(price.id)
                                : false
                            ))
                        }}>{props.t("Continuer")}</Button>
                    </ModalFooter>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};

Pricing.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}
export default withRouter(withTranslation()(Pricing));
