import {
  GET_TYPE_ABONNEMENTS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,

  ADD_TYPE_ABONNEMENT_SUCCESS,
  ADD_TYPE_ABONNEMENT_FAIL,
  
  UPDATE_TYPE_ABONNEMENT_SUCCESS,
  UPDATE_TYPE_ABONNEMENT_FAIL,
} from "./actionType";

const INIT_STATE = {
  typeabonnements: [],
  error: {},
};

const TypeAbonnement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TYPE_ABONNEMENTS:
          return {
            ...state,
            typeabonnements: action.payload.data,
            isTypeAbonnementCreated: false,
            isTypeAbonnementSuccess: true
          };
        
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_TYPE_ABONNEMENTS:
          return {
            ...state,
            error: action.payload.error,
            isTypeAbonnementCreated: false,
            isTypeAbonnementSuccess: false
          };
        
        default:
          return { ...state };
      }

    case ADD_TYPE_ABONNEMENT_SUCCESS:
      return {
        ...state,
        isTypeAbonnementCreated: true,
        typeabonnements: [...state.typeabonnements, action.payload.data],
        isTypeAbonnementAdd: true,
        isTypeAbonnementAddFail: false
      };

    case ADD_TYPE_ABONNEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isTypeAbonnementAdd: false,
        isTypeAbonnementAddFail: true
      };

    case UPDATE_TYPE_ABONNEMENT_SUCCESS:
      return {
        ...state,
        typeabonnements: state.typeabonnements.map(typeabonnement =>
          typeabonnement.id === action.payload.id
            ? { ...typeabonnement, ...action.payload }
            : typeabonnement
        ),
        isTypeAbonnementUpdate: true,
        isTypeAbonnementUpdateFail: false
      };

    case UPDATE_TYPE_ABONNEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isTypeAbonnementUpdate: false,
        isTypeAbonnementUpdateFail: true
      };

    default:
      return { ...state };
  }
};

export default TypeAbonnement;
