export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PAYS = "GET_PAYS";

// Add PAYS
export const ADD_NEW_PAYS = "ADD_NEW_PAYS";
export const ADD_PAYS_SUCCESS = "ADD_PAYS_SUCCESS";
export const ADD_PAYS_FAIL = "ADD_PAYS_FAIL";

// Update PAYS
export const UPDATE_PAYS = "UPDATE_PAYS";
export const UPDATE_PAYS_SUCCESS = "UPDATE_PAYS_SUCCESS";
export const UPDATE_PAYS_FAIL = "UPDATE_PAYS_FAIL";

// Delete PAYS
export const DELETE_PAYS = "DELETE_PAYS";
export const DELETE_PAYS_SUCCESS = "DELETE_PAYS_SUCCESS";
export const DELETE_PAYS_FAIL = "DELETE_PAYS_FAIL";

export const GET_PAYS_BY_ID = "GET_PAYS_BY_ID";
export const GET_PAYS_BY_ID_SUCCESS = "GET_PAYS_BY_ID_SUCCESS";
export const GET_PAYS_BY_ID_FAIL = "GET_PAYS_BY_ID_FAIL";
