import React, { useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types'
import axios from "axios";
import tiktok from "../../assets/images/tiktok.png";
import { isEmpty } from "lodash";
import { axiosIns } from '../../utils';
import {getventes as onGetVentes} from "../../store/ventes/action";
import {
  CardBody,
  Container,
  Progress,
  Row,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Badge,
} from "reactstrap";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb"; 
import Img2 from "../../assets/images/companies/img-2.png";
import ReviewSlider from "../../Components/Common/ReviewSlider";
import TableContainer from "../../Components/Common/TableContainer";
//Import actions
// import { getProducts as onGetProducts } from "../../../store/ecommerce/action";


import Revenue from "../DashboardEcommerce/Revenue";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, Redirect, useParams, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';

const SimplePageAdmin = (props) => {
  const dispatch = useDispatch();

  //Début code pour get le contact sélectionné
  const contactId = useParams('id');
  console.log("numcon",contactId.id);
  const url = process.env.REACT_APP_API_URL;
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const [selectcontact, setSelectContact] = useState({})
  const [email, setEmail] = useState('')
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`https://back.crm.inawo.pro/api/v1/administration/subscribers`, {
            headers : {
                Authorization : "Bearer " + token
        }});
          setSelectContact(data.find((item) => item.id === parseInt(contactId.id)))
          setEmail(data.find((item) => item.id === parseInt(contactId.id)).user)
          console.log(data.find((item) => item.id === parseInt(contactId.id)));
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    }, [contactId, url]);

    console.log("selectcontact",selectcontact);
    console.log("email", email.email);

document.title ="Détails Contacts | Le Conseiller du BTP";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={props.t("Détails Abonnés")} pageTitle={props.t("Abonnés")} />
          <Row>
            <div className="col-xxl-3">
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="flex-shrink-0 avatar-md mx-auto">
                      <div className="avatar-title bg-soft-success text-success rounded-circle fs-20">
                        {
                        `${selectcontact.first_name}`.charAt(0)
                        }
                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      <h5 className="mb-1">{selectcontact.first_name + " " + selectcontact.last_name || '(Non renseignée)'}</h5>
                    </div>
                    <div className="table-responsive">
                      <Table className="table mb-0 table-borderless">
                        <tbody>
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Nom")}</span>
                            </th>
                            <td>{selectcontact.first_name + " " + selectcontact.last_name || '(Non renseignée)'}</td>
                          </tr>
              
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Email")}</span>
                            </th>
                            <td>{ email.email || '(Non renseignée)'}</td>
                          </tr>
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Fonction")}</span>
                            </th>
                            <td><Badge color="danger" style={{ fontSize: "12px" }}>{selectcontact.fonction || '(Non renseignée)'}</Badge></td>
                          </tr>
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Société")}</span>
                            </th>
                            <td><Badge color="info">{selectcontact.societe || '(Non renseignée)'}</Badge></td>
                          </tr>
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Téléphone")}</span>
                            </th>
                            <td>{selectcontact.telephone || '(Non renseignée)'}</td>
                          </tr>
                        
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Adresse")}</span>
                            </th>
                            <td>{selectcontact.adresse + ", " + selectcontact.pays || '(Non renseignée)'}</td>
                          </tr>
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Langue")}</span>
                            </th>
                            <td>{selectcontact.langue === "Fr" ? props.t("Français") : props.t("Anglais") || '(Non renseignée)'}</td>
                          </tr>
                          
                          
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SimplePageAdmin.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(SimplePageAdmin));
