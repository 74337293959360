import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Container, Row, Form, Label, Input, FormFeedback } from 'reactstrap';
import AuthSlider from '../authCarousel';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import logo from "../../../assets/images/lcbtp.png";
import ParticlesAuth from '../ParticlesAuth';
import { userResetPassword } from '../../../store/actions';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

const CoverPasswCreate = () => {
    document.title = "Creation d'un nouveau mot de passe | Le conseiller du BTP";

    const [passwordShow, setPasswordShow] = useState(false);
    const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);   
    const dispatch=useDispatch();
    const history=useHistory();
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
                "email": "",
                "newpass": "",
                "passConfirm": ""

        },
        validationSchema: Yup.object({
            newpass: Yup.string()
                .min(8, 'Le mot de passe doit avoir au moins 8 caractères')
                .matches(RegExp('(.*[a-z].*)'), 'Au moins une lettre miniscule')
                .matches(RegExp('(.*[A-Z].*)'), 'Au moins une lettre majuscule')
                .matches(RegExp('(.*[0-9].*)'), 'Au moins un chiffre')
                .required("Ce champ est obligatoire"),
            passConfirm: Yup.string()
                .when("newpass", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("newpass")],
                        "Les mots de passe ne se ressemblent pas"
                    ),
                })
                .required("Ce champs est requis"),
        }),
        onSubmit: (values) => {
            const user = {
                email: values.email,
                newpass: values.newpass,
                passConfirm: values.passConfirm
            }
            console.log(user);
            dispatch(userResetPassword(user));
            localStorage.setItem("langreset", "fr");
            setTimeout(() => history.push("/auth-success-msg-basic"), 3000);
        }
    });
    return (

        <React.Fragment>
            <ParticlesAuth>
            <div className="auth-page-content">
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                    <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logo} width="32%" alt=""  />
                                        </Link>
                                    </div>
                                    {/* <p className="mt-1 fs-15 fw-medium" style={{color:'#FF6600'}}>Le Conseiller du BTP</p> */}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="justify-content-center g-0">
                                        <AuthSlider />
                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4 pt-12">
                                                <h5 style={{textAlign:'center',color:'#FF6600'}}>Création d'un nouveau mot de passe</h5>

                                                <div className="p-2">
                                                    <Form onSubmit={validation.handleSubmit}>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Email</Label>
                                                            <Input 
                                                            type="email" 
                                                            className="form-control" 
                                                            id="email"
                                                            placeholder="Entrez l'adresse e-mail" 
                                                            name="email"
                                                            value={validation.values.email}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={validation.errors.email && validation.touched.email ? true : false}
                                                            />
                                                            {validation.errors.email && validation.touched.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ): null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="password-input">Nouveau mot de passe</Label>
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    type={passwordShow ? "text" : "password"}
                                                                    className="form-control pe-5 password-input"
                                                                    id="password-input"
                                                                    name="newpass"
                                                                    value={validation.values.newpass}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.newpass && validation.touched.newpass ? true : false}
                                                                />
                                                                {validation.errors.newpass && validation.touched.newpass ? (
                                                                    <FormFeedback type="invalid">{validation.errors.newpass}</FormFeedback>
                                                                ) : null}
                                                                <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                                    id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                            </div>
                                                            <div id="passwordInput" className="form-text">Au moins 8 caratères</div>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="confirm-password-input">Confirmation du mot de passe</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input
                                                                    type={confrimPasswordShow ? "text" : "password"}
                                                                    className="form-control pe-5 password-input"
                                                                    id="confirm-password-input"
                                                                    name="passConfirm"
                                                                    value={validation.values.passConfirm}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.passConfirm && validation.touched.passConfirm ? true : false}
                                                                />
                                                                {validation.errors.passConfirm && validation.touched.passConfirm ? (
                                                                    <FormFeedback type="invalid">{validation.errors.passConfirm}</FormFeedback>
                                                                ) : null}
                                                                <Button color="link" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon">
                                                                <i className="ri-eye-fill align-middle"></i></Button>
                                                            </div>
                                                        </div> 

                                                        <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                            <h5 className="fs-13">Mot de passe doit contenir:</h5>
                                                            <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 caractères</b></p>
                                                            <p id="pass-lower" className="invalid fs-12 mb-2">Au moins <b>une lettre miniscule</b>(a-z)</p>
                                                            <p id="pass-upper" className="invalid fs-12 mb-2">Au moins <b>une lettre majuscule</b>(A-Z)</p>
                                                            <p id="pass-number" className="invalid fs-12 mb-0">Au moins <b>un chiffre</b>(0-9)</p>
                                                        </div>
                                                        <div className="mt-4">
                                                            <Button color="success" style={{ height: "40px", backgroundColor:'#FF6600', border:'#FF6600'}} className="w-100" type="submit">Mettre à jour mon mot de passe</Button>
                                                        </div>
                                                    </Form>
                                                </div>  
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
            </div>
        </ParticlesAuth>
        </React.Fragment>
    );
};

export default CoverPasswCreate;