import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { FieldArray } from "formik";

import {
    CardBody,
    Row,
    Col,
    Card,
    Container,
    Form,
    Input,
    Label,
    Table,
    FormFeedback,
    Button,
} from "reactstrap"

import { ToastContainer, toast } from 'react-toastify';

import { isEmpty } from "lodash";
 
import { Link, useHistory } from "react-router-dom"
import Flatpickr from "react-flatpickr"

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";

import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"

import { useFormik } from "formik";
import * as Yup from "yup";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import newId from '../../../src/newid'
import { axiosIns } from '../../utils'


const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      return { ...state, itemlist: [...state.itemlist, action.payload] };
    }
    case 'REMOVE_ITEM': {
      const newItemListR = state.itemlist.filter(
        (item) => item.id !== action.payload.id
      );
      return { ...state, itemlist: newItemListR };
    }
    case 'UPDATE_DESCRIPTION': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
          ? state.itemlist.map((item) =>
              item.id === existItem.id ? {...item, description: newItem.description } : item
            )
          : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
  } 
    case 'UPDATE_PRICE': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
          ? state.itemlist.map((item) =>
              item.id === existItem.id ? {...item, prix: newItem.prix } : item
            )
          : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
  } 
    case 'UPDATE_QTE': {
      const newItem = action.payload;
      console.log(newItem);
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    case 'UPDATE_PRODUCT': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
          ? state.itemlist.map((item) =>
              item.id === existItem.id ? {...item, produit: newItem.produit } : item
            )
          : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }
    default:
      return state;
  }
};

const DepenseAddNew = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://back.crm.inawo.pro/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  //const uniqueId = newId();
  const [state, invDispatch] = useReducer(reducer, {
    itemlist: [
      {
        id: newId(),
        produit: '',
        qte: 1,
        prix: 0
      },
    ],
  }); 
  const { itemlist } = state;
  const addItem = () => {
    invDispatch({
      type: 'ADD_ITEM',
      payload: {
        id: newId(),
        produit: '',
        qte: 1,
        prix: 0,
      },
    });
  };

  const removeItem = (item) => {
    //const newItem = 2;
    invDispatch({
      type: 'REMOVE_ITEM',
      payload: item,
    });
  };

  const updateProductHandler = async (item, produit) => {
    invDispatch({
      type: 'UPDATE_PRODUCT',
      payload: { ...item, produit},
    });
  }

  const updateQuantityHandler = (item, qte) => {
    //const newItem = 2;
    invDispatch({
      type: 'UPDATE_QTE',
      payload: { ...item, qte },
    });
  };

  
  const updatePriceHandler = (item, prix) => {
    invDispatch({
      type: 'UPDATE_PRICE',
      payload: { ...item, prix},
    });
  }

  
  

  const dispatch = useDispatch();
  const history = useHistory();

  const {ventes, crmcontacts } = useSelector((state) => ({
    ventes: state.Vente.ventes,
    crmcontacts: state.Crm.crmcontacts,
  }));

  const [venteList, setVenteList] = useState([]);
  const [contactList, setContactList] = useState([]);

  

  const [ispaymentDetails, setispaymentDetails] = useState(null);
  const [isCurrency, setisCurrency] = useState('$');

  function handleispaymentDetails(ispaymentDetails) {
    setispaymentDetails(ispaymentDetails);
  }

  function handleisCurrency(isCurrency) {
    setisCurrency(isCurrency);
  }

  const allstatus = [
    {
      options: [
        { label: props.t("Sélectionner le status de la depense"), value: "" },
        { label: props.t("En Cours"), value: "En Cours" },
        { label: props.t("Payé"), value: "Payé" },
        { label: props.t("Non Payé"), value: "Non payé"}
      ],
    },
  ];

  const dateFormat = () => {
    let d = new Date(),
      months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
    return (
      d.getDate() +
      ' ' +
      months[d.getMonth()] +
      ', ' +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const [taxpercent, setTaxpercent] = useState(0);
  const [discountpercent, setDiscountpercent] = useState(0);

  const dateformate = (e) => {
    const date = e.toString().split(' ');
    const joinDate = (date[2] + ' ' + date[1] + ', ' + date[3]).toString();
    setDate(joinDate);
  };

  document.title = props.t(
    'Ajout d\'une dépense | Le Conseiller du BTP'
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      libelle_depense:"",
      date_depense:null,
      status:null,
      depenseDetails:[]
    },
  });

  const [FormData, setFormData] = useState({
    libelle_depense:"",
    status:null,
    date_depense:null,
    montant:null,
    detailsDepense:[]
  });

  // const [selectedClient, setSelectedClient] = useState({});

 

  const handleinputform = (e) => {
    setFormData({ ...FormData, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({});

const validate = () => {
  const newErrors = {};
  if (!FormData.libelle_depense) {
    newErrors.libelle_depense= "Le champ est obligatoire";
  }
  if (!FormData.date_depense) {
    newErrors.date_depense = "Le champ est obligatoire";
  }
  if (!FormData.status) {
    newErrors.status = "Le champ est obligatoire";
  }
  if(!(itemlist.map((item) => item.prix * item.qte).reduce((a, b) => a + b, 0))){
    newErrors.montant = "Le champ est obligatoire"
  }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()){
      const detailsDepense = [];
      itemlist.map((item) =>
      detailsDepense.push({
          produit: item.produit,
          qte: item.qte,
          prix: item.prix,
        })
      );
      const newDepense = {
        libelle_depense: FormData.libelle_depense ? FormData.libelle_depense : 0,
        date_depense: FormData.date_depense,
        status: FormData.status ? FormData.status : 0,
        montant: itemlist.map((item) => item.prix * item.qte).reduce((a, b) => a + b, 0),
        detailsDepense: detailsDepense,
      };
      console.log("depense", newDepense);

      axios.post(`${url}/depenses/`, newDepense)
      .then(res=>{
        if(res.status !==400 || res.status !==500){
          console.log("idonsubmit",newDepense);
          toast.success(props.t("Dépense enregistrée avec succès"));
          history.push("/apps-depenses");
        }else{
          toast.error(props.t("Un problème est survenu lors de l\'enregistrement"),{autoClose:3000})
          console.log(res.status);        
        } 
      })

    }
    
  };


  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />
      <Container fluid>
        <BreadCrumb
          title={props.t('Ajouter une dépense')}
          pageTitle={props.t('Ajout de dépense')}
        />
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card>
              <Form onSubmit={submitHandler}>
                <CardBody className="p-4">
                  <Row>
                    
                    <Col lg={12} sm={12}>
                      <div className='mb-2'>
                        <Label for='libelle_depense'>
                          {props.t("Libellé de la dépense")}
                        </Label>
                        <Input 
                          type="text"
                          className="form-control bg-light border-0"
                          id="libelle_depense"
                          name="libelle_depense"
                          onChange={handleinputform}
                          onBlur={validation.handleBlur}
                          value={FormData.libelle_depense || ""}
                          invalid={errors.libelle_depense ? true : false}
                        />
                            {errors.libelle_depense  ? (
                              <FormFeedback type="invalid">{errors.libelle_depense}</FormFeedback>
                            ) : null}
                        
                      </div>
                    </Col>
                    <Col lg={6} sm={6}>
                    <div className="mb-2">
                        <Label
                          for="choices-payment-type"
                          className="form-label text-muted text-uppercase fw-semibold"
                        >
                          {props.t("Status de la dépense")}
                        </Label>
                        <div className="input-light">
                                        <Input
                                          name="status"
                                          type="select"
                                          className="bg-light border-0"
                                          id="choices-payment-status"
                                          onBlur={validation.handleBlur}
                                          onChange={handleinputform}
                                          value={ FormData.status || "" }
                                          invalid={errors.status ? true : false}

                                        >
                                          {allstatus.map((item, key) => (
                                              <React.Fragment key={key}>
                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                              </React.Fragment>
                                            ))}
                                          </Input>
                            {errors.status  ? (
                              <FormFeedback type="invalid">{errors.status }</FormFeedback>
                            ) : null}
                          </div>
                      </div>
                    </Col>
                    <Col lg={6} sm={6}>
                      <div className='mb-2'>                                                                                                                                                       
                        <Label for='date_depense'>
                          {props.t("Date")}
                        </Label>
                        <Input 
                          type="date"
                          className="form-control bg-light border-0"
                          id="date_depense"
                          name="date_depense"
                          onChange={handleinputform}
                          onBlur={validation.handleBlur}
                          value={FormData.date_depense }
                          invalid= { errors.date_depense ? true: false}
                        />
                         {errors.date_depense  ? (
                              <FormFeedback type="invalid">{errors.date_depense }</FormFeedback>
                            ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <div className="table-responsive">
                  <div className='container-fluid d-flex justify-content-center fw-semibold border-bottom border-bottom-dashed p-2' style={{ backgroundColor: 'whitesmoke' }}>
                    <h4 className='fw-semibold'>{props.t("DEPENSE")}</h4>
                  </div>
                  </div>
                
                <CardBody className="p-4">
                  <div className="table-responsive">
                    <Table className="invoice-table table-borderless table-nowrap mb-0">
                      <thead className="align-middle">
                        <tr className="table-active">
                          <th scope="col" style={{ width: '50px' }}>
                            #
                          </th>
                          <th scope="col">{props.t("Désignation")}</th>
                          <th scope="col" style={{ width: '120px' }}>
                              <center>{props.t("Quantité")}</center> 
                          </th>
                          <th scope="col" style={{ width: '120px' }}>
                            <center>{props.t("Prix")}</center>
                          </th>
                          <th
                            scope="col"
                            className="text-end"
                            style={{ width: '150px' }}
                          ><center>{props.t("Montant")}</center>
                          </th>
                          <th
                            scope="col"
                            className="text-end"
                            style={{ width: '105px' }}
                          ></th>
                        </tr>
                      </thead>
                      {itemlist.map((item) => (
                        <>
                          <tbody key={item.id}>
                            <tr id={item.id} className="product">
                              <th scope="row" className="product-id">
                                {itemlist.indexOf(item) + 1}
                              </th>
                              <td className="text-start">
                                <div className="mb-2">
                                  <Input
                                    className="form-control bg-light border-0"
                                    type="text"
                                    id={`productName-${item.id}`}
                                    name={`product_name-${item.id}`}
                                    placeholder={props.t("Nom...")}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      updateProductHandler(
                                        item,
                                        e.target.value
                                      );
                                    }}
                                  >
                                  </Input>
                                 
                                </div>
                              </td>
                              <td>
                                  <Input
                                    type="number"
                                    className="form-control bg-light border-0"
                                    id={`product-qty-${item.id}`}
                                    onChange={(e) => {
                                      updateQuantityHandler(
                                        item,
                                        e.target.value
                                      );
                                    }}
                                  />
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  className="form-control bg-light border-0"
                                  id={`productRate-${item.id}`}
                                  onChange={(e) => {
                                    updatePriceHandler(
                                      item, 
                                      e.target.value
                                      );
                                  }}
                                />
                                <div className="invalid-feedback">
                                  Please enter a rate
                                </div>
                              </td>
                              <td className="text-end">
                                <div>
                                  <Input
                                    type="number"
                                    className="form-control bg-light border-0 "
                                    placeholder="0.00"
                                    value={item.qte*item.prix}
                                    readOnly
                                  />
                                </div>
                              </td>
                              <td
                                id={`product-removal-${item.id}`}
                                className="product-removal"
                              >
                                <Link
                                  id={`del-${item.id}`}
                                  to="#"
                                  className="btn btn-success"
                                  onClick={() => {
                                    removeItem(item);
                                  }}
                                >
                                  {props.t("Supprimer")}
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      ))}
                      <tbody>
                        <tr>
                          <td colSpan="5">
                            <Link
                              to="#"
                              className="btn btn-soft-secondary fw-medium"
                              id="add-item"
                              onClick={addItem}
                            >
                              <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                              {props.t("Ajouter une dépense")}
                            </Link>
                          </td>
                        </tr>
                        <tr className="border-top border-top-dashed mt-2">
                          <td colSpan="3"></td>
                          <td colSpan="2" className="p-0">
                            <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">{props.t("Montant Total")}</th>
                                  <td style={{ width: '150px' }}>
                                    <Input
                                      type="text"
                                      name=""
                                      className="form-control bg-light border-0"
                                      id="cart-subtotal"
                                      placeholder="0"
                                      readOnly
                                      value={itemlist.map((item) => item.prix * item.qte).reduce((a, b) => a + b, 0)}
                                      invalid= { errors.montant ? true: false}
                                      />
                                       {errors.montant  ? (
                                            <FormFeedback type="invalid">{errors.montant}</FormFeedback>
                                          ) : null}
                                  </td>
                                </tr>
                                
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                 
                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    <button type="submit" className="btn btn-success">
                    <i className="ri-save-3-fill align-bottom me-1"></i>{' '}
                      {props.t("Enregistrer")}
                    </button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

DepenseAddNew.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DepenseAddNew));
