import {
  LOGIN_ADMIN,
  LOGIN_SUCCESS_ADMIN,
  LOGOUT_ADMIN,
  LOGOUT_ADMIN_SUCCESS,
  API_ERROR,
  RESET_LOGIN_FLAG
} from "./actionTypes";

const initialState = {
  admin: {},
  error: "",
  loading: false,
};

const loginAdmin = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ADMIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS_ADMIN:
      state = {
        ...state,
        admin: action.payload,
        loading: false,
      };
      break;
    case LOGOUT_ADMIN:
      state = { ...state, isUserLogout: false };
      break;
    case LOGOUT_ADMIN_SUCCESS:
      state = { ...state, isUserLogout: true };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload.data,
        loading: false,
        isUserLogout: false,
      };
      break;
    case RESET_LOGIN_FLAG:
      state = {
        ...state,
        error: null
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default loginAdmin;
