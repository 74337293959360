import {
  GET_PARTICULIERS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,

  ADD_PARTICULIER_SUCCESS,
  ADD_PARTICULIER_FAIL,
  
  UPDATE_PARTICULIER_SUCCESS,
  UPDATE_PARTICULIER_FAIL,
} from "./actionType";

const INIT_STATE = {
  crmparticuliers: [],
  error: {},
};

const Particulier = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PARTICULIERS:
          return {
            ...state,
            crmparticuliers: action.payload.data,
            isParticulierCreated: false,
            isParticulierSuccess: true
          };
        
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PARTICULIERS:
          return {
            ...state,
            error: action.payload.error,
            isParticulierCreated: false,
            isParticulierSuccess: false
          };
        
        default:
          return { ...state };
      }

    case ADD_PARTICULIER_SUCCESS:
      return {
        ...state,
        isParticulierCreated: true,
        crmparticuliers: [...state.crmparticuliers, action.payload.data],
        isParticulierAdd: true,
        isParticulierAddFail: false
      };

    case ADD_PARTICULIER_FAIL:
      return {
        ...state,
        error: action.payload,
        isParticulierAdd: false,
        isParticulierAddFail: true
      };

    case UPDATE_PARTICULIER_SUCCESS:
      return {
        ...state,
        crmparticuliers: state.crmparticuliers.map(particulier =>
          particulier.id === action.payload.id
            ? { ...particulier, ...action.payload }
            : particulier
        ),
        isParticulierUpdate: true,
        isParticulierUpdateFail: false
      };

    case UPDATE_PARTICULIER_FAIL:
      return {
        ...state,
        error: action.payload,
        isParticulierUpdate: false,
        isParticulierUpdateFail: true
      };

    default:
      return { ...state };
  }
};

export default Particulier;
