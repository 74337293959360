import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";

import logo from "../../../assets/images/lcbtp.png";

const BasicSuccessMsg = () => {
document.title ="Mot de passe bien modifié | Le Conseiller du BTP";
    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logo} width="32%" alt=""  />
                                        </Link>
                                    </div>
                                    {/* <p className="mt-1 fs-15 fw-medium" style={{color:'#FF6600'}}>Le Conseiller du BTP</p> */}
                                </div>
                            </Col>
                        </Row>

                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4 text-center">
                                            <div className="mt-4 pt-2">
                                                <h4 style={{color:'#FF6600'}}> Mot de passe bien modifié !</h4>
                                                <div className="mt-4">
                                                    <Link to="/fr/connexion" className="btn btn-info w-100"  style={{ height: "40px", backgroundColor:'#FF6600', border:'#FF6600'}}>Retour à la page de connexion</Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment >
    );
};

export default BasicSuccessMsg;