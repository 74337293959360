import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// actions
import { loginAdmin, resetLoginAdminFlag, loginAdminSuccess, apiError } from "../../store/actions";

// import logoLight from "../../assets/images/logo-light.png";
import logoInawoCRMBlanc2 from "../../assets/images/logo-InawoCRM-Blanc-2.png";

//Import config
import { facebook, google } from "../../config";
//import images
// import Cookies from 'js-cookie';

// // Fonction pour enregistrer l'état de connexion de l'utilisateur
// const setLoggedIn = (loggedIn) => {
//   Cookies.set('loggedIn', loggedIn, { expires: 7 }); // Stocke l'état de connexion de l'utilisateur dans un cookie qui expire dans 7 jours
//   Cookies.set('redirectUrl', window.location.href); // Stocke l'URL de la page actuelle dans un cookie
// };

// // Fonction pour récupérer l'état de connexion de l'utilisateur
// const isLoggedIn = () => {
//   const loggedIn = Cookies.get('loggedIn');
//   return loggedIn === 'true'; // Retourne vrai si l'utilisateur est connecté, faux sinon
// };

// // Fonction pour récupérer l'URL de redirection stockée dans le cookie
// const getRedirectUrl = () => {
//     const redirectUrl = Cookies.get('redirectUrl');
//     Cookies.remove('redirectUrl'); // Supprime le cookie une fois que l'URL est récupérée
//     return redirectUrl;
// };

const LoginAdmin = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => ({
        user: state.Account.user,
    }));

    const [passwordShow, setPasswordShow] = useState(false);

    const [userLogin, setUserLogin] = useState([]);

    useEffect(() => {
        if (user && user) {
            setUserLogin({
                email: user.user.email,
                password: user.user.confirm_password
            });
        }
    }, [user]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || "" || '',
            password: userLogin.password || "" || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Veuillez saisir votre email"),
            password: Yup.string().required("Veuillez saisir votre mot de password"),
        }),
        onSubmit: (values) => {
            dispatch(loginAdmin(values, props.history));
        }
    });

    const { error } = useSelector(state => ({
        error: state.Login.error,
    }));

    useEffect(() => {
        setTimeout(() => {
            dispatch(resetLoginAdminFlag());
        }, 3000);
    }, [dispatch, error]);

    document.title = "Connexion Administration | Le Conseiller du BTP";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <ToastContainer closeButton={false} />
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            {/* <img src={logoLight} alt="" height="20" /> */}
                                            <img src={logoInawoCRMBlanc2} width="30%" alt=""  />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Grandir avec vos clients</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Bienvenue !</h5>
                                            <p className="text-muted">Connectez-vous pour continuer sur InawoCRM.</p>
                                        </div>
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    // console.log(e);
                                                    return false;
                                                    
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Entrer votre adresse email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/auth-pass-reset-basic" className="text-muted">Mot de passe oublié ?</Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Mot de passe</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Entrez votre mot de passe"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Se souvenir de moi</Label>
                                                </div>

                                                <div className="mt-4">
                                                    <Button backgroud-color="#0166ff" className="btn w-100" type="submit">Se connecter</Button>
                                                </div>

                                                

                                                
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">Vous n'avez pas de compte ? <Link to="/fr/inscription" className="fw-semibold text-primary text-decoration-underline"> S'inscrire </Link> </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(LoginAdmin);