import React, {useEffect, useState, useMemo} from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { 
    Container,
    Row
 } from "reactstrap";
 import { Link } from "react-router-dom";
import TableContainer from "../../Components/Common/TableContainer";

import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from 'axios';


const Experts = (props) => {

    const tokenInfo = JSON.parse(sessionStorage.getItem("authUser"))
    const role= tokenInfo.role;

    const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
    const url = "https://backend.leconseillerdubtp.com/api/v1";

    const [dataexperts, setDataExperts] = useState([])
    const [loading, setLoading] = useState(false);

    const [userList, setUserList] = useState([]);

    useEffect(() => {
      const checkTokenValidity = async () => {
          try {
          const response = await axios.get("https://backend.leconseillerdubtp.com/api/v1/users/list/", {
            headers: {
              Authorization: 'Bearer ' + token
            },
          });
          setUserList(response)
          } catch (error) {
          console.log(error)
          } 
      };
      checkTokenValidity();
    }, []);

    const users =(j)=>{
      for(let i=0;i<=userList.length;i++){
        if(userList[i] && j===userList[i]?.id){
         const name= userList[i]?.profile;
         return name.last_name+ " " +name.first_name;
        }
      }
    }

    const email =(j)=>{
      for(let i=0;i<=userList.length;i++){
        if(userList[i] && j===userList[i]?.id){
          return userList[i]?.email;
        }
      }
    }

    useEffect(() => {
      const checkTokenValidity = async () => {
          try {
          const response = await axios.get(`${url}/expert/`, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          setDataExperts(response)
          } catch (error) {
          console.log(error)
          } 
      };
      checkTokenValidity();
    }, [url]);
  


    document.title = props.t("Liste des experts");
    const checkedAll = () => {
      const checkall = document.getElementById("checkBoxAll");
      const ele = document.querySelectorAll(".entrepriseCheckBox");
  
      if (checkall.checked) {
        ele.forEach((ele) => {
          ele.checked = true;
        });
      } else {
        ele.forEach((ele) => {
          ele.checked = false;
        });
      }
    }
    const columns = useMemo(() => [
      {
        Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="entrepriseCheckBox" value={cellProps.row.original.id} />;
        },
        id: '#',
      },
          {
            Header: props.t("NOM "),
            Cell: (expert) => (
              <>
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1 italic" style={{textTransform:"uppercase",fontWeight:"Bold"}}>
                      <Link
                        className="text-dark" 
                      >
                        {" "}
                        {users(expert.row.original.user)===undefined || users(expert.row.original.user) ===" " ? "" : users(expert.row.original.user) }
                      </Link>
                    </h5>
                  </div>
                </div>
              </>
            ),
          },
          {
            Header: props.t(" "),
            Cell: (cellProps) => {
              return (
                <h5 className="fs-14 mb-1">
                  { cellProps.row.original.banca_certificate && cellProps.row.original.is_validated &&  cellProps.row.original.is_approved && 
                    <div className="flex aligns-items-center justify-items">
                      <span className='badge badge-soft-success me-1'>{props.t("Certifié")}</span> 
                    </div>
                  }

                  {
                      cellProps.row.original.is_validated === false && cellProps.row.original.validation_report && 
                      <span className='badge badge-soft-danger me-1'>{props.t("Non validé")}</span>
                  }

                  {
                     cellProps.row.original.is_approved  && cellProps.row.original.message && !cellProps.row.original.banca_certificate &&
                    <span className='badge badge-soft-danger me-1'>{props.t("Non approuvé")}</span>
                  }
                  
                </h5>
              )
            }
          },
          {
            Header: props.t("EMAIL"),
            Cell: (expert) => (
              <>
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1 italic">
                      <Link
                        className="text-dark" 
                      >
                        {" "}
                        {email(expert.row.original.user) === undefined || email(expert.row.original.user) === " " ? " " : email(expert.row.original.user)}
                      </Link>
                    </h5>
                  </div>
                </div>
              </>
            ),
          },
          {
            Header: props.t("FORMATION"),
            accessor: "formation",
            Cell: (expert) => (
              <>
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1 italic" style={{textTransform:"uppercase"}}>
                      <Link
                        className="text-dark" 
                      >
                        {" "}
                        {!expert.row.original.formation ? "" : expert.row.original.formation }
                      </Link>
                    </h5>
                  </div>
                </div>
              </>
            ),
          },
          {
            Header: props.t("PROFESSION"),
            accessor: "profession",
            Cell: (expert) => (
              <>
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1 italic">
                      <Link
                        className="text-dark" 
                      >
                        {" "}
                        {!expert.row.original.profession ? " " : expert.row.original.profession }
                      </Link>
                    </h5>
                  </div>
                </div>
              </>
            ),
          },
          {
            Header: props.t("DIPLOME"),
            accessor: "last_certificate",
            Cell: (expert) => (
              <>
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1 italic">
                      <Link
                        className="text-dark" 
                      >
                        {" "}
                        {!expert.row.original.last_certificate ? " " : expert.row.original.last_certificate}
                      </Link>
                    </h5>
                  </div>
                </div>
              </>
            ),
          },
        {
            Header: props.t("VALIDATION"),
            Cell: (cellProps) => {
              return(
                <div className="d-flex align-items-center space-x-2" style={{width:'150px'}}>
                  <div className="fs-14 " >
                    {cellProps.row.original.is_validated ===false && !cellProps.row.original.validation_report && (role.some((role)=> role === 'commite') || role.some((role)=> role === 'admin'))  ? 
                    <div className=" aligns-items-center justify-items">
                        <div className="btn btn-soft-danger" style={{fontSize:'13px', margin:'0px'}}>
                            <Link to={`apps-experts-details/${cellProps.row.original.id}/`}>
                                Valider
                              </Link>
                        </div> 
                    </div> :
                    
                  cellProps.row.original.is_approved === false && !cellProps.row.original.message && cellProps.row.original.is_validated && role.some((role)=> role === 'admin')  ? 
                  
                    <div className=" aligns-items-center justify-items">
                        <div className="btn btn-soft-success" style={{fontSize:'13px'}}>
                            <Link to={`apps-experts-details/${cellProps.row.original.id}/`}>
                                Approuver
                              </Link>
                        </div> 
                    </div>
                    : null 
                    }
                  </div>
                  {
                    !cellProps.row.original.banca_certificate &&
                    <div className="btn btn-soft-warning" style={{fontSize:'13px'}}>
                      <Link to={`apps-experts-details/${cellProps.row.original.id}/`}>
                          Certifier
                        </Link>
                    </div> 
                  }
                </div>
              )
            }
        },
          {
            Header: props.t("ACTIONS"),
            Cell: (cellProps) => {
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                
                 <li className="list-inline-item" title={props.t("Voir")}>
                  <Link to={`/apps-experts-details/${cellProps.row.original.id}/`} className="text-warning d-inline-block">
                       <i className="ri-eye-fill align-bottom"></i>
                   </Link>
                 </li>
               </ul>
              );
            },
          },
    ], [props,users,email])

  return (
    <div className='page-content'>
      <ToastContainer closeButton={false} />
        <Container fluid>
            <BreadCrumb title={props.t("Experts")} pageTitle={props.t("Liste des experts")} />

            <Row>
                <div className="col-xl-12 col-lg-12">
                    <div>
                        <div className="card">
                            <div className="card-body pt-0">
                            {  loading ? ( // If loading, show loading indicator
                                <div className="py-4 text-center">
                                    <div>
                                    <lord-icon
                                        src="https://cdn.lordicon.com/xjovhxra.json"
                                        trigger="loop"
                                        colors="primary:#0960b6,secondary:#02376b"
                                        style={{ width: "72px", height:"72px" }}>
                                    </lord-icon>
                                    </div>
                                    <div className="mt-4">
                                    <h5>{props.t("Chargement")}...</h5>
                                    </div>
                                </div>
                                ) 
                                :
                            dataexperts  && dataexperts.length > 0  ? (
                                <div>
                                <TableContainer
                                  columns={columns}
                                  data={dataexperts || []}
                                  isGlobalFilter={true}
                                  isAddUserList={false}
                                  customPageSize={10}
                                  divClass="table-responsive mb-1"
                                  tableClass="mb-0 align-middle"
                                  theadClass="table-light text-muted"
                                  // isExpertFilter={true}
                                />
                                </div>
                                
                            ) : (
                                <div className="py-4 text-center">
                                <div className="mt-4">
                                    <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                                </div>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </Row>

        </Container>
    </div>
  )
}

Experts.propTypes={
    location:PropTypes.object,
    t:PropTypes.any
  }
  export default withRouter(withTranslation()(Experts))
