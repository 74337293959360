import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_AFFILIATE } from "./actionTypes";
import { registerAffiliateSuccessful, registerAffiliateFailed } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeRegisterAffiliate,
  postJwtRegisterAffiliate,
} from "../../../helpers/fakebackend_helper";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is affiliate register successfull then direct plot affiliate in redux.
function* registerAffiliate({ payload: { affiliate, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.registerAffiliate,
        affiliate.email,
        affiliate.password
      );
      yield put(registerAffiliateSuccessful(response));
      history.push("/affiliate/en/login");
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      // const response = yield call(postJwtRegisterAffiliate, "/post-jwt-register", affiliate);
      const response = yield call(postFakeRegisterAffiliate, affiliate);
      yield put(registerAffiliateSuccessful(response));
      history.push("/affiliate/en/login");
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeRegisterAffiliate, affiliate);
      if (response.message === "success") {
        yield put(registerAffiliateSuccessful(response));
        history.push("/affiliate/en/login");
      } else {
        yield put(registerAffiliateFailed(response));
      }
    }
  } catch (error) {
    yield put(registerAffiliateFailed(error));
  }
}

export function* watchAffiliateRegister() {
  yield takeEvery(REGISTER_AFFILIATE, registerAffiliate);
}

function* AffiliateSaga() {
  yield all([fork(watchAffiliateRegister)]);
}

export default AffiliateSaga;
