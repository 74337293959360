import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CODE_OTP, FORGET_PASSWORD,RESET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  otpPwd,
  postFakeForgetPwd,
  postJwtForgetPwd,
  postJwtResetPwd
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, user.email);
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        );
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtForgetPwd, {
        email: user.email,
      });
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Le lien de réinitialisation est envoyé dans votre boîte aux lettres, vérifiez d'abord celle-ci."
          )
        );
      }
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeForgetPwd, user);
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        );
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* resetUser({ payload: { data} }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtResetPwd, {
        email: data.email,
        newpass: data.newpass,
        passConfirm: data.passConfirm,
      });
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Le lien de réinitialisation est envoyé dans votre boîte aux lettres, vérifiez d'abord celle-ci."
          )
        );
      }
    } 
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* otpUser({ payload: { data} }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(otpPwd, {
        email: data.email,
        otp:data.otp
      });
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Le lien de réinitialisation est envoyé dans votre boîte aux lettres, vérifiez d'abord celle-ci."
          )
        );
      }
    } 
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetUser);
}

export function* watchUserOtp() {
  yield takeEvery(CODE_OTP, otpUser);
}



function* PassordSaga() {
  yield all([
    fork(watchUserPasswordForget),
    fork(watchUserPasswordReset),
    fork(watchUserOtp)
  ]);
}

export default PassordSaga