import axios from "axios";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import "react-image-gallery/styles/css/image-gallery.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";

// Redux
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { useFormik } from "formik";
import { registerPlugin } from "react-filepond";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const InnovationsAdd = (props) => {
  document.title = "Création d'innovations ";
  const history = useHistory();

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    let h = d.getHours() % 12 || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      ", " +
      h +
      ":" +
      d.getMinutes() +
      " " +
      ampm
    ).toString();
  };

  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;
  const url = "https://backend.leconseillerdubtp.com/api/v1";
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      label: "",
      description: "",
      innovation_pic: null,
      published: false,
    },

    validationSchema: Yup.object().shape({
      label: Yup.string().required("Ce champ est requis"),
      description: Yup.string().required("Ce champ est requis"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("label", values.label);
      formData.append("description", values.description);
      selectedFile && formData.append("innovation_pic", selectedFile);
      try {
        const res = await axios.post(`${url}/innovations/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.status === 201) {
          // Rechargement après un délai de 3 secondes
          setTimeout(() => window.location.reload(true), 3000);
          toast.success(props.t("Innovation bien ajoutée"), {
            autoClose: 3000,
          });
        }
      } catch (error) {
        toast.error(props.t("Innovation non ajoutée"), {
          autoClose: 3000,
        });
      } finally {
        validation.resetForm();
        // Redirection après le succès de la requête
        history.push("/apps-innovations");
      }
    },
  });
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Création d'innovations")}
          pageTitle={props.t("Innovations")}
        />
        <Form
          encType="multipart/form-data"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <Row>
            <Col lg={12}>
              <Card className="p-4">
                <div className="mb-3">
                  <Label className="form-label" htmlFor="photo">
                    {props.t("IMAGE")}{" "}
                    <span style={{ color: "red" }}>
                      * Tous les champs sont obligatoires
                    </span>
                  </Label>
                  <div>
                    <input
                      type="file"
                      className="form-control"
                      name="innovation_pic"
                      accept="image/jpeg"
                      capture="file"
                      onChange={handleFileChange}
                    />
                    <br />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {selectedFile ? (
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          style={{ maxWidth: "50%" }}
                          alt={`Thumbnail`}
                        />
                      ) : null}
                    </div>
                  </div>
                  {validation.errors.innovation_pic &&
                  validation.touched.innovation_pic ? (
                    <FormFeedback type="invalid">
                      {props.t(validation.errors.innovation_pic)}
                    </FormFeedback>
                  ) : null}
                  <br />
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="label">
                    {props.t("TITRE")} <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form"
                    style={{ fontWeight: "bold", textTransform: "uppercase" }}
                    name="label"
                    value={validation.values.label}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.label && validation.touched.label
                        ? true
                        : false
                    }
                  />
                  {validation.errors.label && validation.touched.label ? (
                    <FormFeedback type="invalid">
                      {props.t(validation.errors.label)}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label" htmlFor="description">
                    {" "}
                    {props.t("DESCRIPTION")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <textarea
                    className="form-control"
                    name="description"
                    value={validation.values.description}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    rows="10"
                    cols="20"
                  />
                  {validation.errors.description &&
                  validation.touched.description ? (
                    <FormFeedback type="invalid">
                      {props.t(validation.errors.description)}
                    </FormFeedback>
                  ) : null}
                </div>
              </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-6">
                  <Link to={`/apps-innovations`} className="text-white">
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning m-2">
                  {props.t("Ajouter")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};
InnovationsAdd.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(InnovationsAdd));
