import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import {
    CardBody,
    Row,
    Col,
    Card,
    Container,
    Form,
    Input,
    Label,
    Table,
    FormFeedback,
    Button,
} from "reactstrap"

import { isEmpty } from "lodash";

import { ToastContainer, toast } from 'react-toastify';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
 
import { Link, useHistory, useParams } from "react-router-dom"
import Flatpickr from "react-flatpickr"

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";

import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"

import newId from '../../../src/newid'
import { axiosIns } from '../../utils'

import { useFormik } from "formik";
import * as Yup from "yup";

import {getProducts as onGetProducts} from "../../store/ecommerce/action"
import { getContacts as onGetContacts } from "../../store/crm/action"; 
import { updateVente as onUpdateVente } from "../../store/ventes/action";
import { isDateSpansEqual } from "@fullcalendar/react";

const VenteUpdateVente = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

  const [productslist, setProductsList] = useState([{
      id: null,
      prixProd: null,
      qteStock: null,
  }]);
      useEffect(() => {
        const fetchProducts = async () => {
          try {
            const { data } = await axiosIns.get(`/products/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            console.log("ali",data);
            setProductsList(data);
          } catch (err) {
            console.log(err);
            //toast.error(t('make_sure_internet'));
          }
        };
        fetchProducts();
      }, [token]);

  const PrixProd =(j)=>{
    for (let i = 0; i < productslist.length; i++) {
      if(productslist[i]?.id === j){
        return productslist[i]
      }
      return productslist[i]
    }
  }

  console.log("code" ,PrixProd(1))

  document.title = props.t("Modifier Vente | Le Conseiller du BTP");

  const { products, crmcontacts } = useSelector((state) => ({
    products :state.Ecommerce.products,
    crmcontacts: state.Crm.crmcontacts,
  
  }));

  const history = useHistory();
  const dispatch = useDispatch();
  const [ispayementDetails, setIspayementDetails] = useState(null);
  const [isCurrency, setIsCurrency] = useState("$")

    function handleisCurrency(isCurrency){
        setIsCurrency(isCurrency);
    }

  function handleispaymentDetails(ispayementDetails) {
    setIspayementDetails(ispayementDetails);
  }

  const [productList, setProductList] = useState([]);
  const [contactList, setContactList] = useState([]);
  
  useEffect(() => {
    if (products && !products.length) {
      dispatch(onGetProducts());
    }
  }, [dispatch, products]);
  
  //  remplacer products par son existant dans l'etat
  useEffect(() => {
    setProductList(products);
  }, [products]);
  console.log("products", products);


  useEffect(() => {
    if (!isEmpty(products)) setProductList(products);
  }, [products]);


  useEffect(() => {
    if (crmcontacts && !crmcontacts.length) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContactList(crmcontacts);
  }, [crmcontacts]);
  console.log("contacts", crmcontacts);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) setContactList(crmcontacts);
  }, [crmcontacts]);


  const venteId = useParams('id');
  console.log("venteId", venteId);
  const url = "https://back.crm.inawo.pro/api/v1"
  //     if (token)
  //       axios.defaults.headers.common["Authorization"] = `Bearer` + token;
       

    
  const paymentdetails = [
    {
      options: [
        { label: props.t("Sélectionner la méthode de paiement"), value: "" },
        {label: props.t("Espèce"), value: "Espece"},
        {label: props.t("Chèque"), value: "Cheque"},
        { label: props.t("Master Card"), value: "Master Card" },
        { label: props.t("Visa"), value: "Visa" },
        { label: props.t("Paypal"), value: "Paypal" },
        {label: props.t("Virement") , value: "Virement"},
        {label: props.t("Mobile Money"), value: "Mobile Money"},
      ],
    },
  ];
  

      const allstatusvente = [
        {
          options: [
            { label: "Sélectionner le status de la vente", value: "" },
            { label: "En cours", value: "En Cours" },
            { label: "Terminé", value: "Terminé" },
          ],
        },
      ];

      const allstatuspayement = [
        {
          options: [
            { label: "Sélectionner le status du payement", value: "" },
            { label: "En cours", value: "En Cours" },
            { label: "Terminé", value: "Terminé" },
            { label: "Non Payé", value: "Non Payé"}
          ],
        },
      ];
    
      const allstatuslivraison = [
        {
          options: [
            { label: props.t("Sélectionner le statut de la livraison"), value: "" },
            { label: props.t("En cours"), value: "En Cours" },
            { label: props.t("Terminé"), value: "Terminé" },
          ],
        },
      ];
    
      const dateFormat = () => {
        let d = new Date(),
          months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
      };
    
      const [date, setDate] = useState(dateFormat());
    
      const dateformate = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
        setDate(joinDate);
      };


      const validation = useFormik ({
        enableReinitialize: true,

        initialValues: {
          statusVente: "",
          statusLivraison: "",
          statusPaiement: "",
          typePaiement: "",
          encaissementRecu: "",
          echeance: "",
          contact: "",
          commentaire: "",
          detailsVente: [
            {
              produit: null,
              qteVendu: null,
          }
          ]
        },
        
        validationSchema: Yup.object().shape({
          statusVente: Yup.string().required("Ce champ est obligatoire"),
          statusLivraison: Yup.string().required("Ce champ est obligatoire"),
          statusPaiement: Yup.string().required("Ce champ est obligatoire"),
          typePaiement: Yup.string().required("Ce champ est obligatoire"),
          encaissementRecu: Yup.number().required("Ce champ est obligatoire"),
          contact: Yup.number().required("Ce champ est obligatoire"),
          commentaire: Yup.string().required("Ce champ est obligatoire"),
          detailsVente: Yup.array().of(
            Yup.object().shape({
              produit: Yup.number().required("Ce champ est obligatoire"),
              qteVendu: Yup.number().required("Ce champ est obligatoire"),
            }
          )
          )
        }),

      })

      const prodId = useParams('id');
      console.log("code1" ,prodId);
     

      const handleAddItem = () => {
        setDetails([...details, { produit: '', qteVendu: '' }]);
      };

      const [discountpercent, setDiscountpercent] = useState(0);
      const [FormData, setFormData] = useState({});
        useEffect(() => {
            const fetchData = async ()=> {
                try {
                    const data = await axios.get(`${url}/ventes/${venteId.id}`);
                    setFormData(data);  
                    setDiscountpercent(data.remise)                
                    console.log("resultat", data)
                } catch (err) {
                    console.log(err)
                }
            };
            fetchData(); 
            console.log("data", fetchData())       
        }, [venteId, url])
      console.log("all",FormData)

    const [details, setDetails] = useState([]);
    useEffect(() => {
      if (!isEmpty(FormData))
      setDetails(FormData.detailsVente);
  }, [FormData]);
  console.log("details", details);

  const handleUpdateQteVendu = (index, value) => {
    const updatedDetails = [...details];
    updatedDetails[index].qteVendu = value;
    setDetails(updatedDetails);
  };

  const handleUpdateProduit = (index, value) => {
    const updatedDetails = [...details];
    updatedDetails[index].produit = value;
    setDetails(updatedDetails);
  };

  const removeItem = (index) => {
        let updatedDetails = [...details];
        updatedDetails.pop(index, 1);
        setDetails(updatedDetails);
      };

      const [selectedProductPrice, setSelectedProductPrice] = useState({ tbodyIndex: null, price: null });
      const [productPrices, setProductPrices] = useState({});
      
      const handleSelectedProductPrice = (tbodyIndex, price) => {
        setSelectedProductPrice({ tbodyIndex, price });
        setProductPrices({ ...productPrices, [tbodyIndex]: price });
      };

  const productPrice = (j)=> {
    for(let i=0;i<=productslist.length; i++){
      if( j===productslist[i]?.id){
       return (productslist[i]?.prixProd) ;
      }
    }
  }


      const handleInputForm = (e) => {
        setFormData({ ...FormData, [e.target.name]: e.target.value });
      };

      const [errors, setErrors] = useState({});

      const montantCalcul2 = discountpercent === 0 ? details.map((item, index) => Number.isFinite(productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu) ? productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu : 0).reduce((a, b) => a + b, 0) : details.map((item, index) => Number.isFinite(productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu) ? productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu : 0).reduce((a, b) => a + b, 0) - (discountpercent/100) * details.map((item, index) => Number.isFinite(productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu) ? productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu : 0).reduce((a, b) => a + b, 0)

const validate = () => {
  const newErrors = {};
  if (!FormData.statusLivraison) {
    newErrors.statusLivraison = "Le statut de livraison est requis";
  }
  if (!FormData.typePaiement) {
    newErrors.typePaiement = "La méthode de paiement est requise";
  }
  if (!FormData.encaissementRecu) {
    newErrors.encaissementRecu = "L'encaissement reçu est requis";
  }
  if (!FormData.contact) {
    newErrors.contact = "Le contact est requis";
  }
  if (parseInt(FormData.encaissementRecu) < parseInt(montantCalcul2) && !FormData.echeance) {
    newErrors.echeance = "L'encaissement étant inférieur au montant, l'échéance est requise";
  }
  if(!montantCalcul2){
    newErrors.montant = "Veuillez renseigner les produits ou services !!!"
  }
  if (parseInt(FormData.encaissementRecu) > parseInt(montantCalcul2)) {
    newErrors.encaissementRecu = "L'encaissement ne peut pas être supérieur au montant";
  }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

      const submitHandler = async (e) => {
        e.preventDefault();
        if (validate()) {

        const newVente = {
          "statusVente": parseInt(FormData.encaissementRecu) === parseInt(montantCalcul2) && FormData.statusLivraison === "Terminé" ? "Terminé" : "En Cours",
          "statusLivraison": FormData.statusLivraison,
          "statusPaiement":  parseInt(FormData.encaissementRecu) === parseInt(montantCalcul2) ? "Terminé" : parseInt(FormData.encaissementRecu) < parseInt(montantCalcul2) ? "En Cours" : "Non Payé",
          "typePaiement": FormData.typePaiement,
          "encaissementRecu": FormData.encaissementRecu,
          "montant": montantCalcul2,
          "echeance": parseInt(FormData.encaissementRecu) === parseInt(montantCalcul2) ? null : FormData.echeance,
          "remise": discountpercent,
          "contact": FormData.contact,
          "commentaire": "",
          "detailsVente": details,
        }
        console.log("newVente", newVente);
    
        try {
          const { data } = await axiosIns.patch(`/ventes/${venteId.id}/`, newVente, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          history.push("/apps-ventes");
          window.location.reload(true)
          toast.success(props.t("Vente modifiée avec succès"));
        } catch (err) {
          console.log(err);
          //toast.error(t('make_sure_internet'));
        }
      }
    }
  
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={props.t("Modifier une Vente")} pageTitle={props.t("Ventes")} />
                <Row className="justify-content-center">
                    <Col xxl={9}>
                        <Card>
                            <Form
                            onSubmit={submitHandler}
                            className="needs-validation"
                            id="invoice_form"
                            >
                                <CardBody className="p-4">
                                  <Row className="g-3">
                                    <Col lg={6} sm={6}>
                                      <Label for="choices-payment-status">{props.t("Statut de Livraison")}</Label>
                                      <div className="input-light">
                                        <Input
                                          name="statusLivraison"
                                          type="select"
                                          className="bg-light border-0"
                                          id="choices-payment-status"
                                          onChange={handleInputForm}
                                          onBlur={validation.handleBlur}
                                          value={FormData.statusLivraison || ""}
                                        >
                                          {allstatuslivraison.map((item, key) => (
                                              <React.Fragment key={key}>
                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                              </React.Fragment>
                                            ))}
                                          </Input>
                                          {validation.touched.statusLivraison &&
                                            validation.errors.statusLivraison ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.statusLivraison}
                                            </FormFeedback>
                                          ) : null}
                                          {errors.statusLivraison && <p className="text-danger">{errors.statusLivraison}</p>}
                                      </div>
                                    </Col>
                                    <Col lg={6} sm={6}>
                                      <Label for="choices-payment-status">{props.t("Méthode de Paiement")}</Label>
                                      <div className="input-light">
                                        <Input
                                          name="typePaiement"
                                          type="select"
                                          className="bg-light border-0"
                                          id="choices-payment-status"
                                          onChange={handleInputForm}
                                          onBlur={validation.handleBlur}
                                          value={FormData.typePaiement || ""}
                                        >
                                          {paymentdetails.map((item, key) => (
                                              <React.Fragment key={key}>
                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                              </React.Fragment>
                                            ))}
                                          </Input>
                                          {validation.touched.typePaiement &&
                                            validation.errors.typePaiement ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.typePaiement}
                                            </FormFeedback>
                                          ) : null}
                                          {errors.typePaiement && <p className="text-danger">{errors.typePaiement}</p>}
                                      </div>
                                    </Col>

                                    <Col lg={6} sm={6}> 
                                      <div className='mb-2'>
                                        <Label for='echeance'>
                                          {props.t("Echéance")}
                                        </Label>
                                        <Input 
                                          type="date"
                                          className="form-control bg-light border-0"
                                          id="echeance"
                                          name="echeance"
                                          onChange={(e) => {
                                            handleInputForm(e)
                                          }}
                                          value={FormData.echeance || ""}
                                        >
                                        </Input>
                                        {errors.echeance && <p className="text-danger">{props.t(errors.echeance)}</p>}
                                      </div>
                                    </Col>

                                  </Row>
                                </CardBody>
                                
                                <CardBody className="p-4">
                                  <div className="table-responsive">
                                    <Table className="invoice-table table table-borderless mb-0">
                                          <thead className="align-middle">
                                              <tr className="table-active">
                                                  <th scope="col" style={{ width: "30px" }}>
                                                  </th>
                                                  <th scope="col" style={{ width: "230px" }}>{props.t("Désignation")} <span className='text-danger'>*</span></th>
                                                  <th scope="col" style={{ width: "180px" }}>
                                                    {props.t("Prix")} <span className='text-danger'>*</span>
                                                  </th>
                                                  <th scope="col" style={{ width: "100px" }}>
                                                    {props.t("Quantité")} <span className='text-danger'>*</span>
                                                  </th>
                                                  <th scope="col" style={{ width: "180px" }}>
                                                    {props.t("Montant")}
                                                  </th>
                                                  <th scope="col" style={{ width: "20px" }}>
                                                  </th>
                                              </tr>
                                        </thead>

                                      {details.map((item, index) => (
                                        <React.Fragment key={index}>
                                          <tbody name={`productInfo-${index}`} key={index}>
                                                <tr id={index} className="product">
                                                  <th scope="row" className="product-id">
                                                    <Label>{index + 1}</Label>
                                                  </th>
                                                  <td className="text-start">

                                                    <div className="mb-2">

                                                    <Input 
                                                        type="select"
                                                        className="form-control bg-light border-0"
                                                        id={`productName-${index}`}
                                                        name={`product_name-${index}`}
                                                        onChange={(e) => {
                                                          handleSelectedProductPrice(index, e.target.value);
                                                          handleUpdateProduit(index, e.target.value);
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        invalid={validation.touched.produit && validation.errors.produit ? true : false}
                                                      >
                                                        <option value="">{props.t("Sélectionner")}</option>
                                                        {productslist.map((product) => (
                                                          <option
                                                            value={product.id}
                                                            key={product.id}
                                                            selected={details[index].produit === product.id}
                                                          >
                                                            {product.designationProd + ' ' + product.marqueProd}
                                                          </option>
                                                        ))} 
                                                      </Input>
                                                      {validation.touched.produit &&
                                                        validation.errors.produit ? (
                                                        <FormFeedback type="invalid">
                                                          {validation.errors.produit}
                                                        </FormFeedback>
                                                      ) : null}

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <Input
                                                      type="number"
                                                      className="form-control product-price bg-light border-0"
                                                      id={`productRate-${index}`}
                                                      value={productPrices[index] ? productPrice(parseInt(productPrices[index])) : productPrice(item.produit)}
                                                      step="0.01"   
                                                      readOnly
                                                    />
                                                      <div className="invalid-feedback">
                                                        {props.t("Veuillez entrer un prix")}
                                                      </div>
                                                  </td>
                                                  <td>
                                                    <div className="input-step">
                                                    <Input
                                                      type="number"
                                                      className="product-quantity"
                                                      id={`product-qty-${index}`}
                                                      value={item.qteVendu}
                                                      onChange={(e) => handleUpdateQteVendu(index, e.target.value)}
                                                    />
                                                    </div>
                                                  </td>
                                                  <td className="text-end">
                                                    <div>
                                                    <Input
                                                      type="text"
                                                      className="form-control bg-light border-0 product-line-price"
                                                      id={`productPrice-${index}`}
                                                      placeholder="0.00"
                                                      value={Number.isFinite(productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu) ? productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu : 0}
                                                      readOnly
                                                    />
                                                    </div>
                                                  </td>
                                                  <td id={`product-removal-${index}`} className="product-removal">
                                                      <button
                                                        id={`del-${index}`}
                                                        className="btn btn-success"
                                                        onClick={() => {
                                                          removeItem(index);
                                                        }}
                                                      >
                                                        {props.t("Supprimer")}
                                                      </button>
                                                  </td>
                                                </tr>
                                            </tbody>

                                        </React.Fragment>
                                      ))}
                                         <tfoot>
                                        <tr>
                                           <td colSpan="5">
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-secondary fw-medium"
                                                  id="add-item"
                                                  onClick={handleAddItem}
                                                >
                                                  <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                                                  {props.t("Ajouter un produit")}
                                                </button>
                                            </td>
                                          </tr>
                                        <tr className="border-top border-top-dashed mt-2">
                                          <td colSpan="3"></td>
                                          <td colSpan="2" className="p-0">
                                          <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                                          <tbody>
                                          <tr>
                                              <th scope="row">
                                                {props.t("Remise")} <span className='text-muted'>(%)</span>
                                              </th>
                                              <td style={{display: "flex"}}>
                                                <Input
                                                  type="text"
                                                  className="form-control bg-light border-0"
                                                  id="cart-discount"
                                                  style={{width: "60px"}}
                                                  name="discountpercent"
                                                  value={discountpercent}
                                                  onChange={e => setDiscountpercent(e.target.value)}
                                                />
                                                <Input
                                                  type="text"
                                                  className="form-control bg-light border-0"
                                                  id="cart-discount"
                                                  placeholder="0"
                                                  name="remisepercent"
                                                  style={{width: "150px", marginLeft: "10px"}}
                                                  value={(discountpercent/100) * details.map((item, index) => Number.isFinite(productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu) ? productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu : 0).reduce((a, b) => a + b, 0)}
                                                />
                                              </td>
                                            </tr>
                                              <tr className="border-top border-top-dashed">
                                                  <th scope="row">{props.t("Encaissement Reçu")}</th>
                                                  <td>
                                                    <Input
                                                      type="number"
                                                      className="form-control bg-light border-0"
                                                      name="encaissementRecu"
                                                      placeholder="0"
                                                      id="cart-total"
                                                      onChange={handleInputForm}
                                                      value={FormData.encaissementRecu || ''}
                                                    />
                                                    {errors.encaissementRecu && <p className="text-danger">{errors.encaissementRecu}</p>}
                                                  </td>
                                              </tr>
                                                <tr className="border-top border-top-dashed">
                                                  <th scope="row">{props.t("Montant Total")}</th>
                                                  <td>
                                                    <Input
                                                      type="number"
                                                      className="form-control bg-light border-0"
                                                      name="montant"
                                                      id="cart-total"
                                                      onChange={handleInputForm}
                                                      value={ discountpercent === 0 ? details.map((item, index) => Number.isFinite(productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu) ? productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu : 0).reduce((a, b) => a + b, 0) : details.map((item, index) => Number.isFinite(productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu) ? productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu : 0).reduce((a, b) => a + b, 0) - (discountpercent/100) * details.map((item, index) => Number.isFinite(productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu) ? productPrices[index] ? productPrice(parseInt(productPrices[index]))*item.qteVendu : productPrice(item.produit)*item.qteVendu : 0).reduce((a, b) => a + b, 0)}
                                                    />
                                                    {errors.montant && <p className="text-danger">{errors.montant}</p>}
                                                  </td>
                                              </tr>
                                          </tbody>
                                          </Table>
                                          </td> 
                                        </tr>  
                                        </tfoot>
                                                                              
                                    </Table>
                                  </div>
                                  <Row className="mt-3">
                                    <Col lg={4}>
                                      <div className="mb-2">
                                        <Input 
                                         type="select"
                                         className="form-control bg-light border-0"
                                          id="contact"
                                          name="contact"
                                          onChange={handleInputForm}
                                          value={FormData.contact || ""}
                                          onBlur={validation.handleBlur}
                                        >
                                                        <option>{props.t("Sélectionner un client")}</option>
                                                        {contactList.map((item, key)=> ( 
                                                          item.category === "Entreprise" || item.category === "Institution"  || item.category === "Association" || item.category === "Administration" || item.category === "ONG" || item.category === "Particulier" || item.category === "Société" ?
                                                            <option value={item.id} key={key}>{item.category === "Entreprise" || item.category === "Institution"  || item.category === "Association" || item.category === "Administration" || item.category === "ONG" || item.category === "Société" ? item.nomEntreprise + " - " + item.type_contact : item.nom + " - " + item.type_contact}</option> : null
                                                        ))
                                                        }
                                        </Input>
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    <button type="submit" className="btn btn-success">
                                      <i className="ri-printer-line align-bottom me-1"></i> {props.t("Valider")}
                                    </button>
                                  </div>
                                </CardBody>

                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )


}

VenteUpdateVente.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(VenteUpdateVente));
