import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  addNewArrondissement as addNewArrondissementApi,
  deleteArrondissement as deleteArrondissementApi,
  getArrondissement as getArrondissementsApi,
  updateArrondissement as updateArrondissementApi,
} from "../../../helpers/fakebackend_helper";
import {
  addNewArrondissementsFail,
  addNewArrondissementsSuccess,
  arrondissementApiResponseError,
  arrondissementApiResponseSuccess,
  deleteArrondissementsFail,
  deleteArrondissementsSuccess,
  updateArrondissementsFail,
  updateArrondissementsSuccess,
} from "./action";
import {
  ADD_NEW_ARRONDISSEMENT,
  DELETE_ARRONDISSEMENT,
  GET_ARRONDISSEMENTS,
  UPDATE_ARRONDISSEMENT,
} from "./actionType";

function* getArrondissements() {
  try {
    const response = yield call(getArrondissementsApi);
    yield put(arrondissementApiResponseSuccess(GET_ARRONDISSEMENTS, response));
  } catch (error) {
    yield put(arrondissementApiResponseError(GET_ARRONDISSEMENTS, error));
  }
}

function* addNewArrondissement(action) {
  try {
    const response = yield call(addNewArrondissementApi, action.payload);
    yield put(addNewArrondissementsSuccess(response));
    toast.success("Arrondissement ajouté avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(addNewArrondissementsFail(error));
    toast.error("Erreur lors de l'ajout de l'arrondissement", {
      autoclose: 3000,
    });
  }
}

function* updateArrondissement(action) {
  try {
    const response = yield call(updateArrondissementApi, action.payload);
    yield put(updateArrondissementsSuccess(response));
    toast.success("Arrondissement modifié avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(updateArrondissementsFail(error));
    toast.error("Erreur lors de la modification de l'arrondissement", {
      autoclose: 3000,
    });
  }
}

function* deleteArrondissement(action) {
  try {
    const response = yield call(deleteArrondissementApi, action.payload);
    yield put(deleteArrondissementsSuccess(response));
    toast.success("Arrondissement supprimé avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(deleteArrondissementsFail(error));
    toast.error("Erreur lors de la suppression de l'arrondissement", {
      autoclose: 3000,
    });
  }
}

export function* watchGetArrondissements() {
  yield takeEvery(GET_ARRONDISSEMENTS, getArrondissements);
}

export function* watchAddNewArrondissement() {
  yield takeEvery(ADD_NEW_ARRONDISSEMENT, addNewArrondissement);
}

export function* watchUpdateArrondissement() {
  yield takeEvery(UPDATE_ARRONDISSEMENT, updateArrondissement);
}

export function* watchDeleteArrondissement() {
  yield takeEvery(DELETE_ARRONDISSEMENT, deleteArrondissement);
}

function* arrondissementsSaga() {
  yield all([
    fork(watchGetArrondissements),
    fork(watchAddNewArrondissement),
    fork(watchUpdateArrondissement),
    fork(watchDeleteArrondissement),
  ]);
}

export default arrondissementsSaga;
