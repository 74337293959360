import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Alert,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Select,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { addProductsCategories ,addProductsCategoriesFail} from "../../../store/ecommerce/action";


import {  useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const EcommerceAddCategories = (props) => {

  const { error} = useSelector(state => ({
    error: state.Ecommerce.error
}));

  
  document.title = "Creation de catégories d'articles";

  const history = useHistory();
  const dispatch = useDispatch();

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  
  const DataUserType = [
    {
      label: "Particulier",
      value: "particular",
    },
    {
      label: "Professionnel du BTP",
      value: "professional",
    },
    {
      label: "Entreprise",
      value: "enterprise",
    },
    {
      label: "Organisme",
      value: "organism",
    },
  ];

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://backend.leconseillerdubtp.com/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [category,setCategoryList]=useState([]);
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/categories/`);
          setCategoryList(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "designation":null,
      "user_type":null
    },
    
    validationSchema: Yup.object().shape({
      designation: Yup.string().required(props.t("Entrer une catégorie")),
      user_type: Yup.string().required(props.t("choisissez le type d'utilisateur")),
    }),

    onSubmit: (values) => {
      const newCategory={
        designation:values.designation,
        user_type:values.user_type
      }
      var count=0;
        if (newCategory){
          for (let i=0; i<=category.length;i++){
            if(newCategory.designation=== category[i]?.designation){
              count= count+1;
              history.push("apps-categories");
              toast.error(props.t("La categorie existe deja"), { autoClose: 3000 });
              break;
            }
          }
          if (count===0){
            axios.post(`${url}/categories/`,newCategory)
              .then(res => {
              if(res){
                toast.success(props.t("Catégorie bien ajoutée"), { autoClose: 3000 })
              }
            }, (error)=>{
              if(error){
                toast.error(props.t("Catégorie non ajoutée"), { autoClose: 3000 })
              }
            }
            )
            validation.resetForm();
            history.push("apps-categories")
            setTimeout(()=>window.location.reload(true),2000);
          }else{
            count=0;
          }

        }
  } 
  });
    useEffect(() => {
      dispatch(addProductsCategoriesFail(""));
  }, [dispatch]);
  
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.t("Création de catégorie")} pageTitle={props.t("Catégorie")} />

        <Row>
          <Col lg={12}>
            <Form encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit(); 
              }}>
                
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="designation">
                      {props.t("Titre de la catégorie")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      placeholder={props.t("Entrer un nom")}
                      name="designation"
                      value={validation.values.designation}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    />
                    {validation.errors.designation && validation.touched.designation? (
                      <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
                    ) : null}
                  </div>
                  
                  <Row>
                    <Col xl={12}>
                      <div className="mb-3">
                        <Label className="form-label"> {props.t("Type d'utilisateur")}  <span style={{color: "red"}}>*</span></Label>
                        <Input
                          className="form-control"
                          name="user_type"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          required
                        >
                          <option value="">Sélectionner le type d'utilisateur</option>
                            {DataUserType.map((item,key)=> (
                                <option value={item.value} key={key}>{item.label}</option>
                            ))
                            }
                        </Input>
                        {validation.errors.user_type && validation.touched.user_type? (
                          <FormFeedback type="invalid">{validation.errors.user_type}</FormFeedback>
                          ) : null
                        }
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
              <button className="btn btn-primary mr-3">
                  <Link
                    to={`apps-categories`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm m-2">
                 {props.t("Ajouter")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

EcommerceAddCategories.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(EcommerceAddCategories));
