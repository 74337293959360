import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { object, string } from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  getArrondissements,
  getCities,
  updateQuartier,
} from "../../../store/actions";
import { axiosIns } from "../../../utils";

const UpdateQuartier = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [quartier, setQuartier] = useState({});
  const [arrondissement, setArrondissement] = useState([]);
  const { arrondissement: arrondissementList } = useSelector((state) => ({
    arrondissement: state.Arrondissement.arrondissements,
  }));

  const handleGetQuartier = async () => {
    try {
      const response = await axiosIns.get(`quartiers/${id}/`);
      if (response.status === 200) {
        setQuartier(response.data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getCities());
    dispatch(getArrondissements());
    handleGetQuartier();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(arrondissementList)) {
      setArrondissement(arrondissementList);
    }
  }, [arrondissementList]);

  document.title = props.t("Modification d'un quartier - Le Conseiller du BTP");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: quartier?.name || "",
      arrondissement: quartier?.arrondissement || "",
    },

    validationSchema: object().shape({
      name: string().required(props.t("Veuillez entrer le nom du quartier")),
      arrondissement: string().required(
        props.t("Veuillez choisir un arrondissement")
      ),
    }),

    onSubmit: (values) => {
      const updatedQuartier = {
        ...quartier,
        name: values.name,
        arrondissement: values.arrondissement,
      };
      dispatch(updateQuartier(updatedQuartier));
      history.push("/apps-geolocalisation-quartier");
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Ajout d'un quartier")}
          pageTitle={props.t("Quartier")}
        />

        <Row>
          <Col lg={12}>
            <Form
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="arrondissement">
                      {props.t("Arrondissement")}
                    </Label>
                    <Input
                      type="select"
                      className="form"
                      placeholder={props.t("Entrer le nom de l'arrondissement")}
                      name="arrondissement"
                      value={validation.values.arrondissement}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    >
                      <option value="">
                        {props.t("Choisir un arrondissement")}
                      </option>
                      {arrondissement.map((p) => (
                        <option key={p.id} value={p.id}>
                          {p.name}
                        </option>
                      ))}
                    </Input>
                    {validation.errors.arrondissement &&
                    validation.touched.arrondissement ? (
                      <FormFeedback type="invalid">
                        {validation.errors.arrondissement}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="name">
                      {props.t("Nom du quartier")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      placeholder={props.t("Entrer le nom du quartier")}
                      name="name"
                      value={validation.values.name}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-3">
                  <Link
                    to={`/apps-geolocalisation-quartier`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm m-2">
                  {props.t("Valider")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

UpdateQuartier.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UpdateQuartier));
