import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  UncontrolledCollapse,
  CardFooter,
  Button
} from "reactstrap";
import axios from "axios";
import { useEffect,useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const LeadDiscover = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://back.crm.inawo.pro/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;


  const [selectContact, setSelectContact] = useState([])
  const [selectCollaborateur, setSelectCollaborateur] = useState([])
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/contacts`);
          setSelectContact(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/collaborateurs`);
          setSelectCollaborateur(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

    
  const ConId = (j)=> {
    for(let i=0;i<=selectContact.length; i++){
      if( j===selectContact[i]?.id){
       return (selectContact[i]?.nomEntreprise) ;
      }
      else{ return "Entreprise non renseignée"}
    }
  }

  const ResponId = (j)=> {
    for(let i=0;i<=selectCollaborateur.length; i++){
      if( j===selectCollaborateur[i]?.id){
       return (selectCollaborateur[i]?.nom) ;
      }
    }
  }

  

       // Récuperer le type d'abonnement
       const [pays, setPays] = useState("")
       useEffect(() => {
         const checkPaysValidity = async () => {
             try {
             const response = await axios.get("https://back.crm.inawo.pro/api/v1/profile/");
             const currentPays = response.pays
             setPays(currentPays)
             } catch (error) {
             console.log(error)
             } 
         };
         checkPaysValidity();
       }, []);

         console.log("ss",pays)
  const [currency, setCurrency] = useState('');
  
  useEffect(() => {
   
    const fetchCurrency = async () => {
      try {
        const response = await fetch(`https://restcountries.com/v3.1/name/${pays}`);
        const data = await response.json();
        const currencies = data[0]?.currencies;

        if (currencies) {
          const currencyCodes = Object.keys(currencies);
          setCurrency(currencyCodes.join(', '));
        } else {
          setCurrency(' ');
        }
      } catch (error) {
        console.error(error);
        setCurrency('Error fetching currency');
      }
    };
    fetchCurrency();
  }, [pays]);
  console.log("currency",currency)

  const [selectOpportunite, setSelectOpportunite] = useState([])
  const [subItems, setSubItems] = useState([])
 
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/opportunities`);
          setSelectOpportunite(data)
          setSubItems(selectOpportunite)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url,selectOpportunite]);


    function lead() {
      const leadNumber=[] ;
      const leadValue=[] ;
      for (let i = 0; i < selectOpportunite.length; i++) {
        if (selectOpportunite[i].type && selectOpportunite[i].type==="lead discovered") {
          leadNumber.push(selectOpportunite[i].type);
          leadValue.push(selectOpportunite[i].value) ;
        }
      }
      const somme = leadValue.reduce((acc, montant) => acc + montant, 0);
      return (<div> {somme}{currency} {"   "}{leadNumber.length} Opportunité(s)</div>)
    }

    function meeting() {
      const meetingNumber=[];
      const meetingValue=[];
      for (let i = 0; i < selectOpportunite.length; i++) {
        if (selectOpportunite[i].type && selectOpportunite[i].type==="meeting arranged") {
          meetingNumber.push(selectOpportunite[i].type) 
          meetingValue.push(selectOpportunite[i].value) ;
        }
      }
      const somme = meetingValue.reduce((acc, montant) => acc + montant, 0);
      return  (<div> {somme}{currency} {"   "}{meetingValue.length} Opportunité(s) </div>)
    }

    function contact() {
      const contactNumber=[];
      const contactValue=[];
      for (let i = 0; i < selectOpportunite.length; i++) {
        if (selectOpportunite[i].type && selectOpportunite[i].type==="contact initiated") {
          contactNumber.push(selectOpportunite[i].type) 
          contactValue.push(selectOpportunite[i].value) 
        }
      }
      const somme = contactValue.reduce((acc, montant) => acc + montant, 0);
      return  (<div> {somme}{currency} {"   "}{contactValue.length} Opportunité(s) </div>)
    }

    function need() {
      const needNumber=[];
      const needValue=[];
      for (let i = 0; i < selectOpportunite.length; i++) {
        if (selectOpportunite[i].type && selectOpportunite[i].type==="need identified") {
          needNumber.push(selectOpportunite[i].type);
          needValue.push(selectOpportunite[i].value);
        }
      }
      const somme = needValue.reduce((acc, montant) => acc + montant, 0);
      return  (<div> {somme}{currency} {" "}{needValue.length} Opportunité(s) </div>)
    }

    function offer() {
      const offerNumber=[];
      const offerValue=[];
      for (let i = 0; i < selectOpportunite.length; i++) {
        if (selectOpportunite[i].type && selectOpportunite[i].type==="offer accepted") {
          offerNumber.push(selectOpportunite[i].type);
          offerValue.push(selectOpportunite[i].value);
        }
      }
      const somme = offerValue.reduce((acc, montant) => acc + montant, 0);
      return  (<div> {somme}{currency} {" "}{offerValue.length} Opportunité(s) </div>)
    }

    const opportunitesId = useParams('id');
    
    const [FormDataList, setFormDataList] = useState({
      "type": null,
      "title": "",
      "value": null,
      "date": null,
      "description": "",
      "owner": null,
      "contact": null,
    });
      useEffect(() => {
          const fetchData = async ()=> {
              try {
                  const data = await axios.get(`${url}/opportunities/${opportunitesId.id}`);
                  setFormDataList(data);                  
                  console.log("resultat", data)
              } catch (err) {
                  console.log(err)
              }
          };
          fetchData(); 
      }, [opportunitesId, url])


  return (
    <div className="col">
      <Card>
        <Link
          to="#"
          className={"card-header bg-soft-" + props.deal.color}
          id={"leadDiscovered" + props.index}
        >
          <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15">
            {props.deal.label}
          </h5>
          <p className="text-muted mb-0">
            {" "}</p> <span className="fw-medium">{
              props.deal.value==="lead discovered"?
              <p>{lead()}</p>:
              props.deal.value==="contact initiated" ?
              <p>{contact()}</p>:
              props.deal.value==="need identified" ?
              <p>{need()}</p>:
              props.deal.value==="meeting arranged" ?
              <p>{meeting()}</p>:
             props.deal.value==="offer accepted" ?
              <p>{offer()}</p>: null
            } </span>
        </Link>
      </Card>
    
      <UncontrolledCollapse
        toggler={"#leadDiscovered" + props.index}
        defaultOpen={true}
      >
        {subItems.map((subitem, subitemkey) => (
         
          <React.Fragment key={subitemkey}>
            { subitem.type==='meeting arranged' && props.deal.value==='meeting arranged' ?
              <Card
              className={
                subitem.isRibbon
                  ? "mb-1 ribbon-box ribbon-fill ribbon-sm"
                  : "mb-1"
              }
            >
              {subitem.isRibbon && (
                <div className="ribbon ribbon-info">
                  <i className="ri-flashlight-fill"></i>
                </div>
              )}
              <CardBody>
                <Link
                  to="#"
                  className="d-flex align-items-center"
                  id={"leadInnerDiscovered" + props.index + subitemkey}
                >
                  <div className="flex-shrink-0">
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="fs-14 mb-1">{subitem.title}</h6>
                    <p className=" mb-0">
                      {subitem.value} {currency}- {subitem.date}
                    </p>
                  </div>
                </Link>
              </CardBody>
              <UncontrolledCollapse
                className="border-top border-top-dashed"
                toggler={"#leadInnerDiscovered" + props.index + subitemkey}
                defaultOpen={subitem.isOpen === true ? true : false}
              >
                <CardBody>
                  <h6 className="fs-14 mb-1">
                    {subitem.subTitle}{" "}
                    <small
                      className={
                        "badge badge-soft-" + subitem.color
                      }
                    >
                      
                    </small>
                  </h6>
                  <p className="text-muted">
                  <h6 className="fs-14 mb-1">{ConId(subitem.contact)}</h6>
                    {subitem.description}
                  </p>

                </CardBody>
                <CardFooter className="hstack gap-2">
                <Button color="secondary" className="btn-sm w-100">
                    <i className="ri-phone-line align-bottom me-1"></i> Appeler
                  </Button>
                  <Button color="warning" className="btn-sm w-100">
                    
                  <Link to={`/apps-opportunites-update/${subitem.id}/`} className="text-light d-inline-block">
                    <i className="ri-pencil-line align-bottom me-1"></i> Modifier 
                  </Link>
                    
                  </Button>
                  
                </CardFooter>
              </UncontrolledCollapse>
            </Card>
            :subitem.type==='contact initiated' && props.deal.value==='contact initiated' ?
            <Card
            className={
              subitem.isRibbon
                ? "mb-1 ribbon-box ribbon-fill ribbon-sm"
                : "mb-1"
            }
          >
            {subitem.isRibbon && (
              <div className="ribbon ribbon-info">
                <i className="ri-flashlight-fill"></i>
              </div>
            )}
            <CardBody>
              <Link
                to="#"
                className="d-flex align-items-center"
                id={"leadInnerDiscovered" + props.index + subitemkey}
              >
                <div className="flex-shrink-0">
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="fs-14 mb-1">{subitem.title}</h6>
                  <p className=" mb-0">
                    {subitem.value} {currency} - {subitem.date}
                  </p>
                </div>
              </Link>
            </CardBody>
            <UncontrolledCollapse
              className="border-top border-top-dashed"
              toggler={"#leadInnerDiscovered" + props.index + subitemkey}
              defaultOpen={subitem.isOpen === true ? true : false}
            >
              <CardBody>
                <h6 className="fs-14 mb-1">
                  {subitem.subTitle}{" "}
                  <small
                    className={
                      "badge badge-soft-" + subitem.color
                    }
                  >
                    
                  </small>
                </h6>
                <p className="text-muted">
                  <h6 className="fs-14 mb-1">{ConId(subitem.contact)}</h6>
                    {subitem.description}
                  </p>
                
              </CardBody>
              <CardFooter className="hstack gap-2">
              <Button color="secondary" className="btn-sm w-100">
                    <i className="ri-phone-line align-bottom me-1"></i> Appeler
                  </Button>
              <Button color="warning" className="btn-sm w-100">
                    
                    <Link to={`/apps-opportunites-update/${subitem.id}/`} className="text-light d-inline-block">
                      <i className="ri-pencil-line align-bottom me-1"></i> Modifier 
                    </Link>
                      
              </Button>
               
              </CardFooter>
            </UncontrolledCollapse>
          </Card>:subitem.type==='need identified' && props.deal.value==='need identified' ?
              <Card
              className={
                subitem.isRibbon
                  ? "mb-1 ribbon-box ribbon-fill ribbon-sm"
                  : "mb-1"
              }
            >
              {subitem.isRibbon && (
                <div className="ribbon ribbon-info">
                  <i className="ri-flashlight-fill"></i>
                </div>
              )}
              <CardBody>
                <Link
                  to="#"
                  className="d-flex align-items-center"
                  id={"leadInnerDiscovered" + props.index + subitemkey}
                >
                  <div className="flex-shrink-0">
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="fs-14 mb-1">{subitem.title}</h6>
                    <p className="mb-0">
                      {subitem.value} {currency} - {subitem.date}
                    </p>
                  </div>
                </Link>
              </CardBody>
              <UncontrolledCollapse
                className="border-top border-top-dashed"
                toggler={"#leadInnerDiscovered" + props.index + subitemkey}
                defaultOpen={subitem.isOpen === true ? true : false}
              >
                <CardBody>
                  <h6 className="fs-14 mb-1">
                    {subitem.subTitle}{" "}
                    <small
                      className={
                        "badge badge-soft-" + subitem.color
                      }
                    >
                      
                    </small>
                  </h6>
                  <p className="text-muted">
                  <h6 className="fs-14 mb-1">{ConId(subitem.contact)}</h6>
                    {subitem.description}
                  </p>
                </CardBody>
                <CardFooter className="hstack gap-2">
                <Button color="secondary" className="btn-sm w-100">
                    <i className="ri-phone-line align-bottom me-1"></i> Appeler
                  </Button>
                <Button color="warning" className="btn-sm w-100">
                    
                  <Link to={`/apps-opportunites-update/${subitem.id}/`} className="text-light d-inline-block">
                    <i className="ri-pencil-line align-bottom me-1"></i> Modifier 
                  </Link>
                    
                </Button>
                </CardFooter>
              </UncontrolledCollapse>
            </Card>:subitem.type==='offer accepted' && props.deal.value==='offer accepted' ?
              <Card
              className={
                subitem.isRibbon
                  ? "mb-1 ribbon-box ribbon-fill ribbon-sm"
                  : "mb-1"
              }
            >
              {subitem.isRibbon && (
                <div className="ribbon ribbon-info">
                  <i className="ri-flashlight-fill"></i>
                </div>
              )}
              <CardBody>
                <Link
                  to="#"
                  className="d-flex align-items-center"
                  id={"leadInnerDiscovered" + props.index + subitemkey}
                >
                  <div className="flex-shrink-0">
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="fs-14 mb-1">{subitem.title}</h6>
                    <p className=" mb-0">
                      {subitem.value} {currency} - {subitem.date}
                    </p>
                  </div>
                </Link>
              </CardBody>
              <UncontrolledCollapse
                className="border-top border-top-dashed"
                toggler={"#leadInnerDiscovered" + props.index + subitemkey}
                defaultOpen={subitem.isOpen === true ? true : false}
              >
                <CardBody>
                  <h6 className="fs-14 mb-1">
                    {subitem.subTitle}{" "}
                    <small
                      className={
                        "badge badge-soft-" + subitem.color
                      }
                    >
                      
                    </small>
                  </h6>
                  <p className="text-muted">
                  <h6 className="fs-14 mb-1">{ConId(subitem.contact)}</h6>
                    {subitem.description}
                  </p>
                  
                </CardBody>
                <CardFooter className="hstack gap-2">
                <Button color="secondary" className="btn-sm w-100">
                    <i className="ri-phone-line align-bottom me-1"></i> Appeler
                  </Button>
                <Button color="warning" className="btn-sm w-100">
                    
                    <Link to={`/apps-opportunites-update/${subitem.id}/`} className="text-light d-inline-block">
                      <i className="ri-pencil-line align-bottom me-1"></i> Modifier 
                    </Link>
                      
                </Button>
                </CardFooter>
              </UncontrolledCollapse>
            </Card>:subitem.type==='lead discovered' && props.deal.value==='lead discovered' ?
              <Card
              className={
                subitem.isRibbon
                  ? "mb-1 ribbon-box ribbon-fill ribbon-sm"
                  : "mb-1"
              }
            >
              {subitem.isRibbon && (
                <div className="ribbon ribbon-info">
                  <i className="ri-flashlight-fill"></i>
                </div>
              )}
              <CardBody>
                <Link
                  to="#"
                  className="d-flex align-items-center"
                  id={"leadInnerDiscovered" + props.index + subitemkey}
                >
                  <div className="flex-shrink-0">
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="fs-14 mb-1">{subitem.title}</h6>
                    <p className="mb-0">
                      {subitem.value} {currency} - {subitem.date}
                    </p>
                  </div>
                </Link>
              </CardBody>
              <UncontrolledCollapse
                className="border-top border-top-dashed"
                toggler={"#leadInnerDiscovered" + props.index + subitemkey}
                defaultOpen={subitem.isOpen === true ? true : false}
              >
                <CardBody>
                  <h6 className="fs-14 mb-1">
                    {subitem.subTitle}{" "}
                    <small
                      className={
                        "badge badge-soft-" + subitem.color
                      }
                    >
                      
                    </small>
                  </h6>
                  <p className="text-muted">
                  <h6 className="fs-14 mb-1">{ConId(subitem.contact)}</h6>
                    {subitem.description}
                  </p>
                  
                </CardBody>
                <CardFooter className="hstack gap-2">
                <Button color="secondary" className="btn-sm w-100">
                    <i className="ri-phone-line align-bottom me-1"></i> Appeler
                </Button>
                <Button color="warning" className="btn-sm w-100">
                    
                    <Link to={`/apps-opportunites-update/${subitem.id}/`} className="text-light d-inline-block">
                      <i className="ri-pencil-line align-bottom me-1"></i> Modifier 
                    </Link>
                      
                </Button>
                </CardFooter>
              </UncontrolledCollapse>
            </Card>:null
          
            }
            
          </React.Fragment>
        ))}
      </UncontrolledCollapse>
    </div>
  );
};

export default LeadDiscover;
