import {
  ADD_NEW_PROVINCE,
  ADD_PROVINCE_FAIL,
  ADD_PROVINCE_SUCCESS,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  DELETE_PROVINCE,
  DELETE_PROVINCE_FAIL,
  DELETE_PROVINCE_SUCCESS,
  GET_PROVINCE,
  UPDATE_PROVINCE,
  UPDATE_PROVINCE_FAIL,
  UPDATE_PROVINCE_SUCCESS,
} from "./actionType";

// common success
export const provinceApiResponseSuccess = (actionType, response) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, response },
});

//common error
export const provinceApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getProvince = () => ({
  type: GET_PROVINCE,
});

export const addNewProvince = (province) => ({
  type: ADD_NEW_PROVINCE,
  payload: province,
});

export const addNewProvinceSuccess = (province) => ({
  type: ADD_PROVINCE_SUCCESS,
  payload: province,
});

export const addNewProvinceFail = (error) => ({
  type: ADD_PROVINCE_FAIL,
  payload: error,
});

export const updateProvince = (province) => ({
  type: UPDATE_PROVINCE,
  payload: province,
});

export const updateProvinceSuccess = (province) => ({
  type: UPDATE_PROVINCE_SUCCESS,
  payload: province,
});

export const updateProvinceFail = (error) => ({
  type: UPDATE_PROVINCE_FAIL,
  payload: error,
});

export const deleteProvince = (province) => ({
  type: DELETE_PROVINCE,
  payload: province,
});

export const deleteProvinceSuccess = (province) => ({
  type: DELETE_PROVINCE_SUCCESS,
  payload: province,
});

export const deleteProvinceFail = (error) => ({
  type: DELETE_PROVINCE_FAIL,
  payload: error,
});
