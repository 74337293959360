import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHearder,
    Nav,
    NavItem,
    NavLink,
    Row,
    Modal,
    ModalHeader,
    Form,
    ModalBody,
    Label,
    Input,
    FormFeedback,
    CardHeader,
   } from 'reactstrap';
   import * as moment from 'moment';
   import { Link, withRouter } from "react-router-dom";
   import Select from "react-select";
   import classnames from "classnames";
   import Flatpickr from "react-flatpickr";
   import BreadCrumb
    from '../../../Components/Common/BreadCrumb';
  import TableContainer from '../../../Components/Common/TableContainer';
  import DeleteModal  from '../../../Components/Common/DeleteModal';
  import { isEmpty } from "lodash";
  import { axiosIns } from '../../../utils';
  // Formik
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// redux
import { useSelector, useDispatch } from "react-redux";

import { useReducer } from 'react';

//import Actions
import {
    getDocuments as onGetDocuments,
    deleteDocument as onDeleteDocument,
} from "../../../store/documents/action"

import {
    getventes as onGetVentes,
  } from "../../../store/ventes/action";

import Loader from '../../../Components/Common/Loader';
import { ToastContainer } from 'react-toastify';
import { getContacts as onGetContacts } from "../../../store/crm/action";
import 'react-toastify/dist/ReactToastify.css';

import ExportCSVModal from '../../../Components/Common/ExportCSVModal';

const BonCommande = (props) => {
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const [docList, setDocList] = useState([]);
    const [doc, setDoc] = useState(null);
    const [selectedMulti, setSelectedMulti] = useState(null);

    const { crmcontacts } = useSelector((state) =>({
        crmcontacts: state.Crm.crmcontacts,
    }))

    const [isEdit, setIsEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);

    const [contactList, setContactList] = useState([]);

    const [venteList, setVenteList] = useState([]);

    const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;

    useEffect(() => {
        const fetchBC = async () => {
          try {
            const { data } = await axiosIns.get(`/commandes/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            console.log("bc",data);
            setDocList(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchBC();
        }, [token]);
    
        const CrmCon = useCallback((j) => {
          for (let i = 0; i < contactList.length; i++) { // Changed <= to <
            if (j === contactList[i]?.id) {
              return contactList[i]?.nom === "" ? contactList[i]?.nomEntreprise : contactList[i]?.nom;
            }
          }
        }, [contactList]); // Add contactList as a dependency for useCallback

        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
          // Simulate an asynchronous operation (e.g., fetching data)
          setTimeout(() => {
            setIsLoading(false); // Set loading to false once the data is fetched or loaded
          }, 2000); // Adjust the time according to your needs
        }, []); 

        const [realCommande, setRealCommande] = useState([]);
        useEffect(() => {
          if (!isEmpty(docList)) {
            const updatedCommande = docList.map(avoir => ({
              ...avoir,
              contact: CrmCon(parseInt(avoir.contact))
            }));
            setRealCommande(updatedCommande);
          }
        }, [docList, contactList, CrmCon]);
        
        console.log("details", realCommande);  

    useEffect(() => {
        const fetchProducts = async () => {
          try {
            const { data } = await axiosIns.get(`/contacts/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            console.log("bella",data);
            setContactList(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchProducts();
        }, [token]);


      const venteCon = (j)=> {
        for(let i = 0;i<=venteList.length; i++){
          if( j === venteList[i]?.id){
           return (venteList[i]?.numVente) ;
          }
        }
      }
      console.log("tet", venteCon(1));

    const ConId = (j)=> {
      for(let i=0;i<=contactList.length; i++){
        if( j===contactList[i]?.nom){
          return (contactList[i]?.id) ;
        }
      }
    }


    const columns = useMemo(
        () => [
            {
                Header: props.t("No."),
                Cell: (doc) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <h5 className="fs-14 mb-1">
                                    {doc.row.original.numCommande}
                                </h5>
                            </div>
                        </div>
                    </>
                ),
                filterable: false,
            },
            {
                Header: props.t("Fournisseurs"),
                accessor: "contact",
                Cell: (doc) => (
                    <>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                            {" "}
                            <Link to={`/apps-crm-contact/${ConId(doc.row.original.contact)}`}>
                                { doc.row.original.contact}
                            </Link>
                        </h5>
                      </div>
                    </div>
                    </>
                  ),
                  filterable: false,
            },
            {
                Header: props.t("Date"),
                Cell: (doc) => (
                    <>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                            {" "}
                            { moment(doc.row.original.dateCommande).format('YYYY-MM-DD') }
                        </h5>
                      </div>
                    </div>
                    </>
                  ),
                  filterable: false,
            },
            {
                Header: props.t("Montant"),
                Cell: (doc) => (
                    <>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                            {" "}
                            { doc.row.original.montant }
                        </h5>
                      </div>
                    </div>
                    </>
                  ),
                  filterable: false,
            },
            {
                Header: props.t("Mode de paiement"),
                accessor: "modePaiement",
                Cell: (doc) => (
                    <>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1">
                                {" "}
                                { props.t(doc.row.original.modePaiement) }
                            </h5>
                        </div>
                    </div>
                    </>
                )
            },
            {
                Header: props.t("Actions"),
                Cell: (cellProps) => {
                  const docData = cellProps.row.original.id;
                  return (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li className="liste-inline-item" title={props.t("Voir")}>
                            <Link to={`/apps-documents-bcommande-view/${docData}`} className="text-warning d-inline-block">
                                <i className="ri-eye-fill align-bottom"></i>
                            </Link>
                        </li>
                        {/* <li className="liste-inline-item" title={props.t("télécharger")}>
                            <Link to="#" className="text-warning d-inline-block">
                                <i className="ri-download-2-fill align-bottom"></i>
                            </Link>
                        </li>       */}
                   </ul>
                  );
                },
              },
        ], []
    )

    document.title = props.t("Bons de Commande | Le Conseiller du BTP");

    return (
        <div className="page-content">
            <ToastContainer closeButton={false} />
            <DeleteModal
            show={deleteModal}
            onCloseClick={() => setDeleteModal(false)}
            />
            <DeleteModal
            show={deleteModalMulti}
            onDeleteClick={
                () => {
                    setDeleteModalMulti(false);
                }}
            onCloseClick={() => setDeleteModalMulti(false)}
            />
            <Container fluid>
                <BreadCrumb title={props.t("Bons de Commande")} />
                <Row>
                    <Col lg={12}>
                        <Card id="orderList">
                            <CardHeader className='card-hearder border-0'>
                            <div className="d-flex.align-items-center">
                                <div></div>
                                <button
                                    className="btn btn-info add-btn"
                                    onClick={() => {
                                    setModal(true);
                                    }}
                                ><Link to={`/apps-documents-bcommande-add`} className="text-white d-inline-block">
                                    <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter un bon de commande")}</Link>
                                </button>
                            </div>
                            </CardHeader>
                            </Card>
                            <Card id='orderList'>
                            <CardBody className='pt-0'>
                                <div>
                                    { isLoading ? ( // If loading, show loading indicator
                                      <div className="py-4 text-center">
                                        <div>
                                          <lord-icon
                                              src="https://cdn.lordicon.com/xjovhxra.json"
                                              trigger="loop"
                                              colors="primary:#0960b6,secondary:#02376b"
                                              style={{ width: "72px", height:"72px" }}>
                                          </lord-icon>
                                        </div>
                                        <div className="mt-4">
                                          <h5>{props.t("Chargement")}...</h5>
                                        </div>
                                      </div>
                                    ) 
                                    :
                                    realCommande && realCommande.length > 0 ? (
                                        <TableContainer
                                            filter
                                            columns={columns}
                                            data={realCommande || []}
                                            isGlobalFilter={true}
                                            isAddUserList = {false}
                                            customPageSize={10}
                                            divClass="table-responsive mb-1"
                                            tableClass="mb-0 align-middle table-borderless"
                                            theadClass="table-light text-muted"
                                        />) : (
                                            <div className="py-4 text-center">
                                                <div className="mt-4">
                                                    <h5>{props.t("Désolé! aucun résultat trouvé")}</h5>
                                                </div>
                                            </div>
                                        )}

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

BonCommande.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(BonCommande));
