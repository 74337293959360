import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { getArrondissements, getCities } from "../../../store/actions";
import useDesignation from "../../../utils/renderDesignation";

const Arrondissement = (props) => {
  const dispatch = useDispatch();
  const [arrondissement, setArrondissement] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const { arrondissement: arrondissementList, city: cityList } = useSelector(
    (state) => ({
      arrondissement: state.Arrondissement.arrondissements,
      city: state.City.cities,
    })
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleGetData = async () => {
    dispatch(getArrondissements());
    dispatch(getCities());
  };

  useEffect(() => {
    handleGetData();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(arrondissementList)) {
      setIsLoading(false);
      setArrondissement(arrondissementList);
    }
  }, [arrondissementList]);

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (arrondissement) => {
          return (
            <input
              type="checkbox"
              className="entrepriseCheckBox"
              value={arrondissement.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: props.t("ARRONDISSEMENT"),
        accessor: "name",
        Cell: (arrondissement) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <p className="fs-14 mb-1">{arrondissement.row.original.name}</p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("VILLE"),
        Cell: (arrondissement) => {
          const designation = useDesignation(
            cityList,
            arrondissement.row.original.city
          );
          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className="fs-14 mb-1">{arrondissement.row.original.city ? arrondissement.row.original.city : "-"}</p>
                </div>
              </div>
            </>
          );
        },
      },

      {
        Header: props.t("PROVINCE"),
        Cell: (arrondissement) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className="fs-14 mb-1">{arrondissement.row.original.province ? arrondissement.row.original.province : "-"}</p>
                </div>
              </div>
            </>
          );
        },
      },

      {
        Header: props.t("PAYS"),
        Cell: (arrondissement) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className="fs-14 mb-1">{arrondissement.row.original.pays ? arrondissement.row.original.pays : "-"}</p>
                </div>
              </div>
            </>
          );
        },
      },

      {
        Header: "ACTIONS",
        Cell: (arrondissement) => {
          const { id } = arrondissement.row.original;
          return (
            <li className="list-inline-item" title={props.t("Modifier")}>
              <Link
                to={`/apps-geolocalisation-arrondissement-update/${id}/`}
                className="text-warning d-inline-block"
              >
                <i className="ri-pencil-fill align-bottom" />{" "}
              </Link>
            </li>
          );
        },
      },
    ],
    [arrondissementList, cityList]
  );

  const handleDeleteArrondissement = () => {
    if (arrondissement) {
      // supprimer le pays
      setDeleteModal(false);
    }
  };

  document.title = props.t("Arrondissement - Le Conseiller du BTP");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteArrondissement}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <BreadCrumb
          title={props.t("Arrondissement")}
          pageTitle={props.t("Liste des arrondissements")}
        />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <div className="d-flex.align-items-center">
                  <div></div>
                  <button className="btn btn-info add-btn">
                    <Link
                      to={`/apps-geolocalisation-arrondissement-add`}
                      className="text-white d-inline-block"
                    >
                      <i className="ri-add-fill me-1 align-bottom" />{" "}
                      {props.t("Ajouter un arrondissement")}{" "}
                    </Link>
                  </button>
                </div>
              </CardHeader>
            </Card>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {isLoading ? (
                    // If loading, show loading indicator
                    <div className="py-4 text-center">
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/xjovhxra.json"
                          trigger="loop"
                          colors="primary:#0960b6,secondary:#02376b"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                      </div>
                      <div className="mt-4">
                        <h5>{props.t("Chargement")}...</h5>
                      </div>
                    </div>
                  ) : arrondissement && arrondissement.length > 0 ? (
                    // If no arrondissement, show empty message
                    <TableContainer
                      columns={columns}
                      data={arrondissement || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={8}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Arrondissement.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Arrondissement));
