import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  addNewProvince as addNewProvinceApi,
  deleteProvince as deleteProvinceApi,
  getProvince as getProvinceApi,
  updateProvince as updateProvinceApi,
} from "../../../helpers/fakebackend_helper";
import {
  addNewProvinceFail,
  addNewProvinceSuccess,
  deleteProvinceFail,
  deleteProvinceSuccess,
  provinceApiResponseError,
  provinceApiResponseSuccess,
  updateProvinceFail,
  updateProvinceSuccess,
} from "./action";
import {
  ADD_NEW_PROVINCE,
  DELETE_PROVINCE,
  GET_PROVINCE,
  UPDATE_PROVINCE,
} from "./actionType";

function* getProvince() {
  try {
    const response = yield call(getProvinceApi);
    yield put(provinceApiResponseSuccess(GET_PROVINCE, response));
  } catch (error) {
    yield put(provinceApiResponseError(GET_PROVINCE, error));
  }
}

function* addNewProvince(action) {
  try {
    const response = yield call(addNewProvinceApi, action.payload);
    yield put(addNewProvinceSuccess(response));
    toast.success("Province ajoutée avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(addNewProvinceFail(error));
    toast.error("Erreur lors de l'ajout de la province", { autoclose: 3000 });
  }
}

function* updateProvince(action) {
  try {
    const response = yield call(updateProvinceApi, action.payload);
    yield put(updateProvinceSuccess(response));
    toast.success("Province modifiée avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(updateProvinceFail(error));
    toast.error("Erreur lors de la modification de la province", {
      autoclose: 3000,
    });
  }
}

function* deleteProvince(action) {
  try {
    const response = yield call(deleteProvinceApi, action.payload);
    yield put(deleteProvinceSuccess(response));
    toast.success("Province supprimée avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(deleteProvinceFail(error));
    toast.error("Erreur lors de la suppression de la province", {
      autoclose: 3000,
    });
  }
}

export function* watchGetProvince() {
  yield takeEvery(GET_PROVINCE, getProvince);
}

export function* watchAddNewProvince() {
  yield takeEvery(ADD_NEW_PROVINCE, addNewProvince);
}

export function* watchUpdateProvince() {
  yield takeEvery(UPDATE_PROVINCE, updateProvince);
}

export function* watchDeleteProvince() {
  yield takeEvery(DELETE_PROVINCE, deleteProvince);
}

function* provinceSaga() {
  yield all([
    fork(watchGetProvince),
    fork(watchAddNewProvince),
    fork(watchUpdateProvince),
    fork(watchDeleteProvince),
  ]);
}

export default provinceSaga;
