import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const Footer = (props) => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            {new Date().getFullYear()} © InawoCRM.
                        </Col>
                        {/* <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                {props.t("Conçu et développé par Le Conseiller du BTP Technologies")}
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

Footer.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};
export default withRouter(withTranslation()(Footer));