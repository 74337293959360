import React, { useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

//Simple bar

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import classnames from "classnames";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { Link,useHistory, useParams } from "react-router-dom";
import { axiosIns } from "../../utils";

  
SwiperCore.use([FreeMode, Navigation, Thumbs]);

function UserDetail(props) {

  const history = useHistory();

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const { id } = useParams('id')

  const [collab, setCollab] = useState({})
  const [profile, setProfile] = useState({})

  const collaborateurInfo = async () => {
    const response = await axiosIns.get(`profile/${id}/`);
    setCollab(response.data);
    setProfile(response.data.profile);
  }

  useEffect(() => {
    collaborateurInfo();
  }, [!collab])

  console.log(profile)


  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

document.title =props.t("Détail utilisateurs");
  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title={props.t("Profil utilisateur")} pageTitle={props.t("Profil utilisateur")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={12}>
                    <div className="mt-xl-0 mt-5">
                      <div>
                        <div className="d-flex">
                          <img src={profile.profile_pic} width="100" style={{
                            borderRadius: '50%',
                            height: '100px',
                            width: '100px',
                            objectFit: 'cover',
                            objectPosition: 'center center',
                            paddingBottom: "10px"
                          }} alt="" />
                          <div 
                          style={{
                            marginLeft: "20px",
                            marginTop: "8px"
                          }}
                          >
                            <h2 style={{fontWeight:"bold",textTransform:"uppercase"}}>{collab.username}</h2>
                            {collab.email}<br/>
                            <br></br>
                            <span className="text-xl fw-medium badge badge-soft-warning me-1 fs-13 pt-2">
                              {profile.user_type}
                            </span>

                            { profile && profile?.banca_certificate && 
                              <span className="text-xl fw-medium badge badge-soft-success me-1 fs-13 pt-2">
                                {profile.banca_certificate}
                              </span>
                            }
                            
                            <br/>
                          </div>
                        </div>
                      </div>

                      <div className="product-content mt-3">
                        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                             { props.t("Détails")}
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                        activeTab={customActiveTab}
                          className="border border-top-0 p-4"
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="1"
                          >
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row"> {props.t("Nom : ")}</th>
                                    <td>{profile.first_name || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Prénom(s) :")} </th>
                                    <td>{profile.last_name || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Email :")} </th>
                                    <td>{collab.email || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Téléphone :")} </th>
                                    <td>{collab.phone_number || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Adresse :")} </th>
                                    <td>{profile.address || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Ville :")} </th>
                                    <td>{profile.city || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Pays :")} </th>
                                    <td>{profile.country || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  
                                  { (profile?.user_type === "organism" || profile?.user_type ==="enterprise"  || profile?.user_type === "bank" ) &&
                                  <>
                                 
                              
                                  <tr>
                                    <th scope="row"> {props.t("RCCM :")} </th>
                                    <td>{profile.rccm || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Adresse :")} </th>
                                    <td>{profile.address || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> {props.t("Email professionnel:")} </th>
                                    <td>{profile.email  || props.t("(Non Renseigné)")}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row"> {props.t("Téléphone professionnel:")} </th>
                                    <td>{profile.telephone || props.t("(Non Renseigné)")}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row"> {props.t("Site internet :")} </th>
                                    <td>{profile.site_internet || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  {profile.user_type === "organism" ? 
                                    (
                                      <tr>
                                      <th scope="row"> {props.t("Type d'organisation: ")} </th>
                                      <td>{ profile.site_internet || props.t("(Non Renseigné)")}</td>
                                    </tr>
                                    ) : profile.user_type === "bank" &&   
                                    <tr>
                                      <th scope="row"> {props.t("Type d'organisation: ")} </th>
                                      <td>{"Banques et Assurances" }</td>
                                    </tr>

                                     }
                                     
                                  </>
                                  }

                                { profile.user_type === "professional" &&
                                  <>
                                    <tr>
                                    <th scope="row"> {props.t("Curriculum vitae : ")}</th>
                                    <td>{profile.cv || props.t("(Non Renseigné)")}</td>
                                  </tr>
                                  </>
                                }                               

                                </tbody>
                              </table>
                            </div>
                          </TabPane>

                        </TabContent>
                      </div>

                      
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

UserDetail.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(UserDetail));