import classnames from "classnames";
import React, { useEffect, useReducer, useState } from "react";
import "react-phone-number-input/style.css";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import PhoneInputWithCountrySelect, {
  isPossiblePhoneNumber,
} from "react-phone-number-input";

// import {isEmpty} from 'lodash';

//import images
import axios from "axios";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import progileBg from "../../../../assets/images/profile-bg.jpg";
import { axiosIns } from "../../../utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, userconnect: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false };

    default:
      return state;
  }
};

const Settings = (props) => {
  const history = useHistory();

  const [valid, setValid] = useState();
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [telephone, setTelephone] = useState("");
  const [pays, setPays] = useState("");
  const [ville, setVille] = useState("");
  const [adresse, setAdresse] = useState("");
  const [societe, setSociete] = useState("");
  const [identifiant, setIdentifiant] = useState("");
  const [nom, setNom] = useState("");
  const [rccm, setRccm] = useState("");
  const [domain, setDomain] = useState("");
  const [org_type, setOrg_type] = useState("");
  const [telephoneOrg, setTelephoneOrg] = useState("");
  const [emailOrg, setEmailOrg] = useState("");
  const [contact_email, setContact_email] = useState("");
  const [contact_firstname, setContact_firstname] = useState("");
  const [contact_lastname, setContact_lastname] = useState("");
  const [site_internet, setSite_Internet] = useState("");
  const [slogan, setSlogan] = useState("");
  const [address, setAddress] = useState("");
  const [contact_telephone, setContact_telephone] = useState("");
  const [arrondissement, setArrondissement] = useState("");
  const [quartier, setQuartier] = useState("");
  const [fonction, setFonction] = useState("");
  const [langue, setLangue] = useState("");
  const [photo, setPhoto] = useState(null);
  const [userProfile, setUserProfile] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [type, setType] = useState("");
  const [defaultValueSet, setDefaultValueSet] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const [{ loading, userconnect }, dispatch] = useReducer(reducer, {
    loading: true,
    userconnect: useParams("id"),
    error: "",
  });
  const userInfo = JSON.parse(sessionStorage.getItem("authUser"));
  const token = userInfo ? userInfo.access : null;
  const [profile, setProfile] = useState({});
  // recupère l'id
  const fetchData = async () => {
    const response = await axiosIns.get(`/profile/${userInfo.id}/`);
    setProfile(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const urlProfile = `https://backend.leconseillerdubtp.com/api/v1/profile/${
    profile && profile.id
  }/`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await axiosIns.get(urlProfile, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setUserProfile(data.data);
        setFirst_name(profile.profile && profile.profile.first_name);
        setLast_name(profile.profile && profile.profile.last_name);
        setTelephone(profile && profile.phone_number);
        setPays(profile.profile && profile.profile.country);
        setVille(profile.profile && profile.profile.city);
        setSociete(profile.organisation && profile.organisation?.societe);
        setFonction(profile.professional && profile.professional?.fonction);
        setAdresse(profile.profile && profile.profile.address);
        setType(profile.profile && profile.profile.user_type);
        setPhoto(profile.profile && profile.profile.profile_pic);
        setNom(data.data?.organisation && data.data?.organisation?.nom);
        setAddress(data.data?.organisation && data.data?.organisation?.address);
        setDomain(data.data?.organisation && data.data?.organisation?.domain);
        setContact_email(
          data.data?.organisation && data.data?.organisation?.contact_email
        );
        setEmailOrg(
          data.data?.organisation && data.data?.organisation?.emailOrg
        );
        setIdentifiant(data.data?.username);
        setContact_firstname(
          data.data?.organisation && data.data?.organisation?.contact_firstname
        );
        setContact_lastname(
          data.data?.organisation && data.data?.organisation?.contact_lastname
        );
        setContact_telephone(
          data.data?.organisation && data.data?.organisation?.contact_telephone
        );
        setOrg_type(
          data.data?.organisation && data.data?.organisation?.org_type
        );
        setSlogan(data.data?.organisation && data.data?.organisation?.slogan);
        setRccm(data.data?.organisation && data.data?.organisation?.rccm);
        setQuartier(profile.profile && profile?.profile.quartier);
        setArrondissement(profile.profile && profile?.profile.arrondissement);
        setSite_Internet(
          data.data?.organisation && data.data?.organisation?.site_internet
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [urlProfile]);
  console.log(telephone);
  const updateHandle = () => {
    if (
      type === "particular" ||
      type === "commite" ||
      type === "assistant" ||
      type === "professional" ||
      type === "expert"
    ) {
      const userObject = {
        username: identifiant,
        phone_number: telephone,
        profile: {
          first_name: first_name,
          last_name: last_name,
          country: pays,
          city: ville,
          arrondissement: arrondissement,
          address: adresse,
          quartier: quartier,
        },
        organisation: {},
        professional: {},
      };

      axios
        .patch(urlProfile, userObject, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(
          (res) => {
            if (res) {
              toast.success(props.t("Profil bien modifié"), {
                autoClose: 3000,
              });
            }
          },
          (error) => {
            if (error) {
              toast.error(props.t("Profil non modifié"), { autoClose: 3000 });
            }
          }
        );

      history.push("/pages-profile");
      setTimeout(() => history.push("/pages-profile"), 3000);
    } else {
      const userObject = {
        username: (userProfile && userProfile.username) || " ",
        phone_number: telephone,
        profile: {
          first_name: first_name,
          last_name: last_name,
          country: pays,
          city: ville,
          arrondissement: arrondissement,
          address: adresse,
          quartier: quartier,
        },
        organisation: {
          nom: nom,
          rccm: rccm,
          address: address,
          telephone: telephoneOrg,
          email: emailOrg,
          domain: domain,
          slogan: slogan,
          site_internet: site_internet,
          contact_firstname: contact_firstname,
          contact_lastname: contact_lastname,
          contact_email: contact_email,
          contact_telephone: contact_telephone,
          "org_type ": org_type,
        },
        professional: {},
      };

      axios
        .patch(urlProfile, userObject, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(
          (res) => {
            if (res) {
              toast.success(props.t("Profil bien modifié"), {
                autoClose: 3000,
              });
            }
          },
          (error) => {
            if (error) {
              toast.error(props.t("Profil non modifié"), { autoClose: 3000 });
            }
          }
        );

      setTimeout(() => history.push("/pages-profile"), 3000);
      history.push("/pages-profile");
    }
  };

  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  document.title = props.t("Modification du profil | Le Conseiller du BTP");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      {photo ? (
                        <img
                          src={photo}
                          alt=""
                          className="avatar-xl rounded-circle"
                        />
                      ) : (
                        <div className="flex-shrink-0 avatar-xl me-2">
                          <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                            {first_name.charAt(0)}
                          </div>
                        </div>
                      )}
                    </div>
                    <h5 className="fs-16 mb-1">
                      {userProfile.profile && userProfile.profile.first_name}{" "}
                      {userProfile.profile && userProfile.profile.last_name}
                    </h5>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-5">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">
                        {props.t("Complétez votre profil")}
                      </h5>
                    </div>
                  </div>
                  {first_name === null ||
                  last_name === null ||
                  telephone === null ||
                  adresse === null ||
                  pays === null ||
                  societe === null ||
                  fonction === null ||
                  langue === null ||
                  ville === null ||
                  photo === null ? (
                    <div className="progress animated-progress custom-progress progress-label">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{ width: "80%" }}
                        aria-valuenow="80"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div className="label">80%</div>
                      </div>
                    </div>
                  ) : (
                    <div className="progress animated-progress custom-progress progress-label">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div className="label">100%</div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xxl={9}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        {props.t("Détail Personnel")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="usernameInput"
                                className="form-label"
                              >
                                {props.t("Identifiant")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder={props.t("Entrer un identifiant")}
                                value={identifiant}
                                onChange={(e) => setIdentifiant(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="lastnameInput"
                                className="form-label"
                              >
                                {props.t("Nom")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="lastnameInput"
                                placeholder={props.t("Entrer le nom")}
                                value={last_name}
                                onChange={(e) => setLast_name(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="firstnameInput"
                                className="form-label"
                              >
                                {props.t("Prénom(s)")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="firstnameInput"
                                placeholder={props.t(
                                  "Entrer le ou les prénom(s)"
                                )}
                                value={first_name}
                                onChange={(e) => setFirst_name(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="title">
                                {props.t("TEL")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Label>

                              <PhoneInputWithCountrySelect
                                defaultCountry="US"
                                international
                                placeholder="Téléphone"
                                id="phone_number"
                                name="phone_number"
                                numberInputProps={{
                                  className: `form-control ${
                                    !valid ? "border-danger" : "border-success"
                                  }`,
                                }}
                                value={telephone}
                                onChange={(e) => {
                                  if (!isNaN(e)) {
                                    if (isPossiblePhoneNumber(e)) {
                                      setTelephone(e);
                                      setValid(true);
                                    } else {
                                      setTelephone(e);
                                      setValid(false);
                                    }
                                  }
                                }}
                              />
                              <label
                                className={`text-danger ${
                                  valid ? "d-none" : "d-block"
                                }`}
                              >
                                {valid === false
                                  ? "Numéro de téléphone invalide"
                                  : ""}
                              </label>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="adresseInput"
                                className="form-label"
                              >
                                {props.t("Adresse")}
                              </Label>
                              <Input
                                type="adresse"
                                className="form-control"
                                id="adresseInput"
                                placeholder={props.t("Entrer l'adresse")}
                                defaultValue={adresse}
                                value={adresse}
                                onChange={(e) => setAdresse(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="paysInput" className="form-label">
                                {props.t("Pays")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="paysInput"
                                placeholder={props.t("Entrer le pays")}
                                defaultValue={pays}
                                value={pays}
                                onChange={(e) => setPays(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="villeInput"
                                className="form-label"
                              >
                                {props.t("Ville")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="villeInput"
                                placeholder={props.t("Entrer la ville")}
                                defaultValue={ville}
                                value={ville}
                                onChange={(e) => setVille(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Arrondissement")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder={props.t(
                                  "Entrer l'arrondissement'"
                                )}
                                defaultValue={arrondissement}
                                value={arrondissement}
                                onChange={(e) =>
                                  setArrondissement(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Quartier")}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="villeInput"
                                placeholder={props.t("Entrer le quartier")}
                                defaultValue={quartier}
                                value={quartier}
                                onChange={(e) => setQuartier(e.target.value)}
                              />
                            </div>
                          </Col>
                          {type == !"particular" ||
                          type == !"commite" ||
                          type == !"assistant" ||
                          type == !"professionnel" ||
                          type == !"expert" ? (
                            <>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Nom de l'entreprise")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="societeInput"
                                    placeholder={props.t("Entrer le nom")}
                                    defaultValue={nom}
                                    value={nom}
                                    onChange={(e) => setNom(e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Rccm")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="societeInput"
                                    placeholder={props.t("Entrer le rccm")}
                                    defaultValue={rccm}
                                    value={rccm}
                                    onChange={(e) => setRccm(e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Adresse de l'entreprise")}
                                  </Label>
                                  <Input
                                    type="address"
                                    className="form-control"
                                    placeholder={props.t("Entrer l'adresse'")}
                                    defaultValue={address}
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="title">
                                    {props.t("TEL 1")}{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Label>

                                  <PhoneInputWithCountrySelect
                                    defaultCountry="US"
                                    international
                                    placeholder="Téléphone"
                                    id="phone_number"
                                    name="phone_number"
                                    numberInputProps={{
                                      className: `form-control ${
                                        !valid
                                          ? "border-danger"
                                          : "border-success"
                                      }`,
                                    }}
                                    value={telephone}
                                    onChange={(e) => {
                                      if (!isNaN(e)) {
                                        if (isPossiblePhoneNumber(e)) {
                                          setTelephone(e);
                                          setValid(true);
                                        } else {
                                          setTelephone(e);
                                          setValid(false);
                                        }
                                      }
                                    }}
                                  />
                                  <label
                                    className={`text-danger ${
                                      valid ? "d-none" : "d-block"
                                    }`}
                                  >
                                    {valid === false
                                      ? "Numéro de téléphone invalide"
                                      : ""}
                                  </label>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="societeInput"
                                    className="form-label"
                                  >
                                    {props.t("Email professionnel")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder={props.t("Entrer l'email")}
                                    defaultValue={emailOrg}
                                    value={emailOrg}
                                    onChange={(e) =>
                                      setEmailOrg(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Domaine")}
                                  </Label>
                                  <Input
                                    type="domain"
                                    className="form-control"
                                    placeholder={props.t("Entrer le domain")}
                                    defaultValue={domain}
                                    value={domain}
                                    onChange={(e) => setDomain(e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Site web")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder={props.t("Entrer le site web")}
                                    defaultValue={site_internet}
                                    value={site_internet}
                                    onChange={(e) =>
                                      setSite_Internet(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Slogan")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder={props.t("Entrer un slogan")}
                                    defaultValue={slogan}
                                    value={slogan}
                                    onChange={(e) => setSlogan(e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="societeInput"
                                    className="form-label"
                                  >
                                    {props.t("Nom du référent à contacter")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder={props.t("Entrer la prénom")}
                                    defaultValue={contact_lastname}
                                    value={contact_lastname}
                                    onChange={(e) => setSociete(e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="societeInput"
                                    className="form-label"
                                  >
                                    {props.t("Prenoms du référent à contacter")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder={props.t("Entrer le nom")}
                                    defaultValue={contact_firstname}
                                    value={contact_firstname}
                                    onChange={(e) =>
                                      setFirst_name(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="societeInput"
                                    className="form-label"
                                  >
                                    {props.t("Numéro du référent à contacter")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder={props.t("Entrer le telephone")}
                                    defaultValue={contact_telephone}
                                    value={contact_telephone}
                                    onChange={(e) =>
                                      setContact_telephone(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="societeInput"
                                    className="form-label"
                                  >
                                    {props.t("Email du référent à contacter")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder={props.t("Entrer l'email'")}
                                    defaultValue={contact_email}
                                    value={contact_email}
                                    onChange={(e) =>
                                      setContact_email(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="societeInput"
                                    className="form-label"
                                  >
                                    {props.t("Société")}
                                  </Label>
                                  <Input
                                    type="societe"
                                    className="form-control"
                                    id="societeInput"
                                    placeholder={props.t("Entrer la société")}
                                    defaultValue={societe}
                                    value={societe}
                                    onChange={(e) => setSociete(e.target.value)}
                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="societeInput"
                                    className="form-label"
                                  >
                                    {props.t("Société")}
                                  </Label>
                                  <Input
                                    type="societe"
                                    className="form-control"
                                    id="societeInput"
                                    placeholder={props.t("Entrer la société")}
                                    defaultValue={societe}
                                    value={societe}
                                    onChange={(e) => setSociete(e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Organisation")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    defaultValue={org_type}
                                    value={org_type}
                                    onChange={(e) =>
                                      setOrg_type(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                            </>
                          ) : null}

                          {/* <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="langueInput" className="form-label">{props.t("Ajouter une image")}</Label>
                                                            <Input id="profile-img-file-input" type="file" accept="image/jpg" name='profile_pic'
                                                                className="profile-img-file-input"
                                                                onChange={handleFileChange} />
                                                            </div>
                                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                {selectedFile ? <img src={URL.createObjectURL(selectedFile)} style={{maxWidth:'50%'}} alt={`Thumbnail`} /> :null}
                                                            </div>
                                                    </Col>  */}

                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                onClick={() => updateHandle()}
                                type="button"
                                className="btn btn-warning"
                              >
                                {props.t("Modifier")}
                              </button>
                              <Link
                                to="/pages-profile"
                                className="btn btn-soft-primary"
                              >
                                {props.t("Quitter")}
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Settings.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Settings));
