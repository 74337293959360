import {
    GET_TYPE_ABONNEMENTS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
  
    UPDATE_TYPE_ABONNEMENT,
    UPDATE_TYPE_ABONNEMENT_SUCCESS,
    UPDATE_TYPE_ABONNEMENT_FAIL,
    
    ADD_NEW_TYPE_ABONNEMENT,
    ADD_TYPE_ABONNEMENT_SUCCESS,
    ADD_TYPE_ABONNEMENT_FAIL,

  } from "./actionType";

  // common success
  export const typeabonnementApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  // common error
  export const typeabonnementApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  
  export const getTypeAbonnements = () => ({
    type: GET_TYPE_ABONNEMENTS,
  });

  export const updateTypeAbonnement = typeabonnement => ({
    type: UPDATE_TYPE_ABONNEMENT,
    payload: typeabonnement,
  });
  
  export const updateTypeAbonnementSuccess = typeabonnement => ({
    type: UPDATE_TYPE_ABONNEMENT_SUCCESS,
    payload: typeabonnement,
  });
  
  export const updateTypeAbonnementFail = error => ({
    type: UPDATE_TYPE_ABONNEMENT_FAIL,
    payload: error,
  });
  
  export const addNewTypeAbonnement = typeabonnement => ({
    type: ADD_NEW_TYPE_ABONNEMENT,
    payload: typeabonnement,
  });
  
  export const addTypeAbonnementSuccess = typeabonnement => ({
    type: ADD_TYPE_ABONNEMENT_SUCCESS,
    payload: typeabonnement,
  });
  
  export const addTypeAbonnementFail = error => ({
    type: ADD_TYPE_ABONNEMENT_FAIL,
    payload: error,
  });