import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import axios from 'axios';
import { useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { t } from "i18next";

const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
const url = "https://back.crm.inawo.pro/api/v1";
if(token)
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
};


const OrdersId = (cell) => {
    return (
        <React.Fragment>
            <Link to="/apps-tasks-details" className="fw-medium link-primary">{cell.value}</Link>
        </React.Fragment>
    );
};

const Project = (cell) => {
    return (
        <React.Fragment>
            <Link to="/apps-projects-overview" className="fw-medium link-primary">{cell.value}</Link>
        </React.Fragment>
    );
};

const Tasks = (cell, onEditIconClick, onDeleteIconClick) => {
    return (
        <React.Fragment>
            <div className="d-flex">
                <div className="flex-grow-1 tasks_name">{cell.row.original.taskname}</div>
                <div className="flex-shrink-0 ms-4">
                    <ul className="list-inline tasks-list-menu mb-0">
                        <li className="list-inline-item">
                            <Link to="/apps-tasks-details">
                                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link to="#" onClick={onEditIconClick}>
                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link to="#" className="remove-item-btn" onClick={onDeleteIconClick}>
                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

const CreateBy = (cell) => {
    const [abonne,setAbonne]=useState({"id":null,"first_name":"","last_name":""});

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data  = await axios.get(`${url}/profile`);
            setAbonne(data)
            console.log(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
  
      }, [url]);
      const abonnee =(j)=>{
          if(j===abonne.id){
            return (abonne.first_name+" "+ abonne.last_name);
        }
      } 
      console.log(abonnee(3));
    return (
        <React.Fragment>
            {abonnee(cell.row.original.created_by)}
        </React.Fragment>
        
    );
};

const AssignedTo = (cell) => {

  const [collaborateur,setCollaborateur]=useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data  = await axios.get(`${url}/collaborateurs/`);
            setCollaborateur(data)
            console.log(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
  
      }, [url]);
      const categgory =(j)=>{
        for(let i=0;i<=collaborateur.length;i++){
          if(j===collaborateur[i]?.id){
            return (collaborateur[i]?.first_name+" "+ collaborateur[i]?.last_name);
          }
        }
      } 
      console.log(categgory(3))
      console.log(collaborateur)
    return (
        <React.Fragment>
            <div className="avatar-group">
            {categgory(cell.row.original.taskresponsable)}
            </div>
        </React.Fragment>
    );
};

const DueDate = (cell) => {
    return (
        <React.Fragment>
            {cell.row.original.expiry}
        </React.Fragment>
    );
};

const Status = (cell) => {
    return (
        <React.Fragment>
            {cell.row.original.status === "en cours"?
                <span className="badge badge-soft-secondary text-uppercase">{t(cell.row.original.status)}</span>
                :cell.row.original.status === "nouveau" ?
                    <span className="badge badge-soft-info text-uppercase">{t(cell.row.original.status)}</span>
                    : cell.row.original.status === "complet"?
                        <span className="badge badge-soft-success text-uppercase">{t(cell.row.original.status)}</span>
                        : cell.row.original.status === "en attente"?
                            <span className="badge badge-soft-warning text-uppercase">{t(cell.row.original.status)}</span>
                            : null
            }
        </React.Fragment>
    );
};

const Priority = (cell) => {
    return (
        <React.Fragment>
            {cell.row.original.priority === "moyenne"?
                <span className="badge bg-warning text-uppercase">{t(cell.row.original.priority)}</span>
                :
                cell.row.original.priority === "haute" ?
                    <span className="badge bg-danger text-uppercase">{t(cell.row.original.priority)}</span>
                    : cell.row.original.priority === "faible"?
                        <span className="badge bg-success text-uppercase">{t(cell.row.original.priority)}</span>
                        : null
            }
        </React.Fragment>
    );
};

Status.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
  };
  Priority.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
  };
 export default withRouter(withTranslation()(Priority,Status));
 export {OrdersId, Project, Tasks, CreateBy, AssignedTo, DueDate,Priority,Status}