import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import { Card, CardHeader, Container, Row } from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import TableContainer from "../../../Components/Common/TableContainer";

//Import actions
import { getArticles as onGetArticles } from "../../../store/ecommerce/action";

//redux
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const Articles = (props) => {
  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;
  const dispatch = useDispatch();

  const { products } = useSelector((state) => ({
    products: state.Ecommerce.products,
  }));

  // Definition des etats
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  const [articleListApp, setArticleListApp] = useState([
    { title: "", comments: [{ id: null, replies: [] }], likes_count: "" },
  ]);
  const [articles, setArticles] = useState([]);
  const [selectArticle, setSelectArticle] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://backend.leconseillerdubtp.com/api/v1/users/list/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setUserList(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://backend.leconseillerdubtp.com/api/v1/articles/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setArticles(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://backend.leconseillerdubtp.com/api/v1/article-list/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setArticleListApp(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  const articlesId = useParams("id");

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          `https://backend.leconseillerdubtp.com/api/v1/articles/${articlesId.id}/`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setSelectArticle(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://backend.leconseillerdubtp.com/api/v1/categories/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setCategoryList(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  // envoyer products s'il n'esxiste pas
  useEffect(() => {
    if (products && !products.length) {
      dispatch(onGetArticles());
    }
  }, [dispatch, products]);

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const handleDeleteProduct = () => {
    if (articles) {
      // dispatch(deleteProducts(articles.id));
      setDeleteModal(false);
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = "none";
    });
  };

  // --Filtre--

  const tokenInfo = JSON.parse(sessionStorage.getItem("authUser"));
  const role = tokenInfo.role;

  const [filterValue, setFilterValue] = useState("");
  let validated = true;
  let unValidated = false;
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (articles) {
      setFilteredData(articles);
    }
  }, [articles]);

  const filterData = (selectedFilter) => {
    if (selectedFilter !== "Tous") {
      const filtered = articles.filter(
        (item) => item.validated === selectedFilter
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(articles);
    }
  };

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilterValue(selectedFilter);
    filterData(selectedFilter);
  };

  // ---Filtre-----

  const categgory = (j) => {
    for (let i = 0; i <= categoryList.length; i++) {
      if (j === categoryList[i]?.id) {
        return categoryList[i]?.designation;
      }
    }
  };

  const users = (j) => {
    for (let i = 0; i <= userList.length; i++) {
      if (j === userList[i]?.id) {
        return userList[i]?.first_name + " " + userList[i]?.last_name;
      }
    }
  };

  function getCommentCountByArticleId(articleId) {
    let number = 0;
    const article = articles.find((article) => article.id === articleId);
    if (article) {
      const commentaire = article.comments;
      for (let i = 0; i < commentaire.length; i++) {
        number = number + commentaire[i].replies.length;
      }
      return number + commentaire.length;
    }
    return 0;
  }

  function getCommentCountByLikeId(articleId) {
    const article = articles.find((article) => article.id === articleId);
    if (article) {
      return article.likes_count;
    }
    return 0;
  }

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="entrepriseCheckBox"
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: props.t("ILLUSTRATION"),
        Cell: (article) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <img
                  src={article.row.original.article_pic}
                  alt=""
                  className="avatar-sm rounded"
                />
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("TITRE"),
        accessor: "title",
        Cell: (article) => (
          <>
            <div
              className="d-flex align-items-center"
              style={{
                width: 200,
                height: 50,
                overflow: "clip",
                verticalAlign: "middle",
                textTransform: "uppercase",
              }}
            >
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 text-dark">
                  <Link
                    to={`apps-articles-details/${article.row.original.id}/`}
                    style={{ fontWeight: "bold", color: "#000000" }}
                  >
                    {article.row.original.title}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("CATEGORIE"),
        accessor: "category",
        Cell: (article) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 ">
                  <span className="fs-12 uppercase badge badge-soft-warning">
                    {categgory(article.row.original.category)}
                  </span>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("PARAGRAPHES"),
        accessor: "paragraphs",
        Cell: (article) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  {article.row.original.paragraphs &&
                    article.row.original.paragraphs?.length}
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("COMMENTAIRES"),
        Cell: (article) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <span
                    className="d-flex align-items-center"
                    style={{ color: "#F6BE1E" }}
                  >
                    {" "}
                    <i
                      style={{ fontSize: "15px", color: "#F6BE1E" }}
                      className="ri-message-2-line align-bottom"
                    ></i>{" "}
                    {"  "} {"  "}{" "}
                    {getCommentCountByArticleId(article.row.original.id)}{" "}
                  </span>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("LIKES"),
        Cell: (article) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <span
                    className="d-flex align-items-center"
                    style={{ color: "#F6BE1E" }}
                  >
                    {" "}
                    <i
                      style={{ fontSize: "15px", color: "#F6BE1E" }}
                      className="ri-heart-line align-bottom"
                    ></i>{" "}
                    {"  "} {"  "}{" "}
                    {getCommentCountByLikeId(article.row.original.id)}
                  </span>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("STATUT"),
        accessor: "validated",
        Cell: (article) => (
          <>
            {role.some((role) => role !== "assistant") ? (
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <h5 className="fs-14  p-2">
                    <span className="mt-4">
                      {article.row.original.validated === false ? (
                        <div
                          className="btn btn-soft-warning"
                          style={{ fontSize: "13px" }}
                        >
                          <Link
                            to={`apps-articles-details/${article.row.original.id}/`}
                          >
                            Valider
                          </Link>
                        </div>
                      ) : (
                        <div
                          className="fs-12 uppercase badge badge-soft-success"
                          style={{ textAlign: "center", fontSize: "13px" }}
                        >
                          {" "}
                          Validé
                        </div>
                      )}
                    </span>
                  </h5>
                </div>
              </div>
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        Header: props.t("AUTEUR"),
        accessor: "author",
        Cell: (article) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 ">
                  <span
                    className="p-2 align-items-center italic "
                    style={{ fontStyle: "italic" }}
                  >
                    {article.row.original.author
                      ? article.row.original.author.username
                      : "-"}
                  </span>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("VALIDE PAR"),
        accessor: "validated_by",
        Cell: (article) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 ">
                  <span
                    className="p-2 align-items-center "
                    style={{ fontStyle: "italic" }}
                  >
                    {article.row.original.validated_by
                      ? article.row.original.validated_by?.username
                      : " "}
                  </span>
                </h5>
              </div>
            </div>
          </>
        ),
      },
    ],
    [props, getCommentCountByArticleId, getCommentCountByLikeId, categgory]
  );

  document.title = props.t("Liste des articles");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb
          title={props.t("Liste des articles")}
          pageTitle={props.t("Listes des articles")}
        />

        <Row>
          {!articles ? (
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <div className="d-flex.align-items-center">
                  <button className="btn btn-info add-btn">
                    <Link
                      to={`/apps-articles-add`}
                      className="text-white d-inline-block"
                    >
                      <i className="ri-add-fill me-1 align-bottom" />{" "}
                      {props.t("Ajouter un article")}
                    </Link>
                  </button>
                </div>
              </CardHeader>
            </Card>
          ) : null}

          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {isLoading ? (
                    <div className="py-4 text-center">
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/xjovhxra.json"
                          trigger="loop"
                          colors="primary:#0960b6,secondary:#02376b"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                      </div>
                      <div className="mt-4">
                        <h5>{props.t("Chargement")}...</h5>
                      </div>
                    </div>
                  ) : articles && articles.length > 0 ? (
                    <div>
                      <TableContainer
                        columns={columns}
                        data={articles || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        divClass="table-responsive mb-1"
                        tableClass="mb-0 align-middle "
                        theadClass="table-light text-muted"
                        isProductsFilter={true}
                      />
                    </div>
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
Articles.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Articles));
