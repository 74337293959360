import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { 
  Card,
  CardBody,
  Col,
  Container,
  CardHearder,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  CardHeader,
 } from 'reactstrap';
 import * as moment from 'moment';
 import { Link } from "react-router-dom";
 import Select from "react-select";
 import classnames from "classnames";
 import Flatpickr from "react-flatpickr";
 import BreadCrumb
  from '../../Components/Common/BreadCrumb';
import TableContainer from '../../Components/Common/TableContainer';
import DeleteModal  from '../../Components/Common/DeleteModal';
import { isEmpty } from "lodash";
import axios from 'axios';
import { axiosIns } from '../../utils';

// import { ventesData } from  "../../common/data"

// Formik
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";

// redux
import { useSelector, useDispatch } from "react-redux";

import { useReducer } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { getContacts as onGetContacts } from "../../store/crm/action"; 

import Loader from '../../Components/Common/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ExportCSVModal from '../../Components/Common/ExportCSVModal';
import { use } from 'echarts';

const AbonnesFormule = (props) => {

    const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;


    const [abonnes, setAbonnes] = useState([]);

  useEffect(() => {
    const fetchAbonnes = async () => {
      try {
        const { data } = await axiosIns.get('/administration/subscribers', {
            headers : {
                Authorization : "Bearer " + token
        }})
        console.log("contact",data);
        setAbonnes(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchAbonnes();
  }, []);

  const [abonnement, setAbonnement] = useState([]);

  useEffect(() => {
    const fetchAbonnement = async () => {
      try {
        const { data } = await axiosIns.get('/administration/subscriptions', {
            headers : {
                Authorization : "Bearer " + token
        }})
        console.log("abonnements", data);
        setAbonnement(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchAbonnement();
  }, []);

  const [sub, setSub] = useState([]);   
  useEffect(() => {
    const fetchSub = async () => {
      try {
        const { data } = await axiosIns.get('/subscriptions', {
            headers : {
                Authorization : "Bearer " + token
        }})
        console.log("sub", data);
        setSub(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchSub();
  }, []);
  
  const [typesub, setTypeSub] = useState([]);   
  useEffect(() => {
    const fetchTypeSub = async () => {
      try {
        const { data } = await axiosIns.get('/subscription-types', {
            headers : {
                Authorization : "Bearer " + token
        }})
        console.log("typesub", data);
        setTypeSub(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchTypeSub();
  }, []);

  const [pricing, setPricing] = useState([]);
  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const { data } = await axiosIns.get('/pricing', {
            headers : {
                Authorization : "Bearer " + token
        }})
        console.log("pricing", data);
        setPricing(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchPricing();
  }, []);

  const [pricingtri, setPricingTri] = useState([]);

  useEffect(() => {

    const Subtri = (j)=> {
        for(let i = 0;i<=sub.length; i++){
          if( j === sub[i]?.id){
           return (sub[i]?.designation) ;
          }
        }
      }

    const TypeSubTri = (j)=> {
    for(let i = 0;i<=typesub.length; i++){
        if( j === typesub[i]?.id){
        return (typesub[i]?.designation) ;
        }
    }
    }
    setPricingTri(pricing.map((pri) => ({ ...pri, subscription: Subtri(pri.subscription), subscription_type: TypeSubTri(pri.subscription_type) })));
  }, [pricing, sub, typesub]);

    console.log("pricingtri", pricingtri);

  
  const [abon, setAbon] = useState([]);
  
    useEffect(() => {

        const AbonnementAbo = (j) => {
          for(let i=0 ; i <= abonnes.length ; i++){
            if( j === abonnes[i]?.id){
            return abonnes[i]?.first_name + " " + abonnes[i]?.last_name ;
            }
          }
          return "";
        }

        const PricingLib = (j)=> {
          for(let i = 0;i<=pricingtri.length; i++){
            if( j === pricingtri[i]?.id){
            return (pricingtri[i]?.subscription + " " + pricingtri[i]?.subscription_type) ;
            }
          }
        }
      setAbon(abonnement.map((abo) => ({ ...abo, abonne: AbonnementAbo(abo.abonne), pricing: PricingLib(abo.pricing), abonneid: abo.abonne })));
    }, [abonnement, abonnes, AbonnesFormule, pricingtri]);

    console.log("abon", abon);

    const [abontri, setAbonTri] = useState([]);

    const handleabontri = (e) => {
      if (isEmpty(e.target.value)){
        setAbonTri([]);
      } else {
        if (e.target.value === "1"){
          setAbonTri(abon.filter((abo) => abo.pricing === "TRIAL Essentiel"));
        } else{
          setAbonTri(abon.filter((abo) => abo.pricing !== "TRIAL Essentiel"));
        }
      }
    }

  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [venteList, setVenteList] = useState([]);
  const [vente, setVente] = useState(null);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedMulti, setSelectedMulti] = useState(null);
    const [factures, setFactures] = useState([]);
    useEffect(() => {
        const fetchFactures = async () => {
          try {
            const { data } = await axiosIns.get(`/factures/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            console.log("test2", data);

            const dateSysteme = new Date();
            const moisActuel = dateSysteme.getMonth();
            const facturesFiltrees = data.filter(facture => {
              const moisFacture = parseInt(facture.dateFacture.split("T")[0].slice(5, 7)) - 1;
              return moisFacture === moisActuel;
            });
            console.log("test3", facturesFiltrees);
            setFactures(facturesFiltrees);
          } catch (err) {
            console.log(err);
          }
        };
        fetchFactures();
        }, [token]);
      

  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [contactList, setContactList] = useState([]);

  const handleVenteClicks = () => {
    setVente("");
    setIsEdit(false);
    toggle();
  };

  const onClickDelete = (vente) => {
    setVente(vente);
    setDeleteModal(true);
  }

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".productCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }

  function handleMulti(ventes, selectedMulti) {
    setSelectedMulti(selectedMulti);
  }

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setVente(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const [dele, setDele] = useState(0);

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let h = (d.getHours() % 12) || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
  };


  const [date, setDate] = useState(defaultdate());

  const dateformate = (e) => {
    const dateString = e.toString().split(" ");

    let time = dateString[4];
    let H = +time.substr(0, 2);
    let h = (H % 12) || 12;
    h = (h <= 9) ? h = ("0" + h) : h;
    let ampm = H < 12 ? "AM" : "PM";
    time = h + time.substr(2, 3) + " " + ampm;

    const date = dateString[2] + " " + dateString[1] + ", " + dateString[3];
    const venteDate = (date + ", " + time).toString();
    setDate(venteDate);

  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
    return updateTime;
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t("No."),
        Cell: (abo)=> (
          <>
            <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.index + 1}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Abonnés"),
        accessor: "abonne",
        Cell: (abo) => ( 
          <>
          <Link to={`/apps-admin-abonne-detail/${abo.row.original.abonneid}/`} className="text-warning d-inline-block">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.original.abonne}
              </h5>
            </div>
          </div>
          </Link> 
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Abonnement"),
        accessor: "pricing",
        Cell: (abo) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.original.pricing === "TRIAL Essentiel" ? "Essai gratuit" : abo.row.original.pricing}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Debut"),
        Cell: (abo) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.original.start}
              </h5>
            </div>
          </div>
          </>
        )
      },
        {
          Header: props.t("Fin"),
          Cell: (abo) => ( 
            <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                    {abo.row.original.end}
                </h5>
              </div>
            </div>
            </>
          )
        },
      {
        Header: props.t("Affilié"),
        Cell: (abo) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {abo.row.original.affilie === null ? "Non-affilié" : abo.row.original.affilie}
              </h5>
            </div>
          </div>
          </>
        )
      },
    ],
    []
  );

  document.title = props.t("Liste des abonnés | Le Conseiller du BTP");

  return (
    <div className='page-content'>
      <ToastContainer closeButton={false} />
        <Container fluid>
          <BreadCrumb title={props.t("Abonnés")} pageTitle={props.t("Liste des abonnés")} />
          <Row>
            <Col lg={12}>
                <Card id="orderList">
                      <CardHeader className='card-header border-0'>
                        <Col lg={7}>
                          <div className="d-flex align-items-center">
                            <Label for="abontri">{props.t("Filtrer par")} :</Label>
                            <Input className='form-control' type='select'
                              style={{ marginLeft: "10px", width: "200px" }}
                              onChange={(e) => {
                                handleabontri(e)
                              }}
                            >
                              <option value="">{props.t("Abonnement")}</option>
                              <option value="1">{props.t("Essai Gratuit")}</option>
                              <option value="2">{props.t("Abonnement payant")}</option>
                            </Input>
                          </div>  
                        </Col>
                      </CardHeader>
                </Card>
                <Card id='orderList'>
                <CardBody className="pt-0">
                <div>
                    {
                        abonnement && abonnement.length > 0 ?
                            isEmpty(abontri) ?
                                (
                                    <TableContainer
                                        columns={columns}
                                        data={(abon || [])}
                                        isGlobalFilter={true}
                                        isAddUserList={false}
                                        customPageSize={25}
                                        className="custom-header-css"
                                        divClass="table-responsive table-card mb-3"
                                        tableClass="align-middle table-nowrap"
                                        theadClass="table-light"
                                      />
                                )
                                :
                                (
                                  <TableContainer
                                      columns={columns}
                                      data={(abontri || [])}
                                      isAddUserList={false}
                                      customPageSize={25}
                                      className="custom-header-css"
                                      divClass="table-responsive table-card mb-3"
                                      tableClass="align-middle table-nowrap"
                                      theadClass="table-light"
                                    />
                              )
                        :
                            (
                                <div className="py-4 text-center">
                                <div>
                                    <lord-icon
                                    src="https://cdn.lordicon.com/msoeawqm.json"
                                    trigger="loop"
                                    colors="primary:#405189,secondary:#0ab39c"
                                    style={{ width: "72px", height: "72px" }}
                                    ></lord-icon>
                                </div>

                                <div className="mt-4">
                                    <h5>{props.t("Sorry! No Result Found")}</h5>
                                </div>
                                </div>
                            )
                    }
                  
                </div>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </Container>
    </div>
  )

}

AbonnesFormule.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default  withRouter(withTranslation()(AbonnesFormule));
