import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import {
    CardBody,
    Row,
    Col,
    Card,
    Container,
    Form,
    Input,
    Label,
    Table,
    FormFeedback,
    Button,
} from "reactstrap"

import { isEmpty } from "lodash";

import { ToastContainer, toast } from 'react-toastify';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
 
import { Link, useHistory, useParams } from "react-router-dom"
import Flatpickr from "react-flatpickr"

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";

import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"

import newId from '../../../src/newid'
import { axiosIns } from '../../utils'

import { useFormik } from "formik";
import * as Yup from "yup";

import {getProducts as onGetProducts} from "../../store/ecommerce/action"
import { getContacts as onGetContacts } from "../../store/crm/action"; 
import { updateVente as onUpdateVente } from "../../store/ventes/action";
import { isDateSpansEqual } from "@fullcalendar/react";

const DepenseUpdate = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

  const [depenselist, setDepenseList] = useState([{
      id: null,
      produit:'',
      qte:null,
      prix:null
  }]);
      useEffect(() => {
        const fetchProducts = async () => {
          try {
            const data  = await axiosIns.get(`/depenses/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            setDepenseList(data);
          } catch (err) {
            console.log(err);
            //toast.error(t('make_sure_internet'));
          }
        };
        fetchProducts();
      }, [token]);

  const PrixProd =(j)=>{
    for (let i = 0; i < depenselist.length; i++) {
      if(depenselist[i]?.id === j){
        return depenselist[i]
      }
      return depenselist[i]
    }
  }

  console.log("code" ,PrixProd(1))

  document.title = props.t("Modifier Dépense | Le Conseiller du BTP");


  const history = useHistory();
  const dispatch = useDispatch();
  const [ispayementDetails, setIspayementDetails] = useState(null);
  const [isCurrency, setIsCurrency] = useState("$")

    function handleisCurrency(isCurrency){
        setIsCurrency(isCurrency);
    }

  function handleispaymentDetails(ispayementDetails) {
    setIspayementDetails(ispayementDetails);
  }

  const [productList, setProductList] = useState([]);
  const [contactList, setContactList] = useState([]);
  

  const depenseId = useParams('id');
  console.log("depenseId", depenseId);
  const url = "https://back.crm.inawo.pro/api/v1"
       
  const allstatus = [
    {
      options: [
        { label: props.t("Sélectionner le status de la depense"), value: "" },
        { label: props.t("En Cours"), value: "En Cours" },
        { label: props.t("Payé"), value: "Payé" },
        { label: props.t("Non Payé"), value: "Non payé"}
      ],
    },
  ];
                                                                                                                                                                                                                                                                                                                                                                                                                       
      const dateFormat = () => {
        let d = new Date(),
          months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
      };
    
      const [date, setDate] = useState(dateFormat());
    
      const dateformate = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
        setDate(joinDate);
      };


      // const validation = useFormik ({
      //   enableReinitialize: true,

      //   initialValues: {
      //     statusVente: "",
      //     statusLivraison: "",
      //     statusPaiement: "",
      //     typePaiement: "",
      //     encaissementRecu: "",
      //     contact: "",
      //     commentaire: "",
      //     detailsVente: [
      //       {
      //         produit: null,
      //         qteVendu: null,
      //     }
      //     ]
      //   },
        
      //   validationSchema: Yup.object().shape({
      //     statusVente: Yup.string().required("Ce champ est obligatoire"),
      //     statusLivraison: Yup.string().required("Ce champ est obligatoire"),
      //     statusPaiement: Yup.string().required("Ce champ est obligatoire"),
      //     typePaiement: Yup.string().required("Ce champ est obligatoire"),
      //     encaissementRecu: Yup.number().required("Ce champ est obligatoire"),
      //     contact: Yup.number().required("Ce champ est obligatoire"),
      //     commentaire: Yup.string().required("Ce champ est obligatoire"),
      //     detailsVente: Yup.array().of(
      //       Yup.object().shape({
      //         produit: Yup.number().required("Ce champ est obligatoire"),
      //         qteVendu: Yup.number().required("Ce champ est obligatoire"),
      //       }
      //     )
      //     )
      //   }),

      // })

      const depenId = useParams('id');
      console.log("code1" ,depenId);
      
      const handleAddItem = () => {
        setDetails([...details, { produit: '', qte: null, prix:null }]);
      };

      const [FormData, setFormData] = useState({});
        useEffect(() => {
            const fetchData = async ()=> {
                try {
                    const data = await axios.get(`${url}/depenses/${depenId.id}`);
                    setFormData(data);                  
                    console.log("resultat", data)
                } catch (err) {
                    console.log(err)
                }
            };
            fetchData(); 
        }, [depenId, url])

        console.log("ddd",FormData)
    const [details, setDetails] = useState([
      {
        produit:'',
        qte:null,
        prix:null
      }
    ]);
    useEffect(() => {
      if (!isEmpty(FormData))
      setDetails(FormData.detailsDepense);
  }, [FormData]);

  const handleUpdateQteVendu = (index, value) => {
    const updatedDetails = [...details];
    updatedDetails[index].qte = value;
    setDetails(updatedDetails);
  };

  const handleUpdateProduit = (index, value) => {
    const updatedDetails = [...details];
    updatedDetails[index].produit = value;
    setDetails(updatedDetails);
  };

  const handleUpdatePrix = (index, value) => {
    const updatedDetails = [...details];
    updatedDetails[index].prix = value;
    setDetails(updatedDetails);
  };

  const removeItem = (index) => {
        let updatedDetails = [...details];
        updatedDetails.pop(index, 1);
        setDetails(updatedDetails);
      };

      const [selectedProductPrice, setSelectedProductPrice] = useState({ tbodyIndex: null, price: null });
      const [productPrices, setProductPrices] = useState({});
      
      const handleSelectedProductPrice = (tbodyIndex, price) => {
        setSelectedProductPrice({ tbodyIndex, price });
        setProductPrices({ ...productPrices, [tbodyIndex]: price });
      };

  const productPrice = (j)=> {
    for(let i=0;i<=depenselist.length; i++){
      if( j===depenselist[i]?.id){
       return (depenselist[i]?.prix) ;
      }
    }
  }


      const handleInputForm = (e) => {
        setFormData({ ...FormData, [e.target.name]: e.target.value });
      };

      const [errors, setErrors] = useState({});

const validate = () => {
  const newErrors = {};
  if (!FormData.libelle_depense) {
    newErrors.libelle_depense= "Le champ est obligatoire";
  }
  if (!FormData.date_depense) {
    newErrors.date_depense = "Le champ est obligatoire";
  }
  if (!FormData.status) {
    newErrors.status = "Le champ est obligatoire";
  }
  if(!(details.map((item) => item.prix * item.qte).reduce((a, b) => a + b, 0))){
    newErrors.montant = "Le champ est obligatoire"
  }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

      const submitHandler = async (e) => {
        e.preventDefault();
        if (validate()) {

        const updateDepense = {
          libelle_depense: FormData.libelle_depense ? FormData.libelle_depense : 0,
          date_depense: FormData.date_depense,
          status: FormData.status ? FormData.status : 0,
          montant:details.map((item) => item.prix * item.qte).reduce((a, b) => a + b, 0),
          detailsDepense: details,
        }
        console.log("newVente", updateDepense);
    
        try {
          const { data } = await axiosIns.patch(`/depenses/${depenseId.id}/`, updateDepense, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          history.push("/apps-depenses");
          window.location.reload(true)
          toast.success(props.t("Dépense modifiée avec succès"));
        } catch (err) {
          console.log(err);
          //toast.error(t('make_sure_internet'));
        }
      }
    }
  
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={props.t("Modifier une dépense")} pageTitle={props.t("Dépenses")} />
                <Row className="justify-content-center">
                    <Col xxl={9}>
                        <Card>
                            <Form
                            onSubmit={submitHandler}
                            className="needs-validation"
                            id="invoice_form"
                            >
                                <CardBody className="p-4">
                                  <Row className="g-3">
                                    <Col lg={6} sm={6}>
                                      <Label for="choices-payment-status">{props.t("Statut de la depense")}</Label>
                                      <div className="input-light">
                                        <Input
                                          name="libelle_depense"
                                          type="text"
                                          className="bg-light border-0"
                                          onChange={handleInputForm}
                                          value={FormData.libelle_depense}
                                        >
                                          </Input>
                                          {errors.libelle_depense && <p className="text-danger">{errors.libelle_depense}</p>}
                                      </div>
                                    </Col>
                                    <Col lg={6} sm={6}>
                    <div className="mb-2">
                        <Label
                          for="choices-payment-type"
                          className="form-label text-muted text-uppercase fw-semibold"
                        >
                          {props.t("Status de la dépense")}
                        </Label>
                        <div className="input-light">
                                        <Input
                                          name="status"
                                          type="select"
                                          className="bg-light border-0"
                                          id="choices-payment-status"
                                          onChange={handleInputForm}
                                          value={ FormData.status}
                                          invalid={errors.status ? true : false}
                                        >
                                          {allstatus.map((item, key) => (
                                              <React.Fragment key={key}>
                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                              </React.Fragment>
                                            ))}
                                          </Input>
                            {errors.status  ? (
                              <FormFeedback type="invalid">{errors.status }</FormFeedback>
                            ) : null}
                          </div>
                      </div>
                    </Col>
                                    <Col lg={6} sm={6}>
                                    <div className='mb-2'>                                                                                                                                                       
                                      <Label for='date_depense'>
                                        {props.t("Date")}
                                      </Label>
                                      <Input 
                                        type="date"
                                        className="form-control bg-light border-0"
                                        id="date_depense"
                                        name="date_depense"
                                        onChange={handleInputForm}
                                        value={FormData.date_depense }
                                        invalid= { errors.date_depense ? true: false}
                                      />
                                      {errors.date_depense  ? (
                                            <FormFeedback type="invalid">{errors.date_depense }</FormFeedback>
                                          ) : null}
                                    </div>
                                  </Col>
                                  </Row>
                                </CardBody>
                                
                                <CardBody className="p-4">
                                  <div className="table-responsive">
                                    <Table className="invoice-table table table-borderless mb-0">
                                        <thead className="align-middle">
                                              <tr className="table-active">
                                                  <th scope="col" style={{ width: "50px" }}>
                                                    
                                                  </th>
                                                  <th scope="col">{props.t("Désignation")}</th>
                                                  <th scope="col" style={{ width: "250px" }}>
                                                    {props.t("Prix")}
                                                  </th>
                                                  <th scope="col" style={{ width: "120px" }}>
                                                    {props.t("Quantité")}
                                                  </th>
                                                  <th scope="col" style={{ width: "150px" }}>
                                                    {props.t("Montant")}
                                                  </th>
                                                  <th scope="col" style={{ width: "20px" }}>
                                                  </th>
                                              </tr>
                                        </thead>

                                      {details.map((item, index) => (
                                        <React.Fragment key={index}>
                                          <tbody name={`productInfo-${index}`} key={index}>
                                                <tr id={index} className="product">
                                                  <th scope="row" className="product-id">
                                                    <Label>{index + 1}</Label>
                                                  </th>
                                                  <td className="text-start">

                                                    <div className="mb-2">

                                                    <Input 
                                                        type="text"
                                                        className="form-control bg-light border-0"
                                                        id={`productName-${index}`}
                                                        name={`product_name-${index}`}
                                                        onChange={(e) => {
                                                          handleSelectedProductPrice(index, e.target.value);
                                                          handleUpdateProduit(index, e.target.value);
                                                        }}
                                                        value={item.produit}

                                                      />
                                                        
                                                      
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <Input
                                                      type="number"
                                                      className="form-control product-price bg-light border-0"
                                                      id={`productRate-${index}`}
                                                      value={item.prix}
                                                      step="0.01" 
                                                      onChange={(e) => handleUpdatePrix(index, e.target.value)} 
                                                    />
                                                      <div className="invalid-feedback">
                                                        {props.t("Veuillez entrer un prix")}
                                                      </div>
                                                  </td>
                                                  <td>
                                                    <div className="input-step">
                                                    <Input
                                                      type="number"
                                                      className="product-quantity"
                                                      id={`product-qty-${index}`}
                                                      value={item.qte}
                                                      onChange={(e) => handleUpdateQteVendu(index, e.target.value)}
                                                    />
                                                    </div>
                                                  </td>
                                                  <td className="text-end">
                                                    <div>
                                                    <Input
                                                      type="text"
                                                      className="form-control bg-light border-0 product-line-price"
                                                      id={`productPrice-${index}`}
                                                      placeholder="0.00"
                                                      value={item.prix*item.qte}
                                                      readOnly
                                                    />
                                                    </div>
                                                  </td>
                                                  <td id={`product-removal-${index}`} className="product-removal">
                                                      <button
                                                        id={`del-${index}`}
                                                        className="btn btn-success"
                                                        onClick={() => {
                                                          removeItem(index);
                                                        }}
                                                      >
                                                        {props.t("Supprimer")}
                                                      </button>
                                                  </td>
                                                </tr>
                                            </tbody>

                                        </React.Fragment>
                                      ))}
                                         <tfoot>
                                        <tr>
                                           <td colSpan="5">
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-secondary fw-medium"
                                                  id="add-item"
                                                  onClick={handleAddItem}
                                                >
                                                  <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                                                  {props.t("Ajouter une dépense")}
                                                </button>
                                            </td>
                                          </tr>
                                        <tr className="border-top border-top-dashed mt-2">
                                          <td colSpan="3"></td>
                                          <td colSpan="2" className="p-0">
                                          <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                                          <tbody>
                                          
                                            <tr className="border-top border-top-dashed">
                                              <th scope="row">{props.t("Montant Total")}</th>
                                              <td>
                                                <Input
                                                  type="number"
                                                  className="form-control bg-light border-0"
                                                  name="montant"
                                                  id="cart-total"
                                                  onChange={handleInputForm}
                                                  value={details.map((item) => item.prix * item.qte).reduce((a, b) => a + b, 0)}
                                                />
                                                {errors.montant && <p className="text-danger">{errors.montant}</p>}
                                              </td>
                                           </tr>
                                          </tbody>
                                          </Table>
                                          </td> 
                                        </tr>  
                                        </tfoot>
                                                                              
                                    </Table>
                                  </div>
                                  
                                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    <button type="submit" className="btn btn-success">
                                      <i className="ri-printer-line align-bottom me-1"></i> {props.t("Valider")}
                                    </button>
                                  </div>
                                </CardBody>

                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )


}

DepenseUpdate.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DepenseUpdate));
 