import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import {
    CardBody,
    Row,
    Col,
    Card,
    Container,
    Form,
    Input,
    Label,
    Table,
    FormFeedback,
    Button,
} from "reactstrap"

import { isEmpty } from "lodash";

import { ToastContainer, toast } from 'react-toastify';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
 
import { Link, useHistory, useParams } from "react-router-dom"
import Flatpickr from "react-flatpickr"

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";

import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"

import newId from '../../../src/newid'
import { axiosIns } from '../../utils'

import { useFormik } from "formik";
import * as Yup from "yup";

import {getProducts as onGetProducts} from "../../store/ecommerce/action"
import { getContacts as onGetContacts } from "../../store/crm/action"; 
import { updateVente as onUpdateVente } from "../../store/ventes/action";
import { isDateSpansEqual } from "@fullcalendar/react";

const OpportunitesUpdate = (props) => {

  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

    useEffect(() => {
        const fetchOpportunites = async () => {
          try {
            const data  = await axiosIns.get(`/opportunities/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            setFormDataList(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchOpportunites();
      }, [token]);





  document.title = props.t("Modifier opportunités | Le Conseiller du BTP");


  const history = useHistory();
  const dispatch = useDispatch();
  const [ispayementDetails, setIspayementDetails] = useState(null);
  const [isCurrency, setIsCurrency] = useState("$")

    function handleisCurrency(isCurrency){
        setIsCurrency(isCurrency);
    }

  function handleispaymentDetails(ispayementDetails) {
    setIspayementDetails(ispayementDetails);
  }

  const [productList, setProductList] = useState([]);
  const [contactList, setContactList] = useState([]);
  

  const opportunitesId = useParams('id');
 const url = "https://back.crm.inawo.pro/api/v1"

  const [selectContact, setSelectContact] = useState([])
  const [selectCollaborateur, setSelectCollaborateur] = useState([])
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/contacts`);
          setSelectContact(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/collaborateurs`);
          setSelectCollaborateur(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);
       
  const alltypes = [
    {
      options: [
        { label: props.t("Sélectionner le type d'opportunité"), value: "" },
        { label: props.t("Contact initialisé"), value: 'contact initiated' },
        { label: props.t('Piste découverte'), value: 'lead discovered'},
        { label: props.t('Reunion arrangée'), value: 'meeting arranged'},
        { label: props.t('Besoin identifié'), value: 'need identified'},
        { label: props.t('Offre acceptée'), value: 'offer accepted'}
      ],
    },
  ];
                                                                                                                                                                                                                                                                                                                                                                                                                       
      const dateFormat = () => {
        let d = new Date(),
          months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
      };
    
      const [date, setDate] = useState(dateFormat());
    
      const dateformate = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
        setDate(joinDate);
      };



      const [FormDataList, setFormDataList] = useState({
        "type": null,
        "title": "",
        "value": null,
        "date": null,
        "description": "",
        "owner": null,
        "contact": null,
      });
        useEffect(() => {
            const fetchData = async ()=> {
                try {
                    const data = await axios.get(`${url}/opportunities/${opportunitesId.id}`);
                    setFormDataList(data);                  
                    console.log("resultat", data)
                } catch (err) {
                    console.log(err)
                }
            };
            fetchData(); 
        }, [opportunitesId, url])

      console.log("Liste",FormDataList.title)

      const handleInputForm = (e) => {
        setFormDataList({ ...FormDataList, [e.target.name]: e.target.value });
      };

      const [errors, setErrors] = useState({});

      const validate = () => {
        const newErrors = {};
        if (!FormDataList.title) {
          console.log("title",FormDataList.title)
          newErrors.title= "Le champ est obligatoire";
        }
        if (!FormDataList.type) {
          newErrors.type = "Le champ est obligatoire";
        }
        if (!FormDataList.value) {
          newErrors.value = "Le champ est obligatoire";
        }
        if (!FormDataList.date) {
          newErrors.date = "Le champ est obligatoire";
        }
        if (!FormDataList.description) {
          newErrors.description = "Le champ est obligatoire";
        }
        // if (!FormData.owner) {
        //   newErrors.owner = "Le champ est obligatoire";
        // }
        if (!FormDataList.contact) {
          newErrors.contact = "Le champ est obligatoire";
        }
      
          setErrors(newErrors);
          return Object.keys(newErrors).length === 0;
      };

      const submitHandler = async (e) => {
        e.preventDefault();
        if (validate()) {

        const updateOpportunites = {
          "type": FormDataList.type,
          "title": FormDataList.title,
          "value": FormDataList.value,
          "date": FormDataList.date,
          "description": FormDataList.description,
          "owner": FormDataList.owner,
          "contact": FormDataList.contact
        }
        console.log("opportinites", updateOpportunites);
        try {
          const { data } = await axiosIns.patch(`/opportunities/${opportunitesId.id}/`, updateOpportunites, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          history.push("/apps-opportunites");
          // window.location.reload(true)
          toast.success(props.t("Opportunité modifiée avec succès"));
        } catch (err) {
          console.log(err);
        }
      }
    }
  
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={props.t("Modifier une opportunité")} pageTitle={props.t("Opportunités")} />
                <Row className="justify-content-center">
                  <Col xxl={9}>
                    <Card>
                      <Form onSubmit={submitHandler}>
                        <CardBody className="p-4">
                          <Row className="g-3">
                            <Col lg={12}>
                              <div className='mb-2'>
                                <Label for='libelle_depense'>
                                  {props.t("Libellé")}
                                </Label>  <span style={{color:'red'}}>*</span>
                                <Input 
                                  type="text"
                                  className="form-control "
                                  name="title"
                                  onChange={handleInputForm}
                                  value={FormDataList.title}
                                  invalid={errors.title ? true : false}
                                />
                                    {errors.title  ? (
                                      <FormFeedback type="invalid">{errors.title}</FormFeedback>
                                    ) : null}
                              </div>
                            </Col>
                            <Col lg={12}>
                            <div className="mb-2">
                                <Label
                                  for="libelle"
                                  className="form-label"
                                >
                                  {props.t("Catégorie d'opportunités")}
                                </Label>  <span style={{color:'red'}}>*</span>
                                <div className="input-light">
                                                <Input
                                                  name="type"
                                                  type="select"
                                                  className="form-control"
                                                  onChange={handleInputForm}
                                                  value={ FormDataList.type  }
                                                  invalid={errors.type ? true : false}
                                                >
                                                  {alltypes.map((item, key) => (
                                                      <React.Fragment key={key}>
                                                        {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                                      </React.Fragment>
                                                    ))}
                                                  </Input>
                                    {errors.type  ? (
                                      <FormFeedback type="invalid">{errors.type  }</FormFeedback>
                                    ) : null}
                                  </div>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className='mb-2'>                                                                                                                                                       
                                <Label for='value'>
                                  {props.t("Nombre")}
                                </Label>
                                <Input 
                                  type="number"
                                  className="form-control "
                                  name="value"
                                  onChange={handleInputForm}
                                  value={FormDataList.value }
                                  invalid= { errors.value ? true: false}
                                />
                                {errors.value  ? (
                                      <FormFeedback type="invalid">{errors.value}</FormFeedback>
                                    ) : null}
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className='mb-2'>                                                                                                                                                       
                                <Label for='date'>
                                  {props.t("Date")}
                                </Label>
                                <Input 
                                  type="date"
                                  className="form-control "
                                  name="date"
                                  onChange={handleInputForm}
                                  value={FormDataList.date }
                                  invalid= { errors.date ? true: false}
                                />
                                {errors.date ? (
                                      <FormFeedback type="invalid">{errors.date}</FormFeedback>
                                    ) : null}
                              </div>
                            </Col>
                            <Col lg={12}>
                            <div className="mb-2">
                                <Label
                                  for="Responsable"
                                  className="form-label "
                                >
                                  {props.t("Responsable")}
                                </Label>
                                <div className="input-light">
                                                <Input
                                                  name="owner"
                                                  type="select"
                                                  className="form-control"
                                                  onChange={handleInputForm}
                                                  value={ FormDataList.owner }
                                                  invalid={errors.owner ? true : false}
                                                >
                                                  <option value="">{props.t("Sélectionner le collaborateur")}</option>
                                                  {selectCollaborateur.map((item, key) => (
                                                      <React.Fragment key={key}>
                                                        <option  key={key} value={item.id}>{item.nom}</option>
                                                      </React.Fragment>
                                                    ))}
                                                  </Input>
                                  
                                  </div>
                              </div>
                            </Col>
                            <Col lg={12}>
                            <div className="mb-2">
                                <Label
                                  for="contact"
                                  className="form-label "
                                >
                                  {props.t("Contact")}
                                </Label>
                                <div className="input-light">
                                                <Input
                                                  name="contact"
                                                  type="select"
                                                  className="form-control"
                                                  onChange={handleInputForm}
                                                  value={ FormDataList.contact}
                                                  invalid={errors.contact? true : false}
                                                >
                                                  <option value="">{props.t("Sélectionner le contact")}</option>
                                                  {selectContact.map((item, key) => (
                                                      <React.Fragment key={key}>
                                                        <option key={key} value={item.id}>{item.nom}</option>
                                                      </React.Fragment>
                                                    ))}
                                                  </Input>
                                    {errors.contact  ? (
                                      <FormFeedback type="invalid">{errors.contact }</FormFeedback>
                                    ) : null}
                                  </div>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className='mb-2'>
                                <Label for='libelle_depense'>
                                  {props.t("Description")}
                                </Label>
                                <textarea 
                                  type="text"
                                  className="form-control"
                                  name="description"
                                  onChange={handleInputForm}
                                  value={FormDataList.description}
                                />
                                    {errors.description  ? (
                                      <FormFeedback type="invalid">{errors.description}</FormFeedback>
                                    ) : null}
                              </div>
                            </Col>
                          </Row>
                          <div className="float-right mt-3 mb-3">
                            <button type="submit" className="btn btn-info">
                              {props.t("Enregistrer")}
                            </button>
                          </div>
                        </CardBody>
                      </Form>
                    </Card>
                  </Col>
                </Row>
            </Container>
        </div>
    )
}
OpportunitesUpdate.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(OpportunitesUpdate));