import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { 
  Card,
  CardBody,
  Col,
  Container,
  CardHearder,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  CardHeader,
 } from 'reactstrap';
 import * as moment from 'moment';
 import { Link } from "react-router-dom";
 import Select from "react-select";
 import classnames from "classnames";
 import Flatpickr from "react-flatpickr";
 import BreadCrumb
  from '../../Components/Common/BreadCrumb';
import TableContainer from '../../Components/Common/TableContainer';
import DeleteModal  from '../../Components/Common/DeleteModal';
import { isEmpty } from "lodash";
import axios from 'axios';
import { axiosIns } from '../../utils';

// import { ventesData } from  "../../common/data"

// Formik
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";

// redux
import { useSelector, useDispatch } from "react-redux";

import { useReducer } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

// Import Actions 
import {
  getventes as onGetVentes,
  addNewVente as onAddNewVente,
  updateVente as onUpdateVente,
  deleteVente as onDeleteVente,
} from "../../store/ventes/action";
import { getContacts as onGetContacts } from "../../store/crm/action"; 

import Loader from '../../Components/Common/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ExportCSVModal from '../../Components/Common/ExportCSVModal';

const Ventes = (props) => {

  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [venteList, setVenteList] = useState([]);
  const [vente, setVente] = useState(null);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedMulti, setSelectedMulti] = useState(null);

  const {ventes, isVenteSuccess, crmcontacts } = useSelector((state) => ({
    ventes: state.Vente.ventes,
    isVenteSuccess: state.Vente.isVenteSuccess,
    crmcontacts: state.Crm.crmcontacts,
  }));

   // Récuperer le type d'abonnement
   const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
   useEffect(() => {
     const checkTokenValidity = async () => {
         try {
         const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
         const getLastAbonnement = response.length - 1
         const currentTypeAbonnement = response[getLastAbonnement].pricing
         setTypeAbonnementCourant(currentTypeAbonnement)
         } catch (error) {
         console.log(error)
         } 
     };
     checkTokenValidity();
   }, []);

   const token = JSON.parse(sessionStorage.getItem('authUser'))
    ? JSON.parse(sessionStorage.getItem('authUser')).access
    : null;

    const [factures, setFactures] = useState([]);
    useEffect(() => {
        const fetchFactures = async () => {
          try {
            const { data } = await axiosIns.get(`/factures/`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            });
            console.log("test2", data);

            const dateSysteme = new Date();
            const moisActuel = dateSysteme.getMonth();
            const facturesFiltrees = data.filter(facture => {
              const moisFacture = parseInt(facture.dateFacture.split("T")[0].slice(5, 7)) - 1;
              return moisFacture === moisActuel;
            });
            console.log("test3", facturesFiltrees);
            setFactures(facturesFiltrees);
          } catch (err) {
            console.log(err);
          }
        };
        fetchFactures();
        }, [token]);
      

  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    if (crmcontacts && !crmcontacts.length) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContactList(crmcontacts);
  }, [crmcontacts]);
  console.log("Contacts", crmcontacts);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) setContactList(crmcontacts);
  }, [crmcontacts]);


  const handleVenteClicks = () => {
    setVente("");
    setIsEdit(false);
    toggle();
  };

  const onClickDelete = (vente) => {
    setVente(vente);
    setDeleteModal(true);
  }

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".productCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const handleDeleteVente = () => {
    if (vente){
      dispatch(onDeleteVente(vente._id));
      setDeleteModal(false);
    }
  }

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }

  function handleMulti(ventes, selectedMulti) {
    setSelectedMulti(selectedMulti);
  }


  useEffect(() => {
    if (ventes && !ventes.length) {
      dispatch(onGetVentes());
    }
  }, [dispatch, ventes]);

  useEffect(()=> {
    setVenteList(ventes);
  }, [ventes]);
  console.log("ventes", venteList);

  useEffect(()=> {
    if (!isEmpty(ventes))
      setVenteList(ventes);
  }, [ventes]);

  console.log("ven", venteList);

  const venteCon = useCallback((j)=> {
    for(let i=0;i<=contactList.length; i++){
      if( j===contactList[i]?.id){
        return (contactList[i]?.nom === "" ? contactList[i]?.nomEntreprise : contactList[i]?.nom) ;
       }
     }
   }, [contactList])
  console.log("cat",venteCon(4));

  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  const [realVente, setRealVente] = useState([]);
  useEffect(() => {
    if (!isEmpty(venteList)) {
      const updatedVentes = venteList.map(vente => ({
        ...vente,
        contact: venteCon(parseInt(vente.contact))
      }));
      setRealVente(updatedVentes);
    }
  }, [venteList, venteCon]);
  
  console.log("details", realVente);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setVente(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const [dele, setDele] = useState(0);

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let h = (d.getHours() % 12) || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
  };


  const [date, setDate] = useState(defaultdate());

  const dateformate = (e) => {
    const dateString = e.toString().split(" ");

    let time = dateString[4];
    let H = +time.substr(0, 2);
    let h = (H % 12) || 12;
    h = (h <= 9) ? h = ("0" + h) : h;
    let ampm = H < 12 ? "AM" : "PM";
    time = h + time.substr(2, 3) + " " + ampm;

    const date = dateString[2] + " " + dateString[1] + ", " + dateString[3];
    const venteDate = (date + ", " + time).toString();
    setDate(venteDate);

  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
    return updateTime;
  };

  const ConId = (j)=> {
    for(let i=0;i<=contactList.length; i++){
      if( j===contactList[i]?.nom){
       return (contactList[i]?.id) ;
      }
    }
  }


  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteVente(element.value));
    });
    checkall.checked = false;
  };

  const handleVenteClick = useCallback((arg) => {
    const vente = arg;
    setVente({
      id: vente.id,
      numVente: vente.numVente,
      dateVente: vente.dateVente,
      statusVente: vente.statusVente,
      statusLivraison: vente.statusLivraison,
      statusPaiement: vente.statusPaiement,
      encaissementRecu: vente.encaissementRecu,
      contact: vente.contact,
      commentaire: vente.commentaire,
      detailsVente: [
        {
          produit: vente.produit,
          qteVendu: vente.qteVendu,
        }
      ]

    });

    setIsEdit(true);
    toggle();
  }, [toggle]);


  const columns = useMemo(
    () => [
      {
        Header: props.t("No."),
        Cell: (vente)=> (
          <>
            <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                {/* <Link
                  to={`/apps-ecommerce-product-details/${vente.row.original.id}/`}
                  className="text-dark"
                > */}
                  {" "}
                  {(vente.row.original.numVente)}
                {/* </Link> */}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Contacts"),
        accessor: "contact",
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  <Link to={`/apps-crm-contact/${ConId(vente.row.original.contact)}`}>
                      { vente.row.original.contact }
                  </Link>  
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Date"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {vente.row.original.dateVente}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Vente"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {/* <span className='badge badge-soft-danger me-1'>{vente.row.original.statusVente}</span> */}
                 {
                    vente.row.original.statusVente === "En Cours" || vente.row.original.statusVente === "E"  ? <span className='badge badge-soft-secondary me-1'>{props.t("En cours")}</span> : <span className='badge badge-soft-success me-1'>{props.t("Terminé")}</span>
                 }  
              </h5>
            </div>
          </div>
          </>
        )
      },
        {
          Header: props.t("Paiement"),
          Cell: (vente) => ( 
            <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                    {" "}
                    {
                      vente.row.original.statusPaiement === "En Cours" || vente.row.original.statusVente === "E"  ? <span className='badge badge-soft-secondary me-1'>{props.t("En cours")}</span> : vente.row.original.statusPaiement === "Non Payé" ? <span className='badge badge-soft-danger me-1'>{props.t("Non payé")}</span> : <span className='badge badge-soft-success me-1'>{props.t("Payé")}</span>
                  }  
                </h5>
              </div>
            </div>
            </>
          )
        },
      {
        Header: props.t("Livraison"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {
                    vente.row.original.statusLivraison === "En Cours" || vente.row.original.statusVente === "E"  ? <span className='badge badge-soft-secondary me-1'>{props.t("En cours")}</span> : <span className='badge badge-soft-success me-1'>{props.t("Terminé")}</span>
                 }  
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: props.t("Encaissé"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                 {vente.row.original.encaissementRecu} 
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: "Total",
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                 {vente.row.original.montant === null ? 0 : vente.row.original.montant} 
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: props.t("Impayé"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                 {vente.row.original.montant === null ? 0 : vente.row.original.montant - vente.row.original.encaissementRecu} 
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: props.t("Actions"),
        Cell: (cellProps) => {
          const venteData = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
             <li className="list-inline-item" title={props.t("Modifier")}>
              <Link to={`/apps-vente-update/${venteData.id}/`} className="text-warning d-inline-block">
                   <i className="ri-pencil-fill align-bottom"></i>
               </Link>
             </li>
           </ul>
          );
        },
      },
    ],
    [venteCon]
  );

  document.title = props.t("Ventes | Le Conseiller du BTP");

  return (
    <div className='page-content'>
      <ToastContainer closeButton={false} />
        <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteVente}
        onCloseClick={()=> setDeleteModal(false)}
        />
        <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={()=> {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
         onCloseClick={()=> setDeleteModalMulti(false)} 
        />
        <Container fluid>
          <BreadCrumb title={props.t("Ventes")} pageTitle={props.t("Liste des ventes")} />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className='card-header border-0'>
                  <div className="d-flex.align-items-center">
                    <div></div>
                    <button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          setModal(true);
                        }}
                      ><Link to={`/apps-vente-add`} className="text-white d-inline-block">
                        <i className="ri-add-fill me-1 align-bottom"></i> {props.t("Ajouter une vente")} </Link>
                      </button>
                  </div>  
                </CardHeader>
                </Card>
                <Card id='orderList'>
                <CardBody className="pt-0">
                <div>
                  { isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
                  (realVente && realVente.length > 0) ? (
                    <TableContainer
                        columns={columns}
                        data={(realVente || [])}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={8}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                  ) : (
                    <div className="py-4 text-center">
                       
                      <div className="mt-4">
                        <h5>{props.t("Sorry! No Result Found")}</h5>
                      </div>
                    </div>
                  )}
                  
                </div>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </Container>
    </div>
  )

}

Ventes.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default  withRouter(withTranslation()(Ventes));
