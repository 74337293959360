// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_FACTURES = "GET_FACTURES";

// Add DOCUMENT
export const ADD_FACTURE = "ADD_FACTURE";
export const ADD_FACTURE_SUCCESS = "ADD_FACTURE_SUCCESS";
export const ADD_FACTURE_FAIL = "ADD_FACTURE_FAIL";

// Update DOCUMENT
export const UPDATE_FACTURE = "UPDATE_FACTURE";
export const UPDATE_FACTURE_SUCCESS = "UPDATE_FACTURE_SUCCESS";
export const UPDATE_FACTURE_FAIL = "UPDATE_FACTURE_FAIL";

// Delete DOCUMENT
export const DELETE_FACTURE = "DELETE_FACTURE";
export const DELETE_FACTURE_SUCCESS = "DELETE_FACTURE_SUCCESS";
export const DELETE_FACTURE_FAIL = "DELETE_FACTURE_FAIL";
