import {
    
    GET_FACTURES,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

    ADD_FACTURE,
    ADD_FACTURE_SUCCESS,
    ADD_FACTURE_FAIL,

    UPDATE_FACTURE,
    UPDATE_FACTURE_SUCCESS,
    UPDATE_FACTURE_FAIL,

    DELETE_FACTURE,
    DELETE_FACTURE_SUCCESS,
    DELETE_FACTURE_FAIL

} from './actionType'

// common success
export const factureApiResponseSuccess = (actionType, response) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, response }
})

//common error
export const factureApiResponseError =(actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error }
})

export const getFactures =() => ({
    type: GET_FACTURES,
})

export const addNewFacture = facture => ({
    type: ADD_FACTURE,
    payload: facture,
})

export const addNewFactureSuccess = facture => ({
    type: ADD_FACTURE_SUCCESS,
    payload: facture,
})

export const addNewFactureFail = error => ({
    type: ADD_FACTURE_FAIL,
    payload: error,
})

export const updateFacture = facture => ({
    type: UPDATE_FACTURE,
    payload: facture,
})

export const updateFactureSuccess = facture => ({
    type: UPDATE_FACTURE_SUCCESS,
    payload: facture,
})

export const updateFactureFail = error => ({
    type: UPDATE_FACTURE_FAIL,
    payload: error,
})

export const deleteFacture = facture => ({
    type: DELETE_FACTURE,
    payload: facture,
})

export const deleteFactureSuccess = facture => ({
    type: DELETE_FACTURE_SUCCESS,
    payload: facture,
})

export const deleteFactureFail = error => ({
    type: DELETE_FACTURE_FAIL,
    payload: error,
})