import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, Col, Container, Alert, Row, Form, FormFeedback, Input, Button, Label } from 'reactstrap';

import AuthSlider from '../authCarousel';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, apiError, resetRegisterFlag } from "../../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import ParticlesAuth from "../ParticlesAuth";
// phone input
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountrySelect, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import 'react-phone-number-input/mobile'

const CoverSignUp = () => {
    document.title = "Inscription | Le Conseiller du BTP";
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmpasswordShow, setConfirmPasswordShow] = useState(false);

    // phone input
    const [phoneinput, setPhoneinput] = useState()
    const [valid, setValid] = useState()


    const validation = useFormik({
        enableReinitialize: false,

        initialValues: {
            username:'',
            email: '',
            password: '',
            phone_number: !phoneinput ? false : phoneinput,
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, 'Le mot de passe doit comporter au moins 8 caractères')
                .matches(RegExp('(.*[a-z].*)'), 'Au moins une lettre minuscule')
                .matches(RegExp('(.*[A-Z].*)'), 'Au moins une lettre majuscule')
                .matches(RegExp('(.*[0-9].*)'), 'Au moins un chiffre')
                .required("Ce champ est obligatoire"),
            username: Yup.string()
                .required("Ce champ est obligatoire"),
            email: Yup.string()
                .required("Ce champ est obligatoire"),
        }),
        onSubmit: (values) => {
            const user = {
                email: values.email,
                password: values.password,
                phone_number:phoneinput,
                username:values.username
            }
            console.log(user);
            dispatch(registerUser(user));
        }
    });

    const handleClick = () => {
        setIsLoading(true);
        // Simulate an asynchronous action (e.g., API call) here
        setTimeout(() => {
        setIsLoading(false);
        }, 4000); // Change this to match the actual loading time

        // You can add your actual login logic here
    };

    const { error, registrationError, success } = useSelector(state => ({
        registrationError: state.Account.registrationError,
        success: state.Account.success,
        error: state.Account.error
    }));

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            setTimeout(() => history.push("/auth-success-msg"), 4000);
            sessionStorage.setItem("langsuccess", "fr");
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]);

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-wrapper  py-5 d-flex justify-content-center align-items-center min-vh-100">
                    <div style={{
                        backgroundColor: "#FFFFFF", opacity: 0, width: "100%", height: "100%", position: "absolute", top: 0,
                        left: 0
                    }}></div>
                    <div className="auth-page-content overflow-hidden pt-lg-5">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <Card className="overflow-hidden m-0">
                                        <Row className="justify-content-center g-0">
                                            <AuthSlider />

                                            <Col lg={6}  style={{ backgroundColor: "#ECECEE" }}>
                                                <div className="p-lg-5 p-4">
                                                    <div>
                                                        <h5 className="text-primary">Bienvenue sur la plateforme d'insciption.</h5>
                                                        <p className="text-muted">Remplissez le formulaire ci-dessous pour vous inscrire.  </p>
                                                    </div>

                                                    <div className="mt-4">
                                                        <Form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                            className="needs-validation" action="#">

                                                            {success && success ? (
                                                                <>
                                                                    {toast("Vous serez redirigé vers la page consulter votre mail...", { position: "top-right", hideProgressBar: false, className: 'bg-success text-white', progress: undefined, toastId: "" })}
                                                                    <ToastContainer autoClose={2000} limit={1} />
                                                                    <Alert color="success">
                                                                        Utilisateur enregistré avec succès !
                                                                    </Alert>
                                                                </>
                                                            ) : null}

                                                            {error && error ? (
                                                                <Alert color="danger"><div>
                                                                    {/* {registrationError} */}
                                                                    L'email ou le numéro de téléphone a déjà été enregistré, veuillez utiliser une autre valeur...
                                                                </div></Alert>
                                                            ) : null}
                                                            <Row>
                                                                
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <Input type="text" className="form-control" placeholder="Identifiant" id="username"
                                                                            name="username"
                                                                            value={validation.values.username}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.username && validation.touched.username ? true : false} />
                                                                        {validation.errors.username && validation.touched.username ? (
                                                                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <Input type="email" className="form-control" id="email" placeholder="Adresse Email" name="email"
                                                                            value={validation.values.email}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.email && validation.touched.email ? true : false} />
                                                                        {validation.errors.email && validation.touched.email ? (
                                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <div className="position-relative auth-pass-inputgroup">
                                                                            <Input
                                                                                type={passwordShow ? "text" : "password"}
                                                                                className="form-control pe-5 password-input"
                                                                                placeholder="Mot de passe"
                                                                                id="password-input"
                                                                                name="password"
                                                                                value={validation.values.password}
                                                                                onBlur={validation.handleBlur}
                                                                                onChange={validation.handleChange}
                                                                                invalid={validation.errors.password && validation.touched.password ? true : false}
                                                                            />
                                                                            {validation.errors.password && validation.touched.password ? (
                                                                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                            ) : null}
                                                                            <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                                id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <PhoneInputWithCountrySelect
                                                                            defaultCountry="US"
                                                                            international
                                                                            placeholder="Téléphone"
                                                                            id="phone_number"
                                                                            name="phone_number"
                                                                            numberInputProps={{
                                                                                className: `form-control ${!valid ? 'border-danger' : 'border-success'}`
                                                                            }}
                                                                            value={validation.values.phone_number|| ""}
                                                                            onChange={(e) => {
                                                                                if(!isNaN(e))
                                                                                {
                                                                                    if(isPossiblePhoneNumber(e))
                                                                                    {
                                                                                        setPhoneinput(e)
                                                                                        setValid(true)
                                                                                    }
                                                                                    else{
                                                                                        setPhoneinput(e)
                                                                                        setValid(false)
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className={`text-danger ${valid ? 'd-none' : 'd-block'}`}>{valid === false ? 'Numéro de téléphone invalide' : ''}</label>
                                                                    </div>
                                                                </Col>

                                                                {/* <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Input type="text" className="form-control" placeholder="Prénom(s)" id="first_name"
                                                                            name="first_name"
                                                                            value={validation.values.first_name}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.first_name && validation.touched.first_name ? true : false} />
                                                                        {validation.errors.first_name && validation.touched.first_name ? (
                                                                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                                                        ) : null}

                                                                    </div>
                                                                </Col> */}
                                                                {/* 
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <div className="position-relative auth-pass-inputgroup">
                                                                            <Input
                                                                                name="confirm_password"
                                                                                type={confirmpasswordShow ? "text" : "password"}
                                                                                placeholder="Confirmer mot de passe"
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation.values.confirm_password || ""}
                                                                                invalid={
                                                                                    validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                                                }
                                                                            />
                                                                            {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                                                <FormFeedback type="invalid"><div>{validation.errors.confirm_password}</div></FormFeedback>
                                                                            ) : null}
                                                                            <Button color="link" onClick={() => setConfirmPasswordShow(!confirmpasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                                id="password-addon-2"><i className="ri-eye-fill align-middle"></i></Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <Input type="text" className="form-control" id="adresse" placeholder="Adresse"
                                                                            name="adresse"
                                                                            value={validation.values.adresse}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.adresse && validation.touched.adresse ? true : false} />
                                                                        {validation.errors.adresse && validation.touched.adresse ? (
                                                                            <FormFeedback type="invalid">{validation.errors.adresse}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col> */}
                                                            </Row>
                                                            <Row>
                                                                {/* <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <Input type="text" className="form-control" id="fonction" placeholder="Fonction "
                                                                            name="fonction"
                                                                            value={validation.values.fonction}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.fonction && validation.touched.fonction ? true : false} />
                                                                        {validation.errors.fonction && validation.touched.fonction ? (
                                                                            <FormFeedback type="invalid">{validation.errors.fonction}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col> */}
                                                                {/* <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <Input type="text" className="form-control" id="societe" placeholder="Société"
                                                                            name="societe"
                                                                            value={validation.values.societe}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.societe && validation.touched.societe ? true : false} />
                                                                        {validation.errors.societe && validation.touched.societe ? (
                                                                            <FormFeedback type="invalid">{validation.errors.societe}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col> */}
                                                                {/* <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="nbrEmploye" value={validation.values.nbrEmploye} onChange={validation.handleChange}>
                                                                            <option value="">--- Nombre d'employés ---</option>
                                                                            <option value="10">1-10</option>
                                                                            <option value="50">11-50</option>
                                                                            <option value="500">51-500</option>
                                                                            <option value="1000">500 Et Plus</option>
                                                                        </Input>
                                                                    </div>
                                                                </Col> */}
                                                            </Row>

                                                            <Row>
                                                          
                                                                {/* <Col md={4}>
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="specificSizeSelect">Ville</label>
                                                                    <select className="form-select" data-choices data-choices-sorting="true" id="ville">
                                                                        <option value="">--- Choose ---</option>
                                                                        <option defaultValue="1">Cotonou</option>
                                                                        <option defaultValue="2">Calavi</option>
                                                                        <option defaultValue="3">Kpota</option>
                                                                        <option defaultValue="4">hevie</option>
                                                                    </select>
                                                                </div>
                                                            </Col> */}

                                                                {/* <Col md={12}>
                                                                    <div className="mb-3">
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="langue" value={validation.values.langue} onChange={validation.handleChange}>
                                                                            <option value="">--- Langue ---</option>
                                                                            <option value="Eng">Anglais</option>
                                                                            <option value="Fr">Français</option>
                                                                        </Input>
                                                                    </div>
                                                                </Col> */}

                                                            </Row>






                                                            <div className="mb-4">
                                                                <p className="mb-0 fs-12 text-muted fst-normal">En vous inscrivant, vous acceptez nos <a href="https://inawo.pro" className="text-primary text-decoration-underline fst-normal fw-medium">Conditions Générales de Vente</a>, nos <a href="https://inawo.pro" className="text-primary text-decoration-underline fst-normal fw-medium">Conditions d'utilisation</a> et à notre <a href="https://inawo.pro/a_propos/mentions_legales" className="text-primary text-decoration-underline fst-normal fw-medium">Politique de confidentialité</a></p>
                                                            </div>

                                                            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                                <h5 className="fs-13">Le mot de passe doit contenir :</h5>
                                                                <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 caractères</b></p>
                                                                <p id="pass-lower" className="invalid fs-12 mb-2">Une <b>lettre (a-z)</b>minuscule</p>
                                                                <p id="pass-upper" className="invalid fs-12 mb-2">Au moins une <b>lettre (A-Z)</b> majuscule</p>
                                                                <p id="pass-number" className="invalid fs-12 mb-0">Au moins un <b>nombre</b> (0-9)</p>
                                                            </div>

                                                            <div className="mt-4">
                                                                <Button
                                                                    className={`btn w-100 ${isLoading ? 'loading' : ''}`}
                                                                    style={{ height: "40px" }}
                                                                    type="submit"
                                                                    onClick={handleClick}
                                                                    >
                                                                    {isLoading ? (
                                                                        <div className="loader"></div>
                                                                    ) : (
                                                                        "S'inscrire"
                                                                    )}
                                                                </Button> 
                                                            </div>


                                                        </Form>
                                                    </div>

                                                    <div className="mt-4 text-center">
                                                        <p className="mb-0">Vous avez déjà un compte ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Se connecter</Link> </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                            </Row>
                        </Container>
                    </div>

                    {/* <footer className="footer">
                    <Container>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0">{new Date().getFullYear()} InawoCRM. Crafted with <i className="mdi mdi-heart text-danger"></i> by Le Conseiller du BTP Technologies</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </footer> */}
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default CoverSignUp;