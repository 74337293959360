import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";

import { Container, Row } from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import TableContainer from "../../Components/Common/TableContainer";

//redux
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const Formations = (props) => {
  const token = JSON.parse(sessionStorage.getItem("authUser"))
    ? JSON.parse(sessionStorage.getItem("authUser")).access
    : null;

  // Definition des etats
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  const tokenInfo = JSON.parse(sessionStorage.getItem("authUser")).email;

  const [formations, setFormations] = useState([]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axios.get(
          "https://backend.leconseillerdubtp.com/api/v1/formations/",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setFormations(response);
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidity();
  }, []);

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const handleDeleteProduct = () => {
    if (formations) {
      setDeleteModal(false);
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      del.style.display = "none";
    });
  };

  const role = JSON.parse(sessionStorage.getItem("authUser"))?.role.some(
    (role) => role === "assistant"
  );

  // const users =(j)=>{
  //   for(let i=0;i<=userList.length;i++){
  //     if(j===userList[i]?.id){
  //       return (userList[i]?.first_name+" "+userList[i]?.last_name);
  //     }
  //   }
  // }
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="entrepriseCheckBox"
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: props.t("TITRE"),
        accessor: "title",
        Cell: (formation) => (
          <>
            <div
              className="d-flex align-items-center"
              style={{
                width: 200,
                height: 50,
                overflow: "clip",
                verticalAlign: "middle",
                textTransform: "uppercase",
              }}
            >
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1" style={{ fontWeight: "bold" }}>
                  <Link className="text-dark">
                    {" "}
                    {formation.row.original.title}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("FORMATION"),
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                {moment().isAfter(
                  moment(formation.row.original.end_date),
                  "day"
                ) && formation.row.original.published ? (
                  <h5
                    className="mb-1 badge badge-soft-danger "
                    style={{ fontSize: "10px" }}
                  >
                    Cloturée
                  </h5>
                ) : moment().isBetween(
                    moment(formation.row.original.start_date),
                    moment(formation.row.original.end_date),
                    "day",
                    "[]"
                  ) && formation.row.original.published ? (
                  <h5
                    className="mb-1 badge badge-soft-success"
                    style={{ fontSize: "10px" }}
                  >
                    En cours
                  </h5>
                ) : moment().isBefore(
                    moment(formation.row.original.start_date),
                    "day"
                  ) && formation.row.original.published ? (
                  <h5
                    className="mb-1 badge badge-soft-primary "
                    style={{ fontSize: "10px" }}
                  >
                    A venir
                  </h5>
                ) : !formation.row.original.published ? (
                  <h5
                    className="mb-1 badge badge-soft-secondary "
                    style={{ fontSize: "10px" }}
                  >
                    En attente de validation
                  </h5>
                ) : null}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("DESCRIPTION"),
        accessor: "description",
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                {formation.row.original.description ? (
                  <h5
                    className="fs-14 mb-1"
                    style={{
                      width: 200,
                      height: 50,
                      overflow: "clip",
                      verticalAlign: "middle",
                    }}
                  >
                    <Link
                      to={`/#/${formation.row.original.id}/`}
                      className="text-dark"
                    ></Link>
                    {formation.row.original.description}
                  </h5>
                ) : (
                  <h5 className="fs-14 mb-1">Pas de description</h5>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("DOMAINE"),
        accessor: "domain",
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="mb-1 badge badge-soft-warning ">
                  <Link> {formation.row.original.domain}</Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("LIEU"),
        accessor: "lieu",
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link className="text-muted">
                    {" "}
                    {formation.row.original.lieu}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("FORMATEUR"),
        accessor: "formateur",
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link className="text-muted">
                    {" "}
                    {formation.row.original.formateur}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("NBR INSCRITS"),
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to={`/#/${formation.row.original.id}/`}
                    className="text-muted"
                  ></Link>
                  {formation.row.original.registers_count}
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("FICHIER"),
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                {formation.row.original.formation_file ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      className="text-xs rounded"
                      href={formation.row.original.formation_file}
                      target="_blank"
                      title="Télécharger"
                      rel="noreferrer"
                    >
                      <i
                        style={{
                          fontSize: "30px",
                          width: "50%",
                          height: "50%",
                          color: "red",
                        }}
                        className="ri-file-download-fill align-bottom"
                      ></i>
                    </a>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      style={{ fontSize: "30px", width: "50%", height: "50%" }}
                      className="ri-link align-bottom"
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("AUTEUR"),
        accessor: "initiator",
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 ">
                  <i className="p-2 align-items-center ">
                    {formation.row.original.initiator
                      ? formation.row.original.initiator.username
                      : "-"}
                  </i>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: props.t("STATUT"),
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                {formation.row.original.published === false ? (
                  <div>
                    <h5 className="mb-1 badge badge-soft-warning me-1 ">
                      En attente
                    </h5>
                  </div>
                ) : (
                  <p className="mb-1 badge badge-soft-success me-1"> Publiée</p>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: " ",
        Cell: (formation) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14  p-2">
                  <span className="mt-4">
                    {formation.row.original.published === false && !role && (
                      <div>
                        <div className="btn btn-soft-success">
                          <Link
                            to={`apps-formations-details/${formation.row.original.id}/`}
                          >
                            Valider
                          </Link>
                        </div>
                      </div>
                    )}
                  </span>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "ACTIONS",
        Cell: (cellProps) => {
          const formationData = cellProps.row.original;
          return (
            <>
              <li className="list-inline-item" title={props.t("Modifier")}>
                <Link
                  to={`apps-formations-update/${formationData.id}`}
                  className="text-warning d-inline-block"
                >
                  <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>

              <li className="list-inline-item" title={props.t("Voir")}>
                <Link
                  to={`apps-formations-details/${formationData.id}/`}
                  className="text-warning d-inline-block"
                >
                  <i className="ri-eye-fill align-bottom"></i>
                </Link>
              </li>
            </>
          );
        },
      },
    ],
    [props]
  );

  document.title = props.t("Liste des formations");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb
          title={props.t("Liste des formations")}
          pageTitle={props.t("Listes des formations")}
        />

        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  {isLoading ? ( // If loading, show loading indicator
                    <div className="py-4 text-center">
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/xjovhxra.json"
                          trigger="loop"
                          colors="primary:#0960b6,secondary:#02376b"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                      </div>
                      <div className="mt-4">
                        <h5>{props.t("Chargement")}...</h5>
                      </div>
                    </div>
                  ) : formations ? (
                    <div>
                      <TableContainer
                        columns={columns}
                        data={formations || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        divClass="table-responsive mb-1"
                        tableClass="mb-0 align-middle "
                        theadClass="table-light text-muted"
                        isFormationFilter={true}
                      />
                    </div>
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
Formations.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Formations));
