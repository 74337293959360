// import React, { useEffect, useState} from "react";
// import BreadCrumb from "../../../Components/Common/BreadCrumb";
// import axios from 'axios';
// import PropTypes from 'prop-types';
// import { withRouter } from "react-router-dom";
// import { withTranslation } from "react-i18next";
// import { toast } from 'react-toastify';
// import { EditorState} from 'draft-js';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import 'react-image-gallery/styles/css/image-gallery.css';
// import {
//   Card,
//   Col,
//   Container,
//   Row,
//   Input,
//   Label,
//   FormFeedback,
//   Form,
// } from "reactstrap";

// // Redux
// import { useDispatch } from "react-redux";
// import { Link, useHistory } from "react-router-dom";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { registerPlugin } from "react-filepond";
// import "filepond/dist/filepond.min.css";
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


// // Register the plugins
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// const ArticlesAdd= (props) => {
 
//   document.title = "Création d'articles ";
//   const history = useHistory();
//   const dispatch = useDispatch();
//   const [customActiveTab, setcustomActiveTab] = useState("1");
//   const toggleCustom = (tab) => {
//     if (customActiveTab !== tab) {
//       setcustomActiveTab(tab);
//     }
//   };

//   const [selectedFiles, setselectedFiles] = useState([]);
//   const [selectedGroup, setselectedGroup] = useState(null);
//   const [selectedStatus, setselectedStatus] = useState(null);
//   const [selectedVisibility, setselectedVisibility] = useState(null);
//   const [categoryList, setCategoryList] = useState([]);

//   const [isChecked, setIsChecked] = useState(false);

//   const handleCheckboxChange = () => {
//     setIsChecked(!isChecked);
//   };

//   function handleAcceptedFiles(files) {
//     files.map((file) =>
//       Object.assign(file, {
//         preview: URL.createObjectURL(file),
//         formattedSize: formatBytes(file.size),
//       })
//     );
//     setselectedFiles(files);
//   }



//   /**
//    * Formats the size
//    */
//   function formatBytes(bytes, decimals = 2) {
//     if (bytes === 0) return "0 Bytes";
//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

//     const i = Math.floor(Math.log(bytes) / Math.log(k));
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
//   }

//   const dateFormat = () => {
//     let d = new Date(),
//       months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     let h = (d.getHours() % 12) || 12;
//     let ampm = d.getHours() < 12 ? "AM" : "PM";
//     return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
//   };

//   const [date, setDate] = useState(dateFormat());

//   const dateformate = (e) => {
//     const dateString = e.toString().split(" ");
//     let time = dateString[4];
//     let H = +time.substr(0, 2);
//     let h = (H % 12) || 12;
//     h = (h <= 9) ? h = ("0" + h) : h;
//     let ampm = H < 12 ? "AM" : "PM";
//     time = h + time.substr(2, 3) + " " + ampm;

//     const date = dateString[2] + " " + dateString[1] + ", " + dateString[3];
//     const orderDate = (date + ", " + time).toString();
//     setDate(orderDate);
//   };

  
//   const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
//   const url = "https://backend.leconseillerdubtp.com/api/v1";
//   if(token)
//   axios.defaults.headers.common["Authorization"] = "Bearer " + token;


//     const [selectedFile, setSelectedFile] = useState(null);

//     const handleFileChange = (event) => {
//       const file = event.target.files[0];
//       setSelectedFile(file);
//     };

//     const [category, setCategory] = useState([]);

//     useEffect(() => {
//         const fetchData = async () => {
//           try {
//             const data  = await axios.get(`${url}/categories/`);
//             setCategory(data)
//           } catch (err) {
//             console.log(err);
//           }
//         };
//         fetchData();
//       }, [url]);


//   const validation = useFormik({
//     enableReinitialize: true,

//     initialValues: {
//         "title": "",
//         "content": "",
//         "article_pic": null,
//         "category": null,
//     },
    
//     validationSchema: Yup.object().shape({
//       "title": Yup.string().required("Ce champ est requis"),
//       "content": Yup.string().required("Ce champ est requis")
//      }),
//     onSubmit: (values) => {
//           const formData= new FormData();
//           formData.append("title",values.title);
//           formData.append("content",values.content);
//           formData.append("category",values.category);
//           selectedFile && formData.append("article_pic",selectedFile);
//           axios.post(`${url}/articles/`,formData)
//           .then(res =>{
//             if(res){
//               toast.success(props.t("Article bien ajouté"),{autoClose:3000})
//               validation.resetForm();
//               history.push("/apps-articles");
//               setTimeout(()=>window.location.reload(true),2000);
//             }
//           }, (error) =>{
//             if(error){
//               toast.error(props.t("Article non ajouté"),{autoClose:3000})
//               validation.resetForm();
//               history.push("/apps-articles");
//             }}
//           )
          
//     }
//   })
//   return (
//     <div className="page-content">
//       <Container fluid>
//         <BreadCrumb title={props.t("Création d'article")} pageTitle={props.t("Article")} />
//         <Form encType="multipart/form-data"
//               onSubmit={(e) => {
//                 e.preventDefault();
//                 validation.handleSubmit(); 
//               }}
//               >
//         <Row>
//           <Col lg={12}>
//             <Card className="p-4">
//             <div className="mb-3" >
//                       <Label className="form-label" htmlFor="photo">
//                         {props.t("IMAGE")}   <span style={{color: "red"}}>* Tous les champs sont requis </span>
//                       </Label>
//                           <div>
//                               <input 
//                               type="file"
//                                className="form-control" 
//                                name="article_pic" 
//                                accept="image/jpeg"
//                                capture="file"
//                                onChange={handleFileChange}
//                                />
//                                <br />
//                                 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
//                                   {selectedFile ? <img src={URL.createObjectURL(selectedFile)} style={{maxWidth:'50%'}} alt={`Thumbnail`} /> :null}
//                                 </div>
//                           </div>
//                       {validation.errors.article_pic && validation.touched.article_pic? (
//                         <FormFeedback type="invalid">{props.t(validation.errors.article_pic)}</FormFeedback>
//                       ) : null}
//                       <br />
//                     </div>
//                     <div className="mb-3">
//                       <Label className="form-label" htmlFor="title">
//                         {props.t("TITRE")}  <span style={{color: "red"}}>*</span>
//                       </Label>
//                       <Input
//                         type="text"
//                         className="form"
//                         name="title"
//                         value={validation.values.title}
//                         onBlur={validation.handleBlur}
//                         onChange={validation.handleChange}
//                         invalid={validation.errors.title && validation.touched.title ? true : false}
//                       />
//                       {validation.errors.title && validation.touched.title? (
//                         <FormFeedback type="invalid">{props.t(validation.errors.title)}</FormFeedback>
//                       ) : null}
//                     </div>
                
//                     <div className="mb-3">
//                       <Label className="form-label" htmlFor="category">
//                         {props.t("CATEGORIE")}  <span style={{color: "red"}}>*</span>
//                       </Label>
//                       <Input
//                         type="select"
//                         className="form-select"
//                         name="category"
//                         value={validation.values.category}
//                         onBlur={validation.handleBlur}
//                         onChange={validation.handleChange}
//                       >
//                         <option value="" className="form-select">Sélectionner une catégorie</option>
//                         {category.map((item,key)=> (
//                             <option value={item.id} key={key}>{item.designation}</option>
//                         ))
//                         }
//                     </Input>
                      
//                     </div>
                
//                     <div className="mb-3">
//                       <Label className="form-label" htmlFor="content"> {props.t("CONTENU")}  <span style={{color: "red"}}>*</span></Label>
//                       <textarea
//                        className="form-control"
//                        name="content"
//                        value={validation.values.content}
//                        onBlur={validation.handleBlur}
//                        onChange={validation.handleChange}
//                        rows="20"
//                        cols="50"
//                       />
//                        {validation.errors.content && validation.touched.content? (
//                         <FormFeedback type="invalid">{props.t(validation.errors.content)}</FormFeedback>
//                       ) : null}
//                     </div>

//             </Card>
//               <div className="text-end mb-3">
//                 <button className="btn btn-primary mr-6">
//                   <Link
//                     to={`apps-articles`}
//                     className="text-white"
//                   >
//                     {props.t("Retour")}
//                   </Link>
//                 </button>
//                 <button type="submit" className="btn btn-warning m-2">
//                    {props.t("Ajouter")}
//                 </button>
//               </div> 
//           </Col>
//         </Row>
//    </Form>
//       </Container>
//     </div>
//   );
// };
// ArticlesAdd.propTypes={
//   location:PropTypes.object,
//   t:PropTypes.any
// }
// export default withRouter(withTranslation()(ArticlesAdd));

import React, { useState } from "react";
import newId from "../../../newid";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { axiosIns } from "../../../utils";
import {CardBody,Table}from "reactstrap";
import { useDispatch } from "react-redux";
import { useReducer } from "react";
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Label,
  Form,
} from "reactstrap";


const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM': {
      return { ...state, itemlist: [...state.itemlist, action.payload] };
    }
    case 'REMOVE_ITEM': {
      const newItemListR = state.itemlist.filter(
        (item) => item.id !== action.payload.id
      );
      return { ...state, itemlist: newItemListR };
    }

    case 'UPDATE_SUBTITLE': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }

    case 'UPDATE_PHOTO': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }

    case 'UPDATE_CONTENT': {
      const newItem = action.payload;
      const existItem = state.itemlist.find((item) => item.id === newItem.id);
      const newItemList = existItem
        ? state.itemlist.map((item) =>
            item.id === existItem.id ? newItem : item
          )
        : [...state.itemlist, newItem];
      return { ...state, itemlist: newItemList };
    }

    default:
      return state;
  }
};

const ArticlesAdd = (props) => {

  const [categoryList, setCategoryList] = useState([]);

  const [state, invDispatch] = useReducer(reducer, {
    itemlist: [
      {
        id: newId(),
        subtitle: '',
        content: '',
        paragraph_pic:''
      },
    ],
  });
  const { itemlist } = state;
  const addItem = () => {
    invDispatch({
      type: 'ADD_ITEM',
      payload: {
        id: newId(),
        subtitle: '',
        content: '',
        paragraph_pic: null
      },
    });
  };

  const removeItem = (item) => {
    invDispatch({
      type: 'REMOVE_ITEM',
      payload: item,
    });
  };

  const updateSubtitleHandler = async (item, subtitle) => {
    invDispatch({
      type: 'UPDATE_SUBTITLE',
      payload: { ...item, subtitle},
    });
  }



  const updateContentHandler = async (item, content) => {
    invDispatch({
      type: 'UPDATE_CONTENT',
      payload: { ...item, content},
    });
   }


   const updatePhotoHandler = async (item, paragraph_pic) => {
    invDispatch({
      type: 'UPDATE_PHOTO',
      payload: { ...item, paragraph_pic},
    });
   }

    const dispatch = useDispatch();
    const history = useHistory();
    const formData = new FormData();

    document.title = props.t("Ajout d'article | Le Conseiller du BTP");


      const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
      const url = "https://backend.leconseillerdubtp.com/api/v1";
      if(token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    
        useEffect(() => {
        const fetchData = async () => {
          try {
            const data  = await axios.get(`${url}/categories/`);
            setCategoryList(data)
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
      }, [url]);
  
    const [Data, setData] = useState({
        "title": "",
        "category": null
    });
  
    const handleInputForm = (e) => {
      setData({ ...Data, [e.target.name]: e.target.value });
    };

    const [errors, setErrors] = useState({}); 

    const validate = () => {
      const newErrors = {};
      if (!Data.title) {
        newErrors.title = "Ce champ est requis";
      }

      if (!Data.category) {
        newErrors.category = "Ce champ est requis";
      }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
  

    const submitHandler = async (e) => {
      e.preventDefault();
      if (validate()) {
        const paragraphsList = [];
        
        for (let index = 0; index < itemlist.length; index++) {
          const paragraph = itemlist[index];
          formData.append(`paragraphs[${index}].paragraph_pic`, paragraph.paragraph_pic);
          try {
            const { data } = await axiosIns.patch(`/paragraphs/`,payload, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
          });
          } catch (err) {
          console.log(err)
        }

        itemlist.map((item) =>
          paragraphsList.push({
            subtitle: item.subtitle,
            content: item.content,
            })
          );

        const payload  = {
          title:Data.title,
          category:Data.category,
          paragraphs:paragraphsList,
        }


          try {
          const { data } = await axiosIns.post(`/articles/`,payload, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          console.log(data);
          history.push("/apps-articles");
          toast.success(props.t("Article bien ajouté"));
        } catch (err) {
          history.push("/apps-articles");
          toast.error(props.t("Article non ajouté"));
        }
      }
      
  }
}
  

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={props.t("Ajout d'article")} pageTitle={props.t("Articles")} />
                <Row className="justify-content-center">
                    <Col xxl={12}>
                        <Card>
                            <Form
                            encType="mutipart/form-data"
                            onSubmit={submitHandler}
                            className="needs-validation"
                            >
                                <CardBody className="p-4">
                                <Row>
                                  <Col xl={12}>
                                    <div className="mb-3">
                                    <Label className="form-label" >
                                      {props.t("TITRE")} <span style={{color: "red"}}>*</span>
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form"
                                      name="title"
                                      value={Data.title}
                                      onChange={handleInputForm}
                                    />
                                    {errors.title && <p className="text-danger">{props.t(errors.title)}</p>}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl={12}>
                                    <div className="mb-3">
                                      <Label className="form-label"> {props.t("CATEGORIE")}  <span style={{color: "red"}}>*</span></Label>
                                      <Input
                                        className="form-control"
                                        name="category"
                                        type="select"
                                        onChange={handleInputForm}
                                      >
                                        <option value="">Sélectionner une catégorie d'articles</option>
                                          {categoryList.map((item,key)=> (
                                              <option value={item.id} key={key}>{item.designation}</option>
                                          ))
                                          }
                                      </Input>
                                      {errors.category && <p className="text-danger">{props.t(errors.category)}</p>}
                                      
                                    </div>
                                  </Col>
                                </Row>
                                
                                </CardBody>

                                <CardBody className="p-4">
                                  <div className="table-responsive">
                                    <Table className="invoice-table table table-borderless mb-0">
                                        <thead className="align-middle">
                                              <tr className="table-active">
                                                  <th scope="col" style={{ width: "10px" }}>
                                                  </th>
                                                  <th scope="col" style={{ width: "20%" }}>{props.t("SOUS TITRE")} <span className='text-danger'>*</span></th>
                                                 
                                                  <th scope="col" style={{ width: "30%"}}>
                                                    {props.t("CONTENU")} <span className='text-danger'>*</span>
                                                  </th>

                                                  <th scope="col" style={{ width: "30%"}}>
                                                    {props.t("IMAGE")} <span className='text-danger'>*</span>
                                                  </th>
                                              </tr>
                                        </thead>
                                        { itemlist.map((item) => {
                                          return (
                                          <React.Fragment key={item.id}>
                                            <tbody name={'paragraphs'} key={item.id}>
                                                <tr id={item.id} className="product">
                                                  <th scope="row" className="product-id">
                                                    <Label>{itemlist.indexOf(item) + 1}</Label>
                                                  </th>
                                                  <td className="text-start">
                                                    <div className="mb-2">
                                                      <Input 
                                                        type="text"
                                                        className="form-control"
                                                        value={item.subtitle}
                                                        name={`paragraphs[${item}].subtitle`}
                                                        onChange={(e) => {
                                                          updateSubtitleHandler(
                                                            item,
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                  <textarea
                                                    className="form-control"
                                                    name={`paragraphs[${item}].content`}
                                                    value={item.content}
                                                    onChange={(e) => {
                                                      updateContentHandler (
                                                        item,
                                                        e.target.value
                                                      );
                                                    }}
                                                    rows="5"
                                                    cols="20"
                                                  />

                                                  </td>
                                                  <td>
                                                  <Input 
                                                    type="file"
                                                    className="form-control" 
                                                    name={`paragraphs[${item}].paragraph_pic`}
                                                    accept="image/jpeg"
                                                    capture="file"
                                                    onChange={(e) => {
                                                      updatePhotoHandler(
                                                        item,
                                                        e.target.files[0]
                                                      );
                                                    }}
                                                    />
                                                    <br></br>
                                                    {item.paragraph_pic ? <img src={URL.createObjectURL(item.paragraph_pic)} style={{maxWidth:'50%'}} alt={`Thumbnail`} /> :null}

                                                  </td>
                                                  <td id={`product-removal-${item.id}`} className="product-removal">
                                                  <Link
                                                    id={`del-${item.id}`}
                                                    to="#"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                      removeItem(item);
                                                    }}
                                                  >
                                                    {props.t("Supprimer")}
                                                  </Link>
                                                  </td>
                                                </tr>
                                          
                                        </tbody>
                                          </React.Fragment> 
                                         ) 
                                        })
                                        }
                                        <tfoot>
                                        <tr>
                                           <td colSpan="5">
                                                <Link
                                                  to="#"
                                                  className="btn btn-soft-success fw-medium"
                                                  id="add-item"
                                                  onClick={addItem}
                                                >
                                                  <i className="ri-add-fill me-1 align-bottom"></i>{' '}
                                                  {props.t("Ajouter un paragraphe")}
                                                </Link>
                                            </td>
                                          </tr>
                                         
                                        </tfoot>
                                                                              
                                    </Table>
                                  </div>
                                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    <button type="submit" className="btn btn-warning">
                                      <i className=" align-bottom me-1"></i> {props.t("Ajouter")}
                                    </button>
                                  </div> 
                              </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}



ArticlesAdd.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(ArticlesAdd));


