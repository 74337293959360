import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { useReducer } from "react";
import Ecommerce from "../../store/ecommerce/reducer";
import { axiosIns } from "../../utils";
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledCollapse,
  Row,
  Card,
  CardHeader,
  Col,
} from "reactstrap";
import classnames from "classnames";

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../Components/Common/DeleteModal";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
// import { Rating, Published, Price } from "./EcommerceProductCol";
//Import data
import { productsData } from "../../common/data";

//Import actions
import { getProducts as onGetProducts, deleteProducts, getProductsCategories as onGetCategories } from "../../store/ecommerce/action";
import { isEmpty } from "lodash";
import Select from "react-select";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { red } from "@material-ui/core/colors";
import { Cell } from "gridjs";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { GET_PRODUCTS } from "../../store/ecommerce/actionType";

const SingleOptions = [
  { value: 'Watches', label: 'Watches' },
  { value: 'Headset', label: 'Headset' },
  { value: 'Sweatshirt', label: 'Sweatshirt' },
  { value: '20% off', label: '20% off' },
  { value: '4 star', label: '4 star' },
];

const Stock = (props) => {
  const dispatch = useDispatch();

  const { products,categories } = useSelector((state) => ({
    products :state.Ecommerce.products,
    categories :state.Ecommerce.categories
  
  }));
  console.log(products);

  // Definition des etats
  const [productList, setProductList] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedMulti, setselectedMulti] = useState(null);
  const [product, setProduct] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false once the data is fetched or loaded
    }, 2000); // Adjust the time according to your needs
  }, []);

  // Récupérer la liste des contacts
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://back.crm.inawo.pro/api/v1/products/");
        const getLastProduct = response.length
        console.log(getLastProduct)
        setProduct(getLastProduct)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
 useEffect(() => {
   const checkTokenValidity = async () => {
       try {
       const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
       const getLastAbonnement = response.length - 1
       const currentTypeAbonnement = response[getLastAbonnement].pricing
       setTypeAbonnementCourant(currentTypeAbonnement)
       } catch (error) {
       console.log(error)
       } 
   };
   checkTokenValidity();
 }, []);

  function handleMulti(products ,selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  
  const token = JSON.parse(sessionStorage.getItem('authUser'))
  ? JSON.parse(sessionStorage.getItem('authUser')).access
  : null;

  const [prod, setProd] = useState([]);
  const [prod1, setProd1] = useState([]);

  useEffect(() => {
    const fetchProd = async () => {
      try {
        const { data } = await axiosIns.get('/products', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        console.log("Prod", data);
        setProd(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchProd();
  }, []);

  const ProductName = (j)=> {
    for(let i = 0;i<=prod.length; i++){
      if( j === prod[i]?.id){
       return (prod[i]?.designationProd) ;
      }
    }
  }

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const { data } = await axiosIns.get('/stocks', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        console.log("Stock", data);
        setProductList(data);
      } catch (err){
        console.log(err);
      }
    }
    fetchStock();
  }, []);

  console.log("prod1", prod1);

  
  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredProducts = products;
      if (type !== "all") {
        filteredProducts = products.filter((product) => product.status === type);
      }
      setProductList(filteredProducts);
    }
  };
  console.log("product",products);


  // const [cate, setCate] = useState(" ");

  // const categories = (category) => {
  //   let filteredProducts = products;
  //   if (category !== "all") {
  //     filteredProducts = products.filter((product) => product.category === category);
  //   }
  //   setProductList(filteredProducts);
  //   setCate(category);
  // };


  const onUpdate = (value) => {
    setProductList(
      productsData.filter(
        (product) => product.price >= value[0] && product.price <= value[1],
      )
    );
  };

  const [ratingvalues, setRatingvalues] = useState([]);
  /*
  on change rating checkbox method
  */
  const onChangeRating = value => {
    setProductList(productsData.filter(product => product.rating >= value));

    var modifiedRating = [...ratingvalues];
    modifiedRating.push(value);
    setRatingvalues(modifiedRating);
  };


  const onUncheckMark = (value) => {
    var modifiedRating = [...ratingvalues];
    const modifiedData = (modifiedRating || []).filter(x => x !== value);
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const onClickDelete = (product) => {
    setProduct(product);
    setDeleteModal(true);
  };
  
  const handleDeleteProduct = () => {
    if (product) {
      dispatch(deleteProducts(product._id));
      setDeleteModal(false);
    }
  };

  const [dele, setDele] = useState(0);

  // Displat Delete Button
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = 'none';
    } else {
      del.style.display = 'block';
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      dispatch(deleteProducts(element.value));
      del.style.display = 'none';
    });
  };
  const items={"id":null,"designationCat":""}
  const [categoryList,setCategoryList] = useState([items]);
  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(onGetCategories());
    }
  }, [dispatch,categories]);
    

  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(categories)) setCategoryList(categories);
  }, [categories]);

// console.log(categoryList)

  const categgory =(j)=>{
    for(let i=0;i<=categoryList.length;i++){
      if(j===categoryList[i]?.id){
        return categoryList[i]?.designationCat;
      }
    }
  }
  console.log("cat",categgory(14));

  const columns = useMemo(() => [
    // {
    //   Header: " ",
    //   Cell: (cell) => {
    //     return <input type="checkbox" className="productCheckBox" value={cell.row.original.id} onClick={() => displayDelete()} />;
    //   },
    // },
    {
      Header: props.t("Produit"),
      accessor: "product",
      Cell: (product) => (
        <>
          <div className="d-flex align-items-center text-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to={`/apps-ecommerce-product-details/${product.row.original.id}/`}
                  className="text-dark"
                >
                  {" "}
                  {ProductName(product.row.original.product)}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t("Date"),
      Cell: (product) => (
        <>
          <div className="d-flex align-items-center text-center" >
            
            <div className="flex-grow-1" >
              <h5 className="fs-14 mb-1 text-muted">
                {product.row.original.date_mvnt}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("Entrées"),
      Cell: (product) => (
        <>
          <div className="d-flex align-items-center text-center">
            <div className="flex-grow-1">
              <p className="fs-14 mb-1">
                <Link
                to={`/apps-ecommerce-product-details/${product.row.original.id}/`}
                  className="text-dark"
                >
                  {" "}
                  {product.row.original.qte_entre}<i className="ri-arrow-down-fill" style={{color: "#6bff6b"}}></i>
                </Link>
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("Sorties"),
      Cell: (product) => (
        <>
          <div className="d-flex align-items-center text-center">
            <div className="flex-grow-1">
              <p className="fs-14 mb-1">
                <Link
                to={`/apps-ecommerce-product-details/${product.row.original.id}/`}
                  className="text-dark"
                >
                  {" "}
                  {product.row.original.qte_sortie}<i className="ri-arrow-up-fill" style={{color: "#fc4444"}}></i>
                </Link>
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("Stock Dispo"),
      Cell: (product) => (
        <>
          <div className="d-flex align-items-center text-center">
            <div className="flex-grow-1">
              <p className="fs-14 mb-1">
                  {" "}
                  <span style={{color: "#447bfc"}}>{product.row.original.stock_dispo}</span>
                  {" "}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("Stock Sécurité"),
      Cell: (product) => (
        <>
          <div className="d-flex align-items-center text-center">
            <div className="flex-grow-1">
              <p className="fs-14 mb-1">
                  {" "}
                  <span style={{color: "#44fcb0"}}>{product.row.original.stock_securite === null ? 0 : product.row.original.stock_securite}</span>
                  
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("Stock Alerte"),
      Cell: (product) => (
        <>
          <div className="d-flex align-items-center text-center">
            <div className="flex-grow-1">
              <p className="fs-14 mb-1">
                  {" "}
                  <span style={{color: "#fc4444"}}>{product.row.original.stock_alerte === null ? 0 : product.row.original.stock_alerte}</span>
                  
              </p>
            </div>
          </div>
        </>
      ),
    },
  ],
    [categgory,props]
  );
  document.title = props.t("Gestion des stocks | Le Conseiller du BTP");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Stocks")} pageTitle={props.t("Gestion des stocks")} />

        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-body pt-0">
                  { isLoading ? ( // If loading, show loading indicator
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/xjovhxra.json"
                              trigger="loop"
                              colors="primary:#0960b6,secondary:#02376b"
                              style={{ width: "72px", height:"72px" }}>
                          </lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>{props.t("Chargement")}...</h5>
                        </div>
                      </div>
                    ) 
                    :
                    typeAbonnementCourant === 1 || typeAbonnementCourant === 2 || typeAbonnementCourant === 3 ? 
                            (
                                <div className="mt-4">
                                  <h5>{props.t("Ce module n'est pas disponible sur votre formule")}</h5>
                                </div>
                                )
                    :
                        productList ? (
                          <TableContainer
                            columns={columns}
                            data={(productList || [])}
                            isAddUserList={false}
                            customPageSize={10}
                            divClass="table-responsive mb-1"
                            tableClass="mb-0 align-middle table table-striped table-hover"
                            theadClass="table-secondary text-muted text-center"
                            // isProductsFilter={true}
                          />
                        ) : (
                          <div className="py-4 text-center">
                            <div>
                              <lord-icon
                                src="https://cdn.lordicon.com/msoeawqm.json"
                                trigger="loop"
                                colors="primary:#405189,secondary:#0ab39c"
                                style={{ width: "72px", height: "72px" }}
                              ></lord-icon>
                            </div>

                            <div className="mt-4">
                              <h5>{props.t("Désolé, pas de resultats trouvés")}</h5>
                            </div>
                          </div>)
                            }
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
Stock.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(Stock))