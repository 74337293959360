import {
  ADD_NEW_PAYS,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  DELETE_PAYS,
  GET_PAYS,
  UPDATE_PAYS,
} from "./actionType";

const initialState = {
  pays: [],
  oneCountry: {},
  error: null,
};

export default function paysReducer(state = initialState, action) {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PAYS:
          return {
            ...state,
            isLoading: false,
            pays: action.payload.response,
          };
        case ADD_NEW_PAYS:
          return {
            ...state,
            isLoading: false,
            pays: [...state.pays, action.payload.response],
          };
        case UPDATE_PAYS:
          return {
            ...state,
            isLoading: false,
            pays: state.pays.map((pays) =>
              pays._id === action.payload.response._id
                ? action.payload.response
                : pays
            ),
          };
        case DELETE_PAYS:
          return {
            ...state,
            isLoading: false,
            pays: state.pays.filter(
              (pays) => pays._id !== action.payload.response._id
            ),
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
