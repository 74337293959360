import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';

import {
    GET_DOCUMENTS,
    ADD_NEW_DOCUMENT,
    UPDATE_DOCUMENT,
    DELETE_DOCUMENT,
    ADD_DOCUMENT,
} from './actionType';

import {
    documentApiResponseSuccess,
    documentApiResponseError,
    addNewDocumentSuccess,
    addNewDocumentFail,
    updateDocumentSuccess,
    updateDocumentFail,
    deleteDocumentSuccess,
    deleteDocumentFail,
} from './action';

import {
    getDocuments as getDocumentApi,
    addNewDocument,
    updateDocument,
    deleteDocument,
} from "../../helpers/fakebackend_helper";

function* getDocuments() {
    try {
        const response = yield call(getDocumentApi);
        yield put(documentApiResponseSuccess(GET_DOCUMENTS,response));
    } catch(error){
        yield put(documentApiResponseError(GET_DOCUMENTS,error));
    }
}

function* onaddNewDocument({payload:document}) {
    try {
        const response = yield call(addNewDocument, document);
        yield put(addNewDocumentSuccess(response));
        toast.success('Document ajouté avec succès', { autoclose: 3000 });
    } catch(error){
        yield put(addNewDocumentFail(error));
        toast.error('Erreur lors de l\'ajout du document', { autoclose: 3000 });
    }
}

function* onUpdateDocument({payload:document}) {
    try {
        const response = yield call(updateDocument, document);
        yield put(updateDocumentSuccess(response));
        toast.success('Document modifié avec succès', { autoclose: 3000 });
    } catch(error){
        yield put(updateDocumentFail(error));
        toast.error('Erreur lors de la modification du document', { autoclose: 3000 });
    }
}

function* onDeleteDocument({payload:document}) {
    try {
        const response = yield call(deleteDocument, document);
        yield put(deleteDocumentSuccess({document, ...response}));
        toast.success('Document supprimé avec succès', { autoclose: 3000 });
    } catch(error){
        yield put(deleteDocumentFail(error));
        toast.error('Erreur lors de la suppression du document', { autoclose: 3000 });
    }
}

export function* watchGetDocuments() {
    yield takeEvery(GET_DOCUMENTS, getDocuments);
}

export function* watchAddNewDocument() {
    yield takeEvery(ADD_DOCUMENT, onaddNewDocument);
}

export function* watchUpdateDocument() {
    yield takeEvery(UPDATE_DOCUMENT, onUpdateDocument);
}

export function* watchDeleteDocument() {
    yield takeEvery(DELETE_DOCUMENT, onDeleteDocument);
}

function* documentSaga() {
    yield all([
        fork(watchGetDocuments),
        fork(watchAddNewDocument),
        fork(watchUpdateDocument),
        fork(watchDeleteDocument),
    ]);
}

export default documentSaga;