import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { object, string } from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { getProvince, updateCity } from "../../../store/actions";
import { axiosIns } from "../../../utils";

const UpdateCity = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [city, setCity] = useState({});
  const [province, setProvince] = useState([]);
  const { province: provinceList } = useSelector((state) => ({
    province: state.Province.provinces,
  }));

  const handleGetCity = async () => {
    try {
      const response = await axiosIns.get(`cities/${id}/`);
      if (response.status === 200) {
        setCity(response.data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getProvince());
    handleGetCity();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(provinceList)) {
      setProvince(provinceList);
    }
  }, [provinceList]);

  document.title = props.t("Modification d'une ville - Le Conseiller du BTP");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: city?.name || "",
      province: city?.province || "",
    },

    validationSchema: object().shape({
      name: string().required(props.t("Veuillez entrer le nom de la ville")),
      province: string().required(props.t("Veuillez choisir une province")),
    }),

    onSubmit: (values) => {
      const updatedCity = {
        ...city,
        name: values.name,
        province: values.province,
      };
      dispatch(updateCity(updatedCity));
      history.push("/apps-geolocalisation-city");
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t("Ajout d'une ville")}
          pageTitle={props.t("Ville")}
        />

        <Row>
          <Col lg={12}>
            <Form
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="province">
                      {props.t("Province")}
                    </Label>
                    <Input
                      type="select"
                      className="form"
                      placeholder={props.t("Entrer le nom de la province")}
                      name="province"
                      value={validation.values.province}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    >
                      <option value="">
                        {props.t("Choisir une province")}
                      </option>
                      {province.map((p) => (
                        <option key={p.id} value={p.id}>
                          {p.name}
                        </option>
                      ))}
                    </Input>
                    {validation.errors.province &&
                    validation.touched.province ? (
                      <FormFeedback type="invalid">
                        {validation.errors.province}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="name">
                      {props.t("Nom de la ville")}
                    </Label>
                    <Input
                      type="text"
                      className="form"
                      placeholder={props.t("Entrer le nom de la ville")}
                      name="name"
                      value={validation.values.name}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      required
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button className="btn btn-primary mr-3">
                  <Link
                    to={`/apps-geolocalisation-ville`}
                    className="text-white"
                  >
                    {props.t("Retour")}
                  </Link>
                </button>
                <button type="submit" className="btn btn-warning w-sm m-2">
                  {props.t("Valider")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

UpdateCity.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UpdateCity));
