import React from 'react';
import axios from 'axios';
import { Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,Row,Col } from "reactstrap";
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
// import avatar10 from "../../../assets/images/users/avatar-10.jpg";
// import avatar8 from "../../../assets/images/users/avatar-8.jpg";
// import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
const TimeTracking = (props) => {
 
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;

  const taskId = useParams('id');
  const url = "https://back.crm.inawo.pro/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [selectTask, setSelectTask] = useState({})
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/tasks/${taskId.id}/`);
          setSelectTask(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [taskId, url]);
  
//   collaborateur
  const [collaborateur,setCollaborateur]=useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data  = await axios.get(`${url}/collaborateurs/`);
            setCollaborateur(data)
            console.log(data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
  
      }, [url]);
      const categgory =(j)=>{
        for(let i=0;i<=collaborateur.length;i++){
          if(j===collaborateur[i]?.id){
            return (collaborateur[i]?.first_name+" "+ collaborateur[i]?.last_name +" | "+ collaborateur[i]?.fonction);
          }
        }
      } 
  
// abonnee
const [abonne,setAbonne]=useState({"id":null,"first_name":"","last_name":""});

useEffect(() => {
    const fetchData = async () => {
      try {
        const data  = await axios.get(`${url}/profile/`);
        setAbonne(data)
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();

  }, [url]);
  const abonnee =(j)=>{
      if(j===abonne.id){
        return (abonne.first_name+" "+ abonne.last_name);
      }
  } 
    return (
        <React.Fragment >
          <Row>
          <Col lg={8}>
            <Card className="mb-3"style={{height:"350px"}}>
                    <CardBody>
                        <div className="table-card">
                            <table className="table " style={{marginTop:"20px"}}>
                                <tbody >
                                    <tr>
                                        <td className="fw-medium" > <b>{props.t("Nom de la tâche")}</b> </td>
                                        <td>{selectTask.taskname}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium"> <b>{props.t("Création de la tâche")}</b></td>
                                        <td>
                                          {selectTask.taskdate}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium"> <b>{props.t("Date d'échéance")}</b></td>
                                        <td>
                                          {/* selectTask.expiry <br></br> */}
                                        {new Date(selectTask.expiry).getFullYear() >=new Date().getFullYear() && new Date(selectTask.expiry).getMonth() >= new Date().getMonth() ? (<div className="badge bg-success text-uppercase"> {props.t("En cours")} </div>) :(<div className="badge bg-danger text-uppercase"> {props.t("Expirée")} </div>)  }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium"> <b>{props.t("Priorité")}</b> </td>
                                        <td>
                                        <React.Fragment>
                                            {selectTask.priority === "moyenne" ?
                                                <span className="badge bg-warning text-uppercase">{props.t(selectTask.priority)}</span>
                                                :
                                                selectTask.priority === "haute" ?
                                                    <span className="badge bg-danger text-uppercase">{props.t(selectTask.priority)}</span>
                                                    : selectTask.priority === "faible" ?
                                                        <span className="badge bg-success text-uppercase">{props.t(selectTask.priority)}</span>
                                                        : null
                                            }
                                        </React.Fragment>
                                        </td>
                                        {/* <td><span className="badge badge-soft-danger">High</span></td> */}
                                    </tr>
                                    <tr>
                                        <td className="fw-medium"><b>{props.t("Statut")}</b> </td>
                                        <td>
                                        <React.Fragment>
                                            {selectTask.status === "en cours" ?
                                                <span className="badge badge-soft-secondary text-uppercase">{props.t(selectTask.status)}</span>
                                                :
                                                selectTask.status === "nouveau" ?
                                                    <span className="badge badge-soft-info text-uppercase">{props.t(selectTask.status)}</span>
                                                    : selectTask.status === "complet" ?
                                                        <span className="badge badge-soft-success text-uppercase">{props.t(selectTask.status)}</span>
                                                        : selectTask.status === "en attente" ?
                                                            <span className="badge badge-soft-warning text-uppercase">{props.t(selectTask.status)}</span>
                                                            : null
                                            }
                                        </React.Fragment>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td className="fw-medium"><b>{props.t("Description")}</b> </td>
                                        <td>{selectTask.description}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={4}>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex mb-3">
                        <h6 className="card-title mb-0 flex-grow-1">{props.t("Responsable de la tache")}</h6>
                        {/* <div className="flex-shrink-0">
                            <button type="button" className="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#inviteMembersModal"><i className="ri-share-line me-1 align-bottom"></i> Tâche assignée</button>
                        </div> */}
                    </div>
                    
                            <div className="d-flex align-items-center">
                                
                                <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1"><Link to="/#">{categgory(selectTask.taskresponsable)}</Link></h6>
                                    <p className="text-muted mb-0">{console.log("dd",collaborateur.fonction)}</p>
                                </div>
                                
                            </div>
                </div>
            </div>
            </Col>
            

          </Row>
            
            
        </React.Fragment>
    );
};

TimeTracking.propTypes={
  location:PropTypes.object,
  t:PropTypes.any
}
export default withRouter(withTranslation()(TimeTracking));
