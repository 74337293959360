import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// type abonnement Redux States
import {
  GET_TYPE_ABONNEMENTS,
  ADD_NEW_TYPE_ABONNEMENT,
  UPDATE_TYPE_ABONNEMENT,
} from "./actionType";

import {
  typeabonnementApiResponseSuccess,
  typeabonnementApiResponseError,
} from "./action";

//Include Both Helper File with needed methods
import {
  getTypeAbonnements as getTypeAbonnementsApi,
} from "../../helpers/fakebackend_helper";

function* getTypeAbonnements() {
  try {
    const response = yield call(getTypeAbonnementsApi);
    yield put(typeabonnementApiResponseSuccess(GET_TYPE_ABONNEMENTS, response));
  } catch (error) {
    yield put(typeabonnementApiResponseError(GET_TYPE_ABONNEMENTS, error));
  }
}

export function* watchGetTypeAbonnements() {
  yield takeEvery(GET_TYPE_ABONNEMENTS, getTypeAbonnements);
}

function* typeabonnementSaga() {
  yield all([
    fork(watchGetTypeAbonnements)
  ]);
}

export default typeabonnementSaga;
