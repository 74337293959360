/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {Container,Row, Col, Card, CardBody, TabContent, TabPane,Nav, NavItem, NavLink, Input, Modal,ModalHeader, ModalFooter, FormFeedback, Label, Form, ModalBody } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from "../../../Components/Common/DeleteModal";
import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import 'react-phone-number-input/style.css'
import 'react-phone-number-input/mobile'
import TableContainer from '../../../Components/Common/TableContainer';
import { useMemo } from 'react';
import classnames from 'classnames';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import CountUp from "react-countup";
import FeatherIcon from "feather-icons-react";
import { axiosIns } from '../../../utils';

//redux

const Team = (props) => {
  document.title = props.t("Utilisateurs | Le Conseiller du BTP");
 
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  // Definition des etats
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); 
    }, 2000); 
  }, []);

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [modal, setModal] = useState(false);
  const [types, setTypes] = useState({});

  const toggle = useCallback(() => {
      if (modal) {
        setModal(false);
        setTypes({});
      } else {
        setModal(true);
      }
      
    }, [modal]);

    const [isEdit, setIsEdit] = useState(false);

console.log(types)
    const validation = useFormik({
      enableReinitialize: true,
  
      initialValues: {
        "banca_certificate":(types && types.banca_certificate )|| " "
      },
      
  
      onSubmit: (values) => {
        if(isEdit){
          const updateCategory={
              banca_certificate:values.banca_certificate
          }
          axios.patch(`https://backend.leconseillerdubtp.com/api/v1/professional/${types?.professional?.id}/certify/`,updateCategory,
          {
            Authorization: 'Bearer ' + token
          })
                .then(res =>{
                  if(res){
                    toast.success(props.t("Professionnel certifié"),{autoClose:3000})
                    
                  }
                }, (error) =>{
                  if(error){
                    toast.error(props.t("Professionnel non certifié"),{autoClose:3000})
                  }}
                )
                validation.resetForm();
                setModal(false);
                setTimeout(()=>window.location.reload(true),3000)
        }
  
    } 
    });
  
  
    const handleContactClick = useCallback((arg) => {
      setTypes({
        id: arg && arg.id,
        profile:arg && arg.profile,
        email: arg && arg.email,
        username: arg && arg.username,
        organisation:null,
        image_str:null,
        filename:null,
        professional: {
          id: arg && arg?.professional && arg?.professional?.id,
          banca_certificate:arg?.banca_certificate
        }
      });
      setIsEdit(true);
      toggle();
    }, [toggle]);

    console.log(types)


  const [recrutements, setRecrutements] = useState([]);
  const url = "https://backend.leconseillerdubtp.com/api/v1/users/list/"

  const [dashboard, setDashboard] = useState({})

  useEffect(() => {
     const fetch = async () => {
      try {
        const {data} = await axiosIns.get(`/dashboard/`, {
          headers: {
            Authorization : 'Bearer ' + token,
          },
        });
        setDashboard(data)
      } catch (err) {
        console.log(err);
      }
     }
      fetch();
  }, [token]);
  

  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + token
          },
        });
        setRecrutements(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, [ url]);


  const [recrutements1, setRecrutements1] = useState([]);
{/* organismes */}
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://backend.leconseillerdubtp.com/api/v1/organismes/", {
          headers: {
            Authorization: 'Bearer ' + token
          },
        });
        setRecrutements1(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

{/* entreprises */}
const [recrutements2, setRecrutements2] = useState([]);
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://backend.leconseillerdubtp.com/api/v1/enterprisesList/", {
          headers: {
            Authorization: 'Bearer ' + token
          },
        });
        setRecrutements2(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

{/* professionnels */}

const [recrutements3, setRecrutements3] = useState([]);
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://backend.leconseillerdubtp.com/api/v1/professionals/", {
          headers: {
            Authorization: 'Bearer ' + token
          },
        });
        setRecrutements3(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  const [recrutements4, setRecrutements4] = useState([]);
  useEffect(() => {
    const checkTokenValidity = async () => {
        try {
        const response = await axios.get("https://backend.leconseillerdubtp.com/api/v1/particulars/", {
          headers: {
            Authorization: 'Bearer ' + token
          },
        });
        setRecrutements4(response)
        } catch (error) {
        console.log(error)
        } 
    };
    checkTokenValidity();
  }, []);

  console.log(recrutements3)
  
  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  
  const handleDeleteProduct = () => {
    if (recrutements) {
      // dispatch(deleteProducts(articles.id));
      setDeleteModal(false);
    }
  };

  console.log(recrutements4)

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      // dispatch(deleteProducts(element.value));
      del.style.display = 'none';
    });
  };

  const name =(j)=>{
    for(let i=0;i<=recrutements.length;i++){
      if(j===recrutements[i]?.email){
        if(recrutements[i].profile){
          const profil= recrutements[i].profile
          return profil.last_name;
        }else{
          return ""
        }
      }
    }
  }

  const name1 =(j)=>{
    for(let i=0;i<=recrutements1.length;i++){
      if(j===recrutements1[i]?.email){
        if(recrutements1[i].profile){
          const profil= recrutements1[i].profile
          return profil.last_name;
        }else{
          return ""
        }
      }
    }
  }

  const name2 =(j)=>{
    for(let i=0;i<=recrutements2.length;i++){
      if(j===recrutements2[i]?.email){
        if(recrutements2[i].profile){
          const profil= recrutements2[i].profile
          return profil.last_name;
        }else{
          return ""
        }
      }
    }
  }


  const name3 =(j)=>{
    for(let i=0;i<=recrutements3.length;i++){
      if(j===recrutements3[i]?.email){
        if(recrutements3[i].profile){
          const profil= recrutements3[i].profile
          return profil.last_name;
        }else{
          return ""
        }
      }
    }
  }


  const name4 =(j)=>{
    for(let i=0;i<=recrutements4.length;i++){
      if(j===recrutements4[i]?.email){
        if(recrutements4[i].profile){
          const profil= recrutements4[i].profile
          return profil.last_name;
        }else{
          return ""
        }
      }
    }
  }

  const prenom =(j)=>{
    for(let i=0;i<=recrutements.length;i++){
      if(j===recrutements[i]?.email){
        if(recrutements[i].profile){
          const prenom= recrutements[i].profile
          return prenom.first_name;
        }else{
          return " "
        }
      }
    }
  }


  const prenom1 =(j)=>{
    for(let i=0;i<=recrutements1.length;i++){
      if(j===recrutements1[i]?.email){
        if(recrutements1[i].profile){
          const prenom= recrutements1[i].profile
          return prenom.first_name;
        }else{
          return " "
        }
      }
    }
  }

  const prenom2 =(j)=>{
    for(let i=0;i<=recrutements2.length;i++){
      if(j===recrutements2[i]?.email){
        if(recrutements2[i].profile){
          const prenom= recrutements2[i].profile
          return prenom.first_name;
        }else{
          return " "
        }
      }
    }
  }


  const prenom3 =(j)=>{
    for(let i=0;i<=recrutements3.length;i++){
      if(j===recrutements3[i]?.email){
        if(recrutements3[i].profile){
          const prenom= recrutements3[i].profile
          return prenom.first_name;
        }else{
          return " "
        }
      }
    }
  }

  const prenom4 =(j)=>{
    for(let i=0;i<=recrutements4.length;i++){
      if(j===recrutements4[i]?.email){
        if(recrutements4[i].profile){
          const prenom= recrutements4[i].profile
          return prenom.first_name;
        }else{
          return " "
        }
      }
    }
  }

  const country =(j)=>{
    for(let i=0;i<=recrutements.length;i++){
      if(j===recrutements[i]?.email){
        if(recrutements[i].profile){
          const country= recrutements[i].profile
          return country.country;
        }else{
          return " "
        }
      }
    }
  }

  const country1 =(j)=>{
    for(let i=0;i<=recrutements1.length;i++){
      if(j===recrutements1[i]?.email){
        if(recrutements1[i].profile){
          const country= recrutements1[i].profile
          return country.country;
        }else{
          return " "
        }
      }
    }
  }

  const country2 =(j)=>{
    for(let i=0;i<=recrutements2.length;i++){
      if(j===recrutements2[i]?.email){
        if(recrutements2[i].profile){
          const country= recrutements2[i].profile
          return country.country;
        }else{
          return " "
        }
      }
    }
  }

  const country3 =(j)=>{
    for(let i=0;i<=recrutements3.length;i++){
      if(j===recrutements3[i]?.email){
        if(recrutements3[i].profile){
          const country= recrutements3[i].profile
          return country.country;
        }else{
          return " "
        }
      }
    }
  }

  const country4 =(j)=>{
    for(let i=0;i<=recrutements4.length;i++){
      if(j===recrutements4[i]?.email){
        if(recrutements4[i].profile){
          const country= recrutements4[i].profile
          return country.country;
        }else{
          return " "
        }
      }
    }
  }

  const picture =(j)=>{
    for(let i=0;i<=recrutements.length;i++){
      if(j===recrutements[i]?.id){
        if(recrutements[i].profile){
          const picture= recrutements[i].profile
          return picture.profile_pic;
        }else{
          return " "
        }
      }
    }
  }

  const picture1 =(j)=>{
    for(let i=0;i<=recrutements1.length;i++){
      if(j===recrutements1[i]?.id){
        if(recrutements1[i].profile){
          const picture= recrutements1[i].profile
          return picture.profile_pic;
        }else{
          return " "
        }
      }
    }
  }

  const picture2 =(j)=>{
    for(let i=0;i<=recrutements2.length;i++){
      if(j===recrutements2[i]?.id){
        if(recrutements2[i].profile){
          const picture= recrutements2[i].profile
          return picture.profile_pic;
        }else{
          return " "
        }
      }
    }
  }

  const picture3 =(j)=>{
    for(let i=0;i<=recrutements3.length;i++){
      if(j===recrutements3[i]?.id){
        if(recrutements3[i].profile){
          const picture= recrutements3[i].profile
          return picture.profile_pic;
        }else{
          return " "
        }
      }
    }
  }

  const picture4 =(j)=>{
    for(let i=0;i<=recrutements4.length;i++){
      if(j===recrutements4[i]?.id){
        if(recrutements4[i].profile){
          const picture= recrutements4[i].profile
          return picture.profile_pic;
        }else{
          return " "
        }
      }
    }
  }

  const type =(j)=>{
    for(let i=0;i<=recrutements.length; i++){
      if(j===recrutements[i]?.email){
        if(recrutements[i].profile){
          const type= recrutements[i].profile
          return type.user_type;
        }else{
          return " "
        }
      }
    }
  }

  const type1 =(j)=>{
    for(let i=0;i<=recrutements1.length; i++){
      if(j===recrutements1[i]?.email){
        if(recrutements1[i].profile){
          const type= recrutements1[i].profile
          return type.user_type;
        }else{
          return " "
        }
      }
    }
  }

  const type2 =(j)=>{
    for(let i=0;i<=recrutements2.length; i++){
      if(j===recrutements2[i]?.email){
        if(recrutements2[i].profile){
          const type= recrutements2[i].profile
          return type.user_type;
        }else{
          return " "
        }
      }
    }
  }

  const type3 =(j)=>{
    for(let i=0;i<=recrutements3.length; i++){
      if(j===recrutements3[i]?.email){
        if(recrutements3[i].profile){
          const type= recrutements3[i].profile
          return type.user_type;
        }else{
          return " "
        }
      }
    }
  }

  const type4 =(j)=>{
    for(let i=0;i<=recrutements4.length; i++){
      if(j===recrutements4[i]?.email){
        if(recrutements4[i].profile){
          const type= recrutements4[i].profile
          return type.user_type;
        }else{
          return " "
        }
      }
    }
  }


  const getCollaboratorAssistantCount = () => {
    if(recrutements){
      const collaboratorUsers = recrutements.filter(user => user.profile ? user.profile.user_type==='assistant' : "" );
      return collaboratorUsers.length;
    } else{
      return 0
    }
    
  };

  const getCollaboratorCommiteCount = () => {
    if(recrutements){
      const collaboratorUsers = recrutements.filter(user => user.profile ? user.profile.user_type==='commite' : "" );
      return collaboratorUsers.length;
    } else{
      return 0
    }
  };

  const getProfessionelCount = () => {
    if(recrutements){
      const collaboratorUsers = recrutements.filter(user => user.profile ? user.profile.user_type==='professional' : "");
      return collaboratorUsers.length;
    } else{
      return 0
    }
  };

  const getExpertCount = () => {
    if(recrutements){
      const collaboratorUsers = recrutements.filter(user => user.profile ? user.profile.user_type==='expert' : "");
      return collaboratorUsers.length;
    } else{
      return 0
    }
  };


  const getParticularCount = () => {
    if(recrutements){
      const collaboratorUsers = recrutements.filter(user => user.profile ? user.profile.user_type==='particular' : "");
      return collaboratorUsers.length;
    } else{
      return 0
    }
  };
  
  const columns = useMemo(() => [
    {
      Header:props.t("IMAGE"),
      accessor:"profile_pic",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
               {picture(recruitments.row.original.id) ? <img src={picture(recruitments.row.original.id)}
                  className="avatar-xs rounded-circle"/> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {name(recruitments.row.original.email).charAt(0)}
                    </div>
                  </div>
                }
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("NOM"),
      accessor:"last_name",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 italic" style={{textTransform:'uppercase',fontWeight:'bold'}}>
                <Link
                  className="text-dark"
                >
                  {name(recruitments.row.original.email)}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
        Header: props.t("PRENOM(S)"),
        accessor:"first_name",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center ">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 italic" style={{fontWeight:"bold"}}>
                  <Link
                    className="text-dark" 
                  >
                    {prenom(recruitments.row.original.email)}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("EMAIL"),
      accessor:"email",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 uppercase">
                {recruitments.row.original.email}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
        Header:props.t("TELEPHONE"),
        accessor:"phone_number",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {recruitments.row.original.phone_number}
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
        Header:props.t("TYPE"),
        accessor:"user_type",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {type(recruitments.row.original.email) && type(recruitments.row.original.email)==="particular"?
                   (<h5 className="badge badge-soft-danger">Particulier</h5>):
                  type(recruitments.row.original.email) && type(recruitments.row.original.email)==="assistant"?
                  (<h5 className="badge badge-soft-primary">Assistant</h5>):
                  type(recruitments.row.original.email) && type(recruitments.row.original.email)==="professional"?
                  (<h5 className="badge badge-soft-info">Professionnel</h5>):
                  type(recruitments.row.original.email) && type(recruitments.row.original.email)==="expert"?
                  (<h5 className="badge badge-soft-secondary">Expert</h5>):
                  type(recruitments.row.original.email) && type(recruitments.row.original.email)==="commite"?
                  (<h5 className="badge badge-soft-secondary">Commité</h5>):
                  type(recruitments.row.original.email) && type(recruitments.row.original.email)==="enterprise"?
                  (<h5 className="badge badge-soft-secondary">Entreprise</h5>):
                  type(recruitments.row.original.email) && type(recruitments.row.original.email)==="organism"?
                  (<h5 className="badge badge-soft-secondary">Organisme</h5>): ""
                  }
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("PAYS"),
      accessor:"country",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-11 uppercase badge badge-soft-warning">
                {country(recruitments.row.original.email)}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
  ],
    [props,name,type,prenom,country,picture]
  );


  const columns1 = useMemo(() => [
    {
      Header:props.t("IMAGE"),
      accessor:"profile_pic",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
               {picture1(recruitments.row.original.id) ? <img src={picture1(recruitments.row.original.id)}
                  className="avatar-xs rounded-circle"/> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {name1(recruitments.row.original.email).charAt(0)}
                    </div>
                  </div>
                }
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("NOM"),
      accessor:"last_name",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 italic" style={{textTransform:'uppercase',fontWeight:'bold'}}>
                <Link
                  className="text-dark"
                >
                  {name1(recruitments.row.original.email)}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
        Header: props.t("PRENOM(S)"),
        accessor:"first_name",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center ">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 italic" style={{fontWeight:"bold"}}>
                  <Link
                    className="text-dark" 
                  >
                    {prenom1(recruitments.row.original.email)}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("EMAIL"),
      accessor:"email",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 uppercase">
                {recruitments.row.original.email}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
        Header:props.t("TELEPHONE"),
        accessor:"phone_number",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {recruitments.row.original.phone_number}
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
        Header:props.t("TYPE"),
        accessor:"user_type",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="particular"?
                   (<h5 className="badge badge-soft-danger">Particulier</h5>):
                  type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="assistant"?
                  (<h5 className="badge badge-soft-primary">Assistant</h5>):
                  type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="professional"?
                  (<h5 className="badge badge-soft-info">Professionnel</h5>):type1(recruitments.row.original.email) && type(recruitments.row.original.email)==="expert"?
                  (<h5 className="badge badge-soft-secondary">Expert</h5>):type1(recruitments.row.original.email) && type(recruitments.row.original.email)==="commite"?
                  (<h5 className="badge badge-soft-secondary">Commité</h5>):
                  type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="enterprise"?
                  (<h5 className="badge badge-soft-secondary">Entreprise</h5>):

                  type1(recruitments.row.original.email) && type1(recruitments.row.original.email)==="organism"?
                  (<h5 className="badge badge-soft-secondary">Organisme</h5>): ""
                  }
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("PAYS"),
      accessor:"country",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-11 uppercase badge badge-soft-warning">
                {country1(recruitments.row.original.email)}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
  ],
    [props,name1,type1,prenom1,country1,picture1]
  );

  const columns2 = useMemo(() => [
    {
      Header:props.t("IMAGE"),
      accessor:"profile_pic",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
               {picture2(recruitments.row.original.id) ? <img src={picture2(recruitments.row.original.id)}
                  className="avatar-xs rounded-circle"/> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {name2(recruitments.row.original.email).charAt(0)}
                    </div>
                  </div>
                }
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("NOM"),
      accessor:"last_name",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 italic" style={{textTransform:'uppercase',fontWeight:'bold'}}>
                <Link
                  className="text-dark"
                >
                  {name2(recruitments.row.original.email)}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
        Header: props.t("PRENOM(S)"),
        accessor:"first_name",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center ">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 italic" style={{fontWeight:"bold"}}>
                  <Link
                    className="text-dark" 
                  >
                    {prenom2(recruitments.row.original.email)}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("EMAIL"),
      accessor:"email",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 uppercase">
                {recruitments.row.original.email}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
        Header:props.t("TELEPHONE"),
        accessor:"phone_number",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {recruitments.row.original.phone_number}
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
        Header:props.t("TYPE"),
        accessor:"user_type",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {type2(recruitments.row.original.email) && type2(recruitments.row.original.email)==="particular"?
                   (<h5 className="badge badge-soft-danger">Particulier</h5>):
                  type2(recruitments.row.original.email) && type2(recruitments.row.original.email)==="assistant"?
                  (<h5 className="badge badge-soft-primary">Assistant</h5>):
                  type2(recruitments.row.original.email) && type2(recruitments.row.original.email)==="professional"?
                  (<h5 className="badge badge-soft-info">Professionnel</h5>):type2(recruitments.row.original.email) && type(recruitments.row.original.email)==="expert"?
                  (<h5 className="badge badge-soft-secondary">Expert</h5>):type2(recruitments.row.original.email) && type(recruitments.row.original.email)==="commite"?
                  (<h5 className="badge badge-soft-secondary">Commité</h5>):
                  type2(recruitments.row.original.email) && type2(recruitments.row.original.email)==="enterprise"?
                  (<h5 className="badge badge-soft-secondary">Entreprise</h5>):

                  type2(recruitments.row.original.email) && type2(recruitments.row.original.email)==="organism"?
                  (<h5 className="badge badge-soft-secondary">Organisme</h5>): ""
                  }
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("PAYS"),
      accessor:"country",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-11 uppercase badge badge-soft-warning">
                {country2(recruitments.row.original.email)}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
  ],
    [props,name2,type2,prenom2,country2,picture2]
  );

  const columns3 = useMemo(() => [
    {
      Header:props.t("IMAGE"),
      accessor:"profile_pic",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
               {picture3(recruitments.row.original.id) ? <img src={picture3(recruitments.row.original.id)}
                  className="avatar-xs rounded-circle"/> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {name3(recruitments.row.original.email).charAt(0)}
                    </div>
                  </div>
                }
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("NOM"),
      accessor:"last_name",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 italic" style={{textTransform:'uppercase',fontWeight:'bold'}}>
                <Link
                  className="text-dark"
                >
                  {name3(recruitments.row.original.email)}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
        Header: props.t("PRENOM(S)"),
        accessor:"first_name",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center ">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 italic" style={{fontWeight:"bold"}}>
                  <Link
                    className="text-dark" 
                  >
                    {prenom3(recruitments.row.original.email)}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("EMAIL"),
      accessor:"email",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 uppercase">
                {recruitments.row.original.email}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
        Header:props.t("TELEPHONE"),
        accessor:"phone_number",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {recruitments.row.original.phone_number}
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
        Header:props.t("TYPE"),
        accessor:"user_type",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {type3(recruitments.row.original.email) && type3(recruitments.row.original.email)==="particular"?
                   (<h5 className="badge badge-soft-danger">Particulier</h5>):
                  type3(recruitments.row.original.email) && type3(recruitments.row.original.email)==="assistant"?
                  (<h5 className="badge badge-soft-primary">Assistant</h5>):
                  type3(recruitments.row.original.email) && type3(recruitments.row.original.email)==="professional"?
                  (<h5 className="badge badge-soft-info">Professionnel</h5>):type3(recruitments.row.original.email) && type(recruitments.row.original.email)==="expert"?
                  (<h5 className="badge badge-soft-secondary">Expert</h5>):type3(recruitments.row.original.email) && type(recruitments.row.original.email)==="commite"?
                  (<h5 className="badge badge-soft-secondary">Commité</h5>):
                  type3(recruitments.row.original.email) && type3(recruitments.row.original.email)==="enterprise"?
                  (<h5 className="badge badge-soft-secondary">Entreprise</h5>):

                  type3(recruitments.row.original.email) && type3(recruitments.row.original.email)==="organism"?
                  (<h5 className="badge badge-soft-secondary">Organisme</h5>): ""
                  }
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("PAYS"),
      accessor:"country",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-11 uppercase badge badge-soft-warning">
                {country3(recruitments.row.original.email)}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
      Header:props.t(" "),
      accessor:"",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
            {recruitments.row.original.professional && recruitments.row.original.professional?.banca_certificate ?  
                <h5 className="fs-10 uppercase badge badge-soft-success">
                    {"Certifié"}
                  </h5>   
                 :
                 <button className="btn btn-soft-warning" style={{fontSize:'11px'}}  onClick={() => {handleContactClick(recruitments.row.original);}}>
                     Certifier
                </button>
                }
              
            </div>
          </div>
        </>
      ),
    },
  ],
    [props,name3,type3,prenom3,country3,picture3]
  );

  const columns4 = useMemo(() => [
    {
      Header:props.t("IMAGE"),
      accessor:"profile_pic",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
               {picture4(recruitments.row.original.id) ? <img src={picture4(recruitments.row.original.id)}
                  className="avatar-xs rounded-circle"/> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {name4(recruitments.row.original.email).charAt(0)}
                    </div>
                  </div>
                }
            </div>
          </div>
        </>
      ),
    },
    {
      Header: props.t("NOM"),
      accessor:"last_name",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 italic" style={{textTransform:'uppercase',fontWeight:'bold'}}>
                <Link
                  className="text-dark"
                >
                  {name4(recruitments.row.original.email)}
                </Link>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
        Header: props.t("PRENOM(S)"),
        accessor:"first_name",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center ">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 italic" style={{fontWeight:"bold"}}>
                  <Link
                    className="text-dark" 
                  >
                    {prenom4(recruitments.row.original.email)}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("EMAIL"),
      accessor:"email",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1 uppercase">
                {recruitments.row.original.email}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
    {
        Header:props.t("TELEPHONE"),
        accessor:"phone_number",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {recruitments.row.original.phone_number}
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
        Header:props.t("TYPE"),
        accessor:"user_type",
        Cell: (recruitments) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1 uppercase">
                  {type4(recruitments.row.original.email) && type4(recruitments.row.original.email)==="particular"?
                   (<h5 className="badge badge-soft-danger">Particulier</h5>):
                  type4(recruitments.row.original.email) && type4(recruitments.row.original.email)==="assistant"?
                  (<h5 className="badge badge-soft-primary">Assistant</h5>):
                  type4(recruitments.row.original.email) && type4(recruitments.row.original.email)==="professional"?
                  (<h5 className="badge badge-soft-info">Professionnel</h5>):type4(recruitments.row.original.email) && type(recruitments.row.original.email)==="expert"?
                  (<h5 className="badge badge-soft-secondary">Expert</h5>):type4(recruitments.row.original.email) && type(recruitments.row.original.email)==="commite"?
                  (<h5 className="badge badge-soft-secondary">Commité</h5>):
                  type4(recruitments.row.original.email) && type4(recruitments.row.original.email)==="enterprise"?
                  (<h5 className="badge badge-soft-secondary">Entreprise</h5>):
                  type4(recruitments.row.original.email) && type4(recruitments.row.original.email)==="organism"?
                  (<h5 className="badge badge-soft-secondary">Organisme</h5>): ""
                  }
                </h5> 
              </div>
            </div>
          </>
        ),
    },
    {
      Header:props.t("PAYS"),
      accessor:"country",
      Cell: (recruitments) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-11 uppercase badge badge-soft-warning">
                {country4(recruitments.row.original.email)}
              </h5> 
            </div>
          </div>
        </>
      ),
    },
  ],
    [props,name4,type4,prenom4,country4,picture4]
  );


  document.title = props.t("Liste des utilisateurs du BTP");
  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Liste des utilisateurs du BTP")} pageTitle={props.t("Listes des utilisateurs du BTP")} />

        <Row className="text-center flex justify-items-center ">
                <Col md={4}>
                  <Card className="card-animate">
                          <CardBody>
                              <div className="d-flex justify-content-between">
                                  <div>
                                      <p className="fw-medium text-muted mb-0">Tous les utilisateurs</p>
                                      <h2 className="mt-4 ff-secondary fw-semibold">
                                          <span className="counter-value">
                                              <CountUp
                                                  start={0}
                                                  end={dashboard.nb_users}
                                                  duration={4}
                                              />
                                              </span></h2>
                                  </div>
                                  <div>
                                      <div className="avatar-sm flex-shrink-0">
                                          <span className="avatar-title bg-soft-warning rounded-circle fs-2">
                                              <FeatherIcon
                                                  icon="users"
                                                  className="text-warning"
                                              />
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </CardBody>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card className="card-animate">
                          <CardBody>
                              <div className="d-flex justify-content-between">
                                  <div>
                                      <p className="fw-medium text-muted mb-0">Admins</p>
                                      <h2 className="mt-4 ff-secondary fw-semibold">
                                          <span className="counter-value">
                                              <CountUp
                                                  start={0}
                                                  end={dashboard.nb_admin}
                                                  duration={4}
                                              />
                                              </span></h2>
                                  </div>
                                  <div>
                                      <div className="avatar-sm flex-shrink-0">
                                          <span className="avatar-title bg-soft-warning rounded-circle fs-2">
                                              <FeatherIcon
                                                  icon="users"
                                                  className="text-warning"
                                              />
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </CardBody>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card className="card-animate">
                          <CardBody>
                              <div className="d-flex justify-content-between">
                                  <div>
                                      <p className="fw-medium text-muted mb-0">Professionnels</p>
                                      <h2 className="mt-4 ff-secondary fw-semibold">
                                          <span className="counter-value">
                                              <CountUp
                                                  start={0}
                                                  end={dashboard.nb_professional}
                                                  duration={4}
                                              />
                                              </span></h2>
                                  </div>
                                  <div>
                                      <div className="avatar-sm flex-shrink-0">
                                          <span className="avatar-title bg-soft-warning rounded-circle fs-2">
                                              <FeatherIcon
                                                  icon="users"
                                                  className="text-warning"
                                              />
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </CardBody>
                  </Card>
                </Col>
        </Row>
        <Row className="text-center flex justify-items-center  ">
                <Col md={4}>
                  <Card className="card-animate">
                          <CardBody>
                              <div className="d-flex justify-content-between">
                                  <div>
                                      <p className="fw-medium text-muted mb-0">Experts</p>
                                      <h2 className="mt-4 ff-secondary fw-semibold">
                                          <span className="counter-value">
                                              <CountUp
                                                  start={0}
                                                  end={dashboard.nb_expert_users}
                                                  duration={4}
                                              />
                                              </span></h2>
                                  </div>
                                  <div>
                                      <div className="avatar-sm flex-shrink-0">
                                          <span className="avatar-title bg-soft-warning rounded-circle fs-2">
                                              <FeatherIcon
                                                  icon="users"
                                                  className="text-warning"
                                              />
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </CardBody>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card className="card-animate">
                          <CardBody>
                              <div className="d-flex justify-content-between">
                                  <div>
                                      <p className="fw-medium text-muted mb-0">Commité</p>
                                      <h2 className="mt-4 ff-secondary fw-semibold">
                                          <span className="counter-value">
                                              <CountUp
                                                  start={0}
                                                  end={dashboard.nb_commite}
                                                  duration={4}
                                              />
                                              </span></h2>
                                  </div>
                                  <div>
                                      <div className="avatar-sm flex-shrink-0">
                                          <span className="avatar-title bg-soft-warning rounded-circle fs-2">
                                              <FeatherIcon
                                                  icon="users"
                                                  className="text-warning"
                                              />
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </CardBody>
                  </Card>
                </Col>

                <Col md={4}>
                  <Card className="card-animate">
                          <CardBody>
                              <div className="d-flex justify-content-between">
                                  <div>
                                      <p className="fw-medium text-muted mb-0">Particuliers</p>
                                      <h2 className="mt-4 ff-secondary fw-semibold">
                                          <span className="counter-value">
                                              <CountUp
                                                  start={0}
                                                  end={dashboard.nb_particular}
                                                  duration={4}
                                              />
                                              </span></h2>
                                  </div>
                                  <div>
                                      <div className="avatar-sm flex-shrink-0">
                                          <span className="avatar-title bg-soft-warning rounded-circle fs-2">
                                              <FeatherIcon
                                                  icon="users"
                                                  className="text-warning"
                                              />
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </CardBody>
                  </Card>
                </Col>

        </Row>


        <br></br>
        
        <Nav tabs className="nav-tabs-custom nav-warning">

              <NavItem>
                <NavLink
                                style={{ cursor: "pointer",fontWeight:'bold' }}
                                className={classnames({
                                  active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                  toggleCustom("1");
                                }}
                              >
                              { props.t("TOUS LES UTILISATEURS")}
                </NavLink>
              </NavItem>

              {/* organismes */}

              <NavItem>
              <NavLink
                                style={{ cursor: "pointer",fontWeight:'bold' }}
                                className={classnames({
                                  active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                  toggleCustom("2");
                                }}
                              >
                              { props.t("ORGANISMES")}
                </NavLink>
              </NavItem>

              {/* Entreprises */}

              <NavItem>
                <NavLink
                                  style={{ cursor: "pointer",fontWeight:'bold' }}
                                  className={classnames({
                                    active: customActiveTab === "3",
                                  })}
                                  onClick={() => {
                                    toggleCustom("3");
                                  }}
                                >
                                { props.t("ENTREPRISES")}
                </NavLink>
              </NavItem>

              {/* professionnels */}

              <NavItem>
                <NavLink
                                style={{ cursor: "pointer",fontWeight:'bold' }}
                                className={classnames({
                                  active: customActiveTab === "4",
                                })}
                                onClick={() => {
                                  toggleCustom("4");
                                }}
                              >
                              { props.t("PROFESSIONNELS")}
                </NavLink>
              </NavItem>

            {/* particuliers */}
              <NavItem>
                <NavLink
                                style={{ cursor: "pointer",fontWeight:'bold' }}
                                className={classnames({
                                  active: customActiveTab === "5",
                                })}
                                onClick={() => {
                                  toggleCustom("5");
                                }}
                              >
                              { props.t("PARTICULIERS")}
                </NavLink>
              </NavItem>
        </Nav>
        
        <TabContent
                        activeTab={customActiveTab}
                          className=" border-top-0 "
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="1"
                          >
                            <div >
                            <Row>
                              <div className="col-xl-12 col-lg-12">
                                <div>
                                  <div className="card">
                                    <div className="card-header border-0">
                                      <div className="row align-items-center">
                                      
                                        <div className="col-auto"> 
                                          <div id="selection-element">
                                            <div className="my-n1 d-flex align-items-center text-muted">
                                              Select{" "}
                                              <div
                                                id="select-content"
                                                className="text-body fw-semibold px-1"
                                              >{}</div>{" "}
                                              Result{" "}
                                              <button
                                                type="button"
                                                className="btn btn-link link-danger p-0 ms-3"
                                                onClick={() => setDeleteModalMulti(true)}
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body pt-0">
                                      {   isLoading ? ( // If loading, show loading indicator
                                          <div className="py-4 text-center">
                                            <div>
                                              <lord-icon
                                                  src="https://cdn.lordicon.com/xjovhxra.json"
                                                  trigger="loop"
                                                  colors="primary:#b38a12,secondary:#02376b"
                                                  style={{ width: "72px", height:"72px" }}>
                                              </lord-icon>
                                            </div>
                                            <div className="mt-4">
                                              <h5>{props.t("Chargement")}...</h5>
                                            </div>
                                          </div>
                                        )  
                                        :
                                      recrutements && recrutements.length >= 0 ? (
                                        <TableContainer
                                          columns={columns}
                                          data={(recrutements || [])}
                                          isAddUserList={false}
                                          customPageSize={10}
                                          divClass="table-responsive mb-1"
                                          tableClass="mb-0 align-middle "
                                          theadClass="table-light text-muted"
                                          isMetierFilter={false}
                                          isGlobalFilter={true}
                                        />
                                      ) : (
                                        <div className="py-4 text-center">
                                          <div className="mt-4">
                                            <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                            </div>
                          </TabPane>
        </TabContent>

        <TabContent activeTab={customActiveTab}
                          className=" border-top-0 "
                          id="nav-tabContent"
                        >
                          <TabPane id="nav-speci"
                            tabId="2"
                          >
                            <div >
                            <Row>
                              <div className="col-xl-12 col-lg-12">
                                <div>
                                  <div className="card">
                                    <div className="card-header border-0">
                                      <div className="row align-items-center">
                                      
                                        <div className="col-auto">
                                          <div id="selection-element">
                                            <div className="my-n1 d-flex align-items-center text-muted">
                                              Select{" "}
                                              <div
                                                id="select-content"
                                                className="text-body fw-semibold px-1"
                                              >{}</div>{" "}
                                              Result{" "}
                                              <button
                                                type="button"
                                                className="btn btn-link link-danger p-0 ms-3"
                                                onClick={() => setDeleteModalMulti(true)}
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body pt-0">
                                      {   isLoading ? ( // If loading, show loading indicator
                                          <div className="py-4 text-center">
                                            <div>
                                              <lord-icon
                                                  src="https://cdn.lordicon.com/xjovhxra.json"
                                                  trigger="loop"
                                                  colors="primary:#b38a12,secondary:#02376b"
                                                  style={{ width: "72px", height:"72px" }}>
                                              </lord-icon>
                                            </div>
                                            <div className="mt-4">
                                              <h5>{props.t("Chargement")}...</h5>
                                            </div>
                                          </div>
                                        ) 
                                        :
                                      recrutements1 && recrutements1.length >= 0 ? (
                                        <TableContainer
                                          columns={columns1}
                                          data={(recrutements1 || [])}
                                          isAddUserList={false}
                                          customPageSize={10}
                                          divClass="table-responsive mb-1"
                                          tableClass="mb-0 align-middle "
                                          theadClass="table-light text-muted"
                                          isMetierFilter={false}
                                          isGlobalFilter={true}
                                        />
                                      ) : (
                                        <div className="py-4 text-center">
                                          <div className="mt-4">
                                            <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                            </div>
                          </TabPane>
        </TabContent>

        <TabContent
                        activeTab={customActiveTab}
                          className=" border-top-0 "
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="3"
                          >
                            <div >
                            <Row>
                              <div className="col-xl-12 col-lg-12">
                                <div>
                                  <div className="card">
                                    <div className="card-header border-0">
                                      <div className="row align-items-center">
                                      
                                        <div className="col-auto">
                                          <div id="selection-element">
                                            <div className="my-n1 d-flex align-items-center text-muted">
                                              Select{" "}
                                              <div
                                                id="select-content"
                                                className="text-body fw-semibold px-1"
                                              >{}</div>{" "}
                                              Result{" "}
                                              <button
                                                type="button"
                                                className="btn btn-link link-danger p-0 ms-3"
                                                onClick={() => setDeleteModalMulti(true)}
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body pt-0">
                                      {   isLoading ? ( // If loading, show loading indicator
                                          <div className="py-4 text-center">
                                            <div>
                                              <lord-icon
                                                  src="https://cdn.lordicon.com/xjovhxra.json"
                                                  trigger="loop"
                                                  colors="primary:#b38a12,secondary:#02376b"
                                                  style={{ width: "72px", height:"72px" }}>
                                              </lord-icon>
                                            </div>
                                            <div className="mt-4">
                                              <h5>{props.t("Chargement")}...</h5>
                                            </div>
                                          </div>
                                        ) 
                                        :
                                      recrutements2 && recrutements2.length > 0 ? (
                                        <TableContainer
                                          columns={columns2}
                                          data={(recrutements2 || [])}
                                          isAddUserList={false}
                                          customPageSize={10}
                                          divClass="table-responsive mb-1"
                                          tableClass="mb-0 align-middle "
                                          theadClass="table-light text-muted"
                                          isMetierFilter={false}
                                          isGlobalFilter={true}
                                        />
                                      ) : (
                                        <div className="py-4 text-center">
                                          <div className="mt-4">
                                            <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                            </div>
                          </TabPane>
        </TabContent>

        <TabContent
                        activeTab={customActiveTab}
                          className=" border-top-0 "
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="4"
                          >
                            <div >
                              <Row>
                                <div className="col-xl-12 col-lg-12">
                                  <div>
                                    <div className="card">
                                      <div className="card-header border-0">
                                        <div className="row align-items-center">
                                        
                                          <div className="col-auto">
                                            <div id="selection-element">
                                              <div className="my-n1 d-flex align-items-center text-muted">
                                                Select{" "}
                                                <div
                                                  id="select-content"
                                                  className="text-body fw-semibold px-1"
                                                >{}</div>{" "}
                                                Result{" "}
                                                <button
                                                  type="button"
                                                  className="btn btn-link link-danger p-0 ms-3"
                                                  onClick={() => setDeleteModalMulti(true)}
                                                >
                                                  Remove
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body pt-0">
                                      <Modal id="showModal1" isOpen={modal} toggle={toggle} centered>
                                        <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                                          {!!isEdit && props.t("Certifier le professionnel") }
                                        </ModalHeader>

                                        <Form onSubmit={(e) => {
                                          e.preventDefault();
                                          validation.handleSubmit();
                                          return false;
                                        }}>
                                          <ModalBody>
                                            <Input type="hidden" id="id-field" />
                                            <Row className="g-3">  
                                              <Col lg={12}>
                                                <div>
                                                  <Label
                                                    htmlFor=""
                                                    className="form-label"
                                                  >
                                                    {props.t("Certicat")}
                                                  </Label>
                                                  <Input
                                                    name="banca_certificate"
                                                    id="banca_certificate"
                                                    className="form-control"
                                                    type="number"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    min={1}
                                                    max={5}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.banca_certificate || ""}
                                                    invalid={
                                                      validation.touched.banca_certificate && validation.errors.banca_certificate ? true : false
                                                    }
                                                  />
                                                  {validation.touched.banca_certificate && validation.errors.banca_certificate ? (
                                                    <FormFeedback type="invalid">{validation.errors.banca_certificate}</FormFeedback>
                                                  ) : null}

                                                </div>
                                              </Col>
                                            </Row>
                                          </ModalBody>
                                          <ModalFooter>
                                            <div className="hstack gap-2 justify-content-end">
                                              <button type="button" className="btn btn-light" onClick={() => { setModal(false); }} > {props.t("Fermer")} </button>
                                              <button type="submit" className="btn btn-warning" > {!!isEdit && props.t("Modifier")} </button>
                                            </div>
                                          </ModalFooter>
                                        </Form>
                                      </Modal>
                                        {   isLoading ? ( // If loading, show loading indicator
                                            <div className="py-4 text-center">
                                              <div>
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/xjovhxra.json"
                                                    trigger="loop"
                                                    colors="primary:#b38a12,secondary:#02376b"
                                                    style={{ width: "72px", height:"72px" }}>
                                                </lord-icon>
                                              </div>
                                              <div className="mt-4">
                                                <h5>{props.t("Chargement")}...</h5>
                                              </div>
                                            </div>
                                          ) 
                                          :
                                        recrutements3 && recrutements3.length >= 0 ? (
                                          <TableContainer
                                            columns={columns3}
                                            data={(recrutements3 || [])} 
                                            isAddUserList={false}
                                            customPageSize={10}
                                            divClass="table-responsive mb-1"
                                            tableClass="mb-0 align-middle "
                                            theadClass="table-light text-muted"
                                            isMetierFilter={false}
                                            isGlobalFilter={true}
                                          />
                                        ) : (
                                          <div className="py-4 text-center">
                                            <div className="mt-4">
                                              <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </TabPane>
        </TabContent>

        <TabContent
                        activeTab={customActiveTab}
                          className=" border-top-0 "
                          id="nav-tabContent"
                        >
                          <TabPane
                            id="nav-speci"
                            tabId="5"
                          >
                            <div >
                              <Row>
                                <div className="col-xl-12 col-lg-12">
                                  <div>
                                    <div className="card">
                                      <div className="card-header border-0">
                                        <div className="row align-items-center">
                                        
                                          <div className="col-auto">
                                            <div id="selection-element">
                                              <div className="my-n1 d-flex align-items-center text-muted">
                                                Select{" "}
                                                <div
                                                  id="select-content"
                                                  className="text-body fw-semibold px-1"
                                                >{}</div>{" "}
                                                Result{" "}
                                                <button
                                                  type="button"
                                                  className="btn btn-link link-danger p-0 ms-3"
                                                  onClick={() => setDeleteModalMulti(true)}
                                                >
                                                  Remove
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body pt-0">
                                        {   isLoading ? ( // If loading, show loading indicator
                                            <div className="py-4 text-center">
                                              <div>
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/xjovhxra.json"
                                                    trigger="loop"
                                                    colors="primary:#b38a12,secondary:#02376b"
                                                    style={{ width: "72px", height:"72px" }}>
                                                </lord-icon>
                                              </div>
                                              <div className="mt-4">
                                                <h5>{props.t("Chargement")}...</h5>
                                              </div>
                                            </div>
                                          ) 
                                          :
                                        recrutements4 && recrutements4.length >= 0 ? (
                                          <TableContainer
                                            columns={columns4}
                                            data={(recrutements4 || [])}
                                            isAddUserList={false}
                                            customPageSize={10}
                                            divClass="table-responsive mb-1"
                                            tableClass="mb-0 align-middle "
                                            theadClass="table-light text-muted"
                                            isMetierFilter={false}
                                            isGlobalFilter={true}
                                          />
                                        ) : (
                                          <div className="py-4 text-center">
                                            <div className="mt-4">
                                              <h5> {props.t("Désolé, pas de resultats trouvés")} </h5>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </TabPane>
        </TabContent>

      </Container>
    </div>
  );
};

Team.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}
export default withRouter(withTranslation()(Team));