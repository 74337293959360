import {
    GET_VENTES,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

    ADD_NEW_VENTE,
    ADD_VENTE_SUCCESS,
    ADD_VENTE_FAIL,

    UPDATE_VENTE,
    UPDATE_VENTE_SUCCESS,
    UPDATE_VENTE_FAIL,

    DELETE_VENTE,
    DELETE_VENTE_SUCCESS,
    DELETE_VENTE_FAIL
} from './actionType';

const INIT_STATE = {
    ventes: [],
}

const Vente = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_VENTES:
                    return {
                        ...state,
                        ventes: action.payload.response,
                        isVenteSuccess: true,
                    };
                case ADD_NEW_VENTE:
                    return {
                        ...state,
                        ventes: [...state.ventes, action.payload.response],
                    };
                case DELETE_VENTE:
                    return {
                        ...state,
                        ventes: state.ventes.filter(
                            vente => (vente.id + '') !== (action.payload.response.id + '')
                            ),
                    };
                    case UPDATE_VENTE:
                        return {
                            ...state,
                            ventes: state.ventes.map(
                                vente => (vente.id + '') === (action.payload.response.id + '')
                                    ? action.payload.response
                                    : vente
                            ),
                        };
                default: 
                return { ...state };    
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_VENTES:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                case DELETE_VENTE:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                    default:
                    return { 
                        ...state
                     };
            }
            case GET_VENTES:
                return {
                    ...state,
                };
            case ADD_NEW_VENTE:
                return {
                    ...state,
                    ventes: [...state.ventes, action.payload],
                };
                case ADD_VENTE_SUCCESS:
                    return {
                        ...state,
                        ventes: [...state.ventes, action.payload.response],
                    };
                case ADD_VENTE_FAIL:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
            case UPDATE_VENTE_SUCCESS:
                return {
                    ...state,
                    ventes: state.ventes.map( vente =>
                        vente.id.toString() === action.payload.data.id.toString() 
                        ? { ...vente, ...action.payload.data }
                        : vente
                        ),
                    };
            case UPDATE_VENTE_FAIL:
                return {
                    ...state,
                    error: action.payload.error,
                }
            case DELETE_VENTE_SUCCESS:
                return {
                    ...state,
                    ventes: state.ventes.filter(
                        vente => vente.id.toString() !== action.payload.vente.toString()
                        ),              
                }
            case DELETE_VENTE_FAIL:
                return {
                    ...state,
                    error: action.payload.error,
                };
        default:
            return { ...state };
    }

}

export default Vente;