import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import createPasswordSaga from "./auth/createpwd/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import LoginAdminSaga from "./auth/login copy/saga";
import AuthSaga from "./auth/login/saga";
import authAffiliateSaga from "./auth/loginAffiliate/saga";
import ProfileSaga from "./auth/profile/saga";
import AccountSaga from "./auth/register/saga";
import AffiliateSaga from "./auth/registerAffiliate/saga";
import validationSaga from "./auth/validation/saga";

//calendar
import calendarSaga from "./calendar/saga";
//chat
import chatSaga from "./chat/saga";
//ecommerce
import ecommerceSaga from "./ecommerce/saga";

//Project
import projectSaga from "./projects/saga";
// Task
import taskSaga from "./tasks/saga";
// Crypto
import cryptoSaga from "./crypto/saga";
//TicketsList
import ticketsSaga from "./tickets/saga";
//Type Abonnement
import typeabonnementSaga from "./typeabonnement/saga";

//crm
import crmSaga from "./crm/saga";
//Particulier
import particulierSaga from "./particulier/saga";
//Entreprise
import entrepriseSaga from "./entreprise/saga";
//invoice
import invoiceSaga from "./invoice/saga";
//VENTES
import venteSaga from "./ventes/saga";
//DOCUMENTS
import documentSaga from "./documents/saga";
//FACTURES
import factureSaga from "./factures/saga";
//mailbox
import mailboxSaga from "./mailbox/saga";

// Dashboard Analytics
import dashboardAnalyticsSaga from "./dashboardAnalytics/saga";

// Dashboard CRM
import dashboardCrmSaga from "./dashboardCRM/saga";

// Dashboard Ecommerce
import dashboardEcommerceSaga from "./dashboardEcommerce/saga";

// Dashboard Crypto
import dashboardCryptoSaga from "./dashboardCrypto/saga";

// Dashboard Project
import dashboardProjectSaga from "./dashboardProject/saga";

// Dashboard NFT
import dashboardNFTSaga from "./dashboardNFT/saga";

// Pages > Team
import teamSaga from "./team/saga";

// File Manager
import fileManager from "./fileManager/saga";

// Pays
import paysSaga from "./geolocalisation/pays/saga";

// Province
import provinceSaga from "./geolocalisation/province/saga";

// City
import citySaga from "./geolocalisation/city/saga";

// Arrondissement
import arrondissementSaga from "./geolocalisation/arrondissements/saga";

// Quartier
import quartierSaga from "./geolocalisation/quartiers/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(authAffiliateSaga),
    fork(AffiliateSaga),
    fork(LoginAdminSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(createPasswordSaga),
    fork(validationSaga),
    fork(ProfileSaga),
    fork(chatSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(cryptoSaga),
    fork(ticketsSaga),
    fork(calendarSaga),
    fork(ecommerceSaga),
    fork(typeabonnementSaga),
    fork(crmSaga),
    fork(particulierSaga),
    fork(entrepriseSaga),
    fork(invoiceSaga),
    fork(mailboxSaga),
    fork(dashboardAnalyticsSaga),
    fork(dashboardCrmSaga),
    fork(dashboardEcommerceSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardProjectSaga),
    fork(dashboardNFTSaga),
    fork(teamSaga),
    fork(venteSaga),
    fork(documentSaga),
    fork(factureSaga),
    fork(fileManager),
    fork(paysSaga),
    fork(provinceSaga),
    fork(citySaga),
    fork(arrondissementSaga),
    fork(quartierSaga),
  ]);
}
