import {
    GET_VENTES,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

    ADD_NEW_VENTE,
    ADD_VENTE_SUCCESS,
    ADD_VENTE_FAIL,

    UPDATE_VENTE,
    UPDATE_VENTE_SUCCESS,
    UPDATE_VENTE_FAIL,

    DELETE_VENTE,
    DELETE_VENTE_SUCCESS,
    DELETE_VENTE_FAIL
} from "./actionType"

// common success
export const venteApiResponseSuccess = (actionType, response) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, response }
})

//common error
export const venteApiResponseError =(actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error }
})

export const getventes =() => ({
    type: GET_VENTES,
})

export const addNewVente = vente => ({
    type: ADD_NEW_VENTE,
    payload: vente,
})

export const addNewVenteSuccess = vente => ({
    type: ADD_VENTE_SUCCESS,
    payload: vente,
})

export const addNewVenteFail = error => ({
    type: ADD_VENTE_FAIL,
    payload: error,
})

export const updateVente = vente => ({
    type: UPDATE_VENTE,
    payload: vente,
})

export const updateVenteSuccess = vente => ({
    type: UPDATE_VENTE_SUCCESS,
    payload: vente,
})

export const updateVenteFail = error => ({
    type: UPDATE_VENTE_FAIL,
    payload: error,
})

export const deleteVente = vente => ({
    type: DELETE_VENTE,
    payload: vente,
})

export const deleteVenteSuccess = vente => ({
    type: DELETE_VENTE_SUCCESS,
    payload: vente,
})

export const deleteVenteFail = error => ({
    type: DELETE_VENTE_FAIL,
    payload: error,
})