import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import userdummy from "../../assets/images/users/user-dummy-img.jpg";
import { axiosIns } from '../../utils';
import axios from "axios";

//import { getProfile as onGetProfile} from '../../store/actions';
const reducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_REQUEST':
        return { ...state, loading: true };
      case 'FETCH_SUCCESS':
        return { ...state, user: action.payload, userfirstname: action.payload.first_name, loading: false };
      case 'FETCH_FAIL':
        return { ...state, loading: false };
  
      default:
        return state;
    }
  };

const ProfileDropdown = (props) => {
    const [
        {
          loading,
          user,
          userfirstname,
          log,
        },
        dispatch,
      ] = useReducer(reducer, {
        loading: true,
        user: {},
        userfirstname: "",
        log: {},
        error: '',
      });

      const userInfo = JSON.parse(sessionStorage.getItem("authUser"))

    const token = userInfo ? userInfo.access : null;

    const [info, setInfo] = useState({});
    const [profile, setProfile] = useState({});

    const fetchData = async () => {
        const response  = await axiosIns.get(`/profile/${userInfo.id}/`);
        setInfo(response.data)
        setProfile(response.data?.profile)
    };

    useEffect(() => {
      fetchData();
    }, []);


    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                          <img className="rounded-circle header-profile-user" src={profile.profile_pic}
                          alt="" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{info.username}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{
                              profile.user_type === "particular" ? 
                                "Particulier" 
                              :
                                profile.user_type === "professional" ?
                                  "Professionnel du BTP"
                                  :
                                  profile.user_type === "organism" ?
                                  "Organisme"
                                  :
                                  profile.user_type === "bank" ?
                                  "Banque"
                                  :
                                  profile.user_type === "expert" ?
                                  "Expert"
                                  :
                                  profile.user_type === "commite" ?
                                  "Commité"
                                  :
                                  profile.user_type === "assistant" ?
                                  "Assistant"
                                  :
                                  profile.user_type === "admin" ?
                                  "Administrateur"
                                  :
                                  "Collaborateur"
                          }</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <h6 className="dropdown-header">{props.t("Bienvenue")} {profile.first_name || profile.username}  !</h6>
                    <DropdownItem href="/pages-profile"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle"> {props.t("Profil")}</span></DropdownItem>              
                    <DropdownItem href="/logout"><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout">{props.t("Déconnexion")}</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

ProfileDropdown.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ProfileDropdown));