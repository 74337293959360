import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Comments from './Comments';
import Summary from './Summary';
import TimeTracking from "./TimeTracking";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";


const TaskDetails = (props) => {
    document.title=props.t("Tâche détail");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={props.t("Tâche détail")} pageTitle={props.t("Tâches")} />
                    <Row>
                        <Col xxl={12}>
                            <TimeTracking />
                        </Col>
                        {/* <Col xxl={9}>
                            <Summary />
                            <Comments />
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
TaskDetails.propTypes={
    location:PropTypes.object,
    t:PropTypes.any
  }
export default withRouter(withTranslation()(TaskDetails));

