// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_TYPE_ABONNEMENTS = "GET_TYPE_ABONNEMENTS";

// Add TYPE_ABONNEMENT
export const ADD_NEW_TYPE_ABONNEMENT = "ADD_NEW_TYPE_ABONNEMENT";
export const ADD_TYPE_ABONNEMENT_SUCCESS = "ADD_TYPE_ABONNEMENT_SUCCESS";
export const ADD_TYPE_ABONNEMENT_FAIL = "ADD_TYPE_ABONNEMENT_FAIL";

// Edit TYPE_ABONNEMENT
export const UPDATE_TYPE_ABONNEMENT = "UPDATE_TYPE_ABONNEMENT";
export const UPDATE_TYPE_ABONNEMENT_SUCCESS = "UPDATE_TYPE_ABONNEMENT_SUCCESS";
export const UPDATE_TYPE_ABONNEMENT_FAIL = "UPDATE_TYPE_ABONNEMENT_FAIL";
