import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  addNewQuartier as addNewQuartierApi,
  deleteQuartier as deleteQuartierApi,
  getQuartier as getQuartiersApi,
  updateQuartier as updateQuartierApi,
} from "../../../helpers/fakebackend_helper";
import {
  addNewQuartierFail,
  addNewQuartierSuccess,
  deleteQuartierFail,
  deleteQuartierSuccess,
  quartiersApiResponseError,
  quartiersApiResponseSuccess,
  updateQuartierFail,
  updateQuartierSuccess,
} from "./action";
import {
  ADD_NEW_QUARTIER,
  DELETE_QUARTIER,
  GET_QUARTIERS,
  UPDATE_QUARTIER,
} from "./actionType";

function* getQuartiers() {
  try {
    const response = yield call(getQuartiersApi);
    yield put(quartiersApiResponseSuccess(GET_QUARTIERS, response));
  } catch (error) {
    yield put(quartiersApiResponseError(GET_QUARTIERS, error));
  }
}

function* addNewQuartier(action) {
  try {
    const response = yield call(addNewQuartierApi, action.payload);
    yield put(addNewQuartierSuccess(response));
    toast.success("Quartier ajouté avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(addNewQuartierFail(error));
    toast.error("Erreur lors de l'ajout du quartier", { autoclose: 3000 });
  }
}

function* updateQuartier(action) {
  try {
    const response = yield call(updateQuartierApi, action.payload);
    yield put(updateQuartierSuccess(response));
    toast.success("Quartier modifié avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(updateQuartierFail(error));
    toast.error("Erreur lors de la modification du quartier", {
      autoclose: 3000,
    });
  }
}

function* deleteQuartier(action) {
  try {
    const response = yield call(deleteQuartierApi, action.payload);
    yield put(deleteQuartierSuccess(response));
    toast.success("Quartier supprimé avec succès", { autoclose: 3000 });
  } catch (error) {
    yield put(deleteQuartierFail(error));
    toast.error("Erreur lors de la suppression du quartier", {
      autoclose: 3000,
    });
  }
}

export function* watchGetQuartiers() {
  yield takeEvery(GET_QUARTIERS, getQuartiers);
}

export function* watchAddNewQuartier() {
  yield takeEvery(ADD_NEW_QUARTIER, addNewQuartier);
}

export function* watchUpdateQuartier() {
  yield takeEvery(UPDATE_QUARTIER, updateQuartier);
}

export function* watchDeleteQuartier() {
  yield takeEvery(DELETE_QUARTIER, deleteQuartier);
}

function* quartiersSaga() {
  yield all([
    fork(watchGetQuartiers),
    fork(watchAddNewQuartier),
    fork(watchUpdateQuartier),
    fork(watchDeleteQuartier),
  ]);
}

export default quartiersSaga;
