import {
  GET_ENTREPRISES,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,

  ADD_ENTREPRISE_SUCCESS,
  ADD_ENTREPRISE_FAIL,
  
  UPDATE_ENTREPRISE_SUCCESS,
  UPDATE_ENTREPRISE_FAIL,
} from "./actionType";

const INIT_STATE = {
  crmentreprises: [],
  error: {},
};

const Entreprise = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ENTREPRISES:
          return {
            ...state,
            crmentreprises: action.payload.data,
            isEntrepriseCreated: false,
            isEntrepriseSuccess: true
          };
        
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_ENTREPRISES:
          return {
            ...state,
            error: action.payload.error,
            isEntrepriseCreated: false,
            isEntrepriseSuccess: false
          };
        
        default:
          return { ...state };
      }

    case ADD_ENTREPRISE_SUCCESS:
      return {
        ...state,
        isEntrepriseCreated: true,
        crmentreprises: [...state.crmentreprises, action.payload.data],
        isEntrepriseAdd: true,
        isEntrepriseAddFail: false
      };

    case ADD_ENTREPRISE_FAIL:
      return {
        ...state,
        error: action.payload,
        isEntrepriseAdd: false,
        isEntrepriseAddFail: true
      };

    case UPDATE_ENTREPRISE_SUCCESS:
      return {
        ...state,
        crmentreprises: state.crmentreprises.map(entreprise =>
          entreprise.id === action.payload.id
            ? { ...entreprise, ...action.payload }
            : entreprise
        ),
        isEntrepriseUpdate: true,
        isEntrepriseUpdateFail: false
      };

    case UPDATE_ENTREPRISE_FAIL:
      return {
        ...state,
        error: action.payload,
        isEntrepriseUpdate: false,
        isEntrepriseUpdateFail: true
      };

    default:
      return { ...state };
  }
};

export default Entreprise;
