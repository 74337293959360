import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { getPays } from "../../../store/actions";

const Pays = (props) => {
  const dispatch = useDispatch();
  const [pays, setPays] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const { pays: paysList } = useSelector((state) => ({
    pays: state.Pays.pays,
  }));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getPays());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(paysList)) {
      setIsLoading(false);
      setPays(paysList);
    }
  }, [paysList]);

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".entrepriseCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="entrepriseCheckBox"
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: props.t("PAYS"),
        accessor: "name",
        Cell: (pays) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <p className="fs-14 mb-1">{pays.row.original.name}</p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "ACTIONS",
        Cell: (cellProps) => {
          const { id } = cellProps.row.original;
          return (
            <li className="list-inline-item" title={props.t("Modifier")}>
              <Link
                to={`apps-geolocalisation-pays-update/${id}/`}
                className="text-warning d-inline-block"
              >
                <i className="ri-pencil-fill align-bottom" />
              </Link>
            </li>
          );
        },
      },
    ],
    [paysList]
  );

  const handleDeletePays = () => {
    if (pays) {
      // supprimer le pays
      setDeleteModal(false);
    }
  };

  document.title = props.t("Pays - Le Conseiller du BTP");

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePays}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <BreadCrumb
          title={props.t("Pays")}
          pageTitle={props.t("Liste des pays")}
        />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <div className="d-flex.align-items-center">
                  <div></div>
                  <button className="btn btn-info add-btn">
                    <Link
                      to={`/apps-geolocalisation-pays-add`}
                      className="text-white d-inline-block"
                    >
                      <i className="ri-add-fill me-1 align-bottom" />{" "}
                      {props.t("Ajouter un pays")}{" "}
                    </Link>
                  </button>
                </div>
              </CardHeader>
            </Card>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {isLoading ? ( // If loading, show loading indicator
                    <div className="py-4 text-center">
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/xjovhxra.json"
                          trigger="loop"
                          colors="primary:#0960b6,secondary:#02376b"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                      </div>
                      <div className="mt-4">
                        <h5>{props.t("Chargement")}...</h5>
                      </div>
                    </div>
                  ) : (
                    pays &&
                    pays.length > 0 && (
                      <TableContainer
                        columns={columns}
                        data={pays || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={8}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                    )
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Pays.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Pays));
