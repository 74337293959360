import React, { useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types'
import axios from "axios";
import { isEmpty } from "lodash";
import { axiosIns } from '../../../utils';
import {getventes as onGetVentes} from "../../../store/ventes/action";
import tiktok from "../../../assets/images/tiktok.png";
import {
  CardBody,
  Container,
  Progress,
  Row,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Badge,
} from "reactstrap";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import Img2 from "../../../assets/images/companies/img-2.png";
import ReviewSlider from "../../../Components/Common/ReviewSlider";
import TableContainer from "../../../Components/Common/TableContainer";
import { ToastContainer, toast } from 'react-toastify';
import { getContacts as onGetContacts } from "../../../store/crm/action"; 
//Import actions
// import { getProducts as onGetProducts } from "../../../store/ecommerce/action";



import Revenue from "../../DashboardEcommerce/Revenue";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, Redirect, useParams, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const EntreprisesDetail = (props) => {
  const dispatch = useDispatch();

  //Début code pour get le entreprise sélectionné
  const entrepriseId = useParams('id');
  console.log(entrepriseId.id);
  const url = process.env.REACT_APP_API_URL;
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [selectentreprise, setSelectEntreprise] = useState({})
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/entreprises/${entrepriseId.id}`);
          setSelectEntreprise(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    }, [entrepriseId, url]);
  // console.log(selectentreprise.nom);
  //Fin code

  const [typeAbonnementCourant, setTypeAbonnementCourant] = useState(null)
   useEffect(() => {
     const checkTokenValidity = async () => {
         try {
         const response = await axios.get("https://back.crm.inawo.pro/api/v1/subscribes/", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
         const getLastAbonnement = response.length - 1
         const currentTypeAbonnement = response[getLastAbonnement].typeAbonnement
         setTypeAbonnementCourant(currentTypeAbonnement)
         } catch (error) {
         console.log(error)
         } 
     };
     checkTokenValidity();
   }, []);

   const [factures, setFactures] = useState([]);
   useEffect(() => {
       const fetchFactures = async () => {
         try {
           const { data } = await axiosIns.get(`/factures/`, {
             headers: {
               Authorization: 'Bearer ' + token,
             },
           });
           console.log("test2", data);

           const dateSysteme = new Date();
           const moisActuel = dateSysteme.getMonth();
           const facturesFiltrees = data.filter(facture => {
             const moisFacture = parseInt(facture.dateFacture.split("T")[0].slice(5, 7)) - 1;
             return moisFacture === moisActuel;
           });
           console.log("test3", facturesFiltrees);
           setFactures(facturesFiltrees);
         } catch (err) {
           console.log(err);
         }
       };
       fetchFactures();
       }, [token]);


  const { ventes, products, crmcontacts } = useSelector((state) => ({
    products: state.Ecommerce.products,
    crmcontacts: state.Crm.crmcontacts,
    ventes: state.Vente.ventes,
  }));

  const [productList, setProductList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [venteList, setVenteList] = useState([]);

  useEffect(() => {
    if (crmcontacts && !crmcontacts.length) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContactList(crmcontacts);
  }, [crmcontacts]);
  console.log("Contacts", crmcontacts);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) setContactList(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    setProductList(products);
  }, [products]);

  useEffect(() => {
    // dispatch(onGetProducts());
  }, [dispatch]);

  
  useEffect(() => {
    if (ventes && !ventes.length) {
      dispatch(onGetVentes());
    }
  }, [dispatch, ventes]);

  useEffect(()=> {
    setVenteList(ventes);
  }, [ventes]);
  console.log("ventes", venteList);

  useEffect(()=> {
    if (!isEmpty(ventes))
      setVenteList(ventes);
  }, [ventes]);

  console.log("ven", venteList);

  const venteCon = (j)=> {
    for(let i=0;i<=contactList.length; i++){
      if( j===contactList[i]?.id){
        return (contactList[i]?.nom === "" ? contactList[i]?.nomEntreprise : contactList[i]?.nom) ;
       }
     }
   }
  console.log("cat",venteCon(4));

  const ConId = (j)=> {
    for(let i=0;i<=contactList.length; i++){
      if( j===contactList[i]?.nom){
       return (contactList[i]?.id) ;
      }
    }
  }

  const [realVente, setRealVente] = useState([]);
  useEffect(() => {
    if (!isEmpty(venteList)) {
      const updatedVentes = venteList.map(vente => ({
        ...vente,
        contact: venteCon(parseInt(vente.contact))
      }));
      setRealVente(updatedVentes);
    }
  }, [venteList]);
  
  console.log("details", realVente);
  console.log("details1", venteList);
  console.log("detailllo", venteList.filter((data) => data.contact === parseInt(entrepriseId.id)))

  const toastMe = () => {
    toast.error("Votre formule ne vous permet d'ajouter que 15 factures par mois.")
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t("No."),
        Cell: (vente)=> (
          <>
            <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                {/* <Link
                  to={`/apps-ecommerce-product-details/${vente.row.original.id}/`}
                  className="text-dark"
                > */}
                  {" "}
                  {(vente.row.original.numVente)}
                {/* </Link> */}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Contacts"),
        accessor: "contact",
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  <Link to={`/apps-crm-contact/${ConId(vente.row.original.contact)}`}>
                      { vente.row.original.contact }
                  </Link>  
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Date"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {vente.row.original.dateVente}
              </h5>
            </div>
          </div>
          </>
        ),
        filterable: false,
      },
      {
        Header: props.t("Vente"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {/* <span className='badge badge-soft-danger me-1'>{vente.row.original.statusVente}</span> */}
                 {
                    vente.row.original.statusVente === "En Cours" || vente.row.original.statusVente === "E"  ? <span className='badge badge-soft-secondary me-1'>{props.t("En cours")}</span> : <span className='badge badge-soft-success me-1'>{props.t("Terminé")}</span>
                 }  
              </h5>
            </div>
          </div>
          </>
        )
      },
        {
          Header: props.t("Paiement"),
          Cell: (vente) => ( 
            <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                    {" "}
                    {
                      vente.row.original.statusPaiement === "En Cours" || vente.row.original.statusVente === "E"  ? <span className='badge badge-soft-secondary me-1'>{props.t("En cours")}</span> : vente.row.original.statusPaiement === "Non Payé" ? <span className='badge badge-soft-danger me-1'>{props.t("Non payé")}</span> : <span className='badge badge-soft-success me-1'>{props.t("Payé")}</span>
                  }  
                </h5>
              </div>
            </div>
            </>
          )
        },
      {
        Header: props.t("Livraison"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                  {
                    vente.row.original.statusLivraison === "En Cours" || vente.row.original.statusVente === "E"  ? <span className='badge badge-soft-secondary me-1'>{props.t("En cours")}</span> : <span className='badge badge-soft-success me-1'>{props.t("Terminé")}</span>
                 }  
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: props.t("Encaissé"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                 {vente.row.original.encaissementRecu} 
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: "Total",
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                 {vente.row.original.montant === null ? 0 : vente.row.original.montant} 
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: props.t("Montant"),
        Cell: (vente) => ( 
          <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                  {" "}
                 {vente.row.original.montant === null ? 0 : vente.row.original.montant} 
              </h5>
            </div>
          </div>
          </>
        )
      },
      {
        Header: props.t("Actions"),
        Cell: (cellProps) => {
          const venteData = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
             <li className="list-inline-item" title={props.t("Modifier")}>
              <Link to={`/apps-vente-update/${venteData.id}/`} className="text-warning d-inline-block">
                   <i className="ri-pencil-fill align-bottom"></i>
               </Link>
             </li>
             
              <li className="list-inline-item" title={props.t("Editer une facture")}>
                {
                  typeAbonnementCourant === 1 && factures.length === 15 ?
                  <Link to="#" onClick={ () => { toastMe() }} className="text-primary d-inline-block">
                    <i className="ri-bill-fill align-bottom"></i>
                  </Link>
                :
                  <Link to={`/apps-documents-facture-add/${venteData.id}/`} className="text-primary d-inline-block">
                      <i className="ri-bill-fill align-bottom"></i>
                  </Link>
                }
                
              </li>
              <li className="list-inline-item" title={props.t("Editer un bon de livraison")}>
              < Link to={`/apps-documents-blivraison-add/${venteData.id}/`} className="text-primary d-inline-block">
                    <i className="ri-bus-fill align-bottom"></i>
                </Link>
              </li>
               
           </ul>
          );
        },
      },
    ],
    [venteCon]
  );
document.title = props.t("Détails Entreprises | Le Conseiller du BTP");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={props.t("Détails Entreprise")} pageTitle={props.t("Entreprises")} />
          <Row>
            <div className="col-xxl-3">
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="flex-shrink-0 avatar-md mx-auto">
                      <div className="avatar-title bg-soft-success text-success rounded-circle fs-20">
                        {`${selectentreprise.responsable}`.charAt(0)}
                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      <h5 className="mb-1">{selectentreprise.responsable || '(Non renseignée)'}</h5>
                      <p className="text-muted">{selectentreprise.nomEntreprise || '(Non renseignée)'}</p>
                    </div>
                    <div className="table-responsive">
                      <Table className="table mb-0 table-borderless">
                        <tbody>
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Responsable")}</span>
                            </th>
                            <td>{selectentreprise.responsable || '(Non renseignée)'}</td>
                          </tr>
              
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Entreprise")}</span>
                            </th>
                            <td>{selectentreprise.nomEntreprise || '(Non renseignée)'}</td>
                          </tr>
              
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Email")}</span>
                            </th>
                            <td>{selectentreprise.email || '(Non renseignée)'}</td>
                          </tr>
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Catégorie")}</span>
                            </th>
                            <td><Badge color="danger">{selectentreprise.category || '(Non renseignée)'}</Badge></td>
                          </tr>
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Forme juridique")}</span>
                            </th>
                            <td><Badge color="warning">{selectentreprise.formeJuridique || '(Non renseignée)'}</Badge></td>
                          </tr>
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Type contact")}</span>
                            </th>
                            <td><Badge color="info">{selectentreprise.type_contact || '(Non renseignée)'}</Badge></td>
                          </tr>
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Revenus de l'entreprise")}</span>
                            </th>
                            <td>{selectentreprise.revenu_entreprise || '(Non renseignée)'}</td>
                          </tr>

                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Date de création")}</span>
                            </th>
                            <td>{selectentreprise.date_creation || '(Non renseignée)'}</td>
                          </tr>

                         
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Téléphone")}</span>
                            </th>
                            <td>{selectentreprise.telephone || '(Non renseignée)'}</td>
                          </tr>
                        
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Code Postal")}</span>
                            </th>
                            <td>{selectentreprise.codePostal || '(Non renseignée)'}</td>
                          </tr>

                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Adresse")}</span>
                            </th>
                            <td>{selectentreprise.adresse || '(Non renseignée)'}</td>
                          </tr>
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("N° légal 1")}</span>
                            </th>
                            <td>{selectentreprise.num_enreg_legal1 || '(Non renseignée)'}</td>
                          </tr>
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("N° légal 2")}</span>
                            </th>
                            <td>{selectentreprise.num_enreg_legal2 || '(Non renseignée)'}</td>
                          </tr>
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Capital social")}</span>
                            </th>
                            <td>{selectentreprise.capitalSocial || '(Non renseignée)'}</td>
                          </tr>
                          
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Faxe")}</span>
                            </th>
                            <td>{selectentreprise.faxe || '(Non renseignée)'}</td>
                          </tr>
                          <tr>
                            <th>
                              <span className="fw-medium">{props.t("Commentaire")}</span>
                            </th>
                            <td><Badge color="info">{selectentreprise.commentaire || '(Non renseignée)'}</Badge></td>
                          </tr>
                          
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="p-4 border-top border-top-dashed">
                    <h5 className="card-title mb-4">Portfolio</h5>
                    <div className="d-flex flex-wrap gap-2">
                        <div>
                            <a href={selectentreprise.siteweb} target="_blank" rel="noreferrer" className="avatar-xs d-block">
                                <span
                                    className="avatar-title rounded-circle fs-16 bg-success">
                                    <i className="ri-global-fill"></i>
                                </span>
                            </a>
                        </div>
                        <div>
                            <a href={selectentreprise.facebook} target="_blank" rel="noreferrer" className="avatar-xs d-block">
                                <span
                                    className="avatar-title rounded-circle fs-16 bg-info">
                                    <i className="ri-facebook-fill"></i>
                                </span>
                            </a>
                        </div>
                        <div>
                            <a href={selectentreprise.youtube} target="_blank" rel="noreferrer" className="avatar-xs d-block">
                                <span
                                    className="avatar-title rounded-circle fs-16 bg-danger">
                                    <i className="ri-youtube-fill"></i>
                                </span>
                            </a>
                        </div>
                        
                        <div>
                            <a href={selectentreprise.instagram} target="_blank" rel="noreferrer" className="avatar-xs d-block">
                                <span
                                    className="avatar-title rounded-circle fs-16 bg-primary">
                                    <i className="ri-instagram-fill"></i>
                                </span>
                            </a>
                        </div>
                        <div>
                            <a href={selectentreprise.linkedin} target="_blank" rel="noreferrer" className="avatar-xs d-block">
                                <span
                                    className="avatar-title rounded-circle fs-16 bg-info">
                                    <i className="ri-linkedin-fill"></i>
                                </span>
                            </a>
                        </div>
                        <div>
                            <a href={selectentreprise.snapchat} target="_blank" rel="noreferrer" className="avatar-xs d-block">
                                <span
                                    className="avatar-title rounded-circle fs-16 bg-warning">
                                    <i className="ri-snapchat-fill"></i>
                                </span>
                            </a>
                        </div>
                        <div>
                            <a href={selectentreprise.twitter} target="_blank" rel="noreferrer" className="avatar-xs d-block">
                                <span
                                    className="avatar-title rounded-circle fs-16 bg-success">
                                    <i className="ri-twitter-fill"></i>
                                </span>
                            </a>
                        </div>
                        <div>
                            <a href={selectentreprise.tiktok} target="_blank" rel="noreferrer" className="avatar-xs d-block">
                                <span className="avatar-title rounded-circle fs-16 bg-dark">
                                    <i>
                                      <img src={tiktok} alt="tik-tok" height="30" />
                                    </i>
                                </span>
                            </a>
                        </div>
                    </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-xxl-9">
              <Card>
                <CardBody>
                  <div
                    className="table-card gridjs-border-none pb-2"
                  >
                   {(realVente && realVente.length > 0) ? (
                    <TableContainer
                        columns={columns}
                        data={(realVente.filter((data) => parseInt(ConId(data.contact)) === parseInt(entrepriseId.id)) || [])}
                        isAddUserList={false}
                        customPageSize={8}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                  ) : (
                    <div className="py-4 text-center">
                       
                      <div className="mt-4">
                        <h5>{props.t("Sorry! No Result Found")}</h5>
                      </div>
                    </div>
                  )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EntreprisesDetail.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(EntreprisesDetail));
