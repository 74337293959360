import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { FieldArray } from "formik";

import {
    CardBody,
    Row,
    Col,
    Card,
    Container,
    Form,
    Input,
    Label,
    Table,
    FormFeedback,
    Button,
} from "reactstrap"

import { ToastContainer, toast } from 'react-toastify';

import { isEmpty } from "lodash";
 
import { Link, useHistory } from "react-router-dom"
import Flatpickr from "react-flatpickr"

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";

import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"

import { useFormik } from "formik";
import * as Yup from "yup";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import newId from '../../../src/newid'
import { axiosIns } from '../../utils'



const OpportunitesAddNew = (props) => {
  const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).access : null;
  const url = "https://back.crm.inawo.pro/api/v1";
  if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const [selectContact, setSelectContact] = useState([])
  const [selectCollaborateur, setSelectCollaborateur] = useState([])
  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/contacts`);
          setSelectContact(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const data  = await axios.get(`${url}/collaborateurs`);
          setSelectCollaborateur(data)
          console.log(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();

    }, [url]);


  const dispatch = useDispatch();
  const history = useHistory();

  const {ventes, crmcontacts } = useSelector((state) => ({
    ventes: state.Vente.ventes,
    crmcontacts: state.Crm.crmcontacts,
  }));

  const [venteList, setVenteList] = useState([]);
  const [contactList, setContactList] = useState([]);

  

  const [ispaymentDetails, setispaymentDetails] = useState(null);
  const [isCurrency, setisCurrency] = useState('$');

  function handleispaymentDetails(ispaymentDetails) {
    setispaymentDetails(ispaymentDetails);
  }

  function handleisCurrency(isCurrency) {
    setisCurrency(isCurrency);
  }

  const alltype = [
    {
      options: [
        { label: props.t("Sélectionner le type d'opportunité"), value: "" },
        { label: props.t("Contact initialisé"), value: 'contact initiated' },
        { label: props.t('Piste découverte'), value: 'lead discovered'},
        { label: props.t('Reunion arrangée'), value: 'meeting arranged'},
        { label: props.t('Besoin identifié'), value: 'need identified'},
        { label: props.t('Offre acceptée'), value: 'offer accepted'}
      ],
    },
  ];

  const dateFormat = () => {
    let d = new Date(),
      months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
    return (
      d.getDate() +
      ' ' +
      months[d.getMonth()] +
      ', ' +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const [taxpercent, setTaxpercent] = useState(0);
  const [discountpercent, setDiscountpercent] = useState(0);

  const dateformate = (e) => {
    const date = e.toString().split(' ');
    const joinDate = (date[2] + ' ' + date[1] + ', ' + date[3]).toString();
    setDate(joinDate);
  };

  document.title = props.t(
    'Ajout d\'une opportunités | Le Conseiller du BTP'
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      "type": null,
      "title": "",
      "value": null,
      "date": null,
      "description": "",
      "owner": null,
      "contact": null,
  }
  });

  const [FormData, setFormData] = useState({
    "type": null,
    "title": "",
    "value": null,
    "date": null,
    "description": "",
    "owner": null,
    "contact": null,
});

  // const [selectedClient, setSelectedClient] = useState({});

 

  const handleinputform = (e) => {
    setFormData({ ...FormData, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({});

const validate = () => {
  const newErrors = {};
  if (!FormData.title) {
    newErrors.title= "Le champ est obligatoire";
  }
  if (!FormData.type) {
    newErrors.type = "Le champ est obligatoire";
  }
  if (!FormData.value) {
    newErrors.value = "Le champ est obligatoire";
  }
  if (!FormData.date) {
    newErrors.date = "Le champ est obligatoire";
  }
  if (!FormData.description) {
    newErrors.description = "Le champ est obligatoire";
  }
  // if (!FormData.owner) {
  //   newErrors.owner = "Le champ est obligatoire";
  // }
  if (!FormData.contact) {
    newErrors.contact = "Le champ est obligatoire";
  }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()){
      const newOpportunites= {
          "type": FormData.type,
          "title": FormData.title,
          "value": FormData.value,
          "date": FormData.date,
          "description": FormData.description,
          "owner": FormData.owner,
          "contact": FormData.contact
      };
      console.log("opportinites", newOpportunites);

      axios.post(`${url}/opportunities/`, newOpportunites)
      .then(res=>{
        if(res.status !==400 || res.status !==500){
          console.log("idonsubmit",newOpportunites);
          toast.success(props.t("Opportunité enregistrée avec succès"));
          history.push("/apps-deals");
        }else{
          toast.error(props.t("Un problème est survenu lors de l\'enregistrement"),{autoClose:3000})
          console.log(res.status);        
        } 
      })

    }
    
  };
  


  return (
    <div className="page-content">
      <ToastContainer closeButton={false} />
      <Container fluid>
        <BreadCrumb
          title={props.t('Ajouter une opportunité')}
          pageTitle={props.t('Ajout de opportunté')}
        />
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card>
              <Form onSubmit={submitHandler}>
                <CardBody className="p-4">
                  <Row className="g-3">
                    <Col lg={12}>
                      <div className='mb-2'>
                        <Label for='title'>
                          {props.t("Libellé")}
                        </Label> <span style={{color:'red'}}>*</span>
                        <Input 
                          type="text"
                          className="form-control "
                          name="title"
                          onChange={handleinputform}
                          onBlur={validation.handleBlur}
                          value={FormData.title || ""}
                          invalid={errors.title ? true : false}
                        />
                            {errors.title  ? (
                              <FormFeedback type="invalid">{errors.title}</FormFeedback>
                            ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                    <div className="mb-2">
                        <Label
                          for="libelle"
                          className="form-label"
                        >
                          {props.t("Catégorie d'opportunités")}
                        </Label> <span style={{color:'red'}}>*</span>
                        <div className="input-light">
                                        <Input
                                          name="type"
                                          type="select"
                                          className="form-control"
                                          onBlur={validation.handleBlur}
                                          onChange={handleinputform}
                                          value={ FormData.type || "" }
                                          invalid={errors.type ? true : false}
                                        >
                                          {alltype.map((item, key) => (
                                              <React.Fragment key={key}>
                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                              </React.Fragment>
                                            ))}
                                          </Input>
                            {errors.type  ? (
                              <FormFeedback type="invalid">{errors.type  }</FormFeedback>
                            ) : null}
                          </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className='mb-2'>                                                                                                                                                       
                        <Label for='value'>
                          {props.t("Nombre")}
                        </Label>
                        <Input 
                          type="number"
                          className="form-control "
                          name="value"
                          onChange={handleinputform}
                          onBlur={validation.handleBlur}
                          value={FormData.value }
                          invalid= { errors.value ? true: false}
                        />
                         {errors.date_depense  ? (
                              <FormFeedback type="invalid">{errors.value}</FormFeedback>
                            ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className='mb-2'>                                                                                                                                                       
                        <Label for='date'>
                          {props.t("Date")}
                        </Label>
                        <Input 
                          type="date"
                          className="form-control "
                          name="date"
                          onChange={handleinputform}
                          onBlur={validation.handleBlur}
                          value={FormData.date }
                          invalid= { errors.date ? true: false}
                        />
                         {errors.date ? (
                              <FormFeedback type="invalid">{errors.date}</FormFeedback>
                            ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                    <div className="mb-2">
                        <Label
                          for="Responsable"
                          className="form-label "
                        >
                          {props.t("Responsable")}
                        </Label>
                        <div className="input-light">
                                        <Input
                                          name="owner"
                                          type="select"
                                          className="form-control"
                                          onBlur={validation.handleBlur}
                                          onChange={handleinputform}
                                          value={ FormData.owner|| "Selectionner le collaborateur" }
                                          invalid={errors.owner ? true : false}
                                        >
                                          <option value="">{props.t("Sélectionner le collaborateur")}</option>
                                          {selectCollaborateur.map((item, key) => (
                                              <React.Fragment key={key}>
                                                <option  key={key} value={item.id}>{item.nom}</option>
                                              </React.Fragment>
                                            ))}
                                          </Input>
                           
                          </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                    <div className="mb-2">
                        <Label
                          for="contact"
                          className="form-label "
                        >
                          {props.t("Contact")}
                        </Label>
                        <div className="input-light">
                                        <Input
                                          name="contact"
                                          type="select"
                                          className="form-control"
                                          onBlur={validation.handleBlur}
                                          onChange={handleinputform}
                                          value={ FormData.contact|| "Sélectionner le contact" }
                                          invalid={errors.contact? true : false}
                                        >
                                          <option value="">{props.t("Sélectionner le contact")}</option>
                                          {selectContact.map((item, key) => (
                                              <React.Fragment key={key}>
                                                <option key={key} value={item.id}>{item.nom}</option>
                                              </React.Fragment>
                                            ))}
                                          </Input>
                            {errors.contact  ? (
                              <FormFeedback type="invalid">{errors.contact }</FormFeedback>
                            ) : null}
                          </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className='mb-2'>
                        <Label for='libelle_depense'>
                          {props.t("Description")}
                        </Label>
                        <textarea 
                          type="text"
                          className="form-control "
                          name="description"
                          onChange={handleinputform}
                          onBlur={validation.handleBlur}
                          value={FormData.description }
                        />
                            {errors.description  ? (
                              <FormFeedback type="invalid">{errors.description}</FormFeedback>
                            ) : null}
                      </div>
                    </Col>
                  </Row>
                  <div className="float-right mt-3 mb-3">
                    <button type="submit" className="btn btn-info">
                      {props.t("Enregistrer")}
                    </button>
                  </div>
                </CardBody>

                
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

OpportunitesAddNew.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(OpportunitesAddNew));
