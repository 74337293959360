import {
  LOGIN_AFFILIATE,
  LOGIN_AFFILIATE_SUCCESS,
  LOGOUT_AFFILIATE,
  LOGOUT_AFFILIATE_SUCCESS,
  API_ERROR,
  RESET_LOGIN_FLAG
} from "./actionTypes";

export const loginAffiliate = (affiliate, history) => {
  return {
    type: LOGIN_AFFILIATE,
    payload: { affiliate, history },
  };
};

export const loginAffiliateSuccess = affiliate => {
  return {
    type: LOGIN_AFFILIATE_SUCCESS,
    payload: affiliate,
  };
};

export const logoutAffiliate = history => {
  return {
    type: LOGOUT_AFFILIATE,
    payload: { history },
  };
};

export const logoutAffiliateSuccess = () => {
  return {
    type: LOGOUT_AFFILIATE_SUCCESS,
    payload: {},
  };
};

export const apiAffiliateError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const resetAffiliateLoginFlag = () => {
  return {
    type: RESET_LOGIN_FLAG,
  }
}
