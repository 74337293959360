import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Button,FormFeedback,Label,Input,Form } from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { codeOtp } from '../../../store/actions';


//import images
import logo from "../../../assets/images/lcbtp.png";


const BasicTwosVerify = () => {
    document.title="Vérification du compte | Le conseiller du BTP";
    const history = useHistory();
    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        
        initialValues: {
            email: "",
            otp:""
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Veuillez entrer votre adresse électronique"),
            otp: Yup.string().required("Veuillez entrer votre adresse électronique")
            
            // .matches("Veuillez inclure un @ dans l'adresse électronique"),
        }),
        onSubmit: (values) => {
            const user = {
                email: values.email,
                otp:values.otp
            }
            console.log(user);
            dispatch(codeOtp(user));
            localStorage.setItem("langreset", "fr");
            setTimeout(() => history.push("/auth-pass-change-cover"), 3000);
        }
    });
    return (
        <React.Fragment>
            <div className="">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logo} width="32%" alt=""  />
                                        </Link>
                                    </div>
                                    {/* <p className="mt-1 fs-15 fw-medium" style={{color:'#FF6600'}}>Le Conseiller du BTP</p> */}
                                </div>
                            </Col>
                        </Row>

                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4">
                                            

                                            <div className="p-2 mt-4">
                                                <div className="text-muted text-center mb-4 mx-lg-3">
                                                    <h4 style={{color:'#FF6600'}}>Verifier vos mails</h4>
                                                    <p>Entrer le code envoyé par mail <span className="fw-semibold"></span></p>
                                                </div>

                                                <div className="p-2">
                                                    <Form onSubmit={validation.handleSubmit} action="#">
                                                        <div className="mb-4">
                                                            <Label className="form-label">Email</Label>
                                                            <Input 
                                                            type="email" 
                                                            className="form-control" 
                                                            id="email"
                                                            placeholder="Entrez l'adresse e-mail" 
                                                            name="email"
                                                            value={validation.values.email}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={validation.errors.email && validation.touched.email ? true : false}
                                                            />
                                                            {validation.errors.email && validation.touched.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ): null}
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Code</Label>
                                                            <Input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Entrez le code" 
                                                            name="otp"
                                                            value={validation.values.otp}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={validation.errors.otp && validation.touched.otp ? true : false}
                                                            />
                                                            {validation.errors.otp && validation.touched.otp ? (
                                                                <FormFeedback type="invalid">{validation.errors.otp}</FormFeedback>
                                                            ): null}
                                                        </div>

                                                        <div className="text-center mt-4" >
                                                            <button className="btn btn-warning w-100" style={{ height: "40px", backgroundColor:'#FF6600', border:'#FF6600'}}type="submit">Confirmer</button>
                                                        </div>
                                                    </Form>
                                                </div>
                                                
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-4 text-center">
                                        <p className="mb-0">Code non recu ? <Link to="/auth-pass-reset-basic" className="fw-semibold text-primary text-decoration-underline">Renvoyer</Link> </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth >

            </div>
        </React.Fragment >
    );
};

export default BasicTwosVerify;