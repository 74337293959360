import {
  ADD_NEW_ARRONDISSEMENT,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  DELETE_ARRONDISSEMENT,
  GET_ARRONDISSEMENTS,
  UPDATE_ARRONDISSEMENT,
} from "./actionType";

const initialState = {
  arrondissements: [],
  oneArrondissement: {},
  error: null,
};

export default function arrondissementReducer(state = initialState, action) {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ARRONDISSEMENTS:
          return {
            ...state,
            isLoading: false,
            arrondissements: action.payload.response,
          };
        case ADD_NEW_ARRONDISSEMENT:
          return {
            ...state,
            isLoading: false,
            arrondissements: [
              ...state.arrondissements,
              action.payload.response,
            ],
          };
        case UPDATE_ARRONDISSEMENT:
          return {
            ...state,
            isLoading: false,
            arrondissements: state.arrondissements.map((arrondissement) =>
              arrondissement._id === action.payload.response._id
                ? action.payload.response
                : arrondissement
            ),
          };
        case DELETE_ARRONDISSEMENT:
          return {
            ...state,
            isLoading: false,
            arrondissements: state.arrondissements.filter(
              (arrondissement) =>
                arrondissement._id !== action.payload.response._id
            ),
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
