import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, Col, Container, Alert, Row, Form, FormFeedback, Input, Button, Label } from 'reactstrap';
import Cookies from 'js-cookie';
import AuthSliderEn from '../authCarouselEn';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, apiError, resetRegisterFlag } from "../../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import ParticlesAuth from "../ParticlesAuth";

// phone input
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountrySelect, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import 'react-phone-number-input/mobile'


const CoverSignUpEn = () => {
    document.title = "SignUp | Le Conseiller du BTP";
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmpasswordShow, setConfirmPasswordShow] = useState(false);

    // phone input
    const [phoneinput, setPhoneinput] = useState()
    const [valid, setValid] = useState()

    const [countryCode, setCountryCode] = useState("US")

    const handleCountryChange = (country) => {
        setCountryCode(country)
    }

    const validation = useFormik({
        enableReinitialize: false,

        initialValues: {
            email: '',
            password: '',
            first_name: '',
            last_name: '',
            confirm_password: '',
            telephone: !phoneinput ? false : phoneinput,
            pays: '',
            adresse: '',
            societe: '',
            fonction: '',
            langue: '',
            nbrEmploye: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
                .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required("This field is required"),
            confirm_password: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Confirm Password Isn't Match"
                )
            }).required("This field is required"),
            first_name: Yup.string()
                .required("This field is required"),
            last_name: Yup.string()
                .required("This field is required"),
            email: Yup.string()
                .required("This field is required"),
            telephone: Yup.string()
                .required("This field is required"),
            // ville: Yup.string()
            //     .required("This field is required"),
            adresse: Yup.string()
                .required("This field is required"),
            societe: Yup.string()
                .required("This field is required"),
            fonction: Yup.string()
                .required("This field is required"),
            langue: Yup.string()
                .required("This field is required"),
            nbrEmploye: Yup.string()
                .required("This field is required"),
        }),
        onSubmit: (values) => {
            console.log(values);
            const user = {
                email: values.email,
                password: values.password,
                abonne: {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    telephone: phoneinput,
                    pays: values.pays,
                    adresse: values.adresse,
                    societe: values.societe,
                    fonction: values.fonction,
                    langue: values.langue
                },
                entreprise: {
                    nbrEmploye: values.nbrEmploye,
                }
            }
            //console.log(user);
            dispatch(registerUser(user));
        }
    });

    const handleClick = () => {
        setIsLoading(true);
        // Simulate an asynchronous action (e.g., API call) here
        setTimeout(() => {
        setIsLoading(false);
        }, 4000); // Change this to match the actual loading time

        // You can add your actual login logic here
    };

    const { error, registrationError, success } = useSelector(state => ({
        registrationError: state.Account.registrationError,
        success: state.Account.success,
        error: state.Account.error
    }));

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            setTimeout(() => history.push("/auth-success-msg-en"), 4000);
            sessionStorage.setItem("langsuccess", "en");
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]);

    const [langue, setLangue] = useState('');

    const handleChang = (event) => {
        const selectedLangue = event.target.value;
        setLangue(selectedLangue);
        Cookies.set('language', selectedLangue);
    }

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-wrapper  py-5 d-flex justify-content-center align-items-center min-vh-100">
                    <div style={{
                        backgroundColor: "#FFFFFF", opacity: 0, width: "100%", height: "100%", position: "absolute", top: 0,
                        left: 0
                    }}></div>
                    <div className="auth-page-content overflow-hidden pt-lg-5">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <Card className="overflow-hidden m-0">
                                        <Row className="justify-content-center g-0">
                                            <AuthSliderEn />
                                            <Col lg={6}  style={{ backgroundColor: "#ECECEE" }}>
                                                <div className="p-lg-5 p-4">
                                                    <div>
                                                        <h5 className="text-primary">Start your free trial now.</h5>
                                                        <p className="text-muted">Fill out the form below to start your free trial. We will be available to accompany you every step of the way.</p>
                                                    </div>

                                                    <div className="mt-4">
                                                        <Form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                            className="needs-validation" action="#">

                                                            {success && success ? (
                                                                <>
                                                                    {toast("You will be redirected to the page consult your mail...", { position: "top-right", hideProgressBar: false, className: 'bg-success text-white', progress: undefined, toastId: "" })}
                                                                    <ToastContainer autoClose={2000} limit={1} />
                                                                    <Alert color="success">
                                                                        Successfully registered user!
                                                                    </Alert>
                                                                </>
                                                            ) : null}

                                                            {error && error ? (
                                                                <Alert color="danger"><div>
                                                                    {/* {registrationError} */}
                                                                    The email or phone number has already been saved, please use another value...
                                                                </div></Alert>
                                                            ) : null}
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        {/* <Label htmlFor="first_name" className="form-label">First Name <span className="text-danger">*</span></Label> */}
                                                                        <Input type="text" className="form-control" placeholder="First name" id="first_name"
                                                                            name="first_name"
                                                                            value={validation.values.first_name}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.first_name && validation.touched.first_name ? true : false} />
                                                                        {validation.errors.first_name && validation.touched.first_name ? (
                                                                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                                                        ) : null}

                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="mb-3">
                                                                        {/* <Label htmlFor="last_nameinput" className="form-label">Last Name <span className="text-danger">*</span></Label> */}
                                                                        <Input type="text" className="form-control" placeholder="Last name" id="last_name"
                                                                            name="last_name"
                                                                            value={validation.values.last_name}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.last_name && validation.touched.last_name ? true : false} />
                                                                        {validation.errors.last_name && validation.touched.last_name ? (
                                                                            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label> */}
                                                                        <Input type="email" className="form-control" id="email" placeholder="Email address" name="email"
                                                                            value={validation.values.email}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.email && validation.touched.email ? true : false} />
                                                                        {validation.errors.email && validation.touched.email ? (
                                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="password-input">Password</label> */}
                                                                        <div className="position-relative auth-pass-inputgroup">
                                                                            <Input
                                                                                type={passwordShow ? "text" : "password"}
                                                                                className="form-control pe-5 password-input"
                                                                                placeholder="Password"
                                                                                id="password-input"
                                                                                name="password"
                                                                                value={validation.values.password}
                                                                                onBlur={validation.handleBlur}
                                                                                onChange={validation.handleChange}
                                                                                invalid={validation.errors.password && validation.touched.password ? true : false}
                                                                            />
                                                                            {validation.errors.password && validation.touched.password ? (
                                                                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                            ) : null}
                                                                            <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                                id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <Label htmlFor="confirmPassword" className="form-label">Confirm Password <span className="text-danger">*</span></Label> */}
                                                                        <div className="position-relative auth-pass-inputgroup">
                                                                            <Input
                                                                                name="confirm_password"
                                                                                type={confirmpasswordShow ? "text" : "password"}
                                                                                placeholder="Confirm password"
                                                                                onChange={validation.handleChange}
                                                                                onBlur={validation.handleBlur}
                                                                                value={validation.values.confirm_password || ""}
                                                                                invalid={
                                                                                    validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                                                }
                                                                            />
                                                                            {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                                                <FormFeedback type="invalid"><div>{validation.errors.confirm_password}</div></FormFeedback>
                                                                            ) : null}
                                                                            <Button color="link" onClick={() => setConfirmPasswordShow(!confirmpasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                                id="password-addon-2"><i className="ri-eye-fill align-middle"></i></Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                <div className="mb-3">
                                                                        <PhoneInputWithCountrySelect
                                                                            defaultCountry="US"
                                                                            international
                                                                            placeholder="Téléphone"
                                                                            id="telephone"
                                                                            name="telephone"
                                                                            numberInputProps={{
                                                                                className: `form-control ${!valid ? 'border-danger' : 'border-success'}`
                                                                            }}
                                                                            value={validation.values.telephone || ""}
                                                                            onChange={(e) => {
                                                                                if(!isNaN(e))
                                                                                {
                                                                                    if(isPossiblePhoneNumber(e))
                                                                                    {
                                                                                        setPhoneinput(e)
                                                                                        setValid(true)
                                                                                    }
                                                                                    else{
                                                                                        setPhoneinput(e)
                                                                                        setValid(false)
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className={`text-danger ${valid ? 'd-none' : 'd-block'}`}>{valid === false ? 'Invalid phone Number' : ''}</label>
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label htmlFor="adresse" className="form-label">Adresse <span className="text-danger">*</span></label> */}
                                                                        <Input type="text" className="form-control" id="adresse" placeholder="Address"
                                                                            name="adresse"
                                                                            value={validation.values.adresse}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.adresse && validation.touched.adresse ? true : false} />
                                                                        {validation.errors.adresse && validation.touched.adresse ? (
                                                                            <FormFeedback type="invalid">{validation.errors.adresse}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            </Row>


                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label htmlFor="fonction" className="form-label">fonction <span className="text-danger">*</span></label> */}
                                                                        <Input type="text" className="form-control" id="fonction" placeholder="Function "
                                                                            name="fonction"
                                                                            value={validation.values.fonction}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.fonction && validation.touched.fonction ? true : false} />
                                                                        {validation.errors.fonction && validation.touched.fonction ? (
                                                                            <FormFeedback type="invalid">{validation.errors.fonction}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label htmlFor="societe" className="form-label">Societe <span className="text-danger">*</span></label> */}
                                                                        <Input type="text" className="form-control" id="societe" placeholder="Company"
                                                                            name="societe"
                                                                            value={validation.values.societe}
                                                                            onBlur={validation.handleBlur}
                                                                            onChange={validation.handleChange}
                                                                            invalid={validation.errors.societe && validation.touched.societe ? true : false} />
                                                                        {validation.errors.societe && validation.touched.societe ? (
                                                                            <FormFeedback type="invalid">{validation.errors.societe}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="nbrEmploye">Nombre d'employés</label> */}
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="nbrEmploye" value={validation.values.nbrEmploye} onChange={validation.handleChange}>
                                                                            <option value="">--- Number of employees ---</option>
                                                                            <option value="10">1-10</option>
                                                                            <option value="50">11-50</option>
                                                                            <option value="500">51-500</option>
                                                                            <option value="1000">500 and more</option>
                                                                        </Input>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="pays">Pays</label> */}
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="pays" value={validation.values.pays} onChange={validation.handleChange}>
                                                                            <option value="">--- Countries ---</option>
                                                                            <option value="Afrique du Sud">Afrique du Sud</option>
                                                                            <option value="Afghanistan">Afghanistan</option>
                                                                            <option value="Albanie">Albanie</option>
                                                                            <option value="Algérie">Algérie</option>
                                                                            <option value="Allemagne">Allemagne</option>
                                                                            <option value="Andorre">Andorre</option>
                                                                            <option value="Angola">Angola</option>
                                                                            <option value="Antigua-et-Barbuda">Antigua-et-Barbuda</option>
                                                                            <option value="Arabie Saoudite">Arabie Saoudite</option>
                                                                            <option value="Argentine">Argentine</option>
                                                                            <option value="Arménie">Arménie</option>
                                                                            <option value="Australie">Australie</option>
                                                                            <option value="Autriche">Autriche</option>
                                                                            <option value="Azerbaïdjan">Azerbaïdjan</option>
                                                                            <option value="Bahamas">Bahamas</option>
                                                                            <option value="Bahreïn">Bahreïn</option>
                                                                            <option value="Bangladesh">Bangladesh</option>
                                                                            <option value="Barbade">Barbade</option>
                                                                            <option value="Belgique">Belgique</option>
                                                                            <option value="Belize">Belize</option>
                                                                            <option value="Bénin">Bénin</option>
                                                                            <option value="Bhoutan">Bhoutan</option>
                                                                            <option value="Biélorussie">Biélorussie</option>
                                                                            <option value="Birmanie">Birmanie</option>
                                                                            <option value="Bolivie">Bolivie</option>
                                                                            <option value="Bosnie-Herzégovine">Bosnie-Herzégovine</option>
                                                                            <option value="Botswana">Botswana</option>
                                                                            <option value="Brésil">Brésil</option>
                                                                            <option value="Brunei">Brunei</option>
                                                                            <option value="Bulgarie">Bulgarie</option>
                                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                                            <option value="Burundi">Burundi</option>
                                                                            <option value="Cambodge">Cambodge</option>
                                                                            <option value="Cameroun">Cameroun</option>
                                                                            <option value="Canada">Canada</option>
                                                                            <option value="Cap-Vert">Cap-Vert</option>
                                                                            <option value="Chili">Chili</option>
                                                                            <option value="Chine">Chine</option>
                                                                            <option value="Chypre">Chypre</option>
                                                                            <option value="Colombie">Colombie</option>
                                                                            <option value="Comores">Comores</option>
                                                                            <option value="Corée du Nord">Corée du Nord</option>
                                                                            <option value="Corée du Sud">Corée du Sud</option>
                                                                            <option value="Costa Rica">Costa Rica</option>
                                                                            <option value="Côte d’Ivoire">Côte d’Ivoire</option>
                                                                            <option value="Croatie">Croatie</option>
                                                                            <option value="Cuba">Cuba</option>
                                                                            <option value="Danemark">Danemark</option>
                                                                            <option value="Djibouti">Djibouti</option>
                                                                            <option value="Dominique">Dominique</option>
                                                                            <option value="Égypte">Égypte</option>
                                                                            <option value="Émirats arabes unis">Émirats arabes unis</option>
                                                                            <option value="Équateur">Équateur</option>
                                                                            <option value="Érythrée">Érythrée</option>
                                                                            <option value="Espagne">Espagne</option>
                                                                            <option value="Eswatini">Eswatini</option>
                                                                            <option value="Estonie">Estonie</option>
                                                                            <option value="États-Unis">États-Unis</option>
                                                                            <option value="Éthiopie">Éthiopie</option>
                                                                            <option value="Fidji">Fidji</option>
                                                                            <option value="Finlande">Finlande</option>
                                                                            <option value="France">France</option>
                                                                            <option value="Gabon">Gabon</option>
                                                                            <option value="Gambie">Gambie</option>
                                                                            <option value="Géorgie">Géorgie</option>
                                                                            <option value="Ghana">Ghana</option>
                                                                            <option value="Grèce">Grèce</option>
                                                                            <option value="Grenade">Grenade</option>
                                                                            <option value="Guatemala">Guatemala</option>
                                                                            <option value="Guinée">Guinée</option>
                                                                            <option value="Guinée équatoriale">Guinée équatoriale</option>
                                                                            <option value="Guinée-Bissau">Guinée-Bissau</option>
                                                                            <option value="Guyana">Guyana</option>
                                                                            <option value="Haïti">Haïti</option>
                                                                            <option value="Honduras">Honduras</option>
                                                                            <option value="Hongrie">Hongrie</option>
                                                                            <option value="Îles Cook">Îles Cook</option>
                                                                            <option value="Îles Marshall">Îles Marshall</option>
                                                                            <option value="Inde">Inde</option>
                                                                            <option value="Indonésie">Indonésie</option>
                                                                            <option value="Irak">Irak</option>
                                                                            <option value="Iran">Iran</option>
                                                                            <option value="Irlande">Irlande</option>
                                                                            <option value="Islande">Islande</option>
                                                                            <option value="Israël">Israël</option>
                                                                            <option value="Italie">Italie</option>
                                                                            <option value="Jamaïque">Jamaïque</option>
                                                                            <option value="Japon">Japon</option>
                                                                            <option value="Jordanie">Jordanie</option>
                                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                                            <option value="Kenya">Kenya</option>
                                                                            <option value="Kirghizistan">Kirghizistan</option>
                                                                            <option value="Kiribati">Kiribati</option>
                                                                            <option value="Koweït">Koweït</option>
                                                                            <option value="Laos">Laos</option>
                                                                            <option value="Lesotho">Lesotho</option>
                                                                            <option value="Lettonie">Lettonie</option>
                                                                            <option value="Liban">Liban</option>
                                                                            <option value="Liberia">Liberia</option>
                                                                            <option value="Libye">Libye</option>
                                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                                            <option value="Lituanie">Lituanie</option>
                                                                            <option value="Luxembourg">Luxembourg</option>
                                                                            <option value="Macédoine">Macédoine</option>
                                                                            <option value="Madagascar">Madagascar</option>
                                                                            <option value="Malaisie">Malaisie</option>
                                                                            <option value="Malawi">Malawi</option>
                                                                            <option value="Maldives">Maldives</option>
                                                                            <option value="Mali">Mali</option>
                                                                            <option value="Malte">Malte</option>
                                                                            <option value="Maroc">Maroc</option>
                                                                            <option value="Maurice">Maurice</option>
                                                                            <option value="Mauritanie">Mauritanie</option>
                                                                            <option value="Mexique">Mexique</option>
                                                                            <option value="Micronésie">Micronésie</option>
                                                                            <option value="Moldavie">Moldavie</option>
                                                                            <option value="Monaco">Monaco</option>
                                                                            <option value="Mongolie">Mongolie</option>
                                                                            <option value="Monténégro">Monténégro</option>
                                                                            <option value="Mozambique">Mozambique</option>
                                                                            <option value="Namibie">Namibie</option>
                                                                            <option value="Nauru">Nauru</option>
                                                                            <option value="Népal">Népal</option>
                                                                            <option value="Nicaragua">Nicaragua</option>
                                                                            <option value="Niger">Niger</option>
                                                                            <option value="Nigeria">Nigeria</option>
                                                                            <option value="Niue">Niue</option>
                                                                            <option value="Norvège">Norvège</option>
                                                                            <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
                                                                            <option value="Oman">Oman</option>
                                                                            <option value="Ouganda">Ouganda</option>
                                                                            <option value="Ouzbékistan">Ouzbékistan</option>
                                                                            <option value="Pakistan">Pakistan</option>
                                                                            <option value="Palaos">Palaos</option>
                                                                            <option value="Palestine">Palestine</option>
                                                                            <option value="Panama">Panama</option>
                                                                            <option value="Papouasie-Nouvelle-Guinée">Papouasie-Nouvelle-Guinée</option>
                                                                            <option value="Paraguay">Paraguay</option>
                                                                            <option value="Pays-Bas">Pays-Bas</option>
                                                                            <option value="Pérou">Pérou</option>
                                                                            <option value="Philippines">Philippines</option>
                                                                            <option value="Pologne">Pologne</option>
                                                                            <option value="Portugal">Portugal</option>
                                                                            <option value="Qatar">Qatar</option>
                                                                            <option value="République centrafricaine">République centrafricaine</option>
                                                                            <option value="République démocratique du Congo">République démocratique du Congo</option>
                                                                            <option value="République Dominicaine">République Dominicaine</option>
                                                                            <option value="République du Congo">République du Congo</option>
                                                                            <option value="République tchèque">République tchèque</option>
                                                                            <option value="Roumanie">Roumanie</option>
                                                                            <option value="Royaume-Uni">Royaume-Uni</option>
                                                                            <option value="Russie">Russie</option>
                                                                            <option value="Rwanda">Rwanda</option>
                                                                            <option value="Saint-Kitts-et-Nevis">Saint-Kitts-et-Nevis</option>
                                                                            <option value="Saint-Vincent-et-les-Grenadines">Saint-Vincent-et-les-Grenadines</option>
                                                                            <option value="Sainte-Lucie">Sainte-Lucie</option>
                                                                            <option value="Saint-Marin">Saint-Marin</option>
                                                                            <option value="Salomon">Salomon</option>
                                                                            <option value="Salvador">Salvador</option>
                                                                            <option value="Samoa">Samoa</option>
                                                                            <option value="São Tomé-et-Principe">São Tomé-et-Principe</option>
                                                                            <option value="Sénégal">Sénégal</option>
                                                                            <option value="Serbie">Serbie</option>
                                                                            <option value="Seychelles">Seychelles</option>
                                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                                            <option value="Singapour">Singapour</option>
                                                                            <option value="Slovaquie">Slovaquie</option>
                                                                            <option value="Slovénie">Slovénie</option>
                                                                            <option value="Somalie">Somalie</option>
                                                                            <option value="Soudan">Soudan</option>
                                                                            <option value="Soudan du Sud">Soudan du Sud</option>
                                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                                            <option value="Suède">Suède</option>
                                                                            <option value="Suisse">Suisse</option>
                                                                            <option value="Suriname">Suriname</option>
                                                                            <option value="Syrie">Syrie</option>
                                                                            <option value="Tadjikistan">Tadjikistan</option>
                                                                            <option value="Tanzanie">Tanzanie</option>
                                                                            <option value="Tchad">Tchad</option>
                                                                            <option value="Thaïlande">Thaïlande</option>
                                                                            <option value="Timor oriental">Timor oriental</option>
                                                                            <option value="Togo">Togo</option>
                                                                            <option value="Tonga">Tonga</option>
                                                                            <option value="Trinité-et-Tobago">Trinité-et-Tobago</option>
                                                                            <option value="Tunisie">Tunisie</option>
                                                                            <option value="Turkménistan">Turkménistan</option>
                                                                            <option value="Turquie">Turquie</option>
                                                                            <option value="Tuvalu">Tuvalu</option>
                                                                            <option value="Ukraine">Ukraine</option>
                                                                            <option value="Uruguay">Uruguay</option>
                                                                            <option value="Vanuatu">Vanuatu</option>
                                                                            <option value="Vatican">Vatican</option>
                                                                            <option value="Venezuela">Venezuela</option>
                                                                            <option value="Viêt Nam">Viêt Nam</option>
                                                                            <option value="Yémen">Yémen</option>
                                                                            <option value="Zambie">Zambie</option>
                                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                                        </Input>
                                                                    </div>
                                                                </Col>

                                                                {/* <Col md={4}>
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="specificSizeSelect">Ville</label>
                                                                    <select className="form-select" data-choices data-choices-sorting="true" id="ville">
                                                                        <option >--- Choose ---</option>
                                                                        <option defaultValue="1">Cotonou</option>
                                                                        <option defaultValue="2">Calavi</option>
                                                                        <option defaultValue="3">Kpota</option>
                                                                        <option defaultValue="4">hevie</option>
                                                                    </select>
                                                                </div>
                                                            </Col> */}

                                                                <Col md={12}>
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" htmlFor="langue">Langue</label> */}
                                                                        <Input className="form-select" type='select' data-choices data-choices-sorting="true" id="langue" value={validation.values.langue} onChange={(e) => {
                                                                            validation.handleChange(e);
                                                                            handleChang(e);
                                                                            }}>
                                                                            <option value="">--- Language ---</option>
                                                                            <option value="Eng">English</option>
                                                                            <option value="Fr">French</option>
                                                                        </Input>
                                                                    </div>
                                                                </Col>

                                                            </Row>






                                                            <div className="mb-4">
                                                                <p className="mb-0 fs-12 text-muted fst-normal">By registering, you agree to our <a href="https://en.inawo.pro" className="text-primary text-decoration-underline fst-normal fw-medium">General terms and conditions of sale</a>, our <a href="https://en.inawo.pro" className="text-primary text-decoration-underline fst-normal fw-medium">Terms and conditions of use</a> and our <a href="https://en.inawo.pro/about/legal_notices" className="text-primary text-decoration-underline fst-normal fw-medium">Privacy Policy</a></p>
                                                            </div>

                                                            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                                <h5 className="fs-13">Password must contain:</h5>
                                                                <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 characters</b></p>
                                                                <p id="pass-lower" className="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                                                <p id="pass-upper" className="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                                                <p id="pass-number" className="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                                            </div>

                                                            <div className="mt-4">
                                                                <Button
                                                                    className={`btn w-100 ${isLoading ? 'loading' : ''}`}
                                                                    style={{ height: "40px" }}
                                                                    type="submit"
                                                                    onClick={handleClick}
                                                                    >
                                                                    {isLoading ? (
                                                                        <div className="loader"></div>
                                                                    ) : (
                                                                        "Sign up"
                                                                    )}
                                                                </Button> 
                                                            </div>


                                                        </Form>
                                                    </div>

                                                    <div className="mt-4 text-center">
                                                        <p className="mb-0">Already have an account? <Link to="/en/login" className="fw-semibold text-primary text-decoration-underline"> Log in</Link> </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                            </Row>
                        </Container>
                    </div>

                    {/* <footer className="footer">
                    <Container>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0">{new Date().getFullYear()} InawoCRM. Crafted with <i className="mdi mdi-heart text-danger"></i> by Le Conseiller du BTP Technologies</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </footer> */}
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default CoverSignUpEn;