import {
  ADD_NEW_QUARTIER,
  ADD_QUARTIER_FAIL,
  ADD_QUARTIER_SUCCESS,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  DELETE_QUARTIER,
  DELETE_QUARTIER_FAIL,
  DELETE_QUARTIER_SUCCESS,
  GET_QUARTIERS,
  UPDATE_QUARTIER,
  UPDATE_QUARTIER_FAIL,
  UPDATE_QUARTIER_SUCCESS,
} from "./actionType";

// common success
export const quartiersApiResponseSuccess = (actionType, response) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, response },
});

//common error
export const quartiersApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getQuartiers = () => ({
  type: GET_QUARTIERS,
});

export const addNewQuartier = (quartier) => ({
  type: ADD_NEW_QUARTIER,
  payload: quartier,
});

export const addNewQuartierSuccess = (quartier) => ({
  type: ADD_QUARTIER_SUCCESS,
  payload: quartier,
});

export const addNewQuartierFail = (error) => ({
  type: ADD_QUARTIER_FAIL,
  payload: error,
});

export const updateQuartier = (quartier) => ({
  type: UPDATE_QUARTIER,
  payload: quartier,
});

export const updateQuartierSuccess = (quartier) => ({
  type: UPDATE_QUARTIER_SUCCESS,
  payload: quartier,
});

export const updateQuartierFail = (error) => ({
  type: UPDATE_QUARTIER_FAIL,
  payload: error,
});

export const deleteQuartier = (quartier) => ({
  type: DELETE_QUARTIER,
  payload: quartier,
});

export const deleteQuartierSuccess = (quartier) => ({
  type: DELETE_QUARTIER_SUCCESS,
  payload: quartier,
});

export const deleteQuartierFail = (error) => ({
  type: DELETE_QUARTIER_FAIL,
  payload: error,
});
