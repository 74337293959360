import React from "react";
import { Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

// Import Images
// import logoLight from "../../assets/images/logo-light.png";
import logoInawoCRMBlanc1 from "../../assets/images/logo01.png";
// import logoInawoCRMBlanc2 from "../../assets/images/logo-InawoCRM-Blanc-2.png";
import inscriptionlaptop from "../../assets/images/inscription-laptop.png";


const AuthSlider = () => {
   
};

export default AuthSlider;