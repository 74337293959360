import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { logoutUser } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const Logout = (props) => {
  const dispatch = useDispatch();

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.Login.isUserLogout,
  }));
  const lang = localStorage.getItem("i18nextLng")

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  if (isUserLogout) {
    if (lang === "fr") {
      return <Redirect to="/fr/connexion" />;
    }
    return <Redirect to="/en/login" />
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);